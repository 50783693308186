import React from 'react';
import PropTypes from 'prop-types';

const GridContainer = props => (
  <div id={props.id} style={props.style} className={(props.isFluid ? 'container-fluid' : 'container') + (props.className ? ' '+props.className : '')} onMouseLeave={props.onMouseLeave} onMouseEnter={props.onMouseEnter} onClick={props.onClick} tabIndex={props.tabIndex} onBlur={props.onBlur} onFocus={props.onFocus}>
    {props.children}
  </div>
);

GridContainer.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  isFluid: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func
};

GridContainer.defaultProps = {
  isFluid: false
};

export default GridContainer;