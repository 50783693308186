import React, { Component } from 'react';
import { connect } from 'react-redux';
import GridContainer from '../../MasterComponents/Grid/GridContainer';
import InsightsBody from './InsightsBody';
import Reports from '../Reports/Reports';
import InsightsEmpty from './InsightsEmpty';
class InsightsContent extends Component {
  render() {
    const props = this.props;
    const { merchantAll, displayReports, loading } = props;
    if (loading) {
      return <GridContainer style={{height: '70vh' }} />;
    }
    return (
      <>
        { merchantAll.length > 0 ?
          <>
            <InsightsBody  {...props} />
            { displayReports && 
              <Reports  />
            }
          </>
          :
            <InsightsEmpty />
        }
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    merchantAll: state.location.merchantFilter.filter(m => m.platformId),
    displayReports: state.company.displayReports,
    loading: state.spinner.spinner
  };
}
export default connect(mapStateToProps)(InsightsContent);
