import React, { Component } from 'react';
import '../SignUp.scss';
import { connect } from 'react-redux';
import SignUpWhitelabelSidebar from './SignUpWhitelabelSidebar';
import SignUpWhitelabelNewOrOldContent from './SignUpWhitelabelNewOrOldContent';
import { setSegment, setSubsegment } from '../../../config/Company/CompanyActions';
import queryString from 'query-string';

class SignUpWhitelabelNewOrOld extends Component {
    componentDidMount() {
        const { history, location, dispatch, displayExistingUser, loading } = this.props;
        const { search } = location;
        const parsed = queryString.parse(search);
        if (parsed.segment) {
          dispatch(setSegment(parsed.segment));
        }
        if (parsed.subsegment) {
          dispatch(setSubsegment(parsed.subsegment));
        }

        if ( !loading) {
            if (!displayExistingUser) {
                history.replace('/register/new/type');
            }
        }
    };
    componentDidUpdate(prevProps, prevState) {
        const { history, displayExistingUser, loading } = this.props;
        if ( prevProps.loading && !loading) {
            if (!displayExistingUser) {
                history.replace('/register/new/type');
            }
        }
    };
    render() {
        const { history, companyName, loading } = this.props;
        const sidebarProps = {
            history,
            companyName
        };
        const contentProps = {
            history
        };
        return (
            <div className='container-signup'>
                <SignUpWhitelabelSidebar {...sidebarProps} />
                { !loading && <SignUpWhitelabelNewOrOldContent {...contentProps} /> }
            </div>
        )
    };
}

const mapStateToProps = state => {
    return {
        companyId: state.company.id,
        companyName: state.company.nickname,
        displayExistingUser: state.company.displayExistingUser,
        loading: state.company.loading
    }
}

export default connect(mapStateToProps)(SignUpWhitelabelNewOrOld);
