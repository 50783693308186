import React from 'react';
import PropTypes from 'prop-types';

import logo from '../../assets/images/hashicorp-logo.svg';

const LogoHC = props => (
  <img id={props.id} className={props.className} src={logo} alt='Hashicorp Logo' />
);

LogoHC.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string
}

export default LogoHC;