import { apiInstance } from '../../config/tokenApi';

const requestFoodCost = () => {
    return apiInstance.post('/foodbam/request-food-cost-management-demo');
};


const agreement = (params) => {
    return apiInstance.post('/food-cost-management/agreement', params);
};

export default {
    requestFoodCost, agreement
};
