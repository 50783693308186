export default function reducer(state = {
  reportsAll: []
}, action) {
  switch (action.type) {
    case 'REPORTS_SET_ALL':
      return {
        ...state,
        reportsAll: action.payload
      };
    default:
      return state;
  }
}