import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { getItemIntelDetailsLinkClass } from '../../config/Company/CompanyHelper';
import Card from '../../MasterComponents/Card/Card';
import GridCol from '../../MasterComponents/Grid/GridCol';
import GridRow from '../../MasterComponents/Grid/GridRow';
import IconEmptyPricing from '../../MasterComponents/Icons/IconEmptyPricing';
import { showModal } from '../../MasterComponents/Modals/ModalsActions';
import { spinner } from '../../MasterComponents/Spinner/SpinnerActions';
import './ItemIntel.scss';
import ItemIntelApi from './ItemIntelApi';
import ServerErrorModal from './Modals/ServerErrorModal';
import SpecifyYourSearchModal from './Modals/SpecifyYourSearchModal';
import { itemIntelLoadMoreItems, itemIntelSetPage, itemIntelSetScrollToSelectedItem } from './Reducer/ItemIntelAction';

class ItemIntelItemsList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      prevY: 0,
      loading: false,
    };
    this.observer = null;
  }

  componentDidMount() {
    this.observeScroll();
    this.executeScroll();
  }


  componentDidUpdate(prevProps) {
    if (prevProps.items.length !== this.props.items.length && this.loadingRef) {
      this.observeScroll();
      this.executeScroll();
    }
  }

  componentWillUnmount() {
    if (this.observer) this.observer.disconnect();
  }

  observeScroll() {
    if (this.observer) this.observer.disconnect();
    var options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    };
    this.observer = new IntersectionObserver(
      this.handleObserver.bind(this),
      options
    );
    this.observer.observe(this.loadingRef);
  }

  handleObserver(entities, observer) {
    const y = entities[0].boundingClientRect.y;
    if (this.state.prevY > y && this.props.items.length > 9) {
      this.loadMore();
    }
    this.setState({ prevY: y });
  }

  onClickSeeDetails(productId, e) {
    const { history, dispatch } = this.props;
    history.push(`/dashboard/itemIntel/${productId}`);
    dispatch(itemIntelSetScrollToSelectedItem(this.mainRef.scrollTop))
  };


  executeScroll() {
    let scrollTo = this.props.scrollToSelectedItem;
    this.mainRef && scrollTo && this.mainRef.scroll(0, scrollTo);
  }


  loadMore() {
    const { dispatch, filters, page } = this.props;
    if ((page + 1) <= 50) {
      dispatch(spinner(true));
      this.setState({ loading: true });
      ItemIntelApi.getItems(filters, (page + 1)).then((res) => {
        dispatch(itemIntelLoadMoreItems(res.data.data));
        dispatch(itemIntelSetPage(page + 1));
        dispatch(spinner(false));
        this.setState({ loading: false });
      }).catch(error => {
        dispatch(spinner(false));
        this.setState({ loading: false });
        const { status, statusText } = error || '';
        dispatch(showModal(ServerErrorModal, 'serverErrorModal', { status, statusText }));
      });
    } else {
      const { dispatch } = this.props;
      dispatch(showModal(SpecifyYourSearchModal, 'specifyYourSearchModal'));
    }
  }

  render() {
    const { items, filters, spinner, companyId } = this.props;
    const itemIntelDetailsLinkClass = getItemIntelDetailsLinkClass(companyId);
    return (
      <>
        {isMobile ?
          <div className='items-list-rows-container-mobile' ref={(ref) => this.mainRef = ref}>
            {items.length > 0 ?
              items.map(item =>
                <Card key={item.manufacturer_product_id} size='col-12' className='py-2 px-2 mb-2 border item-card-mobile'>
                  <GridRow className='py-1 px-0' justify='between'>
                    <GridCol size={4} className='text-grey'>Min</GridCol>
                    <GridCol size={8} className='text-right'>{item.min || ''}</GridCol>
                  </GridRow>
                  <GridRow className='py-1 px-0'>
                    <GridCol size={4} className='text-grey'>Description</GridCol>
                    <GridCol size={8} className='text-right text-capitalize'>{item.description.toLowerCase() || ''}</GridCol>
                  </GridRow>
                  <GridRow className='py-1 px-0'>
                    <GridCol size={4} className='text-grey'>Manufacturer</GridCol>
                    <GridCol size={8} className='text-right'>{item.manufacturer || ''}</GridCol>
                  </GridRow>
                  <GridRow className='py-1 px-0'>
                    <GridCol size={4} className='text-grey'>Rebate</GridCol>
                    <GridCol size={8} className='text-right'>{item.rebate ? 'Y' : 'N'}</GridCol>
                  </GridRow>
                  {/* <GridRow className='py-1 px-0'>
                  <GridCol size={4} className='text-grey'>Deviation</GridCol>
                  <GridCol size={8} className='text-right'>{item.deviated_price || ''}</GridCol>
                </GridRow> */}
                  <GridRow className='py-1 px-0'>
                    <GridCol size={12} className='text-blue text-right text-bold'>
                      <span className={'clickable ' + itemIntelDetailsLinkClass} 
                        onClick={this.onClickSeeDetails.bind(this, item.manufacturer_product_id)}>
                        Details
                      </span>
                    </GridCol>
                  </GridRow>
                </Card>
              )
              :
              filters && (filters.itemFilter !== null ||
                filters.search !== null ||
                filters.manufacturers !== null ||
                filters.categories !== null ||
                filters.types !== null
              ) && !spinner.spinner ?
                <GridCol className='p-5 text-center text-grey'>
                  <IconEmptyPricing /><br /><br />
                  We did not find any results, please try another search.
                </GridCol>
                :
                <></>
            }
            <div
              ref={loadingRef => (this.loadingRef = loadingRef)}
              style={{ height: '30px', margin: '10px 0' }}
            >
              <span style={{ display: this.state.loading ? 'block' : 'none', textAlign: 'center' }}>
                Loading...
              </span>
            </div>
          </div>
          :
          <GridRow className='mb-1 bg-white'>
            <GridCol className='border'>
              <GridRow className='py-2 border-bottom text-size-10 line-height-12 text-grey items-list-rows-headers'>
                <GridCol size={2}>MIN</GridCol>
                <GridCol size={4}>DESCRIPTION</GridCol>
                <GridCol size={4}>MANUFACTURER</GridCol>
                <GridCol>REBATE</GridCol>
                {/* <GridCol>DEVIATED PRICE</GridCol> */}
                <GridCol></GridCol>
              </GridRow>

              <div className='items-list-rows-container scrollbar-custom' ref={(ref) => this.mainRef = ref}>
                {items.length > 0 ?
                  items.map(item =>
                    <GridRow key={item.manufacturer_product_id} className='py-3 border-bottom text-size-14 line-height-16'>
                      <GridCol size={2}>{item.min}</GridCol>
                      <GridCol size={4} className='text-capitalize'>{item.description.toLowerCase()}</GridCol>
                      <GridCol size={4}>{item.manufacturer}</GridCol>
                      <GridCol>{item.rebate > 0 && item.rebate > 0 ? 'Y' : 'N'}</GridCol>
                      {/* <GridCol>{item.deviated_price > 0 ? 'Y' : 'N'}</GridCol> */}
                      <GridCol className='text-blue text-left'>
                        <span className={'clickable ' + itemIntelDetailsLinkClass} 
                          onClick={this.onClickSeeDetails.bind(this, item.manufacturer_product_id)}>
                          Details
                        </span>
                      </GridCol>
                    </GridRow>
                  )
                  :
                  filters && (filters.itemFilter !== null ||
                    filters.search !== null ||
                    filters.manufacturers !== null ||
                    filters.categories !== null ||
                    filters.types !== null
                  ) && !spinner.spinner ?
                    <GridCol className='p-5 text-center text-grey'>
                      <IconEmptyPricing /><br /><br />
                      We did not find any results, please try another search.
                    </GridCol>
                    :
                    <></>
                }
                <div
                  ref={loadingRef => (this.loadingRef = loadingRef)}
                  style={{ height: '30px', margin: '10px 0' }}
                >
                  <span style={{ display: this.state.loading ? 'block' : 'none', textAlign: 'center' }}>
                    Loading...
                  </span>
                </div>
              </div>
            </GridCol>
          </GridRow>
        }
      </>

    );
  };

}

const mapStateToProps = (state) => {
  return {
    items: state.itemIntel.items,
    scrollToSelectedItem: state.itemIntel.scrollToSelectedItem,
    filters: state.itemIntel.filters,
    page: state.itemIntel.page,
    spinner: state.spinner,
    companyId: state.company.id,
  };
};

export default connect(mapStateToProps)(ItemIntelItemsList);