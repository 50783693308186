import React from 'react';
import { Row, Col } from 'reactstrap';
import { BsArrowDown } from 'react-icons/bs';

const InvoiceScanningHeader = () => {
  return (
    <Row className='mt-4 mx-4 p-2 border rounded-2 text-size-10 line-height-12'>
      <Col>
        INVOICE NAME <BsArrowDown />
      </Col>
      <Col>
        LOCATION <BsArrowDown />
      </Col>
      <Col>
        UPLOAD DATE <BsArrowDown />
      </Col>
      <Col>
        STATUS <BsArrowDown />
      </Col>
    </Row>
  )
};

export default InvoiceScanningHeader;