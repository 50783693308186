import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import IconCircle from '../../MasterComponents/Icons/IconCircle';
import GridRow from '../../MasterComponents/Grid/GridRow';
import GridCol from '../../MasterComponents/Grid/GridCol';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import config from '../../config/config';
import  './Insights.scss';

class InsightsBodyItems extends Component {

    showMoreItems = () => {
        this.props.showMoreItems();
    }

    showLessItems = () => {
        this.props.showLessItems();
    }

    render() {
        const { dataItems, tabData } = this.props;
        const dataItemsArray = dataItems.dataItemsTop.map((c, i) => (          
            <GridRow key={i} className={'py-2 ' + (isMobile ? '': '')}>
                <GridCol className='col-1 '><IconCircle color={c.color} size={8} /></GridCol>
                <GridCol className={'text-capitalize insights-graph-text ' + (isMobile ? 'col-6' : 'col-7')} >{c.description ? (c.description).toLowerCase() : c.description}</GridCol>
                <GridCol className='col-4 text-right insights-graph-text'>${(c.y).toLocaleString('en', {minimumFractionDigits: 2})}</GridCol>
             </GridRow>
        ));

        const chartOptions = {
            chart: {
                height: '90%',
            },
            credits: {
                enabled: false
            },
            title: {
                text: ''
            },
            series: [{
                type: 'pie',
                name: 'Items',
                innerSize: '0%',
                showInLegend: false,
                data: dataItems.dataItemsTop
            }],
            tooltip: {
                valuePrefix: '$',
                backgroundColor: '#1A1A1A',
                borderColor: '#1A1A1A',
                borderRadius: 5,
                valueDecimals: 2,
                style: {
                    color: 'white'
                },
                pointFormat: ' {point.y} ({point.percentage:.0f}%)</span>',
                formatter: function () {
                    return this.key !== 'Hide' ? ' $'+this.y.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : false;
                }
            },
            plotOptions: {
                pie: {
                    shadow: false,
                    center: ['50%', '50%'],
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: false,
                }
            }
        }

        if (isMobile) {
            return (
                <GridCol>
                    <GridRow>
                        <GridCol className='pt-3 text-center insights-graph-title'>
                            Top Items
                        </GridCol>
                    </GridRow>
                    <GridRow>
                        <GridCol>
                            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                        </GridCol>
                    </GridRow>
                    <GridRow className='text-center mb-3'>
                        <GridCol>
                            <IconCircle color={tabData.isSelectedItems ? config.colors.rbBlue : config.colors.rbLightGrey} size={8} /> &nbsp;
                            <IconCircle color={tabData.isSelectedManufacturers ? config.colors.rbBlue : config.colors.rbLightGrey} size={8} />&nbsp;
                            <IconCircle color={tabData.isSelectedDistributors ? config.colors.rbBlue : config.colors.rbLightGrey} size={8} /> &nbsp;
                            <IconCircle color={tabData.isSelectedCategories ? config.colors.rbBlue : config.colors.rbLightGrey} size={8} />  
                        </GridCol>
                    </GridRow>
                    <GridRow>
                        <GridCol style={{maxHeight: '270px', overflowX:'hidden', overflowY:'auto'}} >
                            {dataItemsArray}
                        </GridCol>
                    </GridRow>
                    <GridRow className='py-3'>
                        { !dataItems.allItems && dataItems.dataItemsTop.length > 0 ?
                            <GridCol className='text-left insights-show-more clickable' onClick={this.showMoreItems}>
                                Show more <IoIosArrowDown color={config.colors.primary} fontSize='18px'/>
                            </GridCol>
                            : <GridCol/>
                        }
                        { dataItems.dataItemsTop.length > dataItems.initNumberItems ?
                            <GridCol className='text-right insights-show-more clickable' onClick={this.showLessItems}>
                                Show less <IoIosArrowUp color={config.colors.primary} fontSize='18px'/>
                            </GridCol>
                            : <GridCol/>
                        }
                    </GridRow>
                </GridCol>
            )  
        }

        return (
            <GridCol className='py-3 insights-line-right'>
                <GridRow>
                    <GridCol className='pl-5 pb-3 text-left insights-graph-title insights-line-bottom'>
                        Top Items
                    </GridCol>
                </GridRow>
                <GridRow className='pt-4'>
                    <GridCol className='col-5' style={{width: '60px', minWidth: '30%'}}>
                        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                    </GridCol>
                    <GridCol className='col-7 text-left px-0'>
                        <GridCol className='scrollbar-insights' style={{maxHeight: '320px', overflowX:'hidden', overflowY:'auto'}}>
                            {dataItemsArray}
                        </GridCol>
                        <GridRow className='p-3'>
                            { !dataItems.allItems && dataItems.dataItemsTop.length > 0 ?
                                <GridCol className='col-6 text-left insights-show-more clickable' onClick={this.showMoreItems}>
                                    Show more <IoIosArrowDown color={config.colors.primary} fontSize='18px'/>
                                </GridCol>
                                : <GridCol/>
                            }
                            { dataItems.dataItemsTop.length > 5 ?
                                <GridCol className='col-6 text-right insights-show-more clickable' onClick={this.showLessItems}>
                                    Show less <IoIosArrowUp color={config.colors.primary} fontSize='18px'/>
                                </GridCol>
                                : <GridCol/>
                            }
                        </GridRow>
                    </GridCol>
                </GridRow>
            </GridCol>
        );
    }
}

export default InsightsBodyItems;
