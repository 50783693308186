import React, { Component } from 'react';
import Modal from '../../../MasterComponents/Modals/Modal';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import { Row, Col } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import IconClose from '../../../MasterComponents/Icons/IconClose';
import { findModal } from '../../../MasterComponents/Modals/ModalsActions';
class NewCardModal extends Component {

  onClose = () => {
    const { modals } = this.props;
    findModal(modals, 'idNewCardModal').close();
  }
  
  render() {
    const { resource } = this.props;
    const styleClose = {
      position: 'absolute',
      top: isMobile ? '-30px' : '-25px',
      right: isMobile ? '0' : '-25px'
    }

    return (
      <Modal id='idNewCardModal' size='lg' centered={isMobile} classNameContent='bg-black modal-faq-mobile'>
        <div onClick={this.onClose} className='clickable' style={styleClose}><IconClose color={'white'} size={18} /></div>
        <ModalBody className='p-0'>
          <Row>
            <Col>
              { resource.type === 'video' ?
                <iframe 
                  width='100%' 
                  height={isMobile ? '300px' : '400px'} 
                  src={resource.url}
                  title={resource.title} 
                  frameBorder="0" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                  allowFullScreen>
                </iframe>
                :
                <Row>
                  <Col xs={12}>
                    <img src={resource.cover_image} alt="what's new" width='100%' height='300px' />
                  </Col>
                  <Col className='text-center text-bold py-2' xs={12}>
                    {resource.title}
                  </Col>
                  <Col className='text-center text-grey px-5 py-3' xs={12}>
                    {resource.description}
                  </Col>
                </Row>
              }
              </Col>
          </Row>
        </ModalBody>
      </Modal>
    )
  }
}

export default NewCardModal;
