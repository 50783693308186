import { apiInstance } from '../../config/tokenApi';

const getAccess = (salesRepId) => {
  return apiInstance.post('/food-cost-management/access', {
    salesRepId: salesRepId
  });
};

export default {
  getAccess
};
