import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { FaLongArrowAltRight } from 'react-icons/fa';

const JoinGradient = ({scrollTop}) => {
	return (
		<Container fluid className='join-gradient mt-5 rounded-top text-white pt-5 text-center'>
			<Container className='mw-800'>
				<Row className='pt-5 text-gradient-title align-items-center justify-content-center'>
					<Col>
						<b>Join Dining Alliance For FREE</b> And Instantly Start Saving On 165,000 Line Items
					</Col>
				</Row>
				<Row className='pt-5 pb-3'>
					<Col>
						<iframe width='100%' height='600px' src="https://www.youtube.com/embed/XxuD1sv2fD4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
					</Col>
				</Row>
				<Row className='py-3 font-weight-bold'>
					<Col>
						At the heart of the Dining Alliance program is our rebate offerings. We help restaurants earn CashBack and save on over 165,000 rebated line items. 
					</Col>
				</Row>
				<Row className='py-3'>
					<Col>
						We partner with our industries biggest and best manufacturers, such as Tyson, McCormick, McCain, Unilever, Barilla, Keurig Dr Pepper, Smuckers, to bring our members savings on products from over 350+ top manufacturers. With Dining Alliance, there is ALWAYS an opportunity for us to help you save money and earn CashBack. All for FREE.
					</Col>
				</Row>
				<Row className='py-5 text-gradient-title'>
					<Col>
						<Button color='white' className='py-3 px-4 rounded-0 align-items-center text-action mb-3' onClick={scrollTop}>
							JOIN DINING ALLIANCE FOR FREE <FaLongArrowAltRight className='ml-2' />
						</Button>
					</Col>
				</Row>
			</Container>
		</Container>
	)
};

export default JoinGradient;