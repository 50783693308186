import React, { Component } from 'react';
import { findModal } from '../../MasterComponents/Modals/ModalsActions';
import PropTypes from 'prop-types';
import Modal from '../Modals/Modal';
import ModalHeader from '../Modals/ModalHeader';
import ModalBody from '../Modals/ModalBody';
import GridRow from '../Grid/GridRow';
import GridCol from '../Grid/GridCol';
import Button from '../Button/Button';

class AlertModal extends Component {
  
  render() {
    const {
      title, message, showTwoButtons, buttonClick, buttonText, buttonLeftClick, buttonLeftText,
      buttonRightClick, buttonRightText, modals, isMobile
    } = this.props;

    const onClick = () => {
      if (buttonClick) {
        buttonClick();
      }
      findModal(modals, 'idAlertModal').close();
    };
  
    const onLeftClick = () => {
      if (buttonLeftClick) {
        buttonLeftClick();
      }
      findModal(modals, 'idAlertModal').close();
    };
  
    const onRightClick = () => {
      if (buttonRightClick) {
        buttonRightClick();
      }
      findModal(modals, 'idAlertModal').close();
    };

    return (
      <Modal id='idAlertModal' centered fullHeight={isMobile}>
        <ModalHeader noBorder>
          { title !== '' && 
            {title}
          }
        </ModalHeader>
        <ModalBody className='text-center text-size-20 line-height-20'>
        { message !== null && 
          message
        }
        {showTwoButtons ?
          <GridRow className='pt-4'>
            <GridCol>
              <Button onClick={onLeftClick} variant='white' size='md' text={buttonLeftText} />
            </GridCol>
            <GridCol>
              <Button onClick={onRightClick} variant='primary' size='md' text={buttonRightText} />
            </GridCol>
          </GridRow>
          :
          <GridRow className='pt-4'>
            <GridCol className='text-center'>
              <Button onClick={onClick} block variant='primary' size='lg' text={buttonText}></Button>
            </GridCol>
          </GridRow>
        }
        </ModalBody>
      </Modal>
    )
  }
}

AlertModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  buttonClick: PropTypes.func,
  buttonText: PropTypes.any,
  buttonLeftClick: PropTypes.func,
  buttonLeftText: PropTypes.any,
  buttonRightClick: PropTypes.func,
  buttonRightText: PropTypes.any,
  showTwoButtons: PropTypes.bool,
  isMobile: PropTypes.bool
};

AlertModal.defaultProps = {
  title: '',
  message: null,
  showTwoButtons: false,
  buttonText: 'Ok',
  buttonLeftText: 'Ok',
  buttonRightText: 'Ok',
  isMobile: false
};

export default AlertModal;
