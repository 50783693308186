import React, { Component } from 'react'
import { connect } from 'react-redux';
import Modal from '../../../MasterComponents/Modals/Modal';
import ModalHeader from '../../../MasterComponents/Modals/ModalHeader';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import DashboardSettingsChangePassword from '../DashboardSettingsChangePassword';
import DashboardSettingsUsers from '../DashboardSettingsUsers';

class ManageUserModal extends Component {

    render() {
        const { header, type, refresh, modals} = this.props;
        return (
            <Modal id='manageUserModal'>
                <ModalHeader blueHeader>{header}</ModalHeader>
                <ModalBody>
                    {type==='changePassword' ? <DashboardSettingsChangePassword modals={modals}/> : <DashboardSettingsUsers refresh={refresh} modals={modals}/>}
                </ModalBody>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        locations: state.dashboard.merchants,
        users: state.dashboard.users,
    };
}
    
export default connect(mapStateToProps)(ManageUserModal);