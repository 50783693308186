import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconHome = props => (
  <svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Distributor-Dashboard-Homepage" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Homepage" transform="translate(-37.000000, -68.000000)">
        <g id="nav" transform="translate(37.000000, 66.000000)">
          <g id="icons/home" transform="translate(0.000000, 2.000000)">
            <path d="M8.3535,0.1465 C8.158,-0.049 7.842,-0.049 7.6465,0.1465 L0.1465,7.6465 C-0.049,7.842 -0.049,8.158 0.1465,8.3535 L1.6465,9.8535 C1.842,10.049 2.158,10.049 2.3535,9.8535 L2.5,9.707 L2.5,15.5 C2.5,15.776 2.7235,16 3,16 L13,16 C13.2765,16 13.5,15.776 13.5,15.5 L13.5,9.707 L13.6465,9.8535 C13.744,9.951 13.872,10 14,10 C14.128,10 14.256,9.951 14.3535,9.8535 L15.8535,8.3535 C16.049,8.158 16.049,7.842 15.8535,7.6465 L8.3535,0.1465 Z M12.5,15 L3.5,15 L3.5,8.707 L8,4.207 L12.5,8.707 L12.5,15 Z M14,8.793 L13.354,8.147 C13.3535,8.1465 13.3535,8.1465 13.353,8.146 L8.3535,3.1465 C8.256,3.049 8.128,3 8,3 C7.872,3 7.744,3.049 7.6465,3.1465 L2.647,8.146 C2.6465,8.1465 2.6465,8.1465 2.646,8.147 L2,8.793 L1.207,8 L8,1.207 L14.793,8 L14,8.793 Z" id="Shape" fill={!props.isActive ? props.color : props.activeColor} fillRule="nonzero"></path>
            <rect id="Rectangle" stroke={!props.isActive ? props.color : props.activeColor} x="6.5" y="9.5" width="3" height="6"></rect>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

IconHome.propTypes = {
  color: PropTypes.string,
  activeColor: PropTypes.string,
  isActive: PropTypes.bool,
  size: PropTypes.number,
};

IconHome.defaultProps = {
  activeColor: config.colors.rbYellow,
  color: config.colors.rbWhite,
  isActive: false,
  size: 16,
};

export default IconHome;