import React, { Component } from 'react';
import GridContainer from '../../MasterComponents/Grid/GridContainer';
import GridRow from '../../MasterComponents/Grid/GridRow';
import ClaimCash from '../ClaimCash/ClaimCash';
import GridCol from '../../MasterComponents/Grid/GridCol';
import queryString from 'query-string';
import ClaimCashApi from '../ClaimCash/ClaimCashApi';

class Ach extends Component {
  constructor(props){
    super(props);
    this.state = {
      ready: false,
      token: '',
      firstName: null,
      lastName: null,
      email: null
    }
  };
  componentDidMount() {
    const { location, history } = this.props;
    const { search } = location;
    const qp = queryString.parse(search);
    if (!qp || !qp.token) {
      history.replace('/');
    } else {
      ClaimCashApi.achToken(qp.token).then(({data}) => {
        this.setState({
          ready: true,
          token: qp.token,
          firstName: qp.firstName || null,
          lastName: qp.lastName || null,
          email: qp.email || null
        });
      }).catch(err => {
        console.log(err);
      });
    }
  };
  render() {
    const { ready, ...qp } = this.state;
    return (
      <GridContainer isFluid={true}>
        <GridRow>
          <GridCol id='dashboard-content'>
            {
              ready && <ClaimCash isIframe {...qp} isMobile />
            }
          </GridCol>
        </GridRow>
      </GridContainer>
    )
  }
};

export default Ach;