import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../MasterComponents/Modals/Modal';
import ModalHeader from '../../../MasterComponents/Modals/ModalHeader';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import ModalFooter from '../../../MasterComponents/Modals/ModalFooter';
import Button from '../../../MasterComponents/Button/Button';
import Checkbox from '../../../MasterComponents/Checkbox/Checkbox';
import { isMobile } from 'react-device-detect';
import { findModal } from '../../../MasterComponents/Modals/ModalsActions';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../MasterComponents/Grid/GridCol';

class AddNewDistributorToModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkOnePlease: false,
      checkAll: false,
      check: this.props.locations.map((loc) => {
        return props.merchant.id === loc.id;
      })
    }
  }
  clickNext = () => {
    const { modals, locations } = this.props;
    const { check } = this.state;

    const atLeastOneCheck = check.some(c => c === true);

    if (atLeastOneCheck) {
      findModal(modals, 'addNewDistributorToModal').close();
      const merchantsToAddDistributor = locations.map((loc, i) => {
        return {
          id: loc.id,
          name: loc.nickname,
          isCheck: check[i]
        }
      }).filter(loc => loc.isCheck);
      this.props.goToAdd(merchantsToAddDistributor);
    } else {
       this.setState({
        checkOnePlease: true
      });
    }
  }
  checkHandler = (i) => {
    const { check } = this.state;

    this.setState({
      check: check.map((c, ii) => {
        if (i !== ii) return c;
        return !c
      }),
      checkOnePlease: false
    }, () => {
      const { check } = this.state;
      this.setState({
        checkAll: check.every(c => c === true)
      });
    });
  }
  checkAllHandler = (i) => {
    const {check, checkAll} = this.state;
    const newValue = !checkAll;
    this.setState({
      checkAll: newValue,
      check: check.map((item, index) => {
        return newValue
      }),
      checkOnePlease: false
    })
  }
  render() {
    const { checkOnePlease, checkAll, check } = this.state;
    const { locations } = this.props;
    const locationsMap = locations.map((loc, i) => (
      <GridRow align='center' key={loc.id}>
        <GridCol>
          <Checkbox name={'loc-'+i} className={'text-size-14' + (checkOnePlease ? ' text-red' : '')} text={loc.nickname} value={check[i]} onChange={this.checkHandler.bind(this, i)}/>
        </GridCol>
      </GridRow>
    ))
    return (
      <Modal size={isMobile ? '100' : 'sm'} id='addNewDistributorToModal'>
        <ModalHeader closeButton={!isMobile} noBorder={isMobile}>Add New Distributor To</ModalHeader>
        <ModalBody>
          <GridRow align='center'>
            {locations.length > 1 ?
            <GridCol>
              <Checkbox name='all' text='All Locations' className={'text-size-14' + (checkOnePlease ? ' text-red' : '')} value={checkAll} onChange={this.checkAllHandler}/>
            </GridCol>
            : null }
          </GridRow>
          {locationsMap}
        </ModalBody>
        <ModalFooter>
          <Button onClick={this.clickNext} variant='primary' size='lg' className='w-100' text='Next' />
        </ModalFooter>
      </Modal>
    );
  }
};

AddNewDistributorToModal.propTypes = {
  goToAdd: PropTypes.func.isRequired
}

export default AddNewDistributorToModal;