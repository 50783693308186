import React, { Component } from 'react';
import { findModal, showModal } from '../../../MasterComponents/Modals/ModalsActions';
import Modal from '../../../MasterComponents/Modals/Modal'
import ModalHeader from '../../../MasterComponents/Modals/ModalHeader'
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import InputLabel from '../../../MasterComponents/InputLabel/InputLabel';
import IconBlog from '../../../MasterComponents/Icons/IconBlog';
import IconGear from '../../../MasterComponents/Icons/IconGear';
import IconLogout from '../../../MasterComponents/Icons/IconLogout';
import IconQuestion from '../../../MasterComponents/Icons/IconQuestion';
import FaqModal from '../../Faq/Modals/FaqModal';
import IconArrowForward from '../../../MasterComponents/Icons/IconArrowForward';
import IconCart from '../../../MasterComponents/Icons/IconCart';
import IconGraph from '../../../MasterComponents/Icons/IconGraph';
import { connect } from 'react-redux';
import { getMoreModalBodyClass, getMoreModalHeaderClass, getMoreModalIconColor } from '../../../config/Company/CompanyHelper';

class MoreModal extends Component {
  itemIntel = () => {
    const { history, modals } = this.props;
    history.push('/dashboard/itemIntel');
    findModal(modals, 'idMoreModal').close();
  }
  ourBlog = () => {
    const { history, modals } = this.props;
    history.push('/dashboard/blog');
    findModal(modals, 'idMoreModal').close();
  }
  faq = () => {
    const { dispatch } = this.props;
    dispatch(showModal(FaqModal, 'idFaqModal', {header: 'FAQ & Resources'}));
  }
  foodCost = () => {
    const { history, modals } = this.props;
    history.push('/dashboard/foodCost');
    findModal(modals, 'idMoreModal').close();
  }
  settings = () => {
    const { history, modals } = this.props;
    history.push('/dashboard/settings');
    findModal(modals, 'idMoreModal').close();
  }
  logout = () => {
    const { history, modals } = this.props;
    history.push('/logout');
    findModal(modals, 'idMoreModal').close();
  }

  render() {
    const { header, companyId } = this.props;
    const iconColor = getMoreModalIconColor(companyId);

    return (
      <Modal id='idMoreModal' fullHeight={true}>
        <ModalHeader closeColor={'white'} className={getMoreModalHeaderClass(companyId)}>{header.nickname}</ModalHeader>
        <ModalBody className={getMoreModalBodyClass(companyId)}>
          <GridRow className='mt-2 border-bottom' onClick={this.itemIntel}>
            <GridCol size={10} className='form-group text-white'>
              <IconGraph color={iconColor} /> &nbsp;
              <InputLabel title text='ITEM INTEL'/>
            </GridCol>
            <GridCol size={2} className='text-right'>
              <IconArrowForward color={iconColor}/>
            </GridCol>
          </GridRow>
          <GridRow className='mt-4 border-bottom' onClick={this.ourBlog}>
            <GridCol size={10} className='form-group text-white'>
              <IconBlog color={iconColor} /> &nbsp;
              <InputLabel title text='OUR BLOG'/>
            </GridCol>
            <GridCol size={2} className='text-right'>
              <IconArrowForward color={iconColor}/>
            </GridCol>
          </GridRow>
          <GridRow className='mt-4 border-bottom' onClick={this.faq}>
            <GridCol size={10} className='form-group text-white'>
              <IconQuestion color={iconColor} /> &nbsp;
              <InputLabel title text='FAQ & RESOURCES'/>
            </GridCol>
            <GridCol size={2} className='text-right'>
              <IconArrowForward color={iconColor}/>
            </GridCol>
          </GridRow>
          <GridRow className='mt-4 border-bottom' onClick={this.foodCost}>
            <GridCol size={10} className='form-group text-white'>
              <IconCart color={iconColor} /> &nbsp;
              <InputLabel title text='FOOD COST MANAGEMENT'/>
            </GridCol>
            <GridCol size={2} className='text-right'>
              <IconArrowForward color={iconColor}/>
            </GridCol>
          </GridRow>
          <GridRow className='mt-4 border-bottom' onClick={this.settings}>
            <GridCol size={10} className='form-group text-white'>
              <IconGear color={iconColor} /> &nbsp;
              <InputLabel title text='SETTINGS'/>
            </GridCol>
            <GridCol size={2} className='text-right'>
              <IconArrowForward color={iconColor}/>
            </GridCol>
          </GridRow>
          <GridRow className='mt-4 border-bottom' onClick={this.logout}>
            <GridCol className='form-group text-white'>
              <IconLogout color={iconColor} /> &nbsp;
              <InputLabel title text='LOG OUT'/>
            </GridCol>
          </GridRow>

        </ModalBody>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyId: state.company.id,
  };
};

export default connect(mapStateToProps)(MoreModal);
