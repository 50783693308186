import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showModal } from '../../../MasterComponents/Modals/ModalsActions';
import { spinner } from '../../../MasterComponents/Spinner/SpinnerActions';
import { isMobile } from 'react-device-detect';
import AddNewDistributorToModal from '../../Dashboard/Modals/AddNewDistributorToModal';
import UploadPurchaseHistoryModal from '../../Dashboard/Modals/UploadPurchaseHistoryModal';
import EnterCredentialsModal from '../../Dashboard/Modals/EnterCredentialsModal';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import GridColSplit from '../../../MasterComponents/Grid/GridColSplit';
import DashedBox from '../../../MasterComponents/DashedBox/DashedBox';
import IconAddCircle from '../../../MasterComponents/Icons/IconAddCircle';
import IconMarker from '../../../MasterComponents/Icons/IconMarker';
import ReactGA from 'react-ga';
import DistributorCardOne from './DistributorCardOne';
import DashboardApi from '../../Dashboard/DashboardApi';
import showActivateDistributor from '../../../MasterComponents/SharedFunctions/showActivateDistributor';
import ErrorWarning from '../../../MasterComponents/ErrorWarning/ErrorWarning';
import LocationDistributorDeleteModal from '../Modals/LocationDistributorDeleteModal';
import LocationApi from '../LocationApi';
import LocationEmailToSalesRepModal from '../Modals/LocationEmailToSalesRepModal';
import { getIconMarkerColor } from '../../../config/Company/CompanyHelper';
import config from '../../../config/config';

class DistributorCardContent extends Component {
  constructor(props) {
    super(props);
    this.showActivateDistributor = showActivateDistributor.bind(this);
  }
  clickActivate = (merDist, e) => {
    e.stopPropagation();
    this.showActivateDistributor(merDist);
  };

  clickActivateEdit = (merDist, e) => {
    e.stopPropagation();
    this.showActivateDistributor(merDist, true);
  }

  clickActivateDelete = (merDist, e) => {
    e.stopPropagation();
    const { dispatch, refresh } = this.props;
    dispatch(showModal(LocationDistributorDeleteModal, 'idLocationDistributorDeleteModal', {  merDist: merDist, refresh }));
  }

  clickAddNewDistributor = () => {
    const { dispatch, merchantAll, merchant, companyId } = this.props;
    const isAdmin = localStorage.getItem('authKeyAdmin');
    ReactGA.event({
      category: 'Click Button',
      action: 'Click Add New Distributor'
    });
    if (companyId === 17 && !isAdmin) {
      // For Prime Services we are sending an email to the sales rep
      dispatch(spinner(true));
      LocationApi.sendEmailToSalesRep(companyId).then((res) => {
        dispatch(spinner(false));
        dispatch(showModal(LocationEmailToSalesRepModal, 'locationEmailToSalesRepModal', {}));
      }).catch(error => {
        dispatch(spinner(false));
        console.log(error);
      });
    }
    else {
      dispatch(showModal(AddNewDistributorToModal, 'addNewDistributorToModal', { goToAdd: this.goToAddNewDistributor, locations: merchantAll, merchant: merchant }));
    }
  }

  goToAddNewDistributor = (state) => {
    const { history } = this.props;
    ReactGA.event({
      category: 'Click Button',
      action: 'Click Add New Distributor'
    });
    history.push({
      pathname: '/dashboard/add-new-distributor',
      state: state
    });
  }

  goToEditDistributor = (merDist) => {
    const { history } = this.props;
    history.push(`/dashboard/merchant-distributor/${merDist.id}`, { merchantDistributor: merDist })
  }

  clickEditCredentials = (merDist, e) => {
    e.stopPropagation();
    const { dispatch } = this.props;

    dispatch(showModal(EnterCredentialsModal, 'enterCredentialsModal', {
      merchantDistributor: merDist,
      saveCredentials: this.saveCredentials,
      verifyCredentials: this.verifyCredentials,
      isEdit: true,
      dcns: [merDist.dcn]
    }));
  }

  verifyCredentials = (distributorId, merchantId, username, password, dcns, cb) => {
    const { dispatch } = this.props;
    dispatch(spinner(true));
    DashboardApi.verifyCreds( distributorId, merchantId, username, password, dcns).then(({data}) => {
      dispatch(spinner(false));
      cb(data);
    }).catch(({data}) => {
      dispatch(spinner(false));
      cb(data);
    });
  };
  
  saveCredentials = (merchantDistributorId, username, password) => {
    const { dispatch, refresh } = this.props;
    const params = {
      username: username,
      password: password
    }
    dispatch(spinner(true));
    DashboardApi.editCredentials(merchantDistributorId, params).then(({data}) => {
      dispatch(spinner(false));
      refresh();
    }).catch((err) => {
      dispatch(spinner(false));
      dispatch(showModal(ErrorWarning, 'errorWarningModal', {message: "Failed to save credentials."}));
    });
  };

  clickUploadNow = (merDist, e) => {
    e.stopPropagation();
    const { dispatch, refresh } = this.props;
    ReactGA.event({
      category: 'Click Button',
      action: 'Click Upload Now'
    });
    dispatch(showModal(UploadPurchaseHistoryModal, 'uploadPurchaseHistoryModal', { merchantDistributor: merDist, refresh: refresh }));
  };

  render() {
    const { locationName, companyColors, dispatch, companyId, merchantDistributors, locationAddress } = this.props;
    const iconMarkerColor = getIconMarkerColor(companyId);
    const propsLocation = {
      locationName,
      dispatch,
      companyColors,
      companyId
    }
    
    const distributorsMap = merchantDistributors.map((merDist, i) =>  {
      return <DistributorCardOne key={`mercard_${i}`} {...propsLocation}
        merchantDistributor={merDist}
        onClickActivate={this.clickActivate.bind(this, merDist)}
        clickActivateEdit={this.clickActivateEdit.bind(this, merDist)}
        clickActivateDelete={this.clickActivateDelete.bind(this, merDist)}
        goToEditDistributor={this.goToEditDistributor.bind(this, merDist)}
        onClickEditCredentials={this.clickEditCredentials.bind(this, merDist)}
        onClickUploadNow={this.clickUploadNow.bind(this, merDist)}
      />;
    });

    return (
      <GridRow className={(isMobile ? 'pl-0 px-4' : 'px-4') + ' pb-5 align-items-center'}>
        <GridCol className={'text-size-24 text-line-height-22 text-grey mw-fc ' + (isMobile ? 'px-0' : 'pr-0')}>
          <span onClick={this.clickManageLocations}><IconMarker color={iconMarkerColor} size={25}/></span>
        </GridCol>
        <GridCol className={'text-line-height-22 text-grey ' + (isMobile ? 'pl-0 py-3': 'py-2')}>
          <GridRow>
            <GridCol className={'text-size-24'}>
              <span onClick={this.clickManageLocations}>{locationName}</span>
            </GridCol>
          </GridRow>
          <GridRow>
            <GridCol className={'text-size-16'}>
              <span onClick={this.clickManageLocations}>{locationAddress}</span>
            </GridCol>
          </GridRow>
        </GridCol>
        <GridColSplit />
        { merchantDistributors ? distributorsMap : null }
        <GridCol className='card-rb-size py-2 distributor-parent-card px-2'>
          <DashedBox onClick={this.clickAddNewDistributor} height='415px' className='m-0 clickable' textClassName='text-size-14'>
            <div style={{marginBottom:'40px'}} id='add-distributor-icon'><IconAddCircle size={45} color={config.colors.rbLightGrey}/></div>
            <div style={{fontSize: '2em', color: config.colors.rbLightGrey}}>Add New Distributor</div>
          </DashedBox>
        </GridCol>
      </GridRow>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyBrand: state.company.companyBrand,
    companyId: state.company.id,
    manufacturerAd: state.location.manufacturerAd,
    activeMerchant: state.dashboard.activeMerchant,
    merchantAll: state.location.merchantFilter,
  };
};

export default connect(mapStateToProps)(DistributorCardContent);
