export const claimCashSetDataLookUp = (data) => {
  return {
    type: 'CLAIM_CASH_SET_DATA_LOOK_UP',
    payload: data
  }
};

export const claimCashSetCurrentPayment = (data) => {
  return {
    type: 'CLAIM_CASH_SET_CURRENT_PAYMENT',
    payload: data
  }
};

export const claimCashSetStep = (data) => {
  return {
    type: 'CLAIM_CASH_SET_STEP',
    payload: data
  }
};

export const claimCashStep1Input = (data) => {
  return {
    type: 'CLAIM_CASH_STEP1_INPUT',
    payload: data
  }
};

export const claimCashStep3Input = (data) => {
  return {
    type: 'CLAIM_CASH_STEP3_INPUT',
    payload: data
  }
};

export const claimCashSetDataDetail = (data) => {
  return {
    type: 'CLAIM_CASH_SET_DATA_DETAIL',
    payload: data
  }
};

export const claimCashReset = () => {
  return {
    type: 'CLAIM_CASH_RESET',
    payload: ''
  }
};
