import React, { Component } from 'react';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../MasterComponents/Grid/GridRow';

export class DistributorListHeader extends Component {
  render() {
    return (
      <GridRow className='px-4 py-2 border-top text-size-10 line-height-11 text-grey'>
        <GridCol size={2}>
          DISTRIBUTOR NAME
        </GridCol>
        <GridCol size={1}>
          DCN
        </GridCol>
        <GridCol size={1}>
          SUBMISSION TYPE
        </GridCol>
        <GridCol size={2}>
          NEXT SUBMISSION DEADLINE
        </GridCol>
        <GridCol size={2}>
          LAST PROOF OF PURCHASE SUBMISSION
        </GridCol>
        <GridCol size={4}>
          STATUS
        </GridCol>
      </GridRow>
    )
  }
}

export default DistributorListHeader;
