import { apiInstance } from '../../config/tokenApi';

const getReports = (companyId, merchantIds) => {
  const params = {
    companyId,
    merchantIds
  };
  return apiInstance.post('report/all', params);
};

const saveSubscribe = (email, merchantId, companyId) => {
  const params = {
    email,
    merchantId,
    companyId,
  };
  return apiInstance.post('report/subscribe', params);
};

export default {
  getReports,
  saveSubscribe
}
