/**
 * Please follow this naming convention when creating a new function
 *          get[section/module][element][type]
 * Example: getTopbarTextColor
 * - section: Topbar
 * - element: Text
 * - type: Color
 *
 */

import { isMobile, isMobileOnly } from "react-device-detect";
import config from "../config";

/***********************************************************
 *  FONTS
***********************************************************/
export const getFonts = (companyId) => {
  const companyIdFonts = {
    1: {
      body: 'Roboto',
      light: 'Roboto-Light',
      medium: 'Roboto-Medium',
      bold: 'Roboto-Bold'
    },
    2: {
      body: 'Roboto',
      light: 'Roboto-Light',
      medium: 'Roboto-Medium',
      bold: 'Roboto-Bold'
    },
    3: {
      body: 'Futura-Book',
      light: 'Futura-Light',
      medium: 'Futura-Medium',
      bold: 'Futura-Bold'
    },
    4: {
      body: 'Raleway-Medium',
      light: 'Raleway-Light',
      medium: 'Raleway-Medium',
      bold: 'Raleway-Bold'
    },
    12: {
      body: 'Raleway-Regular',
      light: 'Raleway-Light',
      medium: 'Raleway-Medium',
      bold: 'Raleway-Bold'
    },
    19: {
      body: 'Roboto',
      light: 'Roboto-Light',
      medium: 'Roboto-Medium',
      bold: 'Roboto-Bold'
    },
    default: {
      body: 'Roboto',
      light: 'Roboto-Light',
      medium: 'Roboto-Medium',
      bold: 'Roboto-Bold'
    }
  }
  return companyIdFonts[companyId] || companyIdFonts['default'];
};

export const getFontStyleSheet = (companyId) => {
  const companyIdFontStyleSheet = {
    1: 'roboto',
    2: 'roboto',
    3: 'futura',
    4: 'raleway',
    12: 'raleway',
    19: 'roboto',
    default: 'roboto'
  }
  return companyIdFontStyleSheet[companyId] || companyIdFontStyleSheet['default'];
};

/***********************************************************
 *  BORDER
***********************************************************/
export const getBorderBottom = (companyId) => {
  const companyIdBorderBottom = {
    1: 'none',
    2: 'none',
    3: `solid 4px ${config.colors.primary}`,
    4: 'none',
    12: 'none',
    19: 'none',
    default: 'none'
  }
  return companyIdBorderBottom[companyId] || companyIdBorderBottom['default'];
};

/***********************************************************
 *  TOPBAR DASHBOARD
***********************************************************/

// Previously on DB: topbar ('topbar')
export const getTopBarColor = (companyId) => {
  const companyIdTopBarColor = {
    1: config.colors.tertiary,
    2: '#23408f',
    3: config.colors.primary,
    4: '#007398',
    12: config.colors.secondary,
    19: config.colors.tertiary,
    default: '#23408f'
  }
  return companyIdTopBarColor[companyId] || companyIdTopBarColor['default'];
};

// Previously on DB: dashboardTopbar ('dashboard topbar')
export const getDashboardTopBarColor = (companyId) => {
  const companyIdDashboardTopBarColor = {
    1: '#FFFFFF',
    2: '#23408f',
    3: '#FFFFFF',
    4: '#FFFFFF',
    12: '#FFFFFF',
    19: '#FFFFFF',
    default: '#23408f'
  }
  return companyIdDashboardTopBarColor[companyId] || companyIdDashboardTopBarColor['default'];
};

// Previously on DB: dashboardTopbarLink ('dashboard topbar link')
export const getDashboardTopBarLinkColor = (companyId) => {
  const companyIdDashboardTopBarLinkColor = {
    1: config.colors.secondary,
    2: '#23408f',
    3: '#FFFFFF',
    4: '#FFFFFF',
    12: '#FFFFFF',
    19: config.colors.secondary,
    default: '#fa9016'
  }
  return companyIdDashboardTopBarLinkColor[companyId] || companyIdDashboardTopBarLinkColor['default'];
};

export const getTopbarStyle = (companyId) => {
  const companyIdTopbarStyle = {
    1: {
      backgroundColor: config.colors.rbWhite,
      color: config.colors.rbBlack,
      borderTop: 'solid 0.5px rgba(173, 164, 164, 0.3)',
      borderBottom: 'solid 0.5px rgba(173, 164, 164, 0.3)'
    },
    2: {
      backgroundColor: config.colors.rbDarkBlue,
      color: config.colors.rbWhite,
      borderTop: 'solid 0.5px rgba(173, 164, 164, 0.3)',
      borderBottom: 'solid 0.5px rgba(173, 164, 164, 0.3)'
    },
    3: {
      backgroundColor: config.colors.rbWhite,
      color: config.colors.rbBlack,
      borderTop: 'solid 0.5px rgba(173, 164, 164, 0.3)',
      borderBottom: 'solid 0.5px rgba(173, 164, 164, 0.3)'
    },
    4: {
      backgroundColor: config.colors.rbWhite,
      color: config.colors.rbBlack,
      borderTop: 'solid 0.5px rgba(173, 164, 164, 0.3)',
      borderBottom: 'solid 0.5px rgba(173, 164, 164, 0.3)'
    },
    12: {
      backgroundColor: config.colors.rbWhite,
      color: config.colors.rbBlack,
      borderTop: 'solid 0.5px rgba(173, 164, 164, 0.3)',
      borderBottom: 'solid 0.5px rgba(173, 164, 164, 0.3)'
    },
    19: {
      backgroundColor: config.colors.rbWhite,
      color: config.colors.rbBlack,
      borderTop: 'solid 0.5px rgba(173, 164, 164, 0.3)',
      borderBottom: 'solid 0.5px rgba(173, 164, 164, 0.3)'
    },
    default: {
      backgroundColor: config.colors.rbDarkBlue,
      color: config.colors.rbWhite,
      borderTop: `solid 0.5px ${config.colors.rbDarkBlue}`,
      borderBottom: `solid 0.5px ${config.colors.rbDarkBlue}`,
    }
  }
  return companyIdTopbarStyle[companyId] || companyIdTopbarStyle['default'];
};


export const getTopbarClass = (companyId) => {
  const companyIdTopbarLink = {
    1: 'text-size-12 text-uppercase font-family-medium',
    2: 'text-size-12 text-uppercase font-family-medium',
    3: 'text-size-12 text-uppercase bg-white font-family-medium text-bold',
    4: 'text-size-14 line-height-17 text-capitalize font-weight-bold',
    12: 'text-size-14 line-height-17 text-uppercase font-weight-bold',
    19: 'text-size-12 text-uppercase font-family-medium',
    default: 'text-size-12 text-uppercase'
  }
  return companyIdTopbarLink[companyId] || companyIdTopbarLink['default'];
};

export const getTopbarTextColor = (companyId) => {
  const companyIdTopbarText = {
    1: '',
    2: '',
    3: 'text-dark',
    4: 'text-primary',
    12: 'text-primary',
    19: '',
    default: 'text-white'
  }
  return companyIdTopbarText[companyId] || companyIdTopbarText['default'];
};

export const getTopbarBackgroundColor = (companyId) => {
  const companyIdTopbarBackground = {
    1: 'bg-secondary',
    2: 'bg-secondary',
    3: 'bg-white',
    4: 'bg-white',
    12: 'bg-white',
    19: 'bg-secondary',
    default: 'bg-secondary'
  }
  return companyIdTopbarBackground[companyId] || companyIdTopbarBackground['default'];
};

export const getTopBarLinkIconColor = (companyId) => {
  const companyIdTopBarLinkIconColor = {
    1: config.colors.rbBlack,
    2: config.colors.rbWhite,
    3: config.colors.secondary,
    4: isMobile ? config.colors.rbBlack : 'none',
    12: isMobile ? config.colors.rbBlack : 'none',
    19: config.colors.rbBlack,
    default: config.colors.rbWhite
  }
  return companyIdTopBarLinkIconColor[companyId] || companyIdTopBarLinkIconColor['default'];
};

export const getTopBarLinkActiveColor = (companyId) => {
  const companyIdTopBarLinkActiveColor = {
    1: config.colors.rbWhite,
    2: config.colors.rbWhite,
    3: config.colors.rbBlack,
    4: config.colors.rbBlack,
    12: config.colors.primary,
    19: config.colors.rbWhite,
    default: config.colors.rbWhite
  }
  return companyIdTopBarLinkActiveColor[companyId] || companyIdTopBarLinkActiveColor['default'];
};

export const getTopBarHamburguerIconColor = (companyId) => {
  const TopBarHamburguerIconColor = {
    1: config.colors.secondary,
    2: config.colors.rbWhite,
    3: config.colors.secondary,
    4: config.colors.primary,
    12: config.colors.primary,
    19: config.colors.rbWhite,
    default: config.colors.rbWhite
  }
  return TopBarHamburguerIconColor[companyId] || TopBarHamburguerIconColor['default'];
};

export const getTopBarDropdownIconColor = (companyId) => {
  const companyIdTopBarDropdownIconColor = {
    1: config.colors.rbBlack,
    2: config.colors.rbWhite,
    3: config.colors.secondary,
    4: config.colors.rbBlack,
    12: config.colors.rbBlack,
    19: config.colors.rbBlack,
    default: config.colors.rbWhite
  }
  return companyIdTopBarDropdownIconColor[companyId] || companyIdTopBarDropdownIconColor['default'];
};

export const getTopBarHasIcon = (companyId) => {
  const companyIdHasIcon = {
    1: isMobile ? true : true,
    2: isMobile ? true : true,
    3: isMobile ? true : true,
    4: isMobile ? true : false,
    12: isMobile ? true : false,
    19: isMobile ? true : true,
    default: true
  }
  return companyIdHasIcon[companyId] !== undefined ? companyIdHasIcon[companyId] : companyIdHasIcon['default'];
};

/***********************************************************
 *  DASHBOARD FOOTER
***********************************************************/

// Previously on DB: dashboardFooter ('dashboard footer')
export const getDashboardFooterColor = (companyId) => {
  const companyIdDashboardFooterColor = {
    1: '#FFFFFF',
    2: '#23408f',
    3: config.colors.secondary,
    4: '#007398',
    12: config.colors.secondary,
    19: '#FFFFFF',
    default: '#23408f'
  }
  return companyIdDashboardFooterColor[companyId] || companyIdDashboardFooterColor['default'];
};

export const getDashboardFooterLogoStyle = (companyId) => {
  const companyIdStyles = {
    1: {
      maxWidth: '200px',
      maxHeight: '65px'
    },
    2: {
      maxWidth: '200px',
      maxHeight: '65px'
    },
    3: {
      maxWidth: '200px',
      maxHeight: '65px'
    },
    4: {
      maxWidth: '200px',
      maxHeight: '65px'
    },
    11: {
      maxWidth: isMobileOnly ? '300px' : '600px',
      maxHeight: '65px'
    },
    12: {
      maxWidth: '200px',
      maxHeight: '65px'
    },
    19: {
      maxWidth: '200px',
      maxHeight: '65px'
    },
    default: {
      maxWidth: '200px',
      maxHeight: '65px'
    }
  }
  return companyIdStyles[companyId] || companyIdStyles['default'];
};

export const getFooterSocialIconColor = (companyId) => {
  const companyIdFooterSocialIconColor = {
    1: config.colors.rbGrey,
    2: config.colors.rbWhite,
    3: config.colors.rbWhite,
    4: config.colors.rbWhite,
    12: config.colors.rbWhite,
    19: config.colors.rbGrey,
    default: config.colors.rbWhite
  }
  return companyIdFooterSocialIconColor[companyId] || companyIdFooterSocialIconColor['default'];
};

export const getFooterCopyrightColor = (companyId) => {
  const companyIdFooterCopyrightColor = {
    1: config.colors.rbGrey,
    2: config.colors.rbDarkAqua,
    3: config.colors.rbDarkAqua,
    4: config.colors.rbDarkAqua,
    12: config.colors.rbDarkAqua,
    19: config.colors.rbGrey,
    default: config.colors.rbDarkAqua
  }
  return companyIdFooterCopyrightColor[companyId] || companyIdFooterCopyrightColor['default'];
};


/***********************************************************
 *  BACKGROUND
***********************************************************/
export const getBgImageMain = (companyId) => {
  const companyIdBgImage = {
    1: 'https://rebate-portal.s3.amazonaws.com/resources/common/main-bg-da.png',
    2: 'https://rebate-portal.s3.amazonaws.com/resources/common/main-bg-da.png',
    3: 'https://rebate-portal.s3.amazonaws.com/resources/common/main-bg-da.png',
    4: 'https://rebate-portal.s3.amazonaws.com/resources/common/main-bg-s1p.png',
    12: 'https://rebate-portal.s3.amazonaws.com/resources/common/main-bg-bw.png',
    19: 'https://rebate-portal.s3.amazonaws.com/resources/common/main-bg-da.png',
    default: 'https://rebate-portal.s3.amazonaws.com/resources/common/main-bg-da.png'
  }
  return companyIdBgImage[companyId] || companyIdBgImage['default'];
};

export const getBgImageLeft = (companyId) => {
  const companyIdBgImage = {
    1: 'https://rebate-portal.s3.amazonaws.com/resources/common/left-bg-da.png',
    2: 'https://rebate-portal.s3.amazonaws.com/resources/common/left-bg-da.png',
    3: 'https://rebate-portal.s3.amazonaws.com/resources/common/left-bg-da.png',
    4: 'https://rebate-portal.s3.amazonaws.com/resources/common/left-bg-s1p.png',
    12: 'https://rebate-portal.s3.amazonaws.com/resources/common/left-bg-bw.png',
    19: 'https://rebate-portal.s3.amazonaws.com/resources/common/left-bg-da.png',
    default: 'https://rebate-portal.s3.amazonaws.com/resources/common/left-bg-da.png'
  }
  return companyIdBgImage[companyId] || companyIdBgImage['default'];
};

export const getBgColor = (companyId) => {
  const companyIdBgColor = {
    1: 'bg-white',
    2: 'bg-white',
    3: 'bg-white',
    4: 'bg-white',
    12: 'bg-white',
    19: 'bg-white',
    default: 'bg-grey-home'
  }
  return companyIdBgColor[companyId] || companyIdBgColor['default'];
}

export const getBottomBarStyles = (companyId) => {
  const companyIdBottomBarStyles = {
    1: {
      backgroundColor: config.colors.rbWhite,
      borderTop: '1px solid rgba(173, 164, 164, 0.5)',
      color: config.colors.rbBlack
    },
    2: {
      backgroundColor: config.colors.rbDarkBlue,
      borderTop: '1px solid rgba(255, 255, 255, 0.5)',
      color: config.colors.rbWhite
    },
    3: {
      backgroundColor: config.colors.rbWhite,
      borderTop: 'solid 0.5px rgba(173, 164, 164, 0.5)'
    },
    4: {
      backgroundColor: config.colors.rbWhite,
      borderTop: 'solid 0.5px rgba(173, 164, 164, 0.5)'
    },
    12: {
      backgroundColor: config.colors.rbWhite,
      borderTop: 'solid 0.5px rgba(173, 164, 164, 0.5)'
    },
    19: {
      backgroundColor: config.colors.rbWhite,
      borderTop: '1px solid rgba(173, 164, 164, 0.5)',
      color: config.colors.rbBlack
    },
    default: {
      backgroundColor: config.colors.rbDarkBlue,
      borderTop: '1px solid rgba(255, 255, 255, 0.5)',
      color: config.colors.rbWhite
    }
  }
  return companyIdBottomBarStyles[companyId] || companyIdBottomBarStyles['default'];
};

/***********************************************************
 *  BUTTONS
***********************************************************/

// Previously on DB: mainButton ('main button')
export const getMainButtonColor = function (companyId) {
  const companyIdMainButtonColor = {
    1: config.colors.primary,
    2: config.colors.primary,
    3: config.colors.primary,
    4: config.colors.primary,
    12: config.colors.rbWhite,
    19: config.colors.primary,
    default: config.colors.primary
  }
  return companyIdMainButtonColor[companyId] || companyIdMainButtonColor['default'];
};

// Previously on DB: mainButtonBorder ('main button border')
export const getMainButtonBorderColor = function (companyId) {
  const companyIdMainButtonBorderColor = {
    1: '#006dba',
    2: '#006dba',
    3: config.colors.primary,
    4: config.colors.primary,
    12: config.colors.primary,
    19: '#006dba',
    default: '#006dba'
  }
  return companyIdMainButtonBorderColor[companyId] || companyIdMainButtonBorderColor['default'];
};

export const getBtnBorderRadius = (companyId) => {
  const companyIdRadius = {
    1: '0px',
    2: '0px',
    3: '0px',
    4: '25px',
    12: '25px',
    19: '0px',
    default: ''
  }
  return companyIdRadius[companyId] || companyIdRadius['default'];
};

export const getPrimaryButtonTextColor = function (companyId) {
  const companyIdPrimaryButtonTextColor = {
    1: config.colors.rbWhite,
    2: config.colors.rbWhite,
    3: config.colors.rbWhite,
    4: config.colors.rbWhite,
    12: config.colors.primary,
    19: config.colors.rbWhite,
    default: config.colors.rbWhite
  }
  return companyIdPrimaryButtonTextColor[companyId] || companyIdPrimaryButtonTextColor['default'];
};

export const getPrimaryButtonBorderWidth = function (companyId) {
  const companyIdPrimaryButtonBorderWidth = {
    1: '1px',
    2: '1px',
    3: '1px',
    4: '1px',
    12: '2px',
    19: '1px',
    default: '1px'
  }
  return companyIdPrimaryButtonBorderWidth[companyId] || companyIdPrimaryButtonBorderWidth['default'];
};

export const getPrimaryButtonFontWeight = function (companyId) {
  const companyIdPrimaryButtonFontWeight = {
    1: '',
    2: '',
    3: '',
    4: '',
    12: '600',
    19: '',
    default: ''
  }
  return companyIdPrimaryButtonFontWeight[companyId] || companyIdPrimaryButtonFontWeight['default'];
};

/***********************************************************
 *  NOTIFICATION
***********************************************************/
export const getNotificationStyles = (companyId) => {
  const companyIdNotificationStyles = {
    1: {
      backgroundColor: config.colors.rbYellow,
      color: config.colors.rbWhite
    },
    2: {
      backgroundColor: config.colors.rbYellow,
      color: config.colors.rbWhite
    },
    3: {
      backgroundColor: config.colors.primary,
      color: config.colors.rbWhite
    },
    4: {
      backgroundColor: config.colors.primary,
      color: config.colors.rbWhite
    },
    12: {
      backgroundColor: config.colors.primary,
      color: config.colors.rbWhite
    },
    19: {
      backgroundColor: config.colors.rbYellow,
      color: config.colors.rbWhite
    },
    default: {
      backgroundColor: config.colors.rbYellow,
      color: config.colors.rbWhite
    }
  }
  return companyIdNotificationStyles[companyId] || companyIdNotificationStyles['default'];
};


/***********************************************************
 *  ICONS
***********************************************************/
export const getIconBellColor = (companyId) => {
  const companyIdBellColor = {
    1: config.colors.rbBlack,
    2: config.colors.rbWhite,
    3: config.colors.rbBlack,
    4: config.colors.rbBlack,
    12: config.colors.rbBlack,
    19: config.colors.rbBlack,
    default: '#FFFFFF'
  }
  return companyIdBellColor[companyId] || companyIdBellColor['default'];
};

export const getIconMarkerColor = (companyId) => {
  const companyIdIconMarker = {
    1: config.colors.rbYellow,
    2: config.colors.rbYellow,
    3: config.colors.secondary,
    4: '#710098',
    12: config.colors.secondary,
    19: config.colors.rbYellow,
    default: config.colors.rbYellow
  }
  return companyIdIconMarker[companyId] || companyIdIconMarker['default'];
};

export const getIconInformationColor = (companyId) => {
  const companyIdIconInformation = {
    1: config.colors.primary,
    2: config.colors.primary,
    3: config.colors.primary,
    4: '#030925',
    12: config.colors.secondary,
    19: config.colors.primary,
    default: config.colors.rbBlue
  }
  return companyIdIconInformation[companyId] || companyIdIconInformation['default'];
};

export const getIconListColor = (companyId) => {
  const companyIdList = {
    1: config.colors.rbYellow,
    2: config.colors.rbYellow,
    3: config.colors.primary,
    4: '#030925',
    12: config.colors.primary,
    19: config.colors.rbYellow,
    default: config.colors.primary
  }
  return companyIdList[companyId] || companyIdList['default'];
};

export const getIconHamburgerColor = (companyId) => {
  const companyIdHamburger = {
    1: config.colors.rbYellow,
    2: config.colors.rbYellow,
    3: config.colors.primary,
    4: '#030925',
    12: config.colors.primary,
    19: config.colors.rbYellow,
    default: config.colors.primary
  }
  return companyIdHamburger[companyId] || companyIdHamburger['default'];
};

export const getMoreModalHeaderClass = (companyId) => {
  const companyIdMoreModalHeaderClass = {
    1: 'bg-primary text-light',
    2: 'bg-primary text-light',
    3: 'bg-primary text-light',
    4: 'bg-primary text-light',
    12: 'bg-primary text-light',
    19: 'bg-primary text-light',
    default: 'bg-primary text-light'
  }
  return companyIdMoreModalHeaderClass[companyId] || companyIdMoreModalHeaderClass['default'];
};

export const getMoreModalBodyClass = (companyId) => {
  const companyIdMoreModalBodyClass = {
    1: 'bg-primary',
    2: 'bg-primary',
    3: 'bg-primary',
    4: 'bg-primary',
    12: 'bg-primary',
    19: 'bg-primary',
    default: 'bg-primary'
  }
  return companyIdMoreModalBodyClass[companyId] || companyIdMoreModalBodyClass['default'];
};

export const getMoreModalIconColor = (companyId) => {
  const companyIdMoreModalIconColor = {
    1: config.colors.rbWhite,
    2: config.colors.rbWhite,
    3: config.colors.rbWhite,
    4: config.colors.rbWhite,
    12: config.colors.rbWhite,
    19: config.colors.rbWhite,
    default: config.colors.rbWhite
  }
  return companyIdMoreModalIconColor[companyId] || companyIdMoreModalIconColor['default'];
};

export const getNotificationModalHeaderClass = function (companyId) {
  const companyIdNotificationModalHeaderClass = {
    1: '',
    2: '',
    3: 'bg-primary',
    4: 'bg-primary',
    12: '',
    19: '',
    default: ''
  }
  return companyIdNotificationModalHeaderClass[companyId] || companyIdNotificationModalHeaderClass['default'];
};

/***********************************************************
 *  TEXT
***********************************************************/
export const getTextColor = function (companyId) {
  const companyIdTextColor = {
    1: 'text-black text-bold text-uppercase',
    2: '',
    3: 'text-secondary text-bold text-lowercase',
    4: 'text-black text-bold text-lowercase',
    12: 'text-black text-bold',
    19: 'text-black text-bold text-uppercase',
    default: 'text-white text-bold text-uppercase'
  }
  return companyIdTextColor[companyId] || companyIdTextColor['default'];
};

export const getTextLinkColor = function (companyId) {
  const companyIdTextLink = {
    1: config.colors.rbBlue,
    2: config.colors.rbBlue,
    3: config.colors.primary,
    4: config.colors.tertiary,
    12: config.colors.primary,
    19: config.colors.rbBlue,
    default: config.colors.rbBlue
  }
  return companyIdTextLink[companyId] || companyIdTextLink['default'];
};

export const getBtnColor = function (companyId) {
  const companyIdBotonColor = {
    1: config.colors.primary,
    2: '#030925',
    3: config.colors.secondary,
    4: '#030925',
    12: config.colors.primary,
    19: config.colors.primary,
    default: config.colors.primary
  }
  return companyIdBotonColor[companyId] || companyIdBotonColor['default'];
};

export const getStepActiveColor = function (companyId) {
  const companyIdStepColor = {
    1: config.colors.primary,
    2: config.colors.primary,
    3: config.colors.primary,
    4: '#710098',
    12: config.colors.primary,
    19: config.colors.primary,
    default: '#0071CE'
  }
  return companyIdStepColor[companyId] || companyIdStepColor['default'];
};

/***********************************************************
 *  LANDING PAGE
***********************************************************/

export const getLandingPageButtonVariant = function (companyId) {
  const companyIdLandingPageButtonVariant = {
    1: 'landing-page',
    2: 'landing-page',
    3: 'landing-page',
    4: 'landing-page-rounded',
    12: 'landing-page-rounded',
    19: 'landing-page',
    default: 'landing-page'
  }
  return companyIdLandingPageButtonVariant[companyId] || companyIdLandingPageButtonVariant['default'];
};

/***********************************************************
 *  SIGNUP SIMPLE
***********************************************************/

export const getSignUpSimpleHeaderButtonClass = function (companyId) {
  const companyIdSignUpSimpleHeaderButtonClass = {
    1: 'yellow-outline',
    2: 'yellow-outline',
    3: 'white',
    4: 'white',
    12: 'yellow-outline',
    19: 'yellow-outline',
    default: 'yellow-outline'
  }
  return companyIdSignUpSimpleHeaderButtonClass[companyId] || companyIdSignUpSimpleHeaderButtonClass['default'];
};

/***********************************************************
 *  HOME
***********************************************************/

export const getHomeLocationsMarkerIconColor = (companyId) => {
  const companyIdHomeLocationsMarkerIconColor = {
    1: config.colors.rbBlue,
    2: config.colors.rbBlue,
    3: '',
    4: config.colors.tertiary,
    12: config.colors.primary,
    19: config.colors.rbBlue,
    default: config.colors.rbBlue
  }
  return companyIdHomeLocationsMarkerIconColor[companyId] || companyIdHomeLocationsMarkerIconColor['default'];
};

export const getHomeLocationsMarkerIconBgStyle = (companyId) => {
  const companyIdHomeLocationsMarkerIconBgStyle = {
    1: 'rgba(0, 113, 206, 0.1)',
    2: 'rgba(0, 113, 206, 0.1)',
    3: 'rgba(0, 113, 206, 0.1)',
    4: 'rgba(113, 0, 152, 0.1)',
    12: 'rgba(237, 243, 235, 0.9)',
    19: 'rgba(0, 113, 206, 0.1)',
    default: 'rgba(0, 113, 206, 0.1)'
  }
  return companyIdHomeLocationsMarkerIconBgStyle[companyId] || companyIdHomeLocationsMarkerIconBgStyle['default'];
};

export const getHomeLocationsTruckIconColor = (companyId) => {
  const companyIdHomeLocationsTruckIconColor = {
    1: config.colors.rbYellow,
    2: config.colors.rbYellow,
    3: '',
    4: config.colors.secondary,
    12: config.colors.secondary,
    19: config.colors.rbYellow,
    default: config.colors.rbYellow
  }
  return companyIdHomeLocationsTruckIconColor[companyId] || companyIdHomeLocationsTruckIconColor['default'];
};

export const getHomeLocationsTruckIconBgStyle = (companyId) => {
  const companyIdHomeLocationsTruckIconBgStyle = {
    1: 'rgba(250, 144, 22, 0.1)',
    2: 'rgba(250, 144, 22, 0.1)',
    3: 'rgba(250, 144, 22, 0.1)',
    4: 'rgba(0, 115, 152, 0.1)',
    12: 'rgba(234, 234, 241, 0.9)',
    19: 'rgba(250, 144, 22, 0.1)',
    default: 'rgba(250, 144, 22, 0.1)'
  }
  return companyIdHomeLocationsTruckIconBgStyle[companyId] || companyIdHomeLocationsTruckIconBgStyle['default'];
};

export const getHomeInsightsGraphTextColor = (companyId) => {
  const companyIdHomeInsightsGraphTextColor = {
    1: config.colors.rbYellow,
    2: config.colors.rbYellow,
    3: config.colors.rbYellow,
    4: config.colors.primary,
    12: config.colors.primary,
    19: config.colors.rbYellow,
    default: config.colors.rbYellow
  }
  return companyIdHomeInsightsGraphTextColor[companyId] || companyIdHomeInsightsGraphTextColor['default'];
};

/***********************************************************
 *  BROWSER EXTENSION BANNER
***********************************************************/

export const getDisplayBrowserExtensionBanner = (companyId) => {
  const displayBrowserExtensionBanner = {
    1: true,
    2: true,
    4: true,
    5: true,
    11: true,
    12: true,
    16: true,
    18: false,
    default: false
  }
  return displayBrowserExtensionBanner[companyId] !== undefined ? displayBrowserExtensionBanner[companyId] : displayBrowserExtensionBanner['default'];
};

export const getBrowserExtensionBannerHeading = (companyId) => {
  const browserExtensionBannerHeading = {
    1: 'See rebated items as you shop!',
    2: 'Instantly earn rebates on all your online purchases',
    4: 'Instantly earn rebates on all your online purchases',
    5: 'Instantly earn rebates on all your online purchases',
    11: 'Instantly earn rebates on all your online purchases',
    12: 'Instantly earn rebates on all your online purchases',
    16: 'Instantly earn rebates on all your online purchases',
    18: 'Instantly earn rebates on all your online purchases',
    default: 'Instantly earn rebates on all your online purchases'
  }
  return browserExtensionBannerHeading[companyId] || browserExtensionBannerHeading['default'];
};

export const getBrowserExtensionBannerText = (companyId) => {
  const browserExtensionBannerText = {
    1: "Our browser extension will help you increase your rebates, and it's FREE!",
    2: "DVPG plugs right in to your browser to capture your rebates automatically... and it's FREE!",
    4: "Source1 Purchasing plugs right in to your browser to capture your rebates automatically... and it's FREE!",
    5: "Southstar Essentials plugs right in to your browser to capture your rebates automatically... and it's FREE!",
    11: "Source1 Purchasing plugs right in to your browser to capture your rebates automatically... and it's FREE!",
    12: "Source1 Purchasing plugs right in to your browser to capture your rebates automatically... and it's FREE!",
    16: "SMART plugs right in to your browser to capture your rebates automatically... and it's FREE!",
    18: "Cool School Cafe plugs right in to your browser to capture your rebates automatically... and it's FREE!",
    default: "Our browser extension will help you increase your rebates, and it's FREE!"
  }
  return browserExtensionBannerText[companyId] || browserExtensionBannerText['default'];
};

export const getBrowserExtensionBannerLink = (companyId) => {
  const extensions = {
    'booyah': 'https://chrome.google.com/webstore/detail/booyah/nfiainmjgkibdpjpofdillkcbbfimnpb',
    'fsr': 'https://chrome.google.com/webstore/detail/food-service-rewards/npihfoaekhiclkjbjmomflndhjaeefio',
    'southstar': 'https://chrome.google.com/webstore/detail/south-star-essentials/aoaammbbdadfkhhdecefcaijemnhkkjb',
    'da': 'https://chrome.google.com/webstore/detail/dining-alliance/kfmokcceclckeamgbcdeddallckbdlpd',
    'source1': 'https://chrome.google.com/webstore/detail/source1/agjabheamebejcohgnkedhgfohlpdpfm?gclid=CjwKCAjw3MSHBhB3EiwAxcaEu7q2UPKPEXAe9d-c2P4MoywRa2RNpLqF7vck8oVnb02JQR81-Oz_MxoCh5wQAvD_BwE',
    'smart': 'https://chrome.google.com/webstore/detail/smart/oghmkfgehgcnhhdkjahgobhidmnfkhfo',
    'dvpg': 'https://chrome.google.com/webstore/detail/dvpg/joecdbplddjgichcinkeondgkagofkmd',
    'coolschool': null,
  };
  const browserExtensionBannerLink = {
    1: extensions['da'],
    2: extensions['dvpg'],
    4: extensions['source1'],
    5: extensions['southstar'],
    11: extensions['source1'],
    12: extensions['source1'],
    16: extensions['smart'],
    18: extensions['coolschool'],
    default: null
  }
  return browserExtensionBannerLink[companyId] || browserExtensionBannerLink['default'];
};

export const getBrowserExtensionBannerLogo = (companyId) => {
  const browserExtensionBannerLogo = {
    1: 'da-logo-for-extension.png',
    2: 'dvpg-logo-for-extension.png',
    4: 'source1-logo.png',
    5: 'southstar-logo-for-extension.png',
    11: 'source1-logo.png',
    12: 'source1-logo.png',
    16: 'smart-logo.png',
    18: 'coolschool-logo-for-extension.png',
    default: 'da-logo-for-extension.png'
  }
  return browserExtensionBannerLogo[companyId] || browserExtensionBannerLogo['default'];
};

/***********************************************************
 *  INSIGHTS
***********************************************************/
export const getColorPaletteInit = (companyId) => {
  const companyIdColorPalette = {
    1: '#FA9016, #334F9A, #8A43A4, #FB4726, #26C0FB, #C2185B, #827717, #37474F, #FF5722',
    2: '#FA9016, #334F9A, #8A43A4, #FB4726, #26C0FB, #C2185B, #827717, #37474F, #FF5722',
    3: '#98C854, #319B42, #A73F65, #6F4BA1, #467ACC, #E09F1E, #4EACB5, #D35656, #9A928B',
    4: '#D6001C, #007398, #FF4A28, #FFC51A, #710098',
    12: '#51893F, #2D3174, #D7A71B, #C63116, #612B74',
    19: '#FA9016, #334F9A, #8A43A4, #FB4726, #26C0FB, #C2185B, #827717, #37474F, #FF5722',
    default: '#FA9016, #334F9A, #8A43A4, #FB4726, #26C0FB, #C2185B, #827717, #37474F, #FF5722'
  }
  return companyIdColorPalette[companyId] || companyIdColorPalette['default'];
};

export const getInsightsCurrentStyle = (companyId) => {
  const companyIdCurrentStyles = {
    1: {
      backgroundColor: config.colors.rbDarkBlue,
      color: config.colors.rbWhite
    },
    2: {
      backgroundColor: config.colors.rbDarkBlue,
      color: config.colors.rbWhite
    },
    3: {
      backgroundColor: config.colors.primary,
      color: config.colors.rbWhite
    },
    4: {
      backgroundColor: config.colors.rbBlack,
      color: config.colors.rbWhite
    },
    12: {
      backgroundColor: config.colors.secondary,
      color: config.colors.rbWhite
    },
    19: {
      backgroundColor: config.colors.rbDarkBlue,
      color: config.colors.rbWhite
    },
    default: {
      backgroundColor: config.colors.rbDarkBlue,
      color: config.colors.rbWhite
    }
  }
  return companyIdCurrentStyles[companyId] || companyIdCurrentStyles['default'];
};

export const getInsightsSwitchStyle = (companyId) => {
  const companyIdSwitchStyles = {
    1: {
      backgroundColor: config.colors.rbYellow,
      color: config.colors.rbWhite
    },
    2: {
      backgroundColor: config.colors.rbYellow,
      color: config.colors.rbWhite
    },
    3: {
      backgroundColor: config.colors.secondary,
      color: config.colors.rbWhite
    },
    4: {
      backgroundColor: config.colors.secondary,
      color: config.colors.rbWhite
    },
    12: {
      backgroundColor: config.colors.primary,
      color: config.colors.rbWhite
    },
    19: {
      backgroundColor: config.colors.rbYellow,
      color: config.colors.rbWhite
    },
    default: {
      backgroundColor: config.colors.rbYellow,
      color: config.colors.rbWhite
    }
  }
  return companyIdSwitchStyles[companyId] || companyIdSwitchStyles['default'];
};

export const getInsightsSwitchIconColor = (companyId) => {
  const companyIdIcon = {
    1: config.colors.rbBlue,
    2: config.colors.rbBlue,
    3: config.colors.primary,
    4: '#030925',
    12: config.colors.primary,
    19: config.colors.rbBlue,
    default: config.colors.rbBlue
  }
  return companyIdIcon[companyId] || companyIdIcon['default'];
};

export const getInsightsSwitchTextColor = (companyId) => {
  const companyIdIconText = {
    1: config.colors.rbYellow,
    2: config.colors.rbYellow,
    3: config.colors.primary,
    4: '#030925',
    12: config.colors.primary,
    19: config.colors.rbYellow,
    default: config.colors.rbYellow
  }
  return companyIdIconText[companyId] || companyIdIconText['default'];
};

export const getInsightsSwitchBtnShowStyle = (companyId) => {
  const companyIdSwitchBtnShow = {
    1: {
      backgroundColor: config.colors.rbBlue,
      color: config.colors.rbWhite,
      borderRadius: '0'
    },
    2: {
      backgroundColor: config.colors.rbBlue,
      color: config.colors.rbWhite,
      borderRadius: '0'
    },
    3: {
      backgroundColor: config.colors.primary,
      color: config.colors.rbWhite,
      borderRadius: '0'
    },
    4: {
      backgroundColor: config.colors.primary,
      color: config.colors.rbWhite,
      borderRadius: '25px'
    },
    12: {
      backgroundColor: config.colors.primary,
      color: config.colors.rbWhite,
      borderRadius: '25px'
    },
    19: {
      backgroundColor: config.colors.rbBlue,
      color: config.colors.rbWhite,
      borderRadius: '0'
    },
    default: {
      backgroundColor: config.colors.rbYellow,
      color: config.colors.rbWhite,
      borderRadius: '0'
    }
  }
  return companyIdSwitchBtnShow[companyId] || companyIdSwitchBtnShow['default'];
};

export const getInsightsSwitchBtnHideStyle = (companyId) => {
  const companyIdSwitchBtnHide = {
    1: {
      backgroundColor: config.colors.rbDarkGrey,
      color: config.colors.rbWhite,
      borderRadius: '0'
    },
    2: {
      backgroundColor: config.colors.rbBlue,
      color: config.colors.rbWhite,
      borderRadius: '0'
    },
    3: {
      backgroundColor: config.colors.primary,
      color: config.colors.rbWhite,
      borderRadius: '0'
    },
    4: {
      backgroundColor: config.colors.rbDarkGrey,
      color: config.colors.rbWhite,
      borderRadius: '25px'
    },
    12: {
      backgroundColor: config.colors.rbDarkGrey,
      color: config.colors.rbWhite,
      borderRadius: '25px'
    },
    19: {
      backgroundColor: config.colors.rbDarkGrey,
      color: config.colors.rbWhite,
      borderRadius: '0'
    },
    default: {
      backgroundColor: config.colors.rbYellow,
      color: config.colors.rbWhite,
      borderRadius: '0'
    }
  }
  return companyIdSwitchBtnHide[companyId] || companyIdSwitchBtnHide['default'];
};

/***********************************************************
 *  CLAIM ACH
***********************************************************/
export const getClaimACHHasTopHeader = (companyId) => {
  const companyIdHasClaimACH = {
    1: false,
    2: true,
    3: false,
    4: false,
    12: false,
    19: false,
    default: false
  }
  return companyIdHasClaimACH[companyId] !== undefined ? companyIdHasClaimACH[companyId] : companyIdHasClaimACH['default'];
};

/***********************************************************
 *  PRIME PROGRAMS
***********************************************************/

export const getPrimeBgCard = (companyId) => {
  const companyIdBgCard = {
    1: 'linear-gradient(90deg, $rb-blue 0%, $rb-blue 100%)',
    2: 'linear-gradient(90deg, $rb-blue 0%, $rb-blue 100%)',
    3: 'linear-gradient(90deg, var(--secondary) 0%, var(--secondary) 100%)',
    4: 'linear-gradient(90deg, #007398 0%, #007398 100%)',
    12: 'linear-gradient(90deg, #51893F 0%, #51893F 100%)',
    19: 'linear-gradient(90deg, $rb-blue 0%, $rb-blue 100%)',
    default: 'linear-gradient(90deg, $rb-blue 0%, $rb-blue 100%)',
  }
  return companyIdBgCard[companyId] || companyIdBgCard['default'];
};

export const getPrimeBgOverlay = (companyId) => {
  const companyIdBgOverlay = {
    1: 'linear-gradient(90deg, #FA9016 0%, #FA9016 100%)',
    2: 'linear-gradient(90deg, #FA9016 0%, #FA9016 100%)',
    3: 'linear-gradient(90deg, var(--primary) 0%, var(--primary) 100%)',
    4: 'linear-gradient(90deg, #710098 0%, #710098 100%)',
    12: 'linear-gradient(90deg, #2D3174 0%, #2D3174 100%)',
    19: 'linear-gradient(90deg, #FA9016 0%, #FA9016 100%)',
    default: 'linear-gradient(90deg, #FA9016 0%, #FA9016 100%)'
  }
  return companyIdBgOverlay[companyId] || companyIdBgOverlay['default'];
};

export const getPrimeTitleClass = (companyId) => {
  const companyIdPrimeTitleClass = {
    1: '',
    2: '',
    3: 'text-secondary font-family-bold py-0 px-4',
    4: 'text-black font-family-bold',
    12: 'text-capitalize text-dark',
    19: '',
    default: ''
  }
  return companyIdPrimeTitleClass[companyId] || companyIdPrimeTitleClass['default'];
};

export const getPrimeHasCornerCut = (companyId) => {
  const companyIdHasCorner = {
    1: true,
    2: true,
    3: false,
    4: false,
    12: false,
    19: true,
    default: false
  }
  return companyIdHasCorner[companyId] !== undefined ? companyIdHasCorner[companyId] : companyIdHasCorner['default'];
};

export const getPrimeHasTopHeader = (companyId) => {
  const companyIdHasTopHeader = {
    1: true,
    2: true,
    3: false,
    4: false,
    12: false,
    19: true,
    default: false
  }
  return companyIdHasTopHeader[companyId] !== undefined ? companyIdHasTopHeader[companyId] : companyIdHasTopHeader['default'];
};

export const getPrimeCornerCutStyle = (companyId) => {
  const companyIdPrimeCornerCutStyle = {
    1: config.colors.rbDarkBlue,
    2: config.colors.rbDarkBlue,
    3: 'transparent',
    4: 'transparent',
    12: config.colors.primary,
    19: config.colors.rbDarkBlue,
    default: config.colors.rbDarkBlue
  }
  return companyIdPrimeCornerCutStyle[companyId] || companyIdPrimeCornerCutStyle['default'];
};

export const getPrimeCardHoverHeaderClass = (companyId) => {
  const companyIdPrimeCardHoverHeaderClass = {
    1: '',
    2: '',
    3: 'text-white',
    4: isMobile ? 'text-size-13' : 'text-white text-size-11',
    12: isMobile ? 'text-size-13' : 'text-white',
    19: '',
    default: ''
  }
  return companyIdPrimeCardHoverHeaderClass[companyId] || companyIdPrimeCardHoverHeaderClass['default'];
};

export const getPrimeLeftButtonClass = (companyId) => {
  const companyIdPrimeLeftButtonClass = {
    1: 'btn-prime-learn',
    2: 'btn-primary',
    3: 'btn-prime-learn btn-rb-primary',
    4: 'btn-rb-white btn-rb-tertiary',
    12: 'btn-rb-secondary-rounded',
    19: 'btn-prime-learn',
    default: 'btn-primary'
  }
  return companyIdPrimeLeftButtonClass[companyId] || companyIdPrimeLeftButtonClass['default'];
};

export const getPrimeLeftButtonVariant = (companyId) => {
  const companyIdPrimeLeftButtonVariant = {
    1: 'yellow',
    2: 'yellow',
    3: 'primary',
    4: 'tertiary',
    12: '',
    19: 'yellow',
    default: 'yellow'
  }
  return companyIdPrimeLeftButtonVariant[companyId] || companyIdPrimeLeftButtonVariant['default'];
};

export const getPrimeRightButtonClass = (companyId) => {
  const companyIdPrimeRightButtonClass = {
    1: 'btn-prime-signup btn-rb-white',
    2: 'btn-rb-white',
    3: 'btn-prime-signup btn-rb-white',
    4: 'btn-rb-white',
    12: 'btn-rb-white',
    19: 'btn-prime-signup btn-rb-white',
    default: 'btn-rb-white'
  }
  return companyIdPrimeRightButtonClass[companyId] || companyIdPrimeRightButtonClass['default'];
};

export const getPrimeRightButtonVariant = (companyId) => {
  const companyIdPrimeRightButtonVariant = {
    1: 'white-secondary',
    2: 'white-secondary',
    3: 'white',
    4: 'white',
    12: 'white-primary',
    19: 'white-secondary',
    default: 'white-secondary'
  }
  return companyIdPrimeRightButtonVariant[companyId] || companyIdPrimeRightButtonVariant['default'];
};

export const getPrimeFooterClass = (companyId) => {
  const companyIdPrimeFooterClass = {
    1: 'text-yellow text-uppercase font-italic',
    2: 'text-yellow text-uppercase font-italic',
    3: 'text-primary',
    4: 'text-white',
    12: 'text-white',
    19: 'text-yellow text-uppercase font-italic',
    default: 'text-yellow text-uppercase font-italic'
  }
  return companyIdPrimeFooterClass[companyId] || companyIdPrimeFooterClass['default'];
};

export const getPrimeBottomBackground = (companyId) => {
  const companyIdPrimeBottomBackground = {
    1: config.colors.rbYellow,
    2: config.colors.rbYellow,
    3: 'url("https://rebate-portal.s3.amazonaws.com/resources/prime/images/prime-footer-cc.png")',
    4: 'url("https://rebate-portal.s3.amazonaws.com/resources/prime/images/prime-footer-s1p.png")',
    12: 'url("https://rebate-portal.s3.amazonaws.com/resources/prime/images/prime-footer-bw.png")',
    19: config.colors.rbYellow,
    default: config.colors.rbYellow
  }
  return companyIdPrimeBottomBackground[companyId] || companyIdPrimeBottomBackground['default'];
};

export const getPrimeBottomTitleClass = (companyId) => {
  const companyIdPrimeBottomTitleClass = {
    1: 'text-uppercase text-size-32',
    2: 'text-uppercase text-size-32',
    3: 'text-uppercase text-size-32 text-white',
    4: 'text-uppercase text-size-32 text-white',
    12: 'text-white font-weight-light text-size-48',
    19: 'text-uppercase text-size-32',
    default: 'text-uppercase text-size-32'
  }
  return companyIdPrimeBottomTitleClass[companyId] || companyIdPrimeBottomTitleClass['default'];
};

export const getPrimeBottomTextClass = (companyId) => {
  const companyIdPrimeBottomTextClass = {
    1: '',
    2: '',
    3: 'text-white',
    4: 'text-white',
    12: 'text-white',
    19: '',
    default: ''
  }
  return companyIdPrimeBottomTextClass[companyId] || companyIdPrimeBottomTextClass['default'];
};

export const getPrimeBottomButtonClass = (companyId) => {
  const companyIdPrimeBottomButtonClass = {
    1: '',
    2: '',
    3: '',
    4: 'btn-primary',
    12: '',
    19: '',
    default: ''
  }
  return companyIdPrimeBottomButtonClass[companyId] || companyIdPrimeBottomButtonClass['default'];
};

export const getPrimeBottomButtonVariant = (companyId) => {
  const companyIdPrimeBottomButtonVariant = {
    1: 'black',
    2: 'black',
    3: 'white',
    4: 'primary',
    12: 'transparent-rounded',
    19: 'black',
    default: 'black'
  }
  return companyIdPrimeBottomButtonVariant[companyId] || companyIdPrimeBottomButtonVariant['default'];
};

/***********************************************************
 *  ITEM INTEL
***********************************************************/

export const getItemIntelHeaderClass = (companyId) => {
  const companyIdItemIntelHeaderClass = {
    1: isMobile ? 'bg-blue-dark' : 'text-size-18',
    2: isMobile ? 'bg-blue-dark' : 'text-size-18',
    3: isMobile ? 'bg-primary' : 'text-bold text-uppercase justify-content-center font-family-bold',
    4: isMobile ? 'bg-primary' : 'text-size-24',
    12: isMobile ? 'bg-primary' : 'text-size-24 font-weight-light',
    19: isMobile ? 'bg-blue-dark' : 'text-size-18',
    default: isMobile ? 'bg-blue-dark' : 'text-size-18'
  }
  return companyIdItemIntelHeaderClass[companyId] || companyIdItemIntelHeaderClass['default'];
};

export const getItemIntelFilterCheckIconColor = (companyId) => {
  const companyIdItemIntelFilterCheckIconColor = {
    1: config.colors.rbBlue,
    2: config.colors.rbBlue,
    3: config.colors.primary,
    4: config.colors.primary,
    12: config.colors.primary,
    19: config.colors.rbBlue,
    default: config.colors.rbBlue
  }
  return companyIdItemIntelFilterCheckIconColor[companyId] || companyIdItemIntelFilterCheckIconColor['default'];
};

export const getItemIntelDetailsLinkClass = (companyId) => {
  const companyIdItemIntelDetailsLinkClass = {
    1: '',
    2: '',
    3: 'text-uppercase',
    4: 'text-uppercase text-tertiary text-bold',
    12: 'text-bold',
    19: '',
    default: ''
  }
  return companyIdItemIntelDetailsLinkClass[companyId] || companyIdItemIntelDetailsLinkClass['default'];
};

export const getItemIntelDetailsSimilarItemsLinkClass = (companyId) => {
  const companyIdItemIntelDetailsSimilarItemsLinkClass = {
    1: '',
    2: '',
    3: 'text-uppercase',
    4: 'text-uppercase text-bold',
    12: 'text-bold',
    19: '',
    default: ''
  }
  return companyIdItemIntelDetailsSimilarItemsLinkClass[companyId] || companyIdItemIntelDetailsSimilarItemsLinkClass['default'];
};

export const getItemIntelDetailsContainerClass = (companyId) => {
  const companyIdItemIntelDetailsContainerClass = {
    1: isMobile ? 'bg-white' : 'bg-white',
    2: isMobile ? 'bg-white' : 'bg-grey-home',
    3: isMobile ? 'bg-white' : 'bg-grey-home',
    4: isMobile ? 'bg-white' : 'bg-white',
    12: isMobile ? 'bg-white' : 'bg-white',
    19: isMobile ? 'bg-white' : 'bg-white',
    default: isMobile ? 'bg-white' : 'bg-grey-home'
  }
  return companyIdItemIntelDetailsContainerClass[companyId] || companyIdItemIntelDetailsContainerClass['default'];
};

/***********************************************************
 *  FAQ & RESOURCES
***********************************************************/
export const getFAQContainerBorderStyle = (companyId) => {
  const companyIdFAQContainerBorderStyle = {
    1: 'solid 1px rgba(0, 0, 0, 0.125)',
    2: 'none',
    3: 'none',
    4: 'solid 1px rgba(0, 0, 0, 0.125)',
    12: 'solid 1px rgba(0, 0, 0, 0.125)',
    19: 'solid 1px rgba(0, 0, 0, 0.125)',
    default: 'none'
  }
  return companyIdFAQContainerBorderStyle[companyId] || companyIdFAQContainerBorderStyle['default'];
};

export const getFAQSectionTitleClass = (companyId) => {
  const companyIdFAQSectionTitleClass = {
    1: '',
    2: '',
    3: 'font-family-bold text-uppercase justify-content-center',
    4: '',
    12: '',
    19: '',
    default: ''
  }
  return companyIdFAQSectionTitleClass[companyId] || companyIdFAQSectionTitleClass['default'];
};

export const getFAQTitleClass = (companyId) => {
  const companyIdFAQTitleColor = {
    1: '',
    2: '',
    3: 'text-bold',
    4: '',
    12: 'text-bold',
    19: '',
    default: ''
  }
  return companyIdFAQTitleColor[companyId] || companyIdFAQTitleColor['default'];
};

export const getFAQBackground = (companyId) => {
  const companyIdFAQBackground = {
    1: '',
    2: '',
    3: '#F5F5F5',
    4: '',
    12: '',
    19: '',
    default: ''
  }
  return companyIdFAQBackground[companyId] || companyIdFAQBackground['default'];
};

export const getFAQClass = (companyId) => {
  const companyIdFAQClass = {
    1: '',
    2: '',
    3: 'text-bold',
    4: '',
    12: '',
    19: '',
    default: ''
  }
  return companyIdFAQClass[companyId] || companyIdFAQClass['default'];
};

export const getFAQColor = (companyId) => {
  const companyIdFAQColor = {
    1: config.colors.secondary,
    2: config.colors.secondary,
    3: config.colors.primary,
    4: config.colors.rbGrey,
    12: config.colors.rbGrey,
    19: config.colors.secondary,
    default: config.colors.secondary
  }
  return companyIdFAQColor[companyId] || companyIdFAQColor['default'];
};

export const getFAQAddIconColor = (companyId) => {
  const companyIdFAQAddIconColor = {
    1: config.colors.rbDarkGrey,
    2: config.colors.rbDarkGrey,
    3: config.colors.rbDarkGrey,
    4: config.colors.rbDarkGrey,
    12: config.colors.rbDarkGrey,
    19: config.colors.rbDarkGrey,
    default: config.colors.rbDarkGrey
  }
  return companyIdFAQAddIconColor[companyId] || companyIdFAQAddIconColor['default'];
};

export const getFAQSubstractIconColor = (companyId) => {
  const companyIdFAQSubstractIconColor = {
    1: config.colors.rbDarkGrey,
    2: config.colors.rbDarkGrey,
    3: config.colors.rbDarkGrey,
    4: config.colors.primary,
    12: config.colors.primary,
    19: config.colors.rbDarkGrey,
    default: config.colors.rbDarkGrey
  }
  return companyIdFAQSubstractIconColor[companyId] || companyIdFAQSubstractIconColor['default'];
};

export const getFAQHowToLinkClass = (companyId) => {
  const companyIdFAQHowToLinkClass = {
    1: '',
    2: '',
    3: '',
    4: 'text-tertiary',
    12: 'text-capitalize',
    19: '',
    default: ''
  }
  return companyIdFAQHowToLinkClass[companyId] || companyIdFAQHowToLinkClass['default'];
};

/***********************************************************
 *  FOOD COST
***********************************************************/

export const getFoodCostReportingTitleClass = (companyId) => {
  const companyIdFoodCostReportingTitleClass = {
    1: '',
    2: '',
    3: 'text-uppercase',
    4: '',
    12: 'font-weight-normal',
    19: '',
    default: ''
  }
  return companyIdFoodCostReportingTitleClass[companyId] || companyIdFoodCostReportingTitleClass['default'];
};

export const getFoodCostReportingButtonVariant = (companyId) => {
  const companyIdFoodCostReportingButtonVariant = {
    1: 'blue',
    2: 'primary',
    3: 'yellow',
    4: 'primary',
    12: 'landing-page-rounded',
    19: 'blue',
    default: 'primary'
  }
  return companyIdFoodCostReportingButtonVariant[companyId] || companyIdFoodCostReportingButtonVariant['default'];
};

export const getFoodCostInventoryTitleClass = (companyId) => {
  const companyIdFoodCostInventoryTitleClass = {
    1: '',
    2: '',
    3: 'text-uppercase',
    4: '',
    12: 'font-weight-normal',
    19: '',
    default: ''
  }
  return companyIdFoodCostInventoryTitleClass[companyId] || companyIdFoodCostInventoryTitleClass['default'];
};

export const getFoodCostRecipeTitleClass = (companyId) => {
  const companyIdFoodCostRecipeTitleClass = {
    1: '',
    2: '',
    3: 'text-uppercase',
    4: '',
    12: 'font-weight-normal',
    19: '',
    default: ''
  }
  return companyIdFoodCostRecipeTitleClass[companyId] || companyIdFoodCostRecipeTitleClass['default'];
};

export const getFoodCostRecipeButtonVariant = (companyId) => {
  const companyIdFoodCostRecipeButtonVariant = {
    1: 'blue',
    2: 'blue',
    3: 'white',
    4: 'primary',
    12: 'primary',
    19: 'blue',
    default: 'blue'
  }
  return companyIdFoodCostRecipeButtonVariant[companyId] || companyIdFoodCostRecipeButtonVariant['default'];
};

export const getFoodCostRecipeBackgroundClass = (companyId) => {
  const companyIdFoodCostRecipeBackgroundClass = {
    1: 'bg-yellow',
    2: 'bg-yellow',
    3: 'bg-yellow',
    4: 'bg-secondary',
    12: 'bg-primary',
    19: 'bg-yellow',
    default: 'bg-yellow'
  }
  return companyIdFoodCostRecipeBackgroundClass[companyId] || companyIdFoodCostRecipeBackgroundClass['default'];
};

export const getFoodCostAccountingTitleClass = (companyId) => {
  const companyIdFoodCostAccountingTitleClass = {
    1: '',
    2: '',
    3: 'text-uppercase',
    4: '',
    12: 'font-weight-normal',
    19: '',
    default: ''
  }
  return companyIdFoodCostAccountingTitleClass[companyId] || companyIdFoodCostAccountingTitleClass['default'];
};

export const getFoodCostAccountingButtonVariant = (companyId) => {
  const companyIdFoodCostAccountingButtonVariant = {
    1: 'yellow',
    2: 'yellow',
    3: 'white',
    4: 'primary',
    12: 'primary',
    19: 'yellow',
    default: 'yellow'
  }
  return companyIdFoodCostAccountingButtonVariant[companyId] || companyIdFoodCostAccountingButtonVariant['default'];
};

export const getFoodCostAccountingBackgroundClass = (companyId) => {
  const companyIdFoodCostAccountingBackgroundClass = {
    1: 'bg-blue-dark text-white',
    2: 'bg-blue-dark text-white',
    3: 'bg-primary text-white',
    4: 'bg-black text-white',
    12: 'bg-grey-new text-dark',
    19: 'bg-blue-dark text-white',
    default: 'bg-blue-dark text-white'
  }
  return companyIdFoodCostAccountingBackgroundClass[companyId] || companyIdFoodCostAccountingBackgroundClass['default'];
};

export const getFoodCostInvoiceTitleClass = (companyId) => {
  const companyIdFoodCostInvoiceTitleClass = {
    1: '',
    2: '',
    3: 'text-uppercase',
    4: '',
    12: 'font-weight-normal',
    19: '',
    default: ''
  }
  return companyIdFoodCostInvoiceTitleClass[companyId] || companyIdFoodCostInvoiceTitleClass['default'];
};

export const getFoodCostShowBullets = (companyId) => {
  const companyIdFoodCostShowBullets = {
    1: true,
    2: true,
    3: false,
    4: false,
    12: false,
    19: true,
    default: true
  }
  return companyIdFoodCostShowBullets[companyId] !== undefined ? companyIdFoodCostShowBullets[companyId] : companyIdFoodCostShowBullets['default'];
};
