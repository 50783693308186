import React, { Component, Fragment } from 'react'
import Modal from '../../../MasterComponents/Modals/Modal';
import ModalHeader from '../../../MasterComponents/Modals/ModalHeader';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import ModalFooter from '../../../MasterComponents/Modals/ModalFooter';
import Button from '../../../MasterComponents/Button/Button';
import { isMobile } from 'react-device-detect';
import { findModal } from '../../../MasterComponents/Modals/ModalsActions';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import InputLabel from '../../../MasterComponents/InputLabel/InputLabel';
import PropTypes from 'prop-types';
import RadioButton from '../../../MasterComponents/RadioButton/RadioButton';
import SignUpApi from '../SignUpApi';
import { spinner } from '../../../MasterComponents/Spinner/SpinnerActions';
import { showModal } from '../../../MasterComponents/Modals/ModalsActions';
import ConfirmationModal from './ConfirmationModal';

class RequestInviteModal extends Component {
  constructor(props) {
      super(props);
      this.state = {
        contactName: this.props.user.contactName,
        contactEmail: this.props.user.email,
        ownerId: null,
      }
  }

  componentWillMount = () => {
    const { parents } = this.props;
    if(parents.length === 1){
      this.setState({
        ownerId: parents[0].owner.userId
      }); 
    }    
  }

  closeModal = () => {
    const { modals } = this.props;    
    findModal(modals, 'requestInviteModal').close();
  }  

  handleChangeParent = (value) => {
    this.setState({
        ownerId: value
      });  
  }

  requestInvite = () => {
    const { modals } = this.props;
    const action = this.props.dispatch;
    if(this.state.ownerId === null){
      action(showModal(ConfirmationModal, 'confirmationModal', {header:'Confirmation', icon:false, message:'You must select one organization' }));
      return;
    }
    action(spinner(true));
    SignUpApi.requestInvite(this.state)
    .then(({ data }) => {
        action(spinner(false));
        if(data.data){
          action(showModal(ConfirmationModal, 'confirmationModal', {header:'Confirmation', message:'Your request was sent', history: this.props.history }));
        }else{
          action(showModal(ConfirmationModal, 'confirmationModal', {header:'Confirmation', icon:false, message:'There was a problem while sending your request' }));
        }
        findModal(modals, 'requestInviteModal').close();
    })
    .catch(error => {
        action(spinner(false));
        findModal(modals, 'requestInviteModal').close();
        if (error.data.data) {
            action(showModal(ConfirmationModal, 'confirmationModal', {header:'Confirmation', icon:false, message:'There was a problem while sending your request' }));
        }
    });    
  }

  displayChildren = (children) => {
    const childrenMap =  children.map((child) => {
      return (
        <Fragment>            
            <GridRow className='my-0'>
              <GridCol className='col-1 d-flex align-items-center'/>
              <GridCol className='col d-flex align-items-center'>
                <InputLabel text={child.nickname} className='my-0'/>
              </GridCol>              
            </GridRow>
        </Fragment>
      )
    });
    return childrenMap;
  }
  
  render() {
    const { header, parents } = this.props;
    const totalParents = parents.length;
    const message = totalParents > 1 ? " This email address can only be used for one account, which organization would you like to request an invite for?" : " Would you like to send a request to the user associated with these locations so they can invite you to view the portal?";
    const parentsMap = parents.map((p, i) => {
        return(
            <Fragment key={i}>
                <GridRow className='mx-0 my-0'>
                    <GridCol className='col-1 d-flex align-items-center'>
                        {totalParents > 1 ? <RadioButton text='' name='parents' value={p.owner.userId} onChange={this.handleChangeParent} /> : null}                        
                    </GridCol>
                    <GridCol className='col d-flex align-items-center'>
                        {p.nickname}
                    </GridCol>
                    <GridCol className='col-12'>
                      {p.childs.length > 0 ? this.displayChildren(p.childs) : null}
                    </GridCol>
                </GridRow>                                
            </Fragment>
        );
    });
    return (
      <Modal id='requestInviteModal' fullHeight={isMobile} >
        <ModalHeader noBorder closeButton={false} className={isMobile ? 'pt-5 mt-5 px-4' : null}>{header}</ModalHeader>
        <ModalBody className='text-center text-size-14 text-line-height-20 d-flex align-items-center'>
            <GridCol>
                <GridRow className='my-1'>
                    <GridCol className='d-flex justify-content-center'>
                        Sorry, the locations you entered are already on the platform.
                        {message}
                    </GridCol>
                </GridRow>                
                {parentsMap}
            </GridCol>
        </ModalBody>
        <ModalFooter>
          <Button variant='primary' onClick={this.requestInvite} className='clickable' size='lg' text='Request Invite' />
          <Button variant='primary' onClick={this.closeModal} className='clickable' size='lg' text='Cancel' />
        </ModalFooter>
      </Modal>
    );
  }
};

RequestInviteModal.propTypes = {
  icon: PropTypes.bool
}

RequestInviteModal.defaultProps = {
  icon: true
}

export default RequestInviteModal;