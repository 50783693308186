import React, { useState } from 'react';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import locationsImg from '../../../assets/images/onboarding/locations.png';
import distributorsImg from '../../../assets/images/onboarding/distributors.png';
import cashbackImg from '../../../assets/images/onboarding/cashback.png';
import Button from '../../../MasterComponents/Button/Button';
import Walkthrough from './Walkthrough';
import Modal from '../../../MasterComponents/Modals/Modal';
import { isMobile } from 'react-device-detect';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import { findModal } from '../../../MasterComponents/Modals/ModalsActions';
import ModalFooter from '../../../MasterComponents/Modals/ModalFooter';

const WelcomeModal = ({modals}) => {
  const [step, setStep] = useState(0);

  const next = () => {
    if (step === 2) {
      findModal(modals, 'welcomeModal').close();
    }
    setStep(step + 1);
  };

  const back = () => {
    setStep(step - 1);
  };

  return (
    <Modal id='welcomeModal' centered fullHeight={isMobile}>
      <ModalBody className={isMobile ? 'vh-100 d-flex flex-column' : ''}>
        <div className={'position-relative d-flex flex-column' + (isMobile ? ' vh-100' : '')} style={{overflow: 'hidden', height: '500px'}}>
          <GridRow className='align-items-center justify-content-center flex-grow-1 m-0 position-absolute transition-left' style={{width: '300%', left: `-${step}00%`}}>
            <Walkthrough image={locationsImg} text='Add information about your locations' />
            <Walkthrough image={distributorsImg} text='Add your distributors and credentials from distributor portals.' />
            <Walkthrough image={cashbackImg} text="That's it! Now you're ready to earn cash back and get insights about your purchases." />
          </GridRow>
          <GridRow className='flex-grow-1' />
          <GridRow className='mb-5'>
            <GridCol>
              <GridCol className='d-flex flex-row align-items-center justify-content-center'>
                <div className={`${step === 0 ? 'onboarding-on' : 'onboarding-off'}`} onClick={() => setStep(0)} />
                <div className={`${step === 1 ? 'onboarding-on' : 'onboarding-off'} mx-3`} onClick={() => setStep(1)} />
                <div className={`${step === 2 ? 'onboarding-on' : 'onboarding-off'}`} onClick={() => setStep(2)} />
              </GridCol>
            </GridCol>
          </GridRow>
          {
            !isMobile &&
            <GridRow className='m-0'>
              <GridCol size={5}>
                {
                  step > 0 &&
                  <Button variant='yellow-outline' text='BACK' onClick={back} />
                }
              </GridCol>
              <GridCol />
              <GridCol size={isMobile ? 12 : 5}>
                <Button variant='yellow' text={step > 1 ? 'GET STARTED' : 'NEXT'} onClick={next} />
              </GridCol>
            </GridRow>
          }
        </div>
      </ModalBody>
      {
        isMobile ?
        <ModalFooter>
          <GridCol size={isMobile ? 12 : 5}>
            <Button variant='yellow' text={step > 1 ? 'GET STARTED' : 'NEXT'} onClick={next} />
          </GridCol>
        </ModalFooter>
        : <></>
      }
    </Modal>
  );
};

export default WelcomeModal;