export default function reducer(state = {
  items: [],
  manufacturers: [],
  categories: [],
  page: 0,
  filters: {
    itemFilter: null,
    search: null,
    manufacturers: null,
    categories: null,
    types: null,
  },
  scrollToSelectedItem: null,
  itemDetails: {
    description: '',
    region: '',
    manufacturer: '',
    brand: '',
    min: '',
    threeMonthsTrend: 0,
    currentPriceRange: '',
    rebate: 0,
    rebateType: '',
    deviation: 0,
    graphData: null,
  },
  similarItems: [],
  showFiltersSection: false,
}, action) {
  switch (action.type) {
    case 'ITEM_INTEL_SET_ITEMS':
      return {
        ...state,
        items: action.payload
      };
    case 'ITEM_INTEL_SET_MANUFACTURERS':
      return {
        ...state,
        manufacturers: action.payload
      };
    case 'ITEM_INTEL_SET_CATEGORIES':
      return {
        ...state,
        categories: action.payload
      };
    case 'ITEM_INTEL_SET_PAGE':
      return {
        ...state,
        page: action.payload
      };
    case 'ITEM_INTEL_SET_FILTERS':
      return {
        ...state,
        filters: action.payload
      };
    case 'ITEM_INTEL_LOAD_MORE_ITEMS':
      return {
        ...state,
        items: state.items.concat(action.payload)
      };
    case 'ITEM_INTEL_SET_SCROLL_TO_SELECTED_ITEM':
      return {
        ...state,
        scrollToSelectedItem: action.payload
      };
    case 'ITEM_INTEL_SET_ITEM_DETAILS':
      return {
        ...state,
        itemDetails: action.payload
      };
    case 'ITEM_INTEL_RESET_ITEM_DETAILS':
      return {
        ...state,
        itemDetails: {
          description: '',
          region: '',
          manufacturer: '',
          brand: '',
          min: '',
          threeMonthsTrend: 0,
          currentPriceRange: ' ',
          rebate: 0,
          rebateType: '',
          deviation: 0,
          graphData: null,
        },
      };
    case 'ITEM_INTEL_SET_SIMILAR_ITEMS':
      return {
        ...state,
        similarItems: action.payload
      };
    case 'ITEM_INTEL_RESET_SIMILAR_ITEMS':
      return {
        ...state,
        similarItems: []
      };
    case 'ITEM_INTEL_SET_SHOW_FILTERS_SECTION':
      return {
        ...state,
        showFiltersSection: action.payload
      };
    default:
      return state;
  }
}