import React, { Component, Fragment } from 'react';
import '../SignUp.scss';
import { isMobile } from 'react-device-detect';
import InputLabel from '../../../MasterComponents/InputLabel/InputLabel';
import SignUpPorchDoorChannelLinks from './SignUpPorchDoorChannelLinks';
import { spinner } from '../../../MasterComponents/Spinner/SpinnerActions';
import CompanyApi from '../../../config/Company/CompanyApi';
import SignUpFormHeader from '../Forms/SignUpFormHeader';
import { setFormType } from '../../../config/Company/CompanyActions';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import IconClose from '../../../MasterComponents/Icons/IconClose';

class SignUpPorchDoorChannelContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            links: [],
            loading: true
        }
    };
    componentDidMount() {
        this.getPartnerData();
    };
    getPartnerData = () => {
        const { partner, dispatch } = this.props;
        const { id } = partner;
        dispatch(spinner(true));
        CompanyApi.getPartner(id, 'links').then(({data}) => {
            const { links } = data.data;
            dispatch(spinner(false));
            this.setState({
                links: links,
                loading: false
            });
            if (links.length === 1) {
                this.go(links[0]);
            }
        });
    };
    go = (l) => {
        const { history, dispatch } = this.props;
        dispatch(setFormType(l.formType));
        if (l.url) {
            const search = history.location.search ? `&${history.location.search.substring(1)}` : '';
            window.location = `${l.url}${search}`;
        } else {
            history.push(`/platform/brand/${l.code.toLowerCase()}/1${history.location.search}`);
        }
    };
    render() {
        const { partner, history, dispatch } = this.props;
        const { links } = this.state;
        const headerProps = {
            isPorchDoor: true,
            activeStep: 0,
            title: 'Welcome!',
            hideSteps: true
        };
        return (
            <div className='right-signup'>
                <div className='header-main'>
                    <div className='header-fixed be-blue-bg home-header'>
                        { isMobile &&
                            <Fragment>
                                <GridCol size={11}className='pt-2 pl-5'>
                                    <InputLabel text='Create Account' title className='title' />
                                </GridCol>
                                <GridCol size={1} className='p-0' onClick={this.goHome}>
                                    <IconClose color={'white'} size={18} />
                                </GridCol>
                            </Fragment> 
                        }
                    </div>
                </div>
                <div className='header-signup'>
                    <SignUpFormHeader {...headerProps} />
                </div>
                <div className='signup-form'>
                    <SignUpPorchDoorChannelLinks links={links} abbreviation={partner.abbreviation} history={history} dispatch={dispatch}/>
                </div>
            </div>
        )
    }
}

export default SignUpPorchDoorChannelContent;