import React, { useRef } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import Button from '../../../MasterComponents/Button/Button';
import DropDownList from '../../../MasterComponents/DropDownList/DropDownList';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import Input from '../../../MasterComponents/Input/Input';
import InputLabel from '../../../MasterComponents/InputLabel/InputLabel';
import SignatureCanvas from 'react-signature-canvas'
import PasswordsForm from './PasswordsForm';
import IsGpoForm from './IsGpoForm';
import IsDmaForm from './IsDmaForm';
import Places from './Places';

const SignUpSimpleContent = (props) => {
  const sigCanvas = useRef();
  const {
    firstName, lastName, placesAutocompleteProps, businessName, email,
    password, repeatPassword, reportingLocations, placesForm, country,
    termsAndConditions, addressDetails, phoneNumber, onCreateAccount,
    isGpo, gpoName, terminationDate, isDma, nameOfCompanies, isIframe, isFoodServiceRewards, sendSignatureHandler, cleanSignatureHandler
  } = props;

  const placesProps = {
    country,
    placesAutocompleteProps,
    businessName,
    placesForm,
    addressDetails,
    phoneNumber,
    isIframe
  };

  const cleanSignature = () => {
    sigCanvas.current.clear();
    cleanSignatureHandler();
  }
  const sendSignature = () => {
    const imageData = sigCanvas.current.getTrimmedCanvas().toDataURL();
    sendSignatureHandler(imageData);
  }
  const isPortrait = window.screen.height > window.screen.width;
  const signatureWidthPortrait = isTablet ? 690 : (isMobile ? 342 : 500);
  const signatureWidthLandscape = isTablet ? 685 : (isMobile ? 535 : 500);
  const signatureWidth = isPortrait ? signatureWidthPortrait : signatureWidthLandscape;

  return (
    <div className='container' style={{marginTop: isIframe ? '15px' : (isMobile ? '88px' : '30px')}}>
      <GridRow>
        <GridCol size={(isIframe || isMobile) ? 12 : 6}>
          <InputLabel text='First Name *' />
          <Input placeholder='First Name' name='firstName' {...firstName} />
        </GridCol>
      </GridRow>
      <GridRow className='mt-3'>
        <GridCol size={(isIframe || isMobile) ? 12 : 6}>
          <InputLabel text='Last Name *' />
          <Input placeholder='Last Name' name='lastName' {...lastName} />
        </GridCol>
      </GridRow>
      <Places {...placesProps} />
      <GridRow className='mt-3'>
        <GridCol size={(isIframe || isMobile) ? 12 : 6}>
          <InputLabel text='Email *' />
          <Input placeholder='Email' name='email' {...email} />
        </GridCol>
      </GridRow>
      <PasswordsForm password={password} repeatPassword={repeatPassword} isIframe={isIframe} />
      <GridRow className='mt-3'>
        <GridCol size={(isIframe || isMobile) ? 12 : 6}>
          <InputLabel text={reportingLocations.text} />
          <DropDownList top title={reportingLocations.value}>
            {reportingLocations.options}
          </DropDownList>
        </GridCol>
      </GridRow>
      <IsGpoForm isGpo={isGpo} gpoName={gpoName} terminationDate={terminationDate} isIframe={isIframe} />
      <IsDmaForm isDma={isDma} nameOfCompanies={nameOfCompanies} isIframe={isIframe} />
      <GridRow className='mt-3'>
        <GridCol>
          <div className="rb-checkbox custom-control custom-checkbox top-0">
            <input {...termsAndConditions} type='checkbox' id='tandc'/>
            <label className='custom-control-label text-size-12 fsr' htmlFor='tandc'>By clicking you agree to our <a target="_blank" rel="noopener noreferrer" style={{display: "inline-block"}} href='https://www.diningalliance.com/membership-terms-conditions/' className={isFoodServiceRewards ? 'text-fsr' : 'text-primary'}>Terms and Conditions</a></label>
          </div>
        </GridCol>
      </GridRow>
      <GridRow className='mt-3'>
        <GridCol size={(isIframe || isMobile) ? 12 : 6 } onMouseLeave={sendSignature}>
          <InputLabel text='Signed by *'/><br/>
          <SignatureCanvas penColor='black' canvasProps={{width: signatureWidth, height: 120, className: 'sigCanvas border'}}
            ref={sigCanvas}
          />
        </GridCol>
      </GridRow>
      <GridRow  className='mb-4'>
        <GridCol size={(isIframe || isMobile) ? 12 : 6 } className='mt-3'>
          <Button type='button' variant={ 'white'} size='sm' text='CLEAR' onClick={cleanSignature}/>
        </GridCol>
      </GridRow>
      <GridRow className='py-3'>
        <GridCol size={(isIframe || isMobile) ? 12 : 4}>
          <Button onClick={onCreateAccount} type='button' variant={isFoodServiceRewards ? 'fsr' : 'yellow'} text='CREATE ACCOUNT' />
        </GridCol>
      </GridRow>
    </div>
  );
};

export default SignUpSimpleContent;