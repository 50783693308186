import React, { Component } from 'react';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import config from '../../../config/config';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import * as $ from 'jquery';

class DistributorDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    }
  }
  onBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      this.toggle()
    }
  };
  toggle = () => {
    const { triggerSearch } = this.props;

    if (!this.state.open) {
      setTimeout(() => {
        $('#distributordropdown-menu').focus();
      }, 200);
    } else {
      // trigger search
      triggerSearch();
    }

    this.setState({
      open: !this.state.open
    });
  }
  onChange = (loc)=>{
    const { toggleDistributor } = this.props;
    toggleDistributor(loc)
  };

  render() {
    const { options, distributorSelected, actionRequired, toggleActionRequired, toggleAllDistributor } = this.props;
    const { open } = this.state;
    const name = (distributorSelected.length === 0 || distributorSelected.length === options.length ? 'All Distributors' : (distributorSelected.length === 1 ? distributorSelected[0].label : `${distributorSelected.length} Distributors`)) + (actionRequired ? ', Action Required' : '');
    const isAll = distributorSelected.length === 0 || distributorSelected.length === options.length;

    const optionsMap = options.map(loc => {
      return (
        <div key={`loc_${loc.id}`} className="px-0 dropdown-item clickable d-flex flex-row align-items-center">
          <GridCol className='text-grey pr-0' maxWidth="20px">
            <div className="custom-control custom-checkbox">
              <input onChange={this.onChange.bind(this, loc)} checked={ isAll || distributorSelected.some(m => m.id === loc.id)} type="checkbox" className="custom-control-input" id={`loc_checkbox_${loc.id}`} />
              <label className="custom-control-label" htmlFor={`loc_checkbox_${loc.id}`}></label>
            </div>
          </GridCol>
          <GridCol className='py-2 text-wrap' onClick={this.onChange.bind(this, loc)}>
            {loc.label}
          </GridCol>
        </div>
      )
    })
    return (
      <div className="dropdown" id="distributorDropdown">
        <button className="btn rb-dropdown rb-input form-control border" type="button" id="distributorDropdownMenuButton" onClick={this.toggle}>
          {name} {!open ? <IoMdArrowDropdown className='position-absolute dd-arrow' size={18} color={config.colors.rbBlack} /> : <IoMdArrowDropup className='position-absolute dd-arrow' size={18} color={config.colors.rbBlack} />}
        </button>
        <div id='distributordropdown-menu' className={"dropdown-menu w-100 text-size-14 pt-0" + (open ? ' show' : '')} onBlur={this.onBlur} tabIndex={1} style={{maxHeight: '300px', overflow: 'auto'}}>
          <div className="px-0 clickable d-flex flex-row align-items-center dropdown-item">
            <GridCol className='text-grey pr-0' maxWidth="20px">
              <div className="custom-control custom-checkbox">
                <input onChange={toggleActionRequired} checked={actionRequired} type="checkbox" className="custom-control-input" id={`dist_checkbox_action`} />
                <label className="custom-control-label" htmlFor={`dist_checkbox_action`}></label>
              </div>
            </GridCol>
            <GridCol className='py-2' onClick={toggleActionRequired}>
              Action Required
            </GridCol>
          </div>
          <div className="px-0 clickable d-flex flex-row align-items-center dropdown-item">
            <GridCol className='text-grey pr-0' maxWidth="20px">
              <div className="custom-control custom-checkbox">
                <input onChange={toggleAllDistributor} checked={distributorSelected.length === 0} type="checkbox" className="custom-control-input" id={`dist_checkbox_all`} />
                <label className="custom-control-label" htmlFor={`dist_checkbox_all`}></label>
              </div>
            </GridCol>
            <GridCol className='py-2' onClick={toggleAllDistributor}>
              All Distributors
            </GridCol>
          </div>
          <hr/>
          {optionsMap}
        </div>
      </div>
    );
  }
};

export default DistributorDropdown;