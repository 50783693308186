import moment from 'moment';
import { getColorPaletteInit } from './Company/CompanyHelper';

const development = {
    apiURL: 'https://api-dev-cashback.diningalliance.com',
    foodBamURL: 'https://dev-app.foodbam.com',
    foodBamApiURL: 'https://api-dev.foodbam.com',
    foodBamStripeKey: 'pk_test_XOsf0MfXfRjzMplUTayunobe',
    porchdoorUrl: 'https://dev-portal.buyersedgeplatform.com'
}

const staging = {
    apiURL: 'https://api-staging-cashback.diningalliance.com',
    foodBamURL: 'https://dev-app.foodbam.com',
    foodBamApiURL: 'https://api-dev.foodbam.com',
    foodBamStripeKey: 'pk_test_XOsf0MfXfRjzMplUTayunobe',
    porchdoorUrl: 'https://staging-portal.buyersedgeplatform.com'
}

const production = {
    apiURL: 'https://api-cashback.diningalliance.com',
    foodBamURL: 'https://app.foodbam.com',
    foodBamApiURL: 'https://api-dev.foodbam.com',
    foodBamStripeKey: 'pk_live_nzKr98C6PTuUNh4BEXa3GzQ3',
    porchdoorUrl: 'https://portal.buyersedgeplatform.com'
}

const carol = {
    apiURL: 'http://api.rebate.local',
    foodBamURL: 'https://dev-app.foodbam.com',
    foodBamApiURL: 'https://api-dev.foodbam.com',
    foodBamStripeKey: 'pk_test_XOsf0MfXfRjzMplUTayunobe',
    porchdoorUrl: 'https://dev-portal.buyersedgeplatform.com'
}

const jp = {
    apiURL: 'http://localhost:9090',
    foodBamURL: 'http://dev-app.foodbam.com',
    foodBamApiURL: 'https://api-dev.foodbam.com',
    foodBamStripeKey: 'pk_test_XOsf0MfXfRjzMplUTayunobe',
    porchdoorUrl: 'https://dev-portal.buyersedgeplatform.com'
}

const config = (() => {
    switch(process.env.REACT_APP_ENVIRONMENT) {
        case 'development': return development;
        case 'staging': return staging;
        case 'production': return production;
        case 'carol': return carol;
        case 'jp': return jp;
        default: return development;
    }
})();

const company = {
  id: process.env.REACT_APP_COMPANY_ID
}

export const COMPANY_ID = {
  DA: 1,
  DVPG: 2,
  CC: 3,
  S1P: 4,
  SS: 5
}

const colors = {
    primary: process.env.REACT_APP_COMPANY_COLOR_PRIMARY,
    secondary: process.env.REACT_APP_COMPANY_COLOR_SECONDARY,
    tertiary: process.env.REACT_APP_COMPANY_COLOR_TERTIARY,
    rbLightAqua: '#F8F8F8',
    rbAqua: '#eef3f6',
    rbDarkAqua: '#dce8f2',
    rbDarkBlueOpaque: '#143357',
    rbDarkBlue: '#23408F',
    rbBasicBlue: '#006DBA',
    rbMidBlue: '#334F9A',
    rbBlue: '#0071CE',
    rbWhite: '#ffffff',
    rbBlack: '#202020',
    rbGrey: '#8D8D90',
    rbGreytext: '#76777B',
    rbGreyHome: '#F0F0F2',
    rbGreyNew: '#F5F5F5',
    rbLightGrey: '#D7D8D9',
    rbDarkGrey: '#909090',
    rbDarkerGrey: '#7F8083',
    rbYellow: '#FA9016',
    rbDarkYellow: '#F18C18',
    rbYellowLight :'#FB9B26',
    rbPurple: '#766ED7',
    rbLightShadow: '#0000001a',
    rbBlueBorder: '#006DBA',
    rbGreen: '#00C283',
    rbRed: '#AF2127',
    rbRedLight: '#FC745A'
}

let colorsGraph = getColorPaletteInit(company.id);
colorsGraph = colorsGraph.split(',').map(c => c.trim());
const colorOne  = colorsGraph[0] || '#FA9016';
const colorTwo = colorsGraph[1] || '#334F9A';
const colorThree = colorsGraph[2] || '#8A43A4';
const colorFour = colorsGraph[3] || '#FB4726';
const colorFive = colorsGraph[4] || '#26C0FB';
const colorSix = colorsGraph[5] || '#C2185B';
const colorSeven = colorsGraph[6] || '#827717';
const colorEight = colorsGraph[7] || '#37474F';
const colorNine = colorsGraph[8] || '#FF5722';

const colorPalette = [
    colorOne, colorTwo, colorThree, colorFour, colorFive,
    colorSix, colorSeven, colorEight, colorNine, '#43A047',
    '#009688', '#7986CB', '#A1887F', '#00ACC1', '#424242',
    '#FFD600', '#B71C1C', '#78909C', '#EF5350', '#212121',
    '#7B1FA2', '#795548', '#BA68C8','#BF360C', '#5E35B1',
    '#FF7043', '#7C4DFF','#283593', '#FF6D00', '#FFAB00',
    '#FF9800', '#304FFE', '#FFCA28', '#2196F3', '#FFEA00',
    '#0091EA', '#03A9F4', '#00695C', '#388E3C', '#2E7D32',
    '#558B2F', '#33691E', '#455A64','#546E7A', '#607D8B',
    '#616161', '#757575', '#3E2723', '#5D4037', '#6D4C41',
    '#8D6E63', '#FF3D00', '#D84315',  '#DD2C00','#E64A19',
    '#F4511E', '#1B5E20', '#4CAF50', '#00897B', '#00796B',
    '#004D40', '#006064', '#00838F', '#0097A7', '#FF1744',
    '#00BCD4', '#039BE5', '#0288D1', '#0277BD', '#01579B',
    '#1E88E5', '#1976D2', '#1565C0', '#0D47A1', '#448AFF',
    '#263238', '#2979FF', '#FF4081', '#5C6BC0', '#3F51B5',
    '#3949AB', '#536DFE', '#4E342E'
]

const countries = [
    {
        'name': 'Canada',
        'abbreviation': 'CA'
    },
    {
        'name': 'United States',
        'abbreviation': 'US'
    }
]

const caProvinces = [
    {
        'name': 'Alberta',
        'abbreviation': 'AB'
    },
    {
        'name': 'British Columbia',
        'abbreviation': 'BC'
    },
    {
        'name': 'Manitoba',
        'abbreviation': 'MB'
    },
    {
        'name': 'New Brunswick',
        'abbreviation': 'NB'
    },
    {
        'name': 'Newfoundland and Labrador',
        'abbreviation': 'NL'
    },
    {
        'name': 'Northwest Territories',
        'abbreviation': 'NT'
    },
    {
        'name': 'Nova Scotia',
        'abbreviation': 'NS'
    },
    {
        'name': 'Nunavut',
        'abbreviation': 'NU'
    },
    {
        'name': 'Ontario',
        'abbreviation': 'ON'
    },
    {
        'name': 'Prince Edward Island',
        'abbreviation': 'PE'
    },
    {
        'name': 'Quebec',
        'abbreviation': 'QC'
    },
    {
        'name': 'Saskatchewan',
        'abbreviation': 'SK'
    },
    {
        'name': 'Yukon',
        'abbreviation': 'YT'
    }
]

const usStates = [
    {
        "name": "Alabama",
        "abbreviation": "AL"
    },
    {
        "name": "Alaska",
        "abbreviation": "AK"
    },
    {
        "name": "Arizona",
        "abbreviation": "AZ"
    },
    {
        "name": "Arkansas",
        "abbreviation": "AR"
    },
    {
        "name": "California",
        "abbreviation": "CA"
    },
    {
        "name": "Colorado",
        "abbreviation": "CO"
    },
    {
        "name": "Connecticut",
        "abbreviation": "CT"
    },
    {
        "name": "Delaware",
        "abbreviation": "DE"
    },
    {
        "name": "District Of Columbia",
        "abbreviation": "DC"
    },
    {
        "name": "Florida",
        "abbreviation": "FL"
    },
    {
        "name": "Georgia",
        "abbreviation": "GA"
    },
    {
        "name": "Hawaii",
        "abbreviation": "HI"
    },
    {
        "name": "Idaho",
        "abbreviation": "ID"
    },
    {
        "name": "Illinois",
        "abbreviation": "IL"
    },
    {
        "name": "Indiana",
        "abbreviation": "IN"
    },
    {
        "name": "Iowa",
        "abbreviation": "IA"
    },
    {
        "name": "Kansas",
        "abbreviation": "KS"
    },
    {
        "name": "Kentucky",
        "abbreviation": "KY"
    },
    {
        "name": "Louisiana",
        "abbreviation": "LA"
    },
    {
        "name": "Maine",
        "abbreviation": "ME"
    },
    {
        "name": "Maryland",
        "abbreviation": "MD"
    },
    {
        "name": "Massachusetts",
        "abbreviation": "MA"
    },
    {
        "name": "Michigan",
        "abbreviation": "MI"
    },
    {
        "name": "Minnesota",
        "abbreviation": "MN"
    },
    {
        "name": "Mississippi",
        "abbreviation": "MS"
    },
    {
        "name": "Missouri",
        "abbreviation": "MO"
    },
    {
        "name": "Montana",
        "abbreviation": "MT"
    },
    {
        "name": "Nebraska",
        "abbreviation": "NE"
    },
    {
        "name": "Nevada",
        "abbreviation": "NV"
    },
    {
        "name": "New Hampshire",
        "abbreviation": "NH"
    },
    {
        "name": "New Jersey",
        "abbreviation": "NJ"
    },
    {
        "name": "New Mexico",
        "abbreviation": "NM"
    },
    {
        "name": "New York",
        "abbreviation": "NY"
    },
    {
        "name": "North Carolina",
        "abbreviation": "NC"
    },
    {
        "name": "North Dakota",
        "abbreviation": "ND"
    },
    {
        "name": "Ohio",
        "abbreviation": "OH"
    },
    {
        "name": "Oklahoma",
        "abbreviation": "OK"
    },
    {
        "name": "Oregon",
        "abbreviation": "OR"
    },
    {
        "name": "Pennsylvania",
        "abbreviation": "PA"
    },
    {
        "name": "Puerto Rico",
        "abbreviation": "PR"
    },
    {
        "name": "Rhode Island",
        "abbreviation": "RI"
    },
    {
        "name": "South Carolina",
        "abbreviation": "SC"
    },
    {
        "name": "South Dakota",
        "abbreviation": "SD"
    },
    {
        "name": "Tennessee",
        "abbreviation": "TN"
    },
    {
        "name": "Texas",
        "abbreviation": "TX"
    },
    {
        "name": "Utah",
        "abbreviation": "UT"
    },
    {
        "name": "Vermont",
        "abbreviation": "VT"
    },
    {
        "name": "Virginia",
        "abbreviation": "VA"
    },
    {
        "name": "Washington",
        "abbreviation": "WA"
    },
    {
        "name": "West Virginia",
        "abbreviation": "WV"
    },
    {
        "name": "Wisconsin",
        "abbreviation": "WI"
    },
    {
        "name": "Wyoming",
        "abbreviation": "WY"
    }
];

const links = [
    {
        "name": "Alliance Purchasing",
        "desc": "",
        "abbrev": "alliancepurchasing",
        "type": "channel",
        "code": "ALL",
        "id": 0,
        "platformId": "CP-000709"
    },
    {
        "name": "Buyers Edge Purchasing",
        "desc": "",
        "abbrev": "bepl",
        "type": "brand",
        "code": "BEPL"
    },
    {
        "name": "Consolidated Concepts",
        "desc": "",
        "abbrev": "cc",
        "type": "brand",
        "code": "CC"
    },
    {
        "name": "Source1 Purchasing",
        "desc": "",
        "abbrev": "s1p",
        "type": "brand",
        "code": "S1P"
    },
    {
        "name": "Dining Alliance",
        "desc": "",
        "abbrev": "da",
        "type": "brand",
        "code": "DA",
    },
    {
        "name": "Dining Alliance",
        "desc": "Dining Alliance",
        "abbrev": "da",
        "type": "channel",
        "code": "DA",
        "id": 0,
        "platformId": "CP-000686"
    },
    {
        "name": "Buyers Edge Platform",
        "desc": "",
        "abbrev": "be",
        "type": "brand",
        "code": "BE"
    },
    {
        "name": "Buyers Edge Platform",
        "desc": "Buyers Edge Platform",
        "abbrev": "be",
        "type": "channel",
        "code": "be",
        "id": 0,
        "platformId": "CP-000089"
    },
    {
        "name": "CTB Foodservice Consultants",
        "desc": "CTB Foodservice Consultants",
        "abbrev": "ctbfsc",
        "type": "channel",
        "code": "ctbfsc",
        "id": 213,
        "platformId": "CP-000328"
    },
    {
        "name": "Orca",
        "desc": "Orca",
        "abbrev": "orca",
        "type": "channel",
        "code": "orca",
        "id": 215,
        "platformId": "CP-000356"
    },
    {
        "name": "xtraCHEF",
        "desc": "xtraCHEF",
        "abbrev": "xtrachef",
        "type": "channel",
        "code": "xtrachef",
        "id": 112,
        "platformId": "CP-000324"
    },
    {
        "name": "The Restaurant Expert",
        "desc": "TheRestaurantExpert.com",
        "abbrev": "restexpert",
        "type": "channel",
        "id": 89,
        "platformId": "CP-000173"
    },
    {
        "name": "Leverage Rebates",
        "desc": "Leverage Rebates",
        "abbrev": "leverage",
        "type": "channel",
        "code": "leverage",
        "id": 0,
        "platformId": "CP-none"
    },
    {
        "name": "Restaurant Owner",
        "desc": "Restaurant Owner",
        "abbrev": "restowner",
        "type": "channel",
        "code": "restowner",
        "id": 0,
        "platformId": "CP-000782"
    },
    {
        "name": "Restaurant Systems Pro",
        "desc": "Restaurant Systems Pro",
        "abbrev": "restaurantpro",
        "type": "channel",
        "code": "restaurantpro",
        "id": 0,
        "platformId": "CP-000173"
    },
    {
        "name": "Florida Restaurant & Lodging Association",
        "desc": "Florida Restaurant & Lodging Association",
        "abbrev": "flrestaurantlodging",
        "type": "channel",
        "code": "flrestaurantlodging",
        "id": 0,
        "platformId": "CP-000576"
    },
    {
        "name": "Florida Restaurant & Lodging Association",
        "desc": "Florida Restaurant & Lodging Association",
        "abbrev": "frla",
        "type": "channel",
        "code": "frla",
        "id": 0,
        "platformId": "CP-000576"
    },
    {
        "name": "Beyond",
        "desc": "Beyond",
        "abbrev": "beyond",
        "type": "channel",
        "code": "beyond",
        "id": 0,
        "platformId": "CP-000689"
    },
    {
        "name": "Orderly",
        "desc": "Orderly",
        "abbrev": "orderly",
        "type": "channel",
        "code": "orderly",
        "id": 0,
        "platformId": "CP-000623"
    },
    {
        "name": "Best Western",
        "desc": "Best Western",
        "abbrev": "bestwestern",
        "type": "channel",
        "code": "bw",
        "id": 0,
        "platformId": "CP-000711"
    },
    {
        "name": "Recipe Costing",
        "desc": "Recipe Costing",
        "abbrev": "recipecosting",
        "type": "channel",
        "code": "recipecosting",
        "id": 0,
        "platformId": "CP-000439"
    },
    {
        "name": "CirrusPOS",
        "desc": "CirrusPOS",
        "abbrev": "cirrus",
        "type": "channel",
        "code": "cp",
        "id": 0,
        "platformId": "CP-000439"
    },
    {
        "name": "Core Restaurant Marketing LLC",
        "desc": "Core Restaurant Marketing LLC",
        "abbrev": "crm",
        "type": "channel",
        "code": "crm",
        "id": 0,
        "platformId": "CP-000569"
    },
    {
        "name": "The Restaurants Profit LLC",
        "desc": "The Restaurants Profit LLC",
        "abbrev": "trp",
        "type": "channel",
        "code": "trp",
        "id": 0,
        "platformId": "CP-000433"
    },
    {
        "name": "Cornerstone Foodservice Consulting LLC",
        "desc": "Cornerstone Foodservice Consulting LLC",
        "abbrev": "cfc",
        "type": "channel",
        "code": "cfc",
        "id": 0,
        "platformId": "CP-000328"
    },
    {
        "name": "Texas Restaurant Association",
        "desc": "Texas Restaurant Association",
        "abbrev": "tra",
        "type": "channel",
        "code": "tra",
        "id": 0,
        "platformId": "CP-000703"
    },
    {
        "name": "Optimum Control",
        "desc": "Optimum Control",
        "abbrev": "oc",
        "type": "channel",
        "code": "oc",
        "id": 0,
        "platformId": "CP-000690"
    },
    {
        "name": "Lift Restaurant Consulting",
        "desc": "Lift Restaurant Consulting",
        "abbrev": "lrc",
        "type": "channel",
        "code": "lrc",
        "id": 0,
        "platformId": "CP-000570"
    },
    {
        "name": "TouchBistro",
        "desc": "TouchBistro",
        "abbrev": "tb",
        "type": "channel",
        "code": "tb",
        "id": 0,
        "platformId": "CP-000853"
    },
    {
        "name": "Meallogix",
        "desc": "Meallogix",
        "abbrev": "meal",
        "type": "channel",
        "code": "tb",
        "id": 0,
        "platformId": "CP-000840"
    },
    {
        "name": "ezCarter",
        "desc": "ezCarter",
        "abbrev": "ez",
        "type": "channel",
        "code": "ez",
        "id": 0,
        "platformId": "CP-000982"
    },
    {
        "name": "MPP Marketing Group",
        "desc": "MPP Marketing Group",
        "abbrev": "mpp",
        "type": "channel",
        "code": "mpp",
        "id": 0,
        "platformId": "CP-000024"
    },
    {
        "name": "Harbortouch",
        "desc": "Harbortouch",
        "abbrev": "ht",
        "type": "channel",
        "code": "ht",
        "id": 0,
        "platformId": "CP-001077"
    },
    {
        "name": "Merchant Cost Consulting",
        "desc": "Merchant Cost Consulting",
        "abbrev": "mcc",
        "type": "channel",
        "code": "mcc",
        "id": 0,
        "platformId": "CP-001093"
    },
    {
        "name": "Genius Food Manager",
        "desc": "Genius Food Manager",
        "abbrev": "gfm",
        "type": "channel",
        "code": "gfm",
        "id": 0,
        "platformId": "CP-001077"
    }
]

const fullstoryOrg = 'KB8HW';

const flagTypesRadisson = [
    'Country Inn & Suites',
    'Park Inn by Radisson',
    'Park Plaza',
    'Radisson',
    'Radisson Blu',
    'Radisson Red',
];

const flagTypesBestWestern = [
    'Best Western',
    'Best Western Plus',
    'Best Western premier',
    'Best Western Premier Collection',
    'Executive Residency',
    'Glo',
    'Sure Stay',
    'Sure Stay Collection',
    'Sure Stay Plus',
    'Sure Stay Premier',
];

const gpoNameOptions = [
    {label: 'Aramark', value: 'Aramark'},
    {label: 'Avendra', value: 'Avendra'},
    {label: 'Compass', value: 'Compass'},
    {label: 'Entegra', value: 'Entegra'},
    {label: 'Foodbuy Foodservice', value: 'Foodbuy Foodservice'},
    {label: 'Navigator Group Purchasing', value: 'Navigator Group Purchasing'},
    {label: 'Premier Inc', value: 'Premier Inc'},
    {label: 'IPS Rebates', value: 'IPS Rebates'}
];

const signUpStepOneTitleOptions = [
    'Owner',
    'Chef',
    'General Manager',
    'Kitchen Manager',
    'CFO',
    'COO',
    'Accounting',
    'CEO/President',
    'Vice President',
    'Food Service Director',
    'VP of Purchasing/Supply Chain',
    'Other',
];

const LOTLegalText = (gpoName, bussinessName) => {
    const today = moment().format('MMMM d, YYYY');
    return `Effective ${today}, ${bussinessName} will no longer participate in any programs offered by ${gpoName}. Buyers Edge Platform is hereby authorized to communicate this termination with my former GPO on Member's behalf. However, Member hereby acknowledges that it is the Member's responsibility to provide a copy of the letter of termination ("LOT") to the former GPO of record. A copy of the LOT of termination will be supplied to Member for the purposes of communicating the same to Member's former GPO.`;
};

const regexEscape = (str) => {
    return str.replace(new RegExp('[.\\\\+*?\\[\\^\\]$(){}=!<>|:\\-]', 'g'), '\\$&');
};

export default {
    // Add common config values here
    ...config,
    placeKey: process.env.REACT_APP_PLACE_KEY,
    company,
    colors,
    colorPalette,
    countries,
    caProvinces,
    usStates,
    fullstoryOrg,
    links,
    flagTypesRadisson,
    flagTypesBestWestern,
    gpoNameOptions,
    signUpStepOneTitleOptions,
    LOTLegalText,
    regexEscape,
};

// Create a .env file at the root of the project to pick your enviroment
/*
REACT_APP_ENVIRONMENT=development
*/
