import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconLock = props => (
  <svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="right-side-bar" transform="translate(-6.000000, -293.000000)" fill={!props.isActive ? props.color : props.activeColor}>
        <g id="security">
          <g transform="translate(0.000000, 248.000000)">
            <g id="step" transform="translate(12.000000, 44.000000)">
              <g id="icons/lock" transform="translate(0.000000, 1.000000)">
                <path d="M3,30 L17,30 C18.654,30 20,28.654 20,27 L20,17 C20,15.698 19.161,14.598 18,14.184 L18,8.539 C18,3.831 14.411,0 10,0 C5.589,0 2,3.831 2,8.539 L2,14.185 C0.839,14.598 0,15.698 0,17 L0,27 C0,28.654 1.346,30 3,30 Z M10,2 C13.309,2 16,4.933 16,8.539 L16,14 L4,14 L4,8.539 C4,4.933 6.691,2 10,2 Z M2,17 C2,16.449 2.448,16 3,16 L17,16 C17.552,16 18,16.449 18,17 L18,27 C18,27.551 17.552,28 17,28 L3,28 C2.448,28 2,27.551 2,27 L2,17 Z" id="Shape" fillRule="nonzero"></path>
                <path d="M9,22.722 L9,25 C9,25.552 9.447,26 10,26 C10.553,26 11,25.552 11,25 L11,22.722 C11.595,22.375 12,21.737 12,21 C12,19.897 11.103,19 10,19 C8.897,19 8,19.897 8,21 C8,21.737 8.405,22.375 9,22.722 Z" id="Path"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
</svg>
);

IconLock.propTypes = {
  color: PropTypes.string,
  activeColor: PropTypes.string,
  isActive: PropTypes.bool,
  size: PropTypes.number,
};

IconLock.defaultProps = {
  activeColor: config.colors.rbYellow,
  color: config.colors.rbWhite,
  isActive: false,
  size: 16,
};

export default IconLock;