import React from 'react';
import InvoiceScanningHeader from './InvoiceScanningHeader';
import InvoiceScanningTop from './InvoiceScanningTop';
import InvoiceScanningDetail from './InvoiceScanningDetail';
import useInvoiceScanning from './hooks/useInvoiceScanning';
import { isMobile } from 'react-device-detect';

const InvoiceScanning = (props) => {
  const { history } = props;
  const { search, optionsLocation, merchants, invoicesAll, selectedOption, onUploadNow, onChangeLocation, onChangeSearch, onEnterSearch, onChangeSetDate, setTooltipByItem } = useInvoiceScanning();
  const propsTop = {
    history,
    onUploadNow,
    optionsLocation,
    selectedOption,
    onChangeLocation,
    onChangeSearch,
    onEnterSearch,
    onChangeSetDate,
    search
  }

  const propsDetail = {
    invoicesAll,
    onUploadNow,
    setTooltipByItem
  }

  if(merchants.length === 0 && invoicesAll.length === 0) {
    return null;
  }
  return (
    <>
      <InvoiceScanningTop {...propsTop} />
      { !isMobile && <InvoiceScanningHeader />}
      <InvoiceScanningDetail {...propsDetail} />
    </>
  )
}

export default InvoiceScanning;