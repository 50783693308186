import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import ClaimCashDetailContent from './ClaimCashDetailContent';
class ClaimCashDetail extends Component {

  componentDidMount = () => {
    const { claimCash, history } = this.props;
    const { currentPayment } = claimCash;
    if(currentPayment.length <= 0) {
      history.push('/dashboard/claim');
    }
  }
  render() {
    const { history, dispatch  } = this.props;
    const propsClaim = {
      history,
      dispatch
    }
    return (
      <>
        { !isMobile && <ClaimCashDetailContent {...propsClaim} /> }
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    claimCash: state.claimCash
  };
}

export default connect(mapStateToProps)(ClaimCashDetail);