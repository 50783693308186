import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconArrowBack = props => (
  <svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 10 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="FAQ" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="3---FAQ" transform="translate(-16.000000, -58.000000)" fill={!props.isActive ? props.color : props.activeColor} fillRule="nonzero">
        <g id="icons/arrow-left" transform="translate(16.000000, 58.000000)">
            <polygon id="Path" points="9.6 0.97 8.565 0 0 8 8.565 16 9.6 15.035 2.075 8"></polygon>
        </g>
      </g>
    </g>
  </svg>
);

IconArrowBack.propTypes = {
  color: PropTypes.string,
  activeColor: PropTypes.string,
  isActive: PropTypes.bool,
  size: PropTypes.number
};

IconArrowBack.defaultProps = {
  activeColor: config.colors.rbYellow,
  color: config.colors.rbWhite,
  isActive: false,
  size: 16
};

export default IconArrowBack;