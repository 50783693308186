import numeral from "numeral";

export const isPhoneValid = (number, validateLenght) => {
  let pattern
  if (!number && !validateLenght) {
    return true;
  }
  if (validateLenght) {
    pattern = /[(]{1}[0-9]{3}[)]{1}[ ][0-9]{3}[-]{1}[0-9]{4}/g
  } else {
    pattern = /[0-9-()\s]$/g
  }
  return pattern.test(number);
}

export const filterPhone = (number, preValue) => {
  if(number.length>preValue.length){
      number = number.replace(/\D+/g, '');
      if (number.charAt(0) !== '(' && number.length > 0 && number.length < 5) {
        number = '(' + number;
      }
      if (number.charAt(4) !== ')' && number.length > 4 && number.length < 8) {
        if (number.charAt(0) !== '(') {
          number = '(' + number;
        }
        number = number.slice(0, 4) + ') ' + number.slice(4, number.length);
      }
      if (number.length > 7) {
        if (number.charAt(0) !== '(') {
          number = '(' + number;
        }
        if (number.charAt(4) !== ')') {
          number = number.slice(0, 4) + ') ' + number.slice(4, number.length);
        }
        if (number.charAt(9) !== '-' && number.length > 8) {
          number = number.slice(0, 9) + '-' + number.slice(9, number.length);
        }
      }
  }
  if (number.length > 13) {
    return number.slice(0, 14);
  }
  return number;
}

export const isEmailValid = (email) => {
  const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g
  return pattern.test(email);
};

export const isTextValid = (value, min, max) => {
  if (!value && min === 0) {
    return true;
  }
  if (!value || value.length < min || value.length > max ) {
    return false;
  }
  return true;
}

export const isCharacterValid = (value, min, max) => {
  const pattern = /[`~,.<>;%@$?!*#]/;
  if (pattern.test(value)) {
    return false;
  }
  if (value.length < min || value.length > max ) {
    return false;
  }
  return true;
}

export const isNumberValid = (value, min, max) => {
  const number = numeral(value).value();

  if (number >= min && number <= max ) {
    return true;
  }
  return false;
}

export const isZipCode = (value, country = 'us') => {
  const countryUC = country.toUpperCase();
  if(countryUC === 'CA') {
    const pattern = /[a-zA-Z0-9]{3}[ ][a-zA-Z0-9]{3}/g;
    if (pattern.test(value)) {
      return true;
    }
    return false;
  } else {
    //US By Default
    const pattern = /^[0-9]{5}?$/;
    if (pattern.test(value)) {
      return true;
    }
    return false;
  }
};

export const isNumber = (value, size) => {
  const pattern = /^[0-9]*$/;
  if (pattern.test(value)) {
    if (value.length !== size && size) {
      return false;
    }
    return true;
  }
  else {
    return false;
  }
};
