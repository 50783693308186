import React, { Component } from 'react';
import { findModal } from '../../../MasterComponents/Modals/ModalsActions';
import { isMobile } from 'react-device-detect';
import Modal from '../../../MasterComponents/Modals/Modal';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import Button from '../../../MasterComponents/Button/Button';

class FormEditModal extends Component {

    cancel = () => {
        const { modals } = this.props;
        findModal(modals, 'idFormEditModal').close();
    }

    ok = () => {
        this.props.editFields();
        this.cancel();
    }

    render() {
        return (
             <Modal id='idFormEditModal' size='md'>
                <ModalBody>
                    <GridRow className='pt-3'>
                        <GridCol className='text-center' style={{fontSize:'14px', lineHeight:'20px'}}>
                            Currently we only allow one bank account to be linked to a quickbooks vendor code. The account we populated is the most recent account you used. If you change this account any pending payments will be sent to the new account you enter. Please click ok to continue or cancel.
                        </GridCol>
                    </GridRow>
                    <GridRow className='mt-4'>
                        
                        <GridCol size={6}>
                            <Button type='button' variant='white' size={isMobile ? 'sm' : 'md'} text='CANCEL' onClick={this.cancel}></Button>
                        </GridCol>
                        <GridCol className='text-right' size={6}>
                            <Button type='button' variant='primary' size={isMobile ? 'sm' : 'md'} text='OK' onClick={this.ok}></Button>
                        </GridCol>
                    </GridRow>
                </ModalBody>
            </Modal>
        );
    }
}

export default FormEditModal;