import React from 'react';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import { Col } from 'reactstrap';
import config from '../../../../config/config';

const ContractFilters = ({open, toggle, select, ...filters}) => {
	const optionsMap = ['Active', 'Expired', 'Expiring'].map(opt => {
		return (
			<div key={`filter_${opt}`} className='px-0 clickable border-top d-flex flex-row align-items-center dropdown-item'>
				<Col className='text-grey pr-0' style={{maxWidth: '20px'}}>
					<div className='custom-control custom-checkbox'>
						<input onChange={() => select(opt.toString().toLowerCase())} checked={filters[opt.toString().toLowerCase()]} type='checkbox' className='custom-control-input' id={`filter_checkbox_${opt}`} />
						<label className='custom-control-label' htmlFor={`filter_checkbox_${opt}`}></label>
					</div>
				</Col>
				<Col className='py-2' onClick={() => select(opt.toString().toLowerCase())}>
					{opt}
				</Col>
			</div>
		)
	});

	const allChecked = filters.active && filters.expired && filters.expiring;
	const labels = [{check: filters.active, label: 'Active'}, {check: filters.expired, label: 'Expired'}, {check: filters.expiring, label: 'Expiring'}].filter(o => o.check).map(o => o.label);

	return (
		<div id='contractsDropdown' className='dropdown keep-open' style={{zIndex:1, maxWidth: '300px'}}>
			<button className='btn rb-dropdown rb-input form-control' type='button' id='locationDropdownMenuButton' onClick={toggle}>
				{allChecked ? 'All': labels.map((l, i) => (`${l}${i === labels.length-2 ? ' and ' : ''}`))} Contracts
				{!open ? <IoMdArrowDropdown className='position-absolute dd-arrow' size={18} color={config.colors.rbBlack} /> : <IoMdArrowDropup className='position-absolute dd-arrow' size={18} color={config.colors.rbBlack} />}
			</button>
			<div id='contractsDropdown-menu' className={'dropdown-menu w-100 text-size-14 scrollbar-insights ' + (open ? ' show' : '')} role='menu' style={{maxHeight: '300px', overflow: 'auto'}}>
          <div onBlur={toggle} tabIndex={1}>
            <div className='px-0 clickable d-flex flex-row align-items-center dropdown-item'>
              <Col className='text-grey pr-0' style={{maxWidth: '20px'}}>
                <div className='custom-control custom-checkbox'>
                  <input onChange={() => select('all')} checked={allChecked} type='checkbox' className='custom-control-input' id={`filter_checkbox_all`} />
                  <label className='custom-control-label' htmlFor={`filter_checkbox_all`}></label>
                </div>
              </Col>
              <Col className='py-2' onClick={() => select('all')}>
                All Contracts
              </Col>
            </div>
            {optionsMap}
          </div>
        </div>
		</div>
	);
};

export default ContractFilters;