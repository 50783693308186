import React from 'react';
import PropTypes from 'prop-types';

import logo from '../../assets/images/vault-logo.svg';

const LogoVA = props => (
  <img id={props.id} className={props.className} src={logo} alt='Vault Logo' />
);

LogoVA.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string
}

export default LogoVA;