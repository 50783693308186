import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { spinner } from '../../../MasterComponents/Spinner/SpinnerActions';
import moment from 'moment';
import PropTypes from 'prop-types';
import Card from '../../../MasterComponents/Card/Card';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import config from '../../../config/config';
import IconClose from '../../../MasterComponents/Icons/IconClose';
import DashboardApi from '../../Dashboard/DashboardApi'
import IconAlert from '../../../MasterComponents/Icons/IconAlert';
import IconProcessing from '../../../MasterComponents/Icons/IconProcessing';
import IconSuccess from '../../../MasterComponents/Icons/IconSuccess';
import DistributorCardOneDesktop from './DistributorCardOneDesktop';
import DistributorCardOneMobile from './DistributorCardOneMobile';

class DistributorCardOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHistory: false,
      files:[]
    };
  }
  
  showHistory = (e) => {
    const { dispatch, merchantDistributor } = this.props
    e.stopPropagation();
    this.setState({
      showHistory: true
    });
    dispatch(spinner(true));
    DashboardApi.getDistributorSubmissions(merchantDistributor.distributorId).then(({data}) => {
      dispatch(spinner(false));
      const result = data.data;
      this.setState({
        files: result.map((res, i) => {
          return {
            id: res.id,
            name: `File ${i+1}`,
            startDate: moment(res.startDate).format('M-DD-YY'),
            endDate: moment(res.endDate).format('M-DD-YY')
          }
        })
      });
    });
  }
  closeHistory = (e) => {
    e.stopPropagation();
    this.setState({
      showHistory: false
    });
  }

  render() {
    const { onClickUploadNow, onClickActivate, onClickEditCredentials, clickActivateEdit, clickActivateDelete, goToEditDistributor } = this.props;
    const  md  = this.props.merchantDistributor;
    const { distributor } = this.props.merchantDistributor;
    const { showHistory, files } = this.state;

    const isActive = md.isActive;
    const isManual = md.submissionType === 'manual';
    const isNextLoadDateValid = moment(md.nextLoadDate).isValid();
    const nextLoadDate = moment(md.nextLoadDate, 'YYYY-MM-DD');
    const lastLoadDate = moment(md.lastLoadDate, 'YYYY-MM-DD');
    const day = nextLoadDate.format('D');
    const diff = nextLoadDate.diff(lastLoadDate, 'days');

    const isOrange = isActive && isManual && isNextLoadDateValid && day > 0 && day <= 10 && (diff > 0 || isNaN(diff));

    const statusOnlineColor =  md.status === 'success' ? config.colors.rbGreen : (md.status === 'warning' ? config.colors.rbYellowLight : config.colors.rbRedLight)
    const statusEditColor =  md.status === 'error' ? config.colors.rbRedLight : config.colors.rbDarkerGrey;
    const statusCredentialsColor =  md.statusType === 'failedPassword' ? true : false;
    const statusOnlineIcon = md.status === 'success' ? <IconSuccess size={20} color={statusOnlineColor} /> : ((md.statusType === 'processing' || (md.status === null && md.statusType === null)) ? <IconProcessing size={20} color={statusOnlineColor} /> : <IconAlert size={20} color={statusOnlineColor} />);

    const propsCardOne = {
      md,
      distributor,
      isActive,
      isManual,
      isNextLoadDateValid,
      nextLoadDate,
      lastLoadDate,
      day,
      diff,
      isOrange,
      statusOnlineColor,
      statusEditColor,
      statusCredentialsColor,
      statusOnlineIcon,
      onClickActivate,
      clickActivateEdit,
      clickActivateDelete,
      onClickEditCredentials,
      onClickUploadNow,
      goToEditDistributor,
      showHistory: this.showHistory
    }

    if (showHistory) {
      const filesMap = files.map((file) => (
        <GridRow key={file.id} style={{padding: '0 20px 20px'}}>
          <GridCol className='text-size-14 text-line-height-20 text-grey text-weight-bold'>
            {file.name}
          </GridCol>
          <GridCol className='text-size-14 text-line-height-20 text-left text-weight-bold'>
            {file.startDate}
          </GridCol>
          <GridCol className='text-size-14 text-line-height-20 text-left text-weight-bold'>
            {file.endDate}
          </GridCol>
        </GridRow>
      ))
      return (
        <Card className={(isMobile ? 'px-0 py-0 distributor-card mobile' : 'px-1 py-4 px-xl-3 py-xl-4 distributor-card') + (isOrange ? ' orange' : '')} size={isMobile ? 'pb-2 col-12' : 'py-2 distributor-parent-card'}>
          <GridRow className={'text-grey text-size-14' + (isMobile ? ' pb-0' : ' p-0')} justify='end' style={{height: '17px'}}>
            <GridCol className='text-right' onClick={this.showHistory} maxWidth='171px'>
              <span onClick={this.closeHistory} className='clickable'><IconClose color={isOrange ? config.colors.rbWhite : config.colors.rbGrey} fontSize='24px' /></span>
            </GridCol>
          </GridRow>
          <GridRow className={isMobile ? '' : 'pt-1'}>
            <GridCol className='text-center'>
            <h5 className='font-weight-bolder'>{distributor.nickname} Submissions</h5>
            </GridCol>
          </GridRow>
          <GridRow className='p-0' style={{maxHeight: '270px', overflowX:'hidden', overflowY:'auto'}}>
            <GridCol className='p-0'>
              <GridRow className='text-size-12 pb-3' style={{padding:'0 20px 20px'}}>
                <GridCol>
                  &nbsp;
                </GridCol>
                <GridCol>
                  Start Date
                </GridCol>
                <GridCol>
                  End Date
                </GridCol>
              </GridRow>
              {filesMap}
            </GridCol>
          </GridRow>
        </Card>
      )
    }
    if (isMobile && distributor) {
      return (
        <DistributorCardOneMobile {...propsCardOne}  />
      )
    }
    return (
      <DistributorCardOneDesktop {...propsCardOne} />
    );
  }
}

DistributorCardOne.propTypes = {
  onClickUploadNow: PropTypes.func.isRequired,
  onClickActivate: PropTypes.func.isRequired,
  isActive: PropTypes.bool
};

DistributorCardOne.defualtProps = {
  isActive: false
}

export default DistributorCardOne;
