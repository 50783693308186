import React, { Component } from 'react'
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { findModal } from '../../../MasterComponents/Modals/ModalsActions';
import Modal from '../../../MasterComponents/Modals/Modal';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import Button from '../../../MasterComponents/Button/Button';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import InputLabel from '../../../MasterComponents/InputLabel/InputLabel';

class DistributorReportsForMapModal extends Component {
  closeModal = () => {
    const { modals } = this.props;
    findModal(modals, 'distributorReportsForMapModalId').close();
  }
  render() {
    const { nickname } = this.props;
    return (
      <Modal id='distributorReportsForMapModalId' centered={isMobile} >
        <ModalBody className='modal-reports-map text-center' >
          <GridRow>
            <GridCol>
                <InputLabel className='modal-reports-title'>Congrats!</InputLabel><br/>
                <InputLabel className='modal-reports-subtitle mt-2'>You've selected a qualified {nickname} distributor partner!</InputLabel><br/>
                <InputLabel className='modal-reports-text mt-3'>Because {nickname} partners with this distributor, your data feed has been automatically connected.</InputLabel><br/>
                <InputLabel className='modal-reports-text'>You should begin automatically receiving Cash Back soon.</InputLabel><br/>               
            </GridCol>
          </GridRow>
          <GridRow className='mt-5'>
            <GridCol>
                <Button variant='yellow' onClick={this.closeModal} className='clickable' size='lg' text='OK' />
            </GridCol>
          </GridRow>
        </ModalBody>
      </Modal>
    );
  }
};

const mapStateToProps = state => {
  return {
    nickname: state.company.nickname
  }
}

export default connect(mapStateToProps)(DistributorReportsForMapModal);