import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import IconCircle from '../../MasterComponents/Icons/IconCircle';
import { getHomeInsightsGraphTextColor } from '../../config/Company/CompanyHelper';
import { connect } from 'react-redux';
import { getRandomColor } from '../../utils/utils';

const HomeInsightsGraph = (props) => {
  const [showVolumes, setShowVolumes] = useState({show: false, data: []});
  const { insightsOpportunitySummary, companyId, purchasedRebateItems, purchasedNonRebateItems } = props;
  const homeInsightsGraphTextColor = getHomeInsightsGraphTextColor(companyId);
  const chartOptions = {
    chart: {
      height: '90%'
    },
    credits: {
      enabled: false
    },
    title: {
      text: ''
    },
    series: [{
      type: 'pie',
      name: 'Items',
      innerSize: '0%',
      showInLegend: false,
      data: insightsOpportunitySummary
    }],
    tooltip: {
      valuePrefix: '$',
      backgroundColor: '#1A1A1A',
      borderColor: '#1A1A1A',
      borderRadius: 5,
      valueDecimals: 2,
      style: {
        color: 'white'
      },
      pointFormat: ' {point.y} ({point.percentage:.0f}%)</span>',
      formatter: function () {
        return this.key !== 'Hide' ? this.y.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})+'%' : false;
      }
    },
    plotOptions: {
      pie: {
        shadow: false,
        center: ['50%', '50%'],
        cursor: 'pointer',
        point: {
          events: {
            click: function (event) {
              const clickWhat = event.point.description;
              const data = clickWhat === 'Purchases without rebates and/or Deviations' ? purchasedNonRebateItems : purchasedRebateItems;
              setShowVolumes({
                show: true,
                data: data.sort((a, b) => {
                  if (a.purchase < b.purchase) {
                    return 1
                  }
                
                  if (a.purchase > b.purchase) {
                    return -1
                  }
                
                  return 0
                }).map((d,i) => ({description: d.description, y: d.purchase, color: getRandomColor(i+2)})).slice(0, 8)
              });
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        showInLegend: false,
      }
    }
  };

  const chartOptionsVolumes = {
    chart: {
      height: '90%',
    },
    credits: {
      enabled: false
    },
    title: {
      text: ''
    },
    series: [{
      type: 'pie',
      name: 'Items',
      innerSize: '0%',
      showInLegend: false,
      data: showVolumes.data
    }],
    tooltip: {
      valuePrefix: '$',
      backgroundColor: '#1A1A1A',
      borderColor: '#1A1A1A',
      borderRadius: 5,
      valueDecimals: 2,
      style: {
        color: 'white'
      },
      pointFormat: ' {point.y} ({point.percentage:.0f}%)</span>',
      formatter: function () {
        return this.key !== 'Hide' ? this.y.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})+'%' : false;
      }
    },
    plotOptions: {
      pie: {
        borderWidth: 1,
        shadow: false,
        center: ['50%', '50%'],
        cursor: 'pointer',
        dataLabels: {
          enabled: false
        },
        showInLegend: false,
      }
    }
  };

  const opportunityArray = insightsOpportunitySummary.map((d, i) => {
    return (
      <Row className={ isMobile ? '' : 'pt-1 pb-3'} key={`opo_${i}`}>
        <Col className={isMobile ? '' : 'pl-5'}>
          <IconCircle color={d.color} size={12} />
          <span className='px-3 text-grey-primary'>{d.description}</span>
        </Col>
        <Col xs={12} className={'text-size-36 line-height-58 pb-2 ' + (isMobile ? 'text-center' :'pl-5 pt-2 text-left')}>
          {(d.y || 0).toLocaleString('en', {minimumFractionDigits: 2})}%
        </Col>
      </Row>
    )
  })

  const volumesArray = [
    <Row className='no-gutters pb-2 clickable' key={`vol_back`} onClick={() => setShowVolumes({...showVolumes, show: false})}>
      <Col xs={8} className='text-left'>
        <span className='pl-3 text-primary text-left text-capitalize'>Go Back</span>
      </Col>
    </Row>,
  ...showVolumes.data.map((d, i) => {
    return (
      <Row className='no-gutters pb-2' key={`vol_${i}`}>
        <Col xs={8} className='text-left'>
          <IconCircle color={d.color} size={12} />
          <span className='pl-3 text-grey-primary text-left text-capitalize'>{d.description && d.description.toLowerCase()}</span>
        </Col>
        <Col className={'text-grey-primary pb-2 text-right'}>
          ${(d.y || 0).toLocaleString('en', {minimumFractionDigits: 2})}
        </Col>
      </Row>
    )
  })];

  return (
    <>
      <Row>
        <Col xs={isMobile ? 12 : 6} className='pr-0' style={{width: '60px', minWidth: '40%'}}>
          <HighchartsReact highcharts={Highcharts} options={showVolumes.show ? chartOptionsVolumes : chartOptions} />
        </Col>
        <Col xs={isMobile ? 12 : 6}  className='pl-0 pt-4'>
          {showVolumes.show ? volumesArray : opportunityArray}
        </Col>
      </Row>
      <Row className='text-size-18 line-height-22'>
        <Col style={{color: homeInsightsGraphTextColor}}>
          You could be earning more rebates!
        </Col>
      </Row>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    companyId: state.company.id
  };
};

export default connect(mapStateToProps)(HomeInsightsGraph);
