import React from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import config from '../../../config/config';
import Button from '../../../MasterComponents/Button/Button';
import DashedBox from '../../../MasterComponents/DashedBox/DashedBox';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import IconAdd from '../../../MasterComponents/Icons/IconAdd';
import { spinner } from '../../../MasterComponents/Spinner/SpinnerActions';
import DashboardApi from '../../Dashboard/DashboardApi';
import SignUpApi from '../../SignUp/SignUpApi';
import LocationBox from './LocationBox';
import axios from "axios";

const OnboardingLocationStep = ({ locations, dispatchLocations, validate, history, setMaxStep, addEmpty, country }) => {
  const companyId = useSelector(({ company }) => company.id);
  const dispatch = useDispatch();
  const locationsMap = locations.map((loc, i) => (
    <LocationBox key={`loc_${i}`} index={i} {...loc} dispatch={dispatchLocations} country={country} />
  ));

  const onClick = () => {
    if (validate()) {
      let calls = [];
      for (let i = 0; i < locations.length; i++) {
        const loc = locations[i];
        const params = {
          phoneNumber: loc.phoneNumber,
          nickname: loc.name,
          address: loc.street,
          city: loc.city,
          postcode: loc.zipcode,
          state: loc.state && loc.state.value,
          companyId: companyId
        };
        if (loc.id) {
          if (!loc.isEmpty) {
            calls.push(DashboardApi.editMerchant(loc.id, params));
          } else if (i !== 0) {
            calls.push(DashboardApi.deleteMerchant(loc.id));
          }
        } else if (!loc.isEmpty) {
          calls.push(DashboardApi.addMerchant(params));
        }
      }

      dispatch(spinner(true));
      axios.all(calls).then(axios.spread((...res) => {
        DashboardApi.getMerchants().then(({ data }) => {
          dispatch(spinner(false));
          const result = data.data;
          const updateLocations = result.map(l => {
            return {
              id: l.id,
              name: l.nickname,
              street: l.address,
              city: l.city,
              state: { label: config.usStates.filter(s => s.abbreviation === l.state)[0].name, value: l.state },
              zipcode: l.postcode,
              phoneNumber: l.phoneNumber,
              invalid: [],
              addressDetails: false
            }
          });
          smartifyLocations(updateLocations);
        }).catch(err => {
          dispatch(spinner(false));
          console.error(err);
        });
      })).catch(err => {
        dispatch(spinner(false));
        console.error(err);
      })
    }
  };

  const smartifyLocations = (original) => {
    const street = original.map(loc => ({
      address: loc.street,
      city: loc.city,
      state: loc.state.value,
      postcode: loc.zipcode
    }));

    dispatch(spinner(true));
    SignUpApi.getSmartyPlace(street, country).then(({ data }) => {
      dispatch(spinner(false));
      const smart = data.data;
      let calls = [];
      for (let i = 0; i < original.length; i++) {
        const loc = locations[i];
        let zipcode = smart[i].postcode.toString().trim()

        if (zipcode && country !== 'CA' && country !== 'ca') {
          if (zipcode.length > 5) zipcode = zipcode.substring(0, 5);
          if (zipcode.length < 5) zipcode = zipcode.padStart(5, '0');
        }

        const params = {
          phoneNumber: loc.phoneNumber,
          nickname: loc.name,
          ...smart[i],
          postcode: zipcode || '',
          companyId: companyId
        };
        calls.push(DashboardApi.editMerchant(loc.id, params));
      }

      dispatch(spinner(true));
      axios.all(calls).then(axios.spread((...res) => {
        DashboardApi.getMerchants().then(({ data }) => {
          dispatch(spinner(false));
          const result = data.data;
          const updateLocations = result.map(l => {
            return {
              id: l.id,
              name: l.nickname,
              street: l.address,
              city: l.city,
              state: { label: config.usStates.filter(s => s.abbreviation === l.state)[0].name, value: l.state },
              zipcode: l.postcode,
              phoneNumber: l.phoneNumber,
              invalid: [],
              addressDetails: false
            }
          });
          dispatchLocations({ type: 'RELOAD_LOCATIONS', payload: updateLocations });
          setMaxStep(2);
          history.push('/onboarding/2');
        }).catch(err => {
          dispatch(spinner(false));
          console.error(err);
        });
      })).catch(err => {
        dispatch(spinner(false));
        console.error(err);
      });
    }).catch(err => {
      dispatch(spinner(false));
      console.error(err);
    });
  };

  if (isMobile) {
    return (
      <div className='vh-100'>
        <div className='container flex-grow-1' style={{ paddingTop: '88px', paddingBottom: '100px' }}>
          <GridRow className='border-bottom pb-3'>
            <GridCol>
              We integrate with google maps to make this easy for you.
            </GridCol>
          </GridRow>
          {locationsMap}
          <GridRow>
            <GridCol>
              <DashedBox className='clickable m-0 py-4 mt-4' textClassName='text-size-14' onClick={addEmpty}>
                <IconAdd size={14} color={config.colors.rbGrey} />
                &nbsp; Add New Location
              </DashedBox>
            </GridCol>
          </GridRow>
        </div>
        <GridRow className='m-0 py-3 bg-white w-100 position-absolute' style={{ bottom: 0 }}>
          <GridCol>
            <Button variant='yellow' text='NEXT' onClick={onClick} />
          </GridCol>
        </GridRow>
      </div>
    );
  }

  return (
    <div className='container' style={{ paddingTop: '30px', paddingBottom: '30px' }}>
      <GridRow className='pb-3'>
        <GridCol>
          We integrate with google maps to make this easy for you.
        </GridCol>
      </GridRow>
      {locationsMap}
      <GridRow>
        <GridCol size={6}>
          <DashedBox className='clickable m-0 py-4 mt-4' textClassName='text-size-14' onClick={addEmpty}>
            <IconAdd size={14} color={config.colors.rbGrey} />
            &nbsp; Add New Location
          </DashedBox>
        </GridCol>
      </GridRow>
      <GridRow className='py-3'>
        <GridCol size={3}>
          <Button variant='yellow' text='NEXT' onClick={onClick} />
        </GridCol>
      </GridRow>
    </div>
  );
};

export default OnboardingLocationStep;
