import React from 'react';
import JoinSignup from './Components/JoinSignup';
import JoinBenefits from './Components/JoinBenefits';
import JoinNumbers from './Components/JoinNumbers';
import JoinGradient from './Components/JoinGradient';
import JoinEarn from './Components/JoinEarn';
import JoinSavings from './Components/JoinSavings';
import JoinAccess from './Components/JoinAccess';

const JoinToday = ({history}) => {
  const scrollTop = () => {
    window.scroll({top: 0, left: 0, behavior: 'smooth' });
  };
	return (
		<div className='font-montserrat join-today-container'>
			<JoinSignup scrollTop={scrollTop} history={history} />
			<JoinBenefits scrollTop={scrollTop} />
			<JoinGradient scrollTop={scrollTop} />
			<JoinNumbers scrollTop={scrollTop} />
			<JoinEarn scrollTop={scrollTop} />
			<JoinSavings scrollTop={scrollTop} />
			<JoinAccess scrollTop={scrollTop} />
		</div>
	)
};

export default JoinToday;