import React from 'react';
import Input from '../Input/Input';
import IconSearch from '../Icons/IconSearch';
import config from '../../config/config';

const SearchInput = (props) => (    
  <div className={"input-group border" + props.id}>
    <Input className='search' {...props} />
    <IconSearch color={config.colors.rbDarkGrey} />
  </div>
); 


export default SearchInput;