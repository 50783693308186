import AddNewDistributorToModal from '../../Dashboard/Modals/AddNewDistributorToModal';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../../MasterComponents/Modals/ModalsActions';
import { spinner } from '../../../MasterComponents/Spinner/SpinnerActions';
import LocationApi from '../../Location/LocationApi';
import LocationEmailToSalesRepModal from '../../Location/Modals/LocationEmailToSalesRepModal';
import ReactGA from 'react-ga';
import { useEffect } from 'react';

const useHomeLocations = (props) => {
  const { companyId, history } = props;
  const dispatch = useDispatch();
  const summary = useSelector(({location}) => location.summary);
  const merchantAllCount = summary.merchants;
  const distributorAllCount = summary.distributors;

  const merchantAll = useSelector(({location}) => location.merchant);

  const onGoToLocations = () => {
    history.push('/dashboard/rebates/card');
  }

  const onAddNewDistributor = () => {
  const isAdmin = localStorage.getItem('authKeyAdmin');
    ReactGA.event({
      category: 'Click Button',
      action: 'Click Add New Distributor'
    });
    if (companyId === 17 && !isAdmin) {
      // For Prime Services we are sending an email to the sales rep
      dispatch(spinner(true));
      LocationApi.sendEmailToSalesRep(companyId).then((res) => {
        dispatch(spinner(false));
        dispatch(showModal(LocationEmailToSalesRepModal, 'locationEmailToSalesRepModal', {}));
      }).catch(error => {
        dispatch(spinner(false));
        console.log(error);
      });
    }else{
      dispatch(showModal(AddNewDistributorToModal, 'addNewDistributorToModal', { goToAdd: goToAddNewDistributor, locations: merchantAll, merchant: [] }));
    }
  }

  const goToAddNewDistributor = (state) => {
    ReactGA.event({
      category: 'Click Button',
      action: 'Click Add New Distributor'
    });
    history.push({
      pathname: '/dashboard/add-new-distributor',
      state: state
    });
  }

  const onAddNewLocation = () => {
    ReactGA.event({
      category: 'Click Button',
      action: 'Click Add New Location'
    });
    history.push('/dashboard/add-new-location');
  }

  useEffect(() => {
    dispatch({ type: 'SUMMARY_SET_DATA', payload: {merchants: 0, distributors: 0, spinner: true} });
    LocationApi.getMerchantDistributorSummary().then(({data}) => {
      dispatch({ type: 'SUMMARY_SET_DATA', payload: {...data.data, spinner: false} });
    });
  }, [])
  
  return {
    onAddNewDistributor,
    onAddNewLocation,
    onGoToLocations,
    merchantAllCount,
    distributorAllCount
  }
}

export default useHomeLocations;
