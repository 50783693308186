import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import Button from '../../MasterComponents/Button/Button';
import Checkbox from '../../MasterComponents/Checkbox/Checkbox';
import GridCol from '../../MasterComponents/Grid/GridCol';
import GridRow from '../../MasterComponents/Grid/GridRow';
import IconClose from '../../MasterComponents/Icons/IconClose';
import { showModal } from '../../MasterComponents/Modals/ModalsActions';
import SearchInput from '../../MasterComponents/SearchInput/SearchInput';
import { spinner } from '../../MasterComponents/Spinner/SpinnerActions';
import ItemIntelApi from './ItemIntelApi';
import ServerErrorModal from './Modals/ServerErrorModal';
import { itemIntelSetCategories, itemIntelSetManufacturers, itemIntelSetShowFiltersSection } from './Reducer/ItemIntelAction';

class ItemIntelSideBar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      manufacturers: [],
      categories: [],
      types: [
        { label: 'Rebates', value: 'rebate' },
        // { label: 'Deviations', value: 'deviation' },
      ],
      filters: {
        manufacturers: [],
        categories: [],
        types: []
      },
      manufacturerSearchValue: '',
      categorySearchValue: '',
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    if (this.props.manufacturers.length === 0 || this.props.manufacturers.length === 0) {
      const getManufacturers = new Promise((resolve, reject) => {
        ItemIntelApi.getManufacturers().then((res) => {
          let manufacturers = this.orderData('manufacturers', res.data.data);
          this.checkPreviousManufacturersFilters();
          this.setState({ manufacturers });
          dispatch(itemIntelSetManufacturers(manufacturers));
          resolve();
        }).catch(error => {
          reject(error);
        });
      });

      const getCategories = new Promise((resolve, reject) => {
        ItemIntelApi.getCategories().then((res) => {
          let categories = this.orderData('categories', res.data.data);
          this.checkPreviousCategoriesFilters();
          this.setState({ categories });
          dispatch(itemIntelSetCategories(categories));
          resolve();
        }).catch(error => {
          reject(error);
        });
      });

      Promise.all([getManufacturers, getCategories]).then(values => {
        dispatch(spinner(false));
      }).catch(error => {
        dispatch(spinner(false));
        const { status, statusText } = error || '';
        dispatch(showModal(ServerErrorModal, 'serverErrorModal', { status, statusText }));
      });

    } else {
      this.checkPreviousManufacturersFilters();
      this.setState({ manufacturers: this.props.manufacturers });
      dispatch(itemIntelSetManufacturers(this.props.manufacturers));

      this.checkPreviousCategoriesFilters();
      this.setState({ categories: this.props.categories });
      dispatch(itemIntelSetCategories(this.props.categories));
    }
  }

  checkPreviousManufacturersFilters() {
    let { manufacturers } = this.props.filters;
    if (manufacturers) {
      manufacturers = manufacturers.slice(1, -1).split(',').map(manufacturer => Number(manufacturer));
      this.setState({ filters: { ...this.state.filters, manufacturers } });
    }
  }
  checkPreviousCategoriesFilters() {
    let { categories } = this.props.filters;
    if (categories) {
      categories = categories.slice(1, -1).split(',').map(category => Number(category));
      this.setState({ filters: { ...this.state.filters, categories } });
    }
  }

  handleCheckManufacturer(value) {
    let { manufacturers } = this.state.filters;
    if (manufacturers.includes(value)) {
      manufacturers.splice(manufacturers.indexOf(value), 1);
    } else {
      manufacturers.push(value)
    }
    const newFilters = { ...this.state.filters, manufacturers };
    this.setState({ filters: newFilters });
  }

  handleCheckCategory(value) {
    let { categories } = this.state.filters;
    if (categories.includes(value)) {
      categories.splice(categories.indexOf(value), 1);
    } else {
      categories.push(value)
    }
    const newFilters = { ...this.state.filters, categories };
    this.setState({ filters: newFilters });
  }

  handleCheckType(value) {
    let { types } = this.state.filters;
    if (types.includes(value)) {
      types.splice(types.indexOf(value), 1);
    } else {
      types.push(value)
    }
    const newFilters = { ...this.state.filters, types };
    this.setState({ filters: newFilters });
  }

  onChangeSearchManufacturer(value) {
    this.setState({ manufacturerSearchValue: value });
  }

  onEnterSearchManufacturer = (event) => {
    if (event.key === 'Enter') {
      const { dispatch } = this.props;
      dispatch(spinner(true));
      ItemIntelApi.getManufacturers(this.state.manufacturerSearchValue).then((res) => {
        let manufacturers = this.orderData('manufacturers', res.data.data);
        this.setState({ manufacturers })
        dispatch(spinner(false));
      }).catch(error => {
        dispatch(spinner(false));
        const { status, statusText } = error || '';
        dispatch(showModal(ServerErrorModal, 'serverErrorModal', { status, statusText }));
      });
    }
  };

  onChangeSearchCategory(value) {
    this.setState({ categorySearchValue: value });
  }

  onEnterSearchCategory = (event) => {
    if (event.key === 'Enter') {
      const { dispatch } = this.props;
      dispatch(spinner(true));
      ItemIntelApi.getCategories(this.state.categorySearchValue).then((res) => {
        let categories = this.orderData('categories', res.data.data);
        this.setState({ categories })
        dispatch(spinner(false));
      }).catch(error => {
        dispatch(spinner(false));
        const { status, statusText } = error || '';
        dispatch(showModal(ServerErrorModal, 'serverErrorModal', { status, statusText }));
      });
    }
  };

  orderData(type, data) {
    return data.sort((a, b) => {
      if (type === 'manufacturers') {
        if (a.manufacturer > b.manufacturer) return 1;
        if (a.manufacturer < b.manufacturer) return -1;
        return 0;
      }
      else if (type === 'categories') {
        if (a.category > b.category) return 1;
        if (a.category < b.category) return -1;
        return 0;
      }
      else return 0;
    });
  }

  onClickReset() {
    this.setState({
      filters: {
        manufacturers: [],
        categories: [],
        types: [],
      }
    })
    const newFilters = {
      ...this.props.filters,
      manufacturers: null,
      categories: null,
      types: null,
    }
    this.props.updateItems(newFilters);
    if (isMobile) {
      const { dispatch } = this.props;
      dispatch(itemIntelSetShowFiltersSection(false));
    }
  };

  onClickApply() {
    let { manufacturers, categories, types } = this.state.filters;
    manufacturers = manufacturers.length > 0 ?
      `[${this.state.filters.manufacturers.join(',')}]` : null;
    categories = categories.length > 0 ?
      `[${this.state.filters.categories.join(',')}]` : null;
    types = types.length > 0 ?
      `[${this.state.filters.types.join(',')}]` : null;
    const newFilters = {
      ...this.props.filters,
      manufacturers,
      categories,
      types,
    }
    this.props.updateItems(newFilters);
    if (isMobile) {
      const { dispatch } = this.props;
      dispatch(itemIntelSetShowFiltersSection(false));
    }
  };

  closeFilters() {
    const { dispatch } = this.props;
    dispatch(itemIntelSetShowFiltersSection(false));
  }

  render() {
    return (
      <>

        {isMobile ?
          <GridRow className='py-3 filters-title-mobile bg-white border-bottom'>
            Filters
            <div className='close-icon clickable' onClick={this.closeFilters.bind(this)}>
              <IconClose color={'#000000'} />
            </div>
          </GridRow>
          :
          <GridRow className='pt-4 pb-2 filters-title'>Filters</GridRow>
        }

        <div className={isMobile ? 'px-2' : ''}>

          <GridRow className={`filters-group-title${isMobile ? ' special-margin-top' : ''}`}>Manufacturers</GridRow>
          <GridRow>
            <SearchInput name='search' placeholder='Search' value={this.state.manufacturerSearchValue}
              onChange={this.onChangeSearchManufacturer.bind(this)} onKeyPress={this.onEnterSearchManufacturer.bind(this)} />
          </GridRow>
          <GridRow className='border-bottom'>
            <GridCol className={`filters-options-container${isMobile ? '' : ' scrollbar-custom'}`}>
              {this.state.manufacturers.map((manufacturer, index) =>
                <GridRow key={manufacturer.manufacturer_id} className='filters-options-group'>
                  <GridCol className='col-1'>
                    <Checkbox text='' className={''} name={''}
                      value={this.state.filters.manufacturers.includes(manufacturer.manufacturer_id)}
                      onChange={this.handleCheckManufacturer.bind(this, manufacturer.manufacturer_id)}
                    />
                  </GridCol>
                  <GridCol className='col text-size-14'>
                    {manufacturer.manufacturer}
                  </GridCol>
                </GridRow>
              )}
            </GridCol>
          </GridRow>

          <GridRow className='filters-group-title'>Category</GridRow>
          <GridRow>
            <SearchInput name='search' placeholder='Search' value={this.state.categorySearchValue}
              onChange={this.onChangeSearchCategory.bind(this)} onKeyPress={this.onEnterSearchCategory.bind(this)} />
          </GridRow>
          <GridRow className='border-bottom'>
            <GridCol className={`filters-options-container${isMobile ? '' : ' scrollbar-custom'}`}>
              {this.state.categories.map((category, index) =>
                <GridRow key={category.id} className='filters-options-group'>
                  <GridCol className='col-1'>
                    <Checkbox text='' className={''} name={''}
                      value={this.state.filters.categories.includes(category.id)}
                      onChange={this.handleCheckCategory.bind(this, category.id)}
                    />
                  </GridCol>
                  <GridCol className='col text-size-14'>
                    {category.category}
                  </GridCol>
                </GridRow>
              )}
            </GridCol>
          </GridRow>

          {/* <GridRow className='filters-group-title'>Type</GridRow>
          <GridRow className='border-bottom'>
            <GridCol className={`filters-options-container${isMobile ? '' : ' scrollbar-custom'}`}>
              {this.state.types.map(type =>
                <GridRow key={type.value} className='filters-options-group'>
                  <GridCol className='col-1'>
                    <Checkbox text='' className={''} name={''}
                      value={this.state.filters.types.includes(type.value)}
                      onChange={this.handleCheckType.bind(this, type.value)}
                    />
                  </GridCol>
                  <GridCol className='col'>
                    {type.label}
                  </GridCol>
                </GridRow>
              )}
            </GridCol>
          </GridRow> */}

        </div>

        {isMobile ?
          <GridRow className='py-3 buttons-container'>
            <GridCol className='text-left px-2'>
              <Button onClick={this.onClickReset.bind(this)} variant='white' size='sm' text='RESET' />
            </GridCol>
            <GridCol className='text-right px-2'>
              <Button onClick={this.onClickApply.bind(this)} variant='primary' size='sm' text='APPLY' />
            </GridCol>
          </GridRow>
          :
          <GridRow className='py-3'>
            <GridCol className='text-left px-0'>
              <Button onClick={this.onClickReset.bind(this)} variant='white' size='sm' text='RESET' />
            </GridCol>
            <GridCol className='text-right px-0'>
              <Button onClick={this.onClickApply.bind(this)} variant='primary' size='sm' text='APPLY' />
            </GridCol>
          </GridRow>
        }
      </>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    filters: state.itemIntel.filters,
    manufacturers: state.itemIntel.manufacturers,
    categories: state.itemIntel.categories,
  };
};

export default connect(mapStateToProps)(ItemIntelSideBar);