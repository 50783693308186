import React, {useState, useRef } from 'react';
import { Row, Col, Button, Container } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { AiFillCalendar } from 'react-icons/ai';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import DropDownListSelect from '../../MasterComponents/DropDownList/DropDownListSelect';
import SearchInput from '../../MasterComponents/SearchInput/SearchInput';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import config from '../../config/config';

const InvoiceScanningTop = (props) => {
  const { onUploadNow, optionsLocation, selectedLocation, onChangeLocation, onEnterSearch, onChangeSearch, onChangeSetDate, search, history } = props;
  const goToLocations = () => {
    history.push('/dashboard/rebates/card');
  };
  const ref = useRef(null);
  const endDate = new Date();
  const [startDate, setStartDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const onChangeDate = (date) => {
    setStartDate(date);
    onChangeSetDate(date, endDate);
  }

  const  CustomInput = React.forwardRef(({ onClick }, ref) => {
    return (
      <div className='input-group border align-items-center pl-2 clickable'>
        <Col className='px-0 mw-fc'>
          <AiFillCalendar size={16} color={'#5F6272'} />
        </Col>
        <div
          ref={ref}
          className='d-flex align-items-center px-2 col'
          type='text'
          onClick={onClick}
          onChange={()=>{}}
          style={{height: '38px'}}
        >
          {`${moment(startDate).format('MM/DD/YYYY')} - ${moment(endDate).format('MM/DD/YYYY')}`}
        </div>
        <Col className='d-flex justify-content-end mw-fc'>
          { isOpen  ? 
            <MdArrowDropUp size={20} color={config.colors.rbBlack} />
            : 
            <MdArrowDropDown size={20} color={config.colors.rbBlack} />
          }
        </Col>
      </div>
    )});       

  if(isMobile) {
    return (
      <Container fluid className='my-4'>
        <Row>
          <Col>
            <span onClick={goToLocations} className='text-size-18 line-height-16 text-blue clickable'>Locations & Distributors</span> {'>'} <span className='text-size-20 line-height-23'>Invoices</span>
          </Col>
        </Row>
        <Row>
          <Col className='pt-3 pr-0'>
            <SearchInput name='search' id='locationDistributorSearch' placeholder='Search...' value={search} onKeyPress={onEnterSearch} onChange={onChangeSearch} />
          </Col>
          <Col className='pt-3 d-flex justify-content-end mw-fc pl-2'>
            <Button active block className='py-2 shadow-none text-size-12' size='sm' style={{height: '40px'}} color='primary'onClick={()=>onUploadNow(false)} >UPLOAD NOW</Button>
          </Col>
        </Row>
        <Row>
          <Col className='py-2'>
            <DropDownListSelect
              name='location'
              value={selectedLocation} onChange={onChangeLocation}
              options={optionsLocation}
               />
          </Col>
        </Row>
        <Row>
          <Col>
            <DatePicker name='startDate' 
              className='rb-input w-100' locale='en'  
              popperPlacement="bottom-end" 
              selectsRange 
              selectsStart={true} 
              selected={startDate} 
              startDate={startDate} 
              endDate={endDate} 
              inline={false}
              maxDate={new Date()} 
              dateFormat="MM/dd/yyyy"  
              onChange={(date) => onChangeDate(date)}
              onCalendarOpen={()=>setIsOpen(true)}
              onCalendarClose={()=>setIsOpen(false)}
              customInput = {<CustomInput ref={ref} />}
              withPortal />
          </Col>
        </Row>
      </Container>
    )
  };
  return (
    <>
      <Container fluid className='my-4 mx-2'>
        <Row>
          <Col>
            <span onClick={goToLocations} className='text-size-20 line-height-19 text-blue clickable'>Locations & Distributors</span> {'>'} <span className='text-size-20 line-height-23'>Invoices</span>
          </Col>
        </Row>
        <Row>
          <Col className='text-size-24 line-height-28 py-2'>
            Invoices
          </Col>
        </Row>
        <Row className='px-2'>
          <Col xs={3} className='pt-3'>
            <SearchInput name='search' id='locationDistributorSearch' placeholder='Search...' value={search} onKeyPress={onEnterSearch} onChange={onChangeSearch} />
          </Col>
          <Col className='text-size-14'>
            <span >Filter by Location</span>
            <DropDownListSelect
              name='location'
              value={selectedLocation} onChange={onChangeLocation}
              options={optionsLocation}
               />
          </Col>
          <Col xs={3} className='text-size-14'>
            <Col className='px-0'>Select Date Range</Col>
            <DatePicker name='startDate' className='rb-input w-100 border' locale='en'  
              popperPlacement="bottom-end"
              selectsStart={true} 
              selected={startDate} 
              startDate={startDate} 
              endDate={endDate} 
              inline={false}
              maxDate={new Date()} 
              dateFormat="MM/dd/yyyy"  
              onChange={(date) => onChangeDate(date)}
              onCalendarOpen={()=>setIsOpen(true)}
              onCalendarClose={()=>setIsOpen(false)}
              customInput = {<CustomInput ref={ref} />}
              />
          </Col>
          <Col>
          </Col>
          <Col className='px-4 d-flex justify-content-end'>
            <Button active block className='py-2 shadow-none' size='md' style={{height: '40px'}} color='primary'onClick={()=>onUploadNow(false)} >UPLOAD NOW</Button>
          </Col>
        </Row>
      </Container>
    </>
  )
};

export default InvoiceScanningTop;