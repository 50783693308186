import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconBlog = props => (
  <svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 16 10" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Blog-Banner" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="1---Homepage" transform="translate(-835.000000, -31.000000)" fill={!props.isActive ? props.color : props.activeColor} fillRule="nonzero">
        <g id="nav" transform="translate(245.000000, 0.000000)">
          <g id="icons/text" transform="translate(590.000000, 31.000000)">
            <path d="M15.5,0 L0.5,0 C0.224,0 0,0.224 0,0.5 C0,0.776 0.224,1 0.5,1 L15.5,1 C15.776,1 16,0.776 16,0.5 C16,0.224 15.776,0 15.5,0 Z" id="Path"></path>
            <path d="M15.5,3 L0.5,3 C0.224,3 0,3.224 0,3.5 C0,3.776 0.224,4 0.5,4 L15.5,4 C15.776,4 16,3.776 16,3.5 C16,3.224 15.776,3 15.5,3 Z" id="Path"></path>
            <path d="M15.5,6 L0.5,6 C0.224,6 0,6.224 0,6.5 C0,6.776 0.224,7 0.5,7 L15.5,7 C15.776,7 16,6.776 16,6.5 C16,6.224 15.776,6 15.5,6 Z" id="Path"></path>
            <path d="M8,9 L0.5,9 C0.224,9 0,9.224 0,9.5 C0,9.776 0.224,10 0.5,10 L8,10 C8.276,10 8.5,9.776 8.5,9.5 C8.5,9.224 8.276,9 8,9 Z" id="Path"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

IconBlog.propTypes = {
  color: PropTypes.string,
  activeColor: PropTypes.string,
  isActive: PropTypes.bool,
  size: PropTypes.number,
};

IconBlog.defaultProps = {
  activeColor: config.colors.rbYellow,
  color: config.colors.rbWhite,
  isActive: false,
  size: 16,
};

export default IconBlog;