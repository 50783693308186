import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import '../../SignUp.scss';
import GridCol from '../../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../../MasterComponents/Grid/GridRow';
import Button from '../../../../MasterComponents/Button/Button';
import InputLabel from '../../../../MasterComponents/InputLabel/InputLabel';
import FormDefaultLocation from './FormDefaultLocation';
import SignUpApi from '../../SignUpApi';
import { spinner } from '../../../../MasterComponents/Spinner/SpinnerActions';
import ConfirmationModal from '../../Modals/ConfirmationModal';
import { showModal } from '../../../../MasterComponents/Modals/ModalsActions';
import ReactHtmlParser from 'react-html-parser';

class FormDefaultStep2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notValidMessage: 'Required Field Missing',
      validFields: true
    }
  };
  setLocationValid = (name) => {
    const { LocRef1 } = this.refs;
    LocRef1.setLocationValid(name);
  };
  isValid = () => {
    let result = false;
    const { LocRef1 } = this.refs;
    const sl = LocRef1.isValid();
    if (sl) {
      result = true;
    }
    return result;
  };
  setError = (msg) => {
    this.setState({
      notValidMessage: msg
    });
  };
  invalidEmail = () => {
    const { SignUpStepOneInformation } = this.refs;
    SignUpStepOneInformation.invalidEmail();
  };
  invalidConfirmEmail = () => {
    const { SignUpStepOneInformation } = this.refs;
    SignUpStepOneInformation.invalidConfirmEmail();
  };
  backLocation = () => {
    this.props.onBackClick();
  };
  nextLocation = () => {
    const {
      locationsAmount, onSkipClick, onNextClick, companyId,
      step1Locations, dispatch, step1Inputs, sameCheckedValue, country
    } = this.props;
    const result = this.isValid();
    this.setState({ validFields: result }, () => {
      if (!result) {
        return;
      } else {
        // Validate address with smarty streets and SF
        let allAddress = [];
        let skipArray = [];

        if (!sameCheckedValue) {
          allAddress.push({
            address: step1Inputs.businessAddress,
            city: step1Inputs.businessCity,
            state: step1Inputs.businessState,
            postcode: step1Inputs.businessPostcode
          });
        }
        for (let index = 0; index < locationsAmount; index++) {
          const valid = this.refs[`LocRef${index + 1}`].isValidBlind();
          skipArray.push(!valid);
          if (valid) {
            allAddress.push({
              address: step1Locations[index].address,
              city: step1Locations[index].city,
              state: step1Locations[index].state,
              postcode: step1Locations[index].postcode
            });
          }
        }
        onSkipClick(skipArray);
        dispatch(spinner(true));
        SignUpApi.getSmartyPlace(allAddress, country).then(({ data }) => {
          const smartyLocations = data.data.map(loc => {
            let zipcode = loc.postcode.toString().trim()

            if (zipcode && country !== 'CA' && country !== 'ca') {
              if (zipcode.length > 5) zipcode = zipcode.substring(0, 5);
              if (zipcode.length < 5) zipcode = zipcode.padStart(5, '0');
            }
            return {
              ...loc,
              postcode: zipcode || ''
            }
          });
          const params = {
            salesRepId: (step1Inputs.daSalesRep && step1Inputs.daSalesRep.value) || (step1Inputs.referredBy && ((step1Inputs.referredBy.isDaSalesRep !== true && step1Inputs.referredBy.isDaSalesRep !== false) ? step1Inputs.referredBy.isDaSalesRep : 31)) || 31,
            referredPartnerId: step1Inputs.referredBy && step1Inputs.referredBy.value,
            businessName: step1Inputs.businessName,
            contactName: `${step1Inputs.firstName} ${step1Inputs.lastName}`,
            locations: smartyLocations,
            email: step1Inputs.email,
            companyId: companyId
          };
          SignUpApi.validateSFLocation(params).then(({ data }) => {
            dispatch(spinner(false));
            console.log(data);
            onNextClick();
          }).catch(err => {
            console.error(err);
            dispatch(spinner(false));
            if (err && err.data && err.data.data) {
              let errorMessage = err.data.data.message;
              dispatch(showModal(ConfirmationModal, 'confirmationModal', { header: 'Existing Account Found', icon: false, message: ReactHtmlParser(errorMessage) }));
            }
          });
        }).catch(err => {
          console.error(err);
          dispatch(spinner(false));
        });
      }
    });
  };
  backAccount = (e) => {
    e.preventDefault();
    this.props.history.push('/register');
  };
  render() {
    const { props, state } = this;
    const {
      locationsAmount, changeHandlerLocationInput, handleAddressSelectLocation,
      setAddressSearchLocation, clearAddressSearchLocation, handleNicknameSelectLocation,
      toggleAddressDetailsLocation, setNicknameSearchLocation, selectStateLocation, selectFlagTypeLocation
    } = props;
    const { validFields, notValidMessage } = state;

    let locArray = [];

    for (let index = 0; index < locationsAmount; index++) {
      locArray.push(index + 1);
    }

    const locMap = locArray.map(loc => {
      return (
        <FormDefaultLocation ref={`LocRef${loc}`} key={`loc_${loc}`} {...props} stateLocation={loc}
          changeHandlerInput={(name, val) => changeHandlerLocationInput(loc, name, val)}
          setAddressSearch={(val) => setAddressSearchLocation(loc, val)}
          handleAddressSelect={(...rest) => handleAddressSelectLocation(loc, ...rest)}
          setNicknameSearch={(val) => setNicknameSearchLocation(loc, val)}
          handleNicknameSelect={(...rest) => handleNicknameSelectLocation(loc, ...rest)}
          clearAddressSearch={() => clearAddressSearchLocation(loc)}
          toggleAddressDetails={() => toggleAddressDetailsLocation(loc)}
          selectStateLocation={(value) => selectStateLocation(loc, value)}
          selectFlagTypeLocation={(value) => selectFlagTypeLocation(loc, value)}
        />
      )
    });

    return (
      <GridCol>
        {locMap}
        <GridRow className='line-form'>
          <GridCol className='form-group'>
            <Button type='button' variant='white' size='' text='BACK' onClick={this.backLocation} />
          </GridCol>
          <GridCol className='form-group'>
            <Button type='button' variant='primary' size='' text='NEXT' onClick={this.nextLocation} />
          </GridCol>
        </GridRow>
        <GridRow className='line-form'>
          <GridCol className={(isMobile ? '' : 'col-4') + ' form-group d-flex align-items-center'}>
            <InputLabel className='text-red text-size-14'>{validFields ? '' : notValidMessage}</InputLabel>
          </GridCol>
        </GridRow>
      </GridCol>
    )
  };
}

export default FormDefaultStep2;
