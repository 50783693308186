import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconSearch = props => (
  <svg className='icon-search' width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Designs" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="30---Add-3rd-Distributor" transform="translate(-340.000000, -166.000000)" fill={!props.isActive ? props.color : props.activeColor}>
        <g id="add-distributor" transform="translate(300.000000, 88.000000)">
          <g id="search" transform="translate(30.000000, 30.000000)">
            <g id="icons/search" transform="translate(10.000000, 48.000000)">
              <path d="M15.5810909,14.9643636 L11.8167273,11.2014545 C12.8327273,10.0436364 13.4501818,8.52727273 13.4501818,6.86909091 C13.4501818,3.24218182 10.4981818,0.291636364 6.87054545,0.291636364 C3.24218182,0.290909091 0.290909091,3.24218182 0.290909091,6.86836364 C0.290909091,10.4945455 3.24290909,13.4458182 6.87054545,13.4458182 C8.52654545,13.4458182 10.0414545,12.8305455 11.1992727,11.8174545 L14.9643636,15.5810909 C15.0494545,15.6669091 15.1614545,15.7090909 15.2727273,15.7090909 C15.3847273,15.7090909 15.496,15.6661818 15.5810909,15.5810909 C15.752,15.4109091 15.752,15.1345455 15.5810909,14.9643636 Z M6.87054545,12.5730909 C3.72363636,12.5730909 1.16363636,10.0138182 1.16363636,6.86836364 C1.16363636,3.72290909 3.72363636,1.16363636 6.87054545,1.16363636 C10.0174545,1.16363636 12.5774545,3.72290909 12.5774545,6.86836364 C12.5774545,10.0138182 10.0174545,12.5730909 6.87054545,12.5730909 Z" id="Shape"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

IconSearch.propTypes = {
  color: PropTypes.string,
  activeColor: PropTypes.string,
  isActive: PropTypes.bool,
  size: PropTypes.number,
};

IconSearch.defaultProps = {
  activeColor: config.colors.rbYellow,
  color: config.colors.rbWhite,
  isActive: false,
  size: 16,
};

export default IconSearch;