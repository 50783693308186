import React from 'react';
import PropTypes from 'prop-types';

import logo from '../../assets/images/pdf.png';

const LogoPdf = props => (
  <img id={props.id} className={props.className} src={logo} alt='Pdf File Logo' {...props} />
);

LogoPdf.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string
}

export default LogoPdf;