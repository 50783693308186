import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

const Sidebars = () => {
  const side = useSelector(state => state.sidebar.sidebar);
  const modalsMap = side.map( m => 
    <m.component key={m.props.id} id={m.props.id} {...m.props} />
  );
  return (
    <Fragment>
      {modalsMap}
    </Fragment>
  );
};

export default Sidebars;