import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import Modal from '../../../MasterComponents/Modals/Modal';
import ModalHeader from '../../../MasterComponents/Modals/ModalHeader';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import InputLabel from '../../../MasterComponents/InputLabel/InputLabel';
import IconConfirmation from '../../../MasterComponents/Icons/IconConfirmation';
import config from '../../../config/config';

class SignUpModal extends Component {
    render() {        
        return (
           <Modal  id='idSignUpModal' centered={isMobile} >
                <ModalHeader />
                <ModalBody className='text-center'>
                    <GridRow>
                        <GridCol className='pb-4'>
                            <IconConfirmation color={config.colors.rbBlue} size={50}  />
                        </GridCol>
                    </GridRow>
                    <GridRow>
                        <GridCol>
                            <InputLabel className='prime-signup-text1'>Thank You!</InputLabel><br/>
                            <InputLabel className='prime-signup-text2'>We sent your request to our team. We will be in touch shortly.</InputLabel>
                        </GridCol>
                    </GridRow>
                </ModalBody>
            </Modal>
        );
    }
}

export default SignUpModal;