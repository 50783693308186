import { useDispatch } from 'react-redux';
import { showModal } from '../Modals/ModalsActions';
import { spinner } from '../Spinner/SpinnerActions';
import useRefresh from './useRefresh';
import EnterCredentialsModal from '../../views/Dashboard/Modals/EnterCredentialsModal';
import DashboardApi from '../../views/Dashboard/DashboardApi';

const useShowEnterCredentials = () => {
  const dispatch = useDispatch();
  const refresh = useRefresh();

  const showEnterCredentials = (merDist, dcns) => {
    // showEnterCredentials used only for editing
    const modalProps = {
      merchantDistributor: merDist,
      saveCredentials,
      verifyCredentials,
      isEdit: true,
      dcns: dcns
    }
    dispatch(showModal(EnterCredentialsModal, 'enterCredentialsModal', modalProps));
  };

  const verifyCredentials = (distributorId, merchantId, username, password, dcns, cb) => {
    dispatch(spinner(true));
    DashboardApi.verifyCreds( distributorId, merchantId, username, password, dcns).then(({data}) => {
      dispatch(spinner(false));
      cb(data);
    }).catch(({data}) => {
      dispatch(spinner(false));
      cb(data);
    });
  };

  const saveCredentials = (merchantDistributorId, username, password) => {
    const params = {
      merchantDistributorId: merchantDistributorId,
      username: username,
      password: password
    }
    dispatch(spinner(true));
    DashboardApi.editCredentials(merchantDistributorId, params).then(({data}) => {
      dispatch(spinner(false));
      refresh();
    });
  };

  return {
    showEnterCredentials,
    verifyCredentials,
    saveCredentials
  };
 
}

export default useShowEnterCredentials;
