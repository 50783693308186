import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { getBgColor } from '../../config/Company/CompanyHelper';
import GridCol from '../../MasterComponents/Grid/GridCol';
import GridContainer from '../../MasterComponents/Grid/GridContainer';
import GridRow from '../../MasterComponents/Grid/GridRow';
import { showModal } from '../../MasterComponents/Modals/ModalsActions';
import { spinner } from '../../MasterComponents/Spinner/SpinnerActions';
import { itemIntelSetFilters, itemIntelSetItems, itemIntelSetPage } from '../../views/ItemIntel/Reducer/ItemIntelAction';
import { changePage } from '../Dashboard/DashboardActions';
import './ItemIntel.scss';
import ItemIntelApi from './ItemIntelApi';
import ItemIntelItemsList from './ItemIntelItemsList';
import ItemIntelSideBar from './ItemIntelSideBar';
import ItemIntelTop from './ItemIntelTop';
import ServerErrorModal from './Modals/ServerErrorModal';

//getBgColor

class ItemIntel extends Component {

  componentDidMount() {
    const { dispatch, filters } = this.props;
    const section = {
      button: 'Item Intel',
      title: 'Item Intel'
    };
    dispatch(changePage(section));

    if (this.props.items.length === 0) {
      if (filters && (filters.itemFilter !== null ||
        filters.search !== null ||
        filters.manufacturers !== null ||
        filters.categories !== null ||
        filters.types !== null
      ))
        this.updateItems(filters)
      else
        this.updateItems();
    }
  }

  updateItems(filters) {
    const { dispatch } = this.props;
    dispatch(spinner(true));
    dispatch(itemIntelSetItems([]));
    if (filters) dispatch(itemIntelSetFilters(filters));

    ItemIntelApi.getItems(filters).then((res) => {
      dispatch(itemIntelSetItems(res.data.data));
      dispatch(itemIntelSetPage(1));
      dispatch(spinner(false));
    }).catch(error => {
      dispatch(spinner(false));
      const { status, statusText } = error || '';
      dispatch(showModal(ServerErrorModal, 'serverErrorModal', { status, statusText }));
    });
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    const section = {
      button: 'Rebates Portal',
      title: 'Rebates Portal'
    };
    dispatch(changePage(section));
  }

  render() {
    const props = this.props;
    const { companyId } = this.props;
    const bgColor = getBgColor(companyId);

    return (
      <GridContainer isFluid={true} id='item-intel-container' className='flex-grow-1'>
        {isMobile ?
          <GridRow>
            <GridCol className={props.showFiltersSection ? 'px-2 d-none' : 'px-2 d-block'}>
              <ItemIntelTop {...props} updateItems={this.updateItems.bind(this)} />
              <ItemIntelItemsList {...props} />
            </GridCol>
            <GridCol className={`px-4 filters-container-mobile ${props.showFiltersSection ? 'd-block' : 'd-none'}`}>
              <ItemIntelSideBar {...props} updateItems={this.updateItems.bind(this)} />
            </GridCol>
          </GridRow>
          :
          <GridRow className={`${bgColor}`}>
            <GridCol className='px-5'>
              <ItemIntelTop {...props} updateItems={this.updateItems.bind(this)} />
              <ItemIntelItemsList {...props} />
            </GridCol>
            <GridCol maxWidth='350px' className='px-5 bg-white filters-container scrollbar-custom border-left'>
              <ItemIntelSideBar {...props} updateItems={this.updateItems.bind(this)} />
            </GridCol>
          </GridRow>

        }
      </GridContainer>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    companyId: state.company.id,
    items: state.itemIntel.items,
    showFiltersSection: state.itemIntel.showFiltersSection,
    filters: state.itemIntel.filters,
  };
};

export default connect(mapStateToProps)(ItemIntel);