import React from 'react'
import Modal from '../../../MasterComponents/Modals/Modal';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import ModalHeader from '../../../MasterComponents/Modals/ModalHeader';
import { Button, Form, FormGroup, Label } from 'reactstrap';
import ModalFooter from '../../../MasterComponents/Modals/ModalFooter';
import DropDownListSelectCustom from '../../../MasterComponents/DropDownList/DropDownListSelectCustom';
import useSalesRepModal from './useSalesRepModal';

const SalesRepModal = ({modals, history}) => {
	const {
		salesRep, companyName, onEnterSales
	} = useSalesRepModal(modals, history);
	return (
		<Modal id='salesRepModal'>
			<ModalHeader closeButton>How did you hear about us?</ModalHeader>
			<ModalBody>
				<Form>
					<FormGroup>
						<Label>
							{companyName} Sales Rep
						</Label>
						<DropDownListSelectCustom {...salesRep} />
					</FormGroup>
				</Form>
			</ModalBody>
			<ModalFooter justify='end'>
				<Button color='primary' size='sm' block className='py-2 shadow-none active' onClick={onEnterSales}>
					Next
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default SalesRepModal;