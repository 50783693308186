import React, { Component, Fragment } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import SignatureCanvas from 'react-signature-canvas'
import DatePicker from 'react-datepicker';
import DropDownList from '../../../../MasterComponents/DropDownList/DropDownList';
import DropDownListOption from '../../../../MasterComponents/DropDownList/DropDownListOption';
import Input from '../../../../MasterComponents/Input/Input';
import InputLabel from '../../../../MasterComponents/InputLabel/InputLabel';
import Checkbox from '../../../../MasterComponents/Checkbox/Checkbox';
import TextArea from '../../../../MasterComponents/TextArea/TextArea';
import Button from '../../../../MasterComponents/Button/Button';
import GridRow from '../../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../../MasterComponents/Grid/GridCol';
import DropDownListSelect from '../../../../MasterComponents/DropDownList/DropDownListSelect';
import SignUpApi from '../../SignUpApi';
import { spinner } from '../../../../MasterComponents/Spinner/SpinnerActions';
import ReactHtmlParser from 'react-html-parser';
import { showModal } from '../../../../MasterComponents/Modals/ModalsActions';
import LOTAcknowledgementModal from '../../Modals/LOTAcknowledgementModal';
import moment from 'moment';
import config from '../../../../config/config';
const imgFsrlogo = 'https://rebate-portal.s3.amazonaws.com/lops/fsr-second-logo.svg';


class FormDefaultStep4 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            manufacturers: [],
            manufacturersApi: [],
            notValidMessage: 'Required Field Missing',
            validFields: true,
            customGPOName: ''
        };
    };
    isReinhartSelected = () => {
        const { step2Distributors } = this.props;
        let isOnOne = false;
        step2Distributors.forEach((item) => {
            const hasReinhart = item.distributorSelected.esign_name && item.distributorSelected.esign_name.toUpperCase().split(" ").find(word => word  === 'REINHART' );
            const hasPFG = item.distributorSelected.esign_name && item.distributorSelected.esign_name.toUpperCase().search('PERFORMANCE FOOD GROUP') === 0;

            if (hasReinhart || hasPFG) {
                isOnOne = true;
            }
        });
        return isOnOne;
    }
    componentDidMount = () => {
        this.getManufacturers('', 1);
    };
    getManufacturers = (search, page) => {
        const { dispatch } = this.props;
        dispatch(spinner(true));
        SignUpApi.getManufacturers(search, page).then(({ data }) => {
            if (search) {
                this.setState({
                    manufacturers: [
                        {
                            label: 'Add: '+search,
                            value: search,
                            isCustom: true,
                            nickname: search
                        },
                        ...data.data.map((item) => {
                            return {
                                ...item,
                                label: item.nickname,
                                value: item.id,
                            }
                        })
                    ],
                    manufacturersApi: data.data.map((item) => {
                        return {
                            ...item,
                            label: item.nickname,
                            value: item.id,
                        }
                    })
                });
            } else {
                this.setState({
                    manufacturers: data.data.map((item) => {
                        return {
                            ...item,
                            label: item.nickname,
                            value: item.id,
                        }
                    }),
                    manufacturersApi: data.data.map((item) => {
                        return {
                            ...item,
                            label: item.nickname,
                            value: item.id,
                        }
                    })
                });
            }
            dispatch(spinner(false));
        })
    };
    searchManufacturers = (value) => {
        const { manufacturersApi } = this.state;
        if (value) {
            this.setState({
                manufacturers: [
                    {
                        label: 'Add: '+value,
                        value: value,
                        isCustom: true
                    },
                    ...manufacturersApi
                ]
            });
        } else {
            this.setState({
                manufacturers: manufacturersApi
            });
        }
        this.getManufacturers(value, 1);
    };
    back = () => {
        const { onBackClick, cancelDistributor, signUpSalesRep } = this.props;
        cancelDistributor(false);
        if (signUpSalesRep) {
            onBackClick(1);
        } else {
            onBackClick(3);
        }
    };
    validateForm = () => {
        const { isDistributorManufacturerAgreement, isGpo, isAuthorized, isReinhartAuthorized, isLOTAuthorized} = this.props.step3Inputs;
        const { signedText, nameOfCompaniesSelect, gpoName } = this.refs;

        const isReinhartSelected = this.isReinhartSelected();

        const gpo = isGpo ? gpoName.isValid() : true;
        const nc = isDistributorManufacturerAgreement ? nameOfCompaniesSelect.isValid() : true;
        const st = signedText ? signedText.isValid() : true; // no password if SalesRepSignUp
        const r = isReinhartSelected ? isReinhartAuthorized : true;
        let lot = isGpo ? isLOTAuthorized : true;
        if (this.props.companyId === 17) lot = true

        this.props.authorizationInvalid(!isAuthorized, !r, !lot);

        if (gpo && nc && st && isAuthorized && r && lot) {
            return true;
        }
        return false;
    };
    selectOptionMemberGPO = (num) => {
        const { gpoName } = this.refs;
        gpoName.setValid();
        this.props.selectOptionMemberGPO(num);
        this.setState({validFields:true});
    };
    selectOptionGPOName = (option) => {
        const { dispatch } = this.props;
        this.props.selectOptionGPOName(option.value);
        if (this.props.companyId !== 17)
            dispatch(showModal(LOTAcknowledgementModal, 'LOTAcknowledgementModal', { message: this.LOTLegalText(option.value), acknowledgeLOTTerms: this.acknowledgeLOTTerms }));
    };
    acknowledgeLOTTerms = (bool) => {
        this.props.authorizationLOTChecked(bool);
    }
    cleanSignature = () => {
        const { sigCanvas } = this;
        sigCanvas.clear()
    }
    sendSignature = () => {
        const { sigCanvas } = this;
        const imageData = sigCanvas.getTrimmedCanvas().toDataURL();
        this.props.signatureHandler(imageData);
    }
    done = () => {
        let result = this.validateForm();
        this.setState({validFields:result}, () =>{
            if(!result){
                return;
            }else{
                this.props.onDoneClick();
            }
        });
    };
    LOTLegalText = (value) => {
        const { step3Inputs, step1Inputs } = this.props;
        const today = moment().format('MMMM D, YYYY');
        const gpoName = value || step3Inputs.gpoName;
        const parentName = step1Inputs.businessName;
        return `Effective ${today}, ${parentName} will no longer participate in any programs offered by ${gpoName}. Buyers Edge Platform is hereby authorized to communicate this termination with my former GPO on Member's behalf. However, Member hereby acknowledges that it is the Member's responsibility to provide a copy of the letter of termination ("LOT") to the former GPO of record. A copy of the LOT of termination will be supplied to Member for the purposes of communicating the same to Member's former GPO.`;
    };
    createGPOName = (input) => {
        this.setState({
            customGPOName: input
        });
    };
    render() {
        const { props, selectOptionMemberGPO, selectOptionGPOName, createGPOName } = this;
        const { changeHandlerStep3Inputs, authorizationChecked, authorizationReinhartChecked, authorizationFsrChecked,
            selectOptionDistributorManufacturer, step3Inputs, nameOfCompaniesHandler, isPorchDoor,
            handleChangeTerminationDate, companyText, companyBrand, authorizationLOTChecked, hideLegalForm, signUpSalesRep,
          companyId } = props;
        const { isGpo, terminationDate, gpoName } = step3Inputs;
        const options = this.state.manufacturers;
        const { validFields, notValidMessage, customGPOName } = this.state;

        const n = companyBrand.name.toUpperCase();
        const c = companyBrand.code.toUpperCase();

        let textLegal =  `I am an authorized agent, owner or employee of the above business (the “Member”) and have the authority to enter into a participation agreement with ${n} on its behalf. To the best of my knowledge, all information provided herein is correct, if [${c}] should discover that the information provided is not correct, it has the right to cancel or amend our participation in any and all programs through [${c}]. ` +
            ` By signing this letter or participation, I am authorizing [${c}] to enroll the business listed above in one or more [${c}] programs as directed by the member. I also acknowledge that any current programs we desire to continue through a direct relationship have been disclosed and I understand that we will not be allowed to participate in the related [${c}] program(s). Further, if it is discovered that a program or programs exist or existed that was not disclosed above, we agree and authorize [${c}] to allow us to remain on the [${c}] program(s). Once notified, I will cancel said direct agreement within 5 business days and agree to repay any monies related to the undisclosed program(s). ` +
            ` By signing this letter of authorization, I hereby authorize [${c}] to contact all distribution companies listed within this participation agreement in order to obtain product level data and all other data relevant to the above identified Member which may include, without limitation, purchase history, inventory and stocking related information, unique and proprietary products and such other information required to manage Member’s supply chain. I hereby authorize [${c}] to use such Member relevant data for the purposes of securing reporting, for the purposes of price audit and verification, securing pricing, volume allowances tracking and opportunity analysis and for any purpose directed by the Member. Member further agrees that such information may be released by [${c}] to its designated rebate processor and Buyers Edge Platform, LLC. Additionally, I authorize [${c}] to collect and distribute rebates on my behalf, to be paid quarterly. Furthermore, I acknowledge that [${c}] will receive a fee for program administration and participation. Termination of membership shall only be effective upon ninety (90) days’ written notice from member. [${c}] reserves the right to change the [${c}] Membership Terms & Conditions at any time. The current [${c}] Membership Terms & Conditions shall be available at www.diningallianice.com and it shall be Member’s responsibility to be aware of and comply with the [${c}] Membership Terms & Conditions. `

        const textReinhartAgreement = `The undersigned (“Customer”), a customer of Performance Food Group, Inc. and/or one or more of its affiliates (collectively, “Performance”), hereby requests Performance to disclose information regarding Customer’s purchasing information to the following entity (the “Third Party Recipient”):\n\nThird Party Recipient: Entegra and Buyers Edge\n\nCustomer hereby acknowledges that the information disclosed to the Third-Party Recipient may contain confidential and proprietary information of Customer. Customer hereby waives and releases Performance from any and all claims, demands, actions, causes of action, liability, damages and demands of any kind whatsoever that Customer may have against Performance in connection with Performance’s disclosure of Customer information to the Third-Party Recipient.\n\nThis authorization shall continue in effect until Performance receives written\nnotice of its revocation from Customer. All such notices shall be provided to\nPerformance at Performance Food Group, Inc., 12500 West Creek Parkway, Richmond,\nVA 23238, Attn: VP, National Accounts with a copy to Performance Food Group, Inc.,\n12500 West Creek Parkway, Richmond, VA 23238, Attention: General Counsel.`

        const FsrTextLegal = `I would like to be enrolled to earn rewards points through our partner Foodservice Rewards at no cost! By checking this box, I authorize my contact information to be provided to Foodservice Rewards to enroll me in the Foodservice Rewards' Program and accept their terms and conditions found at <a target="_blank" style="color: black;" href="https://tinyurl.com/fsrterms">https://tinyurl.com/fsrterms</a>. Foodservice Rewards will contact you to complete your enrollment process.`

        const isReinhartSelected = this.isReinhartSelected();

        const LOTLegalText = this.LOTLegalText();
        const selectedGpoName = gpoName !== "" && {label: gpoName, value: gpoName};
        const showLOTTerms = gpoName !== "" && isGpo === 1;
        const isPortrait = window.screen.height > window.screen.width;
        const signatureWidthPortrait = isTablet ? 698 : (isMobile ? 320 : 500);
        const signatureWidthLandscape = isTablet ? 490 : (isMobile ? 345 : 500);
        const signatureWidth = isPortrait ? signatureWidthPortrait : signatureWidthLandscape;

        const gpoNameOptions = [{label: `Add: ${customGPOName || '<Type to add custom name>'}`, value: customGPOName},...config.gpoNameOptions]

        return (
            <GridCol>
                <GridRow>
                    <GridCol>
                        <InputLabel text='Legal Information' title className='title'/>
                    </GridCol>
                </GridRow>
                {!hideLegalForm &&
                    <Fragment>
                        <GridRow className='line-form' style={{alignItems:'flex-end'}} >
                            <GridCol className='form-group'>
                                <InputLabel text='Are you a Member of a Group Purchasing Organization (GPO) *'/>
                                <DropDownList title={props.step3Inputs.isGpoTitle}>
                                    <DropDownListOption title='Yes' value={1} onClick={selectOptionMemberGPO.bind(this, 1)} />
                                    <DropDownListOption title='No' value={0} onClick={selectOptionMemberGPO.bind(this, 0)} />
                                </DropDownList>
                            </GridCol>
                            { companyId !== 17 && 
                                <GridCol className='form-group'>
                                    <InputLabel text={companyText.terminationDate} /><br/>
                                    <DatePicker locale='en' popperPlacement="bottom-end" selected={isGpo ? terminationDate : null} dateFormat="MM/dd/yyyy" 
                                        onChange={handleChangeTerminationDate} disabled={!isGpo} placeholderText={isGpo ? 'Select' : 'This is disabled'} />
                                </GridCol>
                            }
                            <GridCol className='form-group'>
                                <InputLabel text='Group Purchasing Organization *'/>
                                <DropDownListSelect ref='gpoName' value={selectedGpoName} options={gpoNameOptions} onInputChange={createGPOName}
                                    onChange={selectOptionGPOName} isDisabled={!props.step3Inputs.isGpo} isSearchable={true} />
                            </GridCol>
                        </GridRow>

                        { signUpSalesRep && companyId === 3 ?
                            null
                        :
                            <GridRow className='line-form'>
                                <GridCol className='form-group'>
                                    <InputLabel text='Direct Manufacturer Agreements (Direct deals) *'/>
                                    <DropDownList title={props.step3Inputs.isDistributorManTitle} >
                                        <DropDownListOption title='Yes' value='1' onClick={selectOptionDistributorManufacturer.bind(this, 1)} />
                                        <DropDownListOption title='No' value='0' onClick={selectOptionDistributorManufacturer.bind(this, 0)} />
                                    </DropDownList>
                                </GridCol>
                                <GridCol className='form-group'>
                                    <InputLabel text="Name of Direct Deal Manufacturer (to prevent 'double dipping') *"/>
                                    <DropDownListSelect ref='nameOfCompaniesSelect'
                                    value={props.step3Inputs.nameOfCompanies} options={options}
                                    onChange={nameOfCompaniesHandler} isMulti onInputChange={this.searchManufacturers}
                                    isDisabled={props.step3Inputs.isDistributorManufacturerAgreement === 1 ? false : true} />
                                </GridCol>
                            </GridRow>
                        }
                    </Fragment>
                }

                    <GridRow className='line-form'>
                        <GridCol className='form-group'>
                            <GridRow className='check'>
                                <GridCol className={isMobile ? 'col-2' : 'col-1'}>
                                    <Checkbox colorClassName={isPorchDoor ? 'be-blue' : null} name='isAuthorized' value={props.step3Inputs.isAuthorized} text='' onChange={authorizationChecked} className={step3Inputs.isAuthorizedInvalid ? 'invalid' : ''} />
                                </GridCol>
                                <GridCol className={'col' + (isMobile ? '' : ' pl-2 checked-text') + (step3Inputs.isAuthorizedInvalid ? ' text-red' : '')} style={{fontSize:'12px'}}>
                                    Acknowledgement, Authorization and Release
                                </GridCol>
                            </GridRow>
                        </GridCol>
                    </GridRow>
                    <GridRow className='line-form'>
                        <GridCol className='form-group'>
                            <div className={'col-12 signup-authorized-div'}>
                                { ReactHtmlParser(companyText.termsAndConditions || textLegal)}
                            </div>
                        </GridCol>
                    </GridRow>

                {showLOTTerms && companyId !== 17 && 
                    <>
                        <GridRow className='line-form'>
                            <GridCol>
                                <GridRow className='check'>
                                    <GridCol className={isMobile ? 'col-2' : 'col-1'}>
                                        <Checkbox colorClassName={isPorchDoor ? 'be-blue' : null} name='isAuthorized'
                                        value={props.step3Inputs.isLOTAuthorized} text=''
                                        onChange={authorizationLOTChecked}
                                        className={step3Inputs.isLOTAuthorizedInvalid ? 'invalid' : ''} />
                                    </GridCol>
                                    <GridCol className={'col' + (isMobile ? '' : ' pl-2 checked-text') + (step3Inputs.isLOTAuthorizedInvalid ? ' text-red' : '')} style={{ fontSize: '12px' }}>
                                        Acknowledgement, Authorization and Release
                                    </GridCol>
                                </GridRow>
                            </GridCol>
                        </GridRow>
                        <GridRow className='line-form'>
                            <GridCol className='form-group'>
                                <div className={'col-12 signup-authorized-div'}>
                                    {ReactHtmlParser(LOTLegalText)}
                                </div>
                            </GridCol>
                        </GridRow>
                    </>
                }

                {isReinhartSelected &&
                    <Fragment>
                        <GridRow className='line-form'>
                            <GridCol>
                                <GridRow className='check'>
                                    <GridCol className={isMobile ? 'col-2' : 'col-1'}>
                                        <Checkbox colorClassName={isPorchDoor ? 'be-blue' : null} name='isReinhartAuthorized' value={props.step3Inputs.isReinhartAgreement} text='' onChange={authorizationReinhartChecked} className={step3Inputs.isReinhartAuthorizedInvalid ? 'invalid' : ''} />
                                    </GridCol>
                                    <GridCol className={'col' + (isMobile ? '' : ' pl-2 checked-text') + (step3Inputs.isReinhartAuthorizedInvalid ? ' text-red' : '')} style={{fontSize:'12px'}}>
                                        Performance Food Service Acknowledgement, Authorization and Release
                                    </GridCol>
                                </GridRow>

                            </GridCol>
                        </GridRow>
                        <GridRow className='line-form'>
                            <GridCol className='form-group'>
                                <TextArea readOnly className={'col-12 signup-authorized'} col={4} rows={8} defaultValue={textReinhartAgreement} />
                            </GridCol>
                        </GridRow>
                    </Fragment>
                }

                { !signUpSalesRep &&
                    <Fragment>
                    <GridRow>
                        <GridCol className='mt-2'>
                            <InputLabel text='Confirm your name and signature' title className='title'/>
                        </GridCol>
                    </GridRow>
                    <GridRow className='line-form'>
                        <GridCol className='form-group'>
                            <InputLabel text='Your Full Name *'/>
                            <Input type='text' placeholder='Full Name' ref='signedText' name='signedText' value={props.step3Inputs.signedText} onChange={changeHandlerStep3Inputs} validate min={3} max={100} />
                        </GridCol>
                    </GridRow>
                    <GridRow className='line-form'>
                        <GridCol size={12} className='form-group px-3'  onMouseLeave={this.sendSignature}>
                            <InputLabel text='Signed by *'/><br/>
                            <SignatureCanvas penColor='black' canvasProps={{width: signatureWidth, height: 120, className: 'sigCanvas border'}}
                                ref={(ref) => { this.sigCanvas = ref }}
                            />
                        </GridCol>
                        <GridCol size={12} className='form-group text-left'>
                            <Button type='button' variant={ 'white'} size='sm' text='CLEAR' onClick={this.cleanSignature}/>
                        </GridCol>
                    </GridRow>
                    </Fragment>
                }

                { (signUpSalesRep && companyId === 3) || companyId === 17 ?
                    null
                :
                    <Fragment>
                    <GridRow className='line-form'>
                        <GridCol>
                            <GridRow className='check'>
                                <GridCol className={isMobile ? 'col-2' : 'col-1'}>
                                    <Checkbox colorClassName={isPorchDoor ? 'be-blue' : null} name='isFsrAuthorized' value={props.step3Inputs.isFsrAuthorized} text='' onChange={authorizationFsrChecked} className={step3Inputs.isFsrAuthorizedInvalid ? 'invalid' : ''} />
                                </GridCol>
                                <GridCol className={'col' + (isMobile ? '' : ' pl-2 checked-text') + (step3Inputs.isFsrAuthorizedInvalid ? ' text-red' : '')} style={{fontSize:'12px'}}>
                                    Reward Program Enrollment, Acceptance and Authorization
                                </GridCol>
                            </GridRow>
                        </GridCol>
                    </GridRow>
                    <GridRow className='p-2'>
                        <GridCol className={'text-center ' + (isMobile ? 'col-2' : 'col-1')}>
                            <img src={imgFsrlogo} alt='fsr'/>
                        </GridCol>
                        <GridCol className={'form-group ' + (isMobile ? 'col-10' : 'col-11')}>
                            <div className={'col-12 signup-authorized-div'}>
                                { ReactHtmlParser(FsrTextLegal)}
                            </div>
                        </GridCol>
                    </GridRow>
                    </Fragment>
                }

                    <GridRow className='line-form submit-buttons'>
                        <GridCol className='form-group'>
                            <Button type='button' variant={ isPorchDoor ? 'white be-blue-border be-blue-color' : 'white'} size='' text='BACK' onClick={this.back}/>
                        </GridCol>
                        <GridCol className='form-group'>
                            <Button type='button' variant={ isPorchDoor ? 'blue be-blue-border be-blue-bg' : 'primary'} size='' text='DONE' onClick={this.done}/>
                        </GridCol>
                    </GridRow>
                    <GridRow className='line-form'>
                        <GridCol className='col form-group d-flex align-items-cente'>
                            <InputLabel className='text-red text-size-14'>{validFields ? '' : notValidMessage}</InputLabel>
                        </GridCol>
                    </GridRow>
            </GridCol>
        );
    };
}

export default FormDefaultStep4;
