import React from 'react';
import { Row, Col } from 'reactstrap';
import Checkbox from '../../../../MasterComponents/Checkbox/Checkbox';

const AddNewContractModalCheckbox = ({onChange, checked, label}) => {
	return (
		<Row className='px-0 clickable d-flex flex-row align-items-center'>
			<Col className='text-grey pr-0' style={{maxWidth: '32px'}}>
				<Checkbox className='mt-2 mb-3' text='' name={label} value={checked} onChange={onChange} />
			</Col>
			<Col className='py-2' onClick={onChange}>
				{label}
			</Col>
		</Row>
	);
};

export default AddNewContractModalCheckbox;