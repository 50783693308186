import React from 'react';
import Button from '../Button/Button';
import GridCol from '../Grid/GridCol';
import GridRow from '../Grid/GridRow';
import Modal from '../Modals/Modal';
import ModalBody from '../Modals/ModalBody';
import ModalFooter from '../Modals/ModalFooter';
import { findModal } from '../Modals/ModalsActions';
import { isMobile, isIOS, isAndroid } from 'react-device-detect';

const OpenInAppModal = ({modals, appUrl}) => {
  const onClose = () => {
    findModal(modals, 'openInAppModal').close();
  };
  const onOpen = () => {
    // "intent://myda/login#Intent;scheme=mydiningalliance;package=com.rebate_portal_mobile_app;end"
    //window.open('intent://mydiningalliance/login#Intent;package=com.rebate_portal_mobile_app;end')

    if (isMobile && isAndroid) {
      window.location = appUrl+window.location.pathname;
    }

    if (isMobile && isIOS) {
      window.location = appUrl+window.location.pathname;
      setTimeout(() => {
        window.location = "https://apps.apple.com/us/app/dining-alliance/id1559282800";
      }, 200);
    }
  };
  return (
    <Modal id='openInAppModal' size='100'>
      <ModalBody>
        <GridRow className='mt-2'>
          <GridCol className='text-size-16 text-center text-line-height-20 text-weight-bold'>
            See My Dining Alliance in...
          </GridCol>
        </GridRow>
      </ModalBody>
      <ModalFooter className='flex-column pb-4'>
        <GridRow className='w-100 pb-5'>
          <GridCol className='pl-0' size={6}>
            <Button variant='white' onClick={onClose} className='clickable col' size='lg' text='Browser' />
          </GridCol>
          <GridCol className='pr-0' size={6}>
            <Button variant='primary' onClick={onOpen} className='clickable col' size='lg' text='Open in App' />
          </GridCol>
        </GridRow>
      </ModalFooter>
    </Modal>
  );
};

export default OpenInAppModal;
