import React from 'react';
import { isMobile } from 'react-device-detect';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../MasterComponents/Grid/GridRow';

const Walkthrough = ({image, text}) => {
  return (
    <GridCol>
      <GridRow>
        <GridCol className='text-center'>
          <img className={isMobile ? 'w-100' : 'w-75'} src={image} alt='onboarding walkthrough' />
        </GridCol>
      </GridRow>
      <GridRow className='mt-4'>
        <GridCol className='text-center'>
          {text}
        </GridCol>
      </GridRow>
    </GridCol>
  );
};

export default Walkthrough;