import React, { Component } from 'react';
import { getClaimACHHasTopHeader } from '../../config/Company/CompanyHelper';
import ClaimCashBody from './ClaimCashBody';
import ClaimCashTop from './ClaimCashTop';

class ClaimCash extends Component {
    render() {
        const props = this.props;
        const { companyId } = this.props;
        const claimACHHasTopHeader = getClaimACHHasTopHeader(companyId);
        return (
            <>
                { claimACHHasTopHeader &&
                    <ClaimCashTop isMobile={props.isMobile} />
                }
                <ClaimCashBody {...props} />
            </>
        );
    }
}

export default ClaimCash;