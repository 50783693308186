export default function reducer(state = {
  notificationCounter: 0,
  notificationList: []
  }, action) {
    switch (action.type) {
      case 'NOTIFICATION_SET_DATA':
        const previousCount = sessionStorage.getItem('notificationCount') ? parseInt(sessionStorage.getItem('notificationCount')) : 0;
        sessionStorage.setItem('notificationCount', action.payload.length);
        if (action.payload.length > 0 && action.payload.length >= previousCount) {
          sessionStorage.setItem('trackDistributorIssue', '1');
        } else {
          sessionStorage.setItem('trackDistributorIssue', '0');
        }
        return {
          ...state,
          notificationList: action.payload
        };
      case 'NOTIFICATION_SET_COUNTER':
        return {
          ...state,
          notificationCounter: action.payload
        };
      default:
        return state;
    }
  }
