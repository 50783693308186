import React from 'react';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import useHomeLocations from './hooks/useHomeLocations';
import IconMarker from '../../MasterComponents/Icons/IconMarker';
import IconTruck from '../../MasterComponents/Icons/IconTruck';
import './HomeMain.scss';
import { getHomeLocationsMarkerIconBgStyle, getHomeLocationsMarkerIconColor, getHomeLocationsTruckIconBgStyle, getHomeLocationsTruckIconColor } from '../../config/Company/CompanyHelper';
import config from '../../config/config';

const HomeLocations = (props) => {
  const { companyId } = props;
  const { spinner } = useSelector(({location}) => location.summary);

  const homeLocationsMarkerIconColor = getHomeLocationsMarkerIconColor(companyId)
  const homeLocationsMarkerIconBgStyle = getHomeLocationsMarkerIconBgStyle(companyId)
  const homeLocationsTruckIconColor = getHomeLocationsTruckIconColor(companyId)
  const homeLocationsTruckIconBgStyle = getHomeLocationsTruckIconBgStyle(companyId)

  const { onAddNewLocation, onAddNewDistributor, onGoToLocations, merchantAllCount, distributorAllCount } = useHomeLocations(props);
  const addNewLocation = () => {
    onAddNewLocation();
  }

  const addNewDistributor = () => {
    onAddNewDistributor();
  }

  return (
    <>
      <Row className={'line-height-38 ' + (isMobile ? 'text-size-30' : 'text-size-32')}>
        <Col className={'py-3 ' + (isMobile ? 'px-0' : '')}>
          Locations & Distributors
        </Col>
      </Row>
      <Row>
        <Col className={isMobile ? 'px-0' : ''}>
          <Card style={{height: isMobile ? null : '320px'}}>
            {
              spinner ?
                <CardBody>
                  <Row className='align-items-center justify-content-center' style={{height: '288px'}}>
                    <div className="lds-ring" style={{borderColor: `${config.colors.primary} transparent transparent transparent`}}><div></div><div></div><div></div><div></div></div>
                  </Row>
                </CardBody>
              :
              <CardBody>
                <Row>
                  <Col xs={isMobile ? 12 : 6} hidden={companyId === 17}>
                    <Row className='p-3'>
                      <Col xs={12} className='text-center py-3'>
                        <span className='home-circle' style={{ backgroundColor: homeLocationsMarkerIconBgStyle }}>
                            <span> <IconMarker color={homeLocationsMarkerIconColor} size={30} /></span>
                          </span>
                      </Col>
                      <Col xs={12} className='text-center text-size-20 line-height-24 text-bold py-3'>
                        {merchantAllCount} Locations
                      </Col>
                      <Col xs={12} className='d-flex justify-content-center py-3'>
                        <Button outline block className='py-2 shadow-none btn-rb-white' size='sm' color='' onClick={()=> addNewLocation() }>ADD NEW</Button>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={isMobile ? 12 : (companyId === 17) ? 12 : 6}>
                    <Row className='p-3'>
                      <Col xs={12} className='text-center py-3'>
                        <span className='home-circle' style={{ backgroundColor: homeLocationsTruckIconBgStyle }}>
                          <span><IconTruck color={homeLocationsTruckIconColor} size={30}/></span>
                        </span>
                      </Col>
                      <Col xs={12} className='text-center text-size-20 line-height-24 text-bold py-3'>
                        {distributorAllCount} Distributors
                      </Col>
                      <Col xs={12} className='d-flex justify-content-center py-3'>
                        <Button outline block className='py-2 shadow-none btn-rb-white' size='sm' color='' onClick={()=> addNewDistributor()} >ADD NEW</Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            }
          </Card>
        </Col>
      </Row>
      <Row className='py-3'>
        <Col className={isMobile ? 'px-0' : ''}>
          <Button active block style={{width: isMobile ? '100%' : ''}} className='py-2 shadow-none' size='sm' color='primary'onClick={onGoToLocations} >VIEW ALL</Button>
        </Col>
      </Row>
    </>
  )
}

export default HomeLocations;
