

export function terms() {
    return `<br/>
        Terms and Conditions <br/>
        These Terms and Conditions are effective as of April 27, 2020. See Section [X] for further information on how changes may be made.
        1.      Introduction
        1.1.    Buyers Edge Platform and You
        1.1.1. Buyers Edge Platform is a group purchasing organization offering rebate processing services to its members operating in the foodservice industry. In addition, Buyers Edge Platform’s subsidiaries and affiliates offer consulting based services to Buyers Edge Platform’s business customers (each a “Member” or a “Payee”) (the “Services”) who desire to make payments to their designated payees, like you.
        1.1.2. Buyers Edge Platform operates and provides the Services through subsidiaries and affiliates (each an “Affiliate”) in North America. Together with their respective parents, subsidiaries and affiliates, these Affiliates are individually and collectively referred to in these Terms and Conditions as “BEP”.  
        1.1.3. BEP has created a technology portal which may be accessed through a website, application programming interface/API, embedded in or integrated with a third-party platform or system, or other method, and may be branded with the third party’s brand or co-branded with the BEP brand (the “Portal“). The Portal is the interface that may facilitate certain payments which you will receive from BEP(the “Payouts” and each, a “Payout”). Payout capabilities are available in multiple currencies and can be implemented using a variety of different methods. You may be able to receive a Payouts from and as directed by BEP through one of several methods, each as determined by your BEP, such as (but not limited to) a transfer to your bank account, load to a prepaid card issued to you by a third-party issuer (“Prepaid Card”), load to your existing debit card, issuance of e-money account (where available), cheque, cash pickup at collection locations provided by third-party money transfer providers (e.g. Western Union), and other payment methods (each a “Payout Method” and collectively “Payout Methods”)), all as supported and made available from time to time. Some Payout Methods may require access to the Portal. The Payouts may comprise money remittance and/or the issuance and distribution or sale of electronic money or stored value. The Payouts are delivered through the BEP’s third party payments vendor, and a network of banks and financial service providers (“Network Partners”). 
        1.1.4 BEP may, at any time and in its sole discretion, delegate the performance of any of the Services, obligations and/or requirements hereunder to 1) a third party vendor, and 2) any of its Affiliates, agents, suppliers and contractors and BEP may disclose to any such persons any information required by them to perform the duties so delegated to them pursuant to the Privacy Policy (see the “Privacy” section of the website).
        1.2.    Why these Terms and Conditions are important to You
        1.2.1. The terms “you”, “your” and “Payee” refer to users making use of the Portal to obtain Payouts. The terms “BEP,” “we,” “us,” “our,” and similar terms, refer to BEP as described herein. Other capitalized terms not otherwise defined within the body of the Terms and Conditions shall have the meanings set forth in the Definitions Schedule.
        1.2.2. BEP has engaged a third party vendor to provide the Services to it, for its subsequent provision of certain Payouts to you. By providing your details on the Portal, registering on, having access to, or accessing the Portal, you acknowledge all of the terms and conditions contained in these Terms and Conditions and the underlying BEP Policies, which include:
        a)     Privacy Policy (see the “Privacy” section of the website)
        b)     GPO Letter of Participation (“LOP”); and
        c)     The Membership Terms and Conditions.
        1.2.3. The Terms and Conditions govern your receipt of Payouts through the Payout Methods. As BEP determines which Payout Methods will be made available to you, not all terms and conditions will apply. Only those terms and conditions relating to the certain Payout Methods determined by BEP to be made available to you may apply. Nonetheless, these are important documents which you should read and consider carefully. Please print or save a copy of these Terms and Conditions for your records.
        1.2.4. We reserve the right to change the Terms and Conditions at any time by posting a revised version in the Portal. The revised version will be effective at the time we post it to the Portal. You are responsible for regularly reviewing the Terms and Conditions. Your continued ability to access the Portal, and/or otherwise using the Payout Methods or receiving the Payouts after the date of any changes constitutes your acceptance of any new or changed Terms and Conditions. If you do not wish to accept the new or changed Terms and Conditions, you must cease accessing the Portal and discontinue receiving the Payouts.
        2. Payouts
        2.1.1. Some registration and other identifying information about you may be provided to BEP’s designated third party payment vendor from time to time. Multiple registrations are prohibited. You may register only once, and each user must maintain a separate registration. If we detect multiple active registrations for a single user, we reserve the right to merge or terminate the registrations and refuse to permit you to receive Payouts without notification to you
        2.1.2. Payees who are companies or registered under a business name are deemed to provide any information required and accept these Terms and Conditions through their authorised representative. If you register on behalf of a company or under a business name, you represent that you are a legal representative of the business Payee, and that you are authorized to provide any information about the business and accept these Terms and Conditions on its behalf. 
        2.2    Restrictions
        2.2.1. The Payout Methods are intended to enable Payouts for business-related and commercial activities in connection with your relationship with BEP and are not intended and may not be used for personal, family, or household purposes. By registering on the Portal, you attest that you are engaged in a trade or business for gain or profit, whether as a sole proprietor or otherwise and are using the Portal for commercial purposes as an independent contractor of  BEP and not for the receipt of any wages subject to payroll withholding or otherwise for personal, family or household purposes. 
        2.2.2.  BEP is not responsible for and does not have any liability with respect to any of the products, services, or activities for which you receive a Payout via the Portal or any of the actions or inactions of BEP or any third party, including but not limited to any dispute as to any amount of funds paid or payable to you by BEP or otherwise. 
        2.3.    Third-Party Services
        2.3.1. You may be able to elect to receive certain services from certain service providers of BEP including, without limitation a third party payments vendor (each such service, a “Third-Party Service,” and each such entity, a “Provider”). BEP is not responsible for such Providers, the Third-Party Services or any material, information or results available through such Third-Party Services. The applicable Providers may require you to agree to terms and conditions or agreements with respect to their provision of the Third-Party Services to you. You are solely responsible for, and assume all risk arising from, your election and receipt of any Third-Party Service.
        2.3.2. If you elect to receive a Third-Party Service, you authorize BEP to submit to the applicable Provider any and all documents and information about you and BEP necessary for such Provider to provide the Third-Party Service to you, including without limitation your personally identifiable information, requested by such Provider that you have provided to BEP in connection with this Agreement and your receipt of the Payouts. You are responsible for the accuracy of all information you provide to us and approve to be submitted to Providers. You represent and warrant that you have all the rights in and to any information necessary to provide information to BEP, and that BEP’s use of information as contemplated hereunder will not violate any rights of privacy or other proprietary rights, or any applicable local, state or federal laws, regulations, orders or rules.
        2.3.3. You agree that by electing to receive a Third-Party Service and consenting and authorizing BEP to submit your information to a Provider, you have waived and released any claim against BEP arising out of a Provider’s use of your information. In no event will BEP be liable to you or any third party for any direct, indirect, consequential, special, or punitive loss or damages regardless of whether such damages are based on contract, tort (including negligence), strict liability, or any other theory or form of action or whether BEP knew or should have known of the likelihood of such damages in any circumstances.
        2.4.    Identity Verification and Third-Party Permissions
        2.4.1. You acknowledge and agree that BEP and/or its Provider may, at any time, as a condition of receiving the Payouts and in accordance with our Privacy Policy (see the “Privacy” section of the website), ask you for information that will allow us to reasonably identify you, require you to take steps to confirm the accuracy and/or completeness of information you have provided to us, and/or verify your information against third-party databases or through other means for purposes of providing the Payouts to you and/or as may be required to perform required screening, monitoring, and investigation of your Portal activities and in order to comply with applicable laws. Information we may require from you may include your name, address, date of birth, government identification number, taxpayer identification number, phone number, email address, and other information that will allow us to identify you. BEP or a Provider may require you to provide documentation, which may include your passport, driver’s license, or other government issued photo identification document. We may also contact you if we have additional questions. You agree that BEP reserves the right to directly assess your fees related to our identity verification, screening, monitoring, investigations and similar activities in connection with your Portal and/or receipt of the Payouts. BEP reserves the right to close, suspend, limit or deny access to the Payouts or the Portal in the event we are unable to obtain this information, for any reason or for no reason, in our sole discretion.
        2.4.2. You agree to the disclosure of your information to third-party verification service(s) providers, Network Partners, government agencies, and other third parties for identity verification purposes, to meet anti-money laundering, anti-terrorist financing, transaction monitoring, and suspicious activity reporting requirements, or as otherwise required to provide the Payout Methods to you. You acknowledge and authorize that information concerning the location of the device you use to access the Portal may be used to assess location, may be obtained at any time while logged into the Portal, and such information may be used or disclosed as a part of the Payout Methods and to establish your eligibility to receive Payouts. Any third party to which your information is transmitted will be bound to maintain your confidentiality and may not use the information supplied for any unauthorized purpose other than to verify your identity, meet our legal, regulatory, risk requirements, provide the Payout Methods, or as otherwise described herein or in the Privacy Policy (see the “Privacy” section of the website).
        2.5 Your Responsibilities
        2.5.1. You are responsible for maintaining adequate security and control of any and all IDs, usernames, passwords, personal identification numbers (PINs), or any other codes that you use to access the Portal or to receive the Payouts. You expressly acknowledge and agree that failure to maintain the security of these items may result in unauthorized transactions and/or loss to you for which BEP is not responsible to reimburse you or otherwise pay you for any such loss. You agree to notify BEP immediately of any unauthorized use of your Portal or any other breach of security. You will be responsible for any transfer of funds request received via your Portal unless we are notified of unauthorized use in the manner set out in these Terms and Conditions. You are urged to take appropriate safeguards before downloading information from the Portal and any third-party links or content connected thereto. We assume no responsibility for any damages to computer equipment or other property that may result from use of the Portal or downloading anything from the Portal.
        2.5.2. Notwithstanding the foregoing, you are responsible for any and all activities, acts or omissions that occur in relation to your Portal, including the accuracy of the information transmitted through your Portal. You are responsible for your Internet connection or any other method that you use to connect to the Portal. You represent that your email account and all information sent to and from your email account is secure and nobody has access to your email account except for you. You also acknowledge that granting permission to a third party to take specific actions on your behalf does not relieve you of any of your responsibilities under this Agreement. Further, you acknowledge and agree that you will not hold BEP responsible for, and will defend and indemnify BEP from, any liability arising from the actions or inactions of this third party in connection with any permissions you grant.
        2.5.3. Subject to Section 7.5, notwithstanding these Terms and Conditions, you may not assign or transfer your right to utilize the Portal to a third party or otherwise grant any third party a legal or equitable interest in connection with the Portal.
        2.5.4. You agree that you may not and will not use the Portal:
        (i)     for any purpose that is unlawful, fraudulent, deceitful, untruthful, misrepresentative, dishonest or related in any manner to unlawful, unethical or morally questionable activities or is directly or indirectly related to the foregoing;
        (ii)    if you are located, residing, working or conducting business in any country restricted by any of the following, which BEP or its Provider may update from time to time without prior notification to you: Special Economic Measures Act (Canada), the United Nations Act (Canada), the Freezing Assets of Corrupt Foreign Officials Act (Canada), the U.S. Office of Foreign Assets Controls’ (“OFAC”) Specially Designated Nationals and Blocked Persons List, or the U.S. Department of State’s Designated Foreign Terrorist Organizations List, as applicable and as, may be amended from time to time; or (ii) any applicable sanction, directive or other action by Foreign Affairs and International Trade Canada, OFSI, OFAC or the United Nations or any other Canadian, U.S, Australian, or European governmental authority regarding terrorism or money laundering;
        (iii)    for any illegal purposes including but not limited to fraud and money laundering. We will report any suspicious activity to the relevant law enforcement agency;
        (iv)   in any attempt to abuse, exploit or circumvent any law or regulation;
        (v)    In any manner that attempts to tamper, hack, modify, frame, "deep link" or otherwise bypass or attempt to bypass security, functionality, entry points or any other features of the Service or the Portal;
        (vi)   directly or indirectly, either separately or as part of another service, attempt to redirect the Payouts; or
        (vii)   for any purpose that BEP or its Provider prohibits in its sole discretion or as otherwise prohibited by the Terms and Conditions.
        3. Fees
        3.1.    Fees related to Payout Methods
        3.1.1. Fees applicable to the Payout Methods (“Fees”) are generally accessible in the Portal or such other channel as may be communicated to you. These Fees are established pursuant to an agreement between BEP and Provider and/or as otherwise determined by Provider in its sole discretion. Fees associated with a Prepaid Card are determined by the Issuer and are outlined in the Issuer’s Cardholder Agreement. In accepting the Terms and Conditions, you agree to pay all applicable Fees associated with the Portal and any and all Payout Methods made available to you. If you do not agree to pay the Fees set forth in the Portal you may close your Portal.
        3.1.2. In addition, Provider, Network Partners and other third parties facilitating the Payout Methods may impose additional fees on transactions, which may be deducted from available funds reflected in your Portal balance. You will be responsible for any fees incurred by BEP or Provider related to a Payout rejected due to errors or omissions in the Payout Method details supplied by you.
        Any bank processing fees, currency exchange fees or intermediary fees which may be charged by your bank or any other payment provider not directly associated with Provider are your sole responsibility. BEP and Provider reserve the right to directly assess your fees and/or taxes related to our servicing and support of your Portal arising out of applicable law, including but not limited to sales or use taxes, value-added taxes (“VAT”), goods and services taxes (“GST”). You are also responsible for SMS, data services, and any similar phone or internet service fees associated with your use of the Portal via computer, mobile device or otherwise.
        3.2.    Collection
        Fees payable by you may be: i) deducted from the amount transferred in the specific transaction to which the fee is applicable, upon your execution of the transaction (for example, if you direct us to receive a Payout from BEP to your bank account through the Portal, the amount transferred to your bank account will be the amount you specify, less the fee applicable to Transfer Funds to Local Bank Account); and/or ii) deducted automatically from available funds reflected in your Portal or Prepaid Card balance, as the case may be. You hereby authorize us to deduct all Fees from the amounts transferred and/or reflected in your Portal, as applicable.
        3.3.    Changes
        BEP reserves the right to change or otherwise adjust Fees at the direction of BEP, an Issuer (in respect of BEP Cards), or otherwise at our sole discretion. We will publish updated fees in the Portal. Fees will take effect immediately unless otherwise stated or as otherwise provided for in these Terms and Conditions. If you have a question about any of the Fees including any dispute involving any Fee paid or payable by you for the Payout Methods, contact BEP directly or you may direct those inquiries to BEP by contacting its third party vendor.
        4.    Copyright and Other Intellectual Property Rights
        The Portal contains copyrighted material, trade-marks and other proprietary and confidential information of BEP, Provider and others (collectively “Proprietary Material”), which may include, but shall not be limited to text, documents, information, data, articles, images, photographs, graphics, software, applications, video recordings, audio recordings, sounds, designs, features, and other materials (collectively with the Proprietary Material, “Content”). All Proprietary Material is owned by BEP or Provider, their respective licensors and various third parties and is protected by applicable intellectual property rights including copyright, patent and trade-mark legislation and treaties. You agree not to modify, publish, copy, transmit, register or claim title to, participate in the transfer or sale of, create derivative works of, or in any way exploit, in whole or in part, any Proprietary Material except for copying that occurs in the ordinary course of browsing the Internet and personal copies of Portal information that you make for your personal use including for your own records. You agree to respect any copyright, trade-mark, patent and other proprietary rights contained in any Proprietary Material on the Portal. The only rights that you can have in Proprietary Material outside of the Terms and Conditions are those granted in writing by BEP, Provider or other owners with rights to the Proprietary Material.
        4.2.    Links and Content
        4.2.1 The links included within the Portal may cause you to leave the Portal in order to gain access to other websites including those related to Third-Party Services (“Linked Sites”). The Linked Sites are not under the control of BEP and we are not responsible for the content of any Linked Site, the services provided at any Linked Site, any link contained in a Linked Site, or any changes or updates to such sites unless otherwise noted on such sites. We provide these links to you only as a convenience. We may amend, add or delete links on the Portal as we determine in our sole discretion. The inclusion of any link to any Linked Sites in our Portal or any link on a third party’s site to our Portal does not reflect an endorsement by us nor does it necessarily reflect any association with their operators. When you access Linked Sites, you do so at your own risk.
        4.2.2 The Content is intended for information purposes only. Although we exercise reasonable efforts to ensure their quality and accuracy, there might be errors, or the information provided may not be complete, current, or applicable to your particular situation. We assume no liability or responsibility for any errors or omissions. You are responsible for evaluating the accuracy, completeness, and usefulness of any opinion, advice, or other content available through the Portal or obtained from a Linked Site.
        4.3.    No Endorsement
        No endorsement or approval of any third party or their advice, opinions, information, products or services is expressed or implied by the Portal or Payout Methods including, without limitation, any endorsement or approval of BEP or other third party.
        5.       Liability
        5.1.    Disclaimers
        5.1.1. The use of the Portal and receipt of the Payouts is at your own risk. The Portal, and any related information, content and/or materials are provided on an “as is” basis without warranties of any kind. BEP hereby disclaims all warranties, either express or implied, including but not limited to: warranties of title or implied warranties of merchantability; fitness for a particular purpose; non-infringement; and those arising by statute or otherwise in law or from a course of dealing or usage of trade; other than those warranties which are imposed by and incapable of exclusion, restriction, or modification under the laws applicable to this agreement. The disclaimers of warranties and limitations of liability will apply to the maximum extent permitted by applicable law.
        5.1.2. Without limiting the generality of the foregoing, you agree that BEP does not warrant or represent that:
        (i)   the operation of the Portal and the Payout Methods will be uninterrupted or error free, or that defects will be corrected;
        (ii)   the Portal, or the servers that make the Portal or Payout Methods available, are free of viruses or other harmful components; and/or,
        (iii)  the use or the results of the use of the Portal or the Payouts Methods will be correct, accurate, timely, or otherwise reliable.
        5.1.3. We make reasonable attempts to exclude viruses from the Portal but cannot ensure that the Portal will be at all times free from viruses or other destructive software.
        5.1.4. You further agree, without limiting the generality of the foregoing, that BEP shall not be responsible or liable to you for:
        (i)   any actions or inactions of BEP including but not limited to any dispute as to any amounts paid or payable to you or the failure of BEP to pay you;
        (ii)  the Payout Methods for which you receive or intend to receive the Payouts from BEP regardless whether it is received via the Portal;
        (iii)  unauthorized access to or alteration of your transmissions or data, any material or data sent or received or not sent or received, or any transactions entered into through the Portal or in connection with the Payout Methods;
        (iv) any loss due to payments to unintended users due to the input by you of incorrect information or for payments in incorrect amounts;
        (v)  any service interruptions, including, but not limited to, system failures, power outages, or other interruptions that may affect processing, settlement, acceptance or disbursement in connection with the Portal or Payout Methods;
        (vi) any actions or inactions of any third party, including but not limited to those of any Network Partner or Provider; and/or,
        (vii) the inability to perform our obligations because of factors beyond our control.
        5.2.    Limitation
        5.2.1. In no event shall BEP, including the Affiliates, agents, subcontractors, representatives, network partners or Provider be liable to you or any other party and you hereby waive any right you may have to sue any of the foregoing entities, for any loss or injury or any damages, either direct, indirect, punitive, special, incidental, consequential or otherwise (including, but not limited to lost profits or lost savings) resulting from or in any way connected to: (a) your use of the Portal or receipt of the Payouts through any of the Payout Methods; (b) any error, failure, or delay of, including, but not limited to the use of or inability to use any functionality or any component of the Portal, for any reason; (c) the performance or non-performance by BEP or its agents, subcontractors or network partners, BEP, third-party service provider products or services, or linked sites; (d) any unauthorized or fraudulent transactions affecting you; or, (e) arising out of any third-party service provider’s use of shared information, even if such party has been advised of the possibility of damages arising out of any of the foregoing. This limitation of liability shall apply regardless of whether the claim asserted is based on contract, tort, negligence, strict liability or otherwise.
        5.2.2. In no event shall BEP, its Affiliates, directors, officers or employees, or Provider, be liable for any act or omission of any third party including, without limitation, any of BEP’s agents, subcontractors, representatives, suppliers or network partners, any other users of the Portal, third-party service providers, providers of a linked site, or for any circumstance beyond the reasonable control of BEP.
        5.2.3. In no event shall the total liability of BEP (inclusive of all Affiliates, agents, subcontractors, representatives and network partners) to you for all damages in any one or more cause(s) of action exceed the lesser of the actual direct damages incurred by you and the amount of fees paid by you for the affected services giving rise to your claim or cause of action for damages.
        5.2.4. Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages. Accordingly, some of the above limitations may be limited by law in your jurisdiction to the extent that such laws apply to the Terms and Conditions. To the extent the applicable law governing the Terms and Conditions as applied to you limit or prohibit any of the limitations in the Terms and Conditions, then to the extent of such limitation or prohibition, the total liability of BEP (inclusive of all Affiliates, agents, subcontractors, representatives and network partners) to you will be to complete the performance of the affected transaction which was unable to be performed for you due to the non-performance of the services by BEP.
        5.2.5. You hereby waive any and all rights to bring any claim or action related to the Portal beyond one (1) year after the first occurrence of the kind of act, event, condition or omission upon which such claim or action is based.
        5.2.6. Nothing in this Agreement excludes our liability for liability on our part that cannot be excluded by applicable law. The parties acknowledge that the other party entered into this Agreement in reliance on the limitations of liability stated in this section, and these limitations are an essential basis of the bargain between the parties.
        5.3. Indemnity
        5.3.1. You agree to indemnify and hold BEP, the Affiliates, its shareholders, subsidiaries, directors, officers and employees, harmless from any loss, claim, demand, or damage (actual and consequential) of every kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, including all third-party claims, liability, losses, expenses, damages, fines, penalties (including damages awarded or settled amounts and reasonable attorney’s fees), arising out of or in connection with your breach of these Terms and Conditions, your relationship or dealings with Provider,  use of the Portal or receipt of the Payouts through any of the Payout Methods.
        5.3.2. You agree to indemnify BEP for all losses arising from us acting on your instruction or that of your Provider (unless these arise due to our gross negligence or willful intent or fraud).
        5.3.3. You agree to indemnify us from any losses we may incur resulting from any error made by you in providing information or instructions to BEP whether verbally or in writing, through the Portal (unless these arise due to our gross negligence or willful default or fraud) or in connection with the Payout Methods.
        5.3.4. All notices to BEP must be sent by postal mail to the Legal Notice Mailing address for the Affiliate associated with your country of location as set forth on the Affiliates and Governing Law Schedule.
        5.4.    Arbitration
        5.4.1. The following Agreement to Arbitrate provisions apply to you if you are located in the United States and where the Payout Methods in question are governed under the laws of the United States.
        5.4.2. This Agreement to Arbitrate contains provisions that govern the resolution of legal claims arising between you and us, which will require you to submit claims you have against us to binding and final arbitration, unless you opt out of the agreement to arbitrate within 30 days after the date you accept the Terms and Conditions for the first time.
        5.4.3. You hereby consent to arbitration of all claims before a single arbitrator. The arbitrator will be selected, and the arbitration conducted pursuant to the commercial arbitration rules and mediation procedures of the American arbitration association (“AAA”). No “class” or similar group arbitration shall be permitted. All arbitration hearings or similar proceedings shall be held in Boston, Massachusetts, although you may elect telephonic proceedings or waive any hearing.
        5.4.4. The AAA commercial arbitration rules and mediation procedures are available for review at: https://www.adr.org/aaa/faces/rules (click rules, then click commercial arbitration rules and mediation procedures). By agreeing to be bound by the Terms and Conditions, you either (a) acknowledge and agree that you have read and understand these rules or (b) waive your opportunity to read these rules and any claim that these rules are unfair or should not apply for any reason.
        5.4.5. Any arbitral award shall be final and binding and may be enforced by any court of competent jurisdiction.
        5.4.6. You understand that, in return for your agreement to this section, we are able to offer you the service at the terms designated, and that your assent to this section is an indispensable consideration to this agreement.
        5.4.7. You also acknowledge and understand that, with respect to any claim:
        (i)   You are giving up your right to have a trial by jury and trial by judge;
        (ii)  You are giving up your right to have a court resolve any such dispute; and
        (iii)  You are giving up your right to serve as a representative, as a private attorney general, or in any other representative capacity, or to participate as a member of a class of claimants, in any arbitration or lawsuit involving any such dispute.
        5.4.8. Unless you opt out: (1) you will only be permitted to pursue claims against BEP on an individual basis, not as a plaintiff or class member in any class or representative action or proceeding, and (2) you will only be permitted to seek relief (including monetary, injunctive, and declaratory relief) on an individual basis.
        5.4.9. If a dispute arises between you and BEP, our goal is to investigate and address your concerns and, if we are unable to do so to your satisfaction, to provide you with a neutral and cost-effective means of resolving the dispute quickly. Disputes between you and BEP regarding the Payout Methods and/or Payouts may be reported by contacting us and as further set forth in the dispute resolution provision above.
        5.4.10. You and BEP each agree that any and all disputes or claims that have arisen or may arise between you and BEP, including without limitation federal and state statutory claims, common law claims, and those based in contract, tort, fraud, misrepresentation or any other legal theory, shall be resolved exclusively through final and binding arbitration, rather than in court, except that you may assert claims in small claims court, if your claims qualify and so long as the matter remains in such court and advances only on an individual (non-class, non-representative) basis. This Agreement to Arbitrate is intended to be broadly interpreted.
        5.4.11. You and BEP agree that each of us may bring claims against the other only on an individual basis and not as a plaintiff or class member in any purported class or representative action or proceeding. Unless both you and BEP agree otherwise, the arbitrator(s) may not consolidate or join more than one person’s or party’s claims and may not otherwise preside over any form of a consolidated, representative or class proceeding. Also, the arbitrator(s) may award relief (including monetary, injunctive and declaratory relief) only in favor of the individual party seeking relief and only to the extent necessary to provide relief necessitated by that party’s individual claim(s). Any relief awarded cannot affect other BEP customers.
        5.4.12. You can choose to reject this Agreement to Arbitrate by mailing us a signed, written opt-out notice which includes your name, address, phone number, and the email address(es) used to log in to the Portal to which the opt-out applies. The opt-out notice must be postmarked no later than 30 days after the date you accept the Terms and Conditions for the first time. You must mail the opt-out notice to Buyers Edge Platform, LLC, Attention: Legal Department, 307 Waverley Oaks Rd., Suite 401, Waltham, MA 02452. This Section is made pursuant to a transaction involving interstate commerce and will be governed and enforced by the Federal Arbitration Act, 9 U.S.C.1-16.
        Termination
        6.1.    Suspension or Termination of Payout Methods
        BEP or Provider may at any time suspend or terminate all or any portion of the Payout Methods, including access to the Portal, for any reason whatsoever as determined by BEP and/or at the instruction of BEP, each in their sole discretion. Upon such event, your access to and/or use of the Portal and receipt of the Payouts will immediately be suspended or terminated, as the case may be.
        6.2.    Closing your Portal
        6.2.1. You may close your Portal at any time by contacting BEP and/or Provider. Upon your Portal closure, we will cancel any pending transactions and you will have a limited period of time (as determined by BEP) to transfer any remaining funds during which time your Portal will be accessible for the purpose of transferring any remaining funds or balance to your Payout Method. You acknowledge that if you choose to close your Portal, you will not be able to receive any further Payouts through our Services unless your Portal is re-opened, or you complete the registration process for a new Portal.
        6.2.2. You may not close your Portal to evade an investigation. If you attempt to close your Portal while we are conducting an investigation, we may hold any funds or balance for up to 180 Days to protect BEP, the Affiliates, Provider, any Network Partner, or a third party against the risk of reversals, chargebacks, claims, fees, fines, penalties and other liability. You agree to cooperate in any investigation of any suspected unlawful, fraudulent or improper activity and will remain liable for all obligations related to your Portal or the Payouts even after the Portal is closed.
        6.3.    Escheatment
        If you do not access your Portal and your Portal or the Payouts which you receive via your Portal is deemed unclaimed or abandoned under applicable law, BEP may close your Portal and any remaining funds reflected in your Portal will be retained by BEP. 
        7.       Miscellaneous
        Jurisdiction
        7.2.1. The Terms and Conditions will be governed by and construed in accordance with the governing law corresponding to the Affiliate providing that portion of the Payout Methods to you as set forth on the Affiliates and Governing Law Schedule, without giving effect to any principles of conflict of laws. Where the laws of the United States apply, all claims and disputes arising under or relating to the Terms and Conditions are to be settled by binding arbitration and such other terms and conditions as set forth in the Agreement to Arbitrate above. Where the laws of Canada apply, arising under or relating to the Terms and Conditions shall be submitted to and be subject to the jurisdiction of the courts of the Province of British Columbia and you hereby submit and attorn to the exclusive jurisdiction of the courts of the Province of British Columbia to finally adjudicate or determine any suit, action or proceeding arising out of or in connection with the Payout Methods, Payouts and the Portal.
        7.2.2. To the extent permitted by applicable law and as set forth in the agreement to arbitrate provision applicable to the Terms and Conditions, the parties hereby unconditionally waive their respective rights to a jury trial, or class action of any claim or cause of action based upon or arising out of, directly or indirectly, the Terms and Conditions, any of the related documents, and/or dealings between them relating to the subject matter of the terms of service.
        7.3.    Third-Party Beneficiaries
        You acknowledge and agree that: (i) you are not a third-party beneficiary of any contract between BEP and any third party, and (ii) if you are receiving Payouts from BEP that BEP is solely responsible for paying you and any dispute related to BEP’s failure to pay you shall be solely between you and BEP. Notwithstanding anything to the contrary herein, Provider and its affiliates are intended third-party beneficiaries of these Terms and Conditions, are entitled to the rights and benefits hereunder and may enforce the provisions hereof as if they were parties hereto.
        7.4.    Waiver
        Our failure to act with respect to a breach of any of your obligations under the Terms and Conditions by you or others does not waive our right to act with respect to subsequent or similar breaches.
        7.5.    Assignment
        You shall not assign, novate, or otherwise transfer any rights or obligations you have under the Terms and Conditions without the prior written consent of BEP. We may assign and/or otherwise novate any right or obligation under the Terms and Conditions without your consent.
        7.6.    Validity
        If any part of the Terms and Conditions is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, the remainder of the Terms and Conditions shall continue in full force and effect. Unless otherwise specified herein, the Terms and Conditions constitute the entire agreement between you and BEP with respect to the Payout Methods, Payouts and the Portal and supersede all prior or contemporaneous communications and proposals, whether electronic, oral or written, between you and BEP with respect to the Payout Methods, Payouts and the Portal. The paragraph titles in the Terms and Conditions are solely used for the convenience of the parties and have no legal or contractual significance. It is the express will of the parties that the Terms and Conditions and all related documents have been drawn up in English. We may make the Terms and Conditions available in a language other than English in our sole discretion. If you are reading the Terms and Conditions in another language, the English text shall prevail in the event of any discrepancy in terms among and between the other language(s).
    `;
}
