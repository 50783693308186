import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import '../../SignUp.scss';
import GridCol from '../../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../../MasterComponents/Grid/GridRow';
import Button from '../../../.././MasterComponents/Button/Button';
import InputLabel from '../../../../MasterComponents/InputLabel/InputLabel';
import FormPorchDoorStepOneInformation from './FormPorchDoorStepOneInformation';

class FormPorchDoorStep1 extends Component {
    constructor(props) {
        super(props);
        this.state={
            notValidMessage: 'Required Field Missing',
            validFields: true
        }
    };
    isValid = () =>{
        let result = false;
        const { SignUpStepOneInformation } = this.refs;
        if (SignUpStepOneInformation.isValid()) {
            result = true;
        }
        return result;
        
    };
    setError = (msg) => {
        this.setState({
            notValidMessage: msg
        });
    };
    invalidEmail = () => {
        const { SignUpStepOneInformation } = this.refs;
        SignUpStepOneInformation.invalidEmail();
    };
    invalidConfirmEmail = () => {
        const { SignUpStepOneInformation } = this.refs;
        SignUpStepOneInformation.invalidConfirmEmail();
    };
    backLocation = () =>{
        this.props.onBackClick(); 
    };
    nextLocation = () =>{
        const result = this.isValid();
        this.setState({validFields:result}, () =>{
            if(!result){
                return;           
            }else{
                this.props.onNextClick();
            }
        });
    };
    skipLocation = (location) =>{
        this.props.onSkipClick(location);
    };
    render() {
        const { props, state } = this;
        const {validFields, notValidMessage} = state;

        return (
            <GridCol>
                <FormPorchDoorStepOneInformation 
                    ref='SignUpStepOneInformation'
                    setError={this.setError}
                    isPorchDoor
                    numberLocations={props.numberLocations} 
                    updateNumberLocations={props.updateNumberLocations} 
                    changeHandlerInputs={props.changeHandlerInputs} 
                    step1Inputs={props.step1Inputs} 
                    selectTitle={props.selectTitle}
                    {...this.props} 
                    daSalesRepFromApi={props.daSalesRepFromApi} 
                    selectDASalesRep={props.selectDASalesRep}
                    mergedDaSalesRepReferredFromApi={props.mergedDaSalesRepReferredFromApi} 
                    referredPartnerFromApi={props.referredPartnerFromApi}
                    selectReferredBy = {props.selectReferredBy}
                    selectStateContactInformation={props.selectStateContactInformation}
                    validateEmailFromApi={props.validateEmailFromApi} 
                    validateRepeatedEmail={props.validateRepeatedEmail} 
                    />
                    <GridRow className='line-form'>
                        <GridCol className={(isMobile ?'':'col-4') + ' form-group'}>
                            <Button type='button' variant='blue be-blue-bg be-blue-border' size='' text='NEXT'  onClick={this.nextLocation}/>
                        </GridCol>
                        <GridCol className={(isMobile ?'':'col-4') + ' form-group d-flex align-items-center'}>
                            <InputLabel className='text-red text-size-14'>{validFields ? '' : notValidMessage}</InputLabel>
                        </GridCol>
                    </GridRow>
                    {props.stateLocation === 1 ? null : 
                    <GridRow className='line-form'>
                        <GridCol className={(isMobile ?'':'col-4') + ' form-group d-flex align-items-center'}>
                            <InputLabel className='text-red text-size-14'>{validFields ? '' : notValidMessage}</InputLabel>
                        </GridCol>                    
                    </GridRow>
                    }
            </GridCol>
        )
    };
}

export default FormPorchDoorStep1;