import React from 'react';
import GridRow from '../../MasterComponents/Grid/GridRow';
import GridCol from '../../MasterComponents/Grid/GridCol';
import IconTwitter from '../../MasterComponents/Icons/IconTwiter';
import IconFacebook from '../../MasterComponents/Icons/IconFacebook'
import IconLinkedin from '../../MasterComponents/Icons/IconLinkedin'
import IconInstagram from '../../MasterComponents/Icons/IconInstagram'
import { isMobile } from 'react-device-detect';
import LogoDashboardFooterCompany from '../../MasterComponents/Logos/LogoDashboardFooterCompany';
import { getFooterCopyrightColor, getFooterSocialIconColor, getDashboardFooterLogoStyle } from '../../config/Company/CompanyHelper';


const DashboardFooter = props => {
  const { facebook, twitter, linkedIn, instagram, copyright, companyId } = props;
  const footerSocialIconColor = getFooterSocialIconColor(companyId);
  const footerCopyrightColor = getFooterCopyrightColor(companyId);
  const dashboardFooterLogoStyle = getDashboardFooterLogoStyle(companyId);
  let l = 0;
  if (facebook) { l++ }
  if (twitter) { l++ }
  if (linkedIn) { l++ }
  if (instagram) { l++ }
  if (isMobile) {
    return (
      <GridRow id='dashboard-footer' align='center' className="pt-4 pb-4 mb-5 border-top">
        <GridRow align='center' className="pb-4 px-4">
          <GridCol className='col-3 text-left no-p'>
            <LogoDashboardFooterCompany style={dashboardFooterLogoStyle} />
          </GridCol>
        </GridRow>
        <GridRow align='center' className="pb-4 px-4">
          <GridCol className='col-3 text-center p-0 text-nowrap' align='center'>
          </GridCol>
        </GridRow>
        <GridRow align='center' className="pb-4 bar w-100 px-5">
          {
            facebook &&
            <GridCol className="clickable text-center" onClick={() => window.open(facebook, "_blank")}>
              <IconFacebook size={20} color={footerSocialIconColor} />
            </GridCol>
          }
          {
            twitter &&
            <GridCol className="clickable text-center" onClick={() => window.open(twitter, "_blank")}>
              <IconTwitter size={20} color={footerSocialIconColor} />
            </GridCol>
          }
          {
            linkedIn &&
            <GridCol className="clickable text-center" onClick={() => window.open(linkedIn, "_blank")}>
              <IconLinkedin size={20} color={footerSocialIconColor} />
            </GridCol>
          }
          {
            instagram &&
            <GridCol className="clickable text-center" onClick={() => window.open(instagram, "_blank")}>
              <IconInstagram size={20} color={footerSocialIconColor} />
            </GridCol>
          }
        </GridRow>
        <GridRow className="pt-4 px-4">
          <GridCol className='no-p copyright mobile' style={{ color: footerCopyrightColor }}>
            &copy; {props.copyright}
          </GridCol>
        </GridRow>
      </GridRow>
    )
  }
  else {
    return (
      <GridRow id='dashboard-footer' align='center' className="p-4 border-top">
        <GridRow align='center' className="pb-5 bar">
          <GridCol className='col-3 text-left no-p'>
            <LogoDashboardFooterCompany style={dashboardFooterLogoStyle} />
          </GridCol>
          <GridCol className='col-3 text-right no-p' maxWidth={`${52 * l}px`}>
            <GridRow align='center' className="">
              {
                facebook &&
                <GridCol className="clickable xdxd" onClick={() => window.open(facebook, "_blank")}>
                  <IconFacebook size={28} color={footerSocialIconColor} />
                </GridCol>
              }
              {
                twitter &&
                <GridCol className="clickable" onClick={() => window.open(twitter, "_blank")}>
                  <IconTwitter size={28} color={footerSocialIconColor} />
                </GridCol>
              }
              {
                linkedIn &&
                <GridCol className="clickable" onClick={() => window.open(linkedIn, "_blank")}>
                  <IconLinkedin size={28} color={footerSocialIconColor} />
                </GridCol>
              }
              {
                instagram &&
                <GridCol className="clickable" onClick={() => window.open(instagram, "_blank")}>
                  <IconInstagram size={28} color={footerSocialIconColor} />
                </GridCol>
              }
            </GridRow>
          </GridCol>
        </GridRow>
        <GridRow className="pt-4 bar">
          <GridCol className='no-p copyright' style={{ color: footerCopyrightColor }}>
            &copy; {copyright}
          </GridCol>
        </GridRow>
      </GridRow>
    )
  }
};

export default DashboardFooter;