import React, { Component, Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import IconCircle from '../../MasterComponents/Icons/IconCircle';
import GridRow from '../../MasterComponents/Grid/GridRow';
import GridCol from '../../MasterComponents/Grid/GridCol';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import config from '../../config/config';
import  './Insights.scss';


class InsightsBodyCategories extends Component {

    showMoreCategories = () => {
        this.props.showMoreCategories();
    }

    showLessCategories = () => {
        this.props.showLessCategories();
    }

    render() {
        const { dataCategories, tabData } = this.props;
        const dataCategoriesArray = dataCategories.dataCategoriesTop.map((c, i) => (          
             <GridRow key={i} className={'py-2 ' + (isMobile ? '': '')}>
                <GridCol className='col-1 '><IconCircle color={c.color} size={8} /></GridCol>
                <GridCol className={'text-capitalize insights-graph-text ' + (isMobile ? 'col-6' : 'col-7')}>{c.description}</GridCol>
                <GridCol className='col-4 text-right insights-graph-text'>${(c.y).toLocaleString('en', {minimumFractionDigits: 2})}</GridCol>
             </GridRow> 
        ));

        const chartOptions = {
            chart: {
                height: '90%',
            },
            credits: {
                enabled: false
            },
            title: {
                text: ''
            },
            series: [{
                type: 'pie',
                name: 'Categories',
                innerSize: '0%',
                showInLegend: false,
                data: dataCategories.dataCategoriesTop
            }],
            tooltip: {
                valuePrefix: '$',
                backgroundColor: '#1A1A1A',
                borderColor: '#1A1A1A',
                borderRadius: 5,
                valueDecimals: 2,
                style: {
                    color: 'white'
                },
                pointFormat: ' {point.y} ({point.percentage:.0f}%)</span>',
                formatter: function () {
                    return this.key !== 'Hide' ? ' $'+this.y.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : false;
                }
            },
            plotOptions: {
                pie: {
                    shadow: false,
                    center: ['50%', '50%'],
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: false,
                }
            }
        }

        if (isMobile) {
            return (
                <Fragment>
                    <GridCol>
                        <GridRow>
                            <GridCol className='pt-3 text-center insights-graph-title'>
                                Categories
                            </GridCol>
                        </GridRow>
                        <GridRow>
                            <GridCol>
                                <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                            </GridCol>
                        </GridRow>
                        <GridRow className='text-center mb-3'>
                            <GridCol>
                                <IconCircle color={tabData.isSelectedItems ? config.colors.rbBlue : config.colors.rbLightGrey} size={8} /> &nbsp;
                                <IconCircle color={tabData.isSelectedManufacturers ? config.colors.rbBlue : config.colors.rbLightGrey} size={8} /> &nbsp;
                                <IconCircle color={tabData.isSelectedDistributors ? config.colors.rbBlue : config.colors.rbLightGrey} size={8} />  &nbsp;
                                <IconCircle color={tabData.isSelectedCategories ? config.colors.rbBlue : config.colors.rbLightGrey} size={8} /> 
                            </GridCol>
                        </GridRow>
                        <GridRow>
                            <GridCol>
                                {dataCategoriesArray}
                            </GridCol>
                        </GridRow>
                        <GridRow className='py-3'>
                            { !dataCategories.allCategories && dataCategories.dataCategoriesTop.length > 0 ?
                                <GridCol className='text-left insights-show-more clickable' onClick={this.showMoreCategories}>
                                    Show more <IoIosArrowDown color={config.colors.primary} fontSize='18px'/>
                                </GridCol>
                                : <GridCol/> 
                            }
                            { dataCategories.dataCategoriesTop.length > dataCategories.initNumberCategories ?
                                <GridCol className='text-right insights-show-more clickable' onClick={this.showLessCategories}>
                                    Show less <IoIosArrowUp color={config.colors.primary} fontSize='18px'/>
                                </GridCol>
                                : <GridCol/> 
                            }
                        </GridRow>
                    </GridCol>
                </Fragment>
            )  
        }

        return (
            <Fragment>
                <GridCol className='py-3'>
                    <GridRow>
                        <GridCol className='pl-5 pb-3 text-left insights-graph-title insights-line-bottom'>
                            Categories
                        </GridCol>
                    </GridRow>
                    <GridRow className='pt-4 pr-4'>
                        <GridCol className='col-5' style={{width: '60px', minWidth: '30%'}}>
                            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                        </GridCol>
                        <GridCol className='col-7 text-left px-0'>
                            <GridCol className='scrollbar-insights' style={{maxHeight: '280px', overflowX:'hidden', overflowY:'auto'}}>
                                {dataCategoriesArray}
                            </GridCol>
                            <GridRow className='p-3'>
                                { !dataCategories.allCategories && dataCategories.dataCategoriesTop.length > 0 ?
                                    <GridCol className='col-6 text-left insights-show-more clickable' onClick={this.showMoreCategories}>
                                        Show more <IoIosArrowDown color={config.colors.primary} fontSize='18px'/>
                                    </GridCol>
                                    : <GridCol/> 
                                }
                                { dataCategories.dataCategoriesTop.length > dataCategories.initNumberCategories ?
                                    <GridCol className='col-6 insights-show-more text-right clickable' onClick={this.showLessCategories}>
                                        Show less <IoIosArrowUp color={config.colors.primary} fontSize='18px'/>
                                    </GridCol>
                                    : <GridCol/>
                                }
                            </GridRow>
                        </GridCol>
                    </GridRow>
                </GridCol>
            </Fragment>
        );
    }
}

export default InsightsBodyCategories;
