import React from 'react';
import PropTypes from 'prop-types';

const LogoDashboardCompany = props => (
  <img id={props.id} className={props.className}
    style={props.style}
    src={ process.env.REACT_APP_COMPANY_DASHBOARD_LOGO }
    alt={`${process.env.REACT_APP_COMPANY_NICKNAME} dashboard logo`} />
);

LogoDashboardCompany.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string
}

export default LogoDashboardCompany;