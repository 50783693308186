import React from 'react';
import { isMobile } from 'react-device-detect';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import Input from '../../../MasterComponents/Input/Input';
import InputLabel from '../../../MasterComponents/InputLabel/InputLabel';

const PasswordsForm = ({password, repeatPassword, isIframe}) => {
  return (
    <>
      <GridRow className='mt-3'>
        <GridCol size={(isMobile || isIframe) ? 12 : 6}>
          <InputLabel text='Create Password *' />
          <Input placeholder='Password' name='password' {...password} />
        </GridCol>
      </GridRow>
      <GridRow className='mt-3'>
        <GridCol size={(isMobile || isIframe) ? 12 : 6}>
          <InputLabel text='Confirm Password *' />
          <Input placeholder='Repeat Password' name='repeatPassword' {...repeatPassword} />
        </GridCol>
      </GridRow>
    </>
  );
};

export default PasswordsForm;