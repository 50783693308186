import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoginSidebar from './LoginSidebar';
import InputLabel from '../../MasterComponents/InputLabel/InputLabel';
import { isMobile } from 'react-device-detect';
import GridRow from '../../MasterComponents/Grid/GridRow';
import GridCol from '../../MasterComponents/Grid/GridCol';
import GridContainer from '../../MasterComponents/Grid/GridContainer';
import { spinner } from '../../MasterComponents/Spinner/SpinnerActions';
import Button from '../../MasterComponents/Button/Button';
import setToken from '../../config/tokenApi';
import { withMsal } from '@azure/msal-react';
import { loginRequest } from '../../config/msAuthConfig';
import myApi from './LoginApi';

class LoginMicrosoft extends Component {
  logout = (e) => {
      e.preventDefault();
      this.props.history.push('/logout');
  }
  loginPopup = () => {
      const msalInstance = this.props.msalContext.instance;
      msalInstance.loginPopup(loginRequest).then(res => {
          console.log('res', res);
          const params = {
              accessToken: res.accessToken,
              tenantId: res.tenantId,
              tokenType: res.tokenType,
              name: res.account && res.account.name,
              email: res.account && res.account.username,
              localEmail: 'admin@admin.com',
          };
          const action = this.props.dispatch;
          action(spinner(true));
          myApi.authenticateMicrosoft(params)
              .then(({ data })=> {
                  action(spinner(false));
                  let result = data.data;
                  const activeMerchant = JSON.stringify(result.activeMerchant);
                  if (result.id) {
                      localStorage.setItem('userid', result.id);
                      localStorage.setItem('contactName', result.contactName);
                      localStorage.setItem('username', result.username);
                      localStorage.setItem('email', result.email);
                      localStorage.setItem('phoneNumber', result.phoneNumber);
                      localStorage.setItem('title', result.title);
                      localStorage.setItem('isLoggedIn', true);
                      localStorage.setItem('authKey', result.jwt);
                      localStorage.setItem('activeMerchant', activeMerchant);
                      localStorage.setItem('isFoodbamActive', result.isFoodbamActive);
                      localStorage.setItem('status', result.status);
                      localStorage.setItem('showInsideTrackModal', false);
                      setToken(result.jwt);
                      if (result.isAdmin) {
                          localStorage.setItem('authKeyAdmin', result.authKey);
                          if (result.isAnalyst) {
                              localStorage.setItem('authKeyAnalyst', result.authKey);
                          }
                          this.props.history.push('/admin-login');
                      }
                  }
              }).catch(error => {
                  if (error) {
                      let errorMessage = error.data.data.message;
                      alert(errorMessage);
                  }
                  action(spinner(false));
              });
      });
  }
  render() {
    return (
      <div className='container-login'>
        <LoginSidebar history={this.props.history}/>
        <div className='right-login'>
          <div className='header-login'>
            {isMobile ? <InputLabel text='Microsoft Login' title className='title' /> : null}
          </div>
          <GridContainer className='login-admin p-0'>
            { isMobile ? null :
            <GridRow className='mb-5'>
              <GridCol>
                <InputLabel text='Microsoft Login' title className='title' />
              </GridCol>
            </GridRow>
            }
            <GridRow>
              <GridCol className='mt-5'>
                <Button type='submit' variant='white' text='Logout' onClick={this.logout} />
              </GridCol>
              <GridCol className='mt-5'>
                <Button type='submit' variant='primary' text='Login with Microsoft' onClick={this.loginPopup} />
              </GridCol>
            </GridRow>
          </GridContainer>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {};
}

export default connect(mapStateToProps)(withMsal(LoginMicrosoft));
