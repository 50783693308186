import React, { Component } from 'react'
import { connect } from 'react-redux';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import Modal from '../../../MasterComponents/Modals/Modal';
import ModalHeader from '../../../MasterComponents/Modals/ModalHeader';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import ModalFooter from '../../../MasterComponents/Modals/ModalFooter';
import Button from '../../../MasterComponents/Button/Button';
import LogoHC from '../../../MasterComponents/Logos/LogoHashicorp';
import LogoVA from '../../../MasterComponents/Logos/LogoVault';
import Input from '../../../MasterComponents/Input/Input';
import InputLabel from '../../../MasterComponents/InputLabel/InputLabel';
import Checkbox from '../../../MasterComponents/Checkbox/Checkbox';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { findModal, showModal } from '../../../MasterComponents/Modals/ModalsActions';
import TermsConditionsModal from './TermsConditionsModal';
import termsPDF from '../../../assets/files/terms-conditions.pdf'
import IconInformation from '../../../MasterComponents/Icons/IconInformation'
import config from '../../../config/config';
import $ from 'jquery';
import { IoIosRefresh } from 'react-icons/io'
import VerifyRespondModal from './VerifyRespondModal';
import LoadingModal from './LoadingModal';
import NotificationApi from '../../Notification/NotificationApi';
import { notificationSetCounter, notificationSetData } from '../../Notification/Reducer/NotificationAction';
import AutomateMyProofPurchasesModal from './AutomateMyProofPurchasesModal';

class EnterCredentialsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userCredential: this.props.username ? this.props.username.toString().trim() : '',
      passCredential: this.props.password ? this.props.password.toString().trim() : '',
      passRepeatCredential: '',
      isCheckTermsConditions : this.props.isCheckTermsConditions !== undefined ? this.props.isCheckTermsConditions : false,
      loading: false
    }
  }
  componentDidMount = () => {
    const { username, password, isSignUp } = this.props;
    if (isSignUp) {
      this.setState({
        userCredential: username,
        passCredential: password
      });
    }
    $('[data-toggle="tooltipEnterCredentials"]').tooltip();
  }
  changeHandler = (value, name, e) => {
    this.setState({
      [name]: value
    });
  };

  checkTermsConditionsHandler = () => {
    const { isCheckTermsConditions } = this.state
    this.setState({
      isCheckTermsConditions: !isCheckTermsConditions
    });
  };

  openTermsConditions = () => {
    const action = this.props.dispatch;
    if (isMobile) {
      let link = document.createElement('a');
      link.href = termsPDF;
      link.download = 'terms-conditions.pdf';
      link.dispatchEvent(new MouseEvent('click'));
    } else {
      action(showModal(TermsConditionsModal, 'termsConditionsModal'));
    }
  }

  focusValidPasswords = () => {
    const { passInput, passRepeatInput } = this.refs;
    passRepeatInput.setValid();
    passInput.setValid();
  };
  save = () => {
    const { saveCredentials, modals, isEdit, merchantDistributor } = this.props;
    const { userCredential, passCredential, passRepeatCredential, isCheckTermsConditions} = this.state;
    const { userInput, passInput, passRepeatInput } = this.refs;
    if (userInput.isValid() && passInput.isValid() && isCheckTermsConditions) {
      if (passRepeatCredential !== passCredential) {
        passInput.setInvalid();
        passRepeatInput.setInvalid();
      } else {
        if (isEdit) {
          saveCredentials(merchantDistributor.id, userCredential, passCredential, isCheckTermsConditions);
        } else {
          saveCredentials(merchantDistributor.id, userCredential, passCredential, isCheckTermsConditions);
        }
        findModal(modals, 'enterCredentialsModal').close();
      }
    }
  }
  deleteNotification = (merDistID) => {
    const { notifications, dispatch } = this.props;
    // Find a notification that matches
    if (notifications.some(not => not.merchantDistributor.id === merDistID)) {
      const notId = notifications.filter(not => not.merchantDistributor.id === merDistID)[0].id;
      NotificationApi.deleteNotification(notId).then(() => {
        NotificationApi.getNotifications().then(({data}) => {
          const noti = data.data;
          const countNotRead = noti.filter(n => n.isRead === 0).length;
          dispatch(notificationSetData(noti));
          dispatch(notificationSetCounter(countNotRead));
        }).catch(err => {
          console.error(err);
        });
      }).catch(err => {
        console.error(err);
      });
    }
  };
  verify = () => {
    const { index, isEdit, saveCredentials, modals, verifyCredentials, dispatch, merchantDistributor, isSignUp, dcns } = this.props;
    const { userCredential, passCredential, passRepeatCredential, isCheckTermsConditions} = this.state;
    const { userInput, passInput, passRepeatInput } = this.refs;
    
    if (userInput.isValid() && passInput.isValid() && isCheckTermsConditions) {
      if (passRepeatCredential !== passCredential) {
        passInput.setInvalid();
        passRepeatInput.setInvalid();
      } else {
        this.setState({
          loading: true
        });
        dispatch(showModal(LoadingModal, 'loadingModal'));
        if (isEdit) {
          const { merchantId, distributorId } = this.props.merchantDistributor;
          verifyCredentials(distributorId, merchantId, userCredential, passCredential, dcns, (data) => {
            this.setState({
              loading: false
            });
            findModal(this.props.modals, 'loadingModal').close();
            if (data.code === 200) {
              if (isSignUp) {
                this.deleteNotification(merchantDistributor.id);
                saveCredentials(merchantDistributor.id, userCredential, passCredential);
                findModal(modals, 'enterCredentialsModal').close();
                dispatch(showModal(VerifyRespondModal, 'verifyRespondModal', {message: "Credentials Verified", button: "Ok"}));
              } else {
                dispatch(showModal(AutomateMyProofPurchasesModal, 'automateMyProofPurchasesModal', {
                  onClick: () => {
                    this.deleteNotification(merchantDistributor.id);
                    saveCredentials(merchantDistributor.id, userCredential, passCredential);
                    findModal(modals, 'enterCredentialsModal').close();
                    dispatch(showModal(VerifyRespondModal, 'verifyRespondModal', {message: "Credentials Verified", button: "Ok"}));
                  }
                }));
              }
            } else {
              if (data.data.message === 'Pending Verification') {
                if (isSignUp) {
                  this.deleteNotification(merchantDistributor.id);
                  saveCredentials(merchantDistributor.id, userCredential, passCredential);
                  findModal(modals, 'enterCredentialsModal').close();
                  dispatch(showModal(VerifyRespondModal, 'verifyRespondModal', {message: "Thank you! We cannot verify your credentials now, but we will reach out if there are any issues", button: "Ok"}));
                } else {
                  dispatch(showModal(AutomateMyProofPurchasesModal, 'automateMyProofPurchasesModal', {
                    onClick: () => {
                      this.deleteNotification(merchantDistributor.id);
                      saveCredentials(merchantDistributor.id, userCredential, passCredential);
                      findModal(modals, 'enterCredentialsModal').close();
                      dispatch(showModal(VerifyRespondModal, 'verifyRespondModal', {message: "Thank you! We cannot verify your credentials now, but we will reach out if there are any issues", button: "Ok"}));
                    }
                  }));
                }
              } else {
                dispatch(showModal(VerifyRespondModal, 'verifyRespondModal', {message: "Sorry, those credentials are incorrect", button: "Try Again"}));
              }
            }
          });
        } else {
          verifyCredentials(userCredential, passCredential, dcns, (data) => {
            this.setState({
              loading: false
            });
            findModal(this.props.modals, 'loadingModal').close();
            if (data.code === 200) {
              if (isSignUp) {
                saveCredentials(index, userCredential, passCredential, isCheckTermsConditions);
                findModal(modals, 'enterCredentialsModal').close();
                dispatch(showModal(VerifyRespondModal, 'verifyRespondModal', {message: "Credentials Verified", button: "Ok"}));
              } else {
                dispatch(showModal(AutomateMyProofPurchasesModal, 'automateMyProofPurchasesModal', {
                  onClick: () => {
                    saveCredentials(index, userCredential, passCredential, isCheckTermsConditions);
                    findModal(modals, 'enterCredentialsModal').close();
                    dispatch(showModal(VerifyRespondModal, 'verifyRespondModal', {message: "Credentials Verified", button: "Ok"}));
                  }
                }));
              }
            } else {
              if (data.data.message === 'Pending Verification') {
                if (isSignUp) {
                  saveCredentials(index, userCredential, passCredential, isCheckTermsConditions);
                  findModal(modals, 'enterCredentialsModal').close();
                  dispatch(showModal(VerifyRespondModal, 'verifyRespondModal', {message: "Thank you! We cannot verify your credentials now, but we will reach out if there are any issues", button: "Ok"}));
                } else {
                  dispatch(showModal(AutomateMyProofPurchasesModal, 'automateMyProofPurchasesModal', {
                    onClick: () => {
                      saveCredentials(index, userCredential, passCredential, isCheckTermsConditions);
                      findModal(modals, 'enterCredentialsModal').close();
                      dispatch(showModal(VerifyRespondModal, 'verifyRespondModal', {message: "Thank you! We cannot verify your credentials now, but we will reach out if there are any issues", button: "Ok"}));
                    }
                  }));
                }
              } else {
                dispatch(showModal(VerifyRespondModal, 'verifyRespondModal', {message: "Sorry, those credentials are incorrect", button: "Try Again"}));
              }
            }
          });
        }
      }
    }
  }
  cancel = () => {
    const { modals } = this.props;
    findModal(modals, 'enterCredentialsModal').close();
  }

  render() {
    const { userCredential, passCredential, passRepeatCredential, isCheckTermsConditions, loading } = this.state;
    const stylesTerms = {
      position: 'relative',
      left: isMobileOnly ? '20px' : '-10px'
    }

    if (isMobile) {
      return (
        <Modal id='enterCredentialsModal'>
          <ModalHeader className='text-size-16' closeButton={false}>Please Connect your Distributors &nbsp;<span className='clickable' data-toggle='tooltipEnterCredentials' data-placement='bottom' title='Username and Password to be able to access your distributor'>  <IconInformation color={config.colors.primary} size={13} /></span></ModalHeader>
          <ModalBody>
            <GridRow className='mt-3'>
              <GridCol>
                <InputLabel text='Username *' />
                <Input ref='userInput' value={userCredential} validate min={3} max={100} name='userCredential' placeholder='Username' onChange={this.changeHandler} autocomplete='off' />
              </GridCol>
            </GridRow>
            <GridRow className='mt-3'>
              <GridCol>
                <InputLabel text='Password *' />
                <Input ref='passInput' onFocus={this.focusValidPasswords} value={passCredential} validate min={3} max={100} name='passCredential' placeholder='Password' onChange={this.changeHandler} type='password' autocomplete='off'/>
              </GridCol>
            </GridRow>
            <GridRow className='mt-3'>
              <GridCol>
                <InputLabel text='Password *' />
                <Input ref='passRepeatInput' onFocus={this.focusValidPasswords} value={passRepeatCredential} validate min={3} max={100} name='passRepeatCredential' placeholder='Repeat Password' onChange={this.changeHandler} type='password' autocomplete='off'/>
              </GridCol>
            </GridRow>
            <GridRow className='mt-3'>
              <GridCol size={1}>
                <Checkbox name='isCheckTermsConditions' value={isCheckTermsConditions} text='' onChange={this.checkTermsConditionsHandler} />
              </GridCol>
              <GridCol size={10} className='d-flex align-items-center px-0'>
                <span className='termsConditions' style={stylesTerms}>I agree with <i className='clickable termsConditionsBlue' onClick={this.openTermsConditions}> Terms & Conditions</i>  </span>
              </GridCol>
            </GridRow>
            <GridRow className='mt-3'>
              <GridCol size={6}>
                <Button variant='white' onClick={this.cancel} className='w-100 clickable' size='lg' text={'Cancel'} />
              </GridCol>
              <GridCol size={6}>
                <Button disabled={ loading || !isCheckTermsConditions } variant='primary' onClick={this.verify} className='w-100 clickable' size='lg' text={!loading ? 'Verify' : null} icon={loading ? <IoIosRefresh color='#fff' rotate={true} /> : null} />
              </GridCol>
            </GridRow>
            <GridRow className='mt-3 no-gutters' align='center'>
              <GridCol size={4} className='text-size-14 text-grey text-left'>Powered By</GridCol>
              <GridCol size={4} className='text-center'><LogoHC /></GridCol>
              <GridCol size={4} className='text-right'><LogoVA /></GridCol>
            </GridRow>
          </ModalBody>
        </Modal>
      );
    }
    return (
      <Modal size='sm' id='enterCredentialsModal'>
        <ModalHeader closeButton={false}>Please Connect your Distributors &nbsp;<span className='clickable' data-toggle='tooltipEnterCredentials' data-placement='bottom' title='Username and Password to be able to access your distributor'>  <IconInformation color={config.colors.primary} size={13} /></span></ModalHeader>
        <ModalBody>
          <form autoComplete='new-password'>
            <InputLabel text='Username *' />
            <Input ref='userInput' value={userCredential} validate min={3} max={100} name='userCredential' placeholder='Username' onChange={this.changeHandler} className='mb-4' autocomplete='off' />
            <InputLabel text='Password *' />
            <Input ref='passInput' onFocus={this.focusValidPasswords} value={passCredential} validate min={3} max={100} name='passCredential' placeholder='Password' onChange={this.changeHandler} type='password' className='mb-2' autocomplete='off'/>
            <InputLabel text='Repeat Password *' />
            <Input ref='passRepeatInput' onFocus={this.focusValidPasswords} value={passRepeatCredential} validate min={3} max={100} name='passRepeatCredential' placeholder='Repeat Password' onChange={this.changeHandler} type='password' autocomplete='off'/>
            <GridRow>
              <GridCol size={1}>
                <Checkbox name='isCheckTermsConditions' value={isCheckTermsConditions} text='' onChange={this.checkTermsConditionsHandler} />
              </GridCol>
              <GridCol size={10} className='d-flex align-items-center'>
                <span className='termsConditions'>I agree with <i className='clickable termsConditionsBlue' onClick={this.openTermsConditions}> Terms & Conditions</i>  </span>
              </GridCol>
            </GridRow>
          </form>
        </ModalBody>
        <ModalFooter className='flex-column'>
          <GridRow className='w-100'>
            <GridCol className='pl-0' size={6}>
              <Button variant='white' onClick={this.cancel} className='w-100 clickable' size='lg' text={'Cancel'} />
            </GridCol>
            <GridCol className='pr-0' size={6}>
              <Button disabled={ loading || !isCheckTermsConditions } variant='primary' onClick={this.verify} className='w-100 clickable' size='lg' text={!loading ? 'Verify' : null} icon={loading ? <IoIosRefresh color='#fff' rotate={true} /> : null} />
            </GridCol>
          </GridRow>
        </ModalFooter>
        <ModalFooter className='pt-0'>
          <GridRow className='no-gutters w-100' align='center'>
            <GridCol size={4} className='text-size-14 text-grey text-left'>Powered By</GridCol>
            <GridCol size={4} className='text-center'><LogoHC /></GridCol>
            <GridCol size={4} className='text-right'><LogoVA /></GridCol>
          </GridRow>
        </ModalFooter>
      </Modal>
    );
  }
};

const mapStateToProps = state => {
  return {
    notifications: state.notification.notificationList
  };
}

export default connect(mapStateToProps)(EnterCredentialsModal);
