import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import Modal from '../../../MasterComponents/Modals/Modal';
import ModalHeader from '../../../MasterComponents/Modals/ModalHeader';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import Button from '../../../MasterComponents/Button/Button';
import { findModal } from '../../../MasterComponents/Modals/ModalsActions';

class VerifyRespondModal extends Component {
  ok = () => {
    const { modals } = this.props;
    findModal(modals, 'verifyRespondModal').close();
  };
  render() {
    return (
      <Modal id='verifyRespondModal'>
        <ModalHeader size='sm' closeButton={false} >
          {this.props.message}
        </ModalHeader>
        <ModalBody>
          <GridRow>
            <GridCol>
              <Button onClick={this.ok} variant='primary' text={this.props.button} />
            </GridCol>
          </GridRow>
        </ModalBody>
      </Modal>
    );
  }
};

Modal.propTypes = {
  message: PropTypes.string,
  button: PropTypes.string
};

Modal.defaultProps = {
  button: 'Ok'
};

const mapStateToProps = state => {
  return {};
}

export default connect(mapStateToProps)(VerifyRespondModal);