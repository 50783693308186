import React, { Component } from 'react';
import Modal from '../../../MasterComponents/Modals/Modal';
import DatePicker from 'react-datepicker';
import ModalHeader from '../../../MasterComponents/Modals/ModalHeader';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import { isMobile } from 'react-device-detect';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import InputLabel from '../../../MasterComponents/InputLabel/InputLabel';
import  '../Insights.scss';
import Button from '../../../MasterComponents/Button/Button';
import {  findModal } from '../../../MasterComponents/Modals/ModalsActions';

class InsightsDateRangeModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
          startDate: new Date(),
          endDate: new Date()
        }
      }

    changeDate = (name, date) => {
        this.setState({
            [name]: date
        });
    }

    saveInsightsDateRange = () => {
        const { modals } = this.props;
        const { startDate, endDate } = this.state;
        this.props.saveInsightsDateRange(startDate, endDate);
        findModal(modals, 'idInsightsDateRangeModal').close();
    }

    render() {
        const { startDate, endDate } = this.state;
        return (
          <Modal  id='idInsightsDateRangeModal' noBorder={isMobile}>
                <ModalHeader>Date Range</ModalHeader>
                <ModalBody>
                    <GridRow>
                        <GridCol>
                            <InputLabel>Start Date</InputLabel>
                        </GridCol>
                    </GridRow>                    
                    <div className={isMobile ? 'customDatePickerAlign' : ''}>
                        { isMobile ?
                            <DatePicker name='startDate' className='rb-input w-100' locale='en'  popperPlacement="bottom-end" selected={startDate} dateFormat="MM/dd/yyyy" maxDate={new Date()} onChange={this.changeDate.bind(this, 'startDate')} withPortal />
                        :
                            <DatePicker name='startDate' className='rb-input w-100' locale='en'  popperPlacement="bottom-end" selected={startDate} dateFormat="MM/dd/yyyy" maxDate={new Date()} onChange={this.changeDate.bind(this, 'startDate')} />
                        }
                    </div>
                    <GridRow>
                        <GridCol>
                            <InputLabel>End Date</InputLabel>
                        </GridCol>
                    </GridRow>                    
                    <div className={isMobile ? 'customDatePickerAlign' : ''}>
                        { isMobile ?
                            <DatePicker name='endDate' className='rb-input w-100' locale='en' popperPlacement="bottom-start" selected={endDate} dateFormat="MM/dd/yyyy" minDate={startDate} maxDate={new Date()} onChange={this.changeDate.bind(this, 'endDate')} withPortal />
                        :
                            <DatePicker name='endDate' className='rb-input w-100' locale='en' popperPlacement="bottom-start" selected={endDate} dateFormat="MM/dd/yyyy" minDate={startDate} maxDate={new Date()} onChange={this.changeDate.bind(this, 'endDate')} />
                        }
                    </div>
                    <GridRow className='mt-4'>
                        <Button type='button' variant='primary' size='' text='SAVE' onClick={this.saveInsightsDateRange}></Button>
                    </GridRow>
                </ModalBody>
            </Modal>
        );
    }
}

export default InsightsDateRangeModal;