import React, { Component } from 'react';
import { connect } from 'react-redux';
// import config from '../../../config/config';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../MasterComponents/Grid/GridRow';
// import IconArrowRight from '../../../MasterComponents/Icons/IconArrowRight';
// import IconGraph from '../../../MasterComponents/Icons/IconGraph';
import { round } from '../../../utils/utils';
class ItemIntelDetailsInfo extends Component {

  getPriceRange(priceRange, unitMeasure) {
    if (!priceRange || priceRange.length === 0) return '';
    if (priceRange[0] === null || priceRange[1] === null) return '';

    let minPrice, maxPrice, perUnitMeasure = '';
    minPrice = `$${round(priceRange[0], 2).toFixed(2)}`;
    maxPrice = `$${round(priceRange[1], 2).toFixed(2)}`;
    if (unitMeasure) perUnitMeasure = `/ per ${unitMeasure.charAt(0).toUpperCase() + unitMeasure.slice(1)}`;
    return `${minPrice} - ${maxPrice} ${perUnitMeasure}`;
  }

  render() {
    const { description, manufacturer, brand, min,
      // region, months, deviation,
      priceRange, unitMeasure, packSize, rebate, rebateType,
    } = this.props.itemDetails;
    const displayIntelPricing = JSON.parse(localStorage.getItem('activeMerchant')).displayIntelPricing || 0;
    return (
      <>
        <GridRow className='pb-3 pt-2 details-info-title text-capitalize'>
          {description && description.toLowerCase()}
        </GridRow>
        {/* <GridRow className='py-2 px-0'>
          <GridCol className='px-0 text-grey'>Region:</GridCol>
          <GridCol className='px-0'>{region && `${region}`}</GridCol>
        </GridRow> */}
        <GridRow className='py-2 px-0'>
          <GridCol className='px-0 text-grey'>Pack Size:</GridCol>
          <GridCol className='px-0'>{packSize}</GridCol>
        </GridRow>
        <GridRow className='py-2 px-0'>
          <GridCol className='px-0 text-grey'>Manufacturer:</GridCol>
          <GridCol className='px-0'>{manufacturer}</GridCol>
        </GridRow>
        <GridRow className='py-2 px-0'>
          <GridCol className='px-0 text-grey'>Brand:</GridCol>
          <GridCol className='px-0'>{brand}</GridCol>
        </GridRow>
        <GridRow className='py-2 px-0'>
          <GridCol className='px-0 text-grey'>MIN:</GridCol>
          <GridCol className='px-0'>{min}</GridCol>
        </GridRow>
        {/* <GridRow className='py-2 px-0'>
          <GridCol className='px-0 text-grey'>3-month Trend:</GridCol>
          <GridCol className='px-0'>{months && `${months}%`}</GridCol>
        </GridRow> */}
        {displayIntelPricing === 1 &&
          <GridRow className='py-2 px-0'>
            <GridCol className='px-0 text-grey'>Current Price Range:</GridCol>
            <GridCol className='px-0'>{this.getPriceRange(priceRange, unitMeasure)}</GridCol>
          </GridRow>
        }
        <GridRow className='py-2 px-0'>
          <GridCol className='px-0 text-grey'>Rebate:</GridCol>
          <GridCol className='px-0'>
            {rebate > 0 && (
              rebateType && rebateType.toLowerCase().includes('percent of price') ?
                `${round((rebate * 100), 2)}%` :
                `$${round(rebate, 2)}`
            )}
          </GridCol>
        </GridRow>
        <GridRow className='py-2 px-0'>
          <GridCol className='px-0 text-grey'>Rebate Type:</GridCol>
          <GridCol className='px-0'>{rebateType}</GridCol>
        </GridRow>
        {/* <GridRow className='py-2 px-0'>
          <GridCol className='px-0 text-grey'>Deviation:</GridCol>
          <GridCol className='px-0'>{deviation > 0 && `$${deviation}`}</GridCol>
        </GridRow> */}

        {/* <GridRow className='pt-3'>
          <GridCol>
            <GridRow className='py-2 pr-3 details-section-title' justify='between' align='center'>
              Milk Substitutes
              <IconArrowRight size={12} color={config.colors.rbBlack} />
            </GridRow>
            <GridRow className='py-2' align='center'>
              Butter
              <div className='clickable'>&nbsp; <IconGraph color={config.colors.rbBlue} /></div>
            </GridRow>
            <GridRow className='py-2'>Cheese</GridRow>
            <GridRow className='py-2'>Dairy - Whipped Cream</GridRow>
            <GridRow className='py-2' align='center'>
              Eggs
              <div className='clickable'>&nbsp; <IconGraph color={config.colors.rbBlue} /></div>
            </GridRow>
            <GridRow className='py-2'>Milk</GridRow>
            <GridRow className='py-2'>Sour Cream</GridRow>
            <GridRow className='py-2'>Yogurt</GridRow>
          </GridCol>
          <GridCol>
            <GridRow className='py-2 info-section-title'>Almond Milk</GridRow>
            <GridRow className='py-2'>Rice Milk</GridRow>
            <GridRow className='py-2' align='center'>
              Soy Milk
              <div className='clickable'>&nbsp; <IconGraph color={config.colors.rbBlue} /></div>
            </GridRow>
          </GridCol>
        </GridRow> */}

      </>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    itemDetails: state.itemIntel.itemDetails,
  };
};

export default connect(mapStateToProps)(ItemIntelDetailsInfo);