import React from 'react';
import { isMobile } from 'react-device-detect';
import Button from '../../../../MasterComponents/Button/Button';
import GridCol from '../../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../../MasterComponents/Grid/GridRow';
import useDistributorIndex from '../../hooks/useDistributorIndex';
import DistributorStepIndexContent from './DistributorStepIndexContent';

const DistributorStepIndex = (props) => {
  const { history, distributorsProps } = props;
  const distributorIndexProps = useDistributorIndex(props);

  const onSave = () => {
    distributorIndexProps.onSave(() => {
      history.replace('/onboarding/2');
    });
  };

  const onCancel = () => {
    history.replace('/onboarding/2');
  };

  if (isMobile) {
    return (
      <div className='vh-100'>
        <div className='container flex-grow-1' style={{paddingTop: '88px', paddingBottom: '100px'}}>
          <DistributorStepIndexContent distributorsProps={distributorsProps} {...distributorIndexProps} />
        </div>
        <GridRow className='m-0 py-3 bg-white w-100 position-absolute' style={{bottom: 0}}>
          <GridCol size={12}>
            <GridRow>
              <GridCol>
                <Button variant='yellow' text='SAVE' onClick={onSave} />
              </GridCol>
            </GridRow>
          </GridCol>
        </GridRow>
      </div>
    )
  }

  return (
    <div className='container' style={{paddingTop: '30px', paddingBottom: '30px'}}>
      <DistributorStepIndexContent distributorsProps={distributorsProps} {...distributorIndexProps} />
      <GridRow className='py-3'>
        <GridCol size={6}>
          <GridRow>
            <GridCol size={4}>
              <Button variant='yellow-outline' text='CANCEL' onClick={onCancel} />
            </GridCol>
            <GridCol size={4}>
              <Button variant='yellow' text='SAVE' onClick={onSave} />
            </GridCol>
          </GridRow>
        </GridCol>
      </GridRow>
    </div>
  );
};

export default DistributorStepIndex;