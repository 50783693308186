import React from 'react';
import './Dashboard.scss';
import GridContainer from '../../MasterComponents/Grid/GridContainer';
import GridRow from '../../MasterComponents/Grid/GridRow';
import DashboardTopbar from './DashboardTopbar';
import DashboardContent from './DashboardContent';
import { isMobile } from 'react-device-detect';
import DashboardTopbarLogo from './DashboardTopbarLogo';
import HiddenFieldList from '../../MasterComponents/HiddenField/HiddenFieldList';
import DashboardSidebar from './DashboardSidebar';
import { useDispatch, useSelector } from 'react-redux';
import { showSidebar } from '../../MasterComponents/Sidebar/reducer/SidebarAction';

const Dashboard = (props) => {
  const activeMerchant = useSelector(state => state.dashboard.activeMerchant);
  const userCurrent = useSelector(state => state.location.userCurrent);
  const dispatch = useDispatch();
  const { history } = props;
  const isOrderly = history.location.pathname.toLowerCase() === '/dashboard/foodcost/orderly';

  const displayInsideTrack = activeMerchant && activeMerchant.hasInsidetrackBiDashboard === 1 && userCurrent && userCurrent.insidetrackId > 0;
  // const isInsideTrack = displayInsideTrack && history.location.pathname.toLowerCase() === '/dashboard/insights';

  const hide2ndTopbar = isOrderly;

  const openSidebar = () => {
    dispatch(showSidebar(DashboardSidebar, {dispatch, history}));
  };

  const add = window.innerWidth < 1350 ? 56 : 0;

  const marginTop = (isMobile || hide2ndTopbar) ? `${55 + add}px` : `${115+add}px`;
  
  return (
    <GridContainer isFluid={true} className='d-flex flex-column vh-100'>
      { !isMobile &&
        <GridRow id='dashboard-topbar-top' className='fixed-top text-white'>
          <DashboardTopbarLogo history={history} pathname={history.location.pathname} hamburgerMenu={hide2ndTopbar} openSidebar={openSidebar} />
        </GridRow>
      }
      {
        !hide2ndTopbar &&
        <GridRow>
          <DashboardTopbar history={history} pathname={history.location.pathname}/>
        </GridRow>
      }
      <GridRow className='h-100' style={{marginTop: marginTop, marginBottom: isMobile ? '50px' : ''}}>
        <DashboardContent isOrderly={isOrderly} {...props} displayInsideTrack={displayInsideTrack} />
      </GridRow>
      <HiddenFieldList />
    </GridContainer>
  )
};
export default Dashboard;
