import React, { Component, Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import InsightBodyManufacturersTab from './InsightsBodyManufacturersTab';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import IconCircle from '../../MasterComponents/Icons/IconCircle';
import GridRow from '../../MasterComponents/Grid/GridRow';
import GridCol from '../../MasterComponents/Grid/GridCol';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import config from '../../config/config';
import  './Insights.scss';

class InsightsBodyManufacturersDeviation extends Component {

    showMoreManufacturers = () => { 
        this.props.showMoreManufacturers('deviation');
    }

    showLessManufacturers = () => {
        this.props.showLessManufacturers('deviation');
    }

    onTabManufacturer = (data) => {
        this.props.onTabManufacturer(data);
    }

    render() {
        const { dataManufacturersDeviation,  tabData, tabManufacturers } = this.props;
        const propsTabs = {
            tabManufacturers,
            onTabManufacturer: this.onTabManufacturer
        }
        const dataManufacturersArray = dataManufacturersDeviation.dataManufacturersTop.map((c, i) => (          
             <GridRow key={i} className='mt-2'>
                <GridCol className='col-1 '><IconCircle color={c.color} size={12} /></GridCol>
                <GridCol className={'text-capitalize insights-graph-text ' + (isMobile ? 'col-6' : 'col-7')}>{c.description}</GridCol>
                <GridCol className='col-4 text-right insights-graph-text'>${(c.amount).toLocaleString('en', {minimumFractionDigits: 2})}</GridCol>
             </GridRow> 
        ));


        const chartOptionsDeviation = {
            chart: {
                height: '90%',
            },
            credits: {
                enabled: false
            },
            title: {
                text: ''
            },
            series: [{
                type: 'pie',
                name: 'Manufacturers Deviation',
                innerSize: '0%',
                showInLegend: false,
                data: dataManufacturersDeviation.dataManufacturersTop
            }],
            tooltip: {
                valuePrefix: '$',
                backgroundColor: '#1A1A1A',
                borderColor: '#1A1A1A',
                borderRadius: 5,
                valueDecimals: 2,
                style: {
                    color: 'white'
                },
                pointFormat: ' {point.y} ({point.percentage:.0f}%)</span>',
                formatter: function () {
                    return this.key !== 'Hide' ? ' $'+this.y.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : false;
                }
            },
            plotOptions: {
                pie: {
                    shadow: false,
                    center: ['50%', '50%'],
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: false,
                }
            }
        }

        if (isMobile) {
            return (
                <Fragment>
                    <GridCol>
                        <InsightBodyManufacturersTab {...propsTabs} />
                        <GridRow>
                            <GridCol>
                                <HighchartsReact highcharts={Highcharts} options={chartOptionsDeviation} />
                            </GridCol>
                        </GridRow>
                        <GridRow className='text-center mb-3'>
                            <GridCol>
                                <IconCircle color={tabData.isSelectedItems ? config.colors.rbBlue : config.colors.rbLightGrey} size={12} /> &nbsp;
                                <IconCircle color={tabData.isSelectedManufacturers ? config.colors.rbBlue : config.colors.rbLightGrey} size={12} /> &nbsp;
                                <IconCircle color={tabData.isSelectedDistributors ? config.colors.rbBlue : config.colors.rbLightGrey} size={12} /> &nbsp;
                                <IconCircle color={tabData.isSelectedCategories ? config.colors.rbBlue : config.colors.rbLightGrey} size={12} />
                            </GridCol>
                        </GridRow>
                        <GridRow>
                            <GridCol>
                                {dataManufacturersArray}
                            </GridCol>
                        </GridRow>
                        <GridRow className='py-3'>
                            { !dataManufacturersDeviation.allManufacturers &&  dataManufacturersDeviation.dataManufacturersTop.length > 0 ?
                                <GridCol className='text-left insights-show-more clickable' onClick={this.showMoreManufacturers}>
                                    Show more <IoIosArrowDown color={config.colors.primary} fontSize='18px'/>
                                </GridCol>
                                : <GridCol/> 
                            }
                            { dataManufacturersDeviation.dataManufacturersTop.length > dataManufacturersDeviation.initNumberManufacturers ?
                                <GridCol className='text-right insights-show-more clickable' onClick={this.showLessManufacturers}>
                                    Show less <IoIosArrowUp color={config.colors.primary} fontSize='18px'/>
                                </GridCol>
                                : <GridCol/> 
                            }
                        </GridRow>
                    </GridCol>
                </Fragment>
            )  
        }

        return (
            <Fragment>
                <GridCol className='py-3'>
                    <InsightBodyManufacturersTab {...propsTabs} />
                    <GridRow className='pt-4 pr-4'>
                        <GridCol className='col-5' style={{width: '60px', minWidth: '30%'}}>
                            <HighchartsReact highcharts={Highcharts} options={chartOptionsDeviation} />
                        </GridCol>
                        <GridCol className='col-7 mt-1 text-left px-0'>
                            <GridCol className='scrollbar-insights' style={{maxHeight: '250px', overflowX:'hidden', overflowY:'auto'}}>
                                {dataManufacturersArray}
                            </GridCol>
                            <GridRow className='p-3'>
                                { !dataManufacturersDeviation.allManufacturers &&  dataManufacturersDeviation.dataManufacturersTop.length > 0 ?
                                    <GridCol className='text-left insights-show-more clickable' onClick={this.showMoreManufacturers}>
                                        Show more <IoIosArrowDown color={config.colors.primary} fontSize='18px'/>
                                    </GridCol>
                                    : <GridCol/> 
                                }
                                { dataManufacturersDeviation.dataManufacturersTop.length > dataManufacturersDeviation.initNumberManufacturers ?
                                    <GridCol className='text-right insights-show-more clickable pr-4' onClick={this.showLessManufacturers}>
                                        Show less <IoIosArrowUp color={config.colors.primary} fontSize='18px'/>
                                    </GridCol>
                                    : <GridCol/>
                                }
                            </GridRow>
                        </GridCol>
                    </GridRow>
                </GridCol>
            </Fragment>
        );
    }
}

export default InsightsBodyManufacturersDeviation;
