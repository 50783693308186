import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import './Bullet.scss';
import config from '../../config/config';
import IconCheck from '../Icons/IconCheck';
import PropTypes from 'prop-types';


const Bullet = props => (
    <div className='line-items'>
        <div className='bullet-circle-container'>
            <div className={((props.big) ? 'bullet-circle-big' : 'bullet-circle') + (props.className ? ` ${props.className}` : '')} style={props.isFoodServiceRewards ? {backgroundColor: '#6BA068'} : null}>
                <IconCheck size={(props.big) ? 30:10} color={config.colors.rbWhite} />
            </div>
        </div>
        <div className='line-text w-90'>
            {ReactHtmlParser(props.text)}
        </div>
    </div>
);


Bullet.propTypes = {
    big: PropTypes.bool
}

Bullet.defaultProps = {
    big: false
}

export default Bullet;