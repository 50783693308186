import React from 'react';
import Bullet from '../../MasterComponents/Bullet/Bullet';
import LogoMainCompany from '../../MasterComponents/Logos/LogoMainCompany';

const ChangePasswordSidebar = props => {
  const goTo = (e) => {
    e.preventDefault();
    props.history.push('/');
  };
  return (
    <div className='left-change'>
      <div className='logo'>
        <a href='/' onClick={goTo}><LogoMainCompany style={{ maxWidth: '250px', maxHeight: '150px' }} /></a>
      </div>
      <div className='bullets'>
        <Bullet text='Collect more cash back' />
        <Bullet text='Get access to Beyond Broadline Programs' />
        <Bullet text='See your restaurant insights' />
      </div>
    </div>
  )
}

export default ChangePasswordSidebar;