import React, { Component } from 'react';
import '../SignUp.scss';
import { connect } from 'react-redux';
import SignUpWhitelabelSidebar from './SignUpWhitelabelSidebar';
import SignUpWhitelabelNewTypeContent from './SignUpWhitelabelNewTypeContent';
import { setRegisterFormType, setSegment, setSubsegment } from '../../../config/Company/CompanyActions';
import queryString from 'query-string';

class SignUpWhitelabelNewType extends Component {
    componentDidMount() {
        const { location, dispatch } = this.props;
        const { search } = location;
        const parsed = queryString.parse(search);
        if (parsed.segment) {
          dispatch(setSegment(parsed.segment));
        }
        if (parsed.subsegment) {
          dispatch(setSubsegment(parsed.subsegment));
        }

        this.isJustOne();
    };
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.links !== this.props.links) {
            this.isJustOne();
        }
    };
    isJustOne = () => {
        const { links } = this.props;
        if (links.filter(link => link.description !== 'register-canada').length === 1) {
            this.go(links[0]);
        }
    };
    go = (l) => {
        const { history, dispatch } = this.props;
        if (l.isExternalDomain) {
          window.location = `${l.domain}${l.route}${history.location.search}`;
        } else {
          dispatch(setRegisterFormType(l.formType));
          history.replace(`${l.route}${history.location.search}`);
        }
    };
    render() {
        const { history, companyName, links, dispatch } = this.props;
        const sidebarProps = {
            history,
            companyName
        };
        const contentProps = {
            history,
            links,
            title: 'Tell us about your business:',
            companyName,
            dispatch
        };
        return (
            <div className='container-signup'>
                <SignUpWhitelabelSidebar {...sidebarProps} />
                <SignUpWhitelabelNewTypeContent {...contentProps} />
            </div>
        )
    };
}

const mapStateToProps = state => {
    return {
        companyId: state.company.id,
        companyName: state.company.nickname,
        links: state.company.registerLinks
    }
}

export default connect(mapStateToProps)(SignUpWhitelabelNewType);
