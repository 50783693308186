import React, { Component, Fragment } from 'react';
import '../SignUp.scss';
import { isMobile } from 'react-device-detect';
import InputLabel from '../../../MasterComponents/InputLabel/InputLabel';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import Button from '../../../MasterComponents/Button/Button';
import SignUpFormHeader from '../Forms/SignUpFormHeader';
import IconClose from '../../../MasterComponents/Icons/IconClose';

class SignUpWhitelabelNewOrOldContent extends Component {
    goToNewRegister = () => {
        this.props.history.push('/register/new/type')
    };
    goToOldRegister = () => {
        this.props.history.push('/register/old')
    };
    goHome = (e) => {
        e.preventDefault();
        this.props.history.push('/');
    };
    render() {
        const headerProps = {
            activeStep: 0,
            hideSteps: true
        };
        return (
            <div className='right-signup'>
                <div className='header-main'>
                    <div className='header-fixed home-header'>
                        { isMobile &&
                            <Fragment>
                                <GridCol size={11}className='pt-2 pl-5'>
                                    <InputLabel text='Create Account' title className='title' />
                                </GridCol>
                                <GridCol size={1} className='p-0' onClick={this.goHome}>
                                    <IconClose color={'white'} size={18} />
                                </GridCol>
                            </Fragment> 
                        }
                    </div>
                </div>
                <div className='header-signup'>
                    <SignUpFormHeader {...headerProps} />
                </div>
                <div className='signup-form welcome-signup'>
                    <GridCol>
                        <GridRow className='line-form mt-3'>
                            <GridCol size={isMobile ? 12 : 6}>
                                <Button type='button' variant='white' text="I'M AN EXISTING MEMBER"  onClick={this.goToOldRegister}/>
                            </GridCol>
                        </GridRow>
                        <GridRow className='line-form mt-3'>
                            <GridCol size={isMobile ? 12 : 6}>
                                <Button type='button' variant='primary' text="I'M A NEW MEMBER"  onClick={this.goToNewRegister}/>
                            </GridCol>
                        </GridRow>
                    </GridCol>
                </div>
            </div>
        )
    };
}

export default SignUpWhitelabelNewOrOldContent;