import React from 'react';
import useDistributors from './hooks/useDistributors';
import useLocations from './hooks/useLocations';
import { Redirect, Route, Switch } from 'react-router-dom';
import OnboardingLocationStep from './OnboardingLocationStep/OnboardingLocationStep';
import OnboardingDistributorStep from './OnboardingDistributorStep/OnboardingDistributorStep';

const OnboardingContent = (props) => {
  const { setMaxStep, maxStep, setdistributorName } = props;
  const locProps = useLocations(props);
  const distributorsProps = useDistributors({...props, locations: locProps.locations})

  return (
    <Switch>
      <Route exact path='/onboarding/1' render={historyProps => <OnboardingLocationStep {...historyProps} {...locProps} setMaxStep={setMaxStep} />} />
      {
        maxStep > 1 &&
        <Route path='/onboarding/2' render={historyProps => <OnboardingDistributorStep {...historyProps} distributorsProps={distributorsProps} setMaxStep={setMaxStep} setdistributorName={setdistributorName} />} />
      }
      <Route>
        <Redirect to={`/onboarding/${maxStep}`} />
      </Route>
    </Switch>
  );
};

export default OnboardingContent;