import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconUpload = props => (
  <svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Designs" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="24---Activate-Offline-Distributor" transform="translate(-646.000000, -495.000000)" fill={!props.isActive ? props.color : props.activeColor}>
        <g id="pop-up" transform="translate(520.000000, 92.000000)">
          <g id="history" transform="translate(45.000000, 368.000000)">
            <g id="Group" transform="translate(81.000000, 35.000000)">
              <g id="icons/upload">
                <path d="M12,7.0295 L12,4.771 C12,4.642 11.95,4.5175 11.8605,4.4245 L7.7565,0.1535 C7.662,0.0555 7.532,0 7.396,0 L0.5,0 C0.2235,0 0,0.224 0,0.5 L0,15.5 C0,15.776 0.2235,16 0.5,16 L11.5,16 C13.9815,16 16,13.981 16,11.5 C16,9.188 14.2465,7.279 12,7.0295 Z M10.5465,4.5 L7.5,4.5 L7.5,1.33 L10.5465,4.5 Z M1,15 L1,1 L6.5,1 L6.5,5 C6.5,5.276 6.7235,5.5 7,5.5 L11,5.5 L11,7.0295 C8.7535,7.2795 7,9.188 7,11.5 C7,12.913 7.656,14.1745 8.678,15 L1,15 Z M11.5,15 C9.5705,15 8,13.43 8,11.5 C8,9.57 9.5705,8 11.5,8 C13.4295,8 15,9.57 15,11.5 C15,13.43 13.4295,15 11.5,15 Z" id="Shape"></path>
                <path d="M11.8,9.1 C11.792,9.094 11.7825,9.093 11.7745,9.088 C11.757,9.0765 11.74,9.0665 11.7215,9.0575 C11.7005,9.047 11.679,9.0395 11.656,9.0315 C11.6355,9.0245 11.616,9.0175 11.595,9.0135 C11.574,9.0095 11.554,9.0085 11.533,9.007 C11.5215,9.006 11.512,9 11.5,9 C11.488,9 11.4775,9.006 11.4655,9.007 C11.4455,9.0085 11.426,9.0095 11.406,9.013 C11.384,9.017 11.364,9.0245 11.3425,9.0315 C11.32,9.039 11.2985,9.047 11.2775,9.0575 C11.2595,9.0665 11.2425,9.076 11.2255,9.0875 C11.2175,9.093 11.2075,9.094 11.1995,9.1 L9.1995,10.6 C8.9785,10.7655 8.9335,11.079 9.0995,11.3 C9.1975,11.431 9.3475,11.5 9.5,11.5 C9.6045,11.5 9.7095,11.4675 9.7995,11.4 L11,10.5 L11,13.5 C11,13.776 11.2235,14 11.5,14 C11.7765,14 12,13.776 12,13.5 L12,10.5 L13.2,11.4 C13.29,11.4675 13.395,11.5 13.4995,11.5 C13.652,11.5 13.8015,11.431 13.9,11.3 C14.066,11.079 14.021,10.7655 13.8,10.6 L11.8,9.1 Z" id="Path"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

IconUpload.propTypes = {
  color: PropTypes.string,
  activeColor: PropTypes.string,
  isActive: PropTypes.bool,
  size: PropTypes.number,
};

IconUpload.defaultProps = {
  activeColor: config.colors.rbYellow,
  color: config.colors.rbWhite,
  isActive: false,
  size: 16,
};

export default IconUpload;