import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconPieChart = props => (
  <svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="side-menu" transform="translate(-33.000000, -159.000000)" fill={!props.isActive ? props.color : props.activeColor} fillRule="nonzero">
        <g id="menu-item">
          <g transform="translate(33.000000, 159.000000)">
            <g id="icons/graph">
              <path d="M9.1085,0.0045 C8.966,-0.0155 8.8215,0.029 8.713,0.124 C8.6045,0.219 8.542,0.356 8.542,0.5 L8.542,4.049 C8.542,4.277 8.696,4.476 8.9165,4.533 C10.1565,4.855 11.1245,5.8315 11.4425,7.0815 C11.499,7.303 11.6985,7.458 11.927,7.458 L15.5,7.458 C15.644,7.458 15.7815,7.3955 15.876,7.2875 C15.9705,7.1795 16.0145,7.035 15.9955,6.892 C15.5175,3.3145 12.6855,0.4825 9.1085,0.0045 Z M12.2985,6.458 C11.846,5.16 10.8315,4.1375 9.542,3.68 L9.542,1.095 C12.216,1.6785 14.321,3.784 14.905,6.458 L12.2985,6.458 Z" id="Shape"></path>
              <path d="M6.9665,15.921 C7.371,15.975 7.7115,16 8.0375,16 C8.3635,16 8.704,15.975 9.1085,15.921 C10.043,15.796 10.9335,15.5145 11.7555,15.085 C11.883,15.018 11.9755,14.8995 12.0095,14.7595 C12.043,14.6195 12.015,14.4715 11.932,14.3535 L9.882,11.4505 C9.7495,11.263 9.507,11.189 9.294,11.2725 C9.1715,11.3195 9.0455,11.3595 8.917,11.393 C8.3205,11.5475 7.7535,11.5475 7.1585,11.393 C5.9185,11.071 4.9505,10.0945 4.6325,8.8445 C4.5565,8.545 4.518,8.2485 4.518,7.963 C4.518,7.6775 4.5565,7.3805 4.6325,7.0815 C4.764,6.565 5.0115,6.0795 5.3495,5.6765 C5.7315,5.2215 6.2265,4.8675 6.7815,4.6535 C6.904,4.6065 7.03,4.5665 7.159,4.533 C7.379,4.4755 7.533,4.2765 7.533,4.049 L7.533,0.5 C7.533,0.356 7.4705,0.2185 7.3625,0.124 C7.254,0.0295 7.1095,-0.014 6.967,0.0045 C6.0335,0.1295 5.143,0.4105 4.3205,0.8405 C3.693,1.1685 3.105,1.586 2.5725,2.0805 C1.2105,3.3465 0.325,5.055 0.08,6.8915 C0.025,7.2965 0,7.6365 0,7.9625 C0,8.2885 0.025,8.629 0.079,9.0335 C0.557,12.611 3.389,15.443 6.9665,15.921 Z M3.2525,2.813 C3.7195,2.379 4.2345,2.0135 4.783,1.7265 C5.334,1.438 5.9205,1.2265 6.5325,1.094 L6.5325,3.6785 C6.495,3.6915 6.4585,3.7055 6.4215,3.7195 C5.709,3.9945 5.073,4.4485 4.583,5.033 C4.1495,5.55 3.8315,6.1725 3.663,6.834 C3.5665,7.214 3.5175,7.5935 3.5175,7.962 C3.5175,8.3305 3.5665,8.71 3.663,9.09 C4.071,10.6935 5.314,11.946 6.907,12.3595 C7.6665,12.5565 8.407,12.5565 9.1685,12.3595 C9.2055,12.3495 9.2425,12.3395 9.2795,12.329 L10.772,14.4425 C10.203,14.682 9.601,14.845 8.9765,14.9285 C8.257,15.024 7.8185,15.024 7.099,14.9285 C3.9675,14.5105 1.489,12.0315 1.0705,8.9 C1.0225,8.5415 1,8.2435 1,7.9625 C1,7.6815 1.0225,7.3835 1.0705,7.024 C1.285,5.417 2.06,3.922 3.2525,2.813 Z" id="Shape"></path>
              <path d="M15.5,8.4675 L11.927,8.4675 C11.698,8.4675 11.499,8.6225 11.4425,8.844 C11.311,9.3605 11.0635,9.846 10.7255,10.249 C10.5795,10.423 10.569,10.6735 10.7,10.8585 L12.7535,13.7665 C12.837,13.8845 12.967,13.9605 13.11,13.9755 C13.1275,13.977 13.1445,13.978 13.162,13.978 C13.2875,13.978 13.4095,13.9305 13.5025,13.844 C14.865,12.5775 15.75,10.869 15.9955,9.033 C16.0145,8.89 15.971,8.746 15.876,8.6375 C15.781,8.529 15.644,8.4675 15.5,8.4675 Z M13.227,12.703 L11.729,10.582 C11.9675,10.24 12.16,9.8635 12.298,9.4675 L14.9045,9.4675 C14.641,10.6705 14.061,11.79 13.227,12.703 Z" id="Shape"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

IconPieChart.propTypes = {
  color: PropTypes.string,
  activeColor: PropTypes.string,
  isActive: PropTypes.bool,
  size: PropTypes.number,
};

IconPieChart.defaultProps = {
  activeColor: config.colors.rbYellow,
  color: config.colors.rbWhite,
  isActive: false,
  size: 16,
};

export default IconPieChart;