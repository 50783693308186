import React from 'react';
import { Col } from 'reactstrap';
import ContractTableHeader from './ContractTableHeader';
import ContractTableRow from './ContractTableRow';
import moment from 'moment';
import config from '../../../../config/config';

const ContractTable = ({sort, contracts, filters, onUpdate, disabledActions}) => {
	const sortContracts = [...contracts].filter(c => c.status !== null).sort((a, b) => {
		// Date Sort
		const sortThis = sort.startDate !== 0 ? 'startDate' : 'endDate';
		const asc = sort[sortThis] === 1 ? 1 : -1;

		return moment(a[sortThis]).isAfter(moment(b[sortThis])) ? asc : -asc;
	}).sort((a, b) => {
		// Status Sort
		if (sort.status === 0) return 0;

		const asc = sort.status === 1 ? -1 : 1;

		if (a.status === 'Active') return asc;
		if (b.status === 'Active') return -asc;

		if (a.status === 'Expiring') return asc;
		if (b.status === 'Expiring') return -asc;

		if (a.status === 'Expired') return asc;
		if (b.status === 'Expired') return -asc;

		return 0;
	}).sort((a, b) => {
		// Manufacturer Sort
		if (sort.manufacturer === 0) return 0;

		const asc = sort.manufacturer === 1 ? -1 : 1;

		if(a.manufacturer < b.manufacturer) return asc;
		if(a.manufacturer > b.manufacturer) return -asc;
		
		return 0;
	}).filter(c => {
		// Dropdown Filters
		const allChecked = filters.active && filters.expired && filters.expiring;
		const noneChecked = !filters.active && !filters.expired && !filters.expiring;

		if (allChecked || noneChecked) return true;

		return (c.status === 'Active' && filters.active) || (c.status === 'Expiring' && filters.expiring) || (c.status === 'Expired' && filters.expired);
	}).filter(c => {
		// Search Filters
		if (filters.search.value && filters.search.value !== '')
		return c.manufacturer.toString().toLowerCase().search(config.regexEscape(filters.search.value).toLowerCase()) !== -1;

		return true;
	});

	const contractsMap = sortContracts.map((c, i) => (
		<ContractTableRow key={`contract_${i}`} {...c} onUpdate={onUpdate} disabledActions={disabledActions} />
	));

	return (
		<Col className='mb-1 bg-white flex-grow-1 table-container'>
			<ContractTableHeader {...sort} />
			<div className='scrollbar-custom items-list-rows-container'>
				{contractsMap}
			</div>
		</Col>
	);
};

export default ContractTable;