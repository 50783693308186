import Joi from 'joi';
import { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { spinner } from '../../../MasterComponents/Spinner/SpinnerActions';
import DashboardApi from '../../Dashboard/DashboardApi';
import LocationApi from '../../Location/LocationApi';
import SignUpApi from '../../SignUp/SignUpApi';

const reducer = (state, action) => {
  switch(action.type) {
    case 'SET_ERRORS':
      return {
        ...state,
        errors: action.payload
      }
    case 'CHECK_LOCATIONS':
      return {
        ...state,
        locations: state.locations.map(loc => {
          if (loc.index !== action.payload) return loc;
          return {
            ...loc,
            check: !loc.check
          }
        }),
        errors: state.errors.filter(e => e !== 'locations')
      }
    case 'UPDATE_SEARCH':
      return {
        ...state,
        search: action.payload
      };
    case 'UPDATE_LOCATIONS':
      return {
        ...state,
        locations: action.payload.map((loc, i) => ({
          ...loc,
          index: i,
          check: (state.locations[i] && state.locations[i].check) || false
        }))
      };
    case 'SELECT_DISTRIBUTOR':
      return {
        ...state,
        distributor: action.payload,
        distributorHouse: null,
        errors: state.errors.filter(e => e !== 'distributor' && e !== 'distributorHouse')
      };
    case 'SELECT_DISTRIBUTOR_HOUSE':
      return {
        ...state,
        distributorHouse: action.payload,
        errors: state.errors.filter(e => e !== 'distributorHouse')
      };
    case 'RESET':
      return {
        ...state,
        search: '',
        searchHouse: '',
        distributor: null,
        distributorHouse: null,
        submissionType: 'online',
        locations: state.locations.map((loc, i) => ({
          ...loc,
          index: i,
          check: false
        })),
        errors: []
      };
    case 'SET_DISTRIBUTORS_HOUSE':
      return {
        ...state,
        distributorHouses: action.payload
      };
    case 'ADD_DISTRIBUTORS':
      return {
        ...state,
        distributors: [
          ...state.distributors,
          ...action.payload.map(d => ({...d, isPopular: false}))
        ]
      };
    case 'SET_POPULARS':
      return {
        ...state,
        distributors: [
          ...state.distributors.map(dis => {
            if (action.payload.some(popularId => dis.id === popularId)) {
              return {
                ...dis,
                isPopular: true
              }
            }
            return dis;
          })
        ]
      };
    case 'SAVE_DISTRIBUTOR_MERCHANTS':
      return {
        ...state,
        distributorMerchants: action.payload
      };
    default:
      return state;
  }
};

const LIST_INITIAL_STATE = {
  distributors: [],
  distributorHouses: [],
  search: '',
  distributor: null,
  distributorHouse: null,
  locations: [],
  locationsCheck: [],
  distributorMerchants: [],
  errors: []
};

const SCHEMA = Joi.object({
  distributor: Joi.object().required().invalid(null),
  distributorHouse: Joi.object().required().invalid(null),
  locations: Joi.array().min(1).items(Joi.number().integer().required())
});

const useDistributors = ({locations, history}) => {
  const dispatch = useDispatch();
  const [state, dispatchState] = useReducer(reducer, LIST_INITIAL_STATE);

  const companyId = useSelector(({company}) => company.id);
  const displayPopularDistributors = useSelector(({company}) => company.displayPopularDistributors);

  useEffect(() => {
    const getPopularDistributors = () => {
      DashboardApi.getDistributorsFixed().then(({data}) => {
        dispatchState({
          type: 'SET_POPULARS',
          payload: data.data.map(d => d.id)
        });
      });
    };
  
    const getDistributors = (page) => {
      dispatch(spinner(true));
      DashboardApi.getDistributorsAll(companyId, '', page).then(({data, headers}) => {
        dispatch(spinner(false));
        dispatchState({
          type: 'ADD_DISTRIBUTORS',
          payload: data.data
        });
        const curPage = parseInt(headers['x-pagination-current-page']);
        const lastPage = parseInt(headers['x-pagination-page-count']);
        if (curPage < lastPage) {
          page ++;
          getDistributors(curPage+1);
        } else {
          getPopularDistributors();
        }
      }).catch(err => {
        console.error(err);
      });
    };
    getDistributors(1);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatchState({
      type: 'UPDATE_LOCATIONS',
      payload: locations
    });
    //eslint-disable-next-line
  }, [locations]);

  useEffect(() => {
    if (state.distributor) {
      dispatch(spinner(true));
      SignUpApi.getDistributorsHouse(companyId, state.distributor.id).then(({data}) => {
        dispatch(spinner(false));
        dispatchState({
          type: 'SET_DISTRIBUTORS_HOUSE',
          payload: data.data
        });
      }).catch(err => {
        dispatch(spinner(false));
        console.error(err);
      });
    } else {
      dispatchState({
        type: 'SET_DISTRIBUTORS_HOUSE',
        payload: []
      });
    }
  }, [state.distributor]);

  const popularDistributors = state.distributors.filter(dist => dist.isPopular).map(d => ({label: d.nickname, ...d}));
  const allDistributors = state.distributors.map(d => ({label: d.nickname, ...d}));
  const tag = {label: 'Popular Distributors', isDisabled: true, value: 0};

  const dropdown = {
    value: state.distributor,
    search: state.search,
    isInvalid: state.errors.some(e => e === 'distributor'),
    onInputChange: (value) => {
      dispatchState({
        type: 'UPDATE_SEARCH',
        payload: value
      });
    },
    onChange: (value) => {
      dispatchState({
        type: 'SELECT_DISTRIBUTOR',
        payload: value
      });
    },
    options: displayPopularDistributors ? (state.search.length > 0 ? allDistributors : [tag, ...popularDistributors]) : allDistributors
  };

  const allDistributorsFilter = allDistributors.filter(dist => dist.label.toString().toLowerCase().search(state.search.toLowerCase()) >= 0);

  const search = {
    name: 'distributorNameSearch',
    placeholder: 'Ex. Sysco',
    value: state.search,
    onChange: (value) => {
      dispatchState({
        type: 'UPDATE_SEARCH',
        payload: value
      });
    },
    isInvalid: state.errors.some(e => e === 'distributor'),
    displayPopularDistributors: displayPopularDistributors,
    options: displayPopularDistributors ? (state.search.length > 0 ? allDistributorsFilter : popularDistributors) : allDistributorsFilter
  };

  const dropdownHouse = {
    value: state.distributorHouse,
    isInvalid: state.errors.some(e => e === 'distributorHouse'),
    onChange: (value) => {
      dispatchState({
        type: 'SELECT_DISTRIBUTOR_HOUSE',
        payload: value
      });
    },
    isDisabled: state.distributor === null || state.distributorHouses.length === 0,
    options: [{...state.distributor, label: `I don't know`}, ...state.distributorHouses.map(d => ({label: d.nickname, ...d}))]
  };

  const validate = () => {
    const params = {
      distributor: state.distributor,
      distributorHouse: state.distributorHouses.length === 0 ? {} : state.distributorHouse,
      locations: state.locations.filter(loc => loc.check).map(loc => loc.id)
    };
    const validation = SCHEMA.validate(params, {abortEarly: false});
    dispatchState({
      type: 'SET_ERRORS',
      payload: validation.error ? validation.error.details.map(e => e.context.key) : []
    })
    if (validation.error) return false;
    return true;
  };

  const refresh = (openDistId) => {
    dispatch(spinner(true));
    LocationApi.getDistributorAllFilter().then(({data}) => {
      const dists = data.data.distributors;

      LocationApi.getMerchantDistributor().then(({data}) => {
        dispatch(spinner(false));

        const disMers = dists.map(d => ({
          ...d,
          merchantDistributors: data.data.filter(merDist => merDist.distributorId === d.id).map(merDist => ({
            ...merDist,
            dcn: merDist.dcn.toString().replace(/^_|_$/g, ""),
          }))
        })).filter(distMer => distMer.merchantDistributors.length > 0);

        dispatchState({
          type: 'SAVE_DISTRIBUTOR_MERCHANTS',
          payload: disMers
        });

        if (openDistId) {
          const distToOpen = disMers.filter(d => d.id === openDistId)[0];

          if (distToOpen) {
            history.push(`/onboarding/2/edit/${distToOpen.id}`);
          }
        }
      }).catch(err => {
        dispatch(spinner(false));
        console.error(err);
      });
    }).catch(err => {
      dispatch(spinner(false));
      console.error(err);
    });
  };

  return {
    dropdown,
    search,
    dropdownHouse,
    locationsInvalid: state.errors.some(e => e === 'locations'),
    locations: state.locations,
    distributorMerchants: state.distributorMerchants,
    refresh,
    dispatchState,
    validate
  };
};

export default useDistributors;