import React, { Component } from 'react';
import GridRow from '../../MasterComponents/Grid/GridRow';
import GridCol from '../../MasterComponents/Grid/GridCol';
import SavingsCalculator from '../Home/SavingsCalculator';

class RebateCalculator extends Component {
  render() {
    return (
        <div className='home-content bg-blue-home'>
          <GridRow>
            <GridCol>
              <SavingsCalculator history={this.props.history} hideGetStarted />
            </GridCol>
          </GridRow>
        </div>
    );
  };
};

export default RebateCalculator;