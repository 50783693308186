export const spinner = (bool) => {
    return {
        type: 'SET_SPINNER',
        payload: bool
    }
}

export const spinnerImage = (bool, loader) => {
    return {
        type: 'SET_SPINNER_IMAGE',
        payload: bool,
        payloadLoader: loader
    }
}