import React, { Component } from 'react'
import Button from '../../../MasterComponents/Button/Button'
import GridCol from '../../../MasterComponents/Grid/GridCol'
import GridRow from '../../../MasterComponents/Grid/GridRow'
import Modal from '../../../MasterComponents/Modals/Modal'
import ModalBody from '../../../MasterComponents/Modals/ModalBody'
import ModalFooter from '../../../MasterComponents/Modals/ModalFooter'
import ModalHeader from '../../../MasterComponents/Modals/ModalHeader'
import { findModal } from '../../../MasterComponents/Modals/ModalsActions'

export class ServerErrorModal extends Component {

  closeModal() {
    const { modals } = this.props;
    findModal(modals, 'serverErrorModal').close();
  }

  render() {
    return (
      <Modal id='serverErrorModal' >
        <ModalHeader>Server Error</ModalHeader>
        <ModalBody className=''>
          <GridRow className='py-2 text-center'>
            <GridCol>
              <p>Sorry, a server error occurred.</p>
              <p><b>Code:</b><br /> {this.props.status}</p>
              <p><b>Message:</b><br /> {this.props.statusText}</p>
            </GridCol>
          </GridRow>
        </ModalBody>
        <ModalFooter className='text-center'>
          <Button type='button' variant='white' size='sm' text='RELOAD' onClick={() => window.location.reload()}></Button>
          <Button type='button' variant='primary' size='sm' text='OK' onClick={this.closeModal.bind(this)}></Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default ServerErrorModal;
