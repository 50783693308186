import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import GridCol from '../../MasterComponents/Grid/GridCol';
import GridRow from '../../MasterComponents/Grid/GridRow';
import Button from '../../MasterComponents/Button/Button';
import Input from '../../MasterComponents/Input/Input';
import InputLabel from '../../MasterComponents/InputLabel/InputLabel';
import LoginApi from './LoginApi';
import setToken from '../../config/tokenApi';
import { spinner } from '../../MasterComponents/Spinner/SpinnerActions';
import config from '../../config/config';
import IconEye from '../../MasterComponents/Icons/IconEye';
import { getTextLinkColor } from '../../config/Company/CompanyHelper';
import * as Utils from '../../utils/utils';

class LoginContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            token: this.props.token,
            message: '',
            passwordEye : {
                type: 'password',
                color: config.colors.rbDarkerGrey
            }
        }
    }
    componentDidMount = () => {
        this.setState({
            email: '',
            password: '',
            token: this.props.token,
            message: ''
        });
        this.activateUser();
    }

    activateUser = () => {

        if (this.state.token === '') {
            return;
        }

        LoginApi.activateUser(this.state.token)
            .then(({ data }) => {
                let result = data.data;
                this.setState({
                    message: result.message
                });
            })
            .catch(error => {
                let errorMessage = error.response.data.data.message;
                this.setState({
                    message: errorMessage
                });
            })
    }

    changeHandler = (value, name, e) => {
        this.setState({
            [name]: value
        });
    };

    identifyFullStory = (activeMerchant, email) => {
        if(window['_fs_namespace']){
            window[window['_fs_namespace']].identify(activeMerchant.id,{
                displayName : activeMerchant.nickname,
                email : email
            });
        }
    };

    login = (e) => {
        e.preventDefault();
        if (this.state.email === '' || this.state.password === '') {
            alert('Fields can not be empty');
        } else {
            const action = this.props.dispatch;
            action(spinner(true));
            LoginApi.authenticate(this.state.email, this.state.password, this.props.companyId)
                .then(({ data }) => {
                    action(spinner(false));
                    let result = data.data;
                    const activeMerchant = JSON.stringify(result.activeMerchant);
                    if (result.id) {
                        localStorage.setItem('userid', result.id);
                        localStorage.setItem('contactName', result.contactName);
                        localStorage.setItem('username', result.username);
                        localStorage.setItem('email', result.email);
                        localStorage.setItem('phoneNumber', result.phoneNumber);
                        localStorage.setItem('title', result.title);
                        localStorage.setItem('isLoggedIn', true);
                        localStorage.setItem('authKey', result.jwt);
                        localStorage.setItem('activeMerchant', activeMerchant);
                        localStorage.setItem('isFoodbamActive', result.isFoodbamActive);
                        localStorage.setItem('status', result.status);
                        localStorage.setItem('showInsideTrackModal', false);
                        localStorage.setItem('hasQuickBooks', true);
                        setToken(result.jwt);
                        if (result.isAdmin) {
                            localStorage.setItem('authKeyAdmin', result.authKey);
                            if (result.isAnalyst) {
                                localStorage.setItem('authKeyAnalyst', result.authKey);
                            }
                            this.props.history.push('/admin-login');
                        } else {
                            // Segment User Identification
                            Utils.identifySegment();
                            // Fullstory Identification
                            this.identifyFullStory(result.activeMerchant,result.email);
                            if (result.status === 1) {
                                // status === onboarding
                                this.props.history.push('/onboarding/1');
                            } else {
                                this.props.history.push('/dashboard');
                            }
                        }
                    } else {
                        alert(result.message);
                    }
                }).catch(function (error) {
                    if (error.status === 400) {
                        const errorMessage = 'Incorrect username or password. Please make sure you clicked the verification link we sent';
                        alert(errorMessage);
                    }
                    action(spinner(false));
                });
        }
    }

    goToRegister = (e) => {
        e.preventDefault();
        this.props.history.push('/register');
    };

    goToForgot = (e) => {
        e.preventDefault();
        this.props.history.push('/reset-password');
    };

    showPassword = () => {
        this.setState({
            passwordEye: {
                type: this.state.passwordEye.type === 'text' ? 'password' : 'text',
                color: this.state.passwordEye.color === config.colors.rbDarkerGrey ? config.colors.primary : config.colors.rbDarkerGrey
            }
        });
    }

    render() {
        const { email, password, message } = this.state;
        const { companyId } = this.props;
        const textLinkUnauthColor = getTextLinkColor(companyId);

        const styleEye = {
            position: 'relative',
            top: '-34px',
            right: isMobileOnly ? '-92%' : (isTablet ? '-96%' : '-92.5%')
        }
        let subtitle;
        if(message) {
            subtitle = <GridCol className={'col-12 form-group ' +  (isMobile ? 'text-center mb-0' : ' text-left mt-2')}>
            <InputLabel text={message} title className='subtitle'/>
        </GridCol>
        }
        return (
            <div className='right-login'>
                <div className='header-login'>
                    {isMobile ? <InputLabel text='Login' title className='title' /> : null}
                </div>
                <form className='login-form' onSubmit={this.login}>
                    <GridRow className='row' minWidth = { isMobile ? null : '400px'}>
                        <GridCol>
                            <InputLabel text='Welcome!' title className='title' />
                        </GridCol>
                        {subtitle}
                        <GridCol className='col-12 form-group' id='email-input'>
                            <InputLabel text='Email' />
                            <GridCol className='col p-0'>
                                <Input value={email} type='email' validate name='email' onChange={this.changeHandler} className='login-text' />
                            </GridCol>
                        </GridCol>
                        <GridCol className='col-12 form-group' id='password-input'>
                            <div className='line-labels'>
                                <InputLabel text='Password' />
                            </div>
                            <GridCol className='col p-0'>
                                <Input type={this.state.passwordEye.type} value={password} min={5} max={50} validate name='password' onChange={this.changeHandler} className='login-text' />
                                <span className='clickable' style={styleEye} onClick={this.showPassword}><IconEye color={this.state.passwordEye.color} size={18} /></span>
                                <InputLabel text='' title className='forgot-text'><a href='/forgot-password' className='font-weight-bold text-size-13' onClick={this.goToForgot} style={{color: textLinkUnauthColor}}>Forgot Password?</a></InputLabel>
                            </GridCol>
                        </GridCol>
                        <GridCol className='col-12 form-group' id='login-button'>
                            <GridCol className='col p-0'>
                                <Button type='submit' variant='primary' text='Log in' onClick={this.login} />
                            </GridCol>
                        </GridCol>
                        <GridCol className='col-12 form-group mt-4'>
                            <GridCol className='col p-0 label-contact d-flex'>
                                <InputLabel text="Don't have an account yet?"/>&nbsp; &nbsp;<a href='/register' className='font-weight-bold text-size-13' style={{color: textLinkUnauthColor}} onClick={this.goToRegister}>Sign Up</a>
                            </GridCol>
                        </GridCol>
                    </GridRow>
                </form>
                <GridRow>
                    <GridCol>
                        <div className='footer-login'>
                        </div>
                    </GridCol>
                </GridRow>

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        spinner: state.spinner.spinner,
        companyId: state.company.id
    };
}

export default connect(mapStateToProps)(LoginContent);
