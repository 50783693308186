import React, { useState } from 'react';
import ErniSvg from '../../assets/images/Erni.svg';
import { IoMdClose } from 'react-icons/io';
import { isMobile } from 'react-device-detect';

const Erni = ({message, rightAlign, centerAlign, top, right, left, bottom}) => {
  const [show, setShow] = useState(true);

  const enziStyle = {
    zIndex: 1070,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    width: '71px', height: '71px',
    border: '1px solid #D3D8E0',
  };

  const chatStyle = {
    zIndex: 1070,
    width: isMobile ? '320px' : '400px',
    color: '#202020',
    fontWeight: 700,
    fontSize: '16px',
    padding: '22px 32px 18px 16px',
    borderRadius: '8px',
    boxShadow: '0px 3px 12px rgba(0, 0, 0, 0.25)',
    right: centerAlign ? undefined : (rightAlign ? undefined : 0),
    bottom: 75,
    left: centerAlign ? (isMobile ? '-124.5px' : '-164.5px') : (rightAlign ? 0 : undefined),
  };

  if (!show) return null;

  return (
    <div className='position-absolute' style={{top, right, left, bottom}}>
      <div className='rounded-circle bg-white d-flex justify-content-center align-items-center position-relative' style={enziStyle}>
        <img src={ErniSvg} alt='Erni' className='erni-logo' />
        <div className='position-absolute bg-white' style={chatStyle}>
          {message}
          <IoMdClose onClick={() => setShow(false)} size={24} className='position-absolute clickable' style={{top: 8, right: 8}} />
        </div>
      </div>
    </div>
  );
};

export default Erni;