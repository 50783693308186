import React, { Component } from 'react';
import { isSafari } from 'react-device-detect';
import { connect } from 'react-redux';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import Modal from '../../../MasterComponents/Modals/Modal';
import ModalHeader from '../../../MasterComponents/Modals/ModalHeader';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import {ImageCapture} from 'image-capture';
import ModalFooter from '../../../MasterComponents/Modals/ModalFooter';
import IconCamera from '../../../MasterComponents/Icons/iconCamera';
import config from '../../../config/config';

let captureDevice;
let videoDevice;
const videoConstraints = {
  video: {
    facingMode: "environment",
    height: 1080,
    width: 1920
  }
};
class TakePictureModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allowPicture: false,
      width: props.width,
      height: props.height
    }
  };
  componentDidMount () {
    this.onUserMedia();
  }
  onUserMedia = () => {
    if (isSafari) {
      this.onSafariUserMedia();
    } else {
      this.onOtherUserMedia();
    }
  };
  onOtherUserMedia = () => {
    navigator.mediaDevices.getUserMedia(videoConstraints).then(mediaStream => {
      videoDevice = mediaStream.getVideoTracks()[0];
      this.setState({
        allowPicture: true
      });
      captureDevice = new ImageCapture(videoDevice);
      let canvas = document.getElementById('canvasOutput');
      if (captureDevice) {
        const processFrame = (imageBitmap) => {
          canvas.width = imageBitmap.width;
          canvas.height = imageBitmap.height;
          canvas.getContext('2d').drawImage(imageBitmap, 0, 0);
          grabFrame();
        };

        const grabFrame = () => {
          captureDevice.grabFrame().then(processFrame).catch(err => {
           console.error('Capture error:', err);
          });
        };

        grabFrame();
      }
    }).catch(err => {
      console.error('user media error: ', err);
    })
  };
  onSafariUserMedia = () => {
    navigator.mediaDevices.getUserMedia(videoConstraints).then(mediaStream => {
      videoDevice = mediaStream.getVideoTracks()[0];
      let video = document.getElementById('videoInput');
      video.srcObject = mediaStream;
      video.play();
      this.setState({
        allowPicture: true
      });
      captureDevice = new ImageCapture(videoDevice);
    }).catch(err => {
      console.error('user media error: ', err);
    })
  };
  onTakePhoto = () => {
    if (this.state.allowPicture) {
      captureDevice.takePhoto().then(blob => {
        const r = Math.random().toString(36).substring(7);
        const file = new File([blob], `snap_${r}.png`);
        this.stopCamera();
        this.props.saveSnap(file);
      }).catch(err => {
        console.error('Snap error:', err)
      });
    }
  };
  stopCamera = () => {
    if (videoDevice) {
      videoDevice.stop();
    }
  };
  render() {
    const { allowPicture } = this.state;
    const { width, height } = this.props;
    return (
      <Modal id="takePictureModal" fullHeight classNameContent='black-bg' onHide={this.stopCamera}>
        <ModalHeader closeColor="#fff" className='black-bg border-0' />
        <ModalBody className='p-0'>
          <GridRow className={'p-0 m-0 black-bg' + (isSafari ? '' : ' d-none')}> 
            <GridCol className='p-0'>
              <video autoPlay muted playsInline id="videoInput" width="1080" height="1920" style={{width: `${width}px`, height:`${height-160}px`}}></video>
            </GridCol>
          </GridRow>
          {
            !isSafari &&
            <GridRow className='p-0 black-bg'>
              <GridCol className='p-0'>
                <canvas id="canvasOutput" style={{width: '100%'}}></canvas>
              </GridCol>
            </GridRow>
          }
        </ModalBody>
        <ModalFooter>
          <GridRow>
            <GridCol onClick={this.onTakePhoto}>
                <IconCamera color={allowPicture ? config.colors.rbWhite : config.colors.rbRed} size={36}  />
            </GridCol>
          </GridRow>
        </ModalFooter>
      </Modal>
    );
  }
};

const mapStateToProps = state => {
  return {
    width: state.resizeListener.width,
    height: state.resizeListener.height
  };
}

export default connect(mapStateToProps)(TakePictureModal);