import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import GridCol from '../../MasterComponents/Grid/GridCol';
import GridRow from '../../MasterComponents/Grid/GridRow';
import Button from '../../MasterComponents/Button/Button';
import Input from '../../MasterComponents/Input/Input';
import InputLabel from '../../MasterComponents/InputLabel/InputLabel';
import ChangePasswordApi from './ChangePasswordApi';

class ChangePasswordContent extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            token: this.props.token,
            newpass: '',
            confirmpass: ''
        }        
    }

    componentDidMount = () => {
        this.setState({
            token: this.props.token,
            newpass: '',
            confirmpass: ''
        });
    }

    changeHandler = (value, name, e) => {
        this.setState({
          [name]: value
        });
    };
    
    savePassword = (e) => {
        e.preventDefault();
        
        if (this.state.newpass === '' || this.state.confirmpass === '') {
            alert('Fields can not be empty');
            return;
        }
        if (this.state.newpass !== this.state.confirmpass) {
            alert('Fields can not be different');
            return;
        }
        
        ChangePasswordApi.changePassword(this.state.token, this.state.newpass, this.state.confirmpass)
            .then(({data})=>{
                let result = data.data;
                alert(result.message);
                setTimeout(()=>{
                    this.goToLogin(e);
                }, 3000);
            })
            .catch(error => {
                if (error.data.data) {
                    let errorMessage = error.data.data.message;
                    alert(errorMessage);
                }                
            })       
    };

    goToLogin = (e) => {
        e.preventDefault();
        this.props.history.push('/login');
    };
    

    render() {
        const { newpass, confirmpass} = this.state;
        return (
            <div className='right-change'>
                <div className='header-change'>
                    { isMobile ? <InputLabel text='Login' title className='title'/> : null}
                </div>
                <form className='change-form'>
                    <GridRow className='row'>
                        <GridCol className='col-12'>
                            <InputLabel text='Create New Password' title className='title'/>                            
                        </GridCol>
                        <GridCol className='col-12 form-group mt-4'>
                            <InputLabel text='New Password'/>                            
                            <GridCol className='col p-0'>
                                <Input type='password' value={newpass} name='newpass' onChange={this.changeHandler} placeholder='..........' className='login-text' />
                            </GridCol>
                        </GridCol>
                        <GridCol className='col-12 form-group mt-4'>
                            <InputLabel text='Confirm Password'/>
                            <GridCol className='col p-0'>
                                <Input type='password' value={confirmpass} name='confirmpass' onChange={this.changeHandler} placeholder='..........' className='login-text' />
                            </GridCol>
                        </GridCol>
                        <GridCol className='col-12 form-group mt-5'>
                            <GridCol className='col p-0'>
                                <Button type='button' variant='primary' text='Save' onClick={this.savePassword}></Button>
                            </GridCol>
                        </GridCol>
                        <GridCol className='col-12 form-group mt-4'>
                            <GridCol className='col p-0 label-contact d-flex'>
                                <span><a href='/login' onClick={this.goToLogin} className='text-primary'>Back to Login</a></span>
                            </GridCol>                            
                        </GridCol>                        
                    </GridRow>                    
                </form>
                <div className='footer-reset'>
                </div>
            </div>
        )
    }
}

export default ChangePasswordContent;