/* global analytics */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../SignUp.scss';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import Button from '../../../MasterComponents/Button/Button';
import InputLabel from '../../../MasterComponents/InputLabel/InputLabel';
import { setRegisterFormType, setSegment, setSubsegment } from '../../../config/Company/CompanyActions';
import queryString from 'query-string';

class SignUpWhitelabelNewTypeContentLinks extends Component {
  resetSegmentAndSubsegment = () => {
    const { dispatch } = this.props;
    dispatch(setSegment(null));
    dispatch(setSubsegment(null));
  };
  go = (l) => {
    const { segment, history, dispatch } = this.props;
    const childrenCount = l.children ? l.children.length : 0;
    if (childrenCount === 0 && l.isExternalDomain) {
      const { search } = history.location;
      const parsed = queryString.parse(search);
      if (!l.parentId) {
        parsed.segment = l.description;
      } else {
        parsed.segment = segment;
        parsed.subsegment = l.description;
      }
      const newSearch = '?' + queryString.stringify(parsed);
      const newLocation = `${l.domain}${l.route}${newSearch}`;
      window.location = newLocation;
    } else {
      dispatch(setRegisterFormType(l.formType));
      if (!l.parentId) {
        dispatch(setSegment(l.description));
      } else {
        dispatch(setSubsegment(l.description));
      }
      if (childrenCount === 0) {
        history.push(`${l.route}${history.location.search}`);
      }
    }

    analytics.track('Business Type Selected', {
   	  business_type: l.description,
      portal_name: this.props.companyName,
    });
  };
  render() {
    const { links, segment } = this.props;
    let selectedLinks = links;
    let selectedItem;
    if (segment) {
      selectedItem = links.find(l => l.description === segment);
      selectedLinks = selectedItem && selectedItem.children && selectedItem.children.length > 0 ? selectedItem.children : links;
    }

    console.log(this.props)
    return (
      <GridCol>
        <GridRow>
          <GridCol>
            <InputLabel text='I represent:' title className='title pl-0'/>
          </GridCol>
        </GridRow>
        {
        (selectedItem && selectedItem.children && selectedItem.children.length > 0) && <GridRow key={0} className='mt-4'>
            <GridCol>
              <Button onClick={this.resetSegmentAndSubsegment.bind(this)} size='md' variant='channel-partner' className='w-100' text={'Change Segment'} />
            </GridCol>
          </GridRow>
        }
        {
          selectedLinks.map( l => {
            return (
              <GridRow key={l.id} className='mt-4'>
                <GridCol>
                  <Button onClick={this.go.bind(this, l)} size='md' variant='channel-partner' className='w-100' text={l.description} />
                </GridCol>
              </GridRow>
            )
          })
        }
      </GridCol>
    )
  };
}
const mapStateToProps = state => {
    return {
        segment: state.company.segment,
        subsegment: state.company.subsegment
    };
}

export default connect(mapStateToProps)(SignUpWhitelabelNewTypeContentLinks);
