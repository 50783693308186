import React, { Component, Fragment } from 'react';
import { showModal } from '../../../MasterComponents/Modals/ModalsActions';
import AddNewDistributorToModal from '../../Dashboard/Modals/AddNewDistributorToModal';
import UploadPurchaseHistoryModal from '../../Dashboard/Modals/UploadPurchaseHistoryModal';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import DistributorListRow from './DistributorListRow';
import IconMarker from '../../../MasterComponents/Icons/IconMarker';
import ReactGA from 'react-ga';
import IconAddCircle from '../../../MasterComponents/Icons/IconAddCircle';
import config from '../../../config/config';
import showActivateDistributor from '../../../MasterComponents/SharedFunctions/showActivateDistributor';
import LocationEmailToSalesRepModal from '../Modals/LocationEmailToSalesRepModal';
import LocationApi from '../LocationApi';
import LocationDistributorDeleteModal from '../Modals/LocationDistributorDeleteModal';
import { spinner } from '../../../MasterComponents/Spinner/SpinnerActions';
import { connect } from 'react-redux';

class DistributorListContent extends Component {
  constructor(props) {
    super(props);
    this.showActivateDistributor = showActivateDistributor.bind(this);
  }
  clickActivate = (merDist, e) => {
    e.stopPropagation();
    this.showActivateDistributor(merDist);
  };
  clickActivateEdit = (merDist, e) => {
    e.stopPropagation();
    this.showActivateDistributor(merDist, true);
  };
  clickActivateDelete = (merDist, e) => {
    e.stopPropagation();
    const { dispatch, refresh } = this.props;
    dispatch(showModal(LocationDistributorDeleteModal, 'idLocationDistributorDeleteModal', {  merDist: merDist, refresh }));
  }
  clickAddNewDistributor = () => {
    const { dispatch, merchantAll, merchant, companyId } = this.props;
    const isAdmin = localStorage.getItem('authKeyAdmin');
    ReactGA.event({
      category: 'Click Button',
      action: 'Click Add New Distributor'
    });
    if (companyId === 17 && !isAdmin) {
      // For Prime Services we are sending an email to the sales rep
      dispatch(spinner(true));
      LocationApi.sendEmailToSalesRep(companyId).then((res) => {
        dispatch(spinner(false));
        dispatch(showModal(LocationEmailToSalesRepModal, 'locationEmailToSalesRepModal', {}));
      }).catch(error => {
        dispatch(spinner(false));
        console.log(error);
      });
    }
    else {
      dispatch(showModal(AddNewDistributorToModal, 'addNewDistributorToModal', { goToAdd: this.goToAddNewDistributor, locations: merchantAll, merchant: merchant }));
    }
  }

  goToAddNewDistributor = (state) => {
    const { history } = this.props;
    ReactGA.event({
      category: 'Click Button',
      action: 'Click Add New Distributor'
    });
    history.push({
      pathname: '/dashboard/add-new-distributor',
      state: state
    });
  }

  goToEdit = (dist) => {
    const { history } = this.props;
    history.push(`/dashboard/distributor/${dist.distributorId}`)
  }

  clickUploadNow = (merDist, e) => {
    e.stopPropagation();
    const { dispatch, refresh } = this.props;
    ReactGA.event({
      category: 'Click Button',
      action: 'Click Upload Now'
    });
    dispatch(showModal(UploadPurchaseHistoryModal, 'uploadPurchaseHistoryModal', { merchantDistributor: merDist, refresh: refresh }));
  };

  render() {
    const { merchantDistributors, locationName, companyColors} = this.props;
    const distributorsListRowMap = merchantDistributors.map((merDist) => (
      <DistributorListRow key={`merlist${merDist.id}`} merchantDistributor={merDist} onClickActivate={this.clickActivate.bind(this, merDist)} clickActivateEdit={this.clickActivateEdit.bind(this, merDist)} clickActivateDelete={this.clickActivateDelete.bind(this, merDist)} goToEdit={this.goToEdit.bind(this, merDist)} onClickUploadNow={this.clickUploadNow.bind(this, merDist)} />
   ));
    return (
      <Fragment >
          <GridCol size={10} className='py-3 text-grey border-top text-size-16 line-height-18 bg-grey-row text-bold'>
            <span > <IconMarker color={companyColors.dashboardTopbarLink} size={20}/> {locationName}</span>
          </GridCol>
          <GridCol size={2} className='py-3 text-right clickable border-top text-size-14 line-height-16 text-blue bg-grey-row' onClick={this.clickAddNewDistributor}>
            <IconAddCircle size={18} color={config.colors.primary} />
             &nbsp; ADD DISTRIBUTOR
          </GridCol>
          <GridCol size={12}>
            {distributorsListRowMap}
          </GridCol>
      </Fragment>
      
      
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyId: state.company.id,
  };
};

export default connect(mapStateToProps)(DistributorListContent);
