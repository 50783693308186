import React from 'react';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import emailImg from '../../../assets/images/email.svg';

const SignUpSimpleSuceed = () => {
  return (
    <div className='container' style={{marginTop: '88px'}}>
      <GridRow className='mt-5'>
        <GridCol className='text-center'>
          <img src={emailImg} alt='email' />
        </GridCol>
      </GridRow>
      <GridRow className='mt-3'>
        <GridCol className='text-center'>
          <label className='mb-2 signup-title-sucessfully'>Almost Done!</label>
        </GridCol>
      </GridRow>
      <GridRow className='mt-3'>
        <GridCol className='text-center signup-body-words'>
          Please click the activation link that we sent you to log in and “activate” your distributors. You must do this in order to start earning cash back.<br/>
          Thank you for registering!
        </GridCol>
      </GridRow>
    </div>
  );
};

export default SignUpSimpleSuceed;