import React, { Component, Fragment } from 'react'
import { isMobile } from 'react-device-detect';
import { findModal } from '../../../MasterComponents/Modals/ModalsActions';
import Modal from '../../../MasterComponents/Modals/Modal';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import ModalHeader from '../../../MasterComponents/Modals/ModalHeader';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import DropDownListSelect from '../../../MasterComponents/DropDownList/DropDownListSelect';
import ModalFooter from '../../../MasterComponents/Modals/ModalFooter';
import Button from '../../../MasterComponents/Button/Button';
import Checkbox from '../../../MasterComponents/Checkbox/Checkbox';
import TextArea from '../../../MasterComponents/TextArea/TextArea';
import InputLabel from '../../../MasterComponents/InputLabel/InputLabel';
import Input from '../../../MasterComponents/Input/Input';


class DistributorHouseModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      distributorsHouseSelected : this.props.distributorsHouseSelected,
      isReinhartChecked : true,
      signedText : this.props.signedText,
      
    }
  }

  closeModal = () => {
    const { modals } = this.props;
    findModal(modals, 'distributorHouseModalId').close();
  }

  onChangeDistributorHouse = (option) => {
    const hasReinhart = option.label.toUpperCase().split(" ").find(word => word  === 'REINHART');
    const hasPFG = this.props.distributorParent && this.props.distributorParent.esign_name.toUpperCase().search('PERFORMANCE FOOD GROUP') === 0;

    if(hasReinhart || hasPFG) {
      this.setState({
        isReinhartSelected : true
      });
    } else {
      this.setState({
        isReinhartSelected : false
      });
    }
    this.setState({
      distributorsHouseSelected : option
    });   
    this.props.onChangeDistributorHouse(option);
  }

  authorizationReinhartChecked = () => {
    const { isReinhartChecked } = this.state;
    this.setState({
      isReinhartChecked : !isReinhartChecked
    });
  }

  changeHandlerSignedText = (value) => {
      this.setState({
        signedText : value
      });
    this.props.changeHandlerSignedText(value);
  }

  saveDistributorHouse = () => {
    const { distributorHouseName, signedText } = this.refs;
    const { isReinhartChecked, isReinhartSelected } = this.state;
    if(isReinhartSelected) {
      if(distributorHouseName.isValid() && signedText.isValid() && isReinhartChecked) {
        this.props.saveDistributorHouse();
        this.closeDistributorHouseModal();
      }
    } else {
      if(distributorHouseName.isValid()) {
        this.props.saveDistributorHouse();
        this.closeDistributorHouseModal();
      }
    }
  }

  closeDistributorHouseModal = () => {
    const { modals } = this.props;
    findModal(modals, 'distributorHouseModalId').close();
  }

  render() {
    const { distributorsHouse } = this.props;
    const { isReinhartSelected, isReinhartChecked, signedText } = this.state;
    let textReinhartAgreement = `The undersigned ("Customer"), a customer of Performance Food Service Foodservice, L.L.C. and/or its subsidiaries ("Performance Food Service"), hereby requests Performance Food Service to disclose information regarding Customer's purchasing information to the following entitiy (the "Third Party Recipient"): \nThird Party Recipient: Entegra and Buyers Edge \nCustomer hereby acknowledges that the information disclosed to the Third Party Recipient may contain confidential and proprietary information of Customer. Customer hereby waives and releases Performance Food Service from any and all claims, demands, actions, causes of action, liability, damages and demands of any kind whatsoever that Customer may have against Performance Food Service in connection with Performance Food Service's release of Customer information to the Third Party Recipient. This aurthorization shall continue in effect until Performance Food Service receives written notice of its revocation from Customer. All such notices shall be provided to Performance Food Service at Reinahrt Foodservice, L.L.C., 6250 N. River Road, Suite 9000, Rosemont, IL 60018, Attention: General Counsel.`

    return (
      <Modal id='distributorHouseModalId' centered={isMobile} >
      <ModalHeader>Distributor House &nbsp;</ModalHeader>
      <ModalBody>
        <GridRow className={(isMobile ? 'mt-3 mb-5' : 'mt-3')}>
                <GridCol className={(isMobile && !isReinhartSelected ? 'mb-3' : '')}>
                    <DropDownListSelect
                        name='distributorHouseName' ref='distributorHouseName'
                        value={this.state.distributorsHouseSelected}
                        options={distributorsHouse}
                        onChange={this.onChangeDistributorHouse}
                        isDisabled={distributorsHouse.length === 0}
                    />
                </GridCol>
        </GridRow>
        { isReinhartSelected ?
            <Fragment>
              <GridRow className='line-form'>
                  <GridCol className='form-group'>
                      <GridRow className='check'>
                          <GridCol className={isMobile ? 'col-2' : 'col-1'}>
                              <Checkbox  name='isReinhartChecked' value={isReinhartChecked} text='' onChange={this.authorizationReinhartChecked} />
                          </GridCol>
                          <GridCol className={'col' + (isMobile ? '' : ' mt-3 checked-text') + (!isReinhartChecked ? ' text-red' : '') }>
                              Performance Food Service Acknowledgement, Authorization and Release
                          </GridCol>                    
                      </GridRow>
                      
                  </GridCol>
              </GridRow>
              <GridRow className='line-form'>
                  <GridCol className='form-group'>
                      <TextArea readOnly className={'col-12 signup-authorized'} col={4} rows={15} defaultValue={textReinhartAgreement} />
                  </GridCol>                    
              </GridRow>
              <GridRow>
                    <GridCol className='form-group'>
                        <InputLabel text='Confirm your name and signature' title className='title'/>
                    </GridCol>                    
                </GridRow>
                <GridRow className='line-form'>
                    <GridCol className='form-group'>
                        <InputLabel text='Your Full Name *'/>
                        <Input type='text' ref='signedText' name='signedText' value={signedText || ''} onChange={this.changeHandlerSignedText} validate min={3} max={100} />
                    </GridCol>
                    <GridCol className='form-group'>
                        <InputLabel text='Signed by *'/><br/>
                        <span className='signup-signed'>{signedText}</span>                     
                    </GridCol>
                </GridRow>
            </Fragment>
        :
              null
        }
      </ModalBody>
      <ModalFooter>
        <Button variant='primary' onClick={this.saveDistributorHouse} className='w-100 clickable' size='lg' text='Save'></Button>
      </ModalFooter>
      </Modal>
    );
  }
};

export default DistributorHouseModal;
