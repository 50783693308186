import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import moment from 'moment';
import * as $ from 'jquery';
import DatePicker from 'react-datepicker';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import Modal from '../../../MasterComponents/Modals/Modal';
import ModalHeader from '../../../MasterComponents/Modals/ModalHeader';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import ModalFooter from '../../../MasterComponents/Modals/ModalFooter';
import Button from '../../../MasterComponents/Button/Button';
import DashedBox from '../../../MasterComponents/DashedBox/DashedBox';
import IconUpload from '../../../MasterComponents/Icons/IconUpload';
import config from '../../../config/config';
import LogoExcel from '../../../MasterComponents/Logos/LogoExcel';
import LogoPicture from '../../../MasterComponents/Logos/LogoPicture';
import IconDelete from '../../../MasterComponents/Icons/IconDelete';
import InputLabel from '../../../MasterComponents/InputLabel/InputLabel';
import DashboardApi from '../DashboardApi';
import { spinner } from '../../../MasterComponents/Spinner/SpinnerActions';
import { findModal, showModal } from '../../../MasterComponents/Modals/ModalsActions';
import { isMobile } from 'react-device-detect';
import LogoPdf from '../../../MasterComponents/Logos/LogoPdf';
import TakePictureModal from './TakePictureModal';

class UploadPurchaseHistoryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileEmpty: true,
      fileName: '',
      file: null,
      incorrectType: false,
      isImage: false,
      startDate: new Date(),
      endDate: new Date(),
      files: []
    }
  }
  uploadExcel = () => {
    const { hiddenFileInput } = this.refs;
    hiddenFileInput.click();
  }
  uploadImage = () => {
    // if (isMobile) {
    //   this.takePicture();
    // } else {
      const { hiddenFileImageInput } = this.refs;
      hiddenFileImageInput.click();
    // }
  }
  takePicture = () => {
    const { dispatch } = this.props;
    dispatch(showModal(TakePictureModal, 'takePictureModal', {saveSnap: this.saveSnap}));
  };
  saveSnap = (snap) => {
    this.setState({
      files: [
        ...this.state.files,
        {
          isImage: true,
          isPdf: false,
          isSnap: true,
          incorrectType: false,
          fileName: snap.name,
          file: snap
        }
      ]
    });
    findModal(this.props.modals, "takePictureModal").close();
  };
  selectFile = (inputFileId) => {
    const files = $('#'+ inputFileId).prop('files');
    const excelTypes = ["text/csv", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","application/vnd.oasis.opendocument.spreadsheet", "application/pdf"];
    const imageTypes = ["image/jpeg", "image/png"];
    const fileArray = [...this.state.files];
    for (let i = 0; i < files.length; i++){
      const file = files[i];
      if (imageTypes.indexOf(file.type) !== -1 || !file.type || excelTypes.indexOf(file.type) !== -1) {
        file.isImage = imageTypes.indexOf(file.type) !== -1 ? true : false;
        file.isPdf = file.type === 'application/pdf' ? true : false;
        file.incorrectType = false;
        file.fileName = file.name;
        file.file = file;
        fileArray.push(file);
      } else {
        $('#'+ inputFileId).val("");
      }
    }

    this.setState({
      files: fileArray
    })
  }
  clearFile = (index) => {
    const files = [...this.state.files];
    files.splice(index,1);
    this.setState({files : files});
    if (files.length === 0){
      $('#excel-upload').val("")
      $('#img-upload').val("")
    }
  }
  changeDate = (name, date) => {
    this.setState({
      [name]: date
    });
  }
  clickUpload = () => {
    const { files, startDate, endDate } = this.state;
    const { dispatch, refresh, merchantDistributor } = this.props;
    if (files.length > 0) {
      const format = 'YYYY-MM-DD';
      const dateRange = moment(startDate).format(format) + ' - ' + moment(endDate).format(format);
      dispatch(spinner(true));
      DashboardApi.updateDistributorFile(merchantDistributor.id, files, dateRange).then(({data}) => {
        dispatch(spinner(false));
        findModal(this.props.modals, 'uploadPurchaseHistoryModal').close();
        refresh();
      }, err => {
        dispatch(spinner(false));
        findModal(this.props.modals, 'uploadPurchaseHistoryModal').close();
        refresh();
        alert("Error uploading file");
      });
    }
  }
 
  render() {
    const { files } = this.state;
    const fileArray = files ? files.map( (file,index) => (
      <div key={index}>
      <Fragment >
          { file.isImage ? <LogoPicture style={{width: '30px'}} /> : (file.isPdf ? <LogoPdf style={{width: '30px'}} /> : <LogoExcel style={{width: '30px'}} />) } {file.fileName}
          <span onClick={() => this.clearFile(index)} className='float-right mt-1 clickable'><IconDelete color={config.colors.rbDarkGrey} /></span>
      </Fragment>
      </div>
    )) : null;

    const imgDashedText = isMobile ? 'Take a Picture' : 'Upload Image';
    const addAnotherText =  isMobile ? 'Add Another Picture' : 'Add Another Image';

    const addNewFile = fileArray && fileArray.length > 0 ? 
    <div className="w-100 d-flex justify-content-between">
      <div style={{flex: '0 1 49%'}}>
          <Button onClick={this.uploadExcel} variant='primary' size='lg-fs-md' className='w-100' text='Add another CSV/Excel File' />
      </div>
      <div style={{flex: '0 1 49%'}}>
          <Button onClick={this.uploadImage} variant='primary' size='lg-fs-md' className='w-100' text={addAnotherText} />
      </div>
    </div> : null;

    const { startDate, endDate } = this.state;
    return (
      <Modal size={ isMobile ? '100' : 'sm'} id='uploadPurchaseHistoryModal'>
        <ModalHeader closeButton={!isMobile} noBorder={isMobile}>Upload Purchase History</ModalHeader>
        <ModalBody>
          <GridRow className='mt-4 mb-1'>
            <GridCol className='text-size-14'> 
              <input ref='hiddenFileInput' className='d-none' onChange={() => this.selectFile('excel-upload')} type="file" multiple="multiple" id='excel-upload' accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xsl, .xlsx, application/vnd.oasis.opendocument.spreadsheet, application/pdf" />
              <input ref='hiddenFileImageInput' className='d-none' onChange={() => this.selectFile('img-upload')} type="file" multiple="multiple" id='img-upload' accept="image/jpeg,image/x-png" />
              {
                fileArray.length === 0 ?
                <div className="d-flex">
                    <DashedBox onClick={this.uploadExcel} textClassName='text-size-12' className={'m-0 clickable w-50'} height='90px'>
                      <IconUpload color={config.colors.rbDarkerGrey} /> 
                      &nbsp; {'Upload pdf, excel or csv file'}</DashedBox>

                    <DashedBox onClick={this.uploadImage} textClassName='text-size-12' className={'m-0 clickable w-50'} height='90px'>
                      <IconUpload color={config.colors.rbDarkerGrey} /> 
                      &nbsp; {imgDashedText}</DashedBox>
                </div>
                    
                :
                fileArray
              }
            </GridCol>
          </GridRow>
          {
            fileArray.length > 0 ?
            <GridRow className='mt-4'>
              <GridCol size={6} className={isMobile ? 'customDatePickerAlign' : ''}>
                <InputLabel>File Start Date</InputLabel>
                { isMobile ?
                <DatePicker name='startDate'  className='rb-input w-100' locale='en'  popperPlacement="bottom-end" selected={startDate} dateFormat="MM/dd/yyyy" maxDate={new Date()} onChange={this.changeDate.bind(this, 'startDate')} withPortal />
                :  
                <DatePicker name='startDate'  className='rb-input w-100' locale='en'  popperPlacement="bottom-end" selected={startDate} dateFormat="MM/dd/yyyy" maxDate={new Date()} onChange={this.changeDate.bind(this, 'startDate')} /> }
              </GridCol>
              <GridCol size={6} className={isMobile ? 'customDatePickerAlign' : ''}>
                <InputLabel>File End Date</InputLabel>
                { isMobile ?
                <DatePicker name='endDate' className='rb-input w-100' locale='en' popperPlacement="bottom-start" selected={endDate} dateFormat="MM/dd/yyyy" minDate={startDate} maxDate={new Date()} onChange={this.changeDate.bind(this, 'endDate')} withPortal  />
                :  
                <DatePicker name='endDate' className='rb-input w-100' locale='en' popperPlacement="bottom-start" selected={endDate} dateFormat="MM/dd/yyyy" minDate={startDate} maxDate={new Date()} onChange={this.changeDate.bind(this, 'endDate')} /> }
              </GridCol>
            </GridRow>
            : null
          }
        </ModalBody>
        <ModalFooter>
          <div className="w-100">
            <div className="w-100 pb-2"> 
              <Button onClick={this.clickUpload} variant='primary' size='lg' className='w-100' text='Upload' />
              <Button onClick={this.takePicture} variant='primary' size='lg-fs-md' className='d-none takePictureButton' text={imgDashedText} />
            </div>
            {addNewFile}
          </div>
        </ModalFooter>
      </Modal>
    );
  }
};

const mapStateToProps = state => {
  return {};
}

export default connect(mapStateToProps)(UploadPurchaseHistoryModal);