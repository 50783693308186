import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser'; 
import { isMobile } from 'react-device-detect';
import DatePicker from 'react-datepicker';
import DropDownList from '../../../../MasterComponents/DropDownList/DropDownList';
import DropDownListOption from '../../../../MasterComponents/DropDownList/DropDownListOption';
import Input from '../../../../MasterComponents/Input/Input';
import InputLabel from '../../../../MasterComponents/InputLabel/InputLabel';
import Checkbox from '../../../../MasterComponents/Checkbox/Checkbox';
import TextArea from '../../../../MasterComponents/TextArea/TextArea';
import Button from '../../../../MasterComponents/Button/Button';
import GridRow from '../../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../../MasterComponents/Grid/GridCol';
import DropDownListSelect from '../../../../MasterComponents/DropDownList/DropDownListSelect';
import SignUpApi from '../../SignUpApi';
import { spinner } from '../../../../MasterComponents/Spinner/SpinnerActions';
import config from '../../../../config/config';
import LOTAcknowledgementModal from '../../Modals/LOTAcknowledgementModal';
import { showModal } from '../../../../MasterComponents/Modals/ModalsActions';
import moment from 'moment';

class FormExistingUserStep2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            manufacturers: [],
            manufacturersApi: [],
        };
    };
    componentDidMount = () => {
        this.getManufacturers('', 1);
    };
    getManufacturers = (search, page) => {
        const { dispatch } = this.props;
        dispatch(spinner(true));
        SignUpApi.getManufacturers(search, page).then(({ data }) => {
            if (search) {
                this.setState({
                    manufacturers: [
                        {
                            label: 'Add: '+search,
                            value: search,
                            isCustom: true
                        },
                        ...data.data.map((item) => {
                            return {
                                ...item,
                                label: item.nickname,
                                value: item.id,
                            }
                        })
                    ],
                    manufacturersApi: data.data.map((item) => {
                        return {
                            ...item,
                            label: item.nickname,
                            value: item.id,
                        }
                    })
                });
            } else {
                this.setState({
                    manufacturers: data.data.map((item) => {
                        return {
                            ...item,
                            label: item.nickname,
                            value: item.id,
                        }
                    }),
                    manufacturersApi: data.data.map((item) => {
                        return {
                            ...item,
                            label: item.nickname,
                            value: item.id,
                        }
                    })
                });
            }
            dispatch(spinner(false));
        })
    };
    searchManufacturers = (value) => {
        const { manufacturersApi } = this.state;
        if (value) {
            this.setState({
                manufacturers: [
                    {
                        label: 'Add: '+value,
                        value: value,
                        isCustom: true
                    },
                    ...manufacturersApi
                ]
            });
        } else {
            this.setState({
                manufacturers: manufacturersApi
            });
        }
        this.getManufacturers(value, 1);
    };
    changeDate = (date) => {
        this.props.changeHandler(date, 'startDate');
    };
    back = () => {
        const { goToStep } = this.props;
        goToStep(1);
    };
    validateForm = () => {        
        const { isDistributorManufacturerAgreement, isGpo, isAuthorized, isLOTAuthorized} = this.props;
        const { signedText, nameOfCompaniesSelect, gpoName } = this.refs;

        const gpo = isGpo ? gpoName.isValid() : true;
        const lot = isGpo ? isLOTAuthorized : true;
        const nc = isDistributorManufacturerAgreement ? nameOfCompaniesSelect.isValid() : true;
        const st = signedText.isValid();

        if (!isAuthorized) {
            this.props.changeHandler(true, 'isAuthorizedInvalid');
        }

        if (gpo && nc && st && isAuthorized && lot) {
            return true;
        }
        return false;
    };
    selectOptionMemberGPO = (num) => {
        const { gpoName } = this.refs;
        const { changeHandler, setError } = this.props;
        gpoName.setValid();
        changeHandler(num, 'isGpo');
        setError('');
    };
    selectOptionMFGAgreements = (num) => {
        const { nameOfCompaniesSelect } = this.refs;
        const { changeHandler, setError } = this.props;
        nameOfCompaniesSelect.setValid();
        changeHandler(num, 'isDistributorManufacturerAgreement');
        setError('');
    };
    authorizationChecked = () => {
        const { changeHandler, isAuthorized } = this.props;
        changeHandler( !isAuthorized, 'isAuthorized');
        changeHandler( false, 'isAuthorizedInvalid');
    };
    authorizationFsrChecked = () => {
        const { changeHandler, isFsrAuthorized } = this.props;
        changeHandler( !isFsrAuthorized, 'isFsrAuthorized');
        changeHandler( false, 'isAuthorizedInvalid');
    };
    done = () => {
        let result = this.validateForm();
        this.setState({validFields:result}, () =>{
            if(!result){
                return;           
            } else {
                this.props.done();
            }
        });
    };
    LOTLegalText = (value) => {
        const { gpoName, businessName } = this.props;
        const today = moment().format('MMMM D, YYYY');
        const gpoNameValue = value || gpoName;
        const parentName = businessName;
        return `Effective ${today}, ${parentName} will no longer participate in any programs offered by ${gpoNameValue}. Buyers Edge Platform is hereby authorized to communicate this termination with my former GPO on Member's behalf. However, Member hereby acknowledges that it is the Member's responsibility to provide a copy of the letter of termination ("LOT") to the former GPO of record. A copy of the LOT of termination will be supplied to Member for the purposes of communicating the same to Member's former GPO.`;
    };
    acknowledgeLOTTerms = (bool) => {
        this.props.authorizationLOTChecked(bool);
    }
    selectOptionGPOName = (option) => {
        const { dispatch, changeHandler } = this.props;
        changeHandler(option.value, 'gpoName');
        dispatch(showModal(LOTAcknowledgementModal, 'LOTAcknowledgementModal', { message: this.LOTLegalText(option.value), acknowledgeLOTTerms: this.acknowledgeLOTTerms }));
    };
    createGPOName = (input) => {
        this.setState({
            customGPOName: input
        });
    };
    render() {
        const {
            props, selectOptionMemberGPO, selectOptionMFGAgreements, state,
            selectOptionGPOName,
            authorizationChecked, authorizationFsrChecked, createGPOName
        } = this;
        const { changeHandler, nameOfCompaniesHandler,
            brandSelected, isGpo, gpoName, isDistributorManufacturerAgreement,
            nameOfCompanies, isAuthorized, isAuthorizedInvalid, signedText, error,
            startDate, isFsrAuthorized, isFsrAuthorizedInvalid, isLOTAuthorized,
            isLOTAuthorizedInvalid, authorizationLOTChecked
         } = props;
        const { manufacturers : manufacturersOptions, customGPOName } = state;
        const textLegal = `I am an authorized agent, owner or employee of the above business (the 'Member') and have the authority to enter into a participation agreement with ${brandSelected.name} ('${brandSelected.abbreviation}') on its behalf. To the best of my knowledge, all information provided herein is correct, if ${brandSelected.abbreviation} should discover that the information provided is not correct, it has the right to cancel or amend our participation in any and all programs through ${brandSelected.abbreviation}. By signing this letter or participation, I am authorizing ${brandSelected.abbreviation} to enroll the business listed above in one or more ${brandSelected.abbreviation} programs as directed by the member.
        I also acknowledge that any current programs we desire to continue through a direct relationship have been disclosed and I understand that we will not be allowed to participate in the related ${brandSelected.abbreviation} program(s). Further, if it is discovered that a program or programs exist or existed that was not disclosed above, we agree and authorize ${brandSelected.abbreviation} to allow us to remain on the ${brandSelected.abbreviation} program(s). Once notified, I will cancel said direct agreement within 5 business days and agree to repay any monies related to the undisclosed program(s).
        By signing this letter of authorization, I hereby authorize ${brandSelected.abbreviation} to contact all distribution companies listed within this participation agreement in order to obtain product level data and all other data relevant to the above identified Member which may include, without limitation, purchase history, inventory and stocking related information, unique and proprietary products and such other information required to manage Member’s supply chain. I hereby authorize ${brandSelected.abbreviation} to use such Member relevant data for the purposes of securing reporting, for the purposes of price audit and verification, securing pricing, volume allowances tracking and opportunity analysis and for any purpose directed by the Member. Member further agrees that such information may be released by ${brandSelected.abbreviation} to its designated rebate processor and ${brandSelected.name}, LLC. Additionally, I authorize ${brandSelected.abbreviation} to collect and distribute rebates on my behalf, to be paid quarterly. Furthermore, I acknowledge that ${brandSelected.abbreviation} will receive a fee for program administration and participation. Termination of membership shall only be effective upon ninety (90) days’ written notice from member. ${brandSelected.abbreviation} reserves the right to change the ${brandSelected.abbreviation} Membership Terms & Conditions at any time. The current ${brandSelected.abbreviation} Membership Terms & Conditions shall be available at www.buyesedgeplatform.com and it shall be Member’s responsibility to be aware of and comply with the ${brandSelected.abbreviation} Membership Terms & Conditions.`;

        const FsrTextLegal = 'I would like to be enrolled to earn rewards points through our partner Foodservice Rewards at no cost! By checking this box, I authorize my contact information to be provided to Foodservice Rewards to enroll me in the Foodservice Rewards’ Program and accept their terms and conditions found at <a target="_blank" style="color: black;" href="https://tinyurl.com/fsrterms">https://tinyurl.com/fsrterms</a>. Foodservice Rewards will contact you to complete your enrollment process.'
        
        const gpoNameOptions = [{label: `Add: ${customGPOName || '<Type to add custom name>'}`, value: customGPOName},...config.gpoNameOptions]
        const showLOTTerms = gpoName !== "" && isGpo === 1;
        const LOTLegalText = this.LOTLegalText();

        return (
            <GridCol>
                <GridRow>
                    <GridCol>
                        <InputLabel text='Legal Information' title className='title'/>                        
                    </GridCol>
                </GridRow>
                <GridRow className='line-form' style={{alignItems:'flex-end'}}>
                    <GridCol className='form-group'>
                        <InputLabel text='Are you a Member of GPO?'/>
                        <DropDownList title={isGpo ? 'Yes' : 'No'}>
                            <DropDownListOption title='Yes' value={true} onClick={selectOptionMemberGPO} />
                            <DropDownListOption title='No' value={false} onClick={selectOptionMemberGPO} />
                        </DropDownList>
                    </GridCol>
                    <GridCol className='form-group' maxWidth='203px'>
                        <InputLabel text='Termination Date'/><br/>
                        <DatePicker locale='en' popperPlacement="bottom-end" selected={isGpo ? startDate : null} dateFormat="MM/dd/yyyy" onChange={this.changeDate} disabled={!isGpo} placeholderText={isGpo ? 'Select' : 'This is disbaled'} />
                    </GridCol>
                    <GridCol className='form-group'>
                        <InputLabel text='Group Purchasing Organization *'/>
                        <DropDownListSelect
                            ref='gpoName' value={(gpoName && {label: gpoName, value: gpoName} )|| ''} options={gpoNameOptions}
                            onInputChange={createGPOName} onChange={selectOptionGPOName}
                            isDisabled={!isGpo} isSearchable={true} 
                        />
                    </GridCol>
                </GridRow>
                <GridRow className='line-form'>
                    <GridCol className='form-group'>
                        <InputLabel text='Distributor MFG Agreements'/>
                        <DropDownList title={isDistributorManufacturerAgreement ? 'Yes' : 'No'} >
                            <DropDownListOption title='Yes' value={true} onClick={selectOptionMFGAgreements} />
                            <DropDownListOption title='No' value={false} onClick={selectOptionMFGAgreements} />
                        </DropDownList>
                    </GridCol>
                    <GridCol className='form-group'>
                        <InputLabel text="Direct MFG Agreements"/>
                        <DropDownListSelect ref='nameOfCompaniesSelect'
                        value={nameOfCompanies} options={manufacturersOptions}
                        onChange={nameOfCompaniesHandler} isMulti onInputChange={this.searchManufacturers}
                        isDisabled={isDistributorManufacturerAgreement ? false : true} />
                    </GridCol>
                </GridRow>

                <GridRow className='line-form'>
                    <GridCol>
                        <GridRow className='check'>
                            <GridCol className={isMobile ? 'col-2' : 'col-1'}>
                                <Checkbox colorClassName='blue' name='isAuthorized' value={isAuthorized} text='' onChange={authorizationChecked} className={isAuthorizedInvalid ? 'invalid' : ''} />
                            </GridCol>
                            <GridCol className={'col' + (isMobile ? '' : ' pl-2 checked-text') + (isAuthorizedInvalid ? ' text-red' : '')} style={{fontSize:'12px'}}>
                                Acknowledgement, Authorization and Release
                            </GridCol>                    
                        </GridRow>
                        
                    </GridCol>
                </GridRow>
                <GridRow className='line-form'>
                    <GridCol className='form-group'>
                        <TextArea readOnly className={'col-12 signup-authorized'} col={4} rows={10} defaultValue={textLegal} />
                    </GridCol>                    
                </GridRow>
                {showLOTTerms &&
                    <>
                        <GridRow className='line-form'>
                            <GridCol>
                                <GridRow className='check'>
                                    <GridCol className={isMobile ? 'col-2' : 'col-1'}>
                                        <Checkbox name='isAuthorized' 
                                        value={isLOTAuthorized} text='' 
                                        onChange={authorizationLOTChecked} 
                                        className={isLOTAuthorizedInvalid ? 'invalid' : ''} />
                                    </GridCol>
                                    <GridCol className={'col' + (isMobile ? '' : ' pl-2 checked-text') + (isLOTAuthorizedInvalid ? ' text-red' : '')} style={{ fontSize: '12px' }}>
                                        Acknowledgement, Authorization and Release
                                    </GridCol>
                                </GridRow>
                            </GridCol>
                        </GridRow>
                        <GridRow className='line-form'>
                            <GridCol className='form-group'>
                                <div className={'col-12 signup-authorized-div'}>
                                    {ReactHtmlParser(LOTLegalText)}
                                </div>
                            </GridCol>
                        </GridRow>
                    </>
                }
                <GridRow>
                    <GridCol className='mt-2'>
                        <InputLabel text='Confirm your name and signature' title className='title'/>
                    </GridCol>                    
                </GridRow>
                <GridRow className='line-form'>
                    <GridCol className='form-group'>
                        <InputLabel text='Your Full Name *'/>
                        <Input type='text' placeholder='Full Name' ref='signedText' name='signedText' value={signedText} onChange={changeHandler} validate min={3} max={100} />
                    </GridCol>
                    <GridCol className='form-group'>
                        <InputLabel text='Signed by *'/><br/>
                        <span className='signup-signed'>{signedText}</span>                     
                    </GridCol>
                </GridRow>

                <GridRow className='line-form'>
                    <GridCol>
                        <GridRow className='check'>
                            <GridCol className={isMobile ? 'col-2' : 'col-1'}>
                                <Checkbox colorClassName='blue' name='isAuthorized' value={isFsrAuthorized} text='' onChange={authorizationFsrChecked} className={isFsrAuthorizedInvalid ? 'invalid' : ''} />
                            </GridCol>
                            <GridCol className={'col' + (isMobile ? '' : ' pl-2 checked-text') + (isFsrAuthorizedInvalid ? ' text-red' : '')} style={{fontSize:'12px'}}>
                                Reward Program Enrollment, Acceptance and Authorization
                            </GridCol>                    
                        </GridRow>
                    </GridCol>
                </GridRow>
                <GridRow className='line-form'>
                    <GridCol className='form-group'>
                        <div className={'col-12 signup-authorized-div'}>
                            { ReactHtmlParser(FsrTextLegal)}
                        </div>
                    </GridCol>                    
                </GridRow>
                
                <GridRow className='line-form'>
                    <GridCol className='form-group'>
                        <Button type='button' variant='white blue-border blue-color' size='' text='BACK' onClick={this.back}/>
                    </GridCol>
                    <GridCol className='form-group'>
                        <Button type='button' variant='blue blue-border blue-bg' size='' text='DONE' onClick={this.done}/>
                    </GridCol>
                </GridRow>
                <GridRow className='line-form'>
                    <GridCol className='col form-group d-flex align-items-cente'>
                        <InputLabel className='text-red text-size-14'>{error}</InputLabel>
                    </GridCol>  
                </GridRow>
            </GridCol>
        );
    };
}

export default FormExistingUserStep2;