import React, { Component } from 'react';
import DistributorListContent from './DistributorListContent';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import DistributorListHeader from './DistributorListHeader';
class DistributorList extends Component {

  render() {
    const { locationName, companyColors, dispatch, companyId, merchantAll, merchantGroup, history, refresh } = this.props;
    const propsLocation = {
      locationName,
      dispatch,
      companyColors,
      companyId,
      merchantAll,
      history,
      refresh
    }

    const merchantGroupMap = merchantGroup.map((m, i) => (
      <DistributorListContent key={i} {...propsLocation} locationName={m.nickname } merchant={m} merchantDistributors={m.merchantDistributors} merchantAll={merchantAll} />
    ));

    return (
      <GridRow className='m-3 bg-white'>
        <GridCol className='border'>
          <DistributorListHeader />
          <GridRow>
            { merchantGroupMap }
          </GridRow>
        </GridCol>
      </GridRow>
    )
  }
}

export default DistributorList;