import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Form, FormGroup, Label, Button } from 'reactstrap';
import { IoMdCloudUpload } from 'react-icons/io';
import { AiFillFile } from 'react-icons/ai';
import { MdOutlineClose } from 'react-icons/md';
import { isMobile } from 'react-device-detect';
import DashedBox from '../../../MasterComponents/DashedBox/DashedBox';
import useInvoiceScanningModal from '../hooks/useInvoiceScanningModal';
import DropDownListSelect from '../../../MasterComponents/DropDownList/DropDownListSelect';
import Input from '../../../MasterComponents/Input/Input';
import ProgressBarInvoice from './ProgressBarInvoice';
import Modal from '../../../MasterComponents/Modals/Modal';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';

const UploadInvoiceModal = (props) => {
  const filesArray = useSelector(({invoice}) => invoice.filesArray);
  const isSendDisbled = useSelector(({invoice}) => invoice.isSendDisbled);
  const { propsModal } = props;
  const { optionsLocationModal } = propsModal;
  const { fileInput, onClickUpload, onChangeFileInput, onChangeLocation, onClickDelete, onClickSend, onChangeName, onClose, invoiceName, locationModal, BYTES_PER_MB} = useInvoiceScanningModal(props);
  const [validOption, setValidOption] = useState(true);
  const submitFiles = () => {
    if(locationModal === null){
      setValidOption(false)
      return;
    }
    if(invoiceName === '') {
      return;
    }
    onClickSend();
  };

  const filesMap = filesArray.map((file, i) => {
    const fileSizeInBytes = file.size;
    const fileSizeInMb = fileSizeInBytes / (BYTES_PER_MB);
    return (
      <div key={`up_${i}`} className='my-2'>
        <Col className='py-2 px-2 border rounded-lg'>
          <Row>
            <Col className='mw-fc pr-1'>
              <AiFillFile size={20} color={'gray'} />
            </Col>
            <Col className='text-size-16 line-height-19 px-0'>
              {file.name}
            </Col>
            <Col onClick={()=>onClickDelete(i)} className={`clickable mw-fc ${isMobile ? 'px-3': ''}`}>
              <MdOutlineClose color='gray' size={20} />
            </Col>
          </Row>
          <Row>
            <Col className={`mw-fc ${isMobile ? 'pl-2': 'pl-4'}`} xs={1}></Col>
            <Col xs={11} className={`text-size-12 line-height-14  py-1 ${isMobile ? '': 'px-0'}`}>
              {fileSizeInMb.toFixed(2)} MB
            </Col>
          </Row>
          <Row>
            <Col>
              <ProgressBarInvoice done="100"/>
            </Col>
          </Row>
        </Col>
      </div>
    )
  });
  
  return (
    <Modal id='idUploadInvoiceModal' size='lg' fullHeight={isMobile ? true : false}>
      <div className='pt-3 pb-2 px-4 d-flex'>
        <Col xs={11} className='px-0'>
          Upload Invoice
        </Col>
        <Col xs={1} className='p-0 d-flex justify-content-end clickable' onClick={onClose}>
          <MdOutlineClose color='gray' size={22} />
        </Col>
      </div>
      <ModalBody className='py-0 px-4 border'>
        <Form>
          <FormGroup className='my-0'>
            <Row className='pt-3'>
              <Col xs={6}>
                  <Col xs={12} className='px-0 text-size-12 line-height-16'>
                    <Label> Invoice Name </Label>
                  </Col>
                  <Input type='text' name='name' value={invoiceName} onChange={onChangeName} validate min={3} max={100} />
              </Col>
              <Col xs={6}>
                  <Col xs={12} className='text-size-12 line-height-16 px-0'>
                    <Label> Location </Label>
                  </Col>
                  <DropDownListSelect
                    name='location'
                    value={locationModal} onChange={onChangeLocation}
                    options={optionsLocationModal}
                    className={`${ validOption ? '' : 'is-invalid'}`}
                    />
              </Col>
            </Row>
            <Row>
              <Col className='m-3 py-3'>
                <input ref={fileInput} type="file" multiple className='d-none' onChange={onChangeFileInput} />
                <DashedBox height={`${isMobile ? '258px': '218px'}`}>
                  <Col xs={12}>
                    <IoMdCloudUpload size={38} color={'gray'} />
                  </Col>
                  <Col xs={12} className='py-3' style={{color: '#060D21'}}>
                    Click the "Upload files" button or drag and drop on desktop
                  </Col>
                  <Col xs={12} style={{color: '#060D21'}}>
                    Support: PDF, JPG, PNG files max size 10mb
                  </Col>
                  <Col className='d-flex justify-content-center py-3'>
                    <Button active block className='py-2 shadow-none' size='md' style={{height: '40px'}} color='primary'onClick={onClickUpload} >UPLOAD FILES</Button>
                  </Col>
                </DashedBox>
              </Col>
            </Row>
            <Row className='scrollbar-custom' style={{maxHeight: '320px', overflowX:'hidden', overflowY:'auto'}}>
              <Col>
                {filesMap}
              </Col>
            </Row>
            {!isMobile && 
              <Row className='py-2 border-top'>
              { filesMap.length > 0 &&
                <Col className='d-flex justify-content-end py-1'>
                  <Button active block className='py-2 shadow-none' size='sm' style={{height: '35px'}} color='primary'onClick={submitFiles} disabled={isSendDisbled} >SEND FILES</Button>
                </Col>
              }
              </Row>
            }
          </FormGroup>
        </Form>
        { isMobile && 
          <Row className='py-2 px-4 fixed-bottom bg-white'>
            { filesMap.length > 0 &&
              <Col className='d-flex justify-content-end py-1 px-2'>
                <Button active block className='py-2 shadow-none' size='sm' style={{height: '35px'}} color='primary'onClick={submitFiles} disabled={isSendDisbled} >SEND FILES</Button>
              </Col>
            }
          </Row>
        }
      </ModalBody>
    </Modal>
  )
};

export default UploadInvoiceModal;