import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconCircle = (props)=>(
    <svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Designs" stroke="none" fill="none">
        <g id="2---Sign-Up---Step-1" transform="translate(-722.000000, -344.000000)" fill={!props.isActive ? props.color : props.activeColor}>
            <g id="input" transform="translate(580.000000, 344.000000)">
                <g id="icons/info" transform="translate(142.000000, 0.000000)">
                    <path d="M11.9733333,5.92 C11.928,2.62133333 9.21866667,-0.016 5.92,0.0266666667 C2.62133333,0.0693333333 -0.016,2.78133333 0.0266666667,6.08 C0.072,9.37866667 2.78133333,12.016 6.08,11.9733333 C9.37866667,11.928 12.0186667,9.21866667 11.9733333,5.92 Z" id="Shape"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
);


IconCircle.propTypes = {
    color: PropTypes.string,
    activeColor: PropTypes.string,
    isActive: PropTypes.bool,
    size: PropTypes.number,
  };
  
  IconCircle.defaultProps = {
    activeColor: config.colors.rbYellow,
    color: config.colors.rbWhite,
    isActive: false,
    size: 22,
  };
  
  export default IconCircle;