import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BsBell } from 'react-icons/bs';
import { IoMdArrowDropdown, IoMdMenu } from 'react-icons/io';
import { updateSidebar, destroySidebar, showSidebar } from '../../MasterComponents/Sidebar/reducer/SidebarAction';
import { setSettingsOpen } from './DashboardActions';
import GridCol from '../../MasterComponents/Grid/GridCol';
import GridRow from '../../MasterComponents/Grid/GridRow';
import LogoDashboardCompany from '../../MasterComponents/Logos/LogoDashboardCompany';
import DashboardTopbarMoreOptions from './DashboardTopbarMoreOptions';
import Notification from '../Notification/Notification';
import { getIconBellColor, getTextColor, getTopBarDropdownIconColor, getTopBarHamburguerIconColor, getNotificationStyles, getTopbarStyle } from '../../config/Company/CompanyHelper';
class DashboardTopbarLogo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationOpen: false,
    };
  };
  closeNotifications = (id) => {
    const { dispatch } = this.props;
    const { notificationOpen } = this.state;
    dispatch(updateSidebar(id, {isOpen: notificationOpen}));
    setTimeout(() =>{
      dispatch(destroySidebar(id));
    }, 300);
  };
  showNotifications = () => {
    const { dispatch, sidebars } = this.props;
    const { notificationOpen } = this.state;

    const notificationSidebar = sidebars.filter(sidebar => sidebar.props.isNotification)[0];

    if (notificationSidebar) {
      this.closeNotifications(notificationSidebar.id)
    } else {
      const modalProps = {
        titleHeader: 'Notifications',
        isNotification: true,
        showHeader: true,
        isOpen: notificationOpen,
        onClose: this.closeNotifications,
        width: '380px',
        from: 'right'
      };
      dispatch(showSidebar(Notification, modalProps));
    }
  };
  toggleOptions = () => {
    const { moreSettingsOpen, dispatch } = this.props;
    setTimeout(() => {
      dispatch(setSettingsOpen(!moreSettingsOpen));
    }, 200);
  };
  clickLink = () => {
    const { dispatch, onClick } = this.props;
    if (onClick)
      onClick();
    dispatch(setSettingsOpen(false));
  }
  render() {
    const {
      activeMerchant, notificationCounter, history,
      moreSettingsOpen, hamburgerMenu, openSidebar, companyId
    } = this.props;
    const topbarStyle = getTopbarStyle(companyId);
    const textColor = getTextColor(companyId);
    const notificationStyles = getNotificationStyles(companyId);
    const topBarHamburguerIconColor = getTopBarHamburguerIconColor(companyId);
    const iconBellColor = getIconBellColor(companyId);
    const topBarDropdownIconColor = getTopBarDropdownIconColor(companyId);

    return (
      <GridCol>
        <GridRow className='pt-3 px-4 pb-2' style={topbarStyle}>
          {
            !!hamburgerMenu &&
              <GridCol className='mw-fc p-0 clickable' onClick={openSidebar}>
                <IoMdMenu size={22} color={topBarHamburguerIconColor} />
              </GridCol>
          }
          <GridCol className='mw-fc'>
            <LogoDashboardCompany style={{height: '35px', maxWidth: '220px'}} />
          </GridCol>
          <GridCol/>
          <GridCol className='mw-fc clickable' onClick={this.showNotifications}> 
            <div className='notification-icon'>
              <div className='notification-count' style={notificationStyles}>{notificationCounter}</div>
              <BsBell size={17} color={iconBellColor} />
            </div>
          </GridCol>
          <GridCol className={`nickname clickable mw-fc text-size-13 text-capitalize ${textColor}`}
            onClick={this.toggleOptions}>
            {(activeMerchant && activeMerchant.nickname && `${activeMerchant.nickname} `) || "..."}
            <IoMdArrowDropdown size={24} color={topBarDropdownIconColor} />
          </GridCol>
          { moreSettingsOpen &&  
            <DashboardTopbarMoreOptions clickLink={this.clickLink} hide={this.toggleOptions} history={history} />
          }
        </GridRow>
      </GridCol>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    activeMerchant: state.dashboard.activeMerchant,
    moreSettingsOpen: state.dashboard.moreSettingsOpen,
    notificationCounter: state.notification.notificationCounter,
    sidebars: state.sidebar.sidebar,
    companyId: state.company.id
  };
};

export default connect(mapStateToProps)(DashboardTopbarLogo);

