import NotificationApi from '../NotificationApi';
import { useDispatch } from 'react-redux';
import {notificationSetData, notificationSetCounter} from '../Reducer/NotificationAction';

const useApi = () => {
  const dispatch = useDispatch();
  
  const getNotifications = () => {
    NotificationApi.getNotifications().then(({data}) => {
      const noti = data.data;
      const countNotRead = noti.filter(n => n.isRead === 0).length;
      dispatch(notificationSetData(noti));
      dispatch(notificationSetCounter(countNotRead));
    }).catch(err => {
      console.error(err);
    });
  }

  const readNotification = (id) => {
    const params = {
      is_read: 1
    }
    NotificationApi.readNotification(id, params).then(({data}) => {
      getNotifications();
    }).catch(err => {
      console.error(err);
    });
  }

  const deleteNotification = (id) => {
    NotificationApi.deleteNotification(id).then(({data}) => {
      getNotifications();
    }).catch(err => {
      console.error(err);
    });
  }

  return {
    getNotifications,
    readNotification,
    deleteNotification
  }
  
}

export default {
  useApi
};
