import React, { Component } from 'react';
import './Login.scss';
import LoginSidebar from './LoginSidebar';
import LoginContent from './LoginContent';
class Login extends Component {
       render() {
        const url = '/login/';
        const token  = this.props.location.pathname.substring(url.length);

        return (            
            <div className='container-login'>
                <LoginSidebar history={this.props.history} token={token}/>
                <LoginContent history={this.props.history} token={token}/>
            </div>
        )
    }
}

export default Login;