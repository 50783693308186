import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import '../FoodBam/FoodBamSignUp.scss';
import FoodBamAddDistributors from './FoodBamAddDistributors';
import FoodBamAgreement from './FoodBamAgreement';
import FoodBamPaymentDetails from './FoodBamPaymentDetails';
import FoodBamApi from './FoodBamApi';

class FoodBamSignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 1,
      errorMessage: ''
    };
  }

  scrollTop = () => {
    window.scroll({top: 0, left: 0, behavior: 'smooth' });
  }

  goToStep = (step) => {
    this.scrollTop();
    this.setState({ currentStep: step });
  }

  goToAgreement = (merchants) => {
    this.setState({ merchants });
    this.goToStep(2);
  }

  goToPaymentDetails = (signature) => {
    this.setState({ signature });
    this.goToStep(3);
  }

  goToFoodbamFrame = (token) => {
    const { merchants, signature } = this.state;
    const payload = {
      external_user_id: localStorage.getItem('userid'),
      external_user_email: localStorage.getItem('email'),
      external_user_phone: localStorage.getItem('phoneNumber'),
      external_user_name: localStorage.getItem('contactName'),
      merchants,
      signature,
      stripe_token: token
    };

    this.scrollTop();

    FoodBamApi.foodBamMydaRegister(payload)
      .then(() => {
        localStorage.setItem('isFoodbamActive', '1');
        this.props.history.push('/dashboard/foodBAM');
      })
      .catch(() => {
        this.setState({ errorMessage: 'Error, please try again later.' });
      });
  }

  currentStep() {
    const { currentStep, errorMessage } = this.state;
    switch (currentStep) {
      case 2:
        return <FoodBamAgreement nextStepHandler={this.goToPaymentDetails} />;
      case 3:
        return <FoodBamPaymentDetails nextStepHandler={this.goToFoodbamFrame} errorMessage={errorMessage} />;
      default:
        return <FoodBamAddDistributors nextStepHandler={this.goToAgreement} />;
    }
  }

  render() {
    return (
      <Fragment>
        { this.currentStep() }
      </Fragment>
    );
  }

}

const mapStateToProps = state => {
  return {};
}

export default connect(mapStateToProps)(FoodBamSignUp);
