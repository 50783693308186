import React from 'react';
import { Row, Col } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import FoodCostNavbarItem from './FoodCostNavbarItem'

const FoodCostNavbar = (props) => {
  const { dataView, buttonText, onClickNav, onClickGetStarted } = props;
  if(isMobile) {
    return (
      <Row className='py-3 text-size-16 line-height-24'>
      { dataView.map((d, i)=> (
        <FoodCostNavbarItem 
          key={i}
          data={d}
          {...d}
          onClickNav={onClickNav}
          onClickGetStarted={onClickGetStarted}
          buttonText={buttonText}
        />
        ))
      }
    </Row>
    )
  }
  return (
    <Col className='py-3 text-size-16 line-height-24'>
      { dataView.map((d, i)=> (
        <FoodCostNavbarItem 
          key={i}
          data={d}
          {...d}
          onClickNav={onClickNav}
          onClickGetStarted={onClickGetStarted}
          buttonText={buttonText}
        />
        ))
      }
    </Col>
  )
};
export default FoodCostNavbar;