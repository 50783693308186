import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { showModal } from '../../MasterComponents/Modals/ModalsActions';
import { spinner } from '../../MasterComponents/Spinner/SpinnerActions';
import GridRow from '../../MasterComponents/Grid/GridRow';
import GridCol from '../../MasterComponents/Grid/GridCol';
import LearnMoreModal from '../PrimePrograms/Modals/LearnMoreModal';
import SignUpModal from '../PrimePrograms/Modals/SignUpModal';
import Button from '../../MasterComponents/Button/Button';
import PrimeProgramsApi from './PrimeProgramsApi';
import ReactPixel  from 'react-facebook-pixel';
import PrimeProgramsAd from './PrimeProgramsAd';
import getDistributorBP, { programDataDefault } from './PrimeProgramsData';
import { getPrimeBgCard, getPrimeBgOverlay, getPrimeCardHoverHeaderClass, getPrimeCornerCutStyle, getPrimeFooterClass, getPrimeHasCornerCut, getPrimeLeftButtonClass, getPrimeLeftButtonVariant, getPrimeRightButtonClass, getPrimeRightButtonVariant } from '../../config/Company/CompanyHelper';
class PrimeProgramsBody extends Component {

    constructor(props) {
        super(props);
        this.getDistributorBP = getDistributorBP.bind(this);
    }

    componentDidMount = () => {
        const { displayBeyondPrime, companyId, manufacturerAd } = this.props;
        if(displayBeyondPrime){
            this.scrollTop();
            this.getDistributorBP(companyId, manufacturerAd.length > 0, true);
        } else {
            window.history.back();
        }
    }

    scrollTop = () => {
        window.scroll({top: 0, left: 0, behavior: 'smooth' });
    }

    goToLearnMore = (data) => {
        const { dispatch } = this.props;
        if (process.env.REACT_APP_ENVIRONMENT === 'production') {
            if (data.name === 'FattMerchant') {
                ReactPixel.pageView();
            }
        }
        if(data.pdf !==''){
            dispatch(showModal(LearnMoreModal, 'idLearnMoreModal', { pdf : data.pdf }));
        }
    }

    goToSignUp = (data) => {
        if (process.env.REACT_APP_ENVIRONMENT === 'production') {
            if (data.name === 'FattMerchant') {
                ReactPixel.pageView();
            }
        }
        if(data.id !== '') {
            const merchant = this.props.activeMerchant;
            const params = {
                distId :  data.id
            }
            if(merchant) {
                this.addDistributorBP(merchant.id, params);
            }
        }
    }

    goToUrl = (data) => {
        if(data.url !==''){
            window.open(data.url, '_blank');
        }
    }

    addDistributorBP = (id, params)=> {
        const { dispatch } = this.props;
        dispatch(spinner(true));
        PrimeProgramsApi.addDistributorBP(id, params).then((res)=> {
            dispatch(spinner(false));
            dispatch(showModal(SignUpModal, 'idSignUpModal'));
        }).catch(function() {
            dispatch(spinner(false));
        });
    }

    render() {
        const { companyId, manufacturerAd, activeMerchant, companyBrand, primeAllUpdated, isSliced } = this.props;
        const buttonTextLeft = (companyId === 3 || companyId === 13 || companyId === 12 ) ? 'Details' : 'Learn More';
        const buttonTextRight = (companyId === 3 || companyId === 13 || companyId === 12 ) ? 'Learn More' : 'Sign Up';
        const primeBgCard = getPrimeBgCard(companyId);
        const primeBgOverlay = getPrimeBgOverlay(companyId);
        const hasCorner = getPrimeHasCornerCut(companyId);
        const primeCornerCutStyle = getPrimeCornerCutStyle(companyId);
        const primeCardHoverHeaderClass = getPrimeCardHoverHeaderClass(companyId);
        const primeFooterClass = getPrimeFooterClass(companyId);
        const primeLeftButtonClass = getPrimeLeftButtonClass(companyId);
        const primeRightButtonClass = getPrimeRightButtonClass(companyId);
        const primeLeftButtonVariant = getPrimeLeftButtonVariant(companyId);
        const primeRightButtonVariant = getPrimeRightButtonVariant(companyId);
        const adProps = {
            manufacturerAd,
            activeMerchant,
            companyBrand
        }
        let primeArr = isSliced ? primeAllUpdated.slice(0, 4) : primeAllUpdated;
        const partnersMap = primeArr.map((p, i) => {
            const hoverStyleBody = p.card ? p.card.cardHover.styleBody : programDataDefault[0]['cardHover']['styleBody'];
            const mainStyleBody = p.card ? p.card.cardMain.styleBody : programDataDefault[0]['cardMain']['styleBody'];
            const hoverStyleTitle = p.card ? p.card.cardHover.styleTitle : programDataDefault[0]['cardHover']['styleTitle'];
            const hoverStyleLogo = p.card ? p.card.cardHover.styleLogo : programDataDefault[0]['cardHover']['styleLogo'];
            const mainHeader = p.mainHeader ? p.mainHeader : '';
            const mainFooter = p.mainFooter ? p.mainFooter : '';
            const mainTitle = p.mainTitle ? p.mainTitle : '';
            const hoverTitle = p.hoverTitle ? p.hoverTitle: '';
            const hoverBody = p.hoverBody ? p.hoverBody : '';
            return (
            <GridCol key={i} className='px-0' size={isMobile ? 12 : 3}>
                {  isMobile ?
                    ( (i !== 2 || manufacturerAd.length === 0) ?
                        <GridCol className={'bounds-mobile '} style={{backgroundImage: primeBgCard}}>
                            <div className='corner-cut-mobile' style={{ borderTopColor: primeCornerCutStyle }}></div>
                            <GridCol className='pl-3 text-left text-uppercase text-white' style={{position:'absolute', top: '12px'}}>{mainHeader}</GridCol>
                            <GridCol size={12} className='col-12'>
                                <img src={p.logo} alt='Partner Logo' style={hoverStyleLogo}  />
                            </GridCol>
                            <GridCol className={'col-12 text-uppercase text-white '} style={hoverStyleTitle}>{mainTitle}</GridCol>
                            <GridCol className={'text-white ' + primeCardHoverHeaderClass} onClick={this.goToUrl.bind(this, p)} style={hoverStyleBody} >{hoverBody}</GridCol>
                            <GridCol style={{position:'absolute', bottom: '10px'}}>
                                <GridRow className='w-100'>
                                    <GridCol>
                                        <Button type='submit' className={primeLeftButtonClass} variant={'primary'} text={buttonTextLeft} onClick={this.goToLearnMore.bind(this, p)} />
                                    </GridCol>
                                    <GridCol>
                                        <Button type='submit' className={primeRightButtonClass} variant={'white'} text={buttonTextRight} onClick={this.goToSignUp.bind(this, p)} />
                                    </GridCol>
                                </GridRow>
                            </GridCol>
                        </GridCol>
                        :
                        <GridCol className='img-ad px-0'>
                            { manufacturerAd.length > 0 &&
                                <PrimeProgramsAd {...adProps} />
                            }
                        </GridCol>
                    )
                    :
                    ( (i !== 2 || manufacturerAd.length === 0) ?
                        <GridCol className='bounds ' style={{backgroundImage: primeBgCard}}>
                            { hasCorner && <div className='corner-cut'></div>}
                            <GridCol className='card-main-header pl-3 text-left text-uppercase' style={{ whiteSpace: 'nowrap'}}>{mainHeader}</GridCol>
                                <img src={p.logo} alt='Partner Logo' style={mainStyleBody} />
                            <GridCol className={'prime-card-main-footer text-right ' + primeFooterClass}>{mainFooter}</GridCol>
                            <GridCol className='overlay-prime ' style={{zIndex:11, backgroundImage: primeBgOverlay}}>
                                <GridCol className='pl-3 pr-3 text-center'>
                                    <img src={p.logo} alt='Partner Logo' style={hoverStyleLogo}  />
                                </GridCol>
                                <GridRow className='container' style={{position:'absolute', top: '20px'}}>
                                    <GridCol className={'prime-card-hover-header text-uppercase mt-3 text-center pl-5 ' + primeCardHoverHeaderClass} style={hoverStyleTitle}>{hoverTitle}</GridCol>
                                    <GridCol className={'text-prime clickable mt-2 pl-4 text-size-10' } onClick={this.goToUrl.bind(this, p)} style={hoverStyleBody}>{hoverBody}</GridCol>
                                </GridRow>
                                <GridRow  style={{position:'relative', top: '-65%'}}>
                                    <GridCol className=''>
                                        <Button type='submit' className={primeLeftButtonClass} variant={primeLeftButtonVariant} text={buttonTextLeft} onClick={this.goToLearnMore.bind(this, p)} />
                                    </GridCol>
                                    <GridCol className=''>
                                        <Button type='submit' className={primeRightButtonClass} variant={primeRightButtonVariant} text={buttonTextRight} onClick={this.goToSignUp.bind(this, p)} />
                                    </GridCol>
                                </GridRow>
                            </GridCol>
                        </GridCol>
                     :
                     <GridCol className='img-ad px-0'>
                         { manufacturerAd.length > 0 &&
                            <PrimeProgramsAd {...adProps} />
                         }
                     </GridCol>
                    )
                }
            </GridCol>
            );
        });

        return (
             <GridRow className='pt-0 '>
                {partnersMap}
            </GridRow>
        );
    }
}

const mapStateToProps = state => {
    return {
        companyId: state.company.id,
        displayBeyondPrime: state.company.displayBeyondPrime,
        manufacturerAd: state.location.manufacturerAd,
        activeMerchant: state.dashboard.activeMerchant,
        companyBrand: state.company.companyBrand,
        primeAll: state.prime.primeAll,
        primeAllUpdated: state.prime.primeAllUpdated
    };
}

export default connect(mapStateToProps)(PrimeProgramsBody);
