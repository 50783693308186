import React from 'react';
import { Button, Col, InputGroup, Input, Label, Row } from 'reactstrap';
import DropDownListSelectCustom from '../../../MasterComponents/DropDownList/DropDownListSelectCustom';
import { MdEdit } from 'react-icons/md';
import byGoogle from '../../../assets/images/powered-by-google.png';
import DropDownListSelect from '../../../MasterComponents/DropDownList/DropDownListSelect';
import InputMask from 'react-input-mask';

const JoinPlaces = (props) => {
	const {
		toggleButton, showAddressDetails, restaurantName, placesAutocomplete,
		phone, state, addressLine, city, zipcode, form
	} = props;
	const { invalid } = form;

	return (
		<>
			<Label className={invalid.some(i => i === 'restaurantName') ? 'text-danger' : ''}>
				Restaurant Name
			</Label>
			<InputGroup>
				{
					showAddressDetails ?
						<Input {...restaurantName} />
					:
						<DropDownListSelectCustom {...placesAutocomplete} />
				}
				<Button {...toggleButton} >
					<MdEdit />
				</Button>
			</InputGroup>
			<Label>
				<img className='w-25' src={byGoogle} alt='google' />
			</Label>
			{
				showAddressDetails &&
				<div className='pl-3'>
					<Label className={invalid.some(i => i === 'phone') ? 'text-danger' : ''}>
						Phone Number
					</Label>
					<InputMask mask='(999) 999-9999' {...phone}>
						{(inputProps) => <Input {...inputProps} />}
					</InputMask>
					<Label className={invalid.some(i => i === 'addressLine') ? 'text-danger' : ''}>
						Address Line *
					</Label>
					<Input {...addressLine} />
					<Label className={invalid.some(i => i === 'city') ? 'text-danger' : ''}>
						City *
					</Label>
					<Input {...city} />
					<Row>
						<Col xs={6}>
							<Label className={invalid.some(i => i === 'state') ? 'text-danger' : ''}>
								State *
							</Label>
							<DropDownListSelect {...state} />
						</Col>
						<Col xs={6}>
							<Label className={invalid.some(i => i === 'zipcode') ? 'text-danger' : ''}>
								Zipcode *
							</Label>
							<InputMask mask='99999' {...zipcode}>
								{(inputProps) => <Input {...inputProps} />}
							</InputMask>
						</Col>
					</Row>
				</div>
			}
		</>
	);
};

export default JoinPlaces;