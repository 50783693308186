import React, { Component } from 'react'
import { connect } from 'react-redux';
import GridRow from '../../MasterComponents/Grid/GridRow';
import GridCol from '../../MasterComponents/Grid/GridCol';
import Card from '../../MasterComponents/Card/Card';
import SearchInput from '../../MasterComponents/SearchInput/SearchInput';
import GridColSplit from '../../MasterComponents/Grid/GridColSplit';
import Button from '../../MasterComponents/Button/Button';
import DashboardAddNewDistributorLogo from './DashboardAddNewDistributorLogo';
import LogoHC from '../../MasterComponents/Logos/LogoHashicorp';
import LogoVA from '../../MasterComponents/Logos/LogoVault';
import IconLock from '../../MasterComponents/Icons/IconLock';
import config from '../../config/config';
import { isMobile } from 'react-device-detect';
import { spinner } from '../../MasterComponents/Spinner/SpinnerActions';
import { changePage } from './DashboardActions';
import DashboardApi from './DashboardApi';
import DistributorHouseModal from './Modals/DistributorHouseModal';
import { showModal } from '../../MasterComponents/Modals/ModalsActions';

class DashboardAddNewDistributor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      distributors: [],
      popularDistributors: [],
      loading: true,
      check: [],
      signedText : ''
    }
  }
  componentDidMount = () => {
    const { location, history, dispatch } = this.props;
    if (!location.state) {
      history.push('/dashboard');
    } else {
      this.getDistributors(null, 1);
      const section= {
        button: 'Rebates Portal',
        title: 'Add New Distributor'
      };
      dispatch(changePage(section));
    }
  }
  isNotPopular = (id) => {
    const { popularDistributors } = this.state;
    let found = false;
    popularDistributors.forEach(dist => {
      if (dist.id === id) {
        found = true;
      }
    });
    return !found;
  }
  getDistributorsPopular = () => {
    const { dispatch, displayPopularDistributors } = this.props;
    if (displayPopularDistributors) {
      DashboardApi.getDistributorsFixed().then(({ data }) => {
        dispatch(spinner(false));
        const result = data.data;
        this.setState({
          popularDistributors: result
        });
      });
    }
  }
  getDistributors = (text, page) => {
    const { dispatch, companyId } = this.props;
    const {distributors, check} = this.state;
    const saveData = (data) => {
      this.setState({
        distributors: [
          ...distributors,
          ...data.map(dist => {
            return {
              ...dist
            }
          })
        ],
        check: [
          ...check,
          ...data.map(dist => {
            return {
              id: dist.id,
              value: false,
              companyDistributorSensitive : dist.company_distributor_sensitive
            }
          })
        ]
      });
    }
    dispatch(spinner(true));
    DashboardApi.getDistributorsAll(companyId, text, page).then(({data, headers}) => {
        dispatch(spinner(false));
        saveData(data.data);
      if (headers['x-pagination-current-page'] !== headers['x-pagination-page-count']) {
        this.getDistributors(null, page+1);
      } else {
        this.getDistributorsPopular();
      }
    });
  }
  onChangeHandler = (value, name, e) => {
    this.setState({
      [name]: value.replace(/([.?*+^$[\]\\(){}|-])/g, "")
    });
  }

  save = () => {
    const { location, history, dispatch } = this.props;
    const { check, signedText } = this.state;
    const params = {
      signedText : signedText,
      distId: check.filter(c => c.value)[0].idParam,
      locations: location.state.map(loc => loc.id)
    };
    dispatch(spinner(true));
    DashboardApi.addDistributor(params).then(({data}) => {
      dispatch(spinner(false));
      history.push({
        pathname: '/dashboard/rebates/card',
        state: {
          action: 'ADD_DISTRIBUTOR',
          ...params
        }
      });
    });
  }

  saveDistributorHouse = () => {
    this.save();
  }

  cantSave = () => {
    const { check, distributors } = this.state;
    return distributors.filter(dist => check.filter( c => c.id === dist.id )[0].value ).length === 0 ? true : false;
  }
  onSelect = async (id) => {
    const { check, popularDistributors } = this.state;
    await this.setState({
      check: check.map((item) => {
        if (item.id === id) {
            return {
              ...item,
              idParam : id,
              value: !item.value,
              companyDistributorSensitive : item.companyDistributorSensitive
            }
        } 
        return {
          ...item,
          idParam : item.id,
          value : false,
          companyDistributorSensitive : item.companyDistributorSensitive
        }
      }),
      popularDistributors : popularDistributors.map(dist => {
        if (dist.id === id) {
          return {
            ...dist,
            value: !dist.value
          }
        }
        return {
          ...dist,
          value: false
        }
      })
    });
    let openModal = false;
    await this.state.check.forEach((item) => {
      if(item.value === true && item.id === id) {
        openModal = true;
      } 
    });
    if (openModal) {
      this.getDistributorsHouse(id);
    }
  }

  getDistributorsHouse = (id) => {
    const { dispatch, companyId } = this.props;
    this.setState({
        distributorsHouse: []
    });
    DashboardApi.getDistributorsHouse(companyId, id).then(({data}) => {
      const result = data.data;
      if(result.length > 0) {
        const resultMap = result.map(m => {
          return {
            label: m.nickname,
            value: m.id
          }
        })
        const resultMapHouse = resultMap.length === 1 ? [...resultMap] : [{ label: 'I dont know', value: id}, ...resultMap];
        this.setState({
            distributorsHouseSelected : null,
            distributorsHouse: resultMapHouse
        });
        dispatch(showModal(DistributorHouseModal, 'distributorHouseModalId', {
          distributorParent: this.state.distributors.filter(d => d.id === id)[0],
          distributorsHouse: this.state.distributorsHouse, 
          distributorsHouseSelected : this.state.distributorsHouseSelected, 
          onChangeDistributorHouse : this.onChangeDistributorHouse, 
          saveDistributorHouse : this.saveDistributorHouse,
          signedText : this.signedText,
          changeHandlerSignedText : this.changeHandlerSignedText
        }));
      }
    });
  }

  onChangeDistributorHouse = (option) => {
    const { check } = this.state;
    this.setState({
        distributorsHouseSelected : option,
        check: check.map((item) => {
          if (item.value === true) {
              return {
                ...item,
                idParam : option.value
              }
          } 
          return {
            ...item
          }
        })
    });
  };

  changeHandlerSignedText = (value) => {
    this.setState({
      signedText : value
    });
  }

  render() {
    const {search, popularDistributors, distributors, check} = this.state;
    const { displayPopularDistributors, title } = this.props;

    const StepTitle = props => (
      <GridRow className={ props.moreMargin ? 'py-2 pt-4' : 'py-2'}>
        <GridCol className='font-weight-bolder text-line-height-24'>
          {props.text}
        </GridCol>
      </GridRow>
    );

    const Step = props => (
      <GridRow className='pt-3'>
        <GridCol className='oval'>
          <GridRow className={ props.number === 3 ? 'm-0 no-gutters border-0' : 'm-0 no-gutters'}>
            {
              props.number === 3 ?
              <GridCol className='text-center' align='center'> <IconLock size={30} color={config.colors.rbBlue} /> </GridCol>
              :
              <GridCol className='text-center' align='center'> {props.number} </GridCol>
            }
          </GridRow>
        </GridCol>
        <GridCol className='text-grey text-line-height-20 text-size-14'>
          {props.text}
        </GridCol>
      </GridRow>
    );

    return (
      <GridRow className={isMobile ? 'py-3' : 'px-4 py-4'}>
        <Card size={ isMobile ? 'col-12' : 'col-9'}>
          <GridRow>
            <GridCol className='text-grey text-size-14'>
              Search For Distributor Name
            </GridCol>
            <GridColSplit className='mt-4' />
            <GridCol className='pr-0'>
              <SearchInput name='search' placeholder='Ex. Sysco' value={search} onChange={this.onChangeHandler} />
            </GridCol>
            <Button className='mx-3' variant='primary' text='Search' size='sm' />
          </GridRow>
          <GridRow className='pb-0'>
            { search === '' ?
              <GridCol className='text-grey text-size-14'>
                { displayPopularDistributors ? 'Or choose from these popular distributors' : 'Type to start searching...'}
              </GridCol>
              : null
            }
          </GridRow>
          <GridRow className={'add-distributors-list' + (isMobile ? ' mobile' : '')}>
            {
              search === '' ?
                distributors.filter(dist => check.filter( c => c.id === dist.id )[0].value && this.isNotPopular(dist.id)).map((dist) => (
                  <DashboardAddNewDistributorLogo value={check.filter( c => c.id === dist.id )[0].value} onChange={this.onSelect} key={dist.id} {...dist} />
                ))
              :
                distributors.filter(dist => check.filter( c => c.id === dist.id )[0].value && dist.nickname.toLowerCase().search(search.toLowerCase()) === -1).map((dist) => (
                  <DashboardAddNewDistributorLogo value={check.filter( c => c.id === dist.id )[0].value} onChange={this.onSelect} key={dist.id} {...dist} />
                ))
            }
            {
              search === '' ?
                popularDistributors.map((dist) => (
                  <DashboardAddNewDistributorLogo value={dist.value || false } onChange={this.onSelect} key={dist.id} {...dist} />
                ))
              :
                distributors.filter(dist => dist.nickname.toString().toLowerCase().search(search.toLowerCase()) >= 0).map((dist) => (
                  <DashboardAddNewDistributorLogo value={check.filter( c => c.id === dist.id )[0].value} onChange={this.onSelect} key={dist.id} {...dist} />
                ))
            }
          </GridRow>
          <GridRow className='pb-5'>
            <GridCol className='pb-2'>
              <Button text='Save' disabled={this.cantSave()} onClick={this.save} variant='primary' size='sm' />
            </GridCol>
          </GridRow>
        </Card>
        <GridCol>
          <StepTitle text='Two steps to add new distributor' />
          <Step number={1} text='Select a distributor to start your cashback process' />
          <Step number={2} text='Activate this distributor by adding your online credentials or manually uploading your purchase history' />
          <StepTitle text='Serious about security' moreMargin />
          <Step number={3} text={`All data shared on ${title} is certified for security best practices`} />
          <StepTitle text='Trusted by thousands of clients' moreMargin />
          <GridRow>
            <GridCol className='text-grey text-line-height-20 text-size-14'>
              Powered by
            </GridCol>
          </GridRow>
          <GridRow className='pt-2'>
            <GridCol align='center'><LogoHC /></GridCol>
            <GridCol align='center'><LogoVA /></GridCol>
          </GridRow>
        </GridCol>
      </GridRow>
    );
  }
};

const mapStateToProps = state => {
  return {
    locations: state.dashboard.merchants,
    companyId: state.company.id,
    displayPopularDistributors: state.company.displayPopularDistributors,
    title: state.company.title
  };
}

export default connect(mapStateToProps)(DashboardAddNewDistributor);