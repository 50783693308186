import React from 'react';
import { isMobile } from 'react-device-detect';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import IconArrowBack from '../../../MasterComponents/Icons/IconArrowBack';
import InputLabel from '../../../MasterComponents/InputLabel/InputLabel';

const Header = ({title, showBack, goBack}) => {
  if (isMobile) {
    return (
      <div className='header-main'>
        <div className='header-fixed home-header'>
          <GridCol className='pt-2'>
            <InputLabel text={title} title className='title' />
          </GridCol>
          {
            showBack &&
            <GridCol className='text-left position-absolute'>
              <div onClick={goBack} style={{width: '20px'}}>
                <IconArrowBack color='white' />
              </div>
            </GridCol>
          }
        </div>
      </div>
    );
  }

  return (
    <div className='container'>
      <GridRow>
        <GridCol className='text-size-36 font-weight-bold pt-5'>
          {title}
        </GridCol>
      </GridRow>
    </div>
  );
};

export default Header;