import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../MasterComponents/Modals/Modal';
import ModalHeader from '../../../MasterComponents/Modals/ModalHeader';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import DashedBox from '../../../MasterComponents/DashedBox/DashedBox';
import IconAdd from '../../../MasterComponents/Icons/IconAdd';
import config from '../../../config/config';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import IconDelete from '../../../MasterComponents/Icons/IconDelete';
import IconEdit from '../../../MasterComponents/Icons/IconEdit';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import { findModal, showModal } from '../../../MasterComponents/Modals/ModalsActions';
import DashboardApi from '../DashboardApi';
import { spinner } from '../../../MasterComponents/Spinner/SpinnerActions';
import Button from '../../../MasterComponents/Button/Button';
import { isMobile } from 'react-device-detect';
import ReactGA from 'react-ga';

class DeleteWarning extends Component {
  yes = () => {
    const { acceptDelete, id, modals } = this.props;
    acceptDelete(id);
    findModal(modals, 'deleteWarningModal').close();
  }
  cancel = () => {
    const { modals } = this.props;
    findModal(modals, 'deleteWarningModal').close();
  }
  render() {
    return(
      <Modal id='deleteWarningModal' centered>
        <ModalHeader>
          Are you sure you want to delete this location?
        </ModalHeader>
        <ModalBody>
          <GridRow>
            <GridCol>
              <Button onClick={this.cancel} variant='white' text='Cancel' />
            </GridCol>
            <GridCol>
              <Button onClick={this.yes} variant='primary' text='Delete' />
            </GridCol>
          </GridRow>
        </ModalBody>
      </Modal>
    )
  }
}

class CantDeleteWarning extends Component {
  render() {
    return(
      <Modal id='cantDeleteWarningModal' size='sm' centered>
        <ModalHeader>
          Can't delete active location.
        </ModalHeader>
        <ModalBody>
          <GridRow>
            <GridCol>
              <Button onClick={this.props.ok} variant='primary' text='Ok' />
            </GridCol>
          </GridRow>
        </ModalBody>
      </Modal>
    );
  }
}

class ManageLocationsModal extends Component {
  addNew = () => {
    const { goToAdd, modals } = this.props;
    findModal(modals, 'manageLocationsModal').close();
    goToAdd();
  }
  changeMerchant = (id) => {
    const { empty, refresh, modals, dispatch, InsightsManageLocations } = this.props;
    empty();
    dispatch(spinner(true));
    findModal(modals, 'manageLocationsModal').close();
    refresh(null, true);
    if(InsightsManageLocations) {
        this.props.InsightsManageLocations();
    }
  }
  acceptDelete = (id) => {
    const { empty, refresh, modals, dispatch } = this.props;
    empty();
    dispatch(spinner(true));
    ReactGA.event({
      category: 'Edit Location',
      action: 'Click Delete'
    });
    DashboardApi.deleteMerchant(id).then(res => {
      findModal(modals, 'manageLocationsModal').close();
      refresh();
    })
  }
  editMerchant = (id) => {
    const { history, modals } = this.props;
    ReactGA.event({
      category: 'Edit Location',
      action: 'Click Edit'
    });
    findModal(modals, 'manageLocationsModal').close();
    history.push(`/dashboard/edit-location/${id}`);
  }
  cantDelete = () => {
    const { modals } = this.props;
    findModal(modals, 'cantDeleteWarningModal').close();
  }
  deleteMerchant = (id) => {
    const { dispatch, activeMerchant } = this.props;
    if (activeMerchant.id === id) {
      dispatch(showModal(CantDeleteWarning, 'cantDeleteWarningModal', {ok: this.cantDelete}));
    } else {
      dispatch(showModal(DeleteWarning, 'deleteWarningModal', {id: id, acceptDelete: this.acceptDelete}));
    }
  }
  render() {
    const Location = props => (
      <GridRow className={'border text-size-14 mb-2' + (isMobile ? ' m-1 mb-3' : '')} align='center' style={{height: '60px'}} >
        <GridCol>
          <span onClick={props.onSelect.bind(this, props.id)} className='clickable'>{props.name}</span>
        </GridCol>
        <GridCol onClick={props.onEdit.bind(this, props.id)} className='clickable text-center' style={{maxWidth: '46px'}}>
          <IconEdit color={config.colors.rbGrey} />
        </GridCol>
        <GridCol onClick={props.onDelete.bind(this, props.id)} className='clickable text-center' style={{maxWidth: '46px'}}>
          <IconDelete color={config.colors.rbGrey} />
        </GridCol>
      </GridRow>
    );
    const locationsMap = this.props.locations.map((loc) => (
      <Location id={loc.id} key={loc.id} name={loc.nickname} onDelete={this.deleteMerchant} onEdit={this.editMerchant} onSelect={this.changeMerchant} />
    ))
    return (
      <Modal id='manageLocationsModal'>
        <ModalHeader closeButton={!isMobile} noBorder={isMobile}>Manage Locations</ModalHeader>
        <ModalBody>
          {locationsMap}
          <DashedBox onClick={this.addNew} className={'text-size-14 text-grey clickable' + (isMobile ? ' m-1' : '')} height='60px'>
            <IconAdd size={10} color={config.colors.rbDarkGrey} /> &nbsp; Add New Location
          </DashedBox>
        </ModalBody>
      </Modal>
    );
  }
};

ManageLocationsModal.propTypes = {
  goToAdd: PropTypes.func.isRequired
}

export default ManageLocationsModal;