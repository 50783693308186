import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Row, Col } from 'reactstrap';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import IconCircle from '../../../MasterComponents/Icons/IconCircle';
import config from '../../../config/config';
class ClaimCashGraph extends Component {

  showMore = () => {
    const { showMore } = this.props;
    showMore();
  }

  showLess = () => {
    const { showLess } = this.props;
    showLess();
  }
  
  render() {
    const { dataTop, dataAll, optInit } = this.props;

    const chartOptions = {
      chart: {
        height: '90%',
        backgroundColor: isMobile ? '#FFF' : '#F0F0F2'
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      series: [{
        type: 'pie',
        name: 'Locations',
        innerSize: '0%',
        showInLegend: false,
        data: dataTop
      }],
      tooltip: {
        valuePrefix: '$',
        backgroundColor: '#1A1A1A',
        borderColor: '#1A1A1A',
        borderRadius: 5,
        valueDecimals: 2,
        style: {
          color: 'white'
        },
        pointFormat: ' {point.y} ({point.percentage:.0f}%)</span>',
        formatter: function () {
          return this.key !== 'Hide' ? ' $' + this.y.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : false;
        }
      },
      plotOptions: {
        pie: {
          shadow: false,
          center: ['50%', '50%'],
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: false,
        }
      }
    }

    const arrayMap = dataTop.map((d, i) => {
      return (
        <Row className={ isMobile ? '' : 'py-1'} key={`item_${i}`}>
          <Col xs={8} className={isMobile ? '' : 'pl-2'}>
            <Row>
              <Col xs={1}>
                <IconCircle color={d.color} size={12} />
              </Col>
              <Col xs={10} className='px-3 text-grey-primary text-size-14 line-height-15'>
                {d.name}
              </Col>
            </Row>
          </Col>
          <Col xs={4} className={'text-grey-primary text-size-14 line-height-15 ' + (isMobile ? 'text-center' :'text-right')}>
            ${(d.y).toLocaleString('en', {minimumFractionDigits: 2})}
          </Col>
        </Row>
      )
    })


    return (
      <Row className={'py-3 ' + (isMobile ? '': 'pr-3')}>
        <Col xs={isMobile ? 12 : 5} style={{width: '60px', minWidth: '40%'}}>
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </Col>
        <Col xs={isMobile ? 12 : 7}  className='pt-4 scrollbar-custom' style={{maxHeight: '220px', overflowX:'hidden', overflowY:'auto'}}>
          {arrayMap}
          <Row>
            { dataAll.length > optInit ?
              <Col className='text-blue clickable pl-2 pt-1 text-size-14 line-height-15' onClick={this.showMore}>
                Show more <IoIosArrowDown color={config.colors.rbBasicBlue} size={20}/>
              </Col>
              :
              <Col/>
            }
            { dataTop.length > optInit ?
              <Col className='text-blue clickable pr-2 pt-1 text-size-14 line-height-15 text-right' onClick={this.showLess}>
                Show Less <IoIosArrowUp color={config.colors.rbBasicBlue} size={20}/>
              </Col>
              : 
              <Col/>
            }
          </Row>
        </Col>
      </Row>
    )
  }
}

export default ClaimCashGraph;