import React, { useState } from 'react';
import config from '../../../../config/config';
import GridRow from '../../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../../MasterComponents/Grid/GridCol';
import IconDelete from '../../../../MasterComponents/Icons/IconDelete';
import IconEdit from '../../../../MasterComponents/Icons/IconEdit';
import checkImg from '../../../../assets/images/check-small.svg';
import alertImg from '../../../../assets/images/alert-small.svg';
import { Popover, PopoverBody } from 'reactstrap';

const FormDefaultDistributorRow = (props) => {
  const [isStatusOpen, setStatusOpen] = useState(false);
  const toggleStatus = () => {
    setStatusOpen(!isStatusOpen);
  };
  const { isPreffered, provideDcn, companyId } = props;

  const isNonEntegra = props.isNonEntegra;

  return (
    <GridRow className='line-box border mx-0'>
      <GridCol className='col-10'>
        <span>{props.distributorName}</span>
      </GridCol>
      <GridCol className='col-2 text-right d-flex justify-content-end'>                        
        <div className='clickable pr-3' onClick={props.handleEditDistributor}>
          <IconEdit color={config.colors.rbGrey}/>
        </div>
        <div className='clickable' onClick={props.handleDeleteDistributor}>
          <IconDelete color={config.colors.rbGrey}/>
        </div>
      </GridCol>
      <GridCol className='p-0 position-absolute' style={{left: '100%'}}>
        <GridRow className='m-0'>
          <GridCol id={`status_dist_${props.id}`} className='p-0 mw-fc' onMouseEnter={() => setStatusOpen(true)} onMouseLeave={() => setStatusOpen(false)}>
            <img src={ isNonEntegra ? alertImg : (isPreffered && provideDcn ? checkImg : alertImg)} alt='status' />
          </GridCol>
        </GridRow>
      </GridCol>
      {
        ((!isPreffered || !provideDcn) && (companyId === 2 ? isNonEntegra : true)) &&
        <Popover placement='top' isOpen={isStatusOpen} target={`status_dist_${props.id}`} toggle={toggleStatus}>
          <PopoverBody>
            { isNonEntegra ? 'Not Processing Rebates' : 'You must login and activate this distributor in order to start earning cash back'}
          </PopoverBody>
        </Popover>
      }
    </GridRow>
  );
};

export default FormDefaultDistributorRow;
