import { apiInstance } from '../../config/tokenApi';

const getItems = (filters, page) => {
    let params = {};
    if (filters) {
        if (filters.itemFilter)
            params.itemFilter = filters.itemFilter;
        if (filters.search)
            params.search = filters.search;
        if (filters.manufacturers)
            params.manufacturers = filters.manufacturers;
        if (filters.categories)
            params.categories = filters.categories;
        if (filters.types)
            params.types = filters.types;
    }
    if (page)
        params.page = page;
    return apiInstance.get(`/item-intel`, { params });
};

const getManufacturers = (filter) => {
    if (filter) return apiInstance.get(`/item-intel/manufacturers?search=${filter}`);
    return apiInstance.get(`/item-intel/manufacturers`);
};

const getCategories = (filter) => {
    if (filter) return apiInstance.get(`/item-intel/categories?search=${filter}`);
    return apiInstance.get(`/item-intel/categories`);
};

const getDetail = (productId) => {
    return apiInstance.get(`/item-intel/${productId}`);
};

const getSimilarItems = (productId) => {
    return apiInstance.get(`/item-intel/matches/${productId}`);
};

export default {
    getDetail,
    getItems,
    getManufacturers,
    getCategories,
    getSimilarItems,
};
