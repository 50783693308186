import React, { Component } from 'react'
import { connect } from 'react-redux';
import config from '../../config/config';
import { spinner } from '../../MasterComponents/Spinner/SpinnerActions';
import '../FoodBam/FoodBamFrame.scss';
import GridRow from '../../MasterComponents/Grid/GridRow';
import GridCol from '../../MasterComponents/Grid/GridCol';
import FoodBamApi from './FoodBamApi';

class FoodBamFrame extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount = () => {
    this.foodBamMydaLogin();
  }

  foodBamMydaLogin = () => {
    const { dispatch } = this.props;
    dispatch(spinner(true));
    const payload = {
      external_user_id: localStorage.getItem('userid'),
      external_user_email: localStorage.getItem('email')
    };
    FoodBamApi.foodBamMydaLogin(payload).then(({ data }) => {
      dispatch(spinner(false));
      this.setState({ iframeUrl: data.url });
    })
    .catch(error => {
      dispatch(spinner(false));
      this.setState({ iframeUrl: config.foodBamURL });
    });
  }

  render() {
    let { iframeUrl } = this.state;

    return (
      <div className='foodbam-app'>
        <GridRow className='no-gutters'>
          <GridCol className='col-12'>
            <iframe id='foodBamFrame' title='FoodBam' src={iframeUrl} width='100%' frameBorder='0' scrolling='no'></iframe>
          </GridCol>
        </GridRow>
      </div>
    );
  }

}

const mapStateToProps = state => {
  return {};
}

export default connect(mapStateToProps)(FoodBamFrame);
