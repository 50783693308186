import React, { Component, Fragment } from 'react';
import '../SignUp.scss';
import { isMobile } from 'react-device-detect';
import InputLabel from '../../../MasterComponents/InputLabel/InputLabel';
import SignUpWhitelabelNewTypeContentLinks from './SignUpWhitelabelNewTypeContentLinks';
import SignUpFormHeader from '../Forms/SignUpFormHeader';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import IconClose from '../../../MasterComponents/Icons/IconClose';
import Button from '../../../MasterComponents/Button/Button';

class SignUpWhitelabelNewTypeContent extends Component {
    goHome = (e) => {
        e.preventDefault();
        this.props.history.push('/');
    };
    backAccount = (e) => {
        e.preventDefault();
        this.props.history.push('/register');
    };
    render() {
        const { history, dispatch, links, title, companyName } = this.props;
        const linkProps = {
            history,
            dispatch,
            links,
            companyName
        };
        const headerProps = {
            activeStep: 0,
            title,
            hideSteps: true
        };
        return (
            <div className='right-signup'>
                <div className='header-main'>
                    <div className='header-fixed home-header'>
                        { isMobile &&
                            <Fragment>
                                <GridCol size={11}className='pt-2 pl-5'>
                                    <InputLabel text='Create Account' title className='title' />
                                </GridCol>
                                <GridCol size={1} className='p-0' onClick={this.goHome}>
                                    <IconClose color={'white'} size={18} />
                                </GridCol>
                            </Fragment> 
                        }
                    </div>
                </div>
                <div className='header-signup'>
                    <SignUpFormHeader {...headerProps} />
                </div>
                <div className='signup-form'>
                    <SignUpWhitelabelNewTypeContentLinks {...linkProps} />
                </div>
                { isMobile &&
                    <div className='signup-form pl-4'>
                        <GridCol className='text-center'>
                            <Button type='button' variant='white' size='' text='BACK' onClick={this.backAccount}/>    
                        </GridCol>
                    </div>
                }
            </div>
        )
    }
}

export default SignUpWhitelabelNewTypeContent;