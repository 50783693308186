import React, { Component } from 'react';
import GridRow from '../../MasterComponents/Grid/GridRow';
import GridCol from '../../MasterComponents/Grid/GridCol';
import * as $ from 'jquery';
import setToken from '../../config/tokenApi';

class DashboardTopbarMoreOptions extends Component {
  componentDidMount = () => {
    $('.notification-list').focus();
  }

  goToSettings = () => {     
    const { history } = this.props;   
    history.push('/dashboard/settings');
    this.props.clickLink();
  };

  goToLogout = () => {     
    const { history } = this.props;
    history.push('/logout');
    this.props.clickLink();
  };

  switchUser = () => {     
    const { history } = this.props;
    const adminAuthKey = localStorage.getItem('authKeyAdmin');
    localStorage.setItem('authKey', adminAuthKey);
    setToken(adminAuthKey);

    history.push('/admin-login');
  };

  render() {
    const { hide } = this.props;
    const isAdmin = localStorage.getItem('authKeyAdmin');

    return (
      <GridCol tabIndex='0' onBlur={hide} className='notification-list'>
        <GridRow>
          <GridCol onClick={this.goToSettings} className='link text-size-16 text-black py-3 px-4 clickable border-bottom'>
            Settings
          </GridCol>
        </GridRow>
        <GridRow>
          <GridCol onClick={this.goToLogout} className='link text-size-16 text-black py-3 px-4 clickable'>
            Logout
          </GridCol>
        </GridRow>
        {
          isAdmin &&
          <GridRow>
            <GridCol onClick={this.switchUser} className='link text-size-16 text-black py-3 px-4 clickable border-top'>
              Switch User
            </GridCol>
          </GridRow>
        }
      </GridCol>
    );
  }
};

export default DashboardTopbarMoreOptions;