import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconProcessing = props => (
    <svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Status" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="3---Homepage" transform="translate(-574.000000, -473.000000)">
                <g id="rebates" transform="translate(-1.000000, 220.000000)">
                    <g id="online-distributor" transform="translate(376.000000, 80.000000)">
                        <g id="icons/pending" transform="translate(199.000000, 173.000000)">
                            <rect id="Rectangle" fill={!props.isActive ? props.color : props.activeColor} x="0" y="0" width="24" height="24" rx="12"></rect>
                            <g id="clock-time-wall-clock-alarm-timer" transform="translate(4.000000, 4.000000)" fill="#FFFFFF" fillRule="nonzero">
                                <path d="M8,0.290909091 C3.74909091,0.290909091 0.290909091,3.74909091 0.290909091,8 C0.290909091,12.2509091 3.74909091,15.7090909 8,15.7090909 C12.2509091,15.7090909 15.7090909,12.2509091 15.7090909,8 C15.7090909,3.74909091 12.2509091,0.290909091 8,0.290909091 Z M8,14.8363636 C4.22981818,14.8363636 1.16363636,11.7694545 1.16363636,8 C1.16363636,4.23054545 4.22981818,1.16363636 8,1.16363636 C11.7701818,1.16363636 14.8363636,4.23054545 14.8363636,8 C14.8363636,11.7694545 11.7701818,14.8363636 8,14.8363636 Z M10.5156364,9.89890909 C10.6858182,10.0683636 10.6858182,10.3454545 10.5156364,10.5156364 C10.4305455,10.6007273 10.3185455,10.6436364 10.2072727,10.6436364 C10.096,10.6436364 9.984,10.6007273 9.89890909,10.5156364 L7.69163636,8.30836364 C7.60945455,8.22690909 7.56363636,8.11563636 7.56363636,8 L7.56363636,3.04072727 C7.56363636,2.8 7.75927273,2.60436364 8,2.60436364 C8.24072727,2.60436364 8.43636364,2.8 8.43636364,3.04072727 L8.43636364,7.81890909 L10.5156364,9.89890909 Z" id="Shape"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

IconProcessing.propTypes = {
    color: PropTypes.string,
    activeColor: PropTypes.string,
    isActive: PropTypes.bool,
    size: PropTypes.number,
};
  
IconProcessing.defaultProps = {
    activeColor: config.colors.rbYellow,
    color: config.colors.rbWhite,
    isActive: false,
    size: 16,
};
  
export default IconProcessing;