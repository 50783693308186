import React, { Component } from 'react';
import PropTypes from 'prop-types';

class DropDownListOption extends Component {
  select = (e) => {
    const value = this.props.value === undefined ? this.props.title : this.props.value;
    this.props.onClick(value);
  }
  clickEnter = (e) => {
    if (e.which === 13) {
      this.select();
    }
  }
  render() {
    return(
      <div onKeyDown={this.clickEnter} onClick={this.select} tabIndex="-1" className={"dropdown-item" + (this.props.disabled ? ' disabled' : '')} >{this.props.title || this.props.children}</div>
    );
  }
}

DropDownListOption.propTypes = {
  title: PropTypes.any,
  value: PropTypes.any,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
}

export default DropDownListOption;