import React from 'react';
import { isMobile } from 'react-device-detect';
import { Row, Col } from 'reactstrap';
import useReports from './hooks/useReports';
import ReportsPagination from './ReportsPagination';
import ReportsSubscribe from './ReportsSubscribe';
import ReportsView from './ReportsView';
import ReportsViewRow from './ReportsViewRow';

const Reports = () => {
  const { reportsAll, currentReports, currentPage, dataPerPage, email, 
    onChangeHandler, onPaginate, onFirst, onLast, onPrevious, onNext, onSaveSubscribe } = useReports();

  return (
    <div className='pt-2 pb-4'>
      { !isMobile && 
        <Row className='pl-5 py-2 text-size-18 line-height-22'>
          Reports
        </Row>
      }
      <ReportsView />
      { currentReports.length > 0 ? currentReports.map((r, i) =>
          <ReportsViewRow 
            key={i} 
            report={r.filename}
            date={r.date}
            url={r.fileurl}
            hasColor = { i % 2 !== 0 ? true : false }
          />
        )
        :
        <Row>
          <Col className='text-center text-size-14 py-2 border'>
            No data available
          </Col>
        </Row>
      }
      <Row>
        <Col xs={isMobile ? 12 : 6}>
          { reportsAll.length > 5 && 
              <ReportsPagination 
              reportsPerPage={dataPerPage} 
              totalReports={reportsAll.length}
              currentPage={currentPage}
              onPaginate={onPaginate}
              onFirst={onFirst}
              onLast={onLast}
              onPrevious={onPrevious}
              onNext={onNext}
              />
          }
        </Col>
        <Col xs={isMobile ? 12 : 6}>
          <ReportsSubscribe email={email} onSaveSubscribe={onSaveSubscribe} onChangeHandler={onChangeHandler}  />
        </Col>
      </Row>
    </div>
  )
}

export default Reports
