import React, { useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import SlidingPane from "react-sliding-pane";
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { destroySidebar } from '../../MasterComponents/Sidebar/reducer/SidebarAction';
import { useSelector } from 'react-redux';
import { showModal } from '../../MasterComponents/Modals/ModalsActions';
import StartOrderingOnlineModal from '../FoodBam/Modals/StartOrderingOnlineModal';
import MoreModal from './Modals/MoreModal';
import ReactGA from 'react-ga';
import { setSettingsOpen } from './DashboardActions';
import IconHome from '../../MasterComponents/Icons/IconHome';
import IconMarker from '../../MasterComponents/Icons/IconMarker';
import IconPieChart from '../../MasterComponents/Icons/IconPieChart';
import IconWallet from '../../MasterComponents/Icons/IconWallet';
import IconCart from '../../MasterComponents/Icons/IconCart';
import IconBlog from '../../MasterComponents/Icons/IconBlog';
import IconStar from '../../MasterComponents/Icons/IconStar';
import IconQuestion from '../../MasterComponents/Icons/IconQuestion';
import { Row, Col} from 'reactstrap';
import { AiOutlineFileText } from 'react-icons/ai';
import { isMobile } from 'react-device-detect';

const DashboardSidebar = ({id, history, dispatch}) => {
  const { activeMerchant } = useSelector(({dashboard}) => dashboard);
  const {
    id : companyId, displayCashBack, displayInsight,
    displayClaimCheck, displayBeyondPrime, displayFoodbam,
    displayContractManagement: displayContractManagementWhitelabel,
    displayFoodCostManagement
  } = useSelector(({company}) => company);

  const displayContractManagement = displayContractManagementWhitelabel && (activeMerchant && activeMerchant.displayContractManagement === 1);

  const [isOpen, setOpen] = useState(true);
  const onClose = () => {
    setOpen(false);
    setTimeout(() =>{
      dispatch(destroySidebar(id));
    }, 300);
  };
  const clickLink = link => {
    if (link.url === '/dashboard/foodBAM' && localStorage.getItem('isFoodbamActive') !== '1') {
      dispatch(showModal(StartOrderingOnlineModal, 'startOrderingOnlineModal', { yesHandler: () => { this.props.history.push('/dashboard/foodBAM'); this.forceUpdate(); } }));
    } else if (link.url === '/dashboard/more') {
      dispatch(showModal(MoreModal, 'idMoreModal', {header: activeMerchant, history, dispatch }));
    } else if (!link.comingSoon) {
      onClose();
      history.push(link.url);
    }
    switch (link.url) {
      case '/dashboard/insights':
        ReactGA.event({
          category: 'Navbar',
          action: 'Click Insights'
        });
        break;
      case '/dashboard/optimize':
        ReactGA.event({
          category: 'Navbar',
          action: 'Click Optimize My Purchases'
        });
        break;
      default:
        break;
    }
    dispatch(setSettingsOpen(false));
  };
  let links = [];
  if(displayCashBack && displayInsight && displayBeyondPrime) {
    links.push({
      button: 'Home',
      title: 'Home',
      url: '/dashboard/home',
      icon: IconHome,
      iconComingSoon: () => { return null }
    });
  }
  if(displayCashBack) {
    links.push({
      button: 'Locations & Distributors',
      title: 'Cash back',
      url: '/dashboard/rebates/card',
      icon: IconMarker,
      iconComingSoon: () => { return null }
    });
  }
  if(displayInsight) {
    links.push({
      button: 'Insights',
      title: 'Insights',
      url: '/dashboard/insights',
      icon: IconPieChart,
      iconComingSoon: () => { return null }
    });
  }
  if(displayClaimCheck) {
    links.push({
      button: 'Claim ACH',
      title: 'Claim ACH',
      url: '/dashboard/claim',
      icon: IconWallet,
      iconComingSoon: () => { return null }
    });
  }

  if (!isMobile && displayContractManagement) {
    links.push({
      button: 'Contract Mgmt',
      title: 'Contract Mgmt',
      url: '/dashboard/contractmanagement',
      icon: () => (
        <AiOutlineFileText color='#fff' size={16} />
      ),
      iconComingSoon: () => { return null }
    });
  }
  if (displayBeyondPrime) {
    links.push({
      button: "Beyond Broadline",
      title: "Beyond Broadline",
      url: "/dashboard/prime",
      icon: IconStar,
      iconComingSoon: () => { return null }
    });
  };
  if (displayFoodbam) {
    links.push({
      button: 'FoodBAM',
      title: 'FoodBAM',
      url: '/dashboard/foodBAM',
      icon: IconCart,
      iconComingSoon: () => { return null }
    });
  }
  if (companyId === 1) {
    links.push({
      button: 'Our Blog',
      title: 'Blog',
      url: '/dashboard/blog',
      icon: IconBlog,
      iconComingSoon: () => { return null }
    });
  }
  links.push({
    button: 'FAQ',
    title: 'FAQ',
    url: '/dashboard/faq',
    icon: IconQuestion,
    iconComingSoon: () => { return null }
  });
  if (displayFoodCostManagement) {
    links.push({
      button: 'Food Cost Management',
      title: 'Food Cost Management',
      url: '/dashboard/foodCost',
      icon: IconCart,
      iconComingSoon: () => { return null }
    });
  }

  const linksMap = links.map((link, i) => (
    <Row key={i} className='text-white py-3 px-3 clickable' onClick={() => clickLink(link)}>
      <Col xs={1} className=''>
        <link.icon size={18} />
      </Col>
      <Col>
        {link.button}
      </Col>
    </Row>
  ));
  return (
    <SlidingPane
      hideHeader
      className='food-cost-sidebar'
      overlayClassName='food-cost-sidebar-overlay'
      isOpen={isOpen}
      from='left'
      width='350px'
      onRequestClose={onClose} >
        <Row className='px-4 py-3 justify-content-end'>
          <Col className='mw-fc text-right clickable text-white' onClick={onClose}>
            <IoMdClose size={32} />
          </Col>
        </Row>
        {linksMap}
    </SlidingPane>
  )
};

export default DashboardSidebar;
