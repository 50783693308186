import React, { Component } from 'react';
import { findModal } from '../../../MasterComponents/Modals/ModalsActions';
import Modal from '../../../MasterComponents/Modals/Modal';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import Faq from '../Faq';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import InputLabel from '../../../MasterComponents/InputLabel/InputLabel';
import IconArrowBack from '../../../MasterComponents/Icons/IconArrowBack';
import config from '../../../config/config';
class FaqModal extends Component {
  
  close = () => {
    const { modals } = this.props;
    findModal(modals, 'idFaqModal').close();
  }

  render() {
    const { header} = this.props;
    return (
    <Modal id='idFaqModal' fullHeight={true}>
      <GridRow className='home-header'>
        <GridCol size={2} className='py-3 pl-4' onClick={this.close}>
          <IconArrowBack color={config.colors.rbWhite} size={16} />
        </GridCol>
        <GridCol size={8}className='py-3 text-center'>
            <InputLabel text={header} title className='title' />
        </GridCol>
        <GridCol size={2}>
        </GridCol>
      </GridRow>
      <ModalBody className='py-0'>
        <Faq/>
      </ModalBody>
    </Modal>
    )
  }
}

export default FaqModal;
