import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import GridCol from '../../MasterComponents/Grid/GridCol';
import GridRow from '../../MasterComponents/Grid/GridRow';

class InsightBodyManufacturersTab extends Component {
  render() {
    const { tabManufacturers, onTabManufacturer, userCurrent } = this.props;
    const showDeviations = userCurrent.displayDeviations;
    if(isMobile) {
      return (
        <>
          <GridRow>
            <GridCol className='py-3 text-center insights-graph-title insights-line-bottom'>
                Top Manufacturers
            </GridCol>
          </GridRow>
          <GridRow className='pt-3 text-center insights-line-bottom'>
              <GridCol className={'pb-3 insights-actived-text clickable text-uppercase '  + (tabManufacturers.isSelectedVolume ? 'insights-actived-line' :'')} onClick={()=>onTabManufacturer('volume')}>
                  Volume
              </GridCol>
              <GridCol className={'pb-3 insights-actived-text clickable text-uppercase insights-line-bottom '  + (tabManufacturers.isSelectedRebate ? 'insights-actived-line' :'')} onClick={()=>onTabManufacturer('rebate')}>
                  Rebates
              </GridCol>
              { showDeviations &&
                <GridCol className={'pb-3 insights-actived-text clickable text-uppercase insights-line-bottom '  + (tabManufacturers.isSelectedDeviation ? 'insights-actived-line' :'')} onClick={()=>onTabManufacturer('deviation')}>
                  Deviation
                </GridCol>
              }
          </GridRow>
        </>
      )
    }
    return (
      <GridRow>
        <GridCol className='col-5 pl-5 pb-3 text-left insights-graph-title insights-line-bottom'>
            Top Manufacturers
        </GridCol>
        <GridCol className={'col-2 pt-1 clickable text-uppercase insights-line-bottom '  + (tabManufacturers.isSelectedVolume ? 'insights-actived-line insights-actived-text' : 'insights-inactive-tab')} onClick={()=> onTabManufacturer('volume')}>
            Volume
        </GridCol>
        <GridCol className={'col-2 pt-1 clickable text-uppercase insights-line-bottom '  + (tabManufacturers.isSelectedRebate ? 'insights-actived-line insights-actived-text' : 'insights-inactive-tab')} onClick={()=> onTabManufacturer('rebate')}>
            Rebates
        </GridCol>
        <GridCol className={'col-3 pt-1 clickable text-uppercase insights-line-bottom '  + (tabManufacturers.isSelectedDeviation ? 'insights-actived-line insights-actived-text' : 'insights-inactive-tab')}>
          { showDeviations &&
            <GridCol onClick={()=> onTabManufacturer('deviation')}>
              Deviation Savings
            </GridCol>
          }
        </GridCol>
        
      </GridRow>
    )
  }
}

const mapStateToProps = state => {
  return {
    userCurrent: state.location.userCurrent
  };
}

export default connect(mapStateToProps)(InsightBodyManufacturersTab);