import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';

const ModalFooter = props => (
  <div className={'modal-footer rb-modal-footer' + (props.center ? ' align-self-center text-center justify-content-' + props.justify : '') + (props.className ? ` ${props.className}` : '') + (isMobile ? ' mobile' : '')}>
    {props.children}
  </div>
);

ModalFooter.propTypes = {
  className: PropTypes.string,
  justify: PropTypes.string,
  center: PropTypes.bool
};

ModalFooter.defaultProps = {
  center: true,
  justify: 'center'
};

export default ModalFooter;