import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reportsSetAll } from '../Reducer/ReportsAction';
import { showModal } from '../../../MasterComponents/Modals/ModalsActions';
import AlertModal from '../../../MasterComponents/AlertModal.js/AlertModal';
import ReportsApi from '../ReportsApi';

const useReports = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(5);
  const [email, setEmail] = useState('');
  const merchants = useSelector(({location}) => location.merchant);
  const companyId = useSelector(({company}) => company.id);
  const parentId = merchants.length > 0 ? merchants[0].parentId: 0;
  const reportsAll = useSelector(({reports}) => reports.reportsAll);
  const indexLast = currentPage * dataPerPage;
  const indexFirst = indexLast - dataPerPage;
  const currentReports = reportsAll.slice(indexFirst, indexLast);
  let merchantsIds =  merchants.length > 0 ? merchants.map(m => m.id) : [];
  merchantsIds = [parentId, ...merchantsIds];

  const onPaginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  }

  const onFirst = () => {
    setCurrentPage(1);
  }

  const onLast = () => {
    const last = Math.ceil(reportsAll.length / dataPerPage);
    if(currentPage !== last) {
      setCurrentPage(last);
    }
  }

  const onPrevious = () => {
    if(currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  const onNext = () => {
    const next = Math.ceil(reportsAll.length / dataPerPage);
    if(currentPage !== next) {
      setCurrentPage(currentPage + 1);
    }
  }

  const getReportsData = () => {
    ReportsApi.getReports(companyId, merchantsIds).then(({data}) => {
      dispatch(reportsSetAll(data.data));
    });
  }

  const onSaveSubscribe = () => {
    ReportsApi.saveSubscribe(email, parentId, companyId).then(({data}) => {
      const props = {
        message: 'Thank you for subscribing',
        buttonText: 'Ok'
      }
      setEmail('');
      dispatch(showModal(AlertModal, 'idAlertModal', {...props}));
    }).catch(({data}) => {
      const message = data.data.message || 'Error, it could not send';
      const props = {
        message : message,
        buttonText: 'Ok'
      }
      dispatch(showModal(AlertModal, 'idAlertModal', {...props}));
    });
  }

  const onChangeHandler = (input) => {
    setEmail(input);
  }

  useEffect(() => {
    getReportsData();
    setDataPerPage(dataPerPage);
  }, [])

  return {
    reportsAll,
    currentReports,
    currentPage,
    dataPerPage,
    email,
    onChangeHandler,
    onPaginate,
    onFirst,
    onLast,
    onPrevious,
    onNext,
    onSaveSubscribe
  }

}

export default useReports;
