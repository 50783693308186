import React, { Fragment, useEffect, useReducer } from 'react';
import Joi from 'joi';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import Button from '../../../MasterComponents/Button/Button';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import Input from '../../../MasterComponents/Input/Input';
import InputLabel from '../../../MasterComponents/InputLabel/InputLabel';
import { showModal, updateModalProps } from '../../../MasterComponents/Modals/ModalsActions';
import { spinner } from '../../../MasterComponents/Spinner/SpinnerActions';
import DashboardApi from '../../Dashboard/DashboardApi';
import EnterCredentialsModal from '../../Dashboard/Modals/EnterCredentialsModal';
import IconCheck from '../../../MasterComponents/Icons/IconCheck';
import IconAlert from '../../../MasterComponents/Icons/IconAlert';
import config from '../../../config/config';
import GoodNewsModal from '../../Dashboard/Modals/GoodNewsModal';
import VerifyRespondModal from '../../Dashboard/Modals/VerifyRespondModal';

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_AUTO':
      return {
        ...state,
        isAuto: action.payload
      }
    case 'CREDENTIALS_ALL':
      const validationAll = CREDS_SCHEMA.validate(action.payload);
      return {
        ...state,
        allCredentials: {
          ...action.payload,
          isValid: validationAll.error ? false : true
        }
      }
    case 'CREDENTIALS_LOCATION':
      const validationLoc = CREDS_SCHEMA.validate(action.payload.credentials);
      return {
        ...state,
        locations: state.locations.map(loc => {
          if (loc.id !== action.payload.id) return loc;
          return {
            ...loc,
            credentials: {
              ...action.payload.credentials,
              isValid: validationLoc.error ? false : true
            }
          }
        })
      }
    case 'KEY_LOCATION':
      return {
        ...state,
        locations: state.locations.map(loc => {
          if (loc.id !== action.payload.id) return loc;
          return {
            ...loc,
            [action.payload.key]: action.payload.value
          }
        })
      }
    case 'CHECK_ALL':
      return {
        ...state,
        allLocations: !state.allLocations
      };
    case 'CHECK':
      return {
        ...state,
        locations: state.locations.map(merDist => {
          if (merDist.id !== action.payload) return merDist;
          return {
            ...merDist,
            check: !merDist.check
          };
        })
      };
    case 'SET_MERCHANTS':
      return {
        ...state,
        locations: action.payload.map(merDist => ({
          ...merDist,
          check: merDist.dcn ? true : (merDist.isActive === 1),
          credentials: {
            username: '',
            password: '',
            isValid: false
          }
        }))
      };
    default:
      return state;
  }
};

const CREDS_SCHEMA = Joi.object({
  username: Joi.string().min(3).required(),
  password: Joi.string().min(3).required(),
});

const ACTIVATE_SCHEMA = Joi.object({
  merchantDistributors: Joi.array().min(1).items(Joi.object({
    id: Joi.number().integer().required(),
    dcn: Joi.string().required().min(2),
    username: Joi.string().min(2).required(),
    password: Joi.string().min(2).required()
  })),
});

const ACTIVATE_AUTO_SCHEMA = Joi.object({
  merchantDistributors: Joi.array().min(1).items(Joi.object({
    id: Joi.number().integer().required(),
    dcn: Joi.string().required().min(2)
  })),
});

const INITIAL_STATE = {
  allLocations: false,
  allCredentials: {
    username: '',
    password: '',
    isValid: false
  },
  locations: [],
  isAuto: false
};

const useDistributorIndex = ({distributorsProps, match, setdistributorName, history}) => {
  const dist = distributorsProps.distributorMerchants.filter(d => d.id === parseInt(match.params.merDistId))[0];
  const [state, dispatchIndex] = useReducer(reducer, INITIAL_STATE);
  const dispatch = useDispatch();

  useEffect(() => {
    if (dist) {
      const name = dist.nickname
      setdistributorName(name);
      dispatchIndex({
        type: 'SET_MERCHANTS',
        payload: dist.merchantDistributors
      });
      dispatchIndex({
        type: 'SET_AUTO',
        payload: dist.merchantDistributors[0].companyDistributorSensitive === 1
      })
    } else {
      history.replace('/onboarding/2');
    }
    //eslint-disable-next-line
  }, []);

  const saveCredentials = (index, username, password) => {
    if (index === 'all') {
      dispatchIndex(({
        type: 'CREDENTIALS_ALL',
        payload: {
          username: username,
          password: password
        }
      }));
    } else {
      dispatchIndex(({
        type: 'CREDENTIALS_LOCATION',
        payload: {
          id: index,
          credentials: {
            username: username,
            password: password
          }
        }
      }));
    }
  };

  const verifyCredentials = (username, password, dcns, cb) => {
    dispatch(spinner(true));
    DashboardApi.verifyCreds(state.locations[0].distributorId, state.locations[0].merchantId, username, password, dcns).then(({data}) => {
      dispatch(spinner(false));
      cb(data);
    }).catch(({data}) => {
      dispatch(spinner(false));
      cb(data);
    });
  };

  const openCredentials = (merDist, isAll) => {
    const props = {
      merchantDistributor: merDist,
      index: isAll ? 'all' : merDist.id,
      verifyCredentials: verifyCredentials,
      saveCredentials: saveCredentials,
      dcns: state.allLocations.map(l => l.dcn)
    };
    dispatch(showModal(EnterCredentialsModal, 'enterCredentialsModal', props));
  };

  useEffect(() => {
    const props = {
      verifyCredentials: verifyCredentials,
      saveCredentials: saveCredentials,
    };
    dispatch(updateModalProps('enterCredentialsModal', props));
    //eslint-disable-next-line
  }, [verifyCredentials, saveCredentials]);

  const locationsMap = state.locations.map((merDist) => {
    const dcn = {
      onChange: (value) => {
        dispatchIndex({
          type: 'KEY_LOCATION',
          payload: {
            id: merDist.id,
            value: value,
            key: 'dcn'
          }
        });
      },
      value: merDist.dcn,
      name: 'dcn',
      placeholder: 'DCN'
    };
    if (isMobile) {
      return (
        <Fragment key={`loc_${merDist.id}`}>
          <GridRow className='mt-3 align-items-center'>
            <GridCol onClick={() => dispatchIndex({type: 'CHECK', payload: merDist.id})}>
              {merDist.merchant.nickname}
            </GridCol>
            <GridCol maxWidth='54px'>
              <div className="rb-checkbox custom-control custom-checkbox top-0">
                <input onChange={() => dispatchIndex({type: 'CHECK', payload: merDist.id})} type='checkbox' id={`loc_${merDist.id}`} className='custom-control-input' checked={merDist.check || state.allLocations} />
                <label className='custom-control-label' htmlFor={`loc_${merDist.id}`} />
              </div>
            </GridCol>
          </GridRow>
          {
            (merDist.check || state.allLocations) &&
            <>
              <GridRow className='mt-2'>
                <GridCol maxWidth='80%'>
                  <InputLabel text='Distributor Account # (DCN) (Optional)' />
                  <Input {...dcn} />
                </GridCol>
              </GridRow>
              {
                (!state.allLocations && !state.isAuto) &&
                <GridRow className='mt-2 align-items-center'>
                  <GridCol style={{maxWidth: '80%', minWidth: '80%'}}>
                    <Button onClick={() => openCredentials(merDist)} size='sm-fs' variant='yellow' text='CONNECT' />
                  </GridCol>
                  {
                    (merDist.credentials.isValid || merDist.isActive) ?
                    <GridCol className='pl-0'>
                      <div className='check-creds mobile'>
                        <IconCheck size={11} color={config.colors.rbWhite} />
                      </div>
                    </GridCol>
                    :
                    <GridCol className='pl-0'>
                      <IconAlert size={24}/>
                    </GridCol>
                  }
                </GridRow>
              }
            </>
          }
        </Fragment>
      );
    }
    return (
      <GridRow className='mt-3 align-items-end' key={`loc_${merDist.id}`}>
        <GridCol size={3} className='align-self-center'>
          <div className="rb-checkbox custom-control custom-checkbox top-0">
            <input onChange={() => dispatchIndex({type: 'CHECK', payload: merDist.id})} type='checkbox' id={`loc_${merDist.id}`} className='custom-control-input' checked={merDist.check || state.allLocations} />
            <label className='custom-control-label' htmlFor={`loc_${merDist.id}`}>{merDist.merchant.nickname}</label>
          </div>
        </GridCol>
        {
          (merDist.check || state.allLocations) &&
          <>
            <GridCol size={4}>
              <InputLabel text='Distributor Account # (DCN) (Optional)' />
              <Input {...dcn} />
            </GridCol>
            {
              (!state.allLocations && !state.isAuto) &&
              <>
                <GridCol size={4}>
                  <Button onClick={() => openCredentials(merDist)} size='sm-fs' variant='yellow' text='CONNECT' />
                </GridCol>
                {
                  (merDist.credentials.isValid || merDist.isActive) ?
                  <GridCol className='pl-0' style={{maxWidth: '40px'}}>
                    <div className='check-creds all'>
                      <IconCheck size={11} color={config.colors.rbWhite} />
                    </div>
                  </GridCol>
                  :
                  <GridCol style={{maxWidth: '40px', padding: 0, paddingBottom: '7px'}}>
                    <IconAlert size={24}/>
                  </GridCol>
                }
              </>
            }
          </>
        }
      </GridRow>
    );
  });

  const AllLocationsCheckbox = () => {
    if (isMobile) {
      return (
        <>
          <GridRow className='mt-3 align-items-center'>
            <GridCol onClick={() => dispatchIndex({type: 'CHECK_ALL'})}>
              All Locations
            </GridCol>
            <GridCol maxWidth='54px'>
              <div className="rb-checkbox custom-control custom-checkbox top-0">
                <input onChange={() => dispatchIndex({type: 'CHECK_ALL'})} type='checkbox' id={`all_loc`} className='custom-control-input' checked={state.allLocations} />
                <label className='custom-control-label' htmlFor={`all_loc`} />
              </div>
            </GridCol>
          </GridRow>
          {
            (state.allLocations && !state.isAuto) &&
            <GridRow className='mt-2 align-items-center'>
              <GridCol style={{maxWidth: '80%', minWidth: '80%'}}>
                <Button onClick={() => openCredentials(state.locations[0], true)} size='sm-fs' variant='yellow' text='CONNECT' />
              </GridCol>
            {
              state.allCredentials.isValid ?
              <GridCol className='pl-0'>
                <div className='check-creds mobile'>
                  <IconCheck size={11} color={config.colors.rbWhite} />
                </div>
              </GridCol>
              :
              <GridCol className='pl-0'>
                <IconAlert size={24}/>
              </GridCol>
            }
            </GridRow>
          }
        </>
      );
    }
    return (
      <GridRow className='mt-3 align-items-end'>
        <GridCol size={3}>
          <div className="rb-checkbox custom-control custom-checkbox top-0">
            <input onChange={() => dispatchIndex({type: 'CHECK_ALL'})} type='checkbox' id={`all_loc`} className='custom-control-input' checked={state.allLocations} />
            <label className='custom-control-label' htmlFor={`all_loc`}>All Locations</label>
          </div>
        </GridCol>
        {
          (state.allLocations && !state.isAuto) &&
          <>
            <GridCol size={4}>
              <Button onClick={() => openCredentials(state.locations[0], true)} size='sm-fs' variant='yellow' text='CONNECT' />
            </GridCol>
            {
              state.allCredentials.isValid ?
              <GridCol className='pl-0' style={{maxWidth: '40px'}}>
                <div className='check-creds all'>
                  <IconCheck size={11} color={config.colors.rbWhite} />
                </div>
              </GridCol>
              :
              <GridCol style={{maxWidth: '40px', padding: 0, paddingBottom: '7px'}}>
                <IconAlert size={24}/>
              </GridCol>
            }
          </>
        }
      </GridRow>
    );
  };

  const onSave = (cb) => {
    const merDists = state.locations.filter(md => state.allLocations || md.check).map(md => {
      if (!state.isAuto) {
        if (state.allCredentials.isValid) {
          return {
            id: md.id,
            dcn: md.dcn.toString(),
            username: state.allCredentials.username.toString(),
            password: state.allCredentials.password.toString()
          };
        }

        if (md.credentials.isValid) {
          return {
            id: md.id,
            dcn: md.dcn.toString(),
            username: md.credentials.username.toString(),
            password: md.credentials.password.toString()
          };
        }
      }

      return {
        id: md.id,
        dcn: md.dcn.toString(),
      }
    });
    dispatch(spinner(true));
    DashboardApi.activateMerchantDistributor(merDists, state.isAuto ? 'auto' : 'online').then(({data}) => {
      console.log(merDists);
      dispatch(spinner(false));
      const isSaveOrActivate = state.isAuto ? ACTIVATE_AUTO_SCHEMA.validate({merchantDistributors: merDists}) : ACTIVATE_SCHEMA.validate({merchantDistributors: merDists});
      if (!isSaveOrActivate.error) {
        dispatch(showModal(GoodNewsModal, 'goodNewsModal'));
      }
      cb();
    }).catch(err => {
      dispatch(spinner(false));
      let errorMessage = err.data.data.message;
      dispatch(showModal(VerifyRespondModal, 'verifyRespondModal', { message: errorMessage, button: "Ok"}));
      cb();
    });
  }

  return {
    AllLocationsCheckbox,
    locationsMap,
    onSave,
    dropdown: state.isAuto ? {label: 'Auto', value: 'auto'} : {label: 'Online Ordering', value: 'online'}
  };
};

export default useDistributorIndex;