import React from 'react';
import { isMobile } from 'react-device-detect';
import { Row, Col } from 'reactstrap';
import config from '../../config/config';

const ReportsViewRow = (props) => {
  const { report, date, url, hasColor } = props;
  const dateArray = date.split('_');
  const dateFormat = dateArray[0] + ', ' + dateArray[1];
  const reportFormat = report.split('_').join(' ');
  const downloadFile = () => {
    if(url) {
      let link = document.createElement('a');
      link.href = url;
      link.download = '';
      link.dispatchEvent(new MouseEvent('click'));
    }
  }
  if(isMobile) {
    return (
      <Row className='mx-2 border py-3'>
        <Col xs={9}>
          <Row>
            <Col className='line-height-21'>
              {reportFormat}
            </Col>
          </Row>
          <Row>
            <Col className='py-2 pt-3 text-grey'>
              {dateFormat}
            </Col>
          </Row>
        </Col>
        <Col xs={3} className='text-blue'>
          <Row onClick={downloadFile}>
            Download
          </Row>
        </Col>
      </Row>
    )
  }

  return (
    <Row className='py-2 border-bottom ' style={{backgroundColor: hasColor ? config.colors.rbGreyNew : ''}}>
      <Col xs={8} className='pl-5 text-size-14 line-height-17'>
        {reportFormat}
      </Col>
      <Col xs={2} className='text-size-14 line-height-17'>
        {dateFormat}
      </Col>
      <Col xs={2} className='text-center text-size-14 line-height-17 clickable text-blue' onClick={downloadFile} >
        Download
      </Col>
    </Row>
  )
}

export default ReportsViewRow;
