import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconConfirmation = props => (
<svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 66 66" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <desc>Created with sketchtool.</desc>
    <g id="Designs" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Programs---Confirmation" transform="translate(-686.000000, -279.000000)">
            <g id="icons/confirmation" transform="translate(687.000000, 280.000000)">
                <g id="Group-2">
                    <circle id="Oval" fill={!props.isActive ? props.color : props.activeColor} fillRule="nonzero" cx="32" cy="32" r="32"></circle>
                    <g id="icons/check" transform="translate(22.000000, 24.000000)" fill="#FFFFFF">
                        <path d="M18.254,2.246 C18.722,2.712 18.722,3.474 18.254,3.942 L8.442,13.754 C8.208,13.988 7.9,14.106 7.594,14.106 C7.288,14.106 6.98,13.988 6.746,13.754 L1.746,8.754 C1.278,8.288 1.278,7.526 1.746,7.058 C2.214,6.59 2.972,6.59 3.442,7.058 L7.594,11.208 L16.558,2.246 C17.028,1.778 17.786,1.778 18.254,2.246 Z" id="Shape"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
);

IconConfirmation.propTypes = {
  color: PropTypes.string,
  activeColor: PropTypes.string,
  isActive: PropTypes.bool,
  size: PropTypes.number
};

IconConfirmation.defaultProps = {
  activeColor: config.colors.rbYellow,
  color: config.colors.rbWhite,
  isActive: false,
  size: 16
};

export default IconConfirmation;