import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const JoinNumbers = () => {
	return (
		<Container className='py-5'>
			<Row className='text-center text-action'>
				<Col xs={12} lg={4} className='pt-3'>
					<Row>
						<Col className='text-title-numbers'>$15B</Col>
					</Row>
					<Row>
						<Col>in Buying Power</Col>
					</Row>
				</Col>
				<Col xs={12} lg={4} className='pt-3'>
					<Row>
						<Col className='text-title-numbers'>165,000+</Col>
					</Row>
					<Row>
						<Col>Rebate Line Items</Col>
					</Row>
				</Col>
				<Col xs={12} lg={4} className='pt-3'>
					<Row>
						<Col className='text-title-numbers'>65,000+</Col>
					</Row>
					<Row>
						<Col>Member Locations</Col>
					</Row>
				</Col>
			</Row>
		</Container>
	)
};

export default JoinNumbers;