import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import '../../SignUp.scss';
import { spinner } from '../../../../MasterComponents/Spinner/SpinnerActions';
import { isPhoneValid, isTextValid } from '../../../../MasterComponents/Input/InputValidate';
import SignUpApi from '../../SignUpApi';
import $ from 'jquery';
import { showModal } from '../../../../MasterComponents/Modals/ModalsActions';
import RequestInviteModal from '../../Modals/RequestInviteModal';
import ConfirmationModal from '../../Modals/ConfirmationModal';
import { sorArrayByKey } from '../../../../utils/utils';
import FormPorchDoorStep1 from './FormPorchDoorStep1';
import FormPorchDoorStep2 from './FormPorchDoorStep2';
import FormPorchDoorSuccessfully from './FormPorchDoorSuccessfully';
import ReactHtmlParser from 'react-html-parser';
import { geocodeByAddress } from 'react-places-autocomplete';

class FormPorchDoor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            location: 1,
            numberLocations: 1,
            numberDistributor: 0,
            showAddDistributor: false,
            showManualUpload: false,
            step1Inputs: {
                firstName: '',
                lastName: '',
                title: '',
                businessName: '',
                parentPlatformId: '',
                businessAddress: '',
                businessCity: '',
                businessStateObj: null,
                businessState: '',
                businessStateName: '',
                businessPostcode: '',
                phoneNumber: '',
                email: '',
                password: '',
                passwordRepeat: '',
                emailRepeat: '',
                daSalesRep: null,
                referredBy: '',
                referredName: '',
                mergedDaSalesRepReferred: null,
                addressSearch: '',
                showAddressDetails: false
            },
            arrayDistributors: [],
            step1Locations: [{
                nickname: '',
                platform_id: '',
                phoneNumber: '',
                address: '',
                city: '',
                stateObj: null,
                state: '',
                stateName: '',
                postcode: '',
                parent: false,
                isSkipEnabled: false
            }],
            step2Distributors: [],
            daSalesRepFromApi: [],
            referredPartnerFromApi: [],
            mergedDaSalesRepReferredFromApi: [],
            step2UnsavedDistributor: {},
            step3Inputs: {
                isAuthorized: false,
                isAuthorizedInvalid: false,
                isFsrAuthorized: false,
                isFsrAuthorizedInvalid: false,
                isLOTAuthorized: false,
                isLOTAuthorizedInvalid: false,
                isGpo: 0,
                isGpoTitle: 'No',
                gpoName: '',
                nameOfCompanies: [],
                isDistributorManufacturerAgreement: 0,
                isDistributorManTitle: 'No',
                legalName: '',
                terminationDate: new Date(),
                signedText: ''
            },
            distributorsHouse: [],
            distributorsHouseSelected: null
        }
    };
    componentDidMount = () => {
        this.getReferredPartner(() => {
            this.getSalesRep();
        });
        $('[data-toggle="tooltipContactName"]').tooltip();
        $('[data-toggle="tooltipPassword"]').tooltip();
        localStorage.setItem('isSignUpRoute', 1);
        this.getInitialRefferedBy();
        setTimeout(this.autofill, 100);
    };
    handleAddressSelect = (value) => {
        const { country } = this.props;
        geocodeByAddress(value).then(res => {
            const { address_components } = res[0];
            const streetNumber = address_components.filter(a => a.types[0] === 'street_number')[0];
            const street = address_components.filter(a => a.types[0] === 'route')[0] || address_components.filter(a => a.types[0] === 'neighborhood')[0];
            const city = address_components.filter(a => a.types[0] === 'locality')[0] || address_components.filter(a => a.types[0] === 'neighborhood')[0];
            const usState = address_components.filter(a => a.types[0] === 'administrative_area_level_1')[0];
            let zipcode = address_components.filter(a => a.types[0] === 'postal_code')[0];

            zipcode = zipcode.long_name.toString().trim()

            if (zipcode && country !== 'CA' && country !== 'ca') {
                if (zipcode.length > 5) zipcode = zipcode.substring(0, 5);
                if (zipcode.length < 5) zipcode = zipcode.padStart(5, '0');
            }

            this.setState({
                step1Inputs: {
                    ...this.state.step1Inputs,
                    addressSearch: (`${streetNumber ? streetNumber.long_name + ' ' : ''}${street ? street.long_name : ''}`) + (city ? `, ${city.long_name}` : '') + (usState ? `, ${usState.short_name}` : '') + (zipcode ? ` ${zipcode.long_name.toString()}` : ''),
                    businessAddress: (`${streetNumber ? streetNumber.long_name : ''}${street ? ' ' + street.long_name : ''}`),
                    businessCity: city ? city.long_name : '',
                    businessState: usState ? usState.short_name : '',
                    businessStateName: usState ? usState.long_name : '',
                    businessPostcode: zipcode ? zipcode : '',
                    businessStateObj: {
                        label: usState ? usState.long_name : '',
                        value: usState ? usState.short_name : ''
                    },
                    showAddressDetails: !(streetNumber && street && city && usState && zipcode) ? true : this.state.step1Inputs.showAddressDetails
                }
            });
        });
    };
    setAddressSearch = (value) => {
        this.setState({
            step1Inputs: {
                ...this.state.step1Inputs,
                addressSearch: value
            }
        });
    };
    clearAddressSearch = () => {
        this.setAddressSearch('');
    };
    blurAddressSearch = () => {
        this.handleAddressSelect(this.state.step1Inputs.addressSearch);
    };
    toggleAddressDetails = () => {
        this.setState({
            step1Inputs: {
                ...this.state.step1Inputs,
                showAddressDetails: !this.state.step1Inputs.showAddressDetails
            }
        });
    };
    autofill = () => {
        const { autofillInputs: af } = this.props;
        if (af) {
            this.setState({
                externalParentMerchantId: af.externalParentMerchantId ? af.externalParentMerchantId : null,
                step1Inputs: {
                    ...this.state.step1Inputs,
                    ...af.step1Inputs
                },
                step1Locations: [{
                    ...this.state.step1Locations[0],
                    ...af.firstLocation
                }]
            });
        }
    };
    getInitialRefferedBy = () => {
        const { initReferredBy } = this.props;
        if (initReferredBy) {
            if (!initReferredBy.iDontKnow) {
                this.disabledRefferedBy();
            }
            this.selectReferredBy(initReferredBy);
        }
    };
    scrollTop = () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    };
    getSalesRep = () => {
        const dispatch = this.props.dispatch;
        const search = null;
        let page = 1;
        let result = [];
        const apiCallSaleRep = () => {
            const params = {
                search,
                page
            }
            SignUpApi.getSalesRep({ params }).then((res) => {
                const loadMore = parseInt(res.headers['x-pagination-current-page']) < parseInt(res.headers['x-pagination-page-count']);
                const { data } = res;
                console.log(data)
                result = [...result, ...data.data];
                const daSalesRepFromApi = result.map(dataSales => ({
                    id: dataSales.id,
                    name: dataSales.firstName + ' ' + dataSales.lastName,
                    isDaSalesRep: true,
                    platformId: null
                }));
                if (loadMore) {
                    page += 1;
                    apiCallSaleRep();
                } else {
                    const salesRepSort = sorArrayByKey(daSalesRepFromApi, 'name');
                    this.setState({
                        daSalesRepFromApi,
                        mergedDaSalesRepReferredFromApi: salesRepSort
                    });
                }
            }).catch(function (error) {
                if (error) {
                    let errorMessage = error.data.data.message;
                    dispatch(showModal(ConfirmationModal, 'confirmationModal', { header: 'Message', icon: false, message: errorMessage }));
                }
            });
        }
        apiCallSaleRep();
    };
    getReferredPartner = (cb) => {
        const {
            brandCode, companyCode, companyId,
            dispatch, isCoolSchool, isReinhartCPOnly, referredPartnerBrand
        } = this.props;
        const brand = brandCode ? brandCode : companyCode;
        const search = null;
        let page = 1;
        let result = [];
        const apiCall = () => {
            const searchThis = referredPartnerBrand ? referredPartnerBrand : (isReinhartCPOnly ? 'da' : (isCoolSchool ? 'psi' : brand));
            SignUpApi.getReferredPartner(isReinhartCPOnly ? 1 : companyId, searchThis, page, search).then((res) => {
                const loadMore = parseInt(res.headers['x-pagination-current-page']) < parseInt(res.headers['x-pagination-page-count']);
                const { data } = res;
                result = [...result, ...data.data];
                const referred = result.map(ref => ({
                    id: ref.id,
                    name: ref.name,
                    isDaSalesRep: false,
                    platformId: ref.platformId
                }));
                if (loadMore) {
                    page += 1;
                    apiCall();
                } else {
                    const stateSort = sorArrayByKey(referred, 'name') // sorting the values by name
                    this.setState({ referredPartnerFromApi: result, mergedDaSalesRepReferredFromApi: stateSort });
                    cb();
                }
            }).catch(function (error) {
                if (error) {
                    let errorMessage = error.data.data.message;
                    dispatch(showModal(ConfirmationModal, 'confirmationModal', { header: 'Message', icon: false, message: errorMessage }));
                }
            });
        };

        apiCall();
    };

    //---------------STEP1-----------------------------------------------

    nextBackStep = (newStep) => {
        this.scrollTop();
        const { history, pathname, step } = this.props;
        if (newStep > step) {
            history.push(`${pathname}/${newStep}${history.location.search}`);
        } else {
            history.replace(`${pathname}/${newStep}${history.location.search}`);
        }
    };
    changeHandlerInputs = (value, name) => {
        const { step1Inputs: st1 } = this.state;
        this.setState({
            step1Inputs: {
                ...st1,
                [name]: value
            }
        }, () => {
            const { step1Inputs } = this.state;
            const { businessAddress, businessCity, businessState, businessPostcode } = step1Inputs;
            this.setState({
                step1Inputs: {
                    ...step1Inputs,
                    addressSearch: businessAddress + (businessCity ? ', ' + businessCity : '') + (businessState ? ', ' + businessState : '') + (businessPostcode ? ' ' + businessPostcode : '')
                }
            })
        });
    };
    changeHandlerLocationInput = (value, name) => {
        const newState = this.state.step1Locations;
        newState[this.state.location - 1] = {
            ...newState[this.state.location - 1],
            [name]: value
        }
        this.setState({
            step1Locations: newState
        });
    };
    updateNumberLocations = (value) => {
        const { step1Locations } = this.state
        let step1LocationsNew = []
        for (let i = 1; i < value; i++) {
            const emptyLocation = {
                nickname: '',
                phoneNumber: '',
                address: '',
                city: '',
                state: '',
                stateName: '',
                stateObj: null,
                postcode: '',
                parent: false,
                isSkipEnabled: false
            }
            step1LocationsNew.push(emptyLocation);
        }
        step1LocationsNew.unshift(step1Locations[0]);
        this.setState({
            step1Locations: step1LocationsNew,
            numberLocations: value
        });
    };
    backLocation = () => {
        this.scrollTop();
        const { location } = this.state
        this.setState({
            location: location - 1
        });
    };
    nextLocation = () => {
        const { step1Locations, step1Inputs } = this.state;
        this.scrollTop();
        const action = this.props.dispatch;
        const parent = {
            nickname: step1Inputs.businessName,
            platform_id: step1Inputs.parentPlatformId,
            phoneNumber: step1Inputs.phoneNumber,
            address: step1Inputs.businessAddress,
            city: step1Inputs.businessCity,
            state: step1Inputs.businessState,
            stateName: step1Inputs.businessStateName,
            postcode: step1Inputs.businessPostcode,
            parent: true
        };
        const objLocations = {
            locations: step1Locations.map((item, index) => {
                const { stateObj, ...rest } = item
                return {
                    ...rest
                }
            })
        }
        objLocations.locations.push(parent);
        action(spinner(true));
        SignUpApi.validateLocations(objLocations).then(({ data }) => {
            let result = data.data.result;
            if (result) {
                this.nextBackStep(2);
            } else {
                this.showRequestInviteModal(data.data.repeatedLocations);
            }
            action(spinner(false));
        })
            .catch(error => {
                action(spinner(false));
                if (error.data.data) {
                    action(showModal(ConfirmationModal, 'confirmationModal', { header: 'Validation', icon: false, message: 'There was a problem while validating locations' }));
                }
            });
    };
    skipLocation = (location) => {
        this.scrollTop();
        const { step1Locations } = this.state;
        let loc = location - 1;
        this.setState({
            step1Locations: step1Locations.map((item, index) => {
                if (index >= loc) {
                    return {
                        ...item,
                        isSkipEnabled: true
                    }
                } else {
                    return {
                        ...item,
                        isSkipEnabled: false
                    }
                }
            })
        });
        this.nextBackStep(2);
    };
    skipDistributor = () => {
        this.scrollTop();
        this.nextBackStep(3);
    };
    showRequestInviteModal = (repeatedLocations) => {
        const { dispatch } = this.props;
        dispatch(showModal(RequestInviteModal, 'requestInviteModal', { header: 'Request Invite', parents: repeatedLocations, user: this.state.step1Inputs, history: this.props.history, dispatch: this.props.dispatch }));
    };
    selectStateContactInformation = (value) => {
        this.setState({
            step1Inputs: {
                ...this.state.step1Inputs,
                businessStateObj: value,
                businessStateName: value.label,
                businessState: value.value
            }
        }, () => {
            const { step1Inputs } = this.state;
            const { businessAddress, businessCity, businessState, businessPostcode } = step1Inputs;
            this.setState({
                step1Inputs: {
                    ...step1Inputs,
                    addressSearch: businessAddress + (businessCity ? ', ' + businessCity : '') + (businessState ? ', ' + businessState : '') + (businessPostcode ? ' ' + businessPostcode : '')
                }
            })
        });
    };
    selectStateLocation = (value) => {
        const { step1Locations, location } = this.state;
        this.setState({
            step1Locations: step1Locations.map((item, index) => {
                if (index !== location - 1) {
                    return item;
                }
                return {
                    ...item,
                    stateObj: value,
                    stateName: value.label,
                    state: value.value
                }
            })
        })
    };
    selectTitle = (value) => {
        this.setState({
            step1Inputs: {
                ...this.state.step1Inputs,
                title: value
            }
        });
    };
    disabledRefferedBy = () => {
        this.setState({
            disabledRefferedBy: true
        })
    };
    selectDASalesRep = (value) => {
        this.setState({
            step1Inputs: {
                ...this.state.step1Inputs,
                daSalesRep: value
            }
        });
    };
    selectReferredBy = (value) => {
        this.setState({
            step1Inputs: {
                ...this.state.step1Inputs,
                referredBy: value,
                referredName: value.label,
                mergedDaSalesRepReferred: value,
            }
        });
    };

    validateEmailFromApi = (value) => {
        const action = this.props.dispatch;
        const objEmail = {
            email: value
        }
        action(spinner(true));
        SignUpApi.validateEmail(objEmail).then(({ data }) => {
            const { SignUpStep1 } = this.refs;
            let result = data.data;
            if (result.message !== '' && result.message !== null) {
                action(showModal(ConfirmationModal, 'confirmationModal', { header: 'Message', icon: false, message: result.message }));
                SignUpStep1.invalidEmail();
            }
            action(spinner(false));
        }).catch(function (error) {
            action(spinner(false));
            if (error) {
                let errorMessage = error.data.data.message;
                action(showModal(ConfirmationModal, 'confirmationModal', { header: 'Message', icon: false, message: errorMessage }));
            }
        });
    };
    validateRepeatedEmail = (value) => {
        const { email, emailRepeat } = this.state.step1Inputs;
        if (email !== emailRepeat) {
            const { SignUpStep1 } = this.refs;
            SignUpStep1.invalidConfirmEmail();
            SignUpStep1.invalidEmail();
        }
    };

    //---------------STEP2------------------------------------------

    changeHandlerDistributorInput = (value, name) => {
        const { step2Distributors, numberDistributor } = this.state;
        this.setState({
            step2Distributors: step2Distributors.map((item, index) => {
                if (index !== numberDistributor) {
                    return item;
                }
                return {
                    ...item,
                    [name]: value
                }
            })
        });
    };
    changeHandlerDistributorInputDcn = (value, i) => {
        const { step2Distributors, numberDistributor } = this.state;
        this.setState({
            step2Distributors: step2Distributors.map((item, index) => {
                if (index !== numberDistributor) {
                    return item;
                }
                return {
                    ...item,
                    dcn: item.dcn.map((item, index) => {
                        if (index !== i) {
                            return item;
                        }
                        return value;
                    })
                }
            })
        })
    };
    saveDistributor = (boolParam) => {
        this.setState({
            showAddDistributor: boolParam,
            step2Distributors: this.state.step2Distributors.map((item, index) => {
                if (index !== this.state.numberDistributor) {
                    return item;
                }

                return {
                    ...item,
                    phoneNumberReminder: (isPhoneValid(item.phoneNumberReminder, true) || item.phoneNumberReminder === '') ? item.phoneNumberReminder : this.state.step2UnsavedDistributor.phoneNumberReminder,
                    dcn: item.dcn.map((item, i) => {
                        return isTextValid(item, 3, 20) ? item : '';
                    })
                }
            })
        });
    };
    handleEditDistributor = (i) => {
        this.setState({
            numberDistributor: i,
            showAddDistributor: true,
            step2UnsavedDistributor: this.state.step2Distributors[i],
            isEmpty: false
        });

    };
    handleDeleteDistributor = (id) => {
        let newState = this.state.step2Distributors;
        this.setState({
            step2Distributors: newState.filter(distributor => (
                distributor.id !== id
            ))
        })
    };
    cancelDistributor = (boolParam) => {
        const { step2Distributors, numberDistributor, step2UnsavedDistributor, isEmpty } = this.state;
        if (isEmpty) {
            this.setState({
                showAddDistributor: boolParam,
                step2Distributors: step2Distributors.filter((item, index) => index !== numberDistributor)
            });
        } else {
            this.setState({
                showAddDistributor: boolParam,
                step2Distributors: step2Distributors.map((item, index) => {
                    if (index !== numberDistributor) {
                        return item
                    }

                    return {
                        ...step2UnsavedDistributor
                    }
                })
            });
        }
    };
    updateAddDistributor = (boolParam) => {
        const { step1Locations, step2Distributors } = this.state;
        let addThisTo = [false]
        let dcn = [];
        let credentials = [{
            username: '',
            password: ''
        }];
        step1Locations.forEach(loc => {
            addThisTo.push(false);
            dcn.push('');
            credentials.push({
                username: '',
                password: '',
                isOk: false
            })
        });
        const emptyDistributors = {
            id: '',
            distributorName: '',
            distributorSelected: null,
            dcn: dcn,
            credentials: credentials,
            branch: '',
            submissionType: '',
            phoneNumberReminder: '',
            emailReminder: '',
            addThisTo: addThisTo
        };
        this.setState({
            step2Distributors: [...step2Distributors, emptyDistributors],
            showAddDistributor: boolParam,
            numberDistributor: step2Distributors.length,
            step2UnsavedDistributor: emptyDistributors,
            isEmpty: true,
            distributorsHouseSelected: null
        });
    };
    updateCheckedLocations = (name) => {
        const { step2Distributors, numberDistributor } = this.state;
        const newValue = !step2Distributors[numberDistributor].addThisTo[name];

        this.setState({
            step2Distributors: step2Distributors.map((item, index) => {
                if (index !== numberDistributor) {
                    return item;
                }
                return {
                    ...item,
                    addThisTo: item.addThisTo.map((item, index) => {
                        if (name === 0) {
                            return newValue;
                        } else {
                            if (index === 0) {
                                return false;
                            }
                            if (index !== name) {
                                return item;
                            }
                            return newValue;
                        }
                    })
                }
            })
        });
    };
    selectOptionDistributor = (value) => {
        const { step2Distributors, numberDistributor } = this.state
        this.setState({
            step2Distributors: step2Distributors.map((item, index) => {
                if (index !== numberDistributor) {
                    return item;
                }
                return {
                    ...item,
                    distributorName: value.nickname,
                    id: value.id,
                    distributorSelected: value
                }
            })
        });
        this.getDistributorsHouse(value.id);
    };
    getDistributorsHouse = (id) => {
        this.setState({
            distributorsHouse: []
        });
        const { companyId } = this.props;
        SignUpApi.getDistributorsHouse(companyId, id).then(({ data }) => {
            const result = data.data;
            if (result.length > 0) {
                this.setState({
                    distributorsHouse: result.map(m => {
                        return {
                            label: m.nickname,
                            value: m.id
                        }
                    }),
                });
                this.setState({
                    distributorsHouseSelected: null,
                    distributorsHouse: [{
                        label: 'I dont know',
                        value: id
                    }, ...this.state.distributorsHouse]
                });
            } else {
                this.setState({
                    distributorsHouseSelected: {
                        label: 'Single House',
                        value: id
                    }
                });
            }
        });
    };
    selectDistributorHouse = (option) => {
        const { step2Distributors, numberDistributor } = this.state
        this.setState({
            distributorsHouseSelected: option,
            step2Distributors: step2Distributors.map((item, index) => {
                if (index !== numberDistributor) {
                    return item;
                }
                return {
                    ...item,
                    id: option.value
                }
            })
        });
    };
    saveCredentials = (username, password, isOk, locIndex) => {
        const { step2Distributors, numberDistributor } = this.state
        this.setState({
            step2Distributors: step2Distributors.map((item, index) => {
                if (index !== numberDistributor) {
                    return item;
                }
                return {
                    ...item,
                    credentials: item.credentials.map((item, index) => {
                        if (index !== locIndex) {
                            return item;
                        }
                        return {
                            username: username,
                            password: password,
                            isOk: isOk
                        }
                    })
                }
            })
        });
    };

    //---------------STEP3---------------------------------------------------

    selectOptionSubmissionType = (value) => {
        const { step2Distributors, numberDistributor } = this.state;
        this.setState({
            showManualUpload: value === 'Manual Upload' ? true : false,
            step2Distributors: step2Distributors.map((item, index) => {
                if (index !== numberDistributor) {
                    return item;
                }
                return {
                    ...item,
                    submissionType: value
                }
            })
        });
    };
    selectOptionMemberGPO = (value) => {
        this.setState({
            step3Inputs: {
                ...this.state.step3Inputs,
                isGpo: value,
                isGpoTitle: value === 1 ? 'Yes' : 'No',
                gpoName: value === 1 ? this.state.step3Inputs.gpoName : ''
            }
        });
    };
    selectOptionGPOName = (value) => {
        this.setState({
            step3Inputs: {
                ...this.state.step3Inputs,
                gpoName: value || ''
            }
        });
    };
    selectOptionDistributorManufacturer = (value) => {
        this.setState({
            step3Inputs: {
                ...this.state.step3Inputs,
                isDistributorManufacturerAgreement: value,
                isDistributorManTitle: value === 1 ? 'Yes' : 'No',
                nameOfCompanies: value === 1 ? this.state.step3Inputs.nameOfCompanies : []
            }
        });
    };
    handleChangeTerminationDate = (value) => {
        this.setState({
            step3Inputs: {
                ...this.state.step3Inputs,
                terminationDate: value
            }
        });
    };
    changeHandlerStep3Inputs = (value, name) => {
        this.setState({
            step3Inputs: {
                ...this.state.step3Inputs,
                [name]: value
            }
        });
    };
    authorizationChecked = (value) => {
        this.setState({
            step3Inputs: {
                ...this.state.step3Inputs,
                isAuthorized: !this.state.step3Inputs.isAuthorized,
                isAuthorizedInvalid: false
            },
        });
    };
    authorizationLOTChecked = (value) => {
        const prevValue = this.state.step3Inputs.isLOTAuthorized;
        const newValue = value === true ? true : (value === false ? false : !prevValue);
        this.setState({
            step3Inputs: {
                ...this.state.step3Inputs,
                isLOTAuthorized: newValue,
                isLOTAuthorizedInvalid: false,
                isGpo: newValue ? 1 : 0,
                isGpoTitle: newValue ? 'Yes' : 'No',
                gpoName: newValue ? this.state.step3Inputs.gpoName : ''
            }
        });
    };
    authorizationFsrChecked = (value) => {
        this.setState({
            step3Inputs: {
                ...this.state.step3Inputs,
                isFsrAuthorized: !this.state.step3Inputs.isFsrAuthorized,
                isFsrAuthorizedInvalid: false
            },
        });
    };
    authorizationInvalid = (a) => {
        this.setState({
            step3Inputs: {
                ...this.state.step3Inputs,
                isAuthorizedInvalid: a,
            }
        });
    };
    nameOfCompaniesHandler = (value) => {
        const { step3Inputs } = this.state;
        this.setState({
            step3Inputs: {
                ...step3Inputs,
                nameOfCompanies: value.map(item => {
                    if (item.isCustom) {
                        return {
                            ...item,
                            label: item.value,
                        };
                    }
                    return item;
                })
            }
        });
    };
    doneSignUp = () => {
        const { dispatch, country } = this.props;
        const { step1Inputs } = this.state;

        const allAddress = [{
            address: step1Inputs.businessAddress,
            city: step1Inputs.businessCity,
            state: step1Inputs.businessState,
            postcode: step1Inputs.businessPostcode
        }];

        dispatch(spinner(true));
        SignUpApi.getSmartyPlace(allAddress, country).then(({ data }) => {
            dispatch(spinner(false));
            const smartyLocations = data.data.map(loc => {
                let zipcode = loc.postcode.toString().trim()

                if (zipcode && country !== 'CA' && country !== 'ca') {
                    if (zipcode.length > 5) zipcode = zipcode.substring(0, 5);
                    if (zipcode.length < 5) zipcode = zipcode.padStart(5, '0');
                }
                return {
                    ...loc,
                    postcode: zipcode || ''
                }
            });

            this.doneSignUpEnd(smartyLocations);
        }).catch(err => {
            console.error(err);
            dispatch(spinner(false));
            dispatch(showModal(ConfirmationModal, 'confirmationModal', { header: 'Message', icon: false, message: "Something wen't wrong with the address." }));
        });
    };
    doneSignUpEnd = (smartyLocations) => {
        const {
            dispatch, company, brandSelected, companyId,
            companyName, companyCode, segment, subsegment,
            isCoolSchool, isReinhartCPOnly, brandCode, brandName,
            showReportingLocations, campaign
        } = this.props;
        const { step3Inputs, step1Inputs, numberLocations } = this.state;
        const { isAuthorized, isAuthorizedInvalid, isFsrAuthorized, isFsrAuthorizedInvalid, nameOfCompanies, terminationDate, isGpo, gpoName, ...lop } = step3Inputs;
        const { daSalesRep, businessStateObj, password, passwordRepeat, title, businessStateName, referredName, mergedDaSalesRepReferred, firstName, lastName, ...step1 } = step1Inputs;
        const { isDistributorManTitle, isGpoTitle, ...step3 } = lop;

        const objSignUp = {
            ...step1,
            title: title || 'Other',
            businessAddress: smartyLocations[0].address,
            businessCity: smartyLocations[0].city,
            businessState: smartyLocations[0].state,
            businessPostcode: smartyLocations[0].postcode,
            contactName: firstName + ':' + lastName,
            siteUrl: `https://${window.location.hostname}/`,
            link: company,
            companyId: companyId,
            brandCode: brandCode ? brandCode : (isReinhartCPOnly ? 'da' : (isCoolSchool ? 'psi' : (brandSelected ? brandSelected.abbreviation : companyCode))),
            brandName: brandName ? brandName : (isReinhartCPOnly ? 'dining alliance' : (isCoolSchool ? 'Prime Services' : (brandSelected ? brandSelected.name : companyName))),
            brand: isReinhartCPOnly ? 'dining alliance' : (isCoolSchool ? 'Prime Services' : (brandSelected ? brandSelected.name : companyName)),
            brandForm: isReinhartCPOnly ? 'dining alliance' : (isCoolSchool ? 'coolschool' : (brandSelected ? brandSelected.name.toLowerCase() : companyName.toLowerCase())),
            daSalesRep: step1Inputs.daSalesRep ? step1Inputs.daSalesRep.label : null,
            referredBy: step1Inputs.referredBy ? (step1Inputs.referredBy.platformId || step1Inputs.referredBy.value) : '',
            isBuyersEdgePortal: true,
            lop: {
                ...step3,
                isFsr: isAuthorized ? 1 : 0,
                legalName: step1Inputs.businessName,
                isGpo: isGpo ? 1 : 0,
                gpoName: isGpo ? (gpoName ? gpoName : '') : '',
                terminationDate: moment(terminationDate).format('YYYY-MM-DD'),
                nameOfCompanies: nameOfCompanies.map(item => {
                    return item.nickname
                }).toString(),
                manufacturers: nameOfCompanies.map(item => {
                    return item.id
                })
            },
            totalReportingLocations: showReportingLocations ? numberLocations : null,
            segment: segment,
            subsegment: subsegment,
            primaryCampaign: campaign
        };
        dispatch(spinner(true));
        SignUpApi.signupBE(objSignUp).then(({ data }) => {
            dispatch(spinner(false));
            this.nextBackStep(3);
        }).catch(function (error) {
            dispatch(spinner(false));
            if (error) {
                let errorMessage = error.data.data.message;
                dispatch(showModal(ConfirmationModal, 'confirmationModal', { header: 'Message', icon: false, message: ReactHtmlParser(errorMessage) }));
            }
        });
    };
    goToNextStep = () => {
        let step1 = <FormPorchDoorStep1 ref='SignUpStep1' isPorchDoor={true}
            disabledRefferedBy={this.state.disabledRefferedBy}
            numberLocations={this.state.numberLocations}
            updateNumberLocations={this.updateNumberLocations}
            changeHandlerInputs={this.changeHandlerInputs}
            step1Inputs={this.state.step1Inputs}
            selectTitle={this.selectTitle}
            {...this.props}
            daSalesRepFromApi={this.state.daSalesRepFromApi}
            selectDASalesRep={this.selectDASalesRep}
            mergedDaSalesRepReferredFromApi={this.state.mergedDaSalesRepReferredFromApi}
            referredPartnerFromApi={this.state.referredPartnerFromApi}
            selectReferredBy={this.selectReferredBy}
            selectStateContactInformation={this.selectStateContactInformation}
            stateLocation={this.state.location}
            changeHandlerLocationInput={this.changeHandlerLocationInput}
            step1Locations={this.state.step1Locations}
            selectStateLocation={this.selectStateLocation}
            onBackClick={this.backLocation}
            onNextClick={this.nextLocation}
            onSkipClick={this.skipLocation}
            validateEmailFromApi={this.validateEmailFromApi}
            validateRepeatedEmail={this.validateRepeatedEmail}
            addressSearch={this.state.step1Inputs.addressSearch}
            handleAddressSelect={this.handleAddressSelect}
            setAddressSearch={this.setAddressSearch}
            clearAddressSearch={this.clearAddressSearch}
            blurAddressSearch={this.blurAddressSearch}
            showAddressDetails={this.state.step1Inputs.showAddressDetails}
            toggleAddressDetails={this.toggleAddressDetails}
        />;
        let step2 = <FormPorchDoorStep2
            {...this.props}
            isPorchDoor={this.props.isPorchDoor} brandSelected={this.props.brandSelected}
            selectOptionGPOName={this.selectOptionGPOName}
            isCoolSchool={this.props.isCoolSchool}
            companyName={this.props.companyName} companyAbbreviation={this.props.companyAbbreviation}
            dispatch={this.props.dispatch} company={this.props.company}
            changeHandlerStep3Inputs={this.changeHandlerStep3Inputs}
            selectOptionMemberGPO={this.selectOptionMemberGPO}
            selectOptionDistributorManufacturer={this.selectOptionDistributorManufacturer}
            step1Inputs={this.state.step1Inputs}
            step3Inputs={this.state.step3Inputs}
            nameOfCompaniesHandler={this.nameOfCompaniesHandler}
            authorizationLOTChecked={this.authorizationLOTChecked}
            authorizationChecked={this.authorizationChecked}
            authorizationFsrChecked={this.authorizationFsrChecked}
            authorizationInvalid={this.authorizationInvalid}
            handleChangeTerminationDate={this.handleChangeTerminationDate}
            onBackClick={this.nextBackStep}
            onDoneClick={() => this.doneSignUp()}
        />;
        let step3 = <FormPorchDoorSuccessfully isPorchDoor={this.props.isPorchDoor} step1Inputs={this.state.step1Inputs} />;

        switch (this.props.step) {
            case 1:
                return step1;
            case 2:
                return step2;
            case 3:
                return step3;
            default:
                return step1;
        }
    };
    render() {
        return (
            <div className='signup-form'>
                {this.goToNextStep()}
            </div>
        )
    };
}

const mapStateToProps = state => {
    return {
        spinner: state.spinner.spinner,
        partnerSelected: state.company.partnerSelected,
        brandSelected: state.company.brandSelected,
        companyId: state.company.id,
        companyCode: state.company.code,
        companyName: state.company.nickname,
        companyAbbreviation: state.company.abbreviation,
        segment: state.company.segment,
        subsegment: state.company.subsegment
    };
}

export default connect(mapStateToProps)(FormPorchDoor);
