import React, { Component } from 'react';
import ModalHeader from '../../../MasterComponents/Modals/ModalHeader';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import Modal from '../../../MasterComponents/Modals/Modal';
import ModalFooter from '../../../MasterComponents/Modals/ModalFooter';
import Button from '../../../MasterComponents/Button/Button';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import InputLabel from '../../../MasterComponents/InputLabel/InputLabel';
import { isMobile } from 'react-device-detect';
import InsightsApi from '../InsightsApi';
import { spinner } from '../../../MasterComponents/Spinner/SpinnerActions';
import { findModal } from '../../../MasterComponents/Modals/ModalsActions';

class InsightsBodyOpportunitiesOrderModal extends Component {
  componentDidMount() {
    const { dispatch, original, match, platformIds } = this.props;
    dispatch(spinner(true));
    InsightsApi.swapItem(original, match, platformIds).then(() => {
      dispatch(spinner(false));
    }).catch(err => {
      console.error(err);
      dispatch(spinner(false));
    });
  };
  copyToClipboard = () => {
    var copyText = document.getElementById("copytextarea");
    
    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/
    
    /* Copy the text inside the text field */
    document.execCommand("copy");
  
    const { modals } = this.props;
    findModal(modals, 'InsightsBodyOpportunitiesOrderModal').close();
  };
  render() {
    const { match } = this.props
    const { mfr, description, min } = match

    const textValue = `Hello Distributor,\n\rCould you please add the following item to the order guide:\n\r${mfr.replace(/\b\w/g, l => l.toUpperCase())}\n${description.replace(/\b\w/g, l => l.toUpperCase())}\nMIN: ${min}\n\rThank you!`;

    return (
      <Modal id='InsightsBodyOpportunitiesOrderModal' fullHeight={isMobile}>
        <ModalHeader noBorder blueHeader={isMobile}>
          Copy the text below and send to your distributor to order
        </ModalHeader>
        <ModalBody className='text-center text-size-20 line-height-20'>
          <GridRow>
            <GridCol className='text-left'>
              <InputLabel text='Message' />
            </GridCol>
          </GridRow>
          <GridRow className='line-form'>
            <GridCol className='form-group'>
              <textarea id='copytextarea' rows='11' className='w-100 text-size-16' defaultValue={textValue} />
            </GridCol>
          </GridRow>
          {
            isMobile ?
            <GridRow>
              <GridCol>
                <Button text='COPY TO CLIPBOARD' variant='primary' onClick={this.copyToClipboard} />
              </GridCol>
            </GridRow>
            : <></>
          }
        </ModalBody>
        {
          !isMobile ?
          <ModalFooter justify='start'>
            <Button text='COPY TO CLIPBOARD' size='md' variant='primary' onClick={this.copyToClipboard} />
          </ModalFooter>
          : <></>
        }
      </Modal>
    )
  }
};

export default InsightsBodyOpportunitiesOrderModal;
