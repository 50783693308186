import React from 'react';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import useHomeAction from './hooks/useHomeAction';
import IconMarker from '../../MasterComponents/Icons/IconMarker';
import actionImg from '../../assets/images/action-empty.svg';
import { getIconMarkerColor } from '../../config/Company/CompanyHelper';

const HomeActionsRequired = (props) => {
  const { companyId } = props;
  const { onActivate, onClickEdit, onGoToLocations, onUploadNow, notificationList }  = useHomeAction(props);
  const notificationTop4 = notificationList.slice(0, 4);
  const iconMarkerColor = getIconMarkerColor(companyId);

  const onClickActivate = (n) => {
    onActivate(n)
  }
  const onClickEditCredential = (n) => {
    onClickEdit(n)
  }
  const clickUploadNow = (n) => {
    onUploadNow(n);
  }

  const actionEmpty = (
    <Col className='text-center py-5'>
      <Row>
        <Col xs={12} className='pt-4 pb-4'>
          <img src={actionImg} alt='action' />
        </Col>
        <Col xs={12} className='text-size-20 line-height-24'>
          Congrats! It looks like you{"'"}re all set
        </Col>
      </Row>
    </Col>
  );

  const actionList = notificationTop4.map((n, i)=> (
    <Col key={`act_${i}`} className='border-bottom py-2 px-1'>
      <Row className='text-size-14 line-height-17 py-2'>
        <Col xs={isMobile ? 12 : 8}>
          <span className='text-bold'>{n.distributor}</span> - {n.message}
        </Col>
        <Col xs={isMobile ? 12 : 4} className={'text-blue text-uppercase clickable ' + (isMobile ? 'text-left pt-2 ' : 'text-right')}>
          { (n.type === 'backupRequired' || n.type === 'backupSuggested') &&
            <Col className='pl-0 clickable' onClick={()=> onClickActivate(n)}>
              Provide Backup
            </Col>
          }
          { (n.type === 'failedActivation' || n.type === 'failedAccountLocked' || n.type === 'failedDcn' || n.type === 'failedPermission')  &&
            <Col className='pl-0 clickable' onClick={()=> onClickActivate(n)}>
              Connect
            </Col>
          }
          { n.type === 'failedPassword' &&
            <Col className='pl-0 clickable' onClick={()=>onClickEditCredential(n)}>
              Reconnect
            </Col>
          }
          { n.type === 'failedUpload' &&
            <Col className='pl-0 clickable' onClick={()=>clickUploadNow(n)}>
              Upload Now
            </Col>
          }
        </Col>
      </Row>
      <Row className='text-size-14 line-height-17 py-2'>
        <Col>
          <IconMarker color={iconMarkerColor} size={15}/><span className='px-2 location-class'>{ n.merchant ? n.merchant.nickname : '' }</span>
        </Col>
      </Row>
    </Col>
  ));

  return (
    <>
      <Row className={'line-height-38 ' + (isMobile ? 'text-size-30' : 'text-size-32')}>
        <Col className={'py-3 ' + (isMobile ? 'px-0' : '')} >
          Action Required
        </Col>
      </Row>
      <Row>
        <Col className={isMobile ? 'px-0' : ''}>
          <Card className='scrollbar-custom' style={{height: isMobile ? null : '320px', overflowY: 'auto'}}>
            <CardBody className={isMobile ? 'px-1' : ''}>
              { notificationTop4.length > 0 ?
                actionList : actionEmpty
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className='py-3'>
        <Col className={isMobile ? 'px-0' : ''}>
          <Button active block style={{width: isMobile ? '100%' : ''}} className='py-2 shadow-none' size='sm' color='primary'onClick={onGoToLocations} >VIEW ALL</Button>
        </Col>
      </Row>
    </>
  )
}

export default HomeActionsRequired;
