import React from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'reactstrap';
import ContractTable from './Components/ContractTable/ContractTable';
import ContractTop from './Components/ContractTop/ContractTop';
import useContractManagement from './useContractManagement';

const ContractManagement = (props) => {
	const contractProps = useContractManagement();
	const company = useSelector(({company}) => company);
	return (
		<Container fluid className={`px-3 py-1 flex-grow-1 ${ company && company.id === 3 ? 'bg-white' : 'bg-grey-home'}`}>
			<ContractTop {...contractProps} />
			<ContractTable {...contractProps} />
		</Container>
	);
};

export default ContractManagement;