import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconAdd = props => (
  <svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 10 10" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Designs" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="27---Offline-Distributor-Activated" transform="translate(-1157.000000, -315.000000)" fill={!props.isActive ? props.color : props.activeColor}>
        <g id="offline-distributor" transform="translate(1050.000000, 140.000000)">
          <g id="Group-2" transform="translate(107.000000, 172.000000)">
            <g id="icons/add" transform="translate(0.000000, 3.000000)">
              <path d="M9,4 L6,4 L6,1 C6,0.447 5.553,0 5,0 C4.447,0 4,0.447 4,1 L4,4 L1,4 C0.447,4 0,4.447 0,5 C0,5.553 0.447,6 1,6 L4,6 L4,9 C4,9.553 4.447,10 5,10 C5.553,10 6,9.553 6,9 L6,6 L9,6 C9.553,6 10,5.553 10,5 C10,4.447 9.553,4 9,4 Z" id="Path"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

IconAdd.propTypes = {
  color: PropTypes.string,
  activeColor: PropTypes.string,
  isActive: PropTypes.bool,
  size: PropTypes.number,
};

IconAdd.defaultProps = {
  activeColor: config.colors.rbYellow,
  color: config.colors.rbWhite,
  isActive: false,
  size: 16,
};

export default IconAdd;