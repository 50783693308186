import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import IconAdd from '../../MasterComponents/Icons/IconAdd';
import IconSubtract from '../../MasterComponents/Icons/IconSubtract';
import config from '../../config/config';

const FoodCostNavbarItem = (props) => {
  const { navbarText, isActive, data, buttonText, onClickNav, onClickGetStarted } = props;
  
  if(isMobile) {
    return (
      <>
        <Col xs={12} className={`${isActive ? '' : 'text-black'} text-size-20 line-height-30  py-3 mx-3 font-weight-bold clickable border`}
          onClick={()=>onClickNav(data)}>
            <Row>
              <Col xs={10}>
                {navbarText}
              </Col>
              { isActive ?
                <Col xs={2} className='px-2'>
                  <IconSubtract size={18} color={config.colors.rbDarkGrey} />
                </Col>
               :
                <Col xs={2} className='px-2'>
                  <IconAdd size={18} color={config.colors.rbDarkGrey} />
                </Col>
              } 
            </Row>
        </Col>
        { isActive && 
          <Col className='py-3 mx-4'>
            <Row>
              <Col>
                <img src={data.urlImage} width='100%' height='100%' alt='foodcost'/>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className='text-size-16 line-height-24 font-weight-bold py-2'>
                {data.viewTitle}
              </Col>
              <Col xs={12} className='text-size-12 line-height-20'>
                {data.viewBodyText}
              </Col>
            </Row>
            <Row>
              <Col className='py-4'>
                <Button color='primary' size='md' className='text-uppercase shadow-none' onClick={onClickGetStarted}>
                  {buttonText}
                </Button>
              </Col>
            </Row>
          </Col>
        }
      </>
    )
  }
  return (
    <>
      <Col xs={12} className={`${isActive ? 'bg-primary text-white' : 'text-black'}  py-3 clickable`}
        onClick={()=>onClickNav(data)} style={{height: '54px', width: '240px'}}>
        {navbarText}
      </Col>
    </>
  )
};
export default FoodCostNavbarItem;