import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { showModal } from '../../../MasterComponents/Modals/ModalsActions';
import { dataViewArray } from '../FoodCostData';
import FoodCostApi from '../FoodCostApi';
import SalesRepModal from '../Modals/SalesRepModal';
import ThankYouModal from '../Modals/ThankYouModal';
const calendarLink = `https://calendly.com/d/y259-44f6/food-cost-management-webinar?month=2021-10`;
const urlLearnMore = `https://intercom.help/foodcostmanagement/en/articles/6640961-how-to-enroll-in-food-cost-management-from-mygpo-portal`;

const useFoodCost = (props) => {
  const { userCurrent } = props;
  const companyId = useSelector(({company}) => company.id);
  const textUser = (userCurrent && userCurrent.fcmAccess) ? 'Get Started' : 'Request a Demo';
  const buttonText = companyId === 3 ? 'Get Started' : textUser;
  const [dataView, setDataView] = useState([{
    id: 0,
    navbarText: 'Overview',
    viewTitle: ``,
    viewBodyText: ``,
    urlImage: '',
    isActive: true
  }]);
  const [view, setView] = useState({
    viewTitle: `It's time to take things Digital`,
    viewBodyText: `Food Cost Management allows restaurants to more deeply understand their business. Run dynamic recipe costs that shift as prices change, track food cost percentage, and make inventory bookkeeping a breeze!`,
    urlImage: `https://rebate-portal.s3.amazonaws.com/resources/foodcost/images/overview.svg`
  });

  useEffect(() => {
    setDataView(dataViewArray)
  }, []);

  const onClickNav = (data) => {
    setDataView(
      dataView.map((d) => {
        if(d.id === data.id){
          return {
            ...d,
            isActive: !d.isActive
          }
        }
        return {
          ...d, 
          isActive: false
        };
      })
    );

    setView({
      ...view,
      viewTitle: data.viewTitle,
      viewBodyText: data.viewBodyText,
      urlImage: data.urlImage
    })
  }

  const onClickGetStarted = () => {
    const { dispatch, userCurrent, history } = props;
    const isAdmin = localStorage.getItem('authKeyAdmin');
    const isAnalyst = localStorage.getItem('authKeyAnalyst');
    if (!isAdmin || (isAdmin && isAnalyst)) {
      if (userCurrent.fcmAccess) {
        dispatch(showModal(SalesRepModal, 'salesRepModal', { history: history}));
      } else {
        FoodCostApi.requestFoodCost().then(()=> {
          dispatch(showModal(ThankYouModal, 'idThankYouModal', {}));
          window.open(calendarLink, '_blank');
        }).catch((err)=> {
          console.error(err);
        });
      }
    }
  };

  const onClickLearn = () => {
    window.open(urlLearnMore, '_blank');
  }

  return {
    view,
    dataView,
    onClickNav,
    onClickGetStarted,
    onClickLearn,
    buttonText
  }
};

export default useFoodCost;
