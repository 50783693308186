import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import Header from './Components/Header';
import SignUpSimpleSidebar from '../SignUpSimple/Components/SignUpSimpleSidebar';
import OnboardingContent from './OnboardingContent';
import { showModal } from '../../MasterComponents/Modals/ModalsActions';
import WelcomeModal from './WelcomeModal/WelcomeModal';
import { useDispatch, useSelector } from 'react-redux';

const Onboarding = (props) => {
  const dispatch = useDispatch();
  const [maxStep, setMaxStep] = useState(1);
  const [distributorName, setdistributorName] = useState(1);
  const urlStep = parseInt(props.match.params.urlStep);

  const company = useSelector(({company}) => company);

  const propsSidebar = {
    history: props.history,
    company,
    isFoodServiceRewards: false,
  };

  useEffect(() => {
    if (urlStep < maxStep) {
      setMaxStep(urlStep || 1);
    }
    //eslint-disable-next-line
  }, [props.match.params.urlStep]);

  useEffect(() => {
    dispatch(showModal(WelcomeModal, 'welcomeModal'));
    //eslint-disable-next-line
  }, []);

  const onDistList = props.history.location.pathname === '/onboarding/2';

  return (
    <div className='container-signup'>
      {
        !isMobile && <SignUpSimpleSidebar {...propsSidebar} />
      }
      <div className={`right-signup${isMobile ? '' : ' mt-5 pl-5'}`}>
        <Header goBack={() => props.history.goBack()} showBack={urlStep === 1 ? false : !onDistList} title={urlStep === 1 ? 'Locations' : ( onDistList ? 'Distributors' : `Activate ${distributorName}`)} />
        <OnboardingContent history={props.history} maxStep={maxStep} setMaxStep={setMaxStep} setdistributorName={setdistributorName} />
      </div>
    </div>
  );
};

export default Onboarding;