import React, { Component} from 'react';
import {connect} from 'react-redux';
import './Home.scss';
import HomeHeader from './HomeHeader';
import HomeContent from './HomeContent';
import HomeContentDA from './HomeContentDA';
import { COMPANY_ID } from '../../config/config';

class Home extends Component {
    componentDidUpdate() {
        const homepage = this.props.homepage;
        if (homepage) {
            window.location.replace(homepage);
        }
    };
    render() {
        const { history, companyId } = this.props;
        const homeProps = {
            history,
            companyId
        }
        return (
            <div className='home-container'>
                <HomeHeader/>
                {
                    companyId === COMPANY_ID.DA ? <HomeContentDA {...homeProps} /> : <HomeContent {...homeProps}/>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
      homepage: state.company.homepage,
      companyId: state.company.id
    }
};

export default connect(mapStateToProps)(Home);