import React, {useRef} from 'react';
import { useSelector } from 'react-redux';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import DropDownListSelect from '../../../MasterComponents/DropDownList/DropDownListSelect';
import DatePicker from 'react-datepicker';
import JoinPlaces from './JoinPlaces';
import { isMobile, isTablet } from 'react-device-detect';
import SignatureCanvas from 'react-signature-canvas'

const JoinSignupFormBig = (props) => {
	const {
		firstName, lastName, title, isGpo, isDma, termsAndConditions,
		email, password, repeatPassword, reportingLocations,
		nameOfCompanies, form, terminationDate, gpoName, onCreateAccount,
		cleanSignatureHandler, sendSignatureHandler
	} = props;
	const { invalid } = form;
	const terminationDateText = useSelector(({company}) => company.text.terminationDate);

  const sigCanvas = useRef();
  const cleanSignature = () => {
    sigCanvas.current.clear();
    cleanSignatureHandler();
  }
  const sendSignature = () => {
    const imageData = sigCanvas.current.getTrimmedCanvas().toDataURL();
    sendSignatureHandler(imageData);
  }
  const isPortrait = window.screen.height > window.screen.width;
  const signatureWidthPortrait = isTablet ? 690 : (isMobile ? 342 : 500);
  const signatureWidthLandscape = isTablet ? 685 : (isMobile ? 535 : 500);
	const signatureWidth = isPortrait ? signatureWidthPortrait : signatureWidthLandscape;

	const onFormSubmit = (e) => {
		e.preventDefault();
	};

	return (
		<Col>
			<Row className='mb-2'>
				<Col className='text-signup-title text-center'>CREATE YOUR MY DINING ALLIANCE ACCOUNT NOW</Col>
			</Row>
			<Form onSubmit={onFormSubmit}>
				<FormGroup>
					<Label className={invalid.some(i => i === 'firstName') ? 'text-danger' : ''}>
						First Name *
					</Label>
					<Input {...firstName} />
					<Label className={invalid.some(i => i === 'lastName') ? 'text-danger' : ''}>
						Last Name *
					</Label>
					<Input {...lastName} />
					<Label>
						Title *
					</Label>
					<DropDownListSelect {...title} />
					<JoinPlaces {...props} />
					<Label className={invalid.some(i => i === 'email') ? 'text-danger' : ''}>
						Email *
					</Label>
					<Input {...email} />
					<Label className={invalid.some(i => i === 'password') ? 'text-danger' : ''}>
						Create Password *
					</Label>
					<Input {...password} />
					<Label className={invalid.some(i => i === 'repeatPassword') ? 'text-danger' : ''}>
						Confirm Password *
					</Label>
					<Input {...repeatPassword} />
					<Label>
						How many locations will be reporting *
					</Label>
					<DropDownListSelect {...reportingLocations} />
					<Label>
						Are you a Member of a Group Purchasing Organization (GPO) *
					</Label>
					<DropDownListSelect {...isGpo} />
					{
						form.isGpo &&
						<>
							<Label className={invalid.some(i => i === 'terminationDate') ? 'text-danger' : ''}>
								{terminationDateText}
							</Label>
							<DatePicker {...terminationDate} wrapperClassName='w-100' className='rb-input form-control w-100' />
							<Label className={invalid.some(i => i === 'gpoName') ? 'text-danger' : ''}>
								Group Purchasing Organization *
							</Label>
							<DropDownListSelect {...gpoName} />
						</>
					}
					<Label>
						Direct Manufacturer Agreements (Direct deals)
					</Label>
					<DropDownListSelect {...isDma} />
					{
						form.isDma &&
						<>
							<Label className={invalid.some(i => i === 'nameOfCompanies') ? 'text-danger' : ''}>
								Name of Direct Deal Manufacturer (to prevent 'double dipping') *
							</Label>
							<DropDownListSelect {...nameOfCompanies} />
						</>
					}
					<div className={`rb-checkbox custom-control custom-checkbox top-0 ${invalid.some(i => i === 'termsAndConditions') ? 'text-danger' : ''}`}>
						<input {...termsAndConditions} type='checkbox' id='tandc'/>
						<label className='custom-control-label text-size-12' htmlFor='tandc'>By clicking you agree to our <a target="_blank" rel="noopener noreferrer" style={{display: "inline-block"}} href='https://www.diningalliance.com/membership-terms-conditions/' className={'text-warning'}>Terms and Conditions</a></label>
					</div>
					<Label>
						Signed by *
					</Label>
					<Col onMouseLeave={sendSignature} className='bg-white p-0'>
						<SignatureCanvas penColor='black' canvasProps={{width: signatureWidth, height: 120, className: 'sigCanvas border'}}
							ref={sigCanvas}
						/>
					</Col>
					<Button color='white' outline className='mt-3' onClick={cleanSignature}>Clear</Button>
					<Button block className='mt-4' color='warning' type='submit' onClick={onCreateAccount}>
						CREATE ACCOUNT
					</Button>
				</FormGroup>
			</Form>
		</Col>
	);
};

export default JoinSignupFormBig;