import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import GridCol from '../../MasterComponents/Grid/GridCol';
import { getTopbarBackgroundColor, getTopbarTextColor } from '../../config/Company/CompanyHelper';

const DashboardTopbarLink = props => {
  const { companyId } = props;
  const topbarTextColor = getTopbarTextColor(companyId);
  const topbarBackgroundColor = getTopbarBackgroundColor(companyId);

  return  (
    <GridCol maxWidth={props.maxWidth+'px'} onClick={props.onClick} onMouseLeave={props.onMouseLeave} onMouseEnter={props.onMouseEnter} className={(props.isActive ? `active ${topbarTextColor} ${topbarBackgroundColor} ` : '') + 'links text-nowrap' + (isMobile ? ' mobile' : '') + (props.className ? ` ${props.className}` : '')} align='center'>
      {isMobile ? <div><props.Icon /></div> : <props.Icon />}
      {isMobile ? <div><props.Icon2 /></div> : <props.Icon2 />}
      {isMobile ? <div>{props.title}</div> : props.title}
    </GridCol>
  )
  
};

DashboardTopbarLink.propTypes = {
  title: PropTypes.string.isRequired, 
  Icon: PropTypes.func.isRequired,
  Icon2: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClick: PropTypes.func,
  maxWidth: PropTypes.number
};

DashboardTopbarLink.defaultProps = {
  isActive: false
};

export default DashboardTopbarLink;