import React, { Component } from 'react';
import ModalHeader from '../../../MasterComponents/Modals/ModalHeader';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import ModalFooter from '../../../MasterComponents/Modals/ModalFooter';
import Button from '../../../MasterComponents/Button/Button';
import Modal from '../../../MasterComponents/Modals/Modal';
import sampleBg from '../../../assets/images/sample.png';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import { findModal } from '../../../MasterComponents/Modals/ModalsActions';
import { isMobile } from 'react-device-detect';
import InsightsApi from '../InsightsApi';
import { spinner } from '../../../MasterComponents/Spinner/SpinnerActions';

class InsightsBodyOpportunitiesSampleModal extends Component {
  ok = () => {
    findModal(this.props.modals, 'InsightsBodyOpportunitiesSampleModal').close();
  };
  componentDidMount() {
    const { dispatch, original, match, platformIds } = this.props;
    dispatch(spinner(true));
    InsightsApi.sampleItem(original, match, platformIds).then(() => {
      dispatch(spinner(false));
    }).catch(err => {
      console.error(err);
      dispatch(spinner(false));
    });
  };
  render() {
    return (
      <Modal id='InsightsBodyOpportunitiesSampleModal'>
        <ModalHeader noBorder>
          Get a Sample
        </ModalHeader>
        <ModalBody className='text-center text-size-20 line-height-20'>
          <GridRow>
            <GridCol>
              <img src={sampleBg} alt='sample' />
            </GridCol>
          </GridRow>
          <GridRow>
            <GridCol>
              Thank you, we will be in touch with information about your sample.
            </GridCol>
          </GridRow>
        </ModalBody>
        <ModalFooter>
          <Button text='Ok' size={ isMobile ? '' : 'md'} variant='primary' onClick={this.ok} />
        </ModalFooter>
      </Modal>
    )
  }
};

export default InsightsBodyOpportunitiesSampleModal;
