import config from '../../config/config';
import SignUpApi from '../../views/SignUp/SignUpApi';

export const getCountries = () => {
  const options = config.countries.map((item) => {
    return {
      label: item.name,
      value: item.abbreviation
    }
  });
  return options;
};

export const getStatesProvinces = (country = 'us') => {
  if(country) {
    const countryUC = country.toUpperCase();
    let arrStatesProvinces;
    switch (countryUC) {
      case 'CA':
        arrStatesProvinces = config.caProvinces;
        break;
      case 'US':
        arrStatesProvinces = config.usStates;
        break;
      default:
        arrStatesProvinces = config.usStates;
        break;
    }
    const options = arrStatesProvinces.map((item) => {
      return {
        label: item.name,
        value: item.abbreviation
      }
    });
    return options;
  }
};

const getPlacesProps = function (country = 'us', predictionsState = 'predictions', search, onSelect, placeholder, name) {
  return {
    placeholder: placeholder || 'Search Business Name...',
    name: name || 'googlePlaces',
    blurInputOnSelect: true,
    openMenuOnClick: false,
    filterOption: false,
    onInputChange: 3,
    components: { DropdownIndicator:() => null, IndicatorSeparator:() => null },
    onSearch: (string) => {
      SignUpApi.getPlaceSearch(string, country).then(({data}) => {
        this.setState({
          [predictionsState]: data.data.predictions.map(pred => {
            return {
              label: pred.description,
              value: pred.place_id
            }
          })
        })
      });
    },
    options: this.state[predictionsState],
    menuIsOpen: false,
    value: (search && {label: search, value: search }) || '',
    onChange: ({label, value}) => {
      onSelect(label, value);
    }
  }
};

export default getPlacesProps;