import React from 'react';

const HiddenField = props => {
    const { value, selectorId } = props;
	return ( 
		<span style={{display: 'none'}} id={`hidden-${selectorId}`}>{value}</span>
	);
}

export default HiddenField;
