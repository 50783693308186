import { apiInstance } from '../../config/tokenApi';

const resetPassword = (email, companyId, siteUrl) => {
    
    return apiInstance.post('/auth/reset-password', {
        email: email,
        companyId: companyId,
        siteUrl: siteUrl
    });
};

export default {
    resetPassword: resetPassword
};
