import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './DashedBox.scss';

class DashedBox extends Component {
    render() {
        const props=this.props
        const height = {
            height: props.height
        }
        return (
            <div onClick={props.onClick} className={'row dashed-box-grey text-center align-items-center' + (props.className ? ` ${props.className}` : '')} style={height} >
                <div className='col-12'>
                    <span className={'text-center' + (props.textClassName ? ` ${props.textClassName}` : '')}> {props.text || props.children}</span>
                </div>
            </div>
        )
    }
}

DashedBox.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    textClassName: PropTypes.string,
    height: PropTypes.string,
    text: PropTypes.string
}

export default DashedBox;
