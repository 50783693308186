import React, { useState } from 'react';
import { Row, Col, Card, CardBody, Button, Tooltip } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import HomeInsightsGraph from './HomeInsightsGraph';
import useHomeInsights from './hooks/useHomeInsights';
import IconInformation from '../../MasterComponents/Icons/IconInformation';
import { useSelector } from 'react-redux';
import { getIconInformationColor, getTextLinkColor } from '../../config/Company/CompanyHelper';
import config from '../../config/config';

const HomeInsights = (props) => {
  const { companyId } = props;
  const { spinnerImage } = useSelector(({spinner}) => spinner);
  const { insightsAll, insightsOpportunity, insightsOpportunitySummary, insightsState,  userCurrent, onGoToInsights, onPeriodTime }  = useHomeInsights(props);
  const { period, datePeriod } = insightsState;
  const iconInformationColor = getIconInformationColor(companyId);
  const textLinkColor = getTextLinkColor(companyId);
  const [tooltipOpen, setTooltipOpen] = useState({
    totalProcessedPurchases: false,
    rebatesOpportunities: false,
    estimatedAccrued: false,
    deviationSavings: false
  });
  const showDeviations = userCurrent.displayDeviations === 1 ? true : false;
  const showOpportunity = userCurrent.displayOpportunity === 1 ? true : false;
  const showRebates = userCurrent.displayRebates === 1 ? true : false;
  const totalAmount = (insightsAll.totalAmount ? insightsAll.totalAmount : 0).toLocaleString('en', {minimumFractionDigits: 2});
  const totalRebates = (insightsAll.totalRebates ? insightsAll.totalRebates : 0).toLocaleString('en', {minimumFractionDigits: 2});
  const annualizedRebates = (insightsAll.annualizedRebates ? insightsAll.annualizedRebates : 0).toLocaleString('en', {minimumFractionDigits: 2});
  const annualizedDeviations = (insightsAll.annualizedDeviations ? insightsAll.annualizedDeviations : 0).toLocaleString('en', {minimumFractionDigits: 2});
  const potentialOpportunity = (insightsOpportunity.total ? insightsOpportunity.total : 0).toLocaleString('en', {minimumFractionDigits: 2});
  const totalDeviations = (insightsAll.totalDeviations ? insightsAll.totalDeviations : 0).toLocaleString('en', {minimumFractionDigits: 2});

  const toggleProcessedPurchases = () => {
    setTooltipOpen({...tooltipOpen, totalProcessedPurchases: !tooltipOpen.totalProcessedPurchases});
  } 

  const toggleRebatesOpportunities = () => {
    setTooltipOpen({...tooltipOpen, rebatesOpportunities: !tooltipOpen.rebatesOpportunities});
  }

  const toggleEstimatedAccrued = () => {
    setTooltipOpen({...tooltipOpen, estimatedAccrued: !tooltipOpen.estimatedAccrued});
  }

  const toggleDeviationSavings = () => {
    setTooltipOpen({...tooltipOpen, deviationSavings: !tooltipOpen.deviationSavings});
  }

  const dataRangeTemplate = (
    <Row>
      <Col xs={12}>
        {datePeriod.startDate} - {datePeriod.endDate}
      </Col>
      <Col xs={isMobile ? 12 : 6} className={'text-size-14 pt-1 text-grey-primary ' + (isMobile ? 'px-4 py-2' : '')} style={{color: textLinkColor}}>
        This information is an estimate. It may or may not include rebates that you have already been paid.
      </Col>
      <Col className={isMobile ? 'mx-4 border-top border-bottom pt-3' : '' } >
        <Row className='text-center text-size-10 line-height-12 text-uppercase clickable'>
          { !isMobile && <Col xs={4}></Col>
          }
          <Col className={'pb-3 px-3 ' + (period.isMonthActivated ? 'insights-actived-line insights-actived-text' : '')} onClick={()=> onPeriodTime('month')} style={{letterSpacing:'0.42px'}}>
            Previous Month
          </Col>
          <Col className={'pb-3 ' + (period.isYearActivated ? 'insights-actived-line insights-actived-text' : '')} onClick={()=> onPeriodTime('year')}>
            Year to Date
          </Col>
          <Col className={'pb-3 ' + (period.isCustomActivated ? 'insights-actived-line insights-actived-text' : '')} onClick={()=> onPeriodTime('custom')}>
            Custom
          </Col>
        </Row>
      </Col>
    </Row>
  );

  if(isMobile) {
    return (
      <>
      <Row className='text-size-30 line-height-38'>
        <Col className='py-3 px-0'>
          Insights Summary
        </Col>
      </Row>
      <Row>
        <Col className='px-0 text-center'>
          <Card>
            {
              spinnerImage ?
              <CardBody className='px-1'>
                <Row className='align-items-center justify-content-center' style={{height: '288px'}}>
                  <div className="lds-ring" style={{borderColor: `${config.colors.primary} transparent transparent transparent`}}><div></div><div></div><div></div><div></div></div>
                </Row>
              </CardBody>
              :
               <CardBody className='px-1'>
                  <Row>
                    {dataRangeTemplate}
                  </Row>
                  <Row className='border-bottom mx-0 py-4'>
                    <Col xs={12} className='py-2 text-size-14 line-height-17 text-grey-primary' >
                      Total Processed Purchases  &nbsp;
                      <span className='clickable' id='idTooltipProcessedPurchases'>
                        <IconInformation color={iconInformationColor} size={15} />
                      </span>
                      <Tooltip placement='top' isOpen={tooltipOpen.totalProcessedPurchases} target='idTooltipProcessedPurchases' toggle={toggleProcessedPurchases}>
                        This is your total purchase volume that we have processed so far. Please note that there can be delays and it takes us time to process all of the purchases.
                      </Tooltip>
                    </Col>
                    <Col xs={12} className='text-size-38 line-height-58'>
                      ${totalAmount}
                    </Col>
                  </Row>
                  { showOpportunity &&
                    <Row className='border-bottom mx-0 py-4'>
                      <Col xs={12} className='py-2 text-size-14 line-height-17 text-grey-primary'>
                        Potential Rebates for Opportunities  &nbsp;
                        <span className='clickable' id='idTooltipRebatesOpportunities'>
                          <IconInformation color={iconInformationColor} size={15} />
                        </span>
                        <Tooltip placement='top' isOpen={tooltipOpen.rebatesOpportunities} target='idTooltipRebatesOpportunities' toggle={toggleRebatesOpportunities}>
                          This is an ESTIMATE of the potential rebates opportunies you could earn. Please note that there can be delays and it takes us time to process all of the purchases.
                        </Tooltip>
                      </Col>
                      <Col xs={12} className='text-size-38 line-height-58'>
                        ${potentialOpportunity}
                      </Col>
                    </Row>
                  }
                  {
                    showRebates &&
                    <Row className='border-bottom mx-0 py-4'>
                      <Col xs={12} className='py-2 text-size-14 line-height-17 text-grey-primary'>
                        Estimated Accrued Rebates  &nbsp;
                        <span className='clickable' id='idTooltipEstimatedAccrued'>
                          <IconInformation color={iconInformationColor} size={15} />
                        </span>
                        <Tooltip placement='top' isOpen={tooltipOpen.estimatedAccrued} target='idTooltipEstimatedAccrued' toggle={toggleEstimatedAccrued}>
                          This is an ESTIMATE of your total accrued rebates for the time period you selected. This number may include payments that have already been made to you. The final payment amounts are subject to change and may differ from this number based on manufacturer contracts changes.
                        </Tooltip>
                      </Col>
                      <Col xs={12} className='text-size-38 line-height-58'>
                        ${totalRebates}
                        { insightsAll.annualizedRebates > 0 &&
                          <Col className='pl-0 insights-text-annualized'> (${annualizedRebates}) 
                            annualized
                          </Col>
                        }
                      </Col>
                    </Row>
                  }
                  { showDeviations &&
                    <Row className='border-bottom mx-0 py-4'>
                      <Col xs={12} className='py-2 text-size-14 line-height-17 text-grey-primary'>
                        Deviation Savings &nbsp;
                        <span className='clickable' id='idTooltipDeviationSavings'>
                          <IconInformation color={iconInformationColor} size={15} />
                        </span>
                        <Tooltip placement='top' isOpen={tooltipOpen.deviationSavings} target='idTooltipDeviationSavings' toggle={toggleDeviationSavings}>
                          This is an estimate of your cumulative deviation savings. The calculation is done by comparing your pre-contract price to your price while on our contract. An estimate is used when a pre-contract price is not available. Please note it is the responsibility of your distributor to load deviated pricing available to them.
                        </Tooltip>
                      </Col>
                      <Col xs={12} className='text-size-38 line-height-58'>
                        ${totalDeviations}
                        { insightsAll.annualizedDeviations > 0 &&
                        <Col className='pl-0 insights-text-annualized'> (${annualizedDeviations}) 
                          annualized
                        </Col>
                        }
                      </Col>
                    </Row>
                  }
                  <Row>
                    <Col>
                      <HomeInsightsGraph insightsOpportunitySummary= {insightsOpportunitySummary} purchasedRebateItems={insightsAll.purchasedRebateItems || []} purchasedNonRebateItems={insightsAll.purchasedNonRebateItems || []} />
                    </Col>
                  </Row>
                </CardBody>
            }
          </Card>
        </Col>
      </Row>
      <Row className='py-3'>
        <Col style={{zIndex: 1}} className='px-0'>
          <Button active block  style={{width:'100%'}} className='py-2 shadow-none' size='sm' color='primary'onClick={onGoToInsights} >VIEW ALL</Button>
        </Col>
      </Row>
      </>
    )
  }
  return (
    <>
      <Row className='text-size-32 line-height-38'>
        <Col className='py-3'>
          Insights Summary
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody className='pt-2'>
              {dataRangeTemplate}
              <Row className='px-2 py-4 text-size-20 line-height-14 font-weight-bold border-top'>
                <Col>
                  Overall Stats
                </Col>
                <Col className='pl-5'>
                  Savings and Opportunities
                </Col>
              </Row>
              {
                spinnerImage ?
                  <Row className='align-items-center justify-content-center' style={{height: '288px'}}>
                    <div className="lds-ring" style={{borderColor: `${config.colors.primary} transparent transparent transparent`}}><div></div><div></div><div></div><div></div></div>
                  </Row>
                :
                <Row>
                  <Col className='p-4 border-right'>
                    <Row>
                      <Col className='py-2 text-size-14 line-height-17 text-grey-primary'>
                        Total Processed Purchases &nbsp;
                        <span className='clickable' id='idTooltipProcessedPurchases'>
                          <IconInformation color={iconInformationColor} size={15} />
                        </span>
                        <Tooltip placement='top' isOpen={tooltipOpen.totalProcessedPurchases} target='idTooltipProcessedPurchases' toggle={toggleProcessedPurchases}>
                          This is your total purchase volume that we have processed so far. Please note that there can be delays and it takes us time to process all of the purchases.
                        </Tooltip>
                      </Col>
                      <Col className='py-2 text-size-14 line-height-17 text-grey-primary'>
                      { showOpportunity &&
                        <>
                          Potential Rebates for Opportunities  &nbsp;
                          <span className='clickable' id='idTooltipRebatesOpportunities'>
                            <IconInformation color={iconInformationColor} size={15} />
                          </span>
                          <Tooltip placement='top' isOpen={tooltipOpen.rebatesOpportunities} target='idTooltipRebatesOpportunities' toggle={toggleRebatesOpportunities}>
                            This is an ESTIMATE of the potential rebates opportunies you could earn. Please note that there can be delays and it takes us time to process all of the purchases.
                          </Tooltip>
                        </>
                      }
                      </Col>
                    </Row>
                    <Row>
                      <Col className='text-size-36 line-height-58'>
                        ${totalAmount}
                      </Col>
                      <Col className='text-size-36 line-height-58'>
                        { showOpportunity ? '$' + potentialOpportunity : null }
                      </Col>
                    </Row>
                    <Row>
                      {
                        showRebates &&
                        <Col className='py-2 pt-4 text-size-14 line-height-17 text-grey-primary'>
                          Estimated Accrued Rebates &nbsp;
                          <span className='clickable' id='idTooltipEstimatedAccrued'>
                            <IconInformation color={iconInformationColor} size={15} />
                          </span>
                          <Tooltip placement='top' isOpen={tooltipOpen.estimatedAccrued} target='idTooltipEstimatedAccrued' toggle={toggleEstimatedAccrued}>
                            This is an ESTIMATE of your total accrued rebates for the time period you selected. This number may include payments that have already been made to you. The final payment amounts are subject to change and may differ from this number based on manufacturer contracts changes.
                          </Tooltip>
                        </Col>
                      }
                      {
                        showDeviations &&
                        <Col className='py-2 pt-4 text-size-14 line-height-17 text-grey-primary'>
                          Deviation Savings &nbsp;
                          <span className='clickable' id='idTooltipDeviationSavings'>
                            <IconInformation color={iconInformationColor} size={15} />
                          </span>
                          <Tooltip placement='top' isOpen={tooltipOpen.deviationSavings} target='idTooltipDeviationSavings' toggle={toggleDeviationSavings}>
                            This is an estimate of your cumulative deviation savings. The calculation is done by comparing your pre-contract price to your price while on our contract. An estimate is used when a pre-contract price is not available. Please note it is the responsibility of your distributor to load deviated pricing available to them.
                          </Tooltip>
                        </Col>
                      }
                    </Row>
                    {
                      showRebates &&
                      <Row>
                        <Col className='text-size-36 line-height-58'>
                          ${totalRebates}
                          { insightsAll.annualizedRebates > 0 &&
                            <Col className='pl-0 insights-text-annualized'> (${annualizedRebates}) 
                              annualized
                            </Col>
                          }
                        </Col>
                        <Col className='text-size-36 line-height-58'>
                          { showDeviations ? '$' + totalDeviations : null }
                          { (showDeviations && insightsAll.annualizedDeviations) > 0 &&
                            <Col className='pl-0 insights-text-annualized'> (${annualizedDeviations}) 
                              annualized
                            </Col>
                          }
                        </Col>
                      </Row>
                    }
                  </Col>
                  <Col>
                    <HomeInsightsGraph insightsOpportunitySummary={insightsOpportunitySummary} purchasedRebateItems={insightsAll.purchasedRebateItems || []} purchasedNonRebateItems={insightsAll.purchasedNonRebateItems || []} />
                  </Col>
                </Row>
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className='py-3'>
        <Col style={{zIndex: 1}}>
          <Button active block className='py-2 shadow-none' size='sm' color='primary' onClick={onGoToInsights} >VIEW ALL</Button>
        </Col>
      </Row>
    </>
  )
}

export default HomeInsights;
