import React, { Component } from 'react';
import Select from 'react-select';
import './DropDownList.scss';

class DropDownListSelectCustom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isValid: true,
      menuIsOpen :false
    };
  }
  open = () => {
    this.setState({
      open: true,
      isValid: true
    })
  }
  close = () => {
    this.setState({
      open: false
    })
  }
  setInvalid = () => {
    this.setState({
      isValid: false
    });
  }
  setValid = () => {
    this.setState({
      isValid: true
    });
  }
  isValid = () => {
    if (this.props.isMulti) {
      if (this.props.value.length === 0) {
        this.setState({
          isValid: false
        });
        return false;
      } else {
        this.setState({
          isValid: true
        });
        return true;
      }
    } else if (this.props.value === null) {
      this.setState({
        isValid: false
      });
      return false;
    } else {
      this.setState({
        isValid: true
      });
      return true;
    }
  }

  handleInputChange = (nCharacters, onSearch, inputValue) => {
    let str = inputValue.length;
    if(str >= nCharacters){
      this.setState({ menuIsOpen: true });
      if (onSearch) {
        onSearch(inputValue);
      }
    } else {
      this.setState({ menuIsOpen: false });
    }
  };

  render() {
    const { className, classNamePrefix, isSearchable, value, openMenuOnClick, onInputChange, onSearch, ...allProps} = this.props;
    return (
      <Select className={'rb-select' + (className ? ` ${className}` :'') + (!this.state.isValid ? ' is-invalid' : '') + (this.props.isMulti ? ' isMulti' : '' )}
        value={value}
        classNamePrefix='rb-select'
        placeholder={'Type to Search'} 
        {...allProps}
        onMenuClose={this.close}
        onMenuOpen={this.open}
        isSearchable={isSearchable}
        openMenuOnClick={openMenuOnClick}
        onInputChange={this.handleInputChange.bind(this, onInputChange, onSearch)}
        menuIsOpen = {this.state.menuIsOpen}
        />
    );
  }
}

DropDownListSelectCustom.defaultProps = {
  isSearchable: true,
}

export default DropDownListSelectCustom;





