import React, { Component } from 'react';
import InsightsContent from './InsightsContent';

class Insights extends Component {
    render() {
        const props = this.props;
        return (
           <InsightsContent {...props} />
        );
    }
}

export default Insights;