
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../../MasterComponents/Modals/ModalsActions';
import { invoiceSetAll, invoiceSetFiles } from '../Reducer/InvoiceScanningAction';
import { spinner } from '../../../MasterComponents/Spinner/SpinnerActions';
import UploadInvoiceModal from '../Modal/UploadInvoiceModal';
import InvoiceSanningApi from '../InvoiceSanningApi';
import moment from 'moment';

const useInvoiceScanning = () => {
  const dispatch = useDispatch();
  const companyId = useSelector(({company}) => company.id);
  const merchants = useSelector(({location}) => location.merchant);
  const invoicesAll = useSelector(({invoice}) => invoice.invoicesAll);
  const [search, setSearch] = useState('');
  const [selectedLocation, setSelectedLocation] = useState({ value: 0, label: 'All Locations'});
  const [date, setDate] = useState({
    startDate: null,
    endDate: null
  });
  let merchantsIds =  merchants.length > 0 ? merchants.map(m => m.id): [];
  let optionsLocation = merchants.filter(m => m.platformId !== null && m.platformId !== "").map(m => {
    return {
      value: m.id,
      label: m.nickname
    }
  });
  let optionsLocationModal = [...optionsLocation]
  optionsLocation.sort((a, b) => a.label.toString().localeCompare(b.label.toString()));
  optionsLocation.unshift({ value: 0, label: 'All Locations'});

  useEffect(() => {    
    if(merchants.length > 0) {
      getInvoices(search, null, null, 0);
    }
  }, []);

  const getFormatDate = (date) => {
    const format = 'YYYY-MM-DD';
    if(date) {
      return moment(date).format(format);
    }
    return date;
  }

  const getInvoices = (search, dateStart, dateEnd, optionValue) => {
    let merchantIds = optionValue === 0 ? merchantsIds: [optionValue];
    let dateStartFormat = getFormatDate(dateStart);
    let dateEndFormat = getFormatDate(dateEnd);
    const params = {
      companyId,
      merchantIds,
      search,
      startDate: dateStartFormat,
      endDate: dateEndFormat
    }
    dispatch(spinner(true));
    InvoiceSanningApi.getInvoices(params).then(({data}) => {
      dispatch(spinner(false));
      dispatch(invoiceSetAll(data.data.map(d => {
        return {
          ...d,
          isTooltipOpen: false
        }
      })));
    }).catch(() => {
      dispatch(spinner(false));
    });
  }

  const setTooltipByItem = (inv) => {
    dispatch(invoiceSetAll(invoicesAll.map(d => {
      if(d.id === inv.id) {
        return {
          ...d,
          isTooltipOpen: !inv.isTooltipOpen
        }
      }
      return {
        ...d,
        isTooltipOpen: false
      }
    })));
  }

  const onEnterSearch = (event) => {
    const { startDate,  endDate} = date;
    if(event.key === 'Enter'){
      getInvoices(search, startDate, endDate, selectedLocation.value);
    }
  }

  const onChangeSearch = (val) => {
    setSearch(val)
  }

  const onChangeLocation = (val) => {
    const { startDate,  endDate} = date;
    setSelectedLocation({ value: val.value, label: val.label });
    getInvoices(search, startDate, endDate,val.value);
  }

  const onChangeSetDate = (start, end) => {
    const dateFormatStart = getFormatDate(start);
    const dateFormatEnd = getFormatDate(end);
    setDate({
      startDate: dateFormatStart,
      endDate: dateFormatEnd
    });
    getInvoices(search, dateFormatStart, dateFormatEnd, selectedLocation.value);
  }

  const onUploadNow = (isEdit, invoiceDetail) => {
    let merchantIds = selectedLocation.value === 0 ? merchantsIds: [selectedLocation.value];
    const props = {
      getInvoices,
      merchantIds,
      optionsLocationModal,
      date,
      isEdit,
      invoiceDetail: {
        invoiceId: invoiceDetail ? invoiceDetail.id : 0,
        name: invoiceDetail ? invoiceDetail.name : '',
        locationId: invoiceDetail ? invoiceDetail.merchantId : null,
      }
    }
    dispatch(invoiceSetFiles([]));
    dispatch(showModal(UploadInvoiceModal, 'idUploadInvoiceModal', { propsModal: props }));
  }

  return {
    onChangeLocation,
    onEnterSearch,
    onChangeSearch,
    onUploadNow,
    onChangeSetDate,
    setTooltipByItem,
    invoicesAll,
    merchants,
    optionsLocation,
    selectedLocation,
    search
  }
}

export default useInvoiceScanning;