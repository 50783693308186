import { apiInstance } from '../../config/tokenApi';

const getMerchants = () => {
  return apiInstance.get('/merchant', {
    params: {
      limit: 2000
    }
  });
}

const getMerchantDistributor = (params) => {
  return apiInstance.get('/merchant-distributor', { params: params });
}

const getMerchantDistributorSummary = () => {
  return apiInstance.get('/merchant-distributor/summary');
}

const getDistributorAllFilter = () => {
  return apiInstance.get('/merchant-distributor/filter');
}

const getMerchantDistributorList = (params) => {
  return apiInstance.get('/merchant-distributor/list', {params: params});
}

const getCurrentUser = () => {
  return apiInstance.get('/user/current');
}

const sendEmailToSalesRep = (companyId) => {
  return apiInstance.post('/merchant-distributor/request', { companyId })
}

const deleteDistributor = (id, params) => {
  return apiInstance.delete(`merchant-distributor/${id}`, { data: params });
}

export default {
  getMerchants,
  getMerchantDistributor,
  getMerchantDistributorList,
  getMerchantDistributorSummary,
  getDistributorAllFilter,
  getCurrentUser,
  sendEmailToSalesRep,
  deleteDistributor
}
