import React from 'react';
import { Row, Col } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import FoodCostView from './FoodCostView';
import FoodCostNavbar from './FoodCostNavbar';
import FoodCostBanner from './FoodCostBanner';
import useFoodCost from '../FoodCost/hooks/useFoodCost';

const FoodCost = (props) => {
  const { dataView, view, buttonText, onClickNav, onClickGetStarted, onClickLearn } = useFoodCost(props);

  const propsBanner = {
    onClickLearn
  }

  const propsNavbar = {
    dataView,
    buttonText,
    onClickNav,
    onClickGetStarted
  }

  const propsView = {
    view,
    buttonText,
    onClickGetStarted
  }

  if(isMobile){
    return (
      <>
        <FoodCostBanner {...propsBanner} />
        <Row>
          <Col xs={12} clasname='p-4' >
            <FoodCostNavbar {...propsNavbar} />
          </Col>
        </Row>
      </>
    )
  }

  return (
    <>
      <FoodCostBanner {...propsBanner} />
      <Row className='px-2 py-3'>
        <Col  clasname='p-4'>
          <FoodCostNavbar {...propsNavbar} />
        </Col>
        <Col xs={10}>
          <FoodCostView {...propsView} />
        </Col>
      </Row>
    </>
  );
};
export default FoodCost;
