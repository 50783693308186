import React from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { getBrowserExtensionBannerHeading, getBrowserExtensionBannerLink, getBrowserExtensionBannerLogo, getBrowserExtensionBannerText, getDisplayBrowserExtensionBanner } from '../../config/Company/CompanyHelper';
import Button from '../../MasterComponents/Button/Button';
import GridCol from '../../MasterComponents/Grid/GridCol';
import GridRow from '../../MasterComponents/Grid/GridRow';
import './BrowserExtensionBanner.scss';

let BrowserExtensionBanner = props => {

  const companyId = useSelector(({ company }) => company.id);
  const displayBrowserExtension = useSelector(({ dashboard }) => (dashboard && dashboard.displayBrowserExtension));
  const displayBrowserExtensionBanner = getDisplayBrowserExtensionBanner(companyId);

  if (isMobile || !displayBrowserExtension || !displayBrowserExtensionBanner) {
    return null;
  }
  const heading = getBrowserExtensionBannerHeading(companyId);
  const text = getBrowserExtensionBannerText(companyId);
  const logo = getBrowserExtensionBannerLogo(companyId);

  const clickDownload = () => {
    const extensionLink = getBrowserExtensionBannerLink(companyId)
    window.open(extensionLink, '_blank');
  };

  return (
    <GridRow id='BrowserExtensionBanner' className='p-3 mx-1'>
      <GridCol id='logo-wrapper' className='col-2'>
        <div className='border-right'>
          <img src={require(`../../assets/images/${logo}`)} alt='' />
        </div>
      </GridCol>
      <GridCol className='col-7'>
        <h1>{heading}</h1>
        <p>{text}</p>
      </GridCol>
      <GridCol id='btn-wrapper' className='col-3'>
        <Button size='md' variant='yellow' text='DOWNLOAD NOW' onClick={clickDownload} />
      </GridCol>
    </GridRow>
  )
};

export default BrowserExtensionBanner;
