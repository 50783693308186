import React, { Component } from 'react';
import '../SignUp.scss';
import Bullet from '../../../MasterComponents/Bullet/Bullet';

class SignUpPorchDoorBrandSidebar extends Component {
    goTo = (e) => {
        e.preventDefault();
        localStorage.setItem('isSignUpRoute', 0);
        this.props.history.push('/');
    };
    getLogo = (abbreviation) => {
        const path = 'https://rebate-portal.s3.amazonaws.com/lops/';
        return `${path}${abbreviation}-white.png`;
    }
    getLogoPartner = (abbreviation) => {
        const path = 'https://rebate-portal.s3.amazonaws.com/lops/';
        return `${path}${abbreviation}-white.png`;
    }
    getBullets = () => {
        const { step, isCoolSchool } = this.props;

        const coolSchoolBullets = ["250 Manufacturers", "100,000+ Point Items"];

        const BulletsStep1 = ["Enter Your Parent Company’s Account Information", "If You Have a Buyers Edge Platform ID already, please enter that so we can assign your account to it.", 'The email address you enter will receive a copy of the Letter of Participation after you submit.'];
        
        const BulletsStep2 = ["If you are part of another Group Purchasing Organization, please select Yes and enter the name that you want to terminate with.", "If you have any direct manufacturer pricing agreements, please enter them here.", "Then click Authorize, type your authorized signature, and click submit.  That’s it!"];

        if (isCoolSchool) {
            return coolSchoolBullets;
        }
        
        return step === 1 ? BulletsStep1 : BulletsStep2;
    }
    render() {
        const { abbreviation, name, extraText } = this.props;
        
        return (
            <div className='left-signup be'>
                <div className='logo'>
                    <a href='/' onClick={this.goTo}>
                        {
                            abbreviation.brand && <img style={{maxWidth:'250px', maxHeight:'150px'}} src={this.getLogo(abbreviation.brand )} alt={`${name.brand} Logo`} />
                        }
                    </a>
                </div>
                <div className='bullets'>
                {
                    this.getBullets().map((b, i) => (
                        <Bullet key={`bullet_${i}`} className='be-blue-bg' text={b} />
                    ))    
                }
                </div>
                {
                    (extraText && extraText.partner) && 
                    <div className='logo m-0'>
                        {extraText.partner}
                    </div>
                }
                {
                    (name.partner && (name.partner !== name.brand)) &&
                    <div className='logo m-0'>
                        <a href='/' onClick={this.goTo}>
                            <img style={{maxWidth:'250px', maxHeight:'150px'}} src={this.getLogo(abbreviation.partner)} alt={`${name.partner} Logo`} />
                        </a>
                    </div>
                }
            </div>
        )
    }
}

export default SignUpPorchDoorBrandSidebar;