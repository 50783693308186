import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconClose = props => (
  <svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
  <g id="Designs" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="20---Activate-Online-Distributor" transform="translate(-881.000000, -117.000000)">
          <g id="pop-up" transform="translate(520.000000, 92.000000)">
              <g id="icons/close" transform="translate(356.000000, 20.000000)">
                  <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                  <path d="M6.3431317,5.6360249 C6.1478773,5.4407705 5.83266245,5.4394155 5.63603895,5.63603895 C5.4407768,5.8313011 5.44324945,6.15035625 5.6360249,6.3431317 L11.2928932,12 L5.6360249,17.6568683 C5.4407705,17.8521227 5.4394155,18.1673375 5.63603895,18.3639611 C5.8313011,18.5592232 6.15035625,18.5567506 6.3431317,18.3639751 L12,12.7071068 L17.6568683,18.3639751 C17.8521227,18.5592295 18.1673375,18.5605845 18.3639611,18.3639611 C18.5592232,18.1686989 18.5567506,17.8496437 18.3639751,17.6568683 L12.7071068,12 L18.3639751,6.3431317 C18.5592295,6.1478773 18.5605845,5.83266245 18.3639611,5.63603895 C18.1686989,5.4407768 17.8496437,5.44324945 17.6568683,5.6360249 L12,11.2928932 L6.3431317,5.6360249 Z" id="Path" fill={!props.isActive ? props.color : props.activeColor}></path>
              </g>
          </g>
      </g>
  </g>
  </svg>
);

IconClose.propTypes = {
  color: PropTypes.string,
  activeColor: PropTypes.string,
  isActive: PropTypes.bool,
  size: PropTypes.number,
};

IconClose.defaultProps = {
  activeColor: config.colors.rbYellow,
  color: config.colors.rbWhite,
  isActive: false,
  size: 14,
};

export default IconClose;