import { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { findModal, showModal } from '../../../MasterComponents/Modals/ModalsActions';
import { spinner } from '../../../MasterComponents/Spinner/SpinnerActions';
import { sorArrayByKey } from '../../../utils/utils';
import SignUpApi from '../../SignUp/SignUpApi';
import FoodCostApi from '../FoodCostApi';
import PreparingAccountModal from './PreparingAccountModal';

const INITIAL_STATE = {
	salesRep: null,
	options: [],
	step: 1
};

const SET_SALES_REP = 'SET_SALES_REP';
const SET_OPTIONS = 'SET_OPTIONS';

const reducer = (state, {type, payload}) => {
	switch (type) {
		case SET_SALES_REP:
			return {
				...state,
				salesRep: payload
			};
		case SET_OPTIONS:
			return {
				...state,
				options: payload
			};
		default:
			return state;
	}
};

const useSalesRepModal = (modals, history) => {
	const [{salesRep, options}, setState] = useReducer(reducer, INITIAL_STATE);
	const company = useSelector(({company}) => company);
	const dispatch = useDispatch();

	const onEnterSales = () => {
		dispatch(spinner(true));
		FoodCostApi.agreement({salesRepId: salesRep.value}).then(() => {
			dispatch(spinner(false));
			findModal(modals, 'salesRepModal').close();
			dispatch(showModal(PreparingAccountModal, 'preparingAccountModal', {dispatch: dispatch, history: history, salesRepId: salesRep.value}));
		}).catch((err)=>{
			dispatch(spinner(false));
			console.error(err);
			findModal(modals, 'salesRepModal').close();
			dispatch(showModal(PreparingAccountModal, 'preparingAccountModal', {dispatch: dispatch, history: history, salesRepId: salesRep.value}));
		});
	};

	const getSalesRep = () => {
    let page = 1;
		let result = [];
		dispatch(spinner(true));
    const apiCall = () => {
      const params = {
        search: null,
        page
      };
      SignUpApi.getSalesRep({params}).then((res) => {
        const loadMore =  parseInt(res.headers['x-pagination-current-page']) < parseInt(res.headers['x-pagination-page-count']);
        const { data } = res;
        result = [...result, ...data.data];
        const referred = result.map(ref => ({
					...ref,
					value: ref.id,
          label: ref.firstName + ' ' + ref.lastName,
        }));
        if (loadMore) {
          page += 1;
          apiCall();
        } else {
          const stateSort = sorArrayByKey(referred, 'name') // sorting the values by name
					dispatch(spinner(false));
          setState({
						type: SET_OPTIONS,
						payload: stateSort
					});
        }
      }).catch(function (error) {
				console.log(error);
      });
    };

    apiCall();
	};
	
	useEffect(() => {
		getSalesRep();
		// eslint-disable-next-line
	}, []);

	return {
		companyName: company.nickname,
		onEnterSales,
		salesRep: {
			name: 'salesRep',
			value: salesRep,
			onChange: (value) => {
				setState({type: SET_SALES_REP, payload: value});
			},
			options,
			openMenuOnClick: false,
			onInputChange: 3,
			menuIsOpen: false,
			blurInputOnSelect: true
		}
	}
};

export default useSalesRepModal;