import React, { Component, Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import GridCol from '../../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../../MasterComponents/Grid/GridRow';
import Bullet from '../../../../MasterComponents/Bullet/Bullet';
import Button from '../../../../MasterComponents/Button/Button';
import FormDefaultBeyondPrime from '../Default/FormDefaultBeyondPrime';

class FormDefaultSuccessfully extends Component {
    done = () => {
        window.location.reload();
    }
    render() {
        const props = this.props;
        const { companyId, successResponse } = this.props;
        const beyondPrimeProps = {
            successResponse,
            companyId
        }
        const header = <Fragment>
            <GridRow>
                <GridCol className='signup-header-sucessfully'>
                    <Bullet/>
                </GridCol>
            </GridRow>
        </Fragment>

        if (props.isBuyersEdge && !props.isDA) {
            return (
                <GridRow minWidth='100%'>
                    <GridCol>
                        {isMobile ? header : null}
                        <GridRow>
                            <GridCol className={'text-size-32 signup-title-sucessfully ' + (isMobile ? 'text-center': null)}>
                                That{"''"}s it! You are signed up!
                            </GridCol>
                        </GridRow>
                        <GridRow>
                            <GridCol className={'text-size-16 signup-body-words ' + (isMobile ? 'text-center': null)}>
                                Your associated platform member will be in contact and get you onboarded and start collecting your rebates...<br />
                            </GridCol>
                        </GridRow>
                        <GridRow className='mt-3'>
                            <GridCol size={6} className={isMobile ? 'text-center': null}>
                                <Button type='button' variant='blue be-blue-border be-blue-bg' size='' text='Done' onClick={this.done} />
                            </GridCol>
                        </GridRow>
                        <GridCol>
                            <FormDefaultBeyondPrime {...beyondPrimeProps}/>
                        </GridCol>
                    </GridCol>
                </GridRow>
            )
        }
        return (
            <GridRow minWidth='100%'>
                <GridCol>
                    {isMobile ? header : null}
                    <GridRow>
                        <GridCol className={'text-size-32 signup-title-sucessfully ' + (isMobile ? 'text-center': null)}>
                            Thank you <span className='text-uppercase'>{props.step1Inputs.firstName} {props.step1Inputs.lastName}!</span>
                        </GridCol>
                    </GridRow>
                    <GridRow className='text-size-16 signup-body-words'>
                        <GridCol size={12} className={'px-0 ' + (isMobile ? 'text-center': '')}>
                            Your information for <span className='text-uppercase'>{props.step1Inputs.businessName}</span> has been submitted successfully.
                        </GridCol>
                        <GridCol size={12} className={'px-0 py-3 ' + (isMobile ? 'text-center': '')}>
                            Once we have processed your information, we will send an activation link to your email that you can use to log in.
                        </GridCol>
                    </GridRow>
                    <GridRow>
                        <GridCol size={12}>
                            <FormDefaultBeyondPrime {...beyondPrimeProps}/>
                        </GridCol>
                    </GridRow>
                </GridCol>
            </GridRow>
        )
    }
}

export default FormDefaultSuccessfully;
