import { useDispatch } from 'react-redux';
import { showModal } from '../../../MasterComponents/Modals/ModalsActions';
import { useSelector } from 'react-redux';
import useRefresh from '../../../MasterComponents/SharedFunctions/useRefresh';
import useShowActivateDistributor from '../../../MasterComponents/SharedFunctions/useShowActivateDistributor';
import UploadPurchaseHistoryModal from '../../Dashboard/Modals/UploadPurchaseHistoryModal';
import useShowEnterCredentials from '../../../MasterComponents/SharedFunctions/useShowEnterCredentials';


const useHomeAction = (props) => {
  const { history } = props;
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const showActivateDistributor = useShowActivateDistributor();
  const { showEnterCredentials } = useShowEnterCredentials();
  const notificationList = useSelector(({notification}) => notification.notificationList);

  const onGoToLocations = () => {
    history.push('/dashboard/rebates/card');
  }

  const onActivate = (noti) => {
    showActivateDistributor(refresh, noti.merchantDistributor, false);
  }

  const onClickEdit = (noti) => {
    const merchantDistributor = noti.merchantDistributor;
    showEnterCredentials(merchantDistributor);
  }

  const onUploadNow = (noti) => {
    dispatch(showModal(UploadPurchaseHistoryModal, 'uploadPurchaseHistoryModal', { merchantDistributor: noti.merchantDistributor, refresh }));
  }

  return {
    onActivate,
    onClickEdit,
    onUploadNow,
    onGoToLocations,
    notificationList
  }
}

export default useHomeAction;
