import React, { Component } from 'react'
import { connect } from 'react-redux';
import Modal from '../../../MasterComponents/Modals/Modal';
import ModalHeader from '../../../MasterComponents/Modals/ModalHeader';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import ModalFooter from '../../../MasterComponents/Modals/ModalFooter';
import Button from '../../../MasterComponents/Button/Button';
import { isMobile } from 'react-device-detect';
import { showModal, findModal } from '../../../MasterComponents/Modals/ModalsActions';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import InputLabel from '../../../MasterComponents/InputLabel/InputLabel';
import DashboardApi from '../DashboardApi';
import { spinner } from '../../../MasterComponents/Spinner/SpinnerActions';
import ConfirmationUserModal from './ConfirmationUserModal';

class DeleteUserModal extends Component {
  closeModal = () => {
    const { modals } = this.props;
    findModal(modals, 'deleteUserModal').close();
  }

  deleteUser = (e) => {
    e.preventDefault();
    const { dispatch, modals, user, refresh } = this.props;
    
    dispatch(spinner(true));
    DashboardApi.deleteUser(user.id).then(({data}) => {
        dispatch(spinner(false));
        findModal(modals, 'deleteUserModal').close();
        dispatch(showModal(ConfirmationUserModal, 'confirmationUserModal', {header:'Confirmation', message: 'User Deleted', email: user.email}));
        refresh();
    })
    .catch(error => {
      dispatch(spinner(false));
      findModal(modals, 'deleteUserModal').close();            
      if (error.response) {
          let errorMessage = error.response.data.data.message;
          alert(errorMessage);
      }
    });    
  }
  
  render() {
    const { header, user, message } = this.props;
    return (
      <Modal id='deleteUserModal' fullHeight={isMobile} >
        <ModalHeader noBorder closeButton={false} className={isMobile ? 'pt-5 mt-5 px-4' : null}>{header}</ModalHeader>
        <ModalBody className='text-center text-size-14 text-line-height-20 d-flex align-items-center'>
            <GridCol>
                <GridRow>
                    <GridCol>
                        <InputLabel className='text-size-16'>{message}</InputLabel><br/>
                        <InputLabel className='text-size-16'>{user.email}</InputLabel>                    
                    </GridCol>
                </GridRow>
            </GridCol>
        </ModalBody>
        <ModalFooter>
          <Button variant='primary' onClick={this.closeModal} className='clickable' size='lg' text='Cancel' />
          <Button variant='primary' onClick={this.deleteUser.bind(this)} className='clickable' size='lg' text='Delete' />
        </ModalFooter>
      </Modal>
    );
  }
};

const mapStateToProps = state => {
  return {
    locations: state.dashboard.merchants,
    users: state.dashboard.users,
  };
}

export default connect(mapStateToProps)(DeleteUserModal);