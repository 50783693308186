import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import config from '../../config/config';

const FoodCostBanner = (props) => {
  const { onClickLearn } = props;
  return (
    <Row className='p-2 bg-primary d-flex align-items-center' style={{ height: isMobile ? '' : '56px'}}>
      <Col xs={isMobile ? 12 : 9} className={`${isMobile ? 'text-size-10': 'text-right text-size-14'} line-height-20 text-white`}>
        IMPORTANT: No support for tablet/surface devices. Google Chrome browser is required, 3rd party cookies must be ENABLED.
      </Col>
      <Col xs={isMobile ? 12 : 3} className='clickable'>
        <Button style={{ backgroundColor: config.colors.primary, borderColor: config.colors.rbWhite, height: '32px', width: '130px'}} size='sm' className='text-uppercase shadow-none' onClick={onClickLearn}>
          Learn More
        </Button>
      </Col>
    </Row>
  )
};
export default FoodCostBanner;