import config from "./config";
import axios from 'axios';

const authKey = localStorage.getItem('authKey');
const token =  authKey ? 'Bearer ' + localStorage.getItem('authKey') : null;

export const apiInstance = axios.create({
  baseURL: config.apiURL
});

apiInstance.defaults.baseURL = config.apiURL;
apiInstance.defaults.timeout = 1000 * 60 * 20;
apiInstance.defaults.headers['Pragma'] = 'no-cache';
if (token !== null){
    apiInstance.defaults.headers.common['Authorization'] = token ? token : null;
}
apiInstance.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8';

apiInstance.interceptors.response.use(response => {
    return response;
}, error => {
    if (error && error.response && error.response.status === 401) {
        if (error.config && error.config.baseURL === config.apiURL) {
            if (error.config.url.search('/insight/download') === -1 && error.config.url.search('/check/list') === -1) {
                alert('Please login with proper credentials.');
                window.location.href = `/logout`;
            }
        }
    }
    return Promise.reject(error.response);
})

const setToken= (token) => {
    if (token !== null) {
        apiInstance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }
};

export default setToken;
