
export const merchantClearSearch = () => {
  return {
    type: 'MERCHANT_CLEAR_SEARCH',
  };
};
  
export const merchantSetSearch = (search) => {
  return {
    type: 'MERCHANT_SET_SEARCH',
    payload: search
  };
};

export const merchantSetSearchId = (search) => {
  return {
    type: 'MERCHANT_SET_SEARCH_ID',
    payload: search
  };
};

export const merchantLoadGroup = (page) => {
  return {
    type: 'MERCHANT_LOAD_GROUP',
    payload: page
  }
};

export const merchantSetGroup = (data, curPage, pageCount) => {
  return {
    type: 'MERCHANT_SET_GROUP',
    payload: {
      data, curPage, pageCount
    }
  }
};

export const merchantSetData = (data) => {
  return {
    type: 'MERCHANT_SET_DATA',
    payload: data
  }
};

export const distributorSetFilter = (data) => {
  return {
    type: 'DISTRIBUTOR_SET_FILTER',
    payload: data
  }
};

export const merchantSetFilter = (data) => {
  return {
    type: 'MERCHANT_SET_FILTER',
    payload: data
  }
};

export const distributorSetAll = (data) => {
  return {
    type: 'DISTRIBUTOR_SET_ALL',
    payload: data
  }
};

export const manufacturerAdSetData = (data) => {
  return {
    type: 'MANUFACTURER_AD_SET_DATA',
    payload: data
  }
};

export const saveUserCurrent = (userCurrent) => {
  return {
    type: 'USER_CURRENT_SAVE',
    payload: userCurrent
  }
};

export const summarySetData = (data) => {
  return {
    type: 'SUMMARY_SET_DATA',
    payload: data
  }
}