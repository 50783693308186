import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { findModal } from '../../../MasterComponents/Modals/ModalsActions';
import Modal from '../../../MasterComponents/Modals/Modal';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import IconArrowBack from '../../../MasterComponents/Icons/IconArrowBack';
import ClaimCashDetailContent from './ClaimCashDetailContent';


class ClaimCashDetailModal extends Component {
  goToBack = () => {
    const { history, modals } = this.props;
    history.push('/dashboard/claim');
    findModal(modals, 'idClaimCashDetailModal').close();
  }

  render() {
    const { header, history, dispatch } = this.props;
    const propsClaim = {
      history,
      dispatch
    }
    return (
      <Modal id='idClaimCashDetailModal' fullHeight={true}>
        <div className='header-main'>
            <Row className='header-fixed home-header py-3 px-4'>
              <Col xs={1} className='p-0' onClick={this.goToBack}>
                <IconArrowBack color={'white'} size={18} />
              </Col>
              <Col xs={10}className='pt-2 text-size-18 line-height-15'>
                {header}
              </Col>
              <Col xs={1} className='p-0'>
              </Col>
            </Row>
        </div>
        <ModalBody className='bg-white'>
          <Row>
            <ClaimCashDetailContent {...propsClaim} />
          </Row>
        </ModalBody>
      </Modal>
    )
  }
}

export default ClaimCashDetailModal;