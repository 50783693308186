import React, { Component } from 'react'
import { isMobile } from 'react-device-detect';
import config from '../../../config/config';
import Button from '../../../MasterComponents/Button/Button';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import IconConfirmation from '../../../MasterComponents/Icons/IconConfirmation';
import InputLabel from '../../../MasterComponents/InputLabel/InputLabel';
import Modal from '../../../MasterComponents/Modals/Modal';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import ModalFooter from '../../../MasterComponents/Modals/ModalFooter';
import ModalHeader from '../../../MasterComponents/Modals/ModalHeader';
import { findModal } from '../../../MasterComponents/Modals/ModalsActions';

export class LocationEmailToSalesRepModal extends Component {
    closeModal = () => {
        const { modals } = this.props;
        findModal(modals, 'locationEmailToSalesRepModal').close();
    }
    render() {
        return (
            <Modal id='locationEmailToSalesRepModal' centered={isMobile} >
                <ModalHeader noBorder={true} />
                <ModalBody className='text-center'>
                    <GridRow>
                        <GridCol className='pb-4'>
                            <IconConfirmation color={config.colors.primary} size={50} />
                        </GridCol>
                    </GridRow>
                    <GridRow>
                        <GridCol>
                            <InputLabel>Thanks!</InputLabel><br />
                            <InputLabel>Your Sales Representative will be notified that you are requesting to add a distributor.</InputLabel>
                        </GridCol>
                    </GridRow>
                </ModalBody>
                <ModalFooter>
                    <GridRow>
                        <GridCol>
                            <Button type='button' onClick={this.closeModal.bind(this)} variant='primary' text='OK' size='md' />
                        </GridCol>
                    </GridRow>
                </ModalFooter>
            </Modal>
        )
    }
}

export default LocationEmailToSalesRepModal;