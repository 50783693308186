export default function reducer(state = {
    searchLocation: '',
    searchLocationId: null,
    merchantGroup: [],
    merchantGroupQuery: {
      page: 1,
      pageCount: 1,
      isLoading: false
    },
    merchant: [],
    merchantFilter: [],
    distributorFilter: {},
    distributorAll: [],
    manufacturerAd: [],
    summary: {
      merchants: 0,
      distributors: 0,
      spinner: true
    },
    userCurrent: {}
  }, action) {
    switch (action.type) {
      case 'SUMMARY_SET_DATA':
        return {
          ...state,
          summary: action.payload
        };
      case 'MERCHANT_CLEAR_SEARCH':
        return {
          ...state,
          searchLocation: ''
        };
      case 'MERCHANT_SET_SEARCH':
        return {
          ...state,
          searchLocation: action.payload
        };
      case 'MERCHANT_SET_SEARCH_ID':
        return {
          ...state,
          searchLocationId: action.payload
      };
      case 'MERCHANT_LOAD_GROUP':
        return {
          ...state,
          merchantGroup: [],
          merchantGroupQuery: {
            ...state.merchantGroupQuery,
            page: action.payload,
            isLoading: true
          }
        };
      case 'MERCHANT_SET_GROUP':
        return {
          ...state,
          merchantGroup: action.payload.data,
          merchantGroupQuery: {
            page: action.payload.curPage,
            pageCount: action.payload.pageCount,
            isLoading: false
          }
        };
      case 'MERCHANT_SET_DATA':
        return {
          ...state,
          merchant: action.payload
        };
      case 'MERCHANT_SET_FILTER':
        return {
          ...state,
          merchantFilter: action.payload
        };
      case 'DISTRIBUTOR_SET_FILTER':
        return {
          ...state,
          distributorFilter: action.payload
        };
      case 'DISTRIBUTOR_SET_ALL':
        return {
          ...state,
          distributorAll: action.payload
        };
      case 'MANUFACTURER_AD_SET_DATA':
        return {
          ...state,
          manufacturerAd: action.payload
        };
      case 'USER_CURRENT_SAVE':
        return {
          ...state,
          userCurrent: action.payload
        };
      default:
        return state;
    }
  }