import React from 'react';
import { Col, Row } from 'reactstrap';

const JoinSignupSucceed = () => {

	return (
		<Col>
			<Row className='mb-2'>
				<Col className='text-signup-title text-center'>Almost Done!</Col>
			</Row>
			<Row>
				<Col className='text-signup-title mt-5'>
					Please click the activation link that we sent you to log in and “activate” your distributors. You must do this in order to start earning cash back.<br/><br />
          Thank you for registering!
				</Col>
			</Row>
		</Col>
	);
};

export default JoinSignupSucceed;