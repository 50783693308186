import React, { Component, Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import Input from '../../../../MasterComponents/Input/Input';
import InputLabel from '../../../../MasterComponents/InputLabel/InputLabel';
import GridCol from '../../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../../MasterComponents/Grid/GridRow';
import config from '../../../../config/config';
import DropDownListSelect from '../../../../MasterComponents/DropDownList/DropDownListSelect';
import byGoogle from '../../../../assets/images/powered-by-google.png';
import Checkbox from '../../../../MasterComponents/Checkbox/Checkbox';
import DropDownListSelectCustom from '../../../../MasterComponents/DropDownList/DropDownListSelectCustom';
import getPlacesProps, { getStatesProvinces } from '../../../../MasterComponents/Helper/getPlaces';
import { getText } from '../../../../MasterComponents/Helper/getHelper';
class FormDefaultLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nicknameInvalid: false
    }
    this.getPlacesProps = getPlacesProps.bind(this);
  }
  setLocationValid = (name) => {
    if (name === 'state') {
      this.refs.stateLocation.setValid();
    } else {
      this.refs[name].isValid();
    }
  };
  isValidBlind = () => {
    const { changeHandlerInput } = this.props;
    const { nickname } = this.props.step1Locations[this.props.stateLocation - 1];
    const { phoneNumber, address, city, state, postcode } = this.refs;
    const n = !(nickname.length < 4);
    const pn = phoneNumber.isValid(true);
    const a = address.isValid(true);
    const c = city.isValid(true);
    const sl = state.isValid(true);
    const pc = postcode.isValid(true);

    if (!pn) {
      changeHandlerInput('phoneNumber', '');
    }

    if (n && a && c && sl && pc) {
      return true;
    }

    return false;
  };
  isValid = () => {
    const { nickname } = this.props.step1Locations[this.props.stateLocation - 1];
    const { phoneNumber, address, city, state, postcode } = this.refs;
    if (nickname.length < 4) {
      this.setState({
        nicknameInvalid: true
      });
    }
    const n = !(nickname.length < 4);
    const pn = phoneNumber.isValid();
    const a = address.isValid();
    const c = city.isValid();
    const sl = state.isValid();
    const pc = postcode.isValid();
    const other = this.isValidCompanies();
    if (n && pn && a && c && sl && pc && other){
      return true;
    }
    const { showAddressDetails } = this.props.step1Locations[this.props.stateLocation - 1];
    if (!showAddressDetails && !(a && c && sl && pc) ) {
      this.props.toggleAddressDetails();
    }
    return false;
  };
  isValidCompanies = () => {
    const { companyId } = this.props;
    const { numberOfRooms, locationId, flagType } = this.refs;
    const companyIds = [11, 12].includes(companyId);
    if(!companyIds) {
      return true;
    }
    if(companyIds) {
      if(numberOfRooms.isValid() && locationId.isValid() && flagType.isValid()) {
        return true;
      }
    } 
    return false;
  }
  getUsStates = () => {
    const options = config.usStates.map((item) => {
      return {
        label: item.name,
        value: item.abbreviation
      }
    });
    return options;
  };
  getFlagTypes = () => {
    const { companyId } = this.props;
    const flagTypes = companyId === 11 ? config.flagTypesRadisson : config.flagTypesBestWestern;
    const options = flagTypes.map((item) => {
      return {
        label: item,
        value: item
      }
    });
    return options;
  };
  render() {
    const props = this.props;
    const {
      changeHandlerInput, step1Locations, stateLocation,
      handleAddressSelect, selectStateLocation, sameChecked, sameCheckedValue, handleNicknameSelect, selectFlagTypeLocation, companyId, country } = props;
    const {
      nickname, phoneNumber, addressSearch, showAddressDetails,
      address, city, stateObj, postcode, numberOfRooms, locationId, flagTypeObj
    } = step1Locations[stateLocation - 1];

    return (
      <Fragment>
        <GridRow>
          <GridCol>
            <InputLabel text={'Location '+ stateLocation} title className='title'/>
          </GridCol>
          <GridCol style={{maxWidth: '120px'}}>
            <img className='w-100' src={byGoogle} alt='google' />
          </GridCol>
        </GridRow>
        {
          stateLocation === 1 &&
          <GridRow className='check line-form'>
            <GridCol>
              <Checkbox name='isSame' value={sameCheckedValue} text='Same as official business address' onChange={sameChecked} />
            </GridCol>
          </GridRow>
        }
        <GridRow className='line-form'>
          <GridCol className='form-group'>
            <InputLabel text={`Location Name${stateLocation === 1 ? ' *' : ''}`}/>
            {
              showAddressDetails ?
                <Input ref="nickname" name="nickname" value={nickname || ''} onChange={changeHandlerInput} validate min={3} max={100} placeholder='Business Name' />
              :
                <DropDownListSelectCustom {...this.getPlacesProps(country, 'optionsPlace', nickname, handleNicknameSelect)} />
            }
          </GridCol>
          <GridCol className='form-group'>
            <InputLabel text={`Phone Number${stateLocation === 1 ? ' *' : ''}`}/>
            <Input ref="phoneNumber" type='tel' name="phoneNumber" value={phoneNumber || ''} onChange={changeHandlerInput} validate min={3} max={100} placeholder='Location Phone Number' />
          </GridCol>
        </GridRow>
        <GridRow className='line-form align-items-center'>
          <GridCol className='form-group mb-0' size={12}>
            <InputLabel text={`Location Address${stateLocation === 1 ? ' *' : ''}`}/>
          </GridCol>
        </GridRow>
        <GridRow className='line-form align-items-center' style={{marginBottom: '40px', flexDirection: 'row'}}>
          <GridCol className='form-group m-0'>
            <DropDownListSelectCustom {...this.getPlacesProps(country, 'optionsPlace', addressSearch, handleAddressSelect)} />
          </GridCol>
          {/* <GridCol style={{maxWidth: 'fit-content'}}>
            <Button onClick={toggleAddressDetails} style={{width: '40px', height: '40px'}} variant={ showAddressDetails ? 'blue' : 'white'} icon={<IconEdit color={ !showAddressDetails ? config.colors.primary : config.colors.rbWhite} />}/>
          </GridCol> */}
        </GridRow>
        <GridRow className={'mx-0' + (showAddressDetails ? '' : ' d-none')} style={{paddingLeft: '15px', marginBottom: '16px'}}>
          <GridCol className='py-3 pl-0' style={{backgroundColor: '#F8F8F8'}}>
            <GridRow className='line-form'>
              <GridCol className='form-group'>
                <InputLabel text={`Address Line${stateLocation === 1 ? ' *' : ''}`}/>
                <Input ref="address" type='text' name="address" value={address || ''} onChange={changeHandlerInput} validate min={3} max={200} placeholder='Address Line' />
              </GridCol>
            </GridRow>
            <GridRow className='line-group'>
              <GridCol className={(isMobile ?  'col-12': 'col-4') + ' form-group'}>
                <InputLabel text={`City${stateLocation === 1 ? ' *' : ''}`}/>
                <Input ref="city" type='text' name="city" value={city || ''} onChange={changeHandlerInput} validate min={3} max={100} placeholder='City' />
              </GridCol>
              <GridCol className={(isMobile ?  'col-6': 'col-4') + ' form-group'}>
                <InputLabel text={getText(country, 'state')}/>
                <DropDownListSelect
                  name='state' ref='state'
                  value={stateObj} onChange={selectStateLocation}
                  options={getStatesProvinces(country)}/>
              </GridCol>
              <GridCol className={(isMobile ?  'col-6': 'col-4') + ' form-group'}>
                <InputLabel text={getText(country, 'zipCode')}/>
                <Input ref="postcode" type='zipCode' name="postcode" value={postcode || ''} onChange={changeHandlerInput} country={country} validate placeholder={getText(country, 'zipCodePlaceholder')} />
              </GridCol>
            </GridRow>
          </GridCol>
        </GridRow>
        {[11, 12].includes(companyId) ?
          <GridRow className='line-form'>
            <GridCol className='form-group'>
              <InputLabel text={`Number of Rooms${stateLocation === 1 ? ' *' : ''}`}/>
              <Input ref="numberOfRooms" type='number' name="numberOfRooms" value={numberOfRooms || ''} onChange={changeHandlerInput} validate min={1} max={1000} placeholder='Number of Rooms' />
            </GridCol>
            <GridCol className='form-group'>
              <InputLabel text={`Location ID${stateLocation === 1 ? ' *' : ''}`}/>
              <Input ref="locationId" type='text' name="locationId" value={locationId || ''} onChange={changeHandlerInput} validate min={1} max={100} placeholder='Location ID' />
            </GridCol>
          </GridRow>
        : null}
        {[11, 12].includes(companyId) ?
          <GridRow className='line-form'>
            <GridCol className='form-group'>
              <InputLabel text={`Flag Type${stateLocation === 1 ? ' *' : ''}`}/>
              <DropDownListSelect
                name='flagType' ref='flagType'
                value={flagTypeObj} onChange={selectFlagTypeLocation}
                options={this.getFlagTypes()}/>
            </GridCol>
          </GridRow>
        : null}
      </Fragment>
    );
  };
}

export default FormDefaultLocation;