import React from 'react';
import { Col, Row, Button, Spinner } from 'reactstrap';
import { isMobile } from 'react-device-detect';

const FaqContactUs = (props) => {
  const {
    containerBorderStyle, sectionTitleClass,
    photoUrl, isLoading, name, title,
    phone, email, bookUrl
  } = props;

  if (isLoading) {
    return (
      <Col className='d-flex flex-column'>
        <Row className={'faq-title ' + (isMobile ? 'my-4 mx-0 ' : 'my-4 mx-1') + sectionTitleClass}>
          <Col>Contact Us</Col>
        </Row>
        <Row className='mx-0 flex-fill'>
          <Col className='bg-white d-flex justify-content-center align-items-center rounded flex-column' style={{ border: containerBorderStyle }}>
            <Spinner />
          </Col>
        </Row>
      </Col>
    )
  }

  if (!name || !email ) {
    return (
      <Col className='d-flex flex-column'>
        <Row className={'faq-title ' + (isMobile ? 'my-4 mx-0 ' : 'my-4 mx-1') + sectionTitleClass}>
          <Col>Contact Us</Col>
        </Row>
        <Row className='mx-0 flex-fill'>
          <Col className='bg-white d-flex justify-content-center rounded flex-column' style={{ border: containerBorderStyle }}>
            <Row className='mb-3'>
              <Col className='text-center faq-title-card font-weight-bold'>
                Please use the chat feature below to reach one of our representatives.
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    );
  }

  return (
    <Col className='d-flex flex-column'>
      <Row className={'faq-title ' + (isMobile ? 'my-4 mx-0 ' : 'my-4 mx-1') + sectionTitleClass}>
        <Col>Contact Us</Col>
      </Row>
      <Row className='mx-0 flex-fill'>
        <Col className='bg-white d-flex justify-content-center rounded flex-column' style={{ border: containerBorderStyle }}>
          <Row>
            <Col className='text-center d-flex justify-content-center mb-3'>
              <div className='rounded-circle' style={{backgroundImage: `url(${photoUrl})`, backgroundSize: 'contain', maxWidth: '100px', minWidth: '100px', height: '100px'}} />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col className='text-center faq-title-card font-weight-bold'>{name}</Col>
          </Row>
          <Row className='mb-3'>
            <Col className='text-center faq-title-card'>{title}</Col>
          </Row>
          <Row className='mb-3'>
            <Col className='text-center faq-title-card'>{phone}</Col>
          </Row>
          <Row className='mb-3'>
            <Col className='text-center faq-title-card text-email-link'>{email}</Col>
          </Row>
          {
            bookUrl &&
            <Row>
              <Col className='text-center'>
                <Button active style={{width: isMobile ? '100%' : ''}} className='py-2 shadow-none' color='primary'>BOOK TIME</Button>
              </Col>
            </Row>
          }
        </Col>
      </Row>
    </Col>
  );
};

export default FaqContactUs;