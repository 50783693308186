import React from 'react';
import { isMobile } from 'react-device-detect';
import config from '../../../config/config';
import Button from '../../../MasterComponents/Button/Button';
import DropDownListSelect from '../../../MasterComponents/DropDownList/DropDownListSelect';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import IconEdit from '../../../MasterComponents/Icons/IconEdit';
import Input from '../../../MasterComponents/Input/Input';
import InputLabel from '../../../MasterComponents/InputLabel/InputLabel';
import byGoogle from '../../../assets/images/powered-by-google.png';
import DropDownListSelectCustom from '../../../MasterComponents/DropDownList/DropDownListSelectCustom';
import { getText } from '../../../MasterComponents/Helper/getHelper';

const Places = ({placesAutocompleteProps, businessName, placesForm, addressDetails, phoneNumber, isIframe, country}) => {
  return (
    <>
      <GridRow className='mt-3'>
        <GridCol size={(isMobile || isIframe) ? 12 : 6}>
          <GridRow className='align-items-end'>
            <GridCol className='pr-0'>
              <InputLabel text='Business Name' />
              {
                addressDetails.value ?
                  <Input placeholder='Business Name' name='businessName' {...businessName} />
                :
                  <DropDownListSelectCustom {...placesAutocompleteProps} />
              }
            </GridCol>
            <GridCol style={{maxWidth: 'fit-content'}}>
              <Button onClick={addressDetails.toggle} style={{width: '40px', height: '40px'}} variant={ addressDetails.value ? 'blue' : 'white'} icon={<IconEdit color={ !addressDetails.value ? config.colors.primary : config.colors.rbWhite} />}/>
            </GridCol>
          </GridRow>
        </GridCol>
      </GridRow>
      <GridRow className='mt-2'>
        <GridCol maxWidth='120px'>
          <img className='w-100' src={byGoogle} alt='google' />
        </GridCol>
      </GridRow>
      <GridRow className={addressDetails.value ? 'mx-0 mt-2' : 'mx-0 d-none'}>
        <GridCol className='py-2' style={{backgroundColor: '#F8F8F8'}}>
          <GridRow>
            <GridCol>
              <InputLabel text='Phone Number' />
              <Input placeholder='Phone Number' clearOnInvalid name='phoneNumber' validate type='tel' {...phoneNumber} />
            </GridCol>
          </GridRow>
          <GridRow className='mt-2'>
            <GridCol>
              <InputLabel text='Address Line *' />
              <Input placeholder='Address Line' name='street' {...placesForm.street} />
            </GridCol>
          </GridRow>
          <GridRow className='mt-2'>
            <GridCol className={isMobile ?  'col-12': 'col-4'}>
              <InputLabel text='City *' />
              <Input placeholder='City' name='city' {...placesForm.city} />
            </GridCol>
            <GridCol className={isMobile ?  'col-6 mt-2': 'col-4'}>
              <InputLabel text={getText(country, 'state')} />
              <DropDownListSelect name='state' {...placesForm.state} />
            </GridCol>
            <GridCol className={isMobile ?  'col-6 mt-2': 'col-4'}>
              <InputLabel text={getText(country, 'zipCode')} />
              <Input placeholder='Zipcode' name='zipcode' {...placesForm.zipcode} />
            </GridCol>
          </GridRow>
        </GridCol>
      </GridRow>
    </>
  );
};

export default Places;