import React, { Component } from 'react';
import { findModal } from '../../../MasterComponents/Modals/ModalsActions';
import { isMobile } from 'react-device-detect';
import Modal from '../../../MasterComponents/Modals/Modal';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import Button from '../../../MasterComponents/Button/Button';
import IconClose from '../../../MasterComponents/Icons/IconClose';

 class InsightsInsideTrackModal extends Component {

  closeModal = () => {
    const { modals } = this.props;
    findModal(modals, 'idInsightsInsideTrackModal').close();
  }

  scheduleDemo = () => {
    const { modals } = this.props;
    this.props.scheduleDemo();
    findModal(modals, 'idInsightsInsideTrackModal').close();
  }

  render() {
    const styles = {
      position: 'absolute', 
      top: '60%', 
      left: isMobile ? '0%' : '-25%'
    }

    return (
      <Modal id='idInsightsInsideTrackModal' backdrop={false} size={isMobile ? 'md': 'lg'}>
        <ModalBody className='bg-inside-track px-0 py-3'>
          <GridRow>
            <GridCol size={11} >
            </GridCol>
            <GridCol size={1} className='clickable pl-0' onClick={this.closeModal}>
              <IconClose color={isMobile ? 'white': 'gray'} size={16} />
            </GridCol>
          </GridRow>
          <GridRow className='text-center'>
            <GridCol style={styles}>
              <Button type='button' variant='primary' size='md' text='SCHEDULE A DEMO' onClick={this.scheduleDemo}></Button>
            </GridCol>
          </GridRow>
        </ModalBody>
      </Modal>
    )
  }
}

export default InsightsInsideTrackModal;
