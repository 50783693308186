export default function reducer(state = {
  section: {
    button: 'Rebates Portal',
    title: 'Rebates Portal',
  },
  merchants: [],
  users: [],
  activeMerchant: {},
  enableToOpenInsights : true,
  moreSettingsOpen: false,
  displayBrowserExtension: false
}, action) {
  switch (action.type) {
    case 'CHANGE_SECTION':
      return {
        ...state,
        section: action.payload
      }
    case 'UPDATE_MERCHANTS':
      return {
        ...state,
        merchants: action.payload
      }
    case 'UPDATE_ACTIVE_MERCHANT':
      return {
        ...state,
        activeMerchant: action.payload
      }
    case 'ORDERLY_START':
      return {
        ...state,
        activeMerchant: {
          ...state.activeMerchant,
          fcmAvailable: 1
        }
      }
    case 'UPDATE_USERS':
      return {
        ...state,
        users: action.payload
      }
    case 'SET_ENABLE_INSIGHTS_MODAL':
      return {
        ...state,
        enableToOpenInsights: action.payload
      }
    case 'SET_SETTINGS_OPEN':
      return {
        ...state,
        moreSettingsOpen: action.payload
      }
    case 'UPDATE_DISPLAY_BROWSER_EXTENSION':
      return {
        ...state,
        displayBrowserExtension: action.payload
      }
    default:
      return state;
  }
}
