import React, { useRef } from 'react';
import config from '../../../../config/config';
import DashedBox from '../../../../MasterComponents/DashedBox/DashedBox';
import IconUpload from '../../../../MasterComponents/Icons/IconUpload';
import * as $ from 'jquery';
import { Col, Row } from 'reactstrap';
import LogoPdf from '../../../../MasterComponents/Logos/LogoPdf';
import LogoExcel from '../../../../MasterComponents/Logos/LogoExcel';
import IconDelete from '../../../../MasterComponents/Icons/IconDelete';

const UploadContract = ({onSelectFile, file}) => {
	const inputUploadRef = useRef(null);
	const getFile = () => {
    const files = $('#contract-upload').prop('files');

		if (files.length > 0) {
			const file = files[0];
			onSelectFile({
				isPdf: file.type === 'application/pdf' ? true : false,
				fileName: file.name,
				file: file
			});
		} 
	};

	const onUpload = () => {
		inputUploadRef.current.click();
	};

	const onDelete = () => {
		onSelectFile(null);
	};

	return (
		<>
			<input ref={inputUploadRef} className='d-none' onChange={getFile} type='file' id='contract-upload' accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xsl, .xlsx, application/vnd.oasis.opendocument.spreadsheet, application/pdf' />
			{
				file === null ?
				<DashedBox onClick={onUpload} height='90px' className='m-0 clickable text-grey mt-2' textClassName='text-size-12'>
					<IconUpload color={config.colors.rbDarkerGrey} /> &nbsp;
					Upload new contract (PDF or Excel file)
				</DashedBox>
				:
				<Row>
					<Col>
						{
							file.isPdf ? <LogoPdf style={{width: '30px'}} /> : <LogoExcel style={{width: '30px'}} />
						}
						{file.fileName}
						<span onClick={onDelete} className='float-right mt-1 clickable'><IconDelete color={config.colors.rbDarkGrey} /></span>
					</Col>
				</Row>
			}
		</>
	);
};

export default UploadContract;