import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import PrimeProgramsBody from '../PrimePrograms/PrimeProgramsBody';
import useHomePrograms from '../HomeMain/hooks/useHomePrograms'

const HomePrograms = (props) => {
  const { onGoToPrograms } = useHomePrograms(props)
  const propsPrograms = {
    isSliced: true
  }
  return (
    <>
      <Row className={'line-height-38 ' + (isMobile ? 'text-size-30' : 'text-size-32')}>
        <Col className={'py-3 ' + (isMobile ? 'px-0' : '')}>
          Beyond Broadline Programs Recommended For You
        </Col>
      </Row>
      <Row>
        <Col className={isMobile ? 'px-3' : 'px-4'}>
          <PrimeProgramsBody {...propsPrograms} />
        </Col>
      </Row>
      <Row className='py-3'>
        <Col className={isMobile ? 'px-0' : ''}>
          <Button active block  style={{width: isMobile ? '100%' : ''}} className='py-2 shadow-none' size='sm' color='primary'onClick={onGoToPrograms} >VIEW ALL</Button>
        </Col>
      </Row>
    </>
  )
}

export default HomePrograms;