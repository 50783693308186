import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import config from '../../../../config/config';
import Button from '../../../../MasterComponents/Button/Button';
import DashedBox from '../../../../MasterComponents/DashedBox/DashedBox';
import GridCol from '../../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../../MasterComponents/Grid/GridRow';
import IconAdd from '../../../../MasterComponents/Icons/IconAdd';
import IconDelete from '../../../../MasterComponents/Icons/IconDelete';
import IconEdit from '../../../../MasterComponents/Icons/IconEdit';
import { saveActiveMerchant, setDisplayBrowserExtension } from '../../../Dashboard/DashboardActions';
import DashboardApi from '../../../Dashboard/DashboardApi';
import LocationApi from '../../../Location/LocationApi';
import SignUpApi from '../../../SignUp/SignUpApi';
import axios from "axios";

const DistributorStepList = ({onAddNew, history, distributorsProps, setMaxStep}) => {
  const dispatch = useDispatch();
  const { distributorMerchants, refresh } = distributorsProps;

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  const onGoBack = () => {
    history.replace('/onboarding/1');
  };

  const onSkipOrDone = () => {
    const email = localStorage.getItem('email');
    SignUpApi.onBoard({email: email, locations: []}).then(({data}) => {
      LocationApi.getCurrentUser().then((res) => {
        const result = res.data.data;
        localStorage.setItem('activeMerchant', JSON.stringify(result.activeMerchant));
        localStorage.setItem('status', result.status);
        localStorage.setItem('userid', result.id);
        localStorage.setItem('contactName', result.contactName);
        localStorage.setItem('username', result.username);
        localStorage.setItem('email', result.email);
        localStorage.setItem('phoneNumber', result.phoneNumber);
        localStorage.setItem('title', result.title);
        localStorage.setItem('isLoggedIn', true);
        localStorage.setItem('authKey', result.jwt);
        localStorage.setItem('isFoodbamActive', result.isFoodbamActive);
        localStorage.setItem('status', result.status);
        dispatch(saveActiveMerchant(result.activeMerchant));
        dispatch(setDisplayBrowserExtension(result.displayBrowserExtension));
        history.push('/dashboard');
      }).catch(err => {
        console.error(err);
      });
    }).catch(err => {
      console.error(err);
    });
  };

  const onDelete = (distId) => {
    let calls = [];

    const dist = distributorMerchants.filter(dist => dist.id === distId)[0];

    if (dist) {
      for (let i = 0; i < dist.merchantDistributors.length; i++) {
        const merDist = dist.merchantDistributors[i];

        calls.push(DashboardApi.deleteDistributor(merDist.id));
      }

      axios.all(calls).then(axios.spread((...res) => {
        refresh()
      })).catch(err => {
        console.error(err);
      });
    }
  };

  const distMap = distributorMerchants.map(dist => {
    const onEdit = () => {
      history.push(`/onboarding/2/edit/${dist.id}`);
    };
    return (
      <GridRow key={`dist_${dist.id}`} className='my-3'>
        <GridCol size={isMobile ? 12 : 6}>
          <GridRow className='m-0 border py-4'>
            <GridCol>
              {dist.nickname}
            </GridCol>
            <GridCol maxWidth='fit-content' className='clickable' onClick={onEdit}>
              <IconEdit color={config.colors.rbGrey} />
            </GridCol>
            <GridCol maxWidth='fit-content' className='clickable' onClick={() => onDelete(dist.id)}>
              <IconDelete color={config.colors.rbGrey} />
            </GridCol>
          </GridRow>
        </GridCol>
      </GridRow>
    )
  });

  if (isMobile) {
    return (
      <div className='vh-100'>
        <div className='container flex-grow-1' style={{paddingTop: '88px', paddingBottom: '100px'}}>
          <GridRow className='border-bottom pb-3'>
            <GridCol>
              Add your distributor customer numbers (DCN)​ and login credentials to your distributor’s portal​s.
            </GridCol>
          </GridRow>
          <GridRow className='my-3'>
            <GridCol className='font-weight-bold'>
              Distributors
            </GridCol>
          </GridRow>
          {distMap}
          <GridRow>
            <GridCol>
              <DashedBox className='clickable m-0 py-4' textClassName='text-size-14' onClick={onAddNew}>
                <IconAdd size={14} color={config.colors.rbGrey} />
                &nbsp; Add New Distributor
              </DashedBox>
            </GridCol>
          </GridRow>
        </div>
        <GridRow className='m-0 py-3 bg-white w-100 position-absolute' style={{bottom: 0}}>
          <GridCol>
            <Button variant='yellow' text={distributorMerchants.length > 0 ? 'DONE' : 'SKIP'} onClick={onSkipOrDone} />
          </GridCol>
        </GridRow>
      </div>
    );
  }

  return (
    <div className='container' style={{paddingTop: '30px', paddingBottom: '30px'}}>
      <GridRow className='pb-3'>
        <GridCol>
          Add your distributor customer numbers (DCN)​ and login credentials to your distributor’s portal​s.
        </GridCol>
      </GridRow>
      <GridRow className='my-3'>
        <GridCol className='font-weight-bold'>
          Distributors
        </GridCol>
      </GridRow>
      {distMap}
      <GridRow>
        <GridCol size={6}>
          <DashedBox className='clickable m-0 py-4' textClassName='text-size-14' onClick={onAddNew}>
            <IconAdd size={14} color={config.colors.rbGrey} />
            &nbsp; Add New Distributor
          </DashedBox>
        </GridCol>
      </GridRow>
      <GridRow className='py-3'>
        <GridCol size={6}>
          <GridRow>
            <GridCol size={4}>
              <Button variant='yellow-outline' text='BACK' onClick={onGoBack} />
            </GridCol>
            <GridCol size={4}>
              <Button variant='yellow' text={distributorMerchants.length > 0 ? 'DONE' : 'SKIP'} onClick={onSkipOrDone} />
            </GridCol>
          </GridRow>
        </GridCol>
      </GridRow>
    </div>
  );
};

export default DistributorStepList;
