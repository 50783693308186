import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconFolder = props => (
  <svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Designs" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="36---3rd-Distributor-Activated" transform="translate(-548.000000, -321.000000)" fill={!props.isActive ? props.color : props.activeColor}>
        <g id="rebates" transform="translate(0.000000, 220.000000)">
          <g id="offline-distributor" transform="translate(376.000000, 80.000000)">
            <g id="icons/folder" transform="translate(172.000000, 21.000000)">
              <path d="M0.96969697,12.6060606 L14.5454545,12.6060606 C14.8029091,12.6060606 15.0152727,12.4048485 15.0298182,12.1483636 L15.5146667,3.42109091 C15.5219394,3.28824242 15.4744242,3.15781818 15.3827879,3.06084848 C15.2911515,2.96387879 15.1636364,2.90909091 15.030303,2.90909091 L14.5454545,2.90909091 L14.5454545,1.45454545 C14.5454545,1.18690909 14.3282424,0.96969697 14.0606061,0.96969697 L7.03030303,0.96969697 L6.44848485,0.193939394 C6.35684848,0.0717575758 6.21333333,0 6.06060606,0 L1.45454545,0 C1.18690909,0 0.96969697,0.217212121 0.96969697,0.484848485 L0.96969697,2.90909091 L0.484848485,2.90909091 C0.351515152,2.90909091 0.224,2.96387879 0.132363636,3.06084848 C0.0407272727,3.15733333 -0.00678787879,3.28775758 0.000484848485,3.42109091 L0.485333333,12.1483636 C0.499878788,12.4048485 0.712242424,12.6060606 0.96969697,12.6060606 Z M14.0867879,11.6363636 L1.42836364,11.6363636 L0.997333333,3.87878788 L14.5178182,3.87878788 L14.0867879,11.6363636 Z M1.93939394,0.96969697 L5.81818182,0.96969697 L6.4,1.74545455 C6.49163636,1.86763636 6.63515152,1.93939394 6.78787879,1.93939394 L13.5757576,1.93939394 L13.5757576,2.90909091 L1.93939394,2.90909091 L1.93939394,0.96969697 Z" id="Shape"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

IconFolder.propTypes = {
  color: PropTypes.string,
  activeColor: PropTypes.string,
  isActive: PropTypes.bool,
  size: PropTypes.number,
};

IconFolder.defaultProps = {
  activeColor: config.colors.rbYellow,
  color: config.colors.rbWhite,
  isActive: false,
  size: 16,
};

export default IconFolder;