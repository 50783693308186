import React from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import DropDownListSelect from '../../../MasterComponents/DropDownList/DropDownListSelect';
import InputMask from 'react-input-mask';
import Joi from 'joi';

const SCHEMA = Joi.object({
  firstName: Joi.string().required().min(2),
  lastName: Joi.string().required().min(2),
  title: Joi.string().required(),
  restaurantName: Joi.string().required().min(2),
  phone: Joi.string().required().length(14),
  email: Joi.string().email({ tlds: {allow: false} }).required().label('Email'),
  state: Joi.string().length(2).required(),
  purchaseFromDistributor: Joi.string().required(),
});

const JoinSignupFormSmall = (props) => {
	const { firstName, lastName, title, restaurantName, phone, email, state, purchaseFromDistributor, onSubmit, form} = props;
	const values = {
		firstName: form.firstName,
		lastName: form.lastName,
		title: form.title && form.title.value,
		restaurantName: form.restaurantName,
		email: form.email,
		phone: form.phone && form.phone.replace('_', ''),
		state: form.state && form.state.value,
		purchaseFromDistributor: form.purchaseFromDistributor && form.purchaseFromDistributor.value,
	};
	const validation = SCHEMA.validate(values, {abortEarly: false, errors: {wrap: {label: ''}}});
	const canSubmit = validation.error ? true : false;

	const onFormSubmit = (e) => {
		e.preventDefault();
	};

	return (
		<Col>
			<Row className='mb-2'>
				<Col className='text-signup-title text-center'>FILL OUT THIS SHORT FORM TO BECOME A MEMBER</Col>
			</Row>
			<Form onSubmit={onFormSubmit}>
				<FormGroup>
					<Label>
						First Name *
					</Label>
					<Input {...firstName} />
					<Label>
						Last Name *
					</Label>
					<Input {...lastName} />
					<Label>
						Title *
					</Label>
					<DropDownListSelect {...title} />
					<Label>
						Restaurant Name *
					</Label>
					<Input {...restaurantName} />
					<Label>
						Phone *
					</Label>
					<InputMask mask='(999) 999-9999' {...phone}>
						{(inputProps) => <Input {...inputProps} />}
					</InputMask>
					<Label>
						Email *
					</Label>
					<Input {...email} />
					<Label>
						State *
					</Label>
					<DropDownListSelect {...state} />
					<Label>
						Do you purchase from a distributor *
					</Label>
					<DropDownListSelect {...purchaseFromDistributor} />
					<Button block className='mt-4' color='warning' type='submit' onClick={onSubmit} disabled={canSubmit}>
						SUBMIT!
					</Button>
				</FormGroup>
			</Form>
		</Col>
	);
};

export default JoinSignupFormSmall;