import uniqid from 'uniqid';

export const showSidebar = (component, props) => {
  const id = uniqid('sidebar-');
  return {
    type: 'ADD_SIDEBAR',
    payload: {
      component,
      id: id,
      props: {
        ...props,
        id: id,
        isOpen: true
      }
    }
  };
};

export const destroySidebar = (id) => {
  return {
    type: 'REMOVE_SIDEBAR',
    payload: id
  };
};

export const updateSidebar = (id, props) => {
  return {
    type: 'UPDATE_SIDEBAR',
    payload: {
      id,
      props
    }
  };
};