import { apiInstance } from '../../config/tokenApi';
import config from '../../config/config';

const getUserMerchantDistributors = () => {
  return apiInstance.get('user/merchant-distributors');
}

const foodBamMydaLogin = (data) => {
  return apiInstance.post(`${config.foodBamApiURL}/be/myda-login`, data, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

const foodBamMydaRegister = (data) => {
  return apiInstance.post(`${config.foodBamApiURL}/be/myda-register`, data, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

export default {
  getUserMerchantDistributors,
  foodBamMydaLogin,
  foodBamMydaRegister,
};
