import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { getBrowser } from '../../MasterComponents/Helper/getHelper';
import GridCol from '../../MasterComponents/Grid/GridCol';
import PrimeProgramsApi from '../PrimePrograms/PrimeProgramsApi';

class PrimeProgramsAd extends Component {
  intervalAd = null;
  intervalHover = null;
  intervalScreen = null;
  constructor(props) {
    super(props);
    this.state = {
      idCurrent: 0,
      index: 0,
      hoverTime: 0,
      screenTime: 0,
      adsList: [],
      imgSrc: '',
      targetUrl: '',
      isHover: false,
    }
  }

  componentDidMount = () => {
    this.getInit();
  }

  componentWillUnmount = () => {
    const { adsList } = this.state;
    const adsListSelected = adsList.filter(ads => ads.isSelected === true);
    const listParams = { list: adsListSelected };
    PrimeProgramsApi.saveManufacturerAd(listParams).then(({data}) => {});
    this.resetIntervalAd();
    this.resetIntervalHover();
    this.resetIntervalScreen();
  }

  getInit = ()=> {
    const { manufacturerAd, activeMerchant, companyBrand } = this.props;
    const sessionStart = Math.round((new Date()).getTime());
    const platform = isMobile ? 'Mobile' : 'Desktop';
    const userEmail = localStorage.getItem('email');
    const userContactName = localStorage.getItem('contactName');
    const userCompany = activeMerchant ? activeMerchant.nickname : '';
    const browser = getBrowser();
    this.setState({
      adsList: manufacturerAd.map((a) => {
        return {
          id: a.externalId,
          clicks: 0,
          hoverTimeTotal: 0,
          hoverEnterTotal: 0,
          onScreenTimeTotal: 0,
          sessionStart: sessionStart,
          platform: platform,
          browser: browser,
          userEmail: userEmail,
          userCompany: userCompany,
          user: userContactName,
          portal: companyBrand.name,
          isSelected: false
        }
      })
    });
    this.loadImage(0);
    this.startIntervalAd();
    this.startIntervalScreen();
  }

  loadImage = (index) => {
    const { adsList, idCurrent, isHover, hoverTime, screenTime } = this.state;
    const { manufacturerAd } = this.props;
    const lastAd = manufacturerAd.length - 1;
    if(index >= lastAd) {
      this.setState({
        idCurrent: manufacturerAd[lastAd] ? manufacturerAd[lastAd].externalId : 0,
        imgSrc: manufacturerAd[lastAd] ? manufacturerAd[lastAd].fileUrl : '',
        targetUrl: manufacturerAd[lastAd] ? manufacturerAd[lastAd].targetUrl : '',
        index: 0
      });
      if(isHover) {
        this.setState({
          adsList: adsList.map((a) => {
            if(a.id !== idCurrent) return a;
            return {
              ...a,
              hoverTimeTotal: a.hoverTimeTotal + hoverTime,
              onScreenTimeTotal: a.onScreenTimeTotal + screenTime,
              isSelected: true
            }
          })
        });
        this.resetIntervalHover();
        this.startIntervalHover();
        this.resetIntervalScreen();
        this.startIntervalScreen();
      }
    } else {
      this.setState({
        idCurrent: manufacturerAd[index].externalId,
        imgSrc: manufacturerAd[index].fileUrl,
        targetUrl: manufacturerAd[index].targetUrl,
        index: index + 1
      })
      if(isHover) {
        this.setState({
          adsList: adsList.map((a) => {
            if(a.id !== idCurrent) return a;
            return {
              ...a,
              hoverTimeTotal: a.hoverTimeTotal + hoverTime,
              onScreenTimeTotal: a.onScreenTimeTotal + screenTime,
              isSelected: true
            }
          })
        });
        this.resetIntervalHover();
        this.startIntervalHover();
        this.resetIntervalScreen();
        this.startIntervalScreen();
      } 
    }
  }
  
  onMouseEnterAd = () => {
    const { idCurrent, adsList, hoverTime } = this.state;
    this.setState({
      isHover: true,
      adsList: adsList.map((a) => {
        if(a.id !== idCurrent) return a;
        return {
          ...a,
          hoverEnterTotal: a.hoverEnterTotal + 1,
          hoverTimeTotal: a.hoverTimeTotal + hoverTime
        }
      })
    });
    this.resetIntervalHover();
    this.startIntervalHover()
  }

  onMouseLeaveAd = () => {
    this.resetIntervalHover();
    this.setState({
      isHover: false
    });
  }

  onClickAd = () => {
    const { idCurrent, adsList, targetUrl } = this.state;
    this.setState({
      isHover: true,
      adsList: adsList.map((a) => {
        if(a.id !== idCurrent) return a;
        return {
          ...a,
          clicks: a.clicks + 1,
          isSelected: true
        }
      })
    });
    if(targetUrl) {
      window.open(targetUrl, '_blank');
    }
  }

  startIntervalAd = () => {
    this.intervalAd = setInterval(() => { 
      const { index } = this.state;
      this.loadImage(index);
    }, 20000);
  }

  startIntervalHover = () => {
    let counter = 0;
    this.intervalHover = setInterval(() => {
      counter +=1;
      this.setState({
        hoverTime: counter
      });
    }, 1);
  }

  startIntervalScreen = () => {
    let counter = 0;
    this.intervalScreen = setInterval(() => {
      counter +=1;
      this.setState({
        screenTime: counter
      });
    }, 1);
  }

  resetIntervalAd = () => {
    clearInterval(this.intervalAd);
    this.setState({
      adsList: []
    });
  }

  resetIntervalHover = () => {
    clearInterval(this.intervalHover);
    this.setState({
      hoverTime: 0
    })
  }

  resetIntervalScreen = () => {
    clearInterval(this.intervalScreen);
    this.setState({
      screenTime: 0
    });
  }

  render() {
    const { imgSrc } = this.state;
    return (
      <GridCol className='px-0 clickable' onMouseEnter={()=> this.onMouseEnterAd()} onMouseLeave={this.onMouseLeaveAd} onClick={this.onClickAd}>
        <img src={imgSrc} height='320px' width='100%'  alt='ads'/>
      </GridCol>
    )
  }
}

export default PrimeProgramsAd;
