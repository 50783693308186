export default function reducer(state = {
  sidebar: []
  }, action) {
  switch (action.type) {
    case 'ADD_SIDEBAR':
      return {
        ...state,
        sidebar: [
          ...state.sidebar,
          action.payload
        ]
      };
    case 'REMOVE_SIDEBAR':
      return {
        ...state,
        sidebar: state.sidebar.filter(item => item.id !== action.payload)
      };
    case 'UPDATE_SIDEBAR':
      return {
        ...state,
        sidebar: state.sidebar.map(item => {
          if (item.id !== action.payload.id) {
            return item;
          }
          return {
            ...item,
            props: {
              ...item.props,
              ...action.payload.props
            }
          }
        })
      };
    default:
      return state;
  }
};