import { apiInstance } from '../../config/tokenApi';

const lookUp = (params, token) => {
    return apiInstance.post('/check/list' + (token ? `?access-token=${token}` : ''), params);
};

const checkDetail = (params, token) => {
    return apiInstance.post('/check/detail' + (token ? `?access-token=${token}` : ''), params);
};

const checkRequest = (params, token) => {
    return apiInstance.post('/check/request-hw' + (token ? `?access-token=${token}` : ''), params);
};

const achToken = (token) => {
    return apiInstance.get('check', {
        params: {
            'access-token': token
        }
    })
};

export default {
    lookUp,
    checkDetail,
    checkRequest,
    achToken
};
