import React, { Component } from 'react'
import GridRow from '../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import Modal from '../../../MasterComponents/Modals/Modal';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import OrderlyApi from '../../Orderly/OrderlyApi';
import { spinner } from '../../../MasterComponents/Spinner/SpinnerActions';
import { findModal, showModal } from '../../../MasterComponents/Modals/ModalsActions';
import { saveActiveMerchant } from '../../Dashboard/DashboardActions';
import AlertModal from '../../../MasterComponents/AlertModal.js/AlertModal';

class PreparingAccountModal extends Component {
  componentDidMount() {
    setTimeout(() => {
      this.loading();
    }, 2000);
  };
  loading() {
    const { dispatch, history, modals, salesRepId } = this.props;

    dispatch(spinner(true));
    OrderlyApi.getAccess(salesRepId).then(({data}) => {
      dispatch(spinner(false));
      if (data.data === true) {
        dispatch(saveActiveMerchant({
          fcmAvailable: 1
        }));
        setTimeout(() => {
          history.push('/dashboard/foodCost/orderly');
          findModal(modals, 'preparingAccountModal').close();
        }, 500);
      }
    }).catch(error => {
      dispatch(spinner(false));
      console.error(error);
      const props = {
        message : `Time out, please wait 5 minutes and refresh.`,
        buttonText: 'Ok'
      };
      dispatch(showModal(AlertModal, 'idAlertModal', {...props}));
      setTimeout(() => {
        findModal(modals, 'preparingAccountModal').close();
      }, 500);
    });
  };
  render() {
    return (
      <Modal id='preparingAccountModal' backdrop={false}>
        <ModalBody>
          <GridRow>
            <GridCol>
              Preparing your account. It will take some time...
            </GridCol>
          </GridRow>
        </ModalBody>
      </Modal>
    );
  }
};

export default PreparingAccountModal;