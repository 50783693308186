import React, { useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { Row, Col, Button } from 'reactstrap';
import config from '../../config/config';
import IconEmailReport from '../../MasterComponents/Icons/IconEmailReport';
import Input from '../../MasterComponents/Input/Input';

const ReportsSubscribe = ({email, onSaveSubscribe, onChangeHandler}) => {
  const inputEmail = useRef();
  const onSave = () => {
    if(inputEmail.current.isValid()) {
      onSaveSubscribe();
    } 
  }
  return (
    <Row className={'py-2 mt-4 ' + (isMobile ? '' : 'mx-5')} style={{backgroundColor: config.colors.rbDarkAqua}}>
      <Col xs={isMobile ? 3 : 2} className='p-3'>
        <IconEmailReport size={60} color={config.colors.rbWhite} />
      </Col>
      <Col xs={isMobile ? 9 : 10}>
        <Row>
          <Col>
            <Row className='py-2 text-size-14 line-height-17'>
              <span> Subscribe to get notified when new monthly reports are available </span>
            </Row>
            <Row>
              <Col xs={8} className='pl-0'>
                <Input ref={inputEmail} type='email' name='email' value={email} onChange={onChangeHandler} validate min={3} max={100} placeholder='Email' />
              </Col>
              <Col xs={4}>
                <Button active block style={{width: isMobile ? '100%' : ''}} className='py-2 shadow-none' size='sm' color='primary'onClick={onSave} >SAVE</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default ReportsSubscribe;
