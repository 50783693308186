import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconEmailReport = props => (
  <svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 80 80" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path d="M32.2431817,0.986478915 L62,24.5988541 L62,24.5988541 L0,24.5988541 L29.7568183,0.986478915 C30.4849168,0.408724414 31.5150832,0.408724414 32.2431817,0.986478915 Z" id="path-1"></path>
    </defs>
    <g id="Designs---Neutral-Demo" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="1---Insights" transform="translate(-728.000000, -1526.000000)">
        <g id="charts" transform="translate(0.000000, 1221.000000)">
          <g id="banner" transform="translate(720.000000, 297.000000)">
            <g id="icons/mail-report" transform="translate(8.000000, 8.000000)">
              <rect id="bound" x="0" y="0" width="80" height="80"></rect>
              <g id="Group-10" transform="translate(9.000000, 5.000000)">
                <path d="M32.3908429,1.34597696 L62,30 L62,30 L0,30 L29.6091571,1.34597696 C30.3845646,0.595582668 31.6154354,0.595582668 32.3908429,1.34597696 Z" id="Triangle" fill={!props.isActive ? props.color : props.activeColor}></path>
                <rect id="Rectangle" fill={!props.isActive ? props.color : props.activeColor} x="0" y="30" width="62" height="39" rx="2"></rect>
                <line x1="38.1165237" y1="49.5" x2="62" y2="69" id="Path-4" stroke="#E7E7E7"></line>
                <line x1="1.10084534" y1="49.5" x2="25.259552" y2="68.3709259" id="Path-4" stroke="#E7E7E7" transform="translate(13.180199, 58.935463) scale(-1, 1) translate(-13.180199, -58.935463)"></line>
                <g id="Group-9" transform="translate(0.000000, 30.000000)">
                  <mask id="mask-2" fill="white">
                  </mask>
                  <use id="Triangle" fill="#F1F1F1" transform="translate(31.000000, 12.299427) scale(1, -1) translate(-31.000000, -12.299427) " ></use>
                  <rect id="Rectangle" fill="#334F9A" mask="url(#mask-2)" x="11" y="-18" width="4" height="40"></rect>
                  <rect id="Rectangle" fill="#26C0FB" mask="url(#mask-2)" x="23" y="-4" width="4" height="31"></rect>
                  <rect id="Rectangle" fill="#FB4726" mask="url(#mask-2)" x="36" y="-11" width="4" height="40"></rect>
                  <rect id="Rectangle" fill="#FA9016" mask="url(#mask-2)" x="48" y="-14" width="4" height="40"></rect>
                </g>
                <path d="M13,12 C14.1045695,12 15,12.8954305 15,14 L15,30 L15,30 L11,30 L11,14 C11,12.8954305 11.8954305,12 13,12 Z" id="Rectangle" fill="#334F9A"></path>
                <path d="M25,28 C26.1045695,28 27,28.8954305 27,30 L27,30 L27,30 L23,30 C23,28.8954305 23.8954305,28 25,28 Z" id="Rectangle" fill="#26C0FB"></path>
                <path d="M38,19 C39.1045695,19 40,19.8954305 40,21 L40,30 L40,30 L36,30 L36,21 C36,19.8954305 36.8954305,19 38,19 Z" id="Rectangle" fill="#FB4726"></path>
                <path d="M50,8 C51.1045695,8 52,8.8954305 52,10 L52,30 L52,30 L48,30 L48,10 C48,8.8954305 48.8954305,8 50,8 Z" id="Rectangle" fill="#FA9016"></path>
                <path d="M32.2431817,45.9864789 L62,69.5988541 L62,69.5988541 L0,69.5988541 L29.7568183,45.9864789 C30.4849168,45.4087244 31.5150832,45.4087244 32.2431817,45.9864789 Z" id="Triangle" fill={!props.isActive ? props.color : props.activeColor}></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

IconEmailReport.propTypes = {
  color: PropTypes.string,
  activeColor: PropTypes.string,
  isActive: PropTypes.bool,
  size: PropTypes.number,
};

IconEmailReport.defaultProps = {
  activeColor: config.colors.rbYellow,
  color: config.colors.rbWhite,
  isActive: false,
  size: 16,
};

export default IconEmailReport;