
export default function reducer(state = {
    links: [{
        button: 'login',
        title: 'Log In'
      }, {
        button: 'register',
        title: 'SignUp'
      }
    ],    
  }, action) {
    switch (action.type) {
      default:
        return state;
    }
  }