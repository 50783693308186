import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { showModal, updateModalProps } from '../../../MasterComponents/Modals/ModalsActions';
import DistributorStepAddNewModal from './Components/DistributorStepAddNewModal';
import DistributorStepIndex from './Components/DistributorStepIndex';
import DistributorStepList from './Components/DistributorStepList';

const OnboardingDistributorStep = ({history, distributorsProps, setMaxStep, setdistributorName}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateModalProps('addNewDistributorModal', distributorsProps));
  }, [distributorsProps]);

  const onAddNew = () => {
    dispatch(showModal(DistributorStepAddNewModal, 'addNewDistributorModal', distributorsProps));
  };

  return (
    <Switch>
      <Route exact path='/onboarding/2'>
        <DistributorStepList onAddNew={onAddNew} distributorsProps={distributorsProps} history={history} setMaxStep={setMaxStep} />
      </Route>
      <Route exact path='/onboarding/2/edit/:merDistId'
        render={historyProps => <DistributorStepIndex {...historyProps} distributorsProps={distributorsProps} setdistributorName={setdistributorName} />}
      />
      <Route>
        <Redirect to='/onboarding/2' />
      </Route>
    </Switch>
  );
};

export default OnboardingDistributorStep;