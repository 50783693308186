import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoginApi from './LoginApi';
import LoginSidebar from './LoginSidebar';
import InputLabel from '../../MasterComponents/InputLabel/InputLabel';
import { isMobile } from 'react-device-detect';
import GridRow from '../../MasterComponents/Grid/GridRow';
import GridCol from '../../MasterComponents/Grid/GridCol';
import DropDownListSelect from '../../MasterComponents/DropDownList/DropDownListSelect';
import GridContainer from '../../MasterComponents/Grid/GridContainer';
import { spinner } from '../../MasterComponents/Spinner/SpinnerActions';
import Button from '../../MasterComponents/Button/Button';
import setToken from '../../config/tokenApi';
import * as Utils from '../../utils/utils';

class LoginAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      merchantSelected: null,
      merchants: [],
      userSelected: null,
      users: [],
      prevTypeValue: '',
      merchantsLoading: 0,
      apiCount: 0
    }
    this.typingTimeout= null;
  }
  componentDidMount() {
    this.getMerchants();
  }
  getMerchants = (value) => {
    const { dispatch } = this.props;
    const { apiCount, merchantsLoading } = this.state;
    const params = {
      search: value ? value : ''
    };
    const thisCount = apiCount;
    dispatch(spinner(true));
    this.setState({
      merchantsLoading: merchantsLoading+1,
      apiCount: apiCount+1
    });
    LoginApi.getMerchantParents(params).then(({ data }) => {
      dispatch(spinner(false));
      const result = data.data;
      if (thisCount === this.state.apiCount-1) {
        this.setState({
          merchants:result.map(m => {
            return {
              label: m.nickname,
              value: m.id
            }
          }),
          merchantsLoading: this.state.merchantsLoading-1
        });
      } else {
        this.setState({
          merchantsLoading: this.state.merchantsLoading-1
        });
      }
    }).catch(err => {
      if (err.status === 403) {
        alert('User is not Admin');
        this.props.history.push('/dashboard');
      }
      console.error(err);
    });
  }
  getUsers = (id) => {
    const { dispatch } = this.props;
    dispatch(spinner(true));
    LoginApi.getUsers(id).then(({ data }) => {
      dispatch(spinner(false));
      const result = data.data;
      this.setState({
        users: result.map(u => {
          return {
            label: `${u.email} - ${u.name}`,
            value: u
          }
        })
      });
    }).catch(err => {
      if (err.status === 403) {
        alert('User is not Admin');
        this.props.history.push('/dashboard');
      }
      console.error(err);
    });
  }
  searchMerchants = (value) => {
    if (value.length > 0) {
      clearTimeout(this.typingTimeout);
      this.typingTimeout = setTimeout(() => {
        const { prevTypeValue } = this.state;
        if (prevTypeValue !== value) {
          this.getMerchants(value);
        }
        this.setState({
          prevTypeValue: value
        });
      }, 500);
    }
  }
  changeMerchant = (merchant) => {
    const action = () => {
      this.setState({
        merchantSelected: merchant,
        userSelected: null
      });
      this.changeMerchantChildren(merchant);
    }
    if (merchant) {
      action();
    }
  }
  changeMerchantChildren = (merchant) => {
    this.getUsers(merchant.value);
  }
  changeUser = (user) => {
    const { userSelected } = this.state;
    const action = () => {
      this.setState({
        userSelected: user
      });
    }
    if ((!userSelected) || (userSelected && userSelected.value !== user.value)) {
      action();
    }
  }
  login = (e) => {
    e.preventDefault();
    const { merchantSelected, userSelected } = this.state;
    if (userSelected === null || merchantSelected === null) {
      alert('Fields can not be empty');
    } else {
      const action = this.props.dispatch;
      action(spinner(true));
      LoginApi.adminAuthenticate(userSelected.value.email).then(({ data }) => {
        action(spinner(false));
        let result = data.data;
        const activeMerchant = JSON.stringify(result.activeMerchant);
        if (result.id) {
          localStorage.setItem('userid', result.id);
          localStorage.setItem('contactName', result.contactName);
          localStorage.setItem('username', result.username);
          localStorage.setItem('email', result.email);
          localStorage.setItem('phoneNumber', result.phoneNumber);
          localStorage.setItem('title', result.title);
          localStorage.setItem('isLoggedIn', true);
          localStorage.setItem('authKey', result.jwt);
          localStorage.setItem('activeMerchant', activeMerchant);
          localStorage.setItem('isFoodbamActive', result.isFoodbamActive);
          localStorage.setItem('status', result.status);
          localStorage.setItem('showInsideTrackModal', false);
          setToken(result.jwt);
          // Segment User Identification
          Utils.identifySegment();
          // Fullstory Identification
          this.identifyFullStory(result.activeMerchant,result.email);
          if (result.status === 1) {
            // status === onboarding
            this.props.history.push('/onboarding/1');
          } else {
            this.props.history.push('/dashboard');
          }
        } else {
          alert(result.message);
        }
      }).catch(function (error) {
        if (error.status === 400) {
          const errorMessage = 'Incorrect username or password. Please make sure you clicked the verification link we sent';
          alert(errorMessage);
        }
        if (error.status === 403) {
          alert('User is not Admin');
          this.props.history.push('/dashboard');
        }
        action(spinner(false));
      });
    }
  }
  logout = (e) => {
    e.preventDefault();
    this.props.history.push('/logout');
  }
  identifyFullStory = (activeMerchant, email) => {
    if(window['_fs_namespace']){
        window[window['_fs_namespace']].identify(activeMerchant.id,{
            displayName : activeMerchant.nickname,
            email : email
        });
    }
  };
  render() {
    const { merchantSelected, merchants, userSelected, users, merchantsLoading } = this.state;
    return (
      <div className='container-login'>
        <LoginSidebar history={this.props.history}/>
        <div className='right-login'>
          <div className='header-login'>
            {isMobile ? <InputLabel text='Admin Login' title className='title' /> : null}
          </div>
          <form onSubmit={this.login}>
            <GridContainer className='login-admin p-0'>
              { isMobile ? null :
              <GridRow className='mb-5'>
                <GridCol>
                  <InputLabel text='Admin Login' title className='title' />
                </GridCol>
              </GridRow>
              }
              <GridRow>
                <GridCol >
                  <InputLabel text='Parents' />
                </GridCol>
              </GridRow>
              <GridRow>
                <GridCol className='mb-3'>
                  <DropDownListSelect name='merchants' onInputChange={this.searchMerchants}
                  noOptionsMessage={() => merchantsLoading ? 'Loading...' : 'No options'}
                  value={merchantSelected} onChange={this.changeMerchant}
                  options={ !merchantsLoading ? merchants : []} />
                </GridCol>
              </GridRow>
              <GridRow>
                <GridCol className='mt-2'>
                  <InputLabel text='Users' />
                </GridCol>
              </GridRow>
              <GridRow>
                <GridCol className='mb-4'>
                  <DropDownListSelect name='users' isDisabled={users.length === 0}
                  value={userSelected} onChange={this.changeUser}
                  options={users} />
                </GridCol>
              </GridRow>
              <GridRow>
                <GridCol className='mt-5'>
                  <Button type='submit' variant='white' text='Logout' onClick={this.logout} />
                </GridCol>
                <GridCol className='mt-5'>
                  <Button type='submit' variant='primary' text='Log in' onClick={this.login} />
                </GridCol>
              </GridRow>
            </GridContainer>
          </form>
          <GridRow>
            <GridCol>
              <div className='footer-login'>
              </div>
            </GridCol>
          </GridRow>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {};
}

export default connect(mapStateToProps)(LoginAdmin);
