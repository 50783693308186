import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import '../FoodBam/FoodBamAgreement.scss';
import GridContainer from '../../MasterComponents/Grid/GridContainer';
import GridRow from '../../MasterComponents/Grid/GridRow';
import GridCol from '../../MasterComponents/Grid/GridCol';
import Step from '../../MasterComponents/Step/Step';
import Dash from '../../MasterComponents/Dash/Dash';
import InputLabel from '../../MasterComponents/InputLabel/InputLabel';
import Input from '../../MasterComponents/Input/Input';
import Checkbox from '../../MasterComponents/Checkbox/Checkbox';
import Button from '../../MasterComponents/Button/Button';
import foodbamTerms from '../../assets/files/foodbam/terms.pdf';

class FoodBamAgreement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agreement: false,
      fullName: '',
      validFields: true
    };
  }

  checkboxHandler = () => {
    const { agreement } = this.state
    this.setState({
      agreement: !agreement
    });
  };

  changeHandler = (value, name, e) => {
    this.setState({
      [name]: value
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { agreement, fullName: signature } = this.state;
    const { fullName } = this.refs;

    if (!fullName.isValid() || !agreement) {
      this.setState({
        validFields: false
      });
      return;
    }

    this.props.nextStepHandler(signature);
  }

  render() {
    const { agreement, fullName, validFields } = this.state;
    return (
      <div className='foodbam-agreement'>
        <GridContainer isFluid={false}>
          <GridRow>
            <GridCol>
              <h1 className='text-center m-5'>START ORDERING ONLINE</h1>
            </GridCol>
          </GridRow>
          <GridRow>
            <GridCol className='steps mt-3 mb-5'>
              <Step status={'done'} text={1} />
              <Dash className='mx-5' />
              <Step status={'active'} text={2} />
              <Dash className='mx-5' />
              <Step status={'inactive'} text={3} />
            </GridCol>
          </GridRow>

          <GridRow className='line-form'>
            <GridCol className='form-group'>
              <iframe id='foodbamTerms' title='FoodBam Terms' src={foodbamTerms} width='100%' frameBorder='0' scrolling='no'></iframe>
            </GridCol>
          </GridRow>

          <GridRow className='line-form'>
            <GridCol className='form-group'>
              <Checkbox className={!agreement && !validFields ? 'invalid' : ''} name='agreement' value={agreement} text='I agree to terms of service' onChange={this.checkboxHandler} />{agreement}
            </GridCol>
          </GridRow>

          <GridRow className='line-form'>
            <GridCol className='form-group'>
              <InputLabel text='Your Full Name *'/>
              <Input type='text' ref='fullName' name='fullName' value={fullName} onChange={this.changeHandler} validate min={1} max={100} />
            </GridCol>
            <GridCol className='form-group'>
              <InputLabel text='Signed by *'/><br/>
              <span className='signup-signed'>{fullName}</span>
            </GridCol>
          </GridRow>

          <GridRow className='line-form mt-3'>
            <GridCol className='form-group'>
              <Button type='button' variant={'blue'} size='' text='NEXT' onClick={this.handleSubmit} />
            </GridCol>
          </GridRow>

          <GridRow className='line-form'>
            <GridCol className='col form-group d-flex align-items-center'>
              <InputLabel className='text-red text-size-14 mb-3'>{ !validFields ? 'Required fields missing' : '' }</InputLabel>
            </GridCol>
          </GridRow>

        </GridContainer>
      </div>
    );
  }

}

FoodBamAgreement.propTypes = {
  nextStepHandler: PropTypes.func
};

const mapStateToProps = state => {
  return {};
}

export default connect(mapStateToProps)(FoodBamAgreement);
