import React from 'react';
import { isMobile } from 'react-device-detect';
import DropDownListSelect from '../../../../MasterComponents/DropDownList/DropDownListSelect';
import GridCol from '../../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../../MasterComponents/Grid/GridRow';
import InputLabel from '../../../../MasterComponents/InputLabel/InputLabel';

const DistributorStepIndexContent = ({locationsMap, AllLocationsCheckbox, dropdown}) => {
  return (
    <GridRow className='pb-3'>
      <GridCol>
        <GridRow>
          <GridCol size={isMobile ? 12 : 6}>
            <InputLabel text='Purchase History Submission Type *' />
            <DropDownListSelect isDisabled value={dropdown} />
          </GridCol>
        </GridRow>
        <AllLocationsCheckbox />
        {locationsMap}
      </GridCol>
    </GridRow>
  );
};

export default DistributorStepIndexContent;