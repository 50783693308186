import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { showModal } from '../../../MasterComponents/Modals/ModalsActions';
import FaqModal from '../../Faq/Modals/FaqModal';
import NewCardModal from '../../Faq/Modals/NewCardModal';

const useNew = (props) => {
  const { history, dispatch } = props;
  const newAll = useSelector(({homeMain}) => homeMain.newAll);

  const goToNew = () => {
    if (isMobile) {
      dispatch(showModal(FaqModal, 'idFaqModal', { header: 'FAQ & Resources' }));
    } else {
      history.push('/dashboard/faq');
    }
  }

  const onClickMore = (resource) => {
    if (resource.type === 'video') {
      dispatch(showModal(NewCardModal, 'idNewCardModal', { resource: resource }));
    } else {
      window.open(resource.url);
    }
  }

  return {
    goToNew,
    onClickMore,
    newAll
  }
}



export default useNew;
