import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import GridRow from '../../MasterComponents/Grid/GridRow';
import GridCol from '../../MasterComponents/Grid/GridCol';
import InputLabel from '../../MasterComponents/InputLabel/InputLabel';
import IconDelete from '../../MasterComponents/Icons/IconDelete';
import IconEdit from '../../MasterComponents/Icons/IconEdit';
import config from '../../config/config';
import Button from '../../MasterComponents/Button/Button';
import { showModal } from '../../MasterComponents/Modals/ModalsActions';
import InviteUserModal from './Modals/InviteUserModal';
import DeleteUserModal from './Modals/DeleteUserModal';
import ConfirmationUserModal from './Modals/ConfirmationUserModal';

class DashboardSettingsUsers extends Component {
    constructor(props) {
      super(props);    
      this.state = {
        users: []
      }
    }

    handleInviteUser = () => {        
        const { dispatch, refresh} = this.props;
        dispatch(showModal(InviteUserModal, 'inviteUserModal', {header:'Invite User', type: 'add',refresh: refresh}));
    }

    handleEditUser = (user) => {
        const { dispatch, refresh} = this.props;
        dispatch(showModal(InviteUserModal, 'inviteUserModal', {header:'Edit User', type: 'edit',refresh: refresh, user: user}));
    }
  
    handleDeleteUser = (user) => {        
        const { dispatch, refresh} = this.props;
        const currentUser = localStorage.getItem('email');
        if (currentUser === user.email){
            dispatch(showModal(ConfirmationUserModal, 'confirmationUserModal', {header:'Delete User', message: 'You can\'t delete your own user', user: user, refresh: refresh, icon: false}));
        }else{
            dispatch(showModal(DeleteUserModal, 'deleteUserModal', {header:'Delete User', message: 'Do you want to delete this account?', user: user, refresh: refresh}));
        }        
    }        

    render() {
        const usersMap = this.props.users.map((u, i) => {
            if(isMobile){
                return(
                    <Fragment key={i}>
                        <GridRow className='border-bottom mt-3'>
                            <GridCol className='col-9 ml-3 mr-0'>
                                <GridRow className='pt-2'><span>{u.contactName}</span></GridRow>
                                <GridRow className='pt-2'><span>{u.email}</span></GridRow>
                                <GridRow className='pt-2 pb-3'><span>{u.title}</span></GridRow>
                            </GridCol>
                            <GridCol className='col-2 mx-0 px-0 d-flex justify-content-end'>
                                <div className='pr-4' onClick={this.handleEditUser.bind(this, u)}>
                                    <IconEdit color={config.colors.rbGrey}/>
                                </div>
                                <div className='' onClick={this.handleDeleteUser.bind(this, u)}>
                                    <IconDelete color={config.colors.rbGrey}/>
                                </div>
                            </GridCol>                    
                        </GridRow>
                    </Fragment>
                )
            }else{
                return(
                    <Fragment key={i}>
                        <GridRow className='line-form'>
                            <GridCol className='col-4 form-group'>
                                <span>{u.contactName}</span>
                            </GridCol>
                            <GridCol className='col-5 form-group'>
                                <span>{u.email}</span>
                            </GridCol>
                            <GridCol className='col-2 form-group'>
                                <span>{u.title}</span>
                            </GridCol>
                            <GridCol className='col-1 form-group text-right d-flex justify-content-end'>                        
                                <div className='clickable pr-3' onClick={this.handleEditUser.bind(this, u)}>
                                    <IconEdit color={config.colors.rbGrey}/>
                                </div>
                                <div className='clickable' onClick={this.handleDeleteUser.bind(this, u)}>
                                    <IconDelete color={config.colors.rbGrey}/>
                                </div>
                            </GridCol>                    
                        </GridRow>
                    </Fragment>
                )
            }
            
        });

        return (
            <Fragment>
                {isMobile ? null :
                    <GridRow className='line-form mt-3'>
                        <GridCol className='col-4 form-group'>
                            <InputLabel text='Name'/>
                        </GridCol>
                        <GridCol className='col-5 form-group'>
                            <InputLabel text='Email'/>
                        </GridCol>
                        <GridCol className='col-2 form-group'>
                            <InputLabel text='Role'/>
                        </GridCol>
                        <GridCol className='col-1 form-group'>
                            
                        </GridCol>
                    </GridRow>
                }
                {usersMap}
                <GridRow className='line-form mt-3'>
                    <GridCol className={(isMobile ?'':'col-4') + ' form-group'}>
                        <Button type='button' variant='primary' size='' text='Invite User'  onClick={this.handleInviteUser}/>
                    </GridCol>
                </GridRow>
            </Fragment>
          );
    }
}

const mapStateToProps = state => {
    return {
      section: state.dashboard.section,
      users: state.dashboard.users,
      locations: state.dashboard.merchants
    };
  }
  
export default connect(mapStateToProps)(DashboardSettingsUsers);
