import React, { Component } from 'react';
import { connect } from 'react-redux';
import GridRow from '../../MasterComponents/Grid/GridRow';
import GridCol from '../../MasterComponents/Grid/GridCol';
import Button from '../../MasterComponents/Button/Button';
import { getPrimeBottomBackground, getPrimeBottomButtonClass, getPrimeBottomButtonVariant, getPrimeBottomTextClass, getPrimeBottomTitleClass } from '../../config/Company/CompanyHelper';

class PrimeProgramsBottom extends Component {

    goToMorePrograms = () => {
        window.open('https://www.diningalliance.com/programs/', '_blank');
    }

    render() {
        const { companyId } = this.props;
        const isCompanyCustom = (companyId === 3 || companyId === 13 || companyId === 14) ? true : false;
        return (
            <div>
                <GridRow className={'content-more-programs text-center'} style={{ background: getPrimeBottomBackground(companyId) }} align='center'>
                    <GridCol className='text-center'>
                        <GridCol className={getPrimeBottomTitleClass(companyId)}>
                            <span className='line-height-38 font-weight-bolder'>"Looking for even more savings opportunities?"</span>
                        </GridCol>
                        { isCompanyCustom ?
                                <GridCol className={'mt-4 ' + getPrimeBottomTextClass(companyId)}>
                                    <span className='text-size-24 line-height-32'>Please reach out to your client manager for more info</span><br/>
                                </GridCol>
                            :
                            <>
                                <GridCol className={'mt-5 ' + getPrimeBottomTextClass(companyId)}>
                                    <span className='text-size-24 line-height-32'>We have more Beyond Broadline available!</span><br/>
                                    <span className='text-size-24 line-height-32'>Click below to learn more about our full suite of programs.</span>
                                </GridCol>
                                <GridCol className='mt-4'>
                                    <Button type='submit' className={'btn-more-pograms text-size-14 line-height-17 ' + getPrimeBottomButtonClass(companyId)}
                                        variant={getPrimeBottomButtonVariant(companyId)} text='VIEW MORE PROGRAMS' onClick={this.goToMorePrograms}  />
                                </GridCol>
                            </>
                        }
                    </GridCol>
                </GridRow>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        companyId: state.company.id
    };
}

export default connect(mapStateToProps)(PrimeProgramsBottom);