import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { Row, Col, Button, Container } from 'reactstrap';
import { IoIosArrowDropleft } from "react-icons/io";
import { claimCashSetStep } from '../Reducer/ClaimCashAction';
import { getRandomColor } from '../../../MasterComponents/Helper/getHelper';
import { destroyModal } from '../../../MasterComponents/Modals/ModalsActions';
import DropDownListCheckbox from '../../../MasterComponents/DropDownListCheckbox/DropDownListCheckbox';
import ClaimCashGraph from './ClaimCashGraph';
import config from '../../../config/config';

 class ClaimCashDetailContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location : {
        isCheckedAllLoc: false,
        dataAllLoc: [],
        dataSelectedLoc: [],
        dataTopLoc: [],
        optAcumLoc: 5,
        optInitLoc: 5
      },
      manufacturer : {
        isCheckedAllMan: false,
        dataAllMan: [],
        dataSelectedMan: [],
        dataTopMan: [],
        optAcumMan: 5,
        optInitMan: 5
      },
      amountSplit: ''
    };
  }

  componentDidMount = () => {
    const { manufacturer, location } = this.state;
    const { claimCash } = this.props;
    const { dataDetail, currentPayment } = claimCash;
    //Locations
    const dataLoc = dataDetail.locations.map((l, i) => {
      if(i < location.optInitLoc) {
        return {
          ...l,
          isChecked: true,
          y: l.amount,
          color: getRandomColor(i)
        };
      }
      return {
        ...l,
        isChecked: false,
        y: l.amount,
        color: getRandomColor(i)
      }
    });
    const dataTopL = dataLoc.slice(0, location.optInitLoc);
    //Manufacturers
    const dataMan = dataDetail.manufacturers.map((m, i) => {
      if(i < manufacturer.optInitMan) {
        return {
          ...m,
          isChecked: true,
          y: m.amount,
          color: getRandomColor(i)
        };
      }
      return {
        ...m,
        isChecked: false,
        y: m.amount,
        color: getRandomColor(i)
      }
    });
    const dataTopM = dataMan.slice(0, manufacturer.optInitMan);
    this.setState({
      location: {
        ...location,
        dataAllLoc: dataLoc,
        dataTopLoc: dataTopL,
        dataSelectedLoc: dataTopL,
      },
      manufacturer: {
        ...manufacturer,
        dataAllMan: dataMan,
        dataTopMan: dataTopM,
        dataSelectedMan: dataTopM
      },
      amountSplit: currentPayment.amount ? (currentPayment.amount).split('.')[0] : ''
    });
  }

  goToBack = () => {
    const { history } = this.props;
    history.push('/dashboard/claim');
  }

  download = () => {
    const { claimCash } = this.props;
    const { dataDetail } = claimCash;
    if(dataDetail.fileUrl){
      window.open(dataDetail.fileUrl, '_blank');
    }
  }

  goToStep3 = () => {
    const { dispatch, history } = this.props;
    history.push('/dashboard/claim');
    dispatch(claimCashSetStep(3));
    if(isMobile) {
      dispatch(destroyModal('idClaimCashDetailModal'));
    }
  }

  //--------- Locations --------------
  toggleAllLocation = () => {
    const { location } = this.state;
    const { isCheckedAllLoc, dataAllLoc, optInitLoc } = location;
    let dataLoc;
    if(!isCheckedAllLoc) {
       dataLoc = dataAllLoc.map(l => {
        return {
          ...l,
          isChecked: true
        }
      });
      this.setState({
        location: {
          ...location,
          isCheckedAllLoc: !location.isCheckedAllLoc,
          dataAllLoc: dataLoc,
          dataTopLoc: dataLoc,
          dataSelectedLoc: dataLoc
        }
      });
    } else {
      dataLoc = dataAllLoc.map((l, i) => {
        if(i < optInitLoc) {
          return {
            ...l,
            isChecked : true
          };
        }
        return {
          ...l,
          isChecked: false
        }
      });
      const dataTop = dataLoc.slice(0, optInitLoc);
      this.setState({
        location: {
          ...location,
          isCheckedAllLoc: !location.isCheckedAllLoc,
          dataAllLoc: dataLoc,
          dataTopLoc: dataTop,
          dataSelectedLoc: dataTop,
          optAcumLoc: 5,
          optInitLoc: 5
        }
      });
    }
  };

  toggleOptionLocation = (opt) => {
    const { location } = this.state;
    const { dataAllLoc } = location;
    const data = dataAllLoc.map(l => {
      if(l.id !== opt.id) {
        return l;
      }
      return {
        ...l,
        isChecked: !l.isChecked
      }
    });
    const dataSel = data.filter(m => m.isChecked === true);
    this.setState({
      location: {
        ...location,
        dataAllLoc: data,
        dataSelectedLoc: dataSel
      }
    });
  }
  
  triggerSearchLocation = () => {
    const { location } = this.state;
    const { dataSelectedLoc } = location;
    this.setState({
      location: {
        ...location,
        dataTopLoc: dataSelectedLoc
      }
    });
  }

  showMoreLocation = () => {
    const { location } = this.state;
    const { dataAllLoc, optAcumLoc, optInitLoc } = location;
    const optAcumLocValue = optAcumLoc + optInitLoc;
    const dataLoc = dataAllLoc.map((l, i) => {
      if(i < optAcumLocValue) {
        return {
          ...l,
          isChecked : true
        };
      }
      return {
        ...l,
        isChecked : false,
      }
    });
    const dataTop = dataLoc.slice(0, optAcumLocValue);
    this.setState({
      location: {
        ...location,
        dataAllLoc: dataLoc,
        dataTopLoc: dataTop,
        dataSelectedLoc: dataTop,
        optAcumLoc: optAcumLocValue
      }
    });
  }

  showLessLocation = () => {
    const { location } = this.state;
    const { dataAllLoc, optAcumLoc, optInitLoc } = location;
    const optAcumLocValue = optAcumLoc - optInitLoc;
    const dataLoc = dataAllLoc.map((l, i) => {
      if(i < optAcumLocValue) {
        return {
          ...l,
          isChecked: true
        };
      }
      return {
        ...l,
        isChecked: false,
      }
    });
    const dataTop = dataLoc.slice(0, optAcumLocValue);
    this.setState({
      location: {
        ...location,
        dataAllLoc: dataLoc,
        dataTopLoc: dataTop,
        dataSelectedLoc: dataTop,
        optAcumLoc: optAcumLocValue
      }
    });
  }

  //--------- Manufacturers --------------
  toggleAllManufacturer = () => {
    const { manufacturer } = this.state;
    const { isCheckedAllMan, dataAllMan, optInitMan } = manufacturer;
    let dataMan;
    if(!isCheckedAllMan) {
      dataMan = dataAllMan.map(m => {
        return {
          ...m,
          isChecked: true
        }
      });
      this.setState({
        manufacturer: {
          ...manufacturer,
          isCheckedAllMan: !manufacturer.isCheckedAllMan,
          dataAllMan: dataMan,
          dataTopMan: dataMan,
          dataSelectedMan: dataMan
        }
      });
    } else {
      dataMan = dataAllMan.map((m, i) => {
        if(i < optInitMan) {
          return {
            ...m,
            isChecked: true
          };
        }
        return {
          ...m,
          isChecked: false
        }
      });
      const dataTop = dataMan.slice(0, optInitMan);
      this.setState({
        manufacturer: {
          ...manufacturer,
          isCheckedAllMan: !manufacturer.isCheckedAllMan,
          dataAllMan: dataMan,
          dataTopMan: dataTop,
          dataSelectedMan: dataTop,
          optAcumMan: 5,
          optInitMan: 5
        }
      });
    }
  };

  toggleOptionManufacturer = (opt) => {
    const { manufacturer } = this.state;
    const { dataAllMan } = manufacturer;
    const data = dataAllMan.map(m => {
      if(m.id !== opt.id) {
        return m;
      }
      return {
        ...m,
        isChecked: !m.isChecked
      }
    });
    const dataSel = data.filter(m => m.isChecked === true);
    this.setState({
      manufacturer: {
        ...manufacturer,
        dataAllMan: data,
        dataSelectedMan: dataSel
      }
    });
  }

  triggerSearchManufacturer = () => {
    const { manufacturer } = this.state;
    const { dataSelectedMan } = manufacturer;
    this.setState({
      manufacturer: {
        ...manufacturer,
        dataTopMan: dataSelectedMan
      }
    })
  }

  showMoreManufacturer = () => {
    const { manufacturer } = this.state;
    const { dataAllMan, optAcumMan, optInitMan } = manufacturer;
    const optAcumManValue = optAcumMan + optInitMan;
    const dataMan = dataAllMan.map((m, i) => {
      if(i < optAcumManValue) {
        return {
          ...m,
          isChecked : true
        };
      }
      return {
        ...m,
        isChecked : false,
      }
    });
    const dataTop = dataMan.slice(0, optAcumManValue);
    this.setState({
      manufacturer: {
        ...manufacturer,
        dataAllMan: dataMan,
        dataTopMan: dataTop,
        dataSelectedMan: dataTop,
        optAcumMan: optAcumManValue
      }
    });
  }

  showLessManufacturer = () => {
    const { manufacturer } = this.state;
    const { dataAllMan, optAcumMan, optInitMan } = manufacturer;
    const optAcumManValue = optAcumMan - optInitMan;
    const dataMan = dataAllMan.map((m, i) => {
      if(i < optAcumManValue) {
        return {
          ...m,
          isChecked: true
        };
      }
      return {
        ...m,
        isChecked: false,
      }
    });
    const dataTop = dataMan.slice(0, optAcumManValue);
    this.setState({
      manufacturer: {
        ...manufacturer,
        dataAllMan: dataMan,
        dataTopMan: dataTop,
        dataSelectedMan: dataTop,
        optAcumMan: optAcumManValue
      }
    });
  }

  render() {
    const { location, manufacturer, amountSplit  } = this.state;
    const { dataAllLoc, dataSelectedLoc, dataTopLoc, optInitLoc, isCheckedAllLoc } = location;
    const { dataAllMan, dataSelectedMan, dataTopMan, optInitMan, isCheckedAllMan } = manufacturer;
    const { claimCash } = this.props;
    const { currentPayment } = claimCash;

    const propsLocations = {
      dataTop: dataTopLoc,
      dataAll: dataAllLoc,
      options: dataAllLoc,
      optionSelected: dataSelectedLoc,
      optInit: optInitLoc,
      isCheckedAll: isCheckedAllLoc,
      showMore: this.showMoreLocation,
      showLess: this.showLessLocation,
      triggerSearch: this.triggerSearchLocation,
      toggleOption: this.toggleOptionLocation,
      toggleAll: this.toggleAllLocation,
      hasEdit: false,
      hasDelete: false,
      hasAll: true,
      defaultTextAll: 'All Locations'
    };

    const propsManufacturers = {
      dataTop: dataTopMan,
      dataAll: dataAllMan,
      options: dataAllMan,
      optionSelected: dataSelectedMan,
      optInit: optInitMan,
      isCheckedAll: isCheckedAllMan,
      showMore: this.showMoreManufacturer,
      showLess: this.showLessManufacturer,
      triggerSearch: this.triggerSearchManufacturer,
      toggleOption: this.toggleOptionManufacturer,
      toggleAll: this.toggleAllManufacturer,
      hasEdit: false,
      hasDelete: false,
      hasAll: true,
      defaultTextAll: 'All Manufacturers'
    }

    if(isMobile) {
        return (
          <Container fluid className='bg-white'>
            <Row>
              <Col xs={12} className='py-4 text-center bg-white'>
                <Row>
                  <Col xs={12} className='px-1 pt-2'>
                    <Col xs={12} className='py-2' style={{color:config.colors.rbGreytext}}>
                      Payment Amount
                    </Col>
                    <Col xs={12} className='py-2 text-size-48 line-height-58'>
                      {amountSplit}
                    </Col>
                  </Col>
                  <Col xs={12}>
                    Details do not include the fee for the paper check
                  </Col>
                </Row>
              </Col>
              <Col xs={12} className='py-4 px-5 d-flex justify-content-center bg-white'>
                <Row>
                  <Col xs={6}>
                    <Button outline block className='py-2 shadow-none btn-rb-white' size='sm' style={{backgroundColor: isMobile ? config.colors.rbWhite : config.colors.rbGreyHome}} onClick={this.download} >DOWNLOAD</Button>
                  </Col>
                  { (currentPayment.details_only === 0 && currentPayment.requested_date === null) &&
                    <Col xs={6}>
                      <Button active block className='py-2 shadow-none' size='sm' color='primary'onClick={this.goToStep3} >CLAIM CASH</Button>
                    </Col>
                  }
                </Row>
              </Col>
          </Row>
          <Row className='pb-5'>
            <Col xs={12} className='border-right px-0'>
              <Col xs={12} className='text-size-24 text-center py-3 bg-grey-home'>
                Locations
              </Col>
              <Col xs={12} className='px-3 pb-4 bg-grey-home'>
                <DropDownListCheckbox {...propsLocations} />
              </Col>
              <Col xs={12}>
                <ClaimCashGraph {...propsLocations} />
              </Col>
            </Col>
            <Col xs={12} className='px-0'>
              <Col className='text-size-24 text-center py-2 bg-grey-home'>
                Manufacturers
              </Col>
              <Col xs={12} className='px-3 pb-4 bg-grey-home'>
                <DropDownListCheckbox  {...propsManufacturers} />
              </Col>
              <Col xs={12}>
                <ClaimCashGraph {...propsManufacturers} />
              </Col>
            </Col>
          </Row>
        </Container>
      )
    }
    return (
      <Container fluid className='bg-grey-home'>
        <Row>
          <Col xs={6} className='py-4'>
            <Row>
              <Col xs={1} className='clickable' onClick={this.goToBack}>
                <IoIosArrowDropleft size={40} color={config.colors.rbGreytext} />
              </Col>
              <Col xs={11} className='px-1 pt-2'>
                <Col className='text-size-14 line-height-17'>
                  {currentPayment.timeframe}
                </Col>
                <Col xs={12} className='py-2' style={{color:config.colors.rbGreytext}}>
                  Payment Amount
                </Col>
                <Col xs={12} className='py-2 text-size-48 line-height-58'>
                  {amountSplit}
                </Col>
                <Col xs={12}>
                  Details do not include the fee for the paper check
                </Col>
              </Col>
            </Row>
          </Col>
          <Col xs={6} className='py-4 px-5 d-flex justify-content-end '>
            <Row>
              <Col xs={6}>
                <Button outline block className='py-2 shadow-none btn-rb-white' size='sm' style={{backgroundColor: config.colors.rbGreyHome}} onClick={this.download} >DOWNLOAD</Button>
              </Col>
              { (currentPayment.details_only === 0 && currentPayment.requested_date === null) &&
                <Col xs={6}>
                  <Button active block className='py-2 shadow-none' size='sm' color='primary'onClick={this.goToStep3} >CLAIM CASH</Button>
                </Col>
              }
            </Row>
          </Col>
        </Row>
        <Row className='border py-2'>
          <Col>
            <Row>
              <Col className='text-size-18 line-height-22 pt-2'>
                Locations
              </Col>
              <Col className='px-4'>
                <DropDownListCheckbox {...propsLocations} />
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col className='text-size-18 line-height-22 pt-2'>
                Manufacturers
              </Col>
              <Col className='px-4'>
                <DropDownListCheckbox  {...propsManufacturers} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className='pb-5'>
          <Col xs={6} className='border-right'>
            <ClaimCashGraph {...propsLocations} />
          </Col>
          <Col xs={6}>
            <ClaimCashGraph {...propsManufacturers} />
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return {
    claimCash: state.claimCash
  };
}

export default connect(mapStateToProps)(ClaimCashDetailContent);
