import React, { Component } from 'react'
import { connect } from 'react-redux';
import Modal from '../../../MasterComponents/Modals/Modal';
import ModalHeader from '../../../MasterComponents/Modals/ModalHeader';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../MasterComponents/Grid/GridCol';

class LoadingModal extends Component {
  render() {
    return (
      <Modal id='loadingModal' centered>
        <ModalHeader closeButton={false} />
        <ModalBody>
          <GridRow>
            <GridCol className='text-center'>
              Verification in progress, this may take a minute
            </GridCol>
          </GridRow>
        </ModalBody>
      </Modal>
    );
  }
};

const mapStateToProps = state => {
  return {};
}

export default connect(mapStateToProps)(LoadingModal);