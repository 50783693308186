import React, { useEffect, useState } from 'react';
import Drift from 'react-driftjs';

const DriftController = () => {
  const [isTesting, setIsTesting] = useState(false);
  const initTesting = window.location.hash === '#test' || window.location.hash === '#test/';
  const hostnameBlacklist = ['myrbgportal.com', 'staging.myrbgportal.com', 'dev.myrbgportal.com', 'myngcra.com', 'beportal.restacct.com'];
  const pathnameBlacklist = ['/cashback-estimator', '/dashboard/claim', '/simple-register', '/terms-and-conditions', '/dashboard/foodcost/orderly'];

  const isDevOrStag = window.location.hostname.search('dev') === 0 || window.location.hostname.search('staging') === 0;
  const isHostNameBlackListed = hostnameBlacklist.indexOf(window.location.hostname.toLowerCase()) !== -1;
  const isPathNameBlackListed = pathnameBlacklist.indexOf(window.location.pathname.toLowerCase()) !== -1;

  useEffect(() => {
    setIsTesting(window.location.hash === '#test' || window.location.hash === '#test/');
  }, []);

  useEffect(() => {
    if (window.drift && window.drift.api) {
      if (isPathNameBlackListed || isTesting) {
        window.drift.api.widget.hide();
      } else {
        window.drift.api.widget.show();
      }
    }
  }, [isPathNameBlackListed, isTesting]);
    
  if (!isDevOrStag && !isHostNameBlackListed && !isPathNameBlackListed && !isTesting && !initTesting) {
    return (
      <Drift appId='sswc9wpasm84' />
    );
  }

  return null;
};

export default DriftController;
