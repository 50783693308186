import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser'; 
import { connect } from 'react-redux';
import { getBgImageLeft } from '../../config/Company/CompanyHelper';
import Bullet from '../../MasterComponents/Bullet/Bullet';
import LogoMainCompany from '../../MasterComponents/Logos/LogoMainCompany';

class LoginSidebar extends Component {
    goTo = (e) => {
        e.preventDefault();
        this.props.history.push('/');
    };
    render() {
        const { loginText, loginBullets, companyId } = this.props;
        const bgImageLeft = getBgImageLeft(companyId);
        const BulletsMap = loginBullets.map( (b, i) => 
            <Bullet key={i} text={b} />
        );
        const LoginTextDiv = loginText && <div>{ ReactHtmlParser(loginText) }</div>;
        return (
            <div className='left-login' style={{backgroundImage: `url(${bgImageLeft})`}}>
                <div className='logo'>
                    <a href='/' onClick={this.goTo}><LogoMainCompany style={{maxWidth: '250px', maxHeight: '150px'}} /></a>                    
                </div>
                <div className='bullets'>
                    { LoginTextDiv }
                    <br />
                    { BulletsMap }
                </div>                
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        companyId: state.company.id,
        loginText: state.company.text.loginText,
        loginBullets: state.company.text.loginBullets
    };
}

export default connect(mapStateToProps)(LoginSidebar);