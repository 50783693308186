import { primeSetData, primeSetDataUpdated } from './Reducer/PrimeAction';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import PrimeProgramsApi from './PrimeProgramsApi';

const getDistributorBP = function(companyId = 1, hasAds = true, hasAuth = true) {
  const { dispatch } = this.props;
  let arrayDis = [];
  PrimeProgramsApi.getDistributorBPAuth(companyId).then((res)=>{
    dispatch(primeSetData(res.data.data));
    //sliced by 15 + ad = 16 cards (Prime programs website)
    let result = hasAds ? res.data.data.slice(0, 15) : res.data.data;
    let programDataArr = hasAuth ? programData : programDataUnAuth;
    if(hasAds) {
      result = result.slice(0, 15);
      result.splice(2, 0, {
        card: programDataDefault[0]
      });
    }
    arrayDis = result.map((d) => {
      return {
        ...d,
        card: programDataArr.find(p => {
          if(p.platformId === d.platformId) {
            return {
              ...p,
              cardMain: p.cardMain,
              cardHover: p.cardHover
            }
          }
          return false;
        })
      }
    });
    dispatch(primeSetDataUpdated(arrayDis));
  });
}

export const programDataDefault = [
  {
    name: '',
    platformId: '',
    cardMain: {
      styleBody: {
        width: '60%'
      }
    },
    cardHover: {
      styleLogo: {
        position: 'relative',
        top: isMobile ? '20px': '-120px',
        width: isMobileOnly ? '60%' : (isTablet ? '40%': '200px'),
        maxHeight: '80px'
      },
      styleTitle: {
        position: 'absolute',
        top: isMobileOnly ? '33%' : (isTablet ? '40%': '50px')
      },
      styleBody: {
        position: 'relative',
        top: isMobile ? '50px': '60px'
      },
      styleButton: {
        position: 'relative',
        top: '40%'
      }
    }
  }
];

export const programData = [
  {
    name: 'Direct TV',
    platformId: 'DI-1003953',
    cardMain: {
      styleBody: {
        width: '80%'
      }
    },
    cardHover: {
      styleLogo: {
        position: 'relative',
        top: isMobile ? '0%': '-120px',
        width: isMobileOnly ? '60%' : (isTablet ? '40%': '200px')
      },
      styleTitle: {
        position: 'absolute',
        top: isMobileOnly ? '33%' : (isTablet ? '45%': '50px')
      },
      styleBody: {
        position: 'relative',
        top: isMobile ? '20px': '120px'
      },
      styleButton: {
        position: 'relative',
        top: '20%'
      }
    }
  },
  {
    name: 'Chef Works',
    platformId: 'DI-1004073',
    cardMain: {
      styleBody: {
        width: '60%'
      }
    },
    cardHover: {
      styleLogo: {
        position: 'relative',
        top: isMobile ? '20px': '-120px',
        width: isMobileOnly ? '60%' : (isTablet ? '40%': '200px')
      },
      styleTitle: {
        position: 'absolute',
        top: isMobileOnly ? '33%' : (isTablet ? '40%': '50px')
      },
      styleBody: {
        position: 'relative',
        top: isMobile ? '80px': '120px'
      },
      styleButton: {
        position: 'relative',
        top: '40%'
      }
    }
  },
  {
    name: 'Sprint',
    platformId: 'DI-1004017',
    cardMain: {
      styleBody: {
        width: ''
      }
    },
    cardHover : {
    },
    styleTitle: {
      position: '',
      top: ''
    },
    styleBody: {
      position: '',
      top: ''
    },
    styleButton: {
      position: '',
      top: ''
    }
  },
  {
    name: 'Skechers',
    platformId: 'DI-1000284',
    cardMain: {
      styleBody: {
        width: '80%'
      }
    },
    cardHover: {
      styleLogo: {
        position: 'relative',
        top: isMobile ? '20px': '-120px',
        width: isMobileOnly ? '60%' : (isTablet ? '40%': '200px')
      },
      styleTitle: {
        position: 'absolute',
        top: isMobileOnly ? '33%' : (isTablet ? '35%': '50px')
      },
      styleBody: {
        position: 'relative',
        top: isMobile ? '80px': '120px'
      },
      styleButton: {
        position: 'relative',
        top: '50%'
      }
    }
  },
  {
    name: 'Staples',
    platformId: 'DI-1004113',
    cardMain: {
      styleBody: {
        width: '70%'
      }
    },
    cardHover: {
      styleLogo: {
        position: 'relative',
        top: isMobile ? '20px': '-120px',
        width: isMobileOnly ? '60%' : (isTablet ? '32%': '60%')
      },
      styleTitle: {
        position: 'absolute',
        top: isMobileOnly ? '40%' : (isTablet ? '40%': '50px')
      },
      styleBody: {
        position: 'relative',
        top: isMobile ? '60px': '120px'
      },
      styleButton: {
        position: 'relative',
        top: '35%'
      }
    }
  },
  {
    name: 'OfficeDepot',
    platformId: 'DI-1000538',
    cardMain: {
      styleBody: {
        width: '40%'
      }
    },
    cardHover : {
      styleLogo: {
        position: 'relative',
        top: isMobile ? '20px': '-120px',
        width: isMobileOnly ? '30%' : (isTablet ? '20%': '130px')
      },
      styleTitle: {
        position: 'absolute',
        top: isMobileOnly ? '33%' : (isTablet ? '60%': '50px')
      },
      styleBody: {
        position: 'relative',
        top: isMobile ? '60px': '120px'
      },
      styleButton: {
        position: 'relative',
        top: '45%'
      }
    }
  },
  {
    name: 'FattMerchant',
    platformId: 'DI-1003898',
    cardMain: {
      styleBody: {
        width: '70%'
      }
    },
    cardHover: {
      styleLogo: {
        position: 'relative',
        top: isMobile ? '20px': '-120px',
        width: isMobileOnly ? '60%' : (isTablet ? '45%': '220px')
      },
      styleTitle: {
        position: 'absolute',
        top: isMobileOnly ? '33%' : (isTablet ? '40%': '50px')
      },
      styleBody: {
        position: 'relative',
        top: isMobile ? '60px': '120px'
      },
      styleButton: {
        position: 'relative',
        top: '45%'
      }
    }
  },
  {
    name: 'BenefitHub',
    platformId: 'DI-1001638',
    cardMain: {
      styleBody: {
        width: '60%'
      }
    },
    cardHover : {
      styleLogo: {
        position: 'relative',
        top: isMobile ? '20px': '-120px',
        width: isMobileOnly ? '50%' : (isTablet ? '35%': '180px')
      },
      styleTitle: {
        position: 'absolute',
        top:'70px'
      },
      styleBody: {
        position: 'relative',
        top: '60px'
      },
      styleButton: {
        position: 'relative',
        top: isMobileOnly ? '35%' : '45%'
      }
    }
  },
  {
    name: 'Aramark',
    platformId: 'DI-1002908',
    cardMain: {
      styleBody: {
        width: '70%'
      }
    },
    cardHover : {
      styleLogo: {
        position: 'relative',
        top: isMobile ? '20px': '-120px',
        width: isMobileOnly ? '50%' : (isTablet ? '45%': '50%')
      },
      styleTitle: {
        position: 'absolute',
        top:'70px'
      },
      styleBody: {
        position: 'relative',
        top: '60px'
      },
      styleButton: {
        position: 'relative',
        top: isMobileOnly ? '20%' : '32%'
      }
    }
  },
  {
    name: 'Cintas',
    platformId: 'DI-1006592',
    cardMain: {
      styleBody: {
        width: '45%'
      }
    },
    cardHover: {
      styleLogo: {
        position: 'relative',
        top: isMobile ? '20px': '-120px',
        width: isMobileOnly ? '40%' : (isTablet ? '30%': '130px')
      },
      styleTitle: {
        position: 'absolute',
        top:'70px'
      },
      styleBody: {
        position: 'relative',
        top: '60px'
      },
      styleButton: {
        position: 'relative',
        top: '40%'
      }
    }
  },
  {
    name: 'Airgas',
    platformId: 'DI-1006668',
    cardMain: {
      styleBody: {
        width: '35%'
      }
    },
    cardHover : {
      styleLogo: {
        position: 'relative',
        top: isMobile ? '20px': '-120px',
        width: isMobileOnly ? '35%' : (isTablet ? '30%': '130px')
      },
      styleTitle: {
        position: 'absolute',
        top:'70px'
      },
      styleBody: {
        position: 'relative',
        top: '60px'
      },
      styleButton: {
        position: 'relative',
        top: '40%'
      }
    }
  },
  {
    name: 'TouchBistro',
    platformId: 'DI-1015138',
    cardMain: {
      styleBody: {
        width: ''
      }
    },
    cardHover: {
      styleLogo: {
        position: 'relative',
        top: isMobile ? '20px': '-120px',
        width: isMobileOnly ? '60%' : (isTablet ? '55%': '60%')
      },
      styleTitle: {
        position: 'absolute',
        top:'70px'
      },
      styleBody: {
        position: 'relative',
        top: '60px'
      },
      styleButton: {
        position: 'relative',
        top: isMobileOnly ? '35%' : '40%'
      }
    }
  },
  {
    name: 'TableBoost',
    platformId: 'DI-1016261',
    cardMain: {
      styleBody: {
        width: '40%'
      }
    },
    cardHover : {
      styleLogo: {
        position: 'relative',
        top: isMobile ? '20px': '-120px',
        width: isMobileOnly ? '20%' : (isTablet ? '22%': '80px')
      },
      styleTitle: {
        position: 'absolute',
        top:'70px'
      },
      styleBody: {
        position: 'relative',
        top: isMobile ? '30px': '70px',
      },
      styleButton: {
        position: 'relative',
        top: '12%'
      }
    }
  },
  {
    name: 'HDSupply',
    platformId: 'DI-1002689',
    cardMain: {
      styleBody: {
        width: '60%'
      }
    },
    cardHover: {
      styleLogo: {
        position: 'relative',
        top: isMobile ? '20px': '-120px',
        width: isMobileOnly ? '60%' : (isTablet ? '40%': '50%')
      },
      styleTitle: {
        position: 'absolute',
        top:'70px'
      },
      styleBody: {
        position: 'relative',
        top: '40px'
      },
      styleButton: {
        position: 'relative',
        top: isMobileOnly ? '15%' : '40%'
      }
    }
  },
  {
    name: 'Heartland',
    platformId: 'DI-1015129',
    cardMain: {
      styleBody: {
        width: '50%'
      }
    },
    cardHover: {
      styleLogo: {
        position: 'relative',
        top: isMobile ? '20px': '-120px',
        width: isMobileOnly ? '60%' : (isTablet ? '35%': '50%')
      },
      styleTitle: {
        position: 'absolute',
        top:'70px'
      },
      styleBody: {
        position: 'relative',
        top: '40px'
      },
      styleButton: {
        position: 'relative',
        top: isMobileOnly ? '30%' : '40%'
      }
    }
  },
  {
    name: 'CHS',
    platformId: 'DI-1009555',
    cardMain: {
      styleBody: {
        width: '60%'
      }
    },
    cardHover : {
      styleLogo: {
        position: 'relative',
        top: isMobile ? '20px': '-120px',
        width: isMobileOnly ? '60%' : (isTablet ? '35%': '120px')
      },
      styleTitle: {
        position: 'absolute',
        top:'70px'
      },
      styleBody: {
        position: 'relative',
        top: isMobile ? '10%': '120px'
      },
      styleButton: {
        position: 'relative',
        top: isMobileOnly ? '18%' : '20%'
      }
    }
  }
];

export const programDataUnAuthDefault = [
  {
    name: '',
    platformId: '',
    cardMain: {
      styleBody: {
        position: 'relative',
        top: isMobile ? '-10%': '20px',
        width: '80%'
      }
    },
    cardHover: {
      styleLogo: {
        position: 'relative',
        top: isMobile ? '-5%': '0',
        width: isMobileOnly ? '60%' : (isTablet ? '40%': '200px'),
        maxHeight: '80px'
      },
      styleTitle: {
        position: 'absolute',
        top: isMobileOnly ? '30%' : (isTablet ? '45%': '50px')
      },
      styleBody: {
        position: 'relative',
        top: isMobile ? '50px': '60px'
      },
      styleBodyMobile: {
        position: 'absolute',
        top: '45%',
        lineHeight: '20px'
      },
      styleButton: {
        position: 'relative',
        top: isMobile ? '-2%': '20%'
      }
    }
  },
];

export const programDataUnAuth = [
  {
    name: 'Direct TV',
    platformId: 'DI-1003953',
    cardMain: {
      styleBody: {
        position: 'relative',
        top: isMobile ? '-10%': '20px',
        width: '80%'
      }
    },
    cardHover: {
      styleLogo: {
        position: 'relative',
        top: isMobile ? '-5%': '0',
        width: isMobileOnly ? '60%' : (isTablet ? '40%': '200px')
      },
      styleTitle: {
        position: 'absolute',
        top: isMobileOnly ? '30%' : (isTablet ? '45%': '50px')
      },
      styleBody: {
        position: 'relative',
        top: '20px'
      },
      styleBodyMobile: {
        position: 'absolute',
        top: '45%',
        lineHeight: '20px'
      },
      styleButton: {
        position: 'relative',
        top: isMobile ? '-2%': '20%'
      }
    }
  },
  {
    name: 'Staples',
    platformId: 'DI-1004113',
    cardMain: {
      styleBody: {
        position: 'relative',
        top: isMobile ? '-10%': '30px',
        width: '70%'
      }
    },
    cardHover: {
      styleLogo: {
        position: 'relative',
        top: isMobile ? '-5%': '0',
        width: isMobileOnly ? '60%' : (isTablet ? '32%': '60%')
      },
      styleTitle: {
        position: 'absolute',
        top: isMobileOnly ? '30%' : (isTablet ? '40%': '70px')
      },
      styleBody: {
        position: 'relative',
        top: '40px'
      },
      styleBodyMobile: {
        position: 'absolute',
        top: '45%',
        lineHeight: '20px'
      },
      styleButton: {
        position: 'relative',
        top: isMobile ? '-2%': '33%'
      }
    }
  },
  {
    name: 'Aramark',
    platformId: 'DI-1002908',
    cardMain: {
      styleBody: {
        position: 'relative',
        top: '60px',
        width: '80%'
      }
    },
    cardHover : {
      styleLogo: {
        position: 'relative',
        top: isMobile ? '0%': '10px',
        width: isMobileOnly ? '60%' : (isTablet ? '45%': '50%')
      },
      styleTitle: {
        position: 'absolute',
        top:'70px'
      },
      styleBody: {
        position: 'relative',
        top: '20px'
      },
      styleBodyMobile: {
        position: 'absolute',
        top: '38%',
        lineHeight: '20px'
      },
      styleButton: {
        position: 'relative',
        top: isMobileOnly ? '-2%' : '22%'
      }
    }
  },
  {
    name: 'HDSupply',
    platformId: 'DI-1002689',
    cardMain: {
      styleBody: {
        position: 'relative',
        top: '60px',
        width: '60%'
      }
    },
    cardHover: {
      styleLogo: {
        position: 'relative',
        top: isMobile ? '-3%': '0',
        width: isMobileOnly ? '50%' : (isTablet ? '40%': '50%')
      },
      styleTitle: {
        position: 'absolute',
        top:'70px'
      },
      styleBody: {
        position: 'relative',
        top: '10px'
      },
      styleBodyMobile: {
        position: 'absolute',
        top: '30%',
        lineHeight: '20px'
      },
      styleButton: {
        position: 'relative',
        top: isMobileOnly ? '-2%' : '10%'
      }
    }
  },
];

export default getDistributorBP;
