import { useReducer, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import SignUpApi from '../../../SignUp/SignUpApi';
import ContractManagementApi from '../../ContractManagementApi';
import Joi from 'joi';
import { spinner } from '../../../../MasterComponents/Spinner/SpinnerActions';
import { findModal, showModal } from '../../../../MasterComponents/Modals/ModalsActions';
import ContractFailedModal from '../ContractFailedModal/ContractFailedModal';

const INITIAL_STATE = {
	manufacturer: {
		manufacturerName: null,
		loading: false,
		options: []
	},
	startDate: null,
	endDate: null,
	isAutomaticRenewal: true,
	isIDontKnow: false,
	clientContactName: '',
	clientContactEmail: '',
	file: null,
	isAgree: true
};

const SIMPLE = 'SIMPLE';
const MANUFACTURERS = 'MANUFACTURERS';
const FILE = 'FILE';
const I_DONT_KNOW = 'I_DONT_KNOW';
const AUTOMATIC_RENEWAL = 'AUTOMATIC_RENEWAL';
const I_AGREE = 'I_AGREE';

const action = (type, payload) => {
	return { type, payload };
};

const SCHEMA = Joi.object({
  manufacturer: Joi.object({
		manufacturerName: Joi.object().required().invalid(null)
	}).unknown(true),
	startDate: Joi.date().required().invalid(null),
	isAutomaticRenewal: Joi.bool(),
	isIDontKnow: Joi.bool(),
  endDate: Joi.when('isAutomaticRenewal', {is: true, then: Joi.allow(null)}).when('isAutomaticRenewal', {is: false, then: Joi.date().required()}),
	clientContactName: Joi.string().min(3).required(),
	clientContactEmail: Joi.string().email({ tlds: {allow: false} }).required(),
	file: Joi.object().allow(null),
  isAgree: Joi.bool().when('isAutomaticRenewal', {is: false, then: Joi.invalid(false)})
});

const reducer = (state, {type, payload}) => {
	switch (type) {
		case MANUFACTURERS:
			return {
				...state,
				manufacturer: {
					...state.manufacturer,
					...payload
				}
			};
		case I_AGREE:
			return {
				...state,
				isAgree: !state.isAgree,
			};
		case I_DONT_KNOW:
			return {
				...state,
				isIDontKnow: !state.isIDontKnow,
				startDate: !state.isIDontKnow ? moment().startOf('month').toDate() : state.startDate
			};
		case AUTOMATIC_RENEWAL:
			return {
				...state,
				isAutomaticRenewal: !state.isAutomaticRenewal,
				endDate: !state.isAutomaticRenewal ? null : moment(state.startDate).endOf('month').toDate()
			};
		case SIMPLE:
			return {
				...state,
				...payload
			};
		case FILE:
			return {
				...state,
				file: payload
			};
		default:
			return state;
	}
};

const useAddNewContractModal = (getContracts, isUpdate, contract, modals) => {
	const [state, setState] = useReducer(reducer, INITIAL_STATE);
	const dispatch = useDispatch();
	const userCurrent = useSelector(state => state.location.userCurrent);
	const company = useSelector(state => (state.company && state.company.nickname) || '');
	const parentMerchant = useSelector(state => state.location.merchant.filter(m => m.parent)[0] && state.location.merchant.filter(m => m.parent)[0].parent);

	useEffect(() => {
		searchManufacturers('', 1);
		if (!isUpdate) {
			setState(action(SIMPLE, {
				clientContactName: userCurrent.contactName,
				clientContactEmail: userCurrent.email,
				startDate: moment().startOf('month').toDate()
			}));
		} else {
			setState(action(SIMPLE, {
				manufacturer: {
					...state.manufacturer,
					loading: true,
				},
				startDate: moment(contract.startDate).toDate(),
				endDate: contract.endDate ? moment(contract.startDate).toDate() : null,
				isAutomaticRenewal: contract.endDate ? false : true,
				clientContactName: contract.clientName || '',
				clientContactEmail: contract.clientEmail || '',
				file: contract.fileName ? {
					isPdf: contract.fileName.slice(-3) === 'pdf' ? true : false,
					fileName: contract.fileName,
					file: null
				} : null
			}));

			SignUpApi.getManufacturers(contract.manufacturer, 1).then(({data}) => {
				const m = data.data[0];
				setState(action(MANUFACTURERS, ({
					loading: false,
					manufacturerName: m ? {...m, label: m.nickname, value: m.platform_id} : {label: 'Not Found', value: null}
				})));
			});
		}
		//eslint-disable-next-line
	}, []);

	const onInputChange = (e) => {
		setState(action(SIMPLE, {[e.target.name]: e.target.value}));
	};

	const searchManufacturers = (search, page) => {
		setState(action(MANUFACTURERS, {loading: true}));
		ContractManagementApi.manufacturers(search, page).then(({data}) => {
      setState(action(MANUFACTURERS, ({
				loading: false,
				options: data.data.map(m => {
					return {
						...m,
						label: m.name,
						value: m.platformid
					};
				})
			})));
    })
	};

	const validation = SCHEMA.validate(state, {abortEarly: false, errors: {wrap: {label: ''}}});
	const isInvalid =  validation.error ? true : false;

	const onAddNew = () => {
		dispatch(spinner(true));
		ContractManagementApi.post(
			parentMerchant.platform_id,
			state.manufacturer.manufacturerName.value,
			moment(state.startDate).format('yyyy-MM-DD'),
			state.endDate ? moment(state.endDate).format('yyyy-MM-DD') : null,
			state.isAutomaticRenewal,
			state.clientContactName,
			state.clientContactEmail,
			(state.file && state.file.file) || null
		).then(({data}) => {
			dispatch(spinner(false));
			getContracts();
			findModal(modals, 'addNewContractModal').close();
		}).catch(err => {
			dispatch(spinner(false));
			findModal(modals, 'addNewContractModal').close();
			dispatch(showModal(ContractFailedModal, 'contractFailedModal', {}));
			console.error(err);
		});
	};

	const onUpdate = () => {
		dispatch(spinner(true));
		ContractManagementApi.put(
			parentMerchant.platform_id,
			state.manufacturer.manufacturerName.value,
			moment(state.startDate).format('yyyy-MM-DD'),
			state.endDate ? moment(state.endDate).format('yyyy-MM-DD') : null,
			state.isAutomaticRenewal,
			state.clientContactName,
			state.clientContactEmail,
			(state.file && state.file.file) || null,
			contract.exclusionId
		).then(({data}) => {
			dispatch(spinner(false));
			getContracts();
      findModal(modals, 'addNewContractModal').close();
		}).catch(err => {
			dispatch(spinner(false));
			findModal(modals, 'addNewContractModal').close();
			dispatch(showModal(ContractFailedModal, 'contractFailedModal', {}));
			console.error(err);
		});
	};

	return {
		manufacturerName: {
			value: state.manufacturer.manufacturerName,
			options: state.manufacturer.options,
			onInputChange: 3,
			className: 'mb-3',
			isDisabled: isUpdate,
			isLoading: state.manufacturer.loading,
			onChange: (value) => {
				setState(action(MANUFACTURERS, { manufacturerName: value }))
			},
			onSearch: (value) => searchManufacturers(value, 1),
			filterOptions: false
		},
		labels: {
			className: 'text-grey mt-2'
		},
		startDate: {
			name: 'startDate',
			className: 'rb-input w-100 mb-3',
			wrapperClassName: 'd-block',
			locale: 'en',
			popperPlacement: 'bottom-start',
			selected: state.startDate,
			disabled: state.isIDontKnow || isUpdate,
			maxDate: !state.isAutomaticRenewal ? (state.endDate && moment(state.endDate).subtract(1, 'day').toDate()) : false,
			dateFormat: 'MM/dd/yyyy',
			onChange: (d) => setState(action(SIMPLE, {startDate: d})),
			showMonthYearPicker: true
		},
		endDate: {
			name: 'endDate',
			className: 'rb-input w-100 mb-3',
			wrapperClassName: 'd-block',
			locale: 'en',
			popperPlacement: 'bottom-start',
			selected: state.endDate,
			minDate: state.startDate && moment(state.startDate).toDate(),
			dateFormat: 'MM/dd/yyyy',
			onChange: (d) => setState(action(SIMPLE, {endDate: moment(d).endOf('month').toDate()})),
			showMonthYearPicker: true
		},
		calendarIcon: {
			pointerEvents: 'none',
			className: 'text-grey position-absolute clickable',
			style: {
				top: '5px',
				right: '5px',
				transform: 'translate(-100%, 50%)'
			}
		},
		iDontKnow: {
			checked: state.isIDontKnow,
			onChange: () => setState(action(I_DONT_KNOW)),
			label: `I don't know my start date`
		},
		automaticRenewal: {
			checked: state.isAutomaticRenewal,
			onChange: () => setState(action(AUTOMATIC_RENEWAL)),
			label: `Automatic Renewal`
		},
		clientContactName: {
			value: state.clientContactName,
			name: 'clientContactName',
			className: 'mb-3 rb-input',
			onChange: onInputChange
		},
		clientContactEmail: {
			value: state.clientContactEmail,
			name: 'clientContactEmail',
			className: 'mb-3 rb-input',
			onChange: onInputChange
		},
		onSelectFile: (file) => {
			setState(action(FILE, file));
		},
		file: state.file,
		saveButton: {
			color:'rb-blue',
			size:'sm',
			block: true,
			disabled: isInvalid,
			className:'py-2 shadow-none active',
			onClick: isUpdate ? onUpdate : onAddNew,
			text: isUpdate ? 'UPDATE' : 'SAVE'
		},
		iAgree: {
			checked: state.isAgree,
			onChange: () => setState(action(I_AGREE)),
			label: `I agree`
		},
		contractText: `Effective ${state.endDate && moment(state.endDate).format('MM/DD/yyyy')} ${userCurrent && userCurrent.activeMerchant && userCurrent.activeMerchant.nickname}'s contract with ${(state.manufacturer.manufacturerName && state.manufacturer.manufacturerName.label) || ''} will expire. Effective ${state.endDate && moment(state.endDate).add(1, 'day').format('MM/DD/yyyy')} ${(userCurrent && userCurrent.activeMerchant && userCurrent.activeMerchant.nickname) || ''} will participate in the ${company} ${(state.manufacturer.manufacturerName && state.manufacturer.manufacturerName.label) || ''} Program`
	}
};

export default useAddNewContractModal;