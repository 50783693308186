import React from 'react';
import { Row, Col, Button } from 'reactstrap';

const FoodCostView = (props) => {
  const { view, buttonText, onClickGetStarted } = props;
  const { viewTitle, viewBodyText, urlImage} = view;
  return (
    <Row className='py-3'>
      <Col xs={7} className='py-3'>
        <Row>
          <Col xs={12} className='text-size-40 line-height-60'>
            {viewTitle}
          </Col>
          <Col xs={12} className='text-size-20 line-height-55'>
            {viewBodyText}
          </Col>
        </Row>
        <Row>
          <Col className='py-4'>
            <Button className='text-uppercase shadow-none' color='primary' size='md'  style={{height: '50px', width: '153px'}} 
              onClick={onClickGetStarted}>
              {buttonText}
            </Button>
          </Col>
        </Row>
      </Col>
      <Col xs={5} className='d-flex justify-content-center align-items-center'>
        <img src={urlImage} width='100%' height='100%' alt='foodcost'/>
      </Col>
    </Row>
  )
};
export default FoodCostView;