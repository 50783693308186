import React from 'react';
import { Row, Col, Card, CardBody, Tooltip} from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { BsArrowClockwise } from 'react-icons/bs';
import config from '../../config/config';
import IconAlert from '../../MasterComponents/Icons/IconAlert';
import IconProcessing from '../../MasterComponents/Icons/IconProcessing';
import IconSuccess from '../../MasterComponents/Icons/IconSuccess';
import moment from 'moment';

const InvoiceScanningDetailItem = (props) => {
  const { inv, name, merchant, updateTime, status, statusType, statusReason, onUploadNow, isTooltipOpen, setTooltipByItem } = props;
  const statusColor =  status === 'Done' ? config.colors.rbGreen : (status === 'InProcess' ? config.colors.rbYellowLight : config.colors.rbRedLight)
  const statusIcon = status === 'Done' ? <IconSuccess size={16} color={statusColor} /> : ((statusType === 'InProcess' || (status === null)) ? <IconProcessing size={16} color={statusColor} /> : <IconAlert size={16} color={statusColor} />);
  const format = 'YYYY-MM-DD';
  if(isMobile) {
    return (
      <Card className='mx-2 my-1'>
        <CardBody>
          <Col xs={12} className='p-1'>
            {name}
          </Col>
          <Col xs={12} className='p-1'>
            {merchant}
          </Col>
          <Col xs={12} className='p-1'>
            {moment(updateTime).format(format)}
          </Col>
          <Col xs={12} className='p-1 text-capitalize'>
            <span>{statusIcon}</span>
            &nbsp;{status} &nbsp;
          </Col>
          <Col xs={12} className='p-1 text-grey text-size-14 line-height-19'>
            {statusReason}
          </Col>
          <Col xs={12} className='p-1 pt-2'>
          { status === 'Problem' && 
            <>
              <span className='text-blue clickable' onClick={()=>onUploadNow(true, inv)}><BsArrowClockwise size={16} color={config.colors.rbBlue} /> REUPLOAD</span>
            </>
          }
          </Col>
        </CardBody>
      </Card>
    )
  };
  return (
    <Row className='mx-4 p-3 border rounded-2 text-size-16 line-height-19'>
      <Col>
        {name}
      </Col>
      <Col>
        {merchant}
      </Col>
      <Col>
        {moment(updateTime).format(format)}
      </Col>
      <Col className='text-capitalize'>
        <span className={`clickable`} id={`idTooltip_${inv.id}`}>{statusIcon}</span>
          &nbsp;{status} &nbsp;
          <Tooltip placement='top' isOpen={isTooltipOpen} target={`idTooltip_${inv.id}`} toggle={()=>setTooltipByItem(inv)}>
            {statusReason}
          </Tooltip>
        { status === 'Problem' && 
          <>
            <span  className='text-blue clickable' onClick={()=>onUploadNow(true, inv)}>
              <BsArrowClockwise size={16} color={config.colors.rbBlue} /> REUPLOAD
            </span>
          </>
        }
      </Col>
    </Row>
  )
};

export default InvoiceScanningDetailItem;