import React, { Component } from 'react';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import SearchInput from '../../../MasterComponents/SearchInput/SearchInput';
import IconAdd from '../../../MasterComponents/Icons/IconAdd';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import IconEdit from '../../../MasterComponents/Icons/IconEdit';
import IconDelete from '../../../MasterComponents/Icons/IconDelete';
import config from '../../../config/config';
import './LocationDropdown.scss';
import * as $ from 'jquery';
import { findModal } from '../../../MasterComponents/Modals/ModalsActions';

class LocationDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      search: ''
    }
  }
  onAdd = () => {
    const { goToAdd } = this.props;
    goToAdd();
  };
  onEdit = (id) => {
    const { goToEdit } = this.props;
    goToEdit(id);
  };
  onDelete = (id) => {
    const { goToDelete } = this.props;
    goToDelete(id);
  };
  onBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      this.toggle()
    }
  };
  toggle = () => {
    const { triggerSearch, modals } = this.props;

    if (!this.state.open) {
      setTimeout(() => {
        $('#locationdropdown-menu').focus();
      }, 200);
    } else {
      // trigger search
      if (!findModal(modals, 'idLocationMangeModalDelete')) {
        triggerSearch();
      }
    }

    this.setState({
      open: !this.state.open
    });
  }
  onChange = (loc)=>{
    const { toggleLocation } = this.props;
    toggleLocation(loc)
  };

  onChangeAll = (loc)=>{
    const { toggleAllLocation } = this.props;
    toggleAllLocation(loc)
  };

  onChageSearch= (value) => {
    this.setState({
      search: value
    });
  } 

  render() {
    const { options, merchantSelected, hideAdd } = this.props;
    const { open, search } = this.state;
    const name = merchantSelected.length === 0 || merchantSelected.length === options.length ? 'All Locations' : (merchantSelected.length === 1 ? merchantSelected[0].label : `${merchantSelected.length} Locations`);
    const isAll = merchantSelected.length === 0 || merchantSelected.length === options.length;
    const filterOpt = options.filter(opt => opt.label.toString().toLowerCase().search(search.toLowerCase()) >= 0);
    const optionsMap = filterOpt.map(loc => {
      return (
        <div key={`loc_${loc.id}`} className="px-0 clickable mt-2 border d-flex flex-row align-items-center dropdown-item">
          <GridCol className='text-grey pr-0' maxWidth="20px">
            <div className="custom-control custom-checkbox">
              <input onChange={this.onChange.bind(this, loc)} checked={ isAll || merchantSelected.some(m => m.id === loc.id)} type="checkbox" className="custom-control-input" id={`loc_checkbox_${loc.id}`} />
              <label className="custom-control-label" htmlFor={`loc_checkbox_${loc.id}`}></label>
            </div>
          </GridCol>
          <GridCol className='py-2 text-wrap' onClick={this.onChange.bind(this, loc)}>
            {loc.label}
          </GridCol>
          <GridCol className='text-right px-0 clickable' maxWidth='30px' onClick={() => this.onEdit(loc.id)}>
            <IconEdit color={config.colors.rbDarkGrey} />
          </GridCol>
          <GridCol className='text-right pl-0 clickable' maxWidth='40px' onClick={() => this.onDelete(loc.id)}>
            <IconDelete color={config.colors.rbDarkGrey} />
          </GridCol>
        </div>
      )
    })
    return (
      <div className="dropdown keep-open" id="locationDropdown" style={{zIndex:1}}>
        <button className="btn rb-dropdown rb-input form-control border" type="button" id="locationDropdownMenuButton" onClick={this.toggle}>
          {name} {!open ? <IoMdArrowDropdown className='position-absolute dd-arrow' size={18} color={config.colors.rbBlack} /> : <IoMdArrowDropup className='position-absolute dd-arrow' size={18} color={config.colors.rbBlack} />}
        </button>
        <div onBlur={this.onBlur} tabIndex={-1} id='locationdropdown-menu' className={"dropdown-menu w-100 text-size-14 p-2 scrollbar-insights " + (open ? ' show' : '')} role='menu' style={{maxHeight: '300px', overflow: 'auto'}}>
          <div className='px-0' tabIndex={0}>
            <GridCol className='px-0 py-2'>
              <SearchInput placeholder='Search...' name='search' value={search} onChange={this.onChageSearch} />
            </GridCol>
          </div>
          <div tabIndex={1}>
            {
              !hideAdd &&
              <div className="dashed-box-grey p-2 clickable text-center" onClick={this.onAdd}>
                <IconAdd size={10} color={config.colors.rbDarkGrey}/> &nbsp; Add New Location
              </div>
            }
            <div className="px-0 clickable mt-2 border d-flex flex-row align-items-center dropdown-item1">
              <GridCol className='text-grey pr-0' maxWidth="20px">
                <div className="custom-control custom-checkbox">
                  <input onChange={this.onChangeAll} checked={merchantSelected.length === 0} type="checkbox" className="custom-control-input" id={`loc_checkbox_all`} />
                  <label className="custom-control-label" htmlFor={`loc_checkbox_all`}></label>
                </div>
              </GridCol>
              <GridCol className='py-2' onClick={this.onChangeAll}>
                All Locations
              </GridCol>
            </div>
            <hr />
            {optionsMap}
          </div>
        </div>
      </div>
    );
  }
};

export default LocationDropdown;
