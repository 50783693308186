import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import Button from '../../MasterComponents/Button/Button';
import DropDownListSelect from '../../MasterComponents/DropDownList/DropDownListSelect';
import GridCol from '../../MasterComponents/Grid/GridCol';
import GridRow from '../../MasterComponents/Grid/GridRow';
import SearchInput from '../../MasterComponents/SearchInput/SearchInput';
import IconSettings from '../../MasterComponents/Icons/IconSettings';
import { itemIntelSetShowFiltersSection } from './Reducer/ItemIntelAction';
import { showModal } from '../../MasterComponents/Modals/ModalsActions';
import ItemFiltersModal from './Modals/ItemFiltersModal';
import IconDropdown from '../../MasterComponents/Icons/IconDropdown';

class ItemIntelTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemsFilterOptions: [
        { label: 'All Items', value: 'all' },
        { label: 'Contracted Items', value: 'contracted-items' },
        { label: 'My Items', value: 'my-items' },
      ],
      selectedFilterOption: null,
      searchValue: '',
    };
  };

  componentDidMount() {
    if (this.props.filters.search) this.setState({ searchValue: this.props.filters.search });
    if (this.props.filters.itemFilter) {
      const selectedOption = this.state.itemsFilterOptions.find(option => option.value === this.props.filters.itemFilter);
      this.setState({ selectedFilterOption: selectedOption });
    } else {
      this.setState({ selectedFilterOption: this.state.itemsFilterOptions[0] });
    }
  }

  onSelectFilter(value) {
    const newFilters = { ...this.props.filters, itemFilter: value.value }
    this.props.updateItems(newFilters);
    this.setState({ selectedFilterOption: value });
  }

  onChangeSearch(value) {
    this.setState({ searchValue: value });
  }

  onEnterSearch = (event) => {
    if (event.key === 'Enter') {
      const newFilters = { ...this.props.filters, search: this.state.searchValue }
      this.props.updateItems(newFilters);
    }
  };

  openFiltersSection() {
    const { dispatch } = this.props;
    dispatch(itemIntelSetShowFiltersSection(true))
  }

  openItemsFiltersModal() {
    const { dispatch } = this.props;
    dispatch(showModal(ItemFiltersModal, 'itemFiltersModal', {
      filterOptions: this.state.itemsFilterOptions,
      onSelectFilter: this.onSelectFilter.bind(this),
    }));
  }

  render() {
    return (
      <>
        {isMobile ?
          <GridRow justify='between' className='py-3'>
            <GridCol className='pr-2' size={8}>
              <GridRow className='pl-3 pr-2' onClick={this.openItemsFiltersModal.bind(this)}>
                <GridCol className='border text-size-14' style={{ height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  {this.state.selectedFilterOption && this.state.selectedFilterOption.label}
                  <IconDropdown />
                </GridCol>
              </GridRow>
            </GridCol>
            <GridCol className='pl-1' size={4}>
              <Button onClick={this.openFiltersSection.bind(this)} variant='primary' size='sm-fs' text='FILTERS' icon={<IconSettings />} />
            </GridCol>
            <GridCol className='mt-2' size={12}>
              <SearchInput name='search' placeholder='Search for MIN, description or manufacturer'
                onChange={this.onChangeSearch.bind(this)}
                onKeyPress={this.onEnterSearch.bind(this)} />
            </GridCol>
          </GridRow>
          :
          <GridRow justify='between' className='py-3'>
            <GridCol className='px-0 clickable' maxWidth='200px'>
              <DropDownListSelect value={this.state.selectedFilterOption} options={this.state.itemsFilterOptions}
                onChange={this.onSelectFilter.bind(this)} isDisabled={false} isSearchable={false} />
            </GridCol>
            <GridCol className='pr-0' maxWidth='350px'>
              <SearchInput name='search' placeholder='Search for MIN, description or manufacturer'
                value={this.state.searchValue}
                onChange={this.onChangeSearch.bind(this)}
                onKeyPress={this.onEnterSearch.bind(this)} />
            </GridCol>
          </GridRow>
        }
      </>
    );
  };

}

const mapStateToProps = (state) => {
  return {
    filters: state.itemIntel.filters
  };
};

export default connect(mapStateToProps)(ItemIntelTop);