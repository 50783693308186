import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconEmailOpen = props => (
<svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 32 33" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Claim-Cash" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="2---Step-1-Filled" transform="translate(-1122.000000, -498.000000)">
            <g id="icons/form" transform="translate(1122.000000, 498.000000)">
                <path d="M16.063767,1.28848122 L9.00489498,7 L5,7 L5,10.2401736 L1,13.4771736 L1,32 L31,32 L31,13.4747724 L27,10.2117724 L27,7 L23.0637985,7 L16.063767,1.28848122 Z" id="Combined-Shape" stroke={!props.isActive ? props.color : props.activeColor} strokeWidth="2"></path>
                <rect id="Rectangle" fill={!props.isActive ? props.color : props.activeColor} x="9" y="6" width="14" height="2"></rect>
                <rect id="Rectangle" fill={!props.isActive ? props.color : props.activeColor} x="9" y="11" width="14" height="1"></rect>
                <rect id="Rectangle" fill={!props.isActive ? props.color : props.activeColor} x="9" y="14" width="14" height="1"></rect>
                <path d="M31,14.5315711 L16.0680698,21.0915168 L1,14.5264837 L1,32 L31,32 L31,14.5315711 Z" id="Rectangle" stroke={!props.isActive ? props.color : props.activeColor} strokeWidth="2"></path>
                <rect id="Rectangle" fill={!props.isActive ? props.color : props.activeColor} x="26" y="10" width="2" height="6"></rect>
                <rect id="Rectangle" fill={!props.isActive ? props.color : props.activeColor} x="4" y="10" width="2" height="6"></rect>
            </g>
        </g>
    </g>
</svg>
);

IconEmailOpen.propTypes = {
  color: PropTypes.string,
  activeColor: PropTypes.string,
  isActive: PropTypes.bool,
  size: PropTypes.number
};

IconEmailOpen.defaultProps = {
  activeColor: config.colors.rbYellow,
  color: config.colors.rbWhite,
  isActive: false,
  size: 16
};

export default IconEmailOpen;