import React, { useEffect } from 'react';
import BrowserExtensionBanner from '../../MasterComponents/BrowserExtensionBanner/BrowserExtensionBanner';
import { Container, Row, Col } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { getBgColor } from '../../config/Company/CompanyHelper';
import { useDispatch, useSelector } from 'react-redux';
import HomeActionsRequired from './HomeActionsRequired';
import HomeLocations from './HomeLocations';
import HomeInsights from './HomeInsights';
import HomePrograms from './HomePrograms';
import InsightsEmpty from '../Insights/InsightsEmpty';
import HomeNew from './HomeNew';
import { faqSetAll, newSetAll } from './Reducer/HomeMainAction';
import HomeApi from './HomeApi';

const Home = (props) => {
  const { history, companyId } = props;
  const dispatch = useDispatch();
  const merchantAll = useSelector(({location}) => location.merchant.filter(m => m.platformId));
  const newAll = useSelector(({homeMain}) => homeMain.newAll);
  const hasNewAll = newAll.length > 0 ? true : false;
  const colSize = (isMobile ? 12 : (hasNewAll ? 4 : 6));
  const bgColor = getBgColor(companyId);
  const propsHome = {
    history,
    companyId
  };

  useEffect(() => {
    //Get FAQ by company
    HomeApi.getFaqCompany(companyId).then(({data}) => {
      dispatch(faqSetAll(data.data));
    });
    //Get FAQ Resource
    HomeApi.getFaqResourceCompany(companyId).then(({data}) => {
      dispatch(newSetAll(data.data));
    });
  }, [])

  return (
    <Container fluid className={`px-4 pt-1 pb-4 ${bgColor}`}>
      <Row>
        <Col className='px-0'>
          <BrowserExtensionBanner />
        </Col>
      </Row>
      <Row>
        <Col xs={colSize}>
          <HomeActionsRequired {...propsHome} />
        </Col>
        <Col xs={colSize}>
          <HomeLocations {...propsHome} />
        </Col>
        { hasNewAll && 
           <Col xs={colSize}>
            <HomeNew {...propsHome} />
          </Col>
        }
      </Row>
      <Row>
        <Col>
          {
            merchantAll.length > 0 ?
              <HomeInsights {...propsHome} />
            :
            <>
              <Row className={`text-size-${isMobile ? '30' : '32'} line-height-38`}>
                <Col className={`py-3${isMobile ? ' px-0' : ''}`}>
                  Insights Summary
                </Col>
              </Row>
              <InsightsEmpty />
            </>
          }
        </Col>
      </Row>
      <Row>
        <Col>
          <HomePrograms {...propsHome} />
        </Col>
      </Row>
    </Container>
  )
}

export default Home;
