import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { findModal } from '../../../MasterComponents/Modals/ModalsActions';
import Modal from '../../../MasterComponents/Modals/Modal';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import Button from '../../../MasterComponents/Button/Button';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import InputLabel from '../../../MasterComponents/InputLabel/InputLabel';
import checkImg from '../../../assets/images/big-check.svg';
import alertImg from '../../../assets/images/big-alert.svg';

class DistributorConfirmSaveModal extends Component {
  closeModal = () => {
    const { modals } = this.props;
    findModal(modals, 'distributorConfirmSaveModal').close();
  }
  render() {
    const { isPreffered, provideDcn } = this.props;
    const headerText = isPreffered ? ( provideDcn ? "You've selected a partner distributor - we will get the data for you!" : "You must add your DCN in order to start earning cash back" ) : 'You must login and activate this distributor in order to start earning cash back';
    return (
      <Modal id='distributorConfirmSaveModal' centered={isMobile} >
        <ModalBody className='modal-reports-map text-center'>
          <GridRow className='my-3'>
            <GridCol className='text-center d-flex justify-content-center'>
              <img src={isPreffered && provideDcn ? checkImg : alertImg} alt='status' />
            </GridCol>
          </GridRow>
          <GridRow>
            <GridCol>
                <InputLabel className='modal-reports-subtitle'>{headerText}</InputLabel><br/>
                {
                    (isPreffered && !provideDcn) ?
                        <InputLabel className='modal-reports-text mt-3'>
                            You can find you DCN on the top of an invoice from your distributor. You can add the DCN later after loggin in.
                        </InputLabel>
                    : null
                }
            </GridCol>
          </GridRow>
          <GridRow className='mt-5'>
            <GridCol>
                <Button variant='yellow' onClick={this.closeModal} className='clickable' size='lg' text='OK' />
            </GridCol>
          </GridRow>
        </ModalBody>
      </Modal>
    );
  }
};

export default DistributorConfirmSaveModal;