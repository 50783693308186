import config from "../../config/config";

export const getBrowser = () => {
  let test = function(regexp) {return regexp.test(window.navigator.userAgent)}
    switch (true) {
      case test(/edg/i): 
        return 'Microsoft Edge';
      case test(/trident/i): 
        return 'Microsoft Internet Explorer';
      case test(/firefox|fxios/i): 
        return 'Mozilla Firefox';
      case test(/opr\//i):
        return 'Opera';
      case test(/ucbrowser/i): 
        return 'UC Browser';
      case test(/samsungbrowser/i): 
        return 'Samsung Browser';
      case test(/chrome|chromium|crios/i): 
        return 'Google Chrome';
      case test(/safari/i): 
        return 'Apple Safari';
      default: 
        return 'Other';
    }
};

export const getRandomColor = (i) => {
  const color = config.colorPalette;
  if(i > color.length) {
      let letters = '0123456789ABCDEF'.split('');
      let colorNew = '#';
      for (let j = 0; j < 6; j++) {
          colorNew += letters[Math.floor(Math.random() * 16)];
      }
      return colorNew;
  }
  return color[i]
};

export const getText = (country= 'us', inputCase) => {
  const countryUC = country.toUpperCase();
  switch (inputCase) {
    case 'zipCode':
      if(countryUC === 'CA') {
        return 'Postal Code *';
      } 
      return 'Zipcode *';
    case 'zipCodeBusiness' :
      if(countryUC === 'CA') {
        return 'Business Postal Code *';
      } 
      return 'Business Zipcode *';
    case 'zipCodePlaceholder':
      if(countryUC === 'CA') {
        return 'Postal Code';
      } 
      return 'Zipcode';
    case 'state' :
      if(countryUC === 'CA') {
        return 'Province *';
      } 
      return 'State *';
    default:
      break;
  }

}