import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { FaLongArrowAltRight } from 'react-icons/fa';
import accessImage from '../../../assets/images/access-the-best.png';

const JoinAccess = ({scrollTop}) => {
	return (
		<Container fluid className='bg-white pt-5 pb-5'>
			<Container className='pt-4 pb-5 mw-1600'>
				<Row className='text-muted align-items-stretc\'>
					<Col className='align-items-center d-flex' md={12} lg={6}>
						<img src={accessImage} alt='laptop' className='mw-100' />
					</Col>
					<Col>
						<Row>
							<Col className='text-title text-action'>
								ACCESS THE BEST AND MOST UP-TO-DATE TECHNOLOGY TO EMPOWER YOUR RESTAURANT
							</Col>
						</Row>
						<Row className='mt-4'>
							<Col>
								Dining Alliance offers our members access to the best and latest technology our industry has to offer. From the state-of-the-art MyDining Alliance Portal, to our enhanced restaurant metrics and reporting, to our inventory ordering and management software, Dining Alliance is the most technologically advanced, and enhanced, buying group in the restaurant industry. 
							</Col>
						</Row>
						<Row className='mt-4'>
							<Col>
								During these challenging and uncertain times in the restaurant industry, we are adapting by offering our members exclusive discounts on delivery and take-out products and services. We understand our members must be agile and willing to adapt, so we give them access at low prices to programs like ItsaCheckmate all while providing high-quality and affordable products from trusted manufacturers like Georgia-Pacific and Dart.
							</Col>
						</Row>
						<Row className='py-5'>
							<Col>
								<Button color='action' className='py-3 px-4 rounded-0 align-items-center text-white mb-3' onClick={scrollTop}>
									GAIN ACCESS <FaLongArrowAltRight className='ml-2' />
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</Container>
	)
};

export default JoinAccess;