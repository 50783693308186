import React, { Component } from 'react';
import { connect } from 'react-redux';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import GridContainer from '../../../MasterComponents/Grid/GridContainer';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import ItemIntelDetailsSimilarItems from './ItemIntelDetailsSimilarItems';
import ItemIntelDetailsChart from './ItemIntelDetailsChart';
import ItemIntelDetailsInfo from './ItemIntelDetailsInfo';
import ItemIntelApi from '../ItemIntelApi';
import { spinner } from '../../../MasterComponents/Spinner/SpinnerActions';
import { itemIntelResetItemDetails, itemIntelResetSimilarItems, itemIntelSetItemDetails, itemIntelSetSimilarItems } from '../Reducer/ItemIntelAction';
import { isMobile } from 'react-device-detect';
import { changePage } from '../../Dashboard/DashboardActions';
import IconArrowBack from '../../../MasterComponents/Icons/IconArrowBack';
import { showModal } from '../../../MasterComponents/Modals/ModalsActions';
import ServerErrorModal from '../Modals/ServerErrorModal';
import { getItemIntelDetailsContainerClass, getItemIntelHeaderClass } from '../../../config/Company/CompanyHelper';
import Erni from '../../../MasterComponents/Erni/Erni';

class ItemIntelDetails extends Component {

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(spinner(true));

    this.loadDetailsData();

    const section = {
      button: 'Item Intel',
      title: 'Item Intel'
    };
    dispatch(changePage(section));
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.productId !== prevProps.match.params.productId) {
      this.clearDetailsData();
      this.loadDetailsData();
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    this.clearDetailsData();
    const section = {
      button: 'Rebates Portal',
      title: 'Rebates Portal'
    };
    dispatch(changePage(section));
  }

  loadDetailsData() {
    const { dispatch } = this.props;
    const { productId } = this.props.match.params;

    dispatch(spinner(true));

    const getDetails = new Promise((resolve, reject) => {
      ItemIntelApi.getDetail(productId).then((res) => {
        dispatch(itemIntelSetItemDetails(res.data.data));
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
    const getSimilarItems = new Promise((resolve, reject) => {
      ItemIntelApi.getSimilarItems(productId).then((res) => {
        let similarItems = res.data.data;
        const { productId } = this.props.match.params;
        similarItems = similarItems.filter(item => item.manufacturer_product_id !== Number(productId));

        dispatch(itemIntelSetSimilarItems(similarItems));
        resolve();
      }).catch(error => {
        reject(error);
      });
    });

    Promise.all([getDetails, getSimilarItems]).then(values => {
      dispatch(spinner(false));
    }).catch(error => {
      dispatch(spinner(false));
      const { status, statusText } = error || '';
      dispatch(showModal(ServerErrorModal, 'serverErrorModal', { status, statusText }));
    });
  }

  clearDetailsData() {
    const { dispatch } = this.props;
    dispatch(itemIntelResetItemDetails());
    dispatch(itemIntelResetSimilarItems());
  }

  goToItemIntel() {
    const { history } = this.props;
    history.push(`/dashboard/itemIntel`);
  }

  render() {
    const props = this.props;
    const companyId = props.companyId;
    const itemIntelHeaderClass = getItemIntelHeaderClass(companyId);
    const itemIntelDetailsContainerClass = getItemIntelDetailsContainerClass(companyId);

    return (
      <GridContainer isFluid={true} id='item-intel-details-container' className={itemIntelDetailsContainerClass}>
        <GridRow className={isMobile ? 'px-0' : 'px-3'}>
          <GridCol>

            {isMobile ?
              <GridRow className={'py-3 text-white border-top justify-content-center ' + itemIntelHeaderClass}>
                <div className='go-back-icon clickable' onClick={this.goToItemIntel.bind(this)}>
                  <IconArrowBack />
                </div>
                Item Details
              </GridRow>
              :
              <GridRow className={'details-title py-3 ' + itemIntelHeaderClass}>
                Ingredient Local Market Pricing Details
              </GridRow>
            }
            <GridRow className='bg-white px-3 border rounded'>
              <GridCol size={isMobile ? 12 : 3} className='py-3'>
                <ItemIntelDetailsInfo />
              </GridCol>
              <GridCol size={isMobile ? 12 : 9} className={isMobile ? 'py-3 border-top text-left' : 'py-3'} >
                <ItemIntelDetailsChart />
              </GridCol>
            </GridRow>

            <GridRow className={isMobile ? 'details-title py-3 px-3 position-relative' : 'details-title py-3 position-relative'}>
              {this.props.similarItems.length > 0 && 'Similar Items'}
              {
                this.props.similarItems.length > 0 &&
                  <Erni centerAlign={isMobile} rightAlign={!isMobile} top={-35.5+25} left={!isMobile ? 100 : 150} message='I found similar items that can save you money! Click “See Details” to see more information.' />
              }
            </GridRow>
            <GridRow className={isMobile ? 'details-card-container-mobile' : 'mb-5 details-card-container'}>
              <ItemIntelDetailsSimilarItems {...props} />
            </GridRow>

          </GridCol>
        </GridRow>

      </GridContainer>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    itemDetails: state.itemIntel.itemDetails,
    similarItems: state.itemIntel.similarItems,
    companyId: state.company.id
  };
};

export default connect(mapStateToProps)(ItemIntelDetails);