import React from 'react';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import useNew from './hooks/useNew';
import { useDispatch } from 'react-redux';
import { getFAQHowToLinkClass } from '../../config/Company/CompanyHelper';

const HomeNew = (props) => {
  const dispatch = useDispatch();
  const { goToNew, newAll, onClickMore } = useNew({ ...props, dispatch });
  let newInit = newAll.filter(item => item.show_home === true || item.show_home === 1)
  newInit = newInit.splice(0, 3);
  const FAQHowToLinkClass = getFAQHowToLinkClass(props.companyId)

  const getDescriptionFirstSentence = (description) => {
    const regExp = /(<br\/>|<br \/>|\?|!|\.)/i
    const index = description.search(regExp);
    if (index <= 0) return description;
    return description.substring(0, index + 1);
  }

  const newArray = newInit.map((n, i) => (
    <Row key={`new_${i}`}>
      <Col xs={5} className='py-2'>
        {n.type === 'video' ?
          <>
            <iframe
              width='100%'
              height='80px'
              src={n.url}
              title={n.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen>
            </iframe>
            <div className='faq-mask-youtube clickable' onClick={() => onClickMore(n)} style={{ height: '80px' }}></div>
          </>
          :
          <img className='clickable' src={n.cover_image} alt="what's new" width='100%' height='80px' onClick={() => onClickMore(n)} />
        }
      </Col>
      <Col xs={7}>
        <Row>
          <Col className='text-size-14 line-height-20 text-bold'>
            {n.title}
          </Col>
        </Row>
        <Row>
          <Col className='text-size-14 line-height-20'>
            {n.description && getDescriptionFirstSentence(n.description)}
          </Col>
        </Row>
        <Row>
          <Col className={'text-blue text-size-14 line-height-17 py-2 clickable text-bold text-uppercase ' + FAQHowToLinkClass} onClick={() => onClickMore(n)}>
            {n.type === 'video' ? 'Watch Now' : 'Learn More'}
          </Col>
        </Row>
      </Col>
    </Row>
  ));

  return (
    <>
      <Row className={'line-height-38 ' + (isMobile ? 'text-size-30' : 'text-size-32')}>
        <Col className={'py-3 ' + (isMobile ? 'px-0' : '')} >
          What's New
        </Col>
      </Row>
      <Row>
        <Col className={(isMobile ? 'px-0' : '')} >
          <Card className='scrollbar-custom' style={{ height: isMobile ? null : '320px', overflowY: 'auto' }}>
            <CardBody>
              {newArray}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className='py-3'>
        <Col className={isMobile ? 'px-0' : ''}>
          <Button active block style={{ width: isMobile ? '100%' : '' }} className='py-2 shadow-none' size='sm' color='primary' onClick={goToNew} >VIEW ALL</Button>
        </Col>
      </Row>
    </>
  )
}

export default HomeNew;
