import { apiInstance } from '../tokenApi';

const getOne = (id, expand) => {
  return apiInstance.get(`company/${id}?expand=${expand}`);
}

const getPartner = (id, expand) => {
  return apiInstance.get(`company-partner/${id}?expand=${expand}`);
}

const getVersion = () => {
  return fetch(`${process.env.REACT_APP_BUCKET_URL}/version.txt`)
}

export default {
  getOne,
  getVersion,
  getPartner
}
