import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import useForm from './hooks/useForm';
import Header from './Components/Header';
import SignUpSimpleContent from './Components/SignUpSimpleContent';
import SignUpApi from '../SignUp/SignUpApi';
import { useDispatch, useSelector } from 'react-redux';
import SignUpSimpleSuceed from './Components/SignUpSimpleSuceed';
import { spinner } from '../../MasterComponents/Spinner/SpinnerActions';
import { showModal } from '../../MasterComponents/Modals/ModalsActions';
import ErrorWarning from '../../MasterComponents/ErrorWarning/ErrorWarning';
import { isMobile } from 'react-device-detect';
import SignUpSimpleSidebar from './Components/SignUpSimpleSidebar';
import useSmartLink from './hooks/useSmartLink';
import { setSegment, setSubsegment } from '../../config/Company/CompanyActions';
import queryString from 'query-string';

const SignUpSimple = ({ history, isIframe, isFoodServiceRewards, country }) => {
  const dispatch = useDispatch();
  const [signatureInput, setSignatureInput] = useState('');
  const { location } = history;

  useEffect(() => {
    const { search } = location;
    const parsed = queryString.parse(search);
    if (parsed.segment) {
      dispatch(setSegment(parsed.segment));
    }
    if (parsed.subsegment) {
      dispatch(setSubsegment(parsed.subsegment));
    }
  }, []);

  const form = useForm(country);
  useSmartLink(history, form.dispatchSmart);
  const {
    firstName, lastName, businessName, email, password, repeatPassword,
    reportingLocations, placesForm, gpoName, nameOfCompanies,
    validate, phoneNumber, finish, isDma, isGpo, terminationDate,
    formValues
  } = form;

  const company = useSelector(({ company }) => company);
  const segment = useSelector(({ company }) => company && company.segment);
  const subsegment = useSelector(({ company }) => company && company.subsegment);

  const propsSidebar = {
    history,
    company,
    isFoodServiceRewards
  };

  const onCreateAccount = () => {
    if (validate()) {
      smartifyAddress();
    }
  };

  const smartifyAddress = () => {
    const streets = [{
      address: placesForm.street.value,
      city: placesForm.city.value,
      state: placesForm.state.value.value,
      postcode: placesForm.zipcode.value
    }];
    dispatch(spinner(true));
    SignUpApi.getSmartyPlace(streets, country).then(({ data }) => {
      dispatch(spinner(false));
      const smartyLocations = data.data.map(loc => {
        let zipcode = loc.postcode.toString().trim()

        if (zipcode && country !== 'CA' && country !== 'ca') {
          if (zipcode.length > 5) zipcode = zipcode.substring(0, 5);
          if (zipcode.length < 5) zipcode = zipcode.padStart(5, '0');
        }
        return {
          ...loc,
          postcode: zipcode || ''
        }
      });
      createAccount(smartyLocations);
    }).catch(err => {
      console.error(err);
      dispatch(spinner(false));
      dispatch(showModal(ErrorWarning, 'errorWarningModal', { message: "Something wen't wrong with the address." }))
    });
  };

  const cleanSignatureHandler = () => {
    setSignatureInput('');
  }

  const sendSignatureHandler = (drawing) => {
    setSignatureInput(drawing)
  }

  const createAccount = (smartyLocations) => {
    const params = {
      contactName: firstName.value + ':' + lastName.value,
      title: 'Owner',
      businessName: businessName.value,
      businessAddress: smartyLocations[0].address,
      businessCity: smartyLocations[0].city,
      businessState: smartyLocations[0].state,
      businessPostcode: smartyLocations[0].postcode,
      businessStateName: placesForm.state.value.label,
      phoneNumber: phoneNumber.value,
      email: email.value,
      password: password.value,
      passwordRepeat: repeatPassword.value,
      emailRepeat: email.value,
      daSalesRep: formValues.daSalesRep || null,
      referredBy: formValues.referredBy,
      referredName: formValues.referredName,
      siteUrl: `https://${window.location.hostname}/`,
      companyId: company.id,
      brandCode: company.companyBrand.code,
      brand: company.companyBrand.name,
      totalReportingLocations: parseInt(reportingLocations.value),
      lop: {
        isReinhartAgreement: 0,
        isDistributorManufacturerAgreement: isDma.value ? 1 : 0,
        legalName: businessName.value,
        signedText: firstName.value + ' ' + lastName.value,
        signature: signatureInput,
        isFsr: 1,
        isGpo: isGpo.value ? 1 : 0,
        gpoName: isGpo.value ? ((gpoName.value && gpoName.value.value) || '') : '',
        terminationDate: terminationDate.value,
        nameOfCompanies: isDma.value ? nameOfCompanies.value.map(c => {
          return c.nickname;
        }).toString() : '',
        manufacturers: isDma.value ? nameOfCompanies.value.map(item => {
          return item.id
        }) : []
      },
      segment: segment,
      subsegment: subsegment
    };
    ReactGA.event({
      category: 'Form',
      action: 'Submit'
    });
    dispatch(spinner(true));
    SignUpApi.signupSimple(params).then(({ data }) => {
      dispatch(spinner(false));
      finish.set(true);
      console.log(data);
    }).catch(err => {
      console.log(err);
      const errorMessage = (err && err.data && err.data.data && err.data.data.message) || 'Failed to create account.';
      dispatch(spinner(false));
      dispatch(showModal(ErrorWarning, 'errorWarningModal', { message: errorMessage }))
    });
  };

  const contentProps = {
    ...form,
    country,
    isIframe,
    onCreateAccount,
    sendSignatureHandler,
    cleanSignatureHandler,
    isFoodServiceRewards
  };

  return (
    <div className='container-signup'>
      {
        (!isMobile && !isIframe) && <SignUpSimpleSidebar {...propsSidebar} />
      }
      <div className={`right-signup${isMobile ? '' : ' mt-5 pl-5'}`}>
        {
          (!finish.value && !isIframe) &&
          <Header history={history} isFoodServiceRewards={isFoodServiceRewards} companyId={company.id} />
        }
        {
          !finish.value ?
            <SignUpSimpleContent {...contentProps} />
            :
            <SignUpSimpleSuceed isFoodServiceRewards={isFoodServiceRewards} />
        }
      </div>
    </div>
  );
};

export default SignUpSimple;
