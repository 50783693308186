import React, { Component, Fragment } from 'react';
import { merchantSetSearch } from '../Location/Reducer/LocationAction';
import { showModal } from '../../MasterComponents/Modals/ModalsActions';
import { BsList, BsGrid3X3Gap } from 'react-icons/bs';
import { isMobile } from 'react-device-detect';
import { saveActiveMerchant, setDisplayBrowserExtension } from '../Dashboard/DashboardActions';
import GridRow from '../../MasterComponents/Grid/GridRow';
import GridCol from '../../MasterComponents/Grid/GridCol';
import SearchInput from '../../MasterComponents/SearchInput/SearchInput';
import ReactGA from 'react-ga';
import config from '../../config/config';
import LocationMangeModalDelete from './Modals/LocationMangeModalDelete';
import DashboardApi from '../Dashboard/DashboardApi';
import AlertModal from '../../MasterComponents/AlertModal.js/AlertModal';
import LocationApi from '../Location/LocationApi';
import LocationDropdown from './LocationDropdown/LocationDropdown';
import DistributorDropdown from './DistributorDropdown/DistributorDropdown';
import BrowserExtensionBanner from '../../MasterComponents/BrowserExtensionBanner/BrowserExtensionBanner';
import { getIconListColor, getIconHamburgerColor } from '../../config/Company/CompanyHelper';
import Button from '../../MasterComponents/Button/Button';
import { spinner } from '../../MasterComponents/Spinner/SpinnerActions';
import LocationPages from './LocationPages';

class LocationTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: props.searchLocation || '',
      distributorSelected: [],
      merchantSelected: [],
      actionRequired: 0
    };
  }
  onChangeSearch = (val) => {
    this.setState({
      searchInput: val
    });
  };

  onEnterSearch = (event) => {
    if(event.key === 'Enter'){
      const { dispatch, refresh } = this.props;
      const { searchInput, distributorSelected, actionRequired, merchantSelected } = this.state;
      dispatch(merchantSetSearch(searchInput));

      const distributorId = distributorSelected ? distributorSelected.map(d => d.id).toString() : '';
      const merchantId = merchantSelected ? merchantSelected.map(m => m.id).toString() : '';
      const params = {
        distributorId: '[' + distributorId + ']',
        merchantId: '[' + merchantId + ']',
        search: searchInput,
        actionRequired:  actionRequired ? 1 : 0
      };
      refresh(()=>{
        //TODO: Search Apply
      }, params);
    }
  };

  goToList = () => {
    const { history } = this.props;
    history.push('/dashboard/rebates/list');
  }

  goToCard = () => {
    const { history } = this.props;
    history.push('/dashboard/rebates/card');
  }

  goToInvoiceScanning = () => {
    const { history } = this.props;
    history.push('/dashboard/invoice');
  }

  goToAddNewLocation = () => {
    const { history, dispatch } = this.props;
    ReactGA.event({
      category: 'Click Button',
      action: 'Click Add New Location'
    });
    history.push('/dashboard/add-new-location');
  }

  goToEditLocation = (id) => {
    const { history } = this.props;
    ReactGA.event({
      category: 'Edit Location',
      action: 'Click Edit'
    });
    history.push(`/dashboard/edit-location/${id}`);
  }

  goToDeleteLocation = (id) => {
    const { dispatch, activeMerchant } = this.props;
    if (activeMerchant.id === id) {
      const props = {
        message : `Can't delete active location.`,
        buttonText: 'Ok'
      }
      dispatch(showModal(AlertModal, 'idAlertModal', {...props}));
    } else {
      dispatch(showModal(LocationMangeModalDelete, 'idLocationMangeModalDelete', {id: id, acceptDelete: this.acceptDelete}));
    }
  }

  acceptDelete = (id) => {
    const { refresh, dispatch } = this.props;
    ReactGA.event({
      category: 'Edit Location',
      action: 'Click Delete'
    });
    this.setState({
      searchInput: '',
      distributorSelected: [],
      merchantSelected: [],
      actionRequired: 0
    });
    dispatch(spinner(true));
    DashboardApi.deleteMerchant(id).then(res => {
      dispatch(spinner(false));
      refresh();
    }).catch(({data}) => {
      const message = data.data.message || 'Error, cannot be removed';
      const props = {
        message : message,
        buttonText: 'Ok'
      }
      dispatch(showModal(AlertModal, 'idAlertModal', {...props}));
    });
  }

  toggleLocation = (loc) => {
    const { merchantSelected } = this.state;
    const alreadyAdded = merchantSelected.some(m => m.id === loc.id);
    this.setState({
      merchantSelected: alreadyAdded ? merchantSelected.filter(m => m.id !== loc.id) : [...merchantSelected, loc]
    }, () => {
      const { merchantSelected } = this.state;
      const { merchantAll } = this.props;
      if (merchantAll.length === merchantSelected.length) {
        this.toggleAllLocation();
      }
    });
  };

  toggleAllLocation = () => {
    this.setState({
      merchantSelected: []
    });
  };

  pageSearch = (page) => {
    const { dispatch, loadMore } = this.props;
    const { distributorSelected, actionRequired, merchantSelected, searchInput } = this.state;
    const distributorId = distributorSelected ? distributorSelected.map(d => d.id).toString() : '';
    const merchantId = merchantSelected ? merchantSelected.map(m => m.id).toString() : '';
    const params = {
      distributorId: '[' + distributorId + ']',
      merchantId: '[' + merchantId + ']',
      search: searchInput,
      actionRequired:  actionRequired ? 1 : 0,
      page: page
    };
    loadMore(()=>{
      //Change active location
      if(merchantSelected[0]) {
        LocationApi.getCurrentUser().then((res) => {
          const data = res.data.data;
          localStorage.setItem('activeMerchant', JSON.stringify(data.activeMerchant));
          localStorage.setItem('status', data.status);
          dispatch(saveActiveMerchant(data.activeMerchant));
          dispatch(setDisplayBrowserExtension(data.displayBrowserExtension));
        });
      }
    }, params);
  }

  triggerSearch = () => {
    const { refresh, dispatch } = this.props;
    const { distributorSelected, actionRequired, merchantSelected, searchInput } = this.state;
    const distributorId = distributorSelected ? distributorSelected.map(d => d.id).toString() : '';
    const merchantId = merchantSelected ? merchantSelected.map(m => m.id).toString() : '';
    const params = {
      distributorId: '[' + distributorId + ']',
      merchantId: '[' + merchantId + ']',
      search: searchInput,
      actionRequired:  actionRequired ? 1 : 0
    };
    refresh(()=>{
      //Change active location
      if(merchantSelected[0]) {
        LocationApi.getCurrentUser().then((res) => {
          const data = res.data.data;
          localStorage.setItem('activeMerchant', JSON.stringify(data.activeMerchant));
          localStorage.setItem('status', data.status);
          dispatch(saveActiveMerchant(data.activeMerchant));
          dispatch(setDisplayBrowserExtension(data.displayBrowserExtension));
        });
      }
    }, params);
  };

  selectDistributor = (val) => {
    const { refresh, locationSearch } = this.props;
    const { merchantSelected } = this.state;
    const distributorId = val ? (val.id !== '-1' ? val.id.toString() : '') : '';
    const merchantId = merchantSelected.length > 0 ? merchantSelected.map(m => m.id).toString() : '';
    const params = {
      distributorId: '[' + distributorId + ']',
      merchantId: '[' + merchantId + ']',
      search: locationSearch,
      actionRequired:  val ? (val.id === '-1' ? 1 : 0) : 0
    };
    this.setState({
      distributorSelected: val ? (val.id !== '-1' ? val : null) : null,
      actionRequired:  val ? (val.id === '-1' ? 1 : 0) : 0
    });
    refresh(()=>{
      //Distributor has change sucessfully
    }, params);
  }

  toggleDistributor = (loc) => {
    const { distributorSelected } = this.state;
    const alreadyAdded = distributorSelected.some(m => m.id === loc.id);
    this.setState({
      distributorSelected: alreadyAdded ? distributorSelected.filter(m => m.id !== loc.id) : [...distributorSelected, loc]
    }, () => {
      const { distributorSelected } = this.state;
      const { distributorAll } = this.props;
      if (distributorAll.length === distributorSelected.length) {
        this.toggleAllDistributor();
      }
    });
  };

  toggleAllDistributor = () => {
    this.setState({
      distributorSelected: []
    });
  };

  toggleActionRequired = () => {
    this.setState({
      actionRequired: !this.state.actionRequired
    });
  };

  render() {
    const isAdmin = localStorage.getItem('authKeyAdmin');
    const { merchantAll, distributorAll, history, companyId, modals, merchantGroupQuery, dispatch } = this.props;
    const { pathname } = history.location;
    const { distributorSelected, searchInput, merchantSelected, actionRequired } = this.state;
    const insideCard = pathname === '/dashboard/rebates/card';
    const insideList = pathname === '/dashboard/rebates/list';
    const iconListColor = getIconListColor(companyId);
    const iconHamburgerColor = getIconHamburgerColor(companyId);
    const propsLocationManage = {
      modals,
      goToAdd: this.goToAddNewLocation,
      toggleLocation: this.toggleLocation,
      goToEdit: this.goToEditLocation,
      goToDelete: this.goToDeleteLocation,
      merchantSelected: merchantSelected,
      toggleAllLocation: this.toggleAllLocation,
      triggerSearch: this.triggerSearch
    };
    const propsDistributorManage = {
      toggleDistributor: this.toggleDistributor,
      distributorSelected: distributorSelected,
      toggleAllDistributor: this.toggleAllDistributor,
      triggerSearch: this.triggerSearch,
      actionRequired,
      toggleActionRequired: this.toggleActionRequired
    };

    const optionsLocation = merchantAll.map(m => {
      return {
        id: m.id,
        label: m.nickname
      }
    });
    optionsLocation.sort((a, b) => a.label.toString().localeCompare(b.label.toString()));
    
    const optionsDistributor = distributorAll.map(m => {
      return {
        id: m.id,
        label: m.nickname
      }
    });

    if(isMobile) {
      return (
        <Fragment>
          <GridRow className='px-3'>
            <GridCol size={12} className='py-1 pr-0'>
              <LocationDropdown options={optionsLocation} {...propsLocationManage} hideAdd={isAdmin ? false : companyId === 3 || companyId === 17} />
            </GridCol>
            <GridCol>
              <GridRow>
                <GridCol className='py-1 pr-1 px-0'>
                  <SearchInput placeholder='Search...' name='search' value={searchInput} onKeyPress={this.onEnterSearch} onChange={this.onChangeSearch}  />
                </GridCol>
                <GridCol className='px-0 pt-1 d-flex justify-content-end mw-fc pl-1'>
                  <Button onClick={this.goToInvoiceScanning} className='clickable' variant='primary' size='sm' text='INVOICES' />
                </GridCol>
              </GridRow>
            </GridCol>
            <GridCol size={12} className='py-1 px-0'>
              <DistributorDropdown options={optionsDistributor} {...propsDistributorManage} />
            </GridCol>
          </GridRow>
          <div>
            <LocationPages {...merchantGroupQuery} dispatch={dispatch} />
          </div>
        </Fragment>
      )
    }

    return (
      <Fragment>
        <BrowserExtensionBanner />
        <GridRow className='p-3'>
          <GridCol maxWidth='350px'>
            <LocationDropdown options={optionsLocation} {...propsLocationManage} hideAdd={isAdmin ? false : companyId === 3 || companyId === 17} />
          </GridCol>
          <GridCol className='text-right'>
            <Button onClick={this.goToInvoiceScanning} className='clickable' variant='primary' size='md' text='INVOICES' style={{height:'40px'}} />
          </GridCol>
          <GridCol>
            <SearchInput name='search' id='locationDistributorSearch' placeholder='Search Locations, Distributors or DCN...' value={searchInput} onKeyPress={this.onEnterSearch} onChange={this.onChangeSearch} />
          </GridCol>
          <GridCol>
            <DistributorDropdown options={optionsDistributor} {...propsDistributorManage} />
          </GridCol>
          <GridCol className='clickable text-right pt-2 px-0 mw-fc'>
            <BsList onClick={this.goToList} size={25} color={insideList ? iconListColor : config.colors.rbDarkGrey } />
          </GridCol>
          <GridCol className='clickable text-right pt-2 mw-fc'>
          <BsGrid3X3Gap size={25} onClick={this.goToCard} color={insideCard ? iconHamburgerColor : config.colors.rbDarkGrey } />
          </GridCol>
        </GridRow>
        {
          parseInt(merchantGroupQuery.pageCount) > 1 && (
            <LocationPages {...merchantGroupQuery} pageSearch={this.pageSearch} />
          )
        }
      </Fragment>
    )
  }
}

export default LocationTop;
