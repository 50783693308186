import React, { Component, Fragment } from 'react';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import {connect} from 'react-redux';
import GridRow from '../../MasterComponents/Grid/GridRow';
import GridCol from '../../MasterComponents/Grid/GridCol';
import Button from '../../MasterComponents/Button/Button';
import SavingsCalculator from './SavingsCalculator';

class HomeContentDA extends Component {
  goTo = (i) => {  
    this.props.history.push('/'+ this.props.links[i].button);
  };
  render() {
    const { links, name, homeVideo } = this.props;
    const buttonsMap = links.map((link, i) =>
      <GridCol key={i} size={isMobile ? 12 : 6} className={isMobile ? 'pb-3' : ''}>
        <Button type='button' variant='landing-page' size='lg' className='w-100' text={link.title} onClick={this.goTo.bind(this, i)}/>
      </GridCol>
    );
    if (isMobile) {
      return (
        <div className='home-content bg-blue-home'>
          <GridRow className='home-bg mx-0'>
            <GridCol>
              <GridRow className='p-3'>
                <GridCol className='login-box pb-3'>
                  <GridRow>
                    <GridCol className='home-row-title home-row-title px-0' style={{fontSize: '24px'}}>
                      Welcome to My {name}
                    </GridCol>                    
                  </GridRow>
                  <GridRow className='px-4 pb-5 pt-3'>
                    {buttonsMap}
                  </GridRow>
                </GridCol>
              </GridRow>
            </GridCol>
          </GridRow>
          <GridRow>
            <GridCol>
              <SavingsCalculator history={this.props.history} />
            </GridCol>
          </GridRow>
          { homeVideo &&
            <Fragment>                    
                <GridRow>
                    <GridCol className='text-center home-title-works my-5'>
                        How It Works
                    </GridCol>
                </GridRow>   
                <GridRow className='pb-5'>
                    <GridCol className={ isMobile ? '' : 'home-row-center'}>
                      <iframe src={homeVideo}
                        frameBorder='0'
                        allow='autoplay; encrypted-media'
                        allowFullScreen
                        height= {isMobileOnly ? '220vh' : ( isTablet ? '290vh' : '360vh')}
                        width={isMobile ? '80%' : '100%'}
                        title='video'
                      />
                    </GridCol>
                </GridRow>
            </Fragment>
          }
        </div>
      );
    }
    return ( 
      <div className='home-content no-gutters bg-blue-home'>
        <GridRow className='ml-0 home-bg'>
          <GridCol className='pl-5' style={{maxWidth: '580px'}}>
            <GridRow className='mx-0' style={{paddingTop: '90px'}}>
              <GridCol className='login-box pb-3'>
                <GridRow>
                  <GridCol className='home-row-title home-row-title-2 px-5'>
                    Welcome to My {name}
                  </GridCol>                    
                </GridRow>
                <GridRow className='px-4 pb-5 pt-3'>
                  {buttonsMap}
                </GridRow>
              </GridCol>
            </GridRow>
          </GridCol>
          <GridCol className='pt-5 pr-5' style={{marginBottom: '-85px'}}>
            <GridRow>
              <SavingsCalculator history={this.props.history} />
            </GridRow>
          </GridCol>
        </GridRow>
        { homeVideo &&
            <Fragment>                    
                <GridRow className='pt-5'>
                    <GridCol className='text-center home-title-works mt-5'>
                        How It Works
                    </GridCol>
                </GridRow>   
                <GridRow>
                    <GridCol className={ isMobile ? '' : 'home-row-center'}>
                            <iframe src={homeVideo}
                                    frameBorder='0'
                                    allow='autoplay; encrypted-media'
                                    allowFullScreen
                                    height= {isMobileOnly ? '220vh' : ( isTablet ? '290vh' : '360vh')}
                                    width={isMobile ? '80%' : '100%'}
                                    title='video'
                            />
                    </GridCol>
                </GridRow>
            </Fragment>
        }
        <GridRow className='home-row-bottom'>                    
            <GridCol className='home-row-text'>
            </GridCol>                    
        </GridRow>
        <GridRow className='clearfix'>                    
        </GridRow>
      </div>
    );
  };
}


const mapStateToProps = (state) => {
    return {
      section: state.home.section,
      links: state.home.links,
      name: state.company.nickname,
      homeVideo: state.company.homeVideo
    };
};
  
export default connect(mapStateToProps)(HomeContentDA);