import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { FaLongArrowAltRight } from 'react-icons/fa';
import OwlCarousel from 'react-owl-carousel2';
import prime01 from '../../../assets/images/join-prime/prime01.png';
import prime02 from '../../../assets/images/join-prime/prime02.png';
import prime03 from '../../../assets/images/join-prime/prime03.png';
import prime04 from '../../../assets/images/join-prime/prime04.png';
import prime05 from '../../../assets/images/join-prime/prime05.png';
import prime06 from '../../../assets/images/join-prime/prime06.png';
import prime07 from '../../../assets/images/join-prime/prime07.png';
import prime08 from '../../../assets/images/join-prime/prime08.png';
import prime09 from '../../../assets/images/join-prime/prime09.png';

const JoinEarn = ({scrollTop}) => {
	const primeLogos = [{
		logo: prime01,
		alt: 'prime01'
	},{
		logo: prime02,
		alt: 'prime02'
	},{
		logo: prime03,
		alt: 'prime03'
	},{
		logo: prime04,
		alt: 'prime04'
	},{
		logo: prime05,
		alt: 'prime05'
	},{
		logo: prime06,
		alt: 'prime06'
	},{
		logo: prime07,
		alt: 'prime07'
	},{
		logo: prime08,
		alt: 'prime08'
	},{
		logo: prime09,
		alt: 'prime09'
	}];
	return (
		<Container fluid className='bg-white pt-5 pb-5'>
			<Container className='pt-4 pb-5 mw-1600'>
				<Row className='text-muted '>
					<Col className='bg-join-earn' md={12} lg={6}>
						<Row className='h-100 align-items-center'>
							<Col>
								<Row>
									<Col>
										<iframe width='100%' height="415" src="https://www.youtube.com/embed/p0cl9SvmNtw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
									</Col>
								</Row>
								<Row className='mt-4'>
									<Col>
										<OwlCarousel options={{items: 6, nav: false, loop: true, autoplay: true, autoplayTimeout: 2000, autoplaySpeed: 1500}}>
											{
												primeLogos.map(({logo, alt}) => (
													<div key={`prime_${alt}`}><img src={logo} alt={alt} /></div>
												))
											}
										</OwlCarousel>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
					<Col>
						<Row>
							<Col className='text-title text-action'>
								SIGN UP IN MINUTES AND START EARNING CASH BACK ON OVER 165,000+ REBATE LINE ITEMS
							</Col>
						</Row>
						<Row className='mt-4'>
							<Col>
								We offer our members over 165,000 rebated line items from over 350+ different manufacturers. With product offerings from all major brands such as Tyson, McCain, Conagra, Unilever, Pepsi products and more. 
							</Col>
						</Row>
						<Row className='mt-4'>
							<Col>
								<i>Just how simple is earning CashBack with Dining Alliance?</i>
							</Col>
						</Row>
						<Row className='mt-4'>
							<Col className='font-weight-bold'>
								First, simply fill out the form below to create your myDining Alliance Portal account. Then check your inbox for an email on how to input your distributor connections and activate any and all DCNs, so that we can start automatically tracking your monthly restaurant spending. From there, our GPO technology systems analyze your monthly invoices to identify products and items that are on our rebate program.  Once we start matching your purchases to our contracted deviated pricing, your pile of CashBack earnings grows! And just like that – your restaurant is now saving and earning CashBack on products you buy everyday – all for free.
							</Col>
						</Row>
						<Row className='mt-4'>
							<Col>
								Set it, and forget it, with the myDining Alliance Portal, and start accruing CashBack for FREE today. 
							</Col>
						</Row>
						<Row className='py-5'>
							<Col>
								<Button color='action' className='py-3 px-4 rounded-0 align-items-center text-white mb-3' onClick={scrollTop}>
									EARN CASHBACK NOW <FaLongArrowAltRight className='ml-2' />
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</Container>
	)
};

export default JoinEarn;