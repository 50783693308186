import React, { Component } from 'react'
import Button from '../../../MasterComponents/Button/Button'
import GridCol from '../../../MasterComponents/Grid/GridCol'
import GridRow from '../../../MasterComponents/Grid/GridRow'
import Modal from '../../../MasterComponents/Modals/Modal'
import ModalBody from '../../../MasterComponents/Modals/ModalBody'
import ModalFooter from '../../../MasterComponents/Modals/ModalFooter'
import { findModal } from '../../../MasterComponents/Modals/ModalsActions'

export class SpecifyYourSearchModal extends Component {

  closeModal() {
    const { modals } = this.props;
    findModal(modals, 'specifyYourSearchModal').close();
  }

  render() {
    return (
      <Modal id='specifyYourSearchModal' backdrop={false}>
        <ModalBody className=''>
          <GridRow className='py-4 text-center'>
            <GridCol>
              Please make your search more specific
            </GridCol>
          </GridRow>
        </ModalBody>
        <ModalFooter className='text-center'>
          <Button type='button' variant='primary' size='sm' text='OK' onClick={this.closeModal.bind(this)}></Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default SpecifyYourSearchModal;
