import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconArrowForward = props => (
  <svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 10 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="FAQ" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="2---Menu" transform="translate(-352.000000, -230.000000)" fill={!props.isActive ? props.color : props.activeColor} fillRule="nonzero">
        <g id="icons/arrow-right" transform="translate(352.000000, 230.000000)">
          <polygon id="Path" transform="translate(4.800000, 8.000000) scale(-1, 1) translate(-4.800000, -8.000000) " points="9.6 0.97 8.565 0 0 8 8.565 16 9.6 15.035 2.075 8"></polygon>
        </g>
      </g>
    </g>
  </svg>
);

IconArrowForward.propTypes = {
  color: PropTypes.string,
  activeColor: PropTypes.string,
  isActive: PropTypes.bool,
  size: PropTypes.number
};

IconArrowForward.defaultProps = {
  activeColor: config.colors.rbYellow,
  color: config.colors.rbWhite,
  isActive: false,
  size: 16
};

export default IconArrowForward;