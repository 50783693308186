import React, { Component } from 'react';
import '../SignUp.scss';
import { connect } from 'react-redux';
import SignUpWhitelabelSidebar from './SignUpWhitelabelSidebar';
import SignUpForm from '../Forms/SignUpForm';
import { setSegment, setSubsegment } from '../../../config/Company/CompanyActions';
import queryString from 'query-string';
import CompanyApi from '../../../config/Company/CompanyApi';

class SignUpWhitelabelOld extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            lastStep: 1,
            loading: true,
            initReferredBy: null,
            partner: null
        }
    };
    componentDidMount() {
        const { location, dispatch } = this.props;
        const { search } = location;
        const parsed = queryString.parse(search);
        if (parsed.segment) {
            dispatch(setSegment(parsed.segment));
        }
        if (parsed.subsegment) {
            dispatch(setSubsegment(parsed.subsegment));
        }

        if (parsed.c) {
            this.isOnTheList(parsed.c);
        } else {
            this.setState({loading: false});
        }
    };
    componentDidUpdate(prevProps, prevState) {
        const { action, push, replace } = this.props.history;
        const { urlStep } = this.props.match.params;
        const url = `/register/old`;
        const prevStep = parseInt(prevProps.match.params.urlStep ? prevProps.match.params.urlStep : 1);
        const futureStep = parseInt(urlStep ? urlStep : 1);

        if (action === 'POP') {
            if ( ( prevStep < futureStep ) && ( futureStep > this.state.lastStep ) ) {
                this.setState({
                    step: prevStep,
                    lastStep: prevStep > this.state.lastStep ? prevStep : this.state.lastStep
                });
                replace(`${url}/${prevStep}`);
            } else if (futureStep > this.state.lastStep) {
                push(`${url}/${this.state.lastStep}`)
            } else {
                if (futureStep !== this.state.step) {
                    this.setState({
                        step: futureStep,
                        lastStep: futureStep > this.state.lastStep ? futureStep : this.state.lastStep
                    });
                }
            }
        } else {
            if (futureStep !== this.state.step) {
                this.setState({
                    step: futureStep,
                    lastStep: futureStep > this.state.lastStep ? futureStep : this.state.lastStep
                });
            }
        }
    };
    isOnTheList = (name) => {
        CompanyApi.getOne(1, 'partners').then(({data}) => {
            const { partners } = data.data;
            let referBy = null;
            let partner = null;
            partners.forEach(c => {
                if (c.abbreviation === name.toLowerCase() && c.type === 'channel') {
                    partner = c;
                    referBy = {value: c.id, isDaSalesRep: c.salesRepId, label: c.description, platformId: c.channelPlatformId};
                }
            });
            this.setState({
                loading: false,
                initReferredBy: referBy,
                partner: partner
            });
        });
    };
    render() {
        const { history, companyName, selfRegisterTitle, selfRegisterSubtitle, companyId } = this.props;
        const { step, loading, initReferredBy, partner } = this.state;
        const sidebarProps = {
            history,
            companyName,
            partner
        };
        const contentProps = {
            history,
            formType: [14].includes(companyId) ? 'existing-user-hide-sales-rep' : 'existing-user',
            step,
            title: selfRegisterTitle,
            subtitle: selfRegisterSubtitle,
            maxSteps: 1,
            hideSteps: true,
            pathname: '/register/old',
            initReferredBy
        };
        return (
            <div className='container-signup'>
                { !loading && <SignUpWhitelabelSidebar {...sidebarProps} isSelf />}
                { !loading && <SignUpForm {...contentProps} />}
            </div>
        )
    };
}

const mapStateToProps = state => {
    return {
        companyId: state.company.id,
        companyName: state.company.nickname,
        selfRegisterTitle: state.company.text.selfRegisterTitle,
        selfRegisterSubtitle: state.company.text.selfRegisterSubtitle
    }
}

export default connect(mapStateToProps)(SignUpWhitelabelOld);
