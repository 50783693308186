import { apiInstance } from '../../config/tokenApi';

export default {
	get: () => {
		return apiInstance.get('contract-management');
	},
	post: (platformId, manufacturerPlatformId, startDate, endDate, autoRenewal, clientName, clientEmail, file) => {
		let formData = new FormData();

		formData.append('platformId', platformId);
		formData.append('manufacturerPlatformId', manufacturerPlatformId);
		formData.append('startDate', startDate);
		formData.append('endDate', endDate);
		formData.append('autoRenewal', autoRenewal);
		formData.append('clientName', clientName);
		formData.append('clientEmail', clientEmail);
		formData.append('file', file);

		return apiInstance.post('contract-management', formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
	},
	put: (platformId, manufacturerPlatformId, startDate, endDate, autoRenewal, clientName, clientEmail, file, exclusionId) => {
		let formData = new FormData();

		formData.append('platformId', platformId);
		formData.append('manufacturerPlatformId', manufacturerPlatformId);
		formData.append('startDate', startDate);
		formData.append('endDate', endDate);
		formData.append('autoRenewal', autoRenewal);
		formData.append('clientName', clientName);
		formData.append('clientEmail', clientEmail);
		formData.append('file', file);
		formData.append('exclusionId', exclusionId);

		return apiInstance.post('contract-management/update', formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
	},
	manufacturers: (search, page) => {
    return apiInstance.get('contract-management/manufacturers', {
			params: {
				search: search,
				page: page
			}
    });
	}
};
