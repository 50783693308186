import { createStore, combineReducers } from 'redux';
import modals from '../MasterComponents/Modals/ModalsReducer';
import sidebar from '../MasterComponents/Sidebar/reducer/SidebarReducer';
import spinner from '../MasterComponents/Spinner/SpinnerReducer';
import dashboard from '../views/Dashboard/DashboardReducer';
import location from '../views/Location/Reducer/LocationReducer';
import home from '../views/Home/HomeReducer';
import resizeListener from '../MasterComponents/ResizeListener/ResizeListenerReducer';
import company from '../config/Company/CompanyReducer';
import notification from '../views/Notification/Reducer/NotificationReducer';
import homeMain from '../views/HomeMain/Reducer/HomeMainReducer';
import claimCash from '../views/ClaimCash/Reducer/ClaimCashReducer';
import prime from '../views/PrimePrograms/Reducer/PrimeReducer';
import itemIntel from '../views/ItemIntel/Reducer/ItemIntelReducer.js';
import reports from '../views/Reports/Reducer/ReportsReducer';
import signUp from '../views/SignUp/Reducer/SignUpReducer';
import invoice from '../views/InvoiceScanning/Reducer/InvoiceScanningReducer';

const reducer = combineReducers({
  // Add Reducers here
  modals,
  sidebar,
  dashboard,
  location,
  home,
  spinner,
  resizeListener,
  company,
  notification,
  homeMain,
  claimCash,
  prime,
  itemIntel,
  reports,
  signUp,
  invoice
});

export default createStore(reducer);
