import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconLogout = props => (
  <svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Bottom-Nav" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="2---Menu" transform="translate(-105.000000, -228.000000)" fill={!props.isActive ? props.color : props.activeColor} fillRule="nonzero">
        <g id="icons/logout" transform="translate(105.000000, 228.000000)">
          <polygon id="Path" points="11.9593929 10.4243929 12.7675 11.2325 16 8 12.7675 4.7675 11.9593571 5.57564286 13.8123214 7.42857143 5.71428571 7.42857143 5.71428571 8.57142857 13.8123214 8.57142857"></polygon>
          <path d="M12.8389643,12.8487857 C11.5438929,14.1438929 9.82207143,14.8571429 7.99057143,14.8571429 C6.15889286,14.8571429 4.43678571,14.1438929 3.14157143,12.84875 C1.84632143,11.5536071 1.14285714,9.83160714 1.14285714,8 C1.14285714,6.16853571 1.84632143,4.44660714 3.14160714,3.15139286 C4.43685714,1.85617857 6.15892857,1.14285714 7.99057143,1.14285714 C9.82196429,1.14285714 11.5438214,1.85617857 12.8389643,3.15135714 C12.9292143,3.24164286 13.0163214,3.33425 13.1008571,3.42857143 L14.5556429,3.42857143 C13.1100357,1.35614286 10.7087857,0 7.99057143,0 C3.57217857,0 0,3.58207143 0,8 C0,12.4183929 3.57217857,16 7.99057143,16 C10.7088571,16 13.1102143,14.6439286 14.5556429,12.5714286 L13.101,12.5714286 C13.0164286,12.6658214 12.9293214,12.7584286 12.8389643,12.8487857 Z" id="Path"></path>
        </g>
      </g>
    </g>
  </svg>
);

IconLogout.propTypes = {
  color: PropTypes.string,
  activeColor: PropTypes.string,
  isActive: PropTypes.bool,
  size: PropTypes.number,
};

IconLogout.defaultProps = {
  activeColor: config.colors.rbYellow,
  color: config.colors.rbWhite,
  isActive: false,
  size: 16,
};

export default IconLogout;