import React, { Component } from 'react'
import { connect } from 'react-redux';
import Modal from '../../../MasterComponents/Modals/Modal';
import ModalHeader from '../../../MasterComponents/Modals/ModalHeader';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import ModalFooter from '../../../MasterComponents/Modals/ModalFooter';
import { isMobile } from 'react-device-detect';
import { findModal } from '../../../MasterComponents/Modals/ModalsActions';
import termsPDF from '../../../assets/files/enter_credentials_terms_and_conditions.pdf'

class TermsConditionsModal extends Component {
    closeModal = () => {
        const { modals } = this.props;
        findModal(modals, 'termsConditionsModal').close();
    }
    render() {
        return (
            <Modal size='xl' id='termsConditionsModal' fullHeight={isMobile} >
                <ModalHeader noBorder className={isMobile ? 'pt-5 mt-5 px-4' : null}>Terms & Conditions</ModalHeader>
                <ModalBody className='text-center text-size-14 text-line-height-20'>
                    <iframe title='terms' src={termsPDF} style={{width: '100%', height: '500px'}}>
                        <p>It appears your web browser doesn't support iframes.</p>
                    </iframe>
                </ModalBody>
                <ModalFooter/>
            </Modal>
        );
    }
};

export default connect()(TermsConditionsModal);