import { apiInstance } from '../../config/tokenApi';

const signup = (params, signUpSalesRep) => {
    if(signUpSalesRep) {
        return apiInstance.post('/sales-rep-sign-up', params);
    }
    return apiInstance.post('/auth/sign-up?expand=fullLocations', params);
};

const signupBE = (params) => {
    return apiInstance.post('/auth/sign-up-be', params);
};

const signupSimple = (params) => {
    return apiInstance.post('/auth/sign-up-simple', params);
};

const onBoard = (params) => {
    return apiInstance.post('user/onboard', params);
};

const signupSF = (params) => {
    return apiInstance.post('/auth/sign-up-sf', params);
}

const getDistributors = (companyId, search, page) => {
    return apiInstance.get('distributor/all', {
        params: {
            search: search,
            page: page,
            companyId: companyId
        }
    });
}

const getDistributorsHouse = (companyId, id) => {
    return apiInstance.get(`/distributor/${id}/house`, {
        params: {
            companyId: companyId
        }
    });
}

const validateEmail = (params) =>{
    sessionStorage.setItem('signUpEmail', params.email);
    return apiInstance.post('/auth/validate-email', params);
}

const validateLocations = (params) =>{
    return apiInstance.post('/auth/validate-locations', params);
}

const requestInvite = (params) =>{
    return apiInstance.post('/auth/request-invite', params);
}

const getSalesRep = (params) => {
    return apiInstance.get('lop-sales-rep', params);
}

const getReferredPartner= (companyId, brand, page, search) => {
    const params = {
        companyId: companyId,
        brandCode: brand,
        page: page,
        search: search
    };
    return apiInstance.get('referred-partner', {params});
}

const getManufacturers = (search, page) => {
    return apiInstance.get('manufacturer', {
        params: {
            search: search,
            page: page
        }
    });
}

const getPlaceSearch = (input, country = 'us') => {
    return apiInstance.get('places/search', {
      params: {
        input,
        country
      }
    });
};

const getPlaceData = (placeId) => {
    return apiInstance.get('places/details', {
        params: {
            placeId: placeId
        }
    });
}

const getSmartyPlace = (streets, country = 'us') => {
    return apiInstance.post('places/smarty', {
        streets: streets.map(loc => {
            if (country === 'ca') {
                return {
                    ...loc,
                    country: 'CA',
                }
            }
            return loc;
        }),
        country: country
    });
}

const validateSFLocation = (params) => {
    return apiInstance.post('auth/validate-sf-locations', params);
};

const getDistributorBP = (token) => {
  return apiInstance.get('/distributor/bp-list', {
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
};

const addDistributorBP = (id, token, params) => {
  return apiInstance.post(`/merchant/${id}/bp-distributor`, params, {
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
};

const verifyAccountLOP = (platformId) => {
    return apiInstance.post('/auth/verify-account-lop', {
        platformId: platformId
    });
};

const lead = (params) => {
    return apiInstance.post('/auth/lead', params);
};

const getSalesRepSignUpInfo = recordId => {
    return apiInstance.get('/sales-rep-sign-up/' + recordId);
}

export default {
    signup,
    signupBE,
    signupSF,
    getDistributors,
    getDistributorsHouse,
    validateEmail,
    validateLocations,
    requestInvite,
    getManufacturers,
    getSalesRep,
    getReferredPartner,
    getPlaceSearch,
    getPlaceData,
    signupSimple,
    onBoard,
    getDistributorBP,
    addDistributorBP,
    getSmartyPlace,
    validateSFLocation,
    verifyAccountLOP,
    lead,
    getSalesRepSignUpInfo
};
