import { useEffect, useReducer } from 'react';
import * as $ from 'jquery';
import ContractManagementApi from './ContractManagementApi';
import { useDispatch, useSelector } from 'react-redux';
import { spinner } from '../../MasterComponents/Spinner/SpinnerActions';
import { showModal } from '../../MasterComponents/Modals/ModalsActions';
import AddNewContractModal from './Components/AddNewContractModal/AddNewContractModal';

const ASC_ORDER = {
	OFF: 0,
	ASC: 1,
	DESC: 2
};

const INITIAL_STATE = {
	filters: {
		open: false,
		active: true,
		expiring: true,
		expired: true,
		search: ''
	},
	sort: {
		manufacturer: ASC_ORDER.OFF,
		status: ASC_ORDER.ASC,
		startDate: ASC_ORDER.DESC,
		endDate: ASC_ORDER.OFF,
	},
	contracts: []
};

const reducer = (state, {type, payload}) => {
	const allChecked = state.filters.active && state.filters.expired && state.filters.expiring;
	const noneChecked = !state.filters.active && !state.filters.expired && !state.filters.expiring;
	switch (type) {
		case 'SAVE_CONTRACTS':
			return {
				...state,
				contracts: payload.filter(c => c.startDate !== null)
			};
		case 'SORT':
			if (payload === 'manufacturer') {
				return {
					...state,
					sort: {
						...state.sort,
						manufacturer:  state.sort.manufacturer === ASC_ORDER.DESC ? ASC_ORDER.OFF : state.sort.manufacturer + 1
					}
				};
			}
			if (payload === 'status') {
				return {
					...state,
					sort: {
						...state.sort,
						status:  state.sort.status === ASC_ORDER.DESC ? ASC_ORDER.OFF : state.sort.status + 1
					}
				};
			}
			return {
				...state,
				sort: {
					...state.sort,
					startDate: ASC_ORDER.OFF,
					endDate: ASC_ORDER.OFF,
					[payload]:  state.sort[payload] === ASC_ORDER.DESC ? ASC_ORDER.ASC : ASC_ORDER.DESC
				}
			};
		case 'FILTERS_SEARCH':
			return {
				...state,
				filters: {
					...state.filters,
					search: payload
				}
			};
		case 'FILTERS_TOGGLE':
			return {
				...state,
				filters: {
					...state.filters,
					open: !state.filters.open,
					active: (state.filters.open && noneChecked) ? true : state.filters.active,
					expiring: (state.filters.open && noneChecked) ? true : state.filters.expiring,
					expired: (state.filters.open && noneChecked) ? true : state.filters.expired
				}
			};
		case 'FILTERS_ALL':
			return {
				...state,
				filters: {
					...state.filters,
					active: !allChecked,
					expiring: !allChecked,
					expired: !allChecked,
				}
			};
		case 'FILTERS_ONE':
			return {
				...state,
				filters: {
					...state.filters,
					[payload]: !state.filters[payload]
				}
			};
		default:
			return state;
	}
};

const useContractManagement = () => {
	const dispatch = useDispatch();
	const [state, disp] = useReducer(reducer, INITIAL_STATE);
	const activeMerchant = useSelector(({dashboard}) => dashboard.activeMerchant);

	const getContracts = () => {
		dispatch(spinner(true));
		ContractManagementApi.get().then(({data}) => {
			dispatch(spinner(false));
			disp({type: 'SAVE_CONTRACTS', payload: data.data});
		}).catch(err => {
			dispatch(spinner(false));
			console.error(err);
		});
	};

	useEffect(() => {
		getContracts();
		// eslint-disable-next-line
	}, []);

	const openContractModal = (props) => {
		dispatch(showModal(AddNewContractModal, 'addNewContractModal', props));
	};

	return {
		contracts: state.contracts,
		disabledActions: activeMerchant ? activeMerchant.canEditContracts === 0 : false,
		onAddNew: () => {
			openContractModal({getContracts});
		},
		onUpdate: (contract) => {
			openContractModal({isUpdate: true, contract, getContracts});
		},
		filters: {
			...state.filters,
			search: {
				value: state.filters.search,
				onFocus: () => disp({type: 'FILTERS_SEARCH', payload: ''}),
				onChange: (e) => {
					disp({
						type: 'FILTERS_SEARCH',
						payload: e.target.value
					});
				}
			},
			select: (opt) => {
				if (opt === 'all') {
					disp({type: 'FILTERS_ALL'});
				} else {
					disp({
						type: 'FILTERS_ONE',
						payload: opt
					});
				}
			},
			toggle: () => {
				if (!state.open) {
					setTimeout(() => {
						$('#contractsDropdown-menu').focus();
					}, 200);
				}
				disp({type: 'FILTERS_TOGGLE'});
			}
		},
		sort: {
			...state.sort,
			toggle: (key) => {
				disp({
					type: 'SORT',
					payload: key
				})
			}
		}
	}
};

export default useContractManagement;