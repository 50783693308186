import React, { useState, useEffect } from 'react';
import '../InvoiceScanning.scss';

const ProgressBarInvoice = ({done}) => {
  const [style, setStyle] = useState({});
  
  useEffect(() => {
    setTimeout(() => {
      const newStyle = {
        opacity: 1,
        width: `${done}%`
      }
      setStyle(newStyle);
    }, 100);
    return () => clearTimeout();
  }, []);
  
  return (
    <>
    <div className='progress-inv'>
      <div className='progress-inv-done text-size-11' style={style}>
        {done}%
      </div>
    </div>
    </>
  )
};

export default ProgressBarInvoice;