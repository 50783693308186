import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { getItemIntelDetailsSimilarItemsLinkClass } from '../../../config/Company/CompanyHelper';
import Card from '../../../MasterComponents/Card/Card';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import { round } from '../../../utils/utils';

class ItemIntelDetailsSimilarItems extends Component {

  seeDetails(productId) {
    this.props.history.push(`/dashboard/itemIntel/${productId}`);
  }

  render() {
    const { companyId } = this.props;
    const itemIntelDetailsSimilarItemsLinkClass = getItemIntelDetailsSimilarItemsLinkClass(companyId);

    return (
      <>
        {this.props.similarItems.map(item =>
          <Card key={item.manufacturer_product_id} size={isMobile ? 'col-12' : 'col-sm-4 col-md-3 col-lg-2'} className='px-3 py-3 mb-2 border'>
            <GridRow className='py-2 font-weight-bold similar-item-card-title text-capitalize'>{item.description.toLowerCase()}</GridRow>
            <GridRow className='py-2' justify='between'>
              <GridCol size={3} className='px-0 text-grey'>Brand</GridCol>
              <GridCol size={9} className='px-0 text-right'>{item.brand}</GridCol>
            </GridRow>
            <GridRow className='py-2' justify='between'>
              <GridCol size={6} className='px-0 text-grey'><div className='text-grey'>Packsize</div></GridCol>
              <GridCol size={6} className='px-0 text-right'>{item.packSize || item.pack}</GridCol>
            </GridRow>
            <GridRow className='py-2' justify='between'>
              <GridCol size={8} className='px-0 text-grey'><div className='text-grey'>Similarity Score</div></GridCol>
              <GridCol size={4} className='px-0 text-right'>{round(item.score, 2)}%</GridCol>
            </GridRow>
            <GridRow className={'py-2 font-weight-bold text-blue clickable ' + itemIntelDetailsSimilarItemsLinkClass}
              onClick={this.seeDetails.bind(this, item.manufacturer_product_id)}>
              See Details
            </GridRow>
          </Card>
        )}
      </>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    companyId: state.company.id,
    similarItems: state.itemIntel.similarItems,
  };
};

export default connect(mapStateToProps)(ItemIntelDetailsSimilarItems);