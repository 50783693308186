import React from 'react';
import PropTypes from 'prop-types';
import './Card.scss';
import { isMobile } from 'react-device-detect';

const Card = props => (
  <div id={props.id} className={'card-rb-size col' + (props.size ? ` ${props.size}` : '') + (props.spacing ? ` px-${props.spacing}` : '')+(isMobile ? ' mobile' : '')}>
    <div className={'card-rb' + (props.className ? ` ${props.className}` : '')} onClick={props.onClick} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
      {props.children}
    </div>
  </div>
);

Card.propTypes = {
  id: PropTypes.string,
  size: PropTypes.string,
  spacing: PropTypes.number,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

export default Card;