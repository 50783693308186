import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { isMobileOnly, isTablet } from 'react-device-detect';
import GridCol from '../../MasterComponents/Grid/GridCol';
import Button from '../../MasterComponents/Button/Button';
import './Blog.scss';
class Blog extends Component {

 clickReadMore = (blogLink) => {
    if(blogLink !== '') {
      const element = document.createElement('a');
      element.href = blogLink;
      element.target = '_blank';
      element.click();
    }
}

  render() {
    const { blogLink } = this.props;
    const height = isMobileOnly ? '4250px' : (isTablet ? '7000px': '2350px');
    const desktopStyle = {
      position: 'absolute',
      top: '90%',
      left: '45%',
      zIndex:'1000'
    }
    const mobileStyle = {
      position: 'absolute',
      top: '94%',
      left: '33%',
      zIndex:'1000'
    }
    const tabletStyle = {
      position: 'absolute',
      top: '96%',
      left: '43%',
      zIndex:'1000'
    }
    return (
      <Fragment>
        <GridCol isFluid={true} className='blog p-0'>
          <GridCol size={12} style={{top:'-70px'}} className='p-0'>
            <iframe  title='Blog' src={blogLink} width='100%' height={height} frameBorder='0' scrolling='no'></iframe>
          </GridCol>
        </GridCol>
        <GridCol style={ isMobileOnly ? mobileStyle : (isTablet ? tabletStyle : desktopStyle) }>
          <Button size='sm' className='clickable btn-learn-blog' text='READ MORE' onClick={()=> this.clickReadMore(blogLink)}  />
        </GridCol>
      </Fragment>
    
    )
  }
}

const mapStateToProps = state => {
  return {
    blogLink: state.company.text.blogLink,
  }
}

export default connect(mapStateToProps)(Blog);
