import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconEdit = props => (
  <svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Designs" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="38---Location-Dropdown" transform="translate(-855.000000, -209.000000)" fill={!props.isActive ? props.color : props.activeColor}>
        <g id="pop-up" transform="translate(470.000000, 92.000000)">
          <g id="add-distributor" transform="translate(45.000000, 95.000000)">
            <g id="icons/edit" transform="translate(340.000000, 22.000000)">
              <path d="M0.516129032,15.483871 C0.57083871,15.483871 0.625548387,15.4750968 0.679225806,15.4575484 L5.13496774,13.972129 C5.24283871,13.9576774 5.3476129,13.9086452 5.43019355,13.8255484 L5.4363871,13.8193548 C5.44154839,13.8147097 5.44670968,13.8095484 5.45135484,13.8043871 L12.2890323,6.96670968 C12.2890323,6.96670968 12.2895484,6.96670968 12.2895484,6.96619355 L15.332129,3.9236129 C15.5339355,3.72180645 15.5339355,3.3956129 15.332129,3.19380645 L12.2895484,0.151225806 C12.0877419,-0.0505806452 11.7615484,-0.0505806452 11.5597419,0.151225806 L9.29187097,2.41858065 C9.29187097,2.41858065 9.29187097,2.41858065 9.29187097,2.41858065 L1.65832258,10.0531613 C1.65780645,10.0531613 1.65780645,10.0536774 1.65729032,10.0541935 C1.65625806,10.0547097 1.65574194,10.0557419 1.65470968,10.0567742 C1.57419355,10.1388387 1.52670968,10.2415484 1.51225806,10.347871 L0.0268387097,14.8036129 C-0.0350967742,14.9889032 0.0134193548,15.1938065 0.151741935,15.3316129 C0.249290323,15.4312258 0.381419355,15.483871 0.516129032,15.483871 Z M1.7243871,12.9754839 C1.92103226,13.0219355 2.09703226,13.1107097 2.23535484,13.2490323 C2.37367742,13.3873548 2.46193548,13.563871 2.5083871,13.76 L1.33264516,14.1517419 L1.7243871,12.9754839 Z M11.9251613,1.24593548 L14.2374194,3.55819355 L12.6993548,5.09625806 L10.3865806,2.78348387 L11.9251613,1.24593548 Z M9.24748387,3.9236129 L9.65729032,3.51380645 L11.9695484,5.82658065 L4.80051613,12.9956129 L2.99716129,13.5963871 C2.92541935,13.3259355 2.7963871,13.0807742 2.59922581,12.8836129 C2.40206452,12.6864516 2.15690323,12.5574194 1.88645161,12.4856774 L2.48722581,10.6823226 L9.24748387,3.9236129 Z" id="Shape"></path>
              <path d="M4.74219355,10.9997419 C4.80825806,10.9997419 4.87432258,10.9744516 4.92490323,10.9243871 L9.89419355,5.95509677 C9.99483871,5.85445161 9.99483871,5.69083871 9.89419355,5.59019355 C9.79354839,5.48954839 9.62993548,5.48954839 9.52929032,5.59019355 L4.56,10.5594839 C4.45935484,10.660129 4.45935484,10.8237419 4.56,10.9243871 C4.61006452,10.9744516 4.67612903,10.9997419 4.74219355,10.9997419 Z" id="Path"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

IconEdit.propTypes = {
  color: PropTypes.string,
  activeColor: PropTypes.string,
  isActive: PropTypes.bool,
  size: PropTypes.number,
};

IconEdit.defaultProps = {
  activeColor: config.colors.rbYellow,
  color: config.colors.rbWhite,
  isActive: false,
  size: 16,
};

export default IconEdit;