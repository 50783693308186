import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getItemIntelFilterCheckIconColor } from '../../../config/Company/CompanyHelper'
import GridCol from '../../../MasterComponents/Grid/GridCol'
import GridRow from '../../../MasterComponents/Grid/GridRow'
import IconCheck from '../../../MasterComponents/Icons/IconCheck'
import Modal from '../../../MasterComponents/Modals/Modal'
import ModalBody from '../../../MasterComponents/Modals/ModalBody'
import ModalHeader from '../../../MasterComponents/Modals/ModalHeader'
import { findModal } from '../../../MasterComponents/Modals/ModalsActions'

export class ItemFiltersModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      filterOptions: this.props.filterOptions,
    };
  }

  onSelectOption(value) {
    const { modals } = this.props;
    findModal(modals, 'itemFiltersModal').close();
    this.props.onSelectFilter(value);
  }

  render() {
    const { filters, companyId } = this.props;
    const activeFilter = filters && filters.itemFilter ? filters.itemFilter : this.state.filterOptions[0].value;
    const itemIntelFilterCheckIconColor = getItemIntelFilterCheckIconColor(companyId);
    return (
      <Modal id='itemFiltersModal' size='100'>
        <ModalHeader noBorder={true}></ModalHeader>
        <ModalBody className='flex-column'>
          <GridRow className='px-3'>
            <GridCol className='text-size-14'>
              {this.state.filterOptions.map((option) =>
                <GridRow key={option.value} className='py-2' justify='between' onClick={this.onSelectOption.bind(this, option)}>
                  {option.label}
                  {activeFilter === option.value && <IconCheck color={itemIntelFilterCheckIconColor} />}
                </GridRow>
              )}
            </GridCol>
          </GridRow>
        </ModalBody>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    filters: state.itemIntel.filters,
    companyId: state.company.id
  };
};

export default connect(mapStateToProps)(ItemFiltersModal);
