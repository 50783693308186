import React, { Component, Fragment } from 'react';

import GridRow from '../../MasterComponents/Grid/GridRow';
import GridCol from '../../MasterComponents/Grid/GridCol';
import Input from '../../MasterComponents/Input/Input';
import InputLabel from '../../MasterComponents/InputLabel/InputLabel';
import Button from '../../MasterComponents/Button/Button';
import IconSearch from '../../MasterComponents/Icons/IconSearch';
import config from '../../config/config';
import  './ClaimCash.scss';

class ClaimCashLookUp extends Component {

    showRebateAmount = () => {
        const { merchant, code} = this.refs;
        const mn = merchant.isValid();
        const vc = code.isValid();
        if(mn && vc) {
            this.props.showRebateAmount();
        }
    }

    render() {
        const {steps, step1Input, changeHandlerStep1} = this.props;
        return (
            <Fragment>
                <GridRow>
                    <GridCol className='text-center'>
                        <GridCol className='py-3'>
                            <IconSearch color={config.colors.secondary} size={30} />
                        </GridCol>
                        <GridCol className='pb-5 claim-text1'>
                            Look Up for Rebates
                        </GridCol>
                    </GridCol>
                </GridRow>
                { steps.step1.status && 
                    <Fragment>
                        <GridRow className='line-form'>
                            <GridCol className='form-group'>
                                <InputLabel text='Location Name *'/>
                                <Input ref='merchant' type='text' name='merchant' value={step1Input.merchant} onChange={changeHandlerStep1} validate min={3} max={100} placeholder='Enter location name' />
                            </GridCol>
                        </GridRow>
                        <GridRow className='line-form'>
                            <GridCol className='form-group'>
                                <InputLabel text='Location Phone Number *'/>
                                <Input ref='phoneNumer' type='tel' name='phoneNumer' value={step1Input.phoneNumer} onChange={changeHandlerStep1} validate min={3} max={50} placeholder='Enter location phone number' />
                            </GridCol>
                        </GridRow>
                        <GridRow className='line-form'>
                            <GridCol className='form-group'>
                                <InputLabel text='Payment ID *'/>
                                <Input ref='code' type='text' name='code' value={step1Input.code} onChange={changeHandlerStep1} validate min={3} max={100} placeholder='Enter Payment ID' />
                            </GridCol>
                        </GridRow>
                        <GridRow className='pb-5 pt-3'>
                            <GridCol>
                                <Button type='button' variant='primary' size='' text='SHOW REBATE AMOUNT' onClick={this.showRebateAmount}></Button>
                            </GridCol>
                        </GridRow>
                    </Fragment>
                }
            </Fragment>
            
        );
    }
}

export default ClaimCashLookUp;