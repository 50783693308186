import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import '../FoodBam/FoodBamAddDistributors.scss';
import config from '../../config/config';
import { spinner } from '../../MasterComponents/Spinner/SpinnerActions';
import GridContainer from '../../MasterComponents/Grid/GridContainer';
import GridRow from '../../MasterComponents/Grid/GridRow';
import GridCol from '../../MasterComponents/Grid/GridCol';
import Step from '../../MasterComponents/Step/Step';
import Dash from '../../MasterComponents/Dash/Dash';
import IconMarker from '../../MasterComponents/Icons/IconMarker';
import Input from '../../MasterComponents/Input/Input';
import InputLabel from '../../MasterComponents/InputLabel/InputLabel';
import Button from '../../MasterComponents/Button/Button';
import FoodBamApi from './FoodBamApi';

class FoodBamAddDistributors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validFields: true,
      notValidMessage: 'All DSR emails are required.',
      merchantDistributors: [],
      distributorsDsrEmails: []
    };
  }

  componentDidMount = () => {
    this.getUserMerchantDistributors();
  }

  getUserMerchantDistributors = () => {
    const { dispatch } = this.props;
    dispatch(spinner(true));
    FoodBamApi.getUserMerchantDistributors().then(({ data }) => {
      dispatch(spinner(false));
      const merchantDistributors = data.data;
      const distributorsDsrEmails = merchantDistributors.map((merchantDistributor) => {
        return { ['md-'+merchantDistributor.merchantId + '-' + merchantDistributor.distributorId]: '' };
      });
      this.setState({
        merchantDistributors,
        distributorsDsrEmails,
      });
    });
  }

  changeHandler = (value, name, e) => {
    const { distributorsDsrEmails } = this.state;
    this.setState({
      distributorsDsrEmails : {
        ...distributorsDsrEmails,
        [name]: value
      }
    });
  }

  dsrEmailRow = (merchantDistributor) => {
    const { distributorsDsrEmails } = this.state;
    const name = 'md-'+merchantDistributor.merchantId + '-' + merchantDistributor.distributorId;
    const value = distributorsDsrEmails[name] || '';

    return <GridRow className='line-form'>
      <GridCol className='form-group pb-2'>
        <InputLabel text='Distributor *' />
        <p className='mb-1'>{merchantDistributor.distributorNickname}</p>
        <InputLabel text='DSR Email *' />
        <Input
          type='email'
          ref={name} name={name}
          value={value} onChange={this.changeHandler}
          placeholder='admin@example.com'
          autocomplete=''
          data-platform-id={merchantDistributor.platform_id}
          data-dcn={merchantDistributor.dcn}
          data-parent-merchant-nickname={merchantDistributor.parentMerchantNickname}
          data-merchant-nickname={merchantDistributor.merchantNickname}
          data-distributor-nickname={merchantDistributor.distributorNickname} />
      </GridCol>
    </GridRow>
  }

  render() {
    const { validFields, notValidMessage, merchantDistributors } = this.state;

    const merchants = new Map();
    merchantDistributors.forEach(merchantDistributor => {
      if (merchants.has(merchantDistributor.merchantId)) {
        merchants.get(merchantDistributor.merchantId).push(merchantDistributor);
      } else {
        merchants.set(merchantDistributor.merchantId, [merchantDistributor]);
      }
    });

    return (
      <div className='foodbam-add-distributors'>
        <GridRow>
          <GridCol>
            <h1 className='text-center m-5'>START ORDERING ONLINE</h1>
          </GridCol>
        </GridRow>
        <GridRow>
          <GridCol className='steps mt-3 mb-5'>
            <Step status={'active'} text={1} />
            <Dash className='mx-5' />
            <Step status={'inactive'} text={2} />
            <Dash className='mx-5' />
            <Step status={'inactive'} text={3} />
          </GridCol>
        </GridRow>

        <GridContainer className='mb-5' isFluid={false}>
          <GridRow>
            <p className='enter-email'>Enter email for each merchant distributor pair</p>
          </GridRow>
            {
              Array.from(merchants.keys()).map(merchantId => {
                return <GridRow key={'row-'+merchantId} className='mb-4'><GridCol className={(isMobile ? 'col-12':'col-6') + ' border'}>
                {
                merchants.get(merchantId).map((merchantDistributor, index) => {
                  if (index === 0) {
                    return <Fragment key={merchantDistributor.merchantId + '-' + merchantDistributor.distributorId}>
                             <p className='merchant-nickname mt-3'><IconMarker className='icon-marker' color={config.colors.rbYellow} size={22} />{merchantDistributor.merchantNickname}</p>
                             {this.dsrEmailRow(merchantDistributor)}
                           </Fragment>;
                  } else {
                    return <Fragment key={merchantDistributor.merchantId + '-' + merchantDistributor.distributorId}>
                             {this.dsrEmailRow(merchantDistributor)}
                           </Fragment>;
                  }
                })
                }
                </GridCol></GridRow>
              })
            }

            <GridRow className='mb-4'><GridCol className={(isMobile ? 'col-12':'col-6')+' p-0'}>
              <Button type='button' variant='primary' size='' text='NEXT' onClick={this.handleSubmit} />
            </GridCol></GridRow>
            <GridRow className='mb-4'><GridCol className={(isMobile ? 'col-12':'col-6')}>
              <InputLabel className='text-red text-size-14'>{validFields ? '' : notValidMessage}</InputLabel>
            </GridCol></GridRow>

        </GridContainer>
      </div>
    );
  }

  handleSubmit = (e) => {
    e.preventDefault();

    let validFields = true;
    for (let input in this.refs) {
      validFields = this.refs[input].isValid() && validFields;
    }
    this.setState({validFields});
    if (!validFields) return;

    const { distributorsDsrEmails } = this.state;
    let merchants = [];
    for (let input in this.refs) {
      merchants.push({
        platform_id: this.refs[input].props['data-platform-id'],
        parent_merchant_nickname: this.refs[input].props['data-parent-merchant-nickname'],
        merchant_nickname: this.refs[input].props['data-merchant-nickname'],
        distributor_nickname: this.refs[input].props['data-distributor-nickname'],
        dcn: this.refs[input].props['data-dcn'],
        dsr: distributorsDsrEmails[input] || ''
      });
    }
    this.props.nextStepHandler(merchants);
  }

}

FoodBamAddDistributors.propTypes = {
  nextStepHandler: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {};
}

export default connect(mapStateToProps)(FoodBamAddDistributors);
