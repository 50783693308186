import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from '../../../MasterComponents/Modals/Modal';
import ModalHeader from '../../../MasterComponents/Modals/ModalHeader';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import { findModal } from '../../../MasterComponents/Modals/ModalsActions';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import Button from '../../../MasterComponents/Button/Button';

class StartOrderingOnlineModal extends Component {
  yes = () => {
    const { modals, yesHandler } = this.props;
    yesHandler();
    findModal(modals, 'startOrderingOnlineModal').close();
  }
  no = () => {
    const { modals } = this.props;
    findModal(modals, 'startOrderingOnlineModal').close();
  }
  render() {
    return(
      <Modal id='startOrderingOnlineModal' centered>
        <ModalHeader closeButton={false} noBorder={true}>
          Would you like to start ordering online?
        </ModalHeader>
        <ModalBody>
          <GridRow>
            <GridCol>
              <Button onClick={this.no} variant='white' text='No' />
            </GridCol>
            <GridCol>
              <Button onClick={this.yes} variant='primary' text='Yes' />
            </GridCol>
          </GridRow>
        </ModalBody>
      </Modal>
    )
  }
}

StartOrderingOnlineModal.propTypes = {
  yesHandler: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
  };
}

export default connect(mapStateToProps)(StartOrderingOnlineModal);
