export default function reducer(state = {
    spinner: window.location.pathname === '/dashboard/home',
    spinnerImage: window.location.pathname === '/dashboard/home',
    spinnerLoader: 1,
}, action) {
    switch (action.type) {
        case 'SET_SPINNER':
            return { ...state, spinner: action.payload };
        case 'SET_SPINNER_IMAGE':
                return { ...state, spinnerImage: action.payload, spinnerLoader:action.payloadLoader };
        default:
            return state;
    }
}
