export const showModal = (component, id, props) => {
  return {
    type: 'ADD_MODAL',
    payload: {
      Component: component,
      id: id,
      props: props
    }
  }
};
export const updateModalProps = (id, props) => {
  return {
    type: 'UPDATE_PROPS',
    payload: {
      id: id,
      props: props
    }
  }
};
export const saveParent = (id, component) => {
  return {
    type: 'SAVE_PARENT',
    payload: {
      parent: component,
      id: id
    }
  }
};
export const destroyModal = (id) => {
  return {
    type: 'REMOVE_MODAL',
    payload: id
  }
};
export const canGoBack = (bool) => {
  return {
    type: 'CAN_GO_BACK',
    payload: bool
  }
};
export const findModal = (state, id) => {
  for (let i = 0; i < state.length; i++) {
    const m = state[i];
    if (m.id === id) {
      return m.parent;
    }
  }
  return null;
}