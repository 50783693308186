const p = process.env;
export default function reducer(state = {
  loading: true,
  id: parseInt(p.REACT_APP_COMPANY_ID),
  title: p.REACT_APP_COMPANY_TITLE,
  appUrl: p.REACT_APP_APP_URL,
  nickname: p.REACT_APP_COMPANY_NICKNAME,
  homepage: null,
  code: p.REACT_APP_COMPANY_CODE,
  abbreviation: p.REACT_APP_COMPANY_ABBREVIATION,
  externalBrand: parseInt(p.REACT_APP_COMPANY_EXTERNAL_BRAND),
  maxLocations: parseInt(p.REACT_APP_COMPANY_MAX_LOCATIONS),
  externalBrandCode: p.REACT_APP_COMPANY_EXTERNAL_BRAND_CODE ? p.REACT_APP_COMPANY_EXTERNAL_BRAND_CODE : null,
  defaultChannelPartner: p.REACT_APP_COMPANY_DEFAULT_CHANNEL_PARTNER_ID ? {
    id: p.REACT_APP_COMPANY_DEFAULT_CHANNEL_PARTNER_ID,
    description: p.REACT_APP_COMPANY_DEFAULT_CHANNEL_PARTNER_DESCRIPTION,
  } : null,
  isPorchdoorRedirect: parseInt(p.REACT_APP_COMPANY_IS_PORCHDOOR_REDIRECT),
  logo: {
    main: p.REACT_APP_COMPANY_MAIN_LOGO,
    dashboard: p.REACT_APP_COMPANY_DASHBOARD_LOGO,
    dashboardFooter: p.REACT_APP_COMPANY_DASHBOARD_FOOTER_LOGO,
  },
  color: {
    mainButton: p.REACT_APP_COMPANY_COLOR_MAIN_BUTTON,
    mainButtonBorder: p.REACT_APP_COMPANY_COLOR_MAIN_BUTTON_BORDER,
    topbar: p.REACT_APP_COMPANY_COLOR_TOPBAR,
    dashboardTopbar: p.REACT_APP_COMPANY_COLOR_DASHBOARD_TOPBAR,
    dashboardTopbarLink: p.REACT_APP_COMPANY_COLOR_DASHBOARD_TOPBAR_LINK,
    dashboardFooter: p.REACT_APP_COMPANY_COLOR_DASHBOARD_FOOTER,
    primary: p.REACT_APP_COMPANY_COLOR_PRIMARY,
    secondary: p.REACT_APP_COMPANY_COLOR_SECONDARY,
  },
  country: "",
  companyBrand: {
    name: p.REACT_APP_COMPANY_BRAND_NAME,
    code: p.REACT_APP_COMPANY_BRAND_CODE
  },
  homeVideo: p.REACT_APP_COMPANY_HOME_VIDEO ? p.REACT_APP_COMPANY_HOME_VIDEO : null,
  dashboardMedia: {
    logo: p.REACT_APP_COMPANY_DASHBOARD_HEADER_LOGO ? p.REACT_APP_COMPANY_DASHBOARD_HEADER_LOGO : null,
    background: p.REACT_APP_COMPANY_DASHBOARD_HEADER_BACKGROUND ? p.REACT_APP_COMPANY_DASHBOARD_HEADER_BACKGROUND : null,
  },
  registerLinks: [],
  partners: [],
  partnerSelected: null,
  brandSelected: null,
  formType: null,
  registerFormType: null,
  displayBeyondPrime: false,
  displayExistingUser: false,
  displayClaimCheck: false,
  displayPopularDistributors: false,
  displayReports: false,
  displayContractManagement: false,
  displayFoodCostManagement: false,
  text: {
    loginText: "",
    loginBullets: [],
    registerText: "",
    registerBullets: [],
    selfRegisterText: "",
    selfRegisterBullets: [],
    selfRegisterTitle: 'Hello, Old Friend',
    selfRegisterSubtitle: '',
    terminationDate: 'Terminatin Date *',
    reportingLocations: 'How many locations will be reporting *',
    copyright: '2019',
    dashboardHeader: '',
    activeDistributorCashBack : '["Your rebates are on their way!","You are ready to start claiming your rebates. Order as usual, we will do the rest."]',
    termsAndConditions : '',
    contactInformationHeader: 'Business Contact Information',
    businessAddress: 'Location Address *',
    existingPlatformId: 'Dining Alliance Platform ID *',
    existingSalesRep: 'Sales Rep',
    blogLink: '',
  },
  socialLinks: {
    facebook: null,
    twitter: null,
    linkedIn: null,
    instagram: null
  },
  segment: null,
  subsegment: null
}, action) {
  switch (action.type) {
    case 'UPDATE_COLORS':
      return {
        ...state,
        color: action.payload
      }
    case 'UPDATE_REGISTER_LINKS':
      return {
        ...state,
        registerLinks: action.payload
      }
    case 'UPDATE_PARTNERS':
      return {
        ...state,
        partners: action.payload,
        partnerSelected: action.payloadSelected
      }
    case 'UPDATE_BRAND_SELECTED':
      return {
        ...state,
        brandSelected: action.payload,
        formType: action.payloadType
      }
    case 'UPDATE_FORM_TYPE':
      return {
        ...state,
        formType: action.payload
      }
    case 'UPDATE_REGISTER_FORM_TYPE':
      return {
        ...state,
        registerFormType: action.payload
      }
    case 'UPDATE_SEGMENT':
      return {
        ...state,
        segment: action.payload
      }
    case 'UPDATE_SUBSEGMENT':
      return {
        ...state,
        subsegment: action.payload
      }
    case 'UPDATE_TEXT':
      return {
        ...state,
        text: {
          ...state.text,
          [action.text]: action.payload
        }
      }
    case 'UPDATE_DISPLAY':
      return {
        ...state,
        displayCashBack: action.payload.cashBack,
        displayInsight: action.payload.insight,
        displayItemIntel: action.payload.itemIntel,
        displayClaimCheck: action.payload.claimCheck,
        displayBeyondPrime: action.payload.beyondPrime,
        displayFoodbam: action.payload.foodbam,
        displayExistingUser: action.payload.existingUser,
        displayPopularDistributors: action.payload.popularDistributors,
        displayReports: action.payload.reports,
        displayContractManagement: action.payload.contractManagement,
        displayFoodCostManagement: action.payload.displayFoodCostManagement
      }
    case 'SET_COMPANY_LOADING':
      return {
        ...state,
        loading: action.payload
      }
    case 'SET_SOCIAL_LINKS':
      return {
        ...state,
        socialLinks: action.payload
      }
    case 'SET_HOMEPAGE':
      return {
        ...state,
        homepage: action.payload
      }
    case 'SET_COUNTRY':
      return {
        ...state,
        country: action.payload
      }
    default:
      return state;
  }
}
