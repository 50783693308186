import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { invoiceSetFiles, invoiceSetSendDisabled } from '../Reducer/InvoiceScanningAction';
import { spinner } from '../../../MasterComponents/Spinner/SpinnerActions';
import { showModal, findModal } from '../../../MasterComponents/Modals/ModalsActions';
import InvoiceSanningApi from '../InvoiceSanningApi';
import AlertModal from '../../../MasterComponents/AlertModal.js/AlertModal';

const useInvoiceScanningModal = (props) => {
  const dispatch = useDispatch();
  const companyId = useSelector(({company}) => company.id);
  const { modals, propsModal } = props;
  const { getInvoices, isEdit, invoiceDetail, optionsLocationModal } = propsModal;
  const { invoiceId, locationId, name } = invoiceDetail;
  const [invoiceName, setInvoiceName] = useState(name);
  const [locationModal, setLocationModal] = useState(null);
  const fileInput = useRef(null);
  const filesArray = useSelector(({invoice}) => invoice.filesArray);
  const BYTES_PER_MB = 1024 ** 2;
  const MAX_IN_MB = 10;

  useEffect(() =>{
    findLocation();
  }, []);

  const findLocation = () => {
    if(locationId !== null) {
      const obj = optionsLocationModal.find((o) => o.value === locationId);
      setLocationModal(obj);
    }
  }

  const onClickUpload = (e) => {
    fileInput.current.click();
  }
  
  const onChangeFileInput = () => {
    const { current } = fileInput;
    let arr = [...filesArray];
    if(arr.length === 0) dispatch(invoiceSetSendDisabled(true));
    if (current && current.files.length > 0) {
      for (let file of current.files) {
        const fileSizeInBytes = file.size;
        const fileSizeInMb = fileSizeInBytes / (BYTES_PER_MB);
        if(fileSizeInMb > MAX_IN_MB) {
          console.log('The file exceeds the allowed size');
          continue;
        }
        arr.push(file);
      }
      dispatch(invoiceSetFiles(arr));
      setTimeout(() =>{
        dispatch(invoiceSetSendDisabled(false))
      }, 1500);
    }
  }

  const onClickDelete = (index) => {
    dispatch(invoiceSetFiles(filesArray.filter((f, i) => i !== index)));
  }

  const onChangeName = (valName) => {
    setInvoiceName(valName)
  }

  const onChangeLocation = (val) => {
    setLocationModal(val);
  }
  
  const onClickSend = () => {
    let formData = new FormData();
    if (Array.isArray(filesArray)) {
      formData.append('name', invoiceName);
      formData.append('merchantId', locationModal.value);
      formData.append('companyId', companyId);
      formData.append('fileCount', filesArray.length);
      filesArray.forEach((file, i) => {
        formData.append('file' + i, file);
      });
    }
    if(isEdit) {
      dispatch(spinner(true));
      InvoiceSanningApi.updateInvoice(invoiceId, formData).then(({data}) => {
        dispatch(spinner(false));
        getInvoices('', null, null, 0);
        findModal(modals, 'idUploadInvoiceModal').close();
      }).catch(err => {
        const message = err.data.data.message || 'Error, it could not send';
        const props = {
          message : message,
          buttonText: 'Ok'
        }
        dispatch(spinner(false));
        findModal(modals, 'idUploadInvoiceModal').close();
        dispatch(showModal(AlertModal, 'idAlertModal', {...props}));
      });
    } else {
      dispatch(spinner(true));
      InvoiceSanningApi.saveInvoice(formData).then(({data}) => {
        dispatch(spinner(false));
        getInvoices('', null, null, 0);
        findModal(modals, 'idUploadInvoiceModal').close();
      }).catch(err => {
        const message = err.data.data.message || 'Error, it could not send';
        const props = {
          message : message,
          buttonText: 'Ok'
        }
        dispatch(spinner(false));
        findModal(modals, 'idUploadInvoiceModal').close();
        dispatch(showModal(AlertModal, 'idAlertModal', {...props}));
      });
    }
  }

  const onClose = () => {
    findModal(modals, 'idUploadInvoiceModal').close();
  }

  return {
    onClickUpload,
    onChangeFileInput,
    onChangeLocation,
    onClickDelete,
    onChangeName,
    onClose,
    onClickSend,
    fileInput,
    locationModal,
    invoiceName,
    BYTES_PER_MB
  }
}

export default useInvoiceScanningModal;