import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconSuccess = props => (
    <svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Status" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="3---Homepage" transform="translate(-252.000000, -473.000000)">
                <g id="rebates" transform="translate(-1.000000, 220.000000)">
                    <g id="online-distributor" transform="translate(26.000000, 80.000000)">
                        <g id="icons/checkmark" transform="translate(227.000000, 173.000000)">
                            <rect id="Rectangle" fill={!props.isActive ? props.color : props.activeColor} x="0" y="0" width="24" height="24" rx="12"></rect>
                            <g id="icons/check" transform="translate(7.000000, 8.000000)" fill="#FFFFFF">
                                <path d="M9.127,1.123 C9.361,1.356 9.361,1.737 9.127,1.971 L4.221,6.877 C4.104,6.994 3.95,7.053 3.797,7.053 C3.644,7.053 3.49,6.994 3.373,6.877 L0.873,4.377 C0.639,4.144 0.639,3.763 0.873,3.529 C1.107,3.295 1.486,3.295 1.721,3.529 L3.797,5.604 L8.279,1.123 C8.514,0.889 8.893,0.889 9.127,1.123 Z" id="Shape"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

IconSuccess.propTypes = {
    color: PropTypes.string,
    activeColor: PropTypes.string,
    isActive: PropTypes.bool,
    size: PropTypes.number,
};
  
IconSuccess.defaultProps = {
    activeColor: config.colors.rbYellow,
    color: config.colors.rbWhite,
    isActive: false,
    size: 16,
};
  
export default IconSuccess;