import React, { Component } from 'react'
import Modal from '../../../MasterComponents/Modals/Modal';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import Button from '../../../MasterComponents/Button/Button';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import { findModal } from '../../../MasterComponents/Modals/ModalsActions';
import { connect } from 'react-redux';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../MasterComponents/Grid/GridCol';

class GoodNewsModal extends Component {
  closeModal = () => {
    const { modals } = this.props;
    findModal(modals, 'goodNewsModal').close();
  }
  render() {
    const { logo, activeDistributorCashBack } = this.props;
    const Text1 = activeDistributorCashBack[0];
    const Text2 = activeDistributorCashBack[1];
    const fontSizeText1 = isMobileOnly ? '1.4em' : (isTablet ? '2em': '1.8em');
    const fontSizeText2 = isMobileOnly ? '0.9em' : (isTablet ? '1.5em': '1.1em');
    const heightImg = isMobileOnly ? '90px' : (isTablet ? '150px': '120px');

    return (
      <Modal id='goodNewsModal' centered={isMobile} >
        <ModalBody className={(isMobileOnly ? 'modal-news-mobile' : (isTablet ? 'modal-news-tablet' : 'modal-news-desktop') ) + ' text-center'} >
          <GridRow>
              <GridCol className='text-center modal-news-text modal-news-line-bottom' style={{fontSize : fontSizeText1}}>
                  {Text1}
              </GridCol>
          </GridRow>
          <GridRow>
              <GridCol className={'text-center ' + (isMobileOnly ? 'py-2 ' : (isTablet ? 'my-5 py-4' : 'pt-5 pb-4')) } >
                  <img src={logo} alt='logo' height={heightImg}></img>
              </GridCol>
          </GridRow>
          <GridRow>
              <GridCol className='text-center modal-news-text2 modal-news-line-bottom' style={{fontSize : fontSizeText2}}>
                  {Text2}
              </GridCol>
          </GridRow>
          <GridRow>
              <GridCol className='text-center pt-4'>
                <Button variant='primary' onClick={this.closeModal} className='clickable' size='lg' text='OK' />
              </GridCol>
          </GridRow>
        </ModalBody>
      </Modal>
    );
  }
};

const mapStateToProps = state => {
  return {
      logo: state.company.dashboardMedia.logo,
      activeDistributorCashBack: state.company.text.activeDistributorCashBack,
  }
}

export default connect(mapStateToProps)(GoodNewsModal);