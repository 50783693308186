import React, { Component } from 'react';
import Bullet from '../../MasterComponents/Bullet/Bullet';
import LogoMainCompany from '../../MasterComponents/Logos/LogoMainCompany';

class ChangePasswordSidebar extends Component {
    goTo = (e) => {
        e.preventDefault();
        this.props.history.push('/');
    };
    render() {
        return (
            <div className='left-change'>
                <div className='logo'>
                    <a href='/' onClick={this.goTo}><LogoMainCompany style={{maxWidth: '250px', maxHeight: '150px'}} /></a>                    
                </div>
                <div className='bullets'>
                    <Bullet text='Collect more cash back'/>
                    <Bullet text='Get access to Beyond Broadline Programs'/>
                    <Bullet text='See your restaurant insights'/>
                </div>                
            </div>
        )
    }
}

export default ChangePasswordSidebar;