export default function reducer(state = {
    insightsAll: {},
    insightsOpportunity: [],
    faqAll: [],
    newAll: []
  }, action) {
    switch (action.type) {
      case 'INSIGHTS_SET_ALL':
        return {
          ...state,
          insightsAll: action.payload
        };
      case 'INSIGHTS_SET_OPPORTUNITY':
        return {
          ...state,
          insightsOpportunity: action.payload
        };
      case 'FAQ_SET_ALL':
        return {
          ...state,
          faqAll: action.payload
        };
      case 'NEW_SET_ALL':
        return {
          ...state,
          newAll: action.payload
        };
      default:
        return state;
    }
  }