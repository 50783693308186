import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { findModal } from '../../../MasterComponents/Modals/ModalsActions';
import Modal from '../../../MasterComponents/Modals/Modal';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import Button from '../../../MasterComponents/Button/Button';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import InputLabel from '../../../MasterComponents/InputLabel/InputLabel';

class DistributorNonEntegraModal extends Component {
  closeModal = () => {
    const { modals } = this.props;
    findModal(modals, 'distributorNonEntegraModal').close();
  }
  render() {
    return (
      <Modal id='distributorNonEntegraModal' centered={isMobile} >
        <ModalBody className='modal-reports-map text-center'>
          <GridRow className='my-3'>
            <GridCol className='text-center d-flex justify-content-center'>
              <InputLabel className='modal-reports-subtitle text-yellow text-size-24'>Attention!</InputLabel><br/>
            </GridCol>
          </GridRow>
          <GridRow>
            <GridCol>
              <InputLabel className='modal-reports-text mt-3'>
                You can submit purchases for this distributor, but you are unlikely to earn rebates.
              </InputLabel>
            </GridCol>
          </GridRow>
          <GridRow className='mt-4'>
            <GridCol>
                <Button variant='yellow' onClick={this.closeModal} className='clickable' size='lg' text='OK' />
            </GridCol>
          </GridRow>
        </ModalBody>
      </Modal>
    );
  }
};

export default DistributorNonEntegraModal;