import { Component } from 'react';
import { withRouter } from 'react-router-dom';

class BeforeUnloadListener extends Component {
  componentDidMount = () => {
    window.addEventListener('beforeunload', this.trackActions);

    const { history } = this.props;
    history.listen((location) => {
      //console.log('location', location);
    });
  }
  trackActions = () => {
    const { pathname } = window.location;
    const authKey = localStorage.getItem('authKey');
    const isSignUpPage = pathname.startsWith('/register') || pathname.startsWith('/join-dining-alliance-for-free-today');
    if (analytics && !authKey && isSignUpPage) { // eslint-disable-line
      analytics.track('SignupAbandonment', {email: sessionStorage.getItem('signUpEmail')}); // eslint-disable-line
    }

    if (analytics && sessionStorage.getItem('trackDistributorIssue') === '1') { // eslint-disable-line
      let activeMerchant = null;
      try {
        activeMerchant = JSON.parse(localStorage.getItem('activeMerchant')).nickname;
      } catch {
      }
      const email = localStorage.getItem('email');
      analytics.track('DistributorIssue', {email, activeMerchant}); // eslint-disable-line
      sessionStorage.setItem('trackDistributorIssue', '0');
    }
  }
  render() {
    return (
      null
    );
  }
}

export default withRouter(BeforeUnloadListener);
