import React from 'react';
import { HiArrowNarrowDown, HiArrowNarrowUp } from 'react-icons/hi';

const ContractSortArrow = ({asc, show}) => {
	if (!show) {
		return null;
	}
	if (asc) {
		return <HiArrowNarrowDown />
	}
	return <HiArrowNarrowUp />
};

export default ContractSortArrow;
