import { apiInstance } from '../../config/tokenApi';

const getInsights = (startDate, endDate, platformIds) => {
    const params = {
        startDate: startDate,
        endDate: endDate,
        platformIds: platformIds
    }
    return apiInstance.post('/insight', params, {
        timeout: 1000 * 60 * 20
    });
};

const downloadInsights = (startDate, endDate, platformIds) => {
    const params = {
        startDate: startDate,
        endDate: endDate,
        platformIds: platformIds
    };
    const adminToken = localStorage.getItem('authKeyAnalyst');
    return apiInstance.post('insight/download', params, {
        headers: {
            'Authorization': `Bearer ${adminToken}`
        }
    });
};

const DeleteInsight = (params) => {
    return apiInstance.post('/insight/delete-match', params);
};

const getOpportunity = (startDate, endDate, items, platformIds) => {
    const params = {
        startDate: startDate,
        endDate: endDate,
        opportunityItems: items,
        platformIds: platformIds
    };
    return apiInstance.post('insight/opportunity', params);
};

const swapItem = (original, match, platformIds) => {
    const params = {
        originalProductId: original.product_id,
        originalProductDescription: original.description,
        originalMin: original.min,
        matchMin: match.min,
        matchProductId: match.product_id,
        matchProductDescription: match.description,
        matchProductQuantity: match.quantity,
        matchManufacturerPlatformId: match.manufacturerPlatformId,
        saving: match.annualSaving,
        rebate: match.rebate,
        platformIds: platformIds,
    };
    return apiInstance.post('insight/swap-item', params);
};

const sampleItem = (original, match, platformIds) => {
    const params = {
        originalProductId: original.product_id,
        originalProductDescription: original.description,
        originalMin: original.min,
        matchMin: match.min,
        matchProductId: match.product_id,
        matchProductDescription: match.description,
        matchProductQuantity: match.quantity,
        matchManufacturerPlatformId: match.manufacturerPlatformId,
        saving: match.annualSaving,
        rebate: match.rebate,
        platformIds: platformIds,
    };
    return apiInstance.post('insight/sample-item', params);
};

const saveScheduleDemo = (annualizedPurchases) => {
  return apiInstance.post('insight/schedule-insidetrack', { annualizedPurchases });
};

export default {
    getInsights,
    downloadInsights,
    DeleteInsight,
    getOpportunity,
    swapItem,
    sampleItem,
    saveScheduleDemo
};
