import React from 'react';
import { isMobile } from 'react-device-detect';
import { Row, Col } from 'reactstrap';
import config from '../../config/config';

const ReportsViewHeader = () => {
  if(isMobile) {
    return (
      <Row className='mx-2'>
        <Col className='text-center text-size-22 py-3 border'>
          Reports
        </Col>
      </Row>
    )
  }
  return (
    <Row className='py-2' style={{backgroundColor: config.colors.rbGreyNew}}>
      <Col xs={8} className='pl-5'>
        Report Name
      </Col>
      <Col xs={2}>
        Date
      </Col>
      <Col>
      </Col>
    </Row>
  )
}

export default ReportsViewHeader;
