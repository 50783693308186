import React from 'react';
import PropTypes from 'prop-types';

const GridColSplit = props => (
  <div id={props.id} className={(props.className ? 'w-100 '+ props.className : 'w-100')} onMouseLeave={props.onMouseLeave} onMouseEnter={props.onMouseEnter} onClick={props.onClick} tabIndex={props.tabIndex} onBlur={props.onBlur} onFocus={props.onFocus}>
    {props.children}
  </div>
);

GridColSplit.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func
};

export default GridColSplit;