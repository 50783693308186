import React, { Component, Fragment } from "react";
import PropTypes from 'prop-types';
import Step from '../../../MasterComponents/Step/Step';
import Dash from '../../../MasterComponents/Dash/Dash';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import InputLabel from '../../../MasterComponents/InputLabel/InputLabel';
class SignUpFormHeader extends Component {
    render() {
        const { activeStep, isPorchDoor, maxSteps, hideSteps, title, subtitle } = this.props;
        let stepsArray = [];
        for (let i = 0; i < maxSteps; i++) {
            stepsArray.push({
                number: i+1,
                isActive: (i+1) === activeStep ? true : false,
                isAlreadyActive: (i+1) < activeStep ? true : false,
                isLast: (i+1) === maxSteps ? true : false
            });
        }
        const stepsLayout = stepsArray.map((step, i) =>
            <Fragment key={i}>
                <Step status={ (step.isActive ? ( isPorchDoor ? 'active be-blue-bg be-blue-border' : 'active') : (step.isAlreadyActive ? ( isPorchDoor ? 'done be-blue-bg be-blue-border' : 'done') : 'inactive') )} text={step.number}/>
                {!step.isLast ? <Dash /> : null}                
            </Fragment>
        )

        const headerLayout = 
            <Fragment>
                <GridRow>
                    <GridCol>
                        <InputLabel text={title} title className='title'/>
                    </GridCol>
                </GridRow>
                {
                    subtitle ?
                        <GridRow style={{padding: '0 3% 3%'}}>
                            <GridCol>
                                <InputLabel text={subtitle} className='text-size-16 subtitle' />
                            </GridCol>
                        </GridRow>
                    : null
                }
                <GridRow>
                    <GridCol className='steps'>
                        { hideSteps ? null : stepsLayout}
                    </GridCol>
                </GridRow>
            </Fragment>            
        ;

        return (            
            (activeStep <= maxSteps) ? headerLayout : null            
        )
    }

};
SignUpFormHeader.propTypes = {
    activeStep: PropTypes.number,
    title: PropTypes.string,
    maxSteps: PropTypes.number,
    hideSteps: PropTypes.bool
}

SignUpFormHeader.defaultProps = {
    maxSteps: 3,
    title: 'Welcome! Are you new or existing?'
};
export default SignUpFormHeader;
