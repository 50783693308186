/* global analytics */

// export default function Utils(errorOutputId) { // eslint-disable-line no-unused-vars
//     let self = this;
//     this.errorOutput = document.getElementById(errorOutputId);
//     const cv = window.cv;
//     const OPENCV_URL = 'opencv.js';
//     this.loadOpenCv = function(onloadCallback) {
//         let script = document.createElement('script');
//         script.setAttribute('async', '');
//         script.setAttribute('type', 'text/javascript');
//         script.addEventListener('load', () => {
//             if (cv.getBuildInformation)
//             {
//                 console.log(cv.getBuildInformation());
//                 onloadCallback();
//             }
//             else
//             {
//                 // WASM
//                 cv['onRuntimeInitialized'] = () => {
//                     console.log(cv.getBuildInformation());
//                     onloadCallback();
//                 }
//             }
//         });
//         script.addEventListener('error', () => {
//             self.printError('Failed to load ' + OPENCV_URL);
//         });
//         script.src = OPENCV_URL;
//         let node = document.getElementsByTagName('script')[0];
//         node.parentNode.insertBefore(script, node);
//     };

import config from "../config/config";

//     this.createFileFromUrl = function(path, url, callback) {
//         let request = new XMLHttpRequest();
//         request.open('GET', url, true);
//         request.responseType = 'arraybuffer';
//         request.onload = function(ev) {
//             if (request.readyState === 4) {
//                 if (request.status === 200) {
//                     let data = new Uint8Array(request.response);
//                     cv.FS_createDataFile('/', path, data, true, false, false);
//                     callback();
//                 } else {
//                     self.printError('Failed to load ' + url + ' status: ' + request.status);
//                 }
//             }
//         };
//         request.send();
//     };

//     this.loadImageToCanvas = function(url, cavansId) {
//         let canvas = document.getElementById(cavansId);
//         let ctx = canvas.getContext('2d');
//         let img = new Image();
//         img.crossOrigin = 'anonymous';
//         img.onload = function() {
//             canvas.width = img.width;
//             canvas.height = img.height;
//             ctx.drawImage(img, 0, 0, img.width, img.height);
//         };
//         img.src = url;
//     };

//     this.executeCode = function(textAreaId) {
//         try {
//             this.clearError();
//             let code = document.getElementById(textAreaId).value;
//             eval(code);
//         } catch (err) {
//             this.printError(err);
//         }
//     };

//     this.clearError = function() {
//         this.errorOutput.innerHTML = '';
//     };

//     this.printError = function(err) {
//         if (typeof err === 'undefined') {
//             err = '';
//         } else if (typeof err === 'number') {
//             if (!isNaN(err)) {
//                 if (typeof cv !== 'undefined') {
//                     err = 'Exception: ' + cv.exceptionFromPtr(err).msg;
//                 }
//             }
//         } else if (typeof err === 'string') {
//             let ptr = Number(err.split(' ')[0]);
//             if (!isNaN(ptr)) {
//                 if (typeof cv !== 'undefined') {
//                     err = 'Exception: ' + cv.exceptionFromPtr(ptr).msg;
//                 }
//             }
//         } else if (err instanceof Error) {
//             err = err.stack.replace(/\n/g, '<br>');
//         }
//         this.errorOutput.innerHTML = err;
//     };

//     this.loadCode = function(scriptId, textAreaId) {
//         let scriptNode = document.getElementById(scriptId);
//         let textArea = document.getElementById(textAreaId);
//         if (scriptNode.type !== 'text/code-snippet') {
//             throw Error('Unknown code snippet type');
//         }
//         textArea.value = scriptNode.text.replace(/^\n/, '');
//     };

//     this.addFileInputHandler = function(fileInputId, canvasId) {
//         let inputElement = document.getElementById(fileInputId);
//         inputElement.addEventListener('change', (e) => {
//             let files = e.target.files;
//             if (files.length > 0) {
//                 let imgUrl = URL.createObjectURL(files[0]);
//                 self.loadImageToCanvas(imgUrl, canvasId);
//             }
//         }, false);
//     };

//     function onVideoCanPlay() {
//         if (self.onCameraStartedCallback) {
//             self.onCameraStartedCallback(self.stream, self.video);
//         }
//     };

//     this.startCamera = function(resolution, callback, videoId) {
//         const constraints = {
//             'qvga': {width: {exact: 320}, height: {exact: 240}},
//             'vga': {width: {exact: 640}, height: {exact: 480}}};
//         let video = document.getElementById(videoId);
//         if (!video) {
//             video = document.createElement('video');
//         }

//         let videoConstraint = constraints[resolution];
//         if (!videoConstraint) {
//             videoConstraint = true;
//         }

//         navigator.mediaDevices.getUserMedia({video: videoConstraint, audio: false})
//             .then(function(stream) {
//                 video.srcObject = stream;
//                 video.play();
//                 self.video = video;
//                 self.stream = stream;
//                 self.onCameraStartedCallback = callback;
//                 video.addEventListener('canplay', onVideoCanPlay, false);
//             })
//             .catch(function(err) {
//                 self.printError('Camera Error: ' + err.name + ' ' + err.message);
//             });
//     };

//     this.stopCamera = function() {
//         if (this.video) {
//             this.video.pause();
//             this.video.srcObject = null;
//             this.video.removeEventListener('canplay', onVideoCanPlay);
//         }
//         if (this.stream) {
//             this.stream.getVideoTracks()[0].stop();
//         }
//     };
// };

const identifySegment = function() {
  const userId = localStorage.getItem('userid');
  if (userId && analytics) {
    const contactName = localStorage.getItem('contactName');
    const email = localStorage.getItem('email');
    const phoneNumber = localStorage.getItem('phoneNumber');
    const companyName = process.env.REACT_APP_COMPANY_NICKNAME;
    analytics.identify(userId, {contactName, email, phoneNumber, brand: companyName}); // eslint-disable-line
  }
};

const clearError = function(errorOutputId) {
    console.error(errorOutputId)
};

const startCamera = function(resolution, callback, videoId) {
    const constraints = {
        'qvga': {width: {exact: 320}, height: {exact: 240}, facingMode: 'environment'},
        'vga': {width: {exact: 640}, height: {exact: 480}, facingMode: 'environment'}
    };
    let video = document.getElementById(videoId);
    if (!video) {
        video = document.createElement('video');
    }

    let videoConstraint = constraints[resolution];
    if (!videoConstraint) {
        videoConstraint = true;
    }



    navigator.mediaDevices.getUserMedia({video: videoConstraint, audio: false})
        .then(function(stream) {
            video.srcObject = stream;
            const videoPlayButton = document.getElementById('videoPlay');
            videoPlayButton.click();
            //video.play();
            // self.video = video;
            // self.stream = stream;
            // self.onCameraStartedCallback = callback;
            video.addEventListener('canplay', callback.bind(video,stream), false);
        })
        .catch(function(err) {
            console.log('Camera Error: ' + err.name + ' ' + err.message);
        });
};


const stopCamera = function (videoId,callback) {
    let video = document.getElementById(videoId);
    if (video) {
        video.pause();
        video.removeEventListener('canplay', callback.bind(video,video.srcObject));
        if (video.srcObject) {
            video.srcObject.getVideoTracks()[0].stop();
        }
        video.srcObject = null;
    }
}

const sorArrayByKey = function (array, key, isDecending) {
    return array.sort((a,b) => {
        const x = a[key];
        const y = b[key];
        if (!isDecending)
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        else
            return ((x > y) ? -1 : ((x > y) ? 1 : 0));
    });
}

const round = function (value, decimals = 0) {
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

const getRandomColor = (i) => {
    const color = config.colorPalette;
    if(i > color.length) {
        const letters = '0123456789ABCDEF'.split('');
        let colorNew = '#';
        for (let j = 0; j < 6; j++) {
        colorNew += letters[Math.floor(Math.random() * 16)];
        }
        return colorNew;
    }
    return color[i]
}

const companiesByDomain = [
    {
        production: 'https://my.diningalliance.com',
        staging: 'https://staging-cashback.diningalliance.com',
        development: 'https://dev-cashback.diningalliance.com'
    },
    {
        production: 'https://mydiningalliance.com',
        staging: 'https://staging-cashback.diningalliance.com',
        development: 'https://dev-cashback.diningalliance.com'
    },
    {
        production: 'https://mydvpg.com',
        staging: 'https://staging.mydvpg.com',
        development: 'https://dev.mydvpg.com'
    },
    {
        production: 'https://www.mydvpg.com',
        staging: 'https://staging.mydvpg.com',
        development: 'https://dev.mydvpg.com'
    },
    {
        production: 'https://portal.consolidatedconcepts.net',
        staging: 'https://portal-staging.consolidatedconcepts.net',
        development: 'https://portal-dev.consolidatedconcepts.net'
    },
    {
        production: 'https://rebates.source1purchasing.com',
        staging: 'https://staging-rebates.source1purchasing.com',
        development: 'https://dev-rebates.source1purchasing.com'
    },
    {
        production: 'https://mysouthstar.com',
        staging: 'https://staging.mysouthstar.com',
        development: 'https://dev.mysouthstar.com'
    },
    {
        production: 'https://myngcra.com',
        staging: 'https://staging.myngcra.com',
        development: 'https://dev.myngcra.com'
    },
    {
        production: 'https://restaurantrebatehub.com',
        staging: 'https://staging.restaurantrebatehub.com',
        development: 'https://dev.restaurantrebatehub.com'
    },
    {
        production: 'https://myalliancepurchasing.com',
        staging: 'https://staging.myalliancepurchasing.com',
        development: 'https://dev.myalliancepurchasing.com'
    },
    {
        production: 'https://myradisson-marketplace.com',
        staging: 'https://staging.myradisson-marketplace.com',
        development: 'https://dev.myradisson-marketplace.com'
    },
    {
        production: 'https://mybestwestern-marketplace.com',
        staging: 'https://staging.mybestwestern-marketplace.com',
        development: 'https://dev.mybestwestern-marketplace.com'
    },
    {
        production: 'https://mypurchasesmaart.com',
        staging: 'https://staging.mypurchasesmaart.com',
        development: 'https://dev.mypurchasesmaart.com'
    },
    {
        production: 'https://myrbgportal.com',
        staging: 'https://staging.myrbgportal.com',
        development: 'https://dev.myrbgportal.com'
    },
    {
        production: 'https://valethospitality.com',
        staging: 'https://staging.valethospitality.com',
        development: 'https://dev.valethospitality.com'
    },
    {
        production: 'https://mysmartrebates.com',
        staging: 'https://staging.mysmartrebates.com',
        development: 'https://dev.mysmartrebates.com'
    },
    {
        production: 'https://primeservicesportal.com',
        staging: 'https://staging.primeservicesportal.com',
        development: 'https://dev.primeservicesportal.com'
    },
    {
        production: 'https://my.coolschoolprime.com',
        staging: 'https://staging.my.coolschoolprime.com',
        development: 'https://dev.my.coolschoolprime.com'
    },
    {
        production: 'https://beportal.restacct.com',
        staging: 'https://staging.beportal.restacct.com',
        development: 'https://dev.beportal.restacct.com'
    },
    {
        production: 'https://mysavemore.com',
        staging: 'https://staging.mysavemore.com',
        development: 'https://dev.mysavemore.com'
    },
    {
        production: 'https://www.mysavemore.com',
        staging: 'https://staging.mysavemore.com',
        development: 'https://dev.mysavemore.com'
    }
]

export {
    identifySegment,
    clearError,
    startCamera,
    stopCamera,
    sorArrayByKey,
    round,
    getRandomColor,
    companiesByDomain
}
