import React, { Component } from 'react';
import '../SignUp.scss';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import CompanyApi from '../../../config/Company/CompanyApi';
import { spinner } from '../../../MasterComponents/Spinner/SpinnerActions';
import { updatePartners } from '../../../config/Company/CompanyActions';
import SignUpPorchDoorChannelContent from './SignUpPorchDoorChannelContent';
import SignUpPorchDoorChannelSidebar from './SignUpPorchDoorChannelSidebar';

class SignUpPorchDoorChannel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            partnerSelected: null
        };
    };
    componentDidMount() {
        const { history } = this.props;
        this.isOnTheList().then((p) => {
            this.setState({
                loading: false,
                partnerSelected: p
            });
        }).catch(() => {
            // Not on list
            history.push('/');
        });
    };
    isOnTheList = () => {
        return new Promise ((resolve, reject) => {
            const { company } = this.props.match.params;
            const { dispatch } = this.props;
            let result = false;

            dispatch(spinner(true));
            CompanyApi.getOne(1, 'partners').then(({data}) => {
                const { partners } = data.data;
                dispatch(spinner(false));
                partners.forEach(p => {
                    if (p.abbreviation === company.toLowerCase() && p.type === 'channel') {
                        dispatch(updatePartners(partners, p));
                        result = p;
                    }
                });
                if (result) {
                    resolve(result);
                } else {
                    reject(false);
                }
            });
        });
    };
    getFavicon = () => {
        const { company } = this.props.match.params;
        const path = 'https://rebate-portal.s3.amazonaws.com/lops/';        
        const icon = `${path}${company.toLowerCase()}-ico.png`;
        return icon;
    };
    render() {
        const { history, dispatch } = this.props;
        const { loading, partnerSelected } = this.state;
        const contentProps = {
            history,
            dispatch,
            partner: partnerSelected
        }
        if (loading) {
            return null;
        }
        return (
            <div className='container-signup'>
                <Helmet>
                    <link rel="shortcut icon" type="image/png" href={this.getFavicon()} />
                    <title>{partnerSelected.name}</title>
                </Helmet>
                <SignUpPorchDoorChannelSidebar history={history} name={partnerSelected.name} description={partnerSelected.description} abbreviation={partnerSelected.abbreviation} />
                <SignUpPorchDoorChannelContent {...contentProps} />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        partners: state.company.partners,
    }
}

export default connect(mapStateToProps)(SignUpPorchDoorChannel);
