import React, { Component } from 'react';
import './ResetPassword.scss';
import ResetPasswordSidebar from './ResetPasswordSidebar';
import ResetPasswordContent from './ResetPasswordContent';
class ResetPassword extends Component {
       render() {
        return (            
            <div className='container-reset'>
                <ResetPasswordSidebar history={this.props.history}/>
                <ResetPasswordContent history={this.props.history}/>
            </div>
        )
    }
}

export default ResetPassword;