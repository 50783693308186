import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconGraph = props => (
  <svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 15 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Intel" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="1---Menu" transform="translate(-105.000000, -116.000000)" stroke={!props.isActive ? props.color : props.activeColor}>
        <g id="icons/graph-2" transform="translate(105.000000, 116.000000)">
          <rect id="Rectangle" x="0.5" y="4.5" width="2" height="9"></rect>
          <rect id="Rectangle" x="4.5" y="9.5" width="2" height="4"></rect>
          <rect id="Rectangle" x="12.5" y="4.5" width="2" height="9"></rect>
          <rect id="Rectangle" x="8.5" y="0.5" width="2" height="13"></rect>
        </g>
      </g>
    </g>
  </svg>
);

IconGraph.propTypes = {
  color: PropTypes.string,
  activeColor: PropTypes.string,
  isActive: PropTypes.bool,
  size: PropTypes.number,
};

IconGraph.defaultProps = {
  activeColor: config.colors.rbYellow,
  color: config.colors.rbWhite,
  isActive: false,
  size: 16,
};

export default IconGraph;