/* global analytics */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import '../../SignUp.scss';
import { spinner } from '../../../../MasterComponents/Spinner/SpinnerActions';
import { isPhoneValid, isTextValid } from '../../../../MasterComponents/Input/InputValidate';
import SignUpApi from '../../SignUpApi';
import $ from 'jquery';
import { showModal } from '../../../../MasterComponents/Modals/ModalsActions';
import RequestInviteModal from '../../Modals/RequestInviteModal';
import ConfirmationModal from '../../Modals/ConfirmationModal';
import { sorArrayByKey } from '../../../../utils/utils';
import FormDefaultStep1 from './FormDefaultStep1';
import FormDefaultStep3 from './FormDefaultStep3';
import FormDefaultStep4 from './FormDefaultStep4';
import ReactHtmlParser from 'react-html-parser';
import FormDefaultSuccessfullyAlmostDone from './FormDefaultSuccessfullyAlmostDone';
import DistributorConfirmSaveModal from '../../Modals/DistributorConfirmSaveModal';
import FormDefaultStep2 from './FormDefaultStep2';
import FormDefaultDistributorRow from './FormDefaultDistributorRow';
import DistributorNonEntegraModal from '../../Modals/DistributorNonEntegraModal';
import FormDefaultSuccessfully from './FormDefaultSuccessfully';
import { signUpClearAllSalesRep } from '../../Reducer/SignUpAction';

class FormDefault extends Component {
  constructor(props) {
    super(props);
    const { signUpWithSaleRep } = props;
    const { step1Inputs, step1Locations, step2Distributors, step3Inputs, salesRepSignUpId } = signUpWithSaleRep;
    this.state = {
      location: 1,
      numberDistributor: 0,
      showAddDistributor: false,
      numberLocations: 1,
      showManualUpload: false,
      salesRepSignUpId: salesRepSignUpId || 0,
      step1Inputs: step1Inputs || {
        numberLocations: 1,
        firstName: '',
        lastName: '',
        title: '',
        businessName: '',
        taxId: '',
        parentPlatformId: '',
        businessAddress: '',
        businessCity: '',
        businessStateObj: null,
        businessState: '',
        businessStateName: '',
        businessPostcode: '',
        businessPhoneNumber: '',
        phoneNumber: '',
        email: '',
        password: '',
        passwordRepeat: '',
        emailRepeat: '',
        daSalesRep: null,
        referredBy: null,
        referredName: '',
        mergedDaSalesRepReferred: null,
        distributorSalesRep: null,
        addressSearch: '',
        showAddressDetails: false,
        sameAddress: false,
        platformId: '', // 'CL-',
      },
      arrayDistributors: [],
      step1Locations: step1Locations || [{
        nickname: '',
        platform_id: '',
        phoneNumber: '',
        addressSearch: '',
        showAddressDetails: false,
        address: '',
        city: '',
        stateObj: null,
        state: '',
        stateName: '',
        postcode: '',
        numberOfRooms: null,
        locationId: '',
        flagTypeObj: null,
        flagTypeName: '',
        flagType: '',
        parent: false,
        isSkipEnabled: false
      }],
      step2Distributors: step2Distributors || [],
      daSalesRepFromApi: [],
      referredPartnerFromApi: [],
      mergedDaSalesRepReferredFromApi: [],
      step2UnsavedDistributor: {},
      distributorsHouse: [],
      distributorsHouseSelected: null,
      step3Inputs: step3Inputs || {
        isAuthorized: false,
        isAuthorizedInvalid: false,
        isFsrAuthorized: false,
        isFsrAuthorizedInvalid: false,
        isReinhartAuthorized: false,
        isReinhartAgreement: false,
        isLOTAuthorized: false,
        isLOTAuthorizedInvalid: false,
        isGpo: 0,
        isGpoTitle: 'No',
        gpoName: '',
        nameOfCompanies: [],
        isDistributorManufacturerAgreement: 0,
        isDistributorManTitle: 'No',
        legalName: '',
        terminationDate: new Date(),
        signedText: '',
        signature: ''
      },
      successResponse: null,
      analyticsIdentified: false,
    }
  };
  componentDidMount = () => {
    this.getReferredPartner(() => {
      this.getSalesRep();
      this.getInitialRefferedBy();
    });
    $('[data-toggle="tooltipContactName"]').tooltip();
    $('[data-toggle="tooltipPassword"]').tooltip();
    localStorage.setItem('isSignUpRoute', 1);
    this.autofill();
  };
  handleAddressSelect = (value, placeId) => {
    const { country } = this.props;

    if (placeId) {
      SignUpApi.getPlaceData(placeId).then(({ data }) => {
        const { address_components, formatted_phone_number } = data.data.result;
        const streetNumber = address_components.filter(a => a.types[0] === 'street_number')[0];
        const street = address_components.filter(a => a.types[0] === 'route')[0] || address_components.filter(a => a.types[0] === 'neighborhood')[0];
        const city = address_components.filter(a => a.types[0] === 'locality')[0] || address_components.filter(a => a.types[0] === 'neighborhood')[0];
        const usState = address_components.filter(a => a.types[0] === 'administrative_area_level_1')[0];
        let zipcode = address_components.filter(a => a.types[0] === 'postal_code')[0];

        zipcode = zipcode.long_name.toString().trim()

        if (zipcode && country !== 'CA' && country !== 'ca') {
          if (zipcode.length > 5) zipcode = zipcode.substring(0, 5);
          if (zipcode.length < 5) zipcode = zipcode.padStart(5, '0');
        }

        this.setState({
          step1Inputs: {
            ...this.state.step1Inputs,
            addressSearch: (`${streetNumber ? streetNumber.long_name + ' ' : ''}${street ? street.long_name : ''}`) + (city ? `, ${city.long_name}` : '') + (usState ? `, ${usState.short_name}` : '') + (zipcode ? ` ${zipcode}` : ''),
            businessAddress: (`${streetNumber ? streetNumber.long_name : ''}${street ? ' ' + street.long_name : ''}`),
            businessCity: city ? city.long_name : '',
            businessState: usState ? usState.short_name : '',
            businessStateName: usState ? usState.long_name : '',
            businessPostcode: zipcode ? zipcode : '',
            businessPhoneNumber: formatted_phone_number || '',
            businessStateObj: {
              label: usState ? usState.long_name : '',
              value: usState ? usState.short_name : ''
            },
            showAddressDetails: !(streetNumber && street && city && usState && zipcode && formatted_phone_number) ? true : this.state.step1Inputs.showAddressDetails
          }
        });
      }).catch(err => {
        console.error(err);
      });
    } else {
      this.setState({
        step1Inputs: {
          ...this.state.step1Inputs,
          showAddressDetails: true
        }
      });
    }
  };
  setAddressSearch = (value) => {
    this.setState({
      step1Inputs: {
        ...this.state.step1Inputs,
        addressSearch: value
      }
    });
  };
  clearAddressSearch = () => {
    this.setAddressSearch('');
  };
  blurAddressSearch = () => {
    this.handleAddressSelect(this.state.step1Inputs.addressSearch);
  };
  toggleAddressDetails = () => {
    this.setState({
      step1Inputs: {
        ...this.state.step1Inputs,
        showAddressDetails: !this.state.step1Inputs.showAddressDetails
      }
    });
  };
  handleAddressSelectLocation = (location, value, placeId) => {
    const { country } = this.props;
    if (placeId) {
      SignUpApi.getPlaceData(placeId).then(({ data }) => {
        const { address_components } = data.data.result;
        const streetNumber = address_components.filter(a => a.types[0] === 'street_number')[0];
        const street = address_components.filter(a => a.types[0] === 'route')[0] || address_components.filter(a => a.types[0] === 'neighborhood')[0];
        const city = address_components.filter(a => a.types[0] === 'locality')[0] || address_components.filter(a => a.types[0] === 'neighborhood')[0];
        const usState = address_components.filter(a => a.types[0] === 'administrative_area_level_1')[0];
        let zipcode = address_components.filter(a => a.types[0] === 'postal_code')[0];

        zipcode = zipcode.long_name.toString().trim()

        if (zipcode && country !== 'CA' && country !== 'ca') {
          if (zipcode.length > 5) zipcode = zipcode.substring(0, 5);
          if (zipcode.length < 5) zipcode = zipcode.padStart(5, '0');
        }

        console.log('zipcode', zipcode, country);

        this.setState({
          step1Locations: this.state.step1Locations.map((l, i) => {
            if (i !== location - 1) return l;
            return {
              ...l,
              addressSearch: (`${streetNumber ? streetNumber.long_name + ' ' : ''}${street ? street.long_name : ''}`) + (city ? `, ${city.long_name}` : '') + (usState ? `, ${usState.short_name}` : '') + (zipcode ? ` ${zipcode}` : ''),
              address: (`${streetNumber ? streetNumber.long_name : ''}${street ? ' ' + street.long_name : ''}`),
              city: city ? city.long_name : '',
              state: usState ? usState.short_name : '',
              stateName: usState ? usState.long_name : '',
              postcode: zipcode ? zipcode : '',
              stateObj: {
                label: usState ? usState.long_name : '',
                value: usState ? usState.short_name : ''
              },
              showAddressDetails: !(streetNumber && street && city && usState && zipcode) ? true : l.showAddressDetails
            }
          })
        });
      }).catch(err => {
        console.error(err);
      });
    } else {
      this.setState({
        step1Locations: this.state.step1Locations.map((l, i) => {
          if (i !== location - 1) return l;
          return {
            ...l,
            showAddressDetails: true
          }
        })
      });
    }
  };
  handleNicknameSelectLocation = (location, value, placeId) => {
    const { country } = this.props;
    if (placeId) {
      SignUpApi.getPlaceData(placeId).then(({ data }) => {
        const { address_components } = data.data.result;
        const streetNumber = address_components.filter(a => a.types[0] === 'street_number')[0];
        const street = address_components.filter(a => a.types[0] === 'route')[0] || address_components.filter(a => a.types[0] === 'neighborhood')[0];
        const city = address_components.filter(a => a.types[0] === 'locality')[0] || address_components.filter(a => a.types[0] === 'neighborhood')[0];
        const usState = address_components.filter(a => a.types[0] === 'administrative_area_level_1')[0];
        let zipcode = address_components.filter(a => a.types[0] === 'postal_code')[0];

        zipcode = zipcode.long_name.toString().trim()

        if (zipcode && country !== 'CA' && country !== 'ca') {
          if (zipcode.length > 5) zipcode = zipcode.substring(0, 5);
          if (zipcode.length < 5) zipcode = zipcode.padStart(5, '0');
        }

        console.log('zipcode', zipcode, country);

        this.setState({
          step1Locations: this.state.step1Locations.map((l, i) => {
            if (i !== location - 1) return l;
            return {
              ...l,
              phoneNumber: data.data.result.formatted_phone_number || l.phoneNumber,
              nickname: data.data.result.name,
              addressSearch: (`${streetNumber ? streetNumber.long_name + ' ' : ''}${street ? street.long_name : ''}`) + (city ? `, ${city.long_name}` : '') + (usState ? `, ${usState.short_name}` : '') + (zipcode ? ` ${zipcode}` : ''),
              address: (`${streetNumber ? streetNumber.long_name : ''}${street ? ' ' + street.long_name : ''}`),
              city: city ? city.long_name : '',
              state: usState ? usState.short_name : '',
              stateName: usState ? usState.long_name : '',
              postcode: zipcode ? zipcode : '',
              stateObj: {
                label: usState ? usState.long_name : '',
                value: usState ? usState.short_name : ''
              },
              showAddressDetails: !(streetNumber && street && city && usState && zipcode) ? true : l.showAddressDetails
            }
          })
        });
      }).catch(err => {
        console.error(err);
      });
    } else {
      this.setState({
        step1Locations: this.state.step1Locations.map((l, i) => {
          if (i !== location - 1) return l;
          return {
            ...l,
            showAddressDetails: true
          }
        })
      });
    }
  };
  setNicknameSearchLocation = (location, value) => {
    const { step1Locations } = this.state
    this.setState({
      step1Locations: step1Locations.map((l, i) => {
        if (i !== location - 1) return l;
        return {
          ...l,
          nickname: value
        }
      })
    });
  };
  setAddressSearchLocation = (location, value) => {
    const { step1Locations } = this.state
    this.setState({
      step1Locations: step1Locations.map((l, i) => {
        if (i !== location - 1) return l;
        return {
          ...l,
          addressSearch: value
        }
      })
    });
  };
  clearAddressSearchLocation = (location) => {
    this.setAddressSearchLocation(location, '');
  };
  blurAddressSearchLocation = (location, useName) => {
    const { step1Locations } = this.state;
    this.handleAddressSelectLocation(location, useName ? step1Locations[location - 1].nickname : step1Locations[location - 1].addressSearch)
  };
  toggleAddressDetailsLocation = (location) => {
    const { step1Locations } = this.state;
    this.setState({
      step1Locations: step1Locations.map((l, i) => {
        if (i !== location - 1) return l;
        return {
          ...l,
          showAddressDetails: !step1Locations[location - 1].showAddressDetails
        }
      })
    });
  };
  autofill = () => {
    const { autofillInputs: af } = this.props;
    if (af) {
      this.setState({
        externalParentMerchantId: af.externalParentMerchantId ? af.externalParentMerchantId : null,
        step1Inputs: {
          ...this.state.step1Inputs,
          ...af.step1Inputs
        }
      })
    }
  };
  getInitialRefferedBy = () => {
    const { initReferredBy } = this.props;
    const { referredPartnerFromApi } = this.state;
    if (initReferredBy) {
      const found = referredPartnerFromApi.filter((ref) => {
        if (initReferredBy.platformId) {
          return ref.platformId === initReferredBy.platformId
        };
        return ref.id === initReferredBy.value;
      })[0];

      const item = found ? {
        label: found.name,
        value: found.id,
        platformId: found.platformId,
        isDaSalesRep: found.salesRepId
      } : {
        ...initReferredBy
      }

      this.selectReferredBy(item);
      this.disabledRefferedBy();
    }
  };
  scrollTop = () => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  };
  getSalesRep = () => {
    const { dispatch } = this.props;
    const search = null;
    let page = 1;
    let result = [];
    const apiCallSaleRep = () => {
      const params = {
        search,
        page
      };
      SignUpApi.getSalesRep({ params }).then((res) => {
        const loadMore = parseInt(res.headers['x-pagination-current-page']) < parseInt(res.headers['x-pagination-page-count']);
        const { data } = res;
        result = [...result, ...data.data];
        const daSalesRepFromApi = result.map(dataSales => ({
          id: dataSales.id,
          name: dataSales.firstName + ' ' + dataSales.lastName,
          isDaSalesRep: true,
          platformId: null
        }));
        if (loadMore) {
          page += 1;
          apiCallSaleRep();
        } else {
          const salesRepSort = sorArrayByKey(daSalesRepFromApi, 'name');
          this.setState({
            daSalesRepFromApi,
            mergedDaSalesRepReferredFromApi: salesRepSort
          });
        }
      }).catch(function (error) {
        if (error) {
          let errorMessage = error.data.data.message;
          dispatch(showModal(ConfirmationModal, 'confirmationModal', { header: 'Message', icon: false, message: errorMessage }));
        }
      });
    }
    apiCallSaleRep();
  };
  getReferredPartner = (cb) => {
    const { referredBrandSearch, companyCode, companyId, dispatch, isReinhartCPOnly, filterChannelPartner } = this.props;
    const brand = referredBrandSearch ? referredBrandSearch : companyCode;
    const search = null;
    let page = 1;
    let result = [];
    const apiCall = () => {
      SignUpApi.getReferredPartner(
        isReinhartCPOnly || filterChannelPartner ? 1 : companyId,
        isReinhartCPOnly || filterChannelPartner ? null : brand,
        page, search).then((res) => {
          const loadMore = parseInt(res.headers['x-pagination-current-page']) < parseInt(res.headers['x-pagination-page-count']);
          const { data } = res;
          result = [...result, ...data.data];
          const referred = result.map(ref => ({
            id: ref.id,
            name: ref.name,
            isDaSalesRep: false,
            platformId: ref.platformId
          }));
          if (loadMore) {
            page += 1;
            apiCall();
          } else {
            const stateSort = sorArrayByKey(referred, 'name') // sorting the values by name
            this.setState({ referredPartnerFromApi: result, mergedDaSalesRepReferredFromApi: stateSort });
            cb();
          }
        }).catch(function (error) {
          if (error) {
            let errorMessage = error.data.data.message;
            dispatch(showModal(ConfirmationModal, 'confirmationModal', { header: 'Message', icon: false, message: errorMessage }));
          }
        });
    };

    apiCall();
  };

  //---------------STEP1-----------------------------------------------

  sameCheckedValue = () => {
    const { step1Inputs, step1Locations } = this.state;
    return step1Inputs.addressSearch === step1Locations[0].addressSearch && step1Inputs.businessName === step1Locations[0].nickname && step1Inputs.businessPhoneNumber === step1Locations[0].phoneNumber;
  };

  sameChecked = () => {
    const { step1Inputs, step1Locations } = this.state;
    const {
      businessAddress, businessCity, businessState, businessPostcode,
      addressSearch, businessStateObj, businessStateName, businessName, businessPhoneNumber
    } = step1Inputs;
    const emptyLocation = {
      nickname: '',
      phoneNumber: '',
      addressSearch: '',
      address: '',
      city: '',
      state: '',
      stateName: '',
      platform_id: '',
      stateObj: null,
      postcode: '',
      numberOfRooms: null,
      locationId: '',
      flagTypeObj: null,
      flagTypeName: '',
      flagType: '',
      parent: false,
      isSkipEnabled: false
    }
    const isSame = this.sameCheckedValue();
    this.setState({
      step1Locations: step1Locations.map((loc, i) => {
        if (i !== 0) return loc;
        if (isSame) return emptyLocation;
        return {
          ...loc,
          nickname: businessName,
          phoneNumber: businessPhoneNumber,
          addressSearch: addressSearch,
          address: businessAddress,
          city: businessCity,
          state: businessState,
          stateObj: businessStateObj,
          stateName: businessStateName,
          postcode: businessPostcode,
        }
      })
    });
  };
  nextBackStep = (newStep) => {
    this.scrollTop();
    const { history, pathname, step } = this.props;
    if (newStep > step) {
      history.push(`${pathname}/${newStep}${history.location.search}`);

      // Segment tracking
      if (newStep === 2) {
        analytics.identify({
          firstName: this.state.step1Inputs.firstName,
          lastName: this.state.step1Inputs.lastName,
          email: this.state.step1Inputs.email,
          addressLine1: this.state.step1Inputs.addressLine1,
          businessAddress: this.state.step1Inputs.businessAddress,
          channelPartner: this.state.step1Inputs.channelPartner,
          city: this.state.step1Inputs.city,
          locationNumber: this.state.step1Inputs.locationNumber,
          phone: this.state.step1Inputs.phone,
          salesRep: this.state.step1Inputs.salesRep,
          state: this.state.step1Inputs.state,
          title: this.state.step1Inputs.title,
          brand: this.props.companyName,
          zipcode: this.state.step1Inputs.zipcode,
        });

        analytics.track('Form Submitted', {
          formName: 'Business Contact Information',
          data: this.state.step1Inputs,
          portal_name: this.props.companyName
        });
      }
    } else {
      history.replace(`${pathname}/${newStep}${history.location.search}`);
    }
  };
  changeHandlerInputs = (value, name) => {
    const { step1Inputs: st1 } = this.state;
    if (name === 'numberLocations') {
      this.updateNumberLocations(value);
    }
    this.setState({
      step1Inputs: {
        ...st1,
        [name]: value
      }
    }, () => {
      const { step1Inputs } = this.state;
      const { businessAddress, businessCity, businessState, businessPostcode } = step1Inputs;
      this.setState({
        step1Inputs: {
          ...step1Inputs,
          addressSearch: businessAddress + (businessCity ? ', ' + businessCity : '') + (businessState ? ', ' + businessState : '') + (businessPostcode ? ' ' + businessPostcode : '')
        }
      });
    });
  };
  changeHandlerLocationInput = (location, value, name) => {
    const { step1Locations } = this.state;
    const emptyLocation = {
      nickname: '',
      phoneNumber: '',
      addressSearch: '',
      address: '',
      city: '',
      state: '',
      stateName: '',
      platform_id: '',
      showAddressDetails: false,
      stateObj: null,
      postcode: '',
      numberOfRooms: null,
      locationId: '',
      flagTypeObj: null,
      flagTypeName: '',
      flagType: '',
      parent: false,
      isSkipEnabled: false
    };
    this.setState({
      step1Locations: step1Locations.map((item, index) => {
        if (index !== location - 1) {
          return item;
        }
        if (value === '' && name === 'nickname') {
          return {
            ...item,
            ...emptyLocation
          };
        }
        return {
          ...item,
          [name]: value
        }
      })
    }, () => {
      const { step1Locations } = this.state;
      const { address, city, state, postcode } = step1Locations[location - 1];
      this.setState({
        step1Locations: step1Locations.map((l, i) => {
          if (i !== location - 1) return l;
          return {
            ...l,
            addressSearch: address + (city ? ', ' + city : '') + (state ? ', ' + state : '') + (postcode ? ' ' + postcode : '')
          }
        })
      })
    })
  };
  selectStateLocationInput = (location, value) => {
    const { step1Locations } = this.state;
    this.setState({
      step1Locations: step1Locations.map((item, index) => {
        if (index !== location - 1) {
          return item;
        }
        return {
          ...item,
          stateObj: value,
          stateName: value.label,
          state: value.value
        }
      })
    }, () => {
      const { step1Locations } = this.state;
      const { address, city, state, postcode } = step1Locations[location - 1];
      this.setState({
        step1Locations: step1Locations.map((l, i) => {
          if (i !== location - 1) return l;
          return {
            ...l,
            addressSearch: address + (city ? ', ' + city : '') + (state ? ', ' + state : '') + (postcode ? ' ' + postcode : '')
          }
        })
      })
    })
  };
  updateNumberLocations = (value) => {
    const { step1Locations } = this.state
    let step1LocationsNew = []
    for (let i = 1; i < value; i++) {
      const emptyLocation = {
        nickname: '',
        phoneNumber: '',
        addressSearch: '',
        address: '',
        city: '',
        state: '',
        stateName: '',
        platform_id: '',
        showAddressDetails: false,
        stateObj: null,
        postcode: '',
        numberOfRooms: null,
        locationId: '',
        flagTypeObj: null,
        flagTypeName: '',
        flagType: '',
        parent: false,
        isSkipEnabled: false
      }
      step1LocationsNew.push(emptyLocation);
    }
    step1LocationsNew.unshift(step1Locations[0]);
    this.setState({
      step1Locations: step1LocationsNew,
      numberLocations: value
    });
  };
  backLocation = () => {
    this.scrollTop();
    const { location } = this.state
    if (location === 1) {
      this.nextBackStep(1);
    } else {
      this.setState({
        location: location - 1
      });
    }
  };
  nextLocation = () => {
    const { location, numberLocations, step1Locations, step1Inputs } = this.state;
    this.scrollTop();
    if (location >= numberLocations) {
      const action = this.props.dispatch;
      const parent = {
        nickname: step1Inputs.businessName,
        platform_id: step1Inputs.parentPlatformId,
        phoneNumber: step1Inputs.phoneNumber,
        address: step1Inputs.businessAddress,
        city: step1Inputs.businessCity,
        state: step1Inputs.businessState,
        stateName: step1Inputs.businessStateName,
        postcode: step1Inputs.businessPostcode,
        parent: true
      };
      const objLocations = {
        locations: step1Locations.map((item, index) => {
          const { stateObj, flagTypeObj, ...rest } = item
          return {
            ...rest
          }
        })
      }
      objLocations.locations.push(parent);
      action(spinner(true));
      SignUpApi.validateLocations(objLocations).then(({ data }) => {
        let result = data.data.result;
        if (result) {
          this.cancelDistributor(false);
          this.nextBackStep(3);
        } else {
          this.showRequestInviteModal(data.data.repeatedLocations);
        }
        action(spinner(false));
      }).catch(error => {
        action(spinner(false));
        if (error.data.data) {
          action(showModal(ConfirmationModal, 'confirmationModal', { header: 'Validation', icon: false, message: 'There was a problem while validating locations' }));
        }
      });
    } else {
      this.setState({ location: location + 1 });
    }
  };
  goToLocationStep = () => {
    const { signUpSalesRep, companyId } = this.props;
    const { SignUpStep1 } = this.refs;
    if (signUpSalesRep && companyId === 3) {
      this.sameChecked();
      this.nextBackStep(4);
      return;
    }
    if (SignUpStep1.isValid()) {
      this.nextBackStep(2);
    }
  }
  skipLocation = (skipArray) => {
    this.scrollTop();
    const { step1Locations } = this.state;
    const emptyLocation = {
      nickname: '',
      phoneNumber: '',
      addressSearch: '',
      address: '',
      city: '',
      state: '',
      stateName: '',
      platform_id: '',
      showAddressDetails: false,
      stateObj: null,
      postcode: '',
      numberOfRooms: null,
      locationId: '',
      flagTypeObj: null,
      flagTypeName: '',
      flagType: '',
      parent: false,
      isSkipEnabled: false
    };
    this.setState({
      step1Locations: step1Locations.map((loc, i) => {
        if (skipArray[i]) {
          return {
            ...loc,
            ...emptyLocation,
            isSkipEnabled: true
          }
        }
        return loc;
      })
    });
  };
  skipDistributor = () => {
    this.scrollTop();
    this.cancelDistributor(false);
    this.nextBackStep(4);
  };
  showRequestInviteModal = (repeatedLocations) => {
    const { dispatch } = this.props;
    dispatch(showModal(RequestInviteModal, 'requestInviteModal', { header: 'Request Invite', parents: repeatedLocations, user: this.state.step1Inputs, history: this.props.history, dispatch: this.props.dispatch }));
  };
  selectStateContactInformation = (value) => {
    this.setState({
      step1Inputs: {
        ...this.state.step1Inputs,
        businessStateObj: value,
        businessStateName: value.label,
        businessState: value.value
      }
    }, () => {
      const { step1Inputs } = this.state;
      const { businessAddress, businessCity, businessState, businessPostcode } = step1Inputs;
      this.setState({
        step1Inputs: {
          ...step1Inputs,
          addressSearch: businessAddress + (businessCity ? ', ' + businessCity : '') + (businessState ? ', ' + businessState : '') + (businessPostcode ? ' ' + businessPostcode : '')
        }
      })
    });
  };
  selectStateLocation = (value) => {
    const { step1Locations, location } = this.state;
    this.setState({
      step1Locations: step1Locations.map((item, index) => {
        if (index !== location - 1) {
          return item;
        }
        return {
          ...item,
          stateObj: value,
          stateName: value.label,
          state: value.value
        }
      })
    }, () => {
      const { step1Locations, location } = this.state;
      const { address, city, state, postcode } = step1Locations[location - 1];
      this.setState({
        step1Locations: step1Locations.map((l, i) => {
          if (i !== location - 1) return l;
          return {
            ...l,
            addressSearch: address + (city ? ', ' + city : '') + (state ? ', ' + state : '') + (postcode ? ' ' + postcode : '')
          }
        })
      })
    })
  };
  selectFlagTypeInput = (location, value) => {
    const { step1Locations } = this.state;
    this.setState({
      step1Locations: step1Locations.map((item, index) => {
        if (index !== location - 1) {
          return item;
        }
        return {
          ...item,
          flagTypeObj: value,
          flagTypeName: value.label,
          flagType: value.value
        }
      })
    })
  };
  selectTitle = (value) => {
    this.setState({
      step1Inputs: {
        ...this.state.step1Inputs,
        title: value
      }
    });
  };
  disabledRefferedBy = () => {
    this.setState({
      disabledRefferedBy: true
    })
  };
  selectDASalesRep = (value) => {
    this.setState({
      step1Inputs: {
        ...this.state.step1Inputs,
        daSalesRep: value,
        mergedDaSalesRepReferred: value
      }
    });
  };
  selectReferredBy = (value) => {
    this.setState({
      step1Inputs: {
        ...this.state.step1Inputs,
        referredBy: value,
        referredName: value ? value.label : ''
      }
    });
  };
  validateEmailFromApi = (value) => {
    const action = this.props.dispatch;
    const objEmail = {
      email: value
    }
    action(spinner(true));
    SignUpApi.validateEmail(objEmail).then(({ data }) => {
      const { SignUpStep1 } = this.refs;
      let result = data.data;
      if (result.message !== '' && result.message !== null) {
        action(showModal(ConfirmationModal, 'confirmationModal', { header: 'Message', icon: false, message: result.message }));
        SignUpStep1.invalidEmail();
      }
      action(spinner(false));
    }).catch(function (error) {
      action(spinner(false));
      if (error) {
        let errorMessage = error.data.data.message;
        action(showModal(ConfirmationModal, 'confirmationModal', { header: 'Message', icon: false, message: errorMessage }));
      }
    });
  };
  validateRepeatedEmail = (value) => {
    const { email, emailRepeat } = this.state.step1Inputs;
    if (email !== emailRepeat) {
      const { SignUpStep1 } = this.refs;
      SignUpStep1.invalidConfirmEmail();
      SignUpStep1.invalidEmail();
    }
  };

  //---------------STEP2------------------------------------------

  changeHandlerDistributorInput = (value, name) => {
    const { step2Distributors, numberDistributor } = this.state;
    this.setState({
      step2Distributors: step2Distributors.map((item, index) => {
        if (index !== numberDistributor) {
          return item;
        }
        return {
          ...item,
          [name]: value
        }
      })
    });
  };
  changeHandlerDistributorInputDcn = (value, i) => {
    const { step2Distributors, numberDistributor } = this.state;
    this.setState({
      step2Distributors: step2Distributors.map((item, index) => {
        if (index !== numberDistributor) {
          return item;
        }
        return {
          ...item,
          dcn: item.dcn.map((item, index) => {
            if (index !== i) {
              return item;
            }
            return value;
          })
        }
      })
    })
  };
  saveDistributor = (boolParam) => {
    const { dispatch } = this.props;
    this.setState({
      showAddDistributor: boolParam,
      step2Distributors: this.state.step2Distributors.map((item, index) => {
        if (index !== this.state.numberDistributor) {
          return item;
        }
        return {
          ...item,
          phoneNumberReminder: (isPhoneValid(item.phoneNumberReminder, true) || item.phoneNumberReminder === '') ? item.phoneNumberReminder : this.state.step2UnsavedDistributor.phoneNumberReminder,
          dcn: item.dcn.map((item, i) => {
            return isTextValid(item, 3, 20) ? item : '';
          }),
          distributorsHouseSelected: this.state.distributorsHouseSelected
        }
      })
    }, () => {
      const { step2Distributors, numberDistributor } = this.state;
      let provideDcn = true;
      for (let i = 0; i < step2Distributors[numberDistributor].dcn.length; i++) {
        if (step2Distributors[numberDistributor].addThisTo[i + 1]) {
          if (step2Distributors[numberDistributor].dcn[i] === '') {
            provideDcn = false;
          }
        }
      }
      const dataHouse = step2Distributors[numberDistributor].distributorsHouseSelected.data;
      console.log(dataHouse);
      const dataDist = step2Distributors[numberDistributor].distributorSelected;
      const isPreffered = dataHouse ? dataHouse.is_preferred_distributor : (dataDist && dataDist.is_preferred_distributor);
      const isNonEntegra = dataHouse ? dataHouse.entegraReportingMethod === 'NON-ENTEGRA DISTRIBUTOR' : (dataDist && dataDist.entegraReportingMethod === 'NON-ENTEGRA DISTRIBUTOR');

      if (!boolParam) {
        if (isNonEntegra) {
          dispatch(showModal(DistributorNonEntegraModal, 'distributorNonEntegraModal'));
        } else {
          // Ignore Ben E Keith
          const isBenEKeith = step2Distributors[numberDistributor].distributorName.search('Ben E Keith') !== -1;
          // Don't show Join Popup on DVPG
          const canDVPGShowIt = this.props.companyId === 2 ? (isPreffered ? true : false) : true;
          if (!isBenEKeith && canDVPGShowIt) {
            dispatch(showModal(DistributorConfirmSaveModal, 'distributorConfirmSaveModal', { isPreffered, provideDcn, isNonEntegra }));
          }
        }
      }
    });
  };
  handleEditDistributor = (i) => {
    const { step2Distributors } = this.state;
    this.setState({
      numberDistributor: i,
      showAddDistributor: true,
      step2UnsavedDistributor: this.state.step2Distributors[i],
      distributorsHouseSelected: this.state.step2Distributors[i].distributorsHouseSelected,
      isEmpty: false
    });
    this.getDistributorsHouse(step2Distributors[i].distributorSelected.id, step2Distributors[i].distributorsHouseSelected);
  };
  handleDeleteDistributor = (id) => {
    let newState = this.state.step2Distributors;
    this.setState({
      step2Distributors: newState.filter(distributor => (
        distributor.id !== id
      ))
    })
  };
  cancelDistributor = (boolParam) => {
    const { step2Distributors, numberDistributor, step2UnsavedDistributor, isEmpty, showAddDistributor } = this.state;
    if (showAddDistributor) {
      if (isEmpty) {
        this.setState({
          showAddDistributor: boolParam,
          step2Distributors: step2Distributors.filter((item, index) => index !== numberDistributor)
        });
      } else {
        this.setState({
          showAddDistributor: boolParam,
          step2Distributors: step2Distributors.map((item, index) => {
            if (index !== numberDistributor) {
              return item
            }
            return {
              ...step2UnsavedDistributor
            }
          })
        });
      }
    }
  };
  updateAddDistributor = (boolParam) => {
    const { step1Locations, step2Distributors } = this.state;
    let addThisTo = [false]
    let dcn = [];
    let credentials = [{
      username: '',
      password: ''
    }];
    step1Locations.forEach(loc => {
      addThisTo.push(false);
      dcn.push('');
      credentials.push({
        username: '',
        password: '',
        isOk: false
      })
    });
    const emptyDistributors = {
      id: '',
      distributorName: '',
      distributorSelected: null,
      dcn: dcn,
      credentials: credentials,
      branch: '',
      submissionType: '',
      phoneNumberReminder: '',
      emailReminder: '',
      addThisTo: addThisTo,
      distributorsHouseSelected: null,
      isOnlineOrderer: false,
      distributorRepName: '',
      distributorRepEmail: ''
    };
    this.setState({
      step2Distributors: [...step2Distributors, emptyDistributors],
      showAddDistributor: boolParam,
      numberDistributor: step2Distributors.length,
      step2UnsavedDistributor: emptyDistributors,
      isEmpty: true,
      distributorsHouseSelected: null
    });
  };
  updateCheckedLocations = (name) => {
    const { step2Distributors, numberDistributor } = this.state;
    const newValue = !step2Distributors[numberDistributor].addThisTo[name];

    this.setState({
      step2Distributors: step2Distributors.map((item, index) => {
        if (index !== numberDistributor) {
          return item;
        }
        return {
          ...item,
          addThisTo: item.addThisTo.map((item, index) => {
            if (name === 0) {
              return newValue;
            } else {
              if (index === 0) {
                return false;
              }
              if (index !== name) {
                return item;
              }
              return newValue;
            }
          })
        }
      })
    });
  };
  checkOrderOnline = () => {
    const { step2Distributors, numberDistributor } = this.state;
    this.setState({
      step2Distributors: step2Distributors.map((item, index) => {
        if (index !== numberDistributor) {
          return item;
        }
        return {
          ...item,
          isOnlineOrderer: !item.isOnlineOrderer
        }
      })
    });
  };
  selectOptionDistributor = (value) => {
    const { step2Distributors, numberDistributor } = this.state;
    this.setState({
      step2Distributors: step2Distributors.map((item, index) => {
        if (index !== numberDistributor) {
          return item;
        }
        return {
          ...item,
          distributorName: value.nickname,
          id: value.id,
          distributorSelected: value
        }
      })
    });
    this.getDistributorsHouse(value.id);
  };
  getDistributorsHouse = (id, init) => {
    this.setState({
      distributorsHouse: []
    });
    const { companyId } = this.props;
    SignUpApi.getDistributorsHouse(companyId, id).then(({ data }) => {
      const result = data.data;
      if (result.length > 0) {
        const resultMap = result.map(m => {
          return {
            label: m.nickname,
            value: m.id,
            data: m
          }
        });
        const resultMapHouse = resultMap.length === 1 ? [...resultMap] : [{ label: 'I dont know', value: id }, ...resultMap];
        this.setState({
          distributorsHouseSelected: init ? init : null,
          distributorsHouse: resultMapHouse
        });
      } else {
        this.setState({
          distributorsHouseSelected: {
            label: 'Single House',
            value: id
          }
        });
      }
    });
  };
  selectDistributorHouse = (option) => {
    const { step2Distributors, numberDistributor } = this.state
    this.setState({
      distributorsHouseSelected: option,
      step2Distributors: step2Distributors.map((item, index) => {
        if (index !== numberDistributor) {
          return item;
        }
        return {
          ...item,
          distributorName: option.label,
          id: option.value,
          distributorsHouseSelected: option,
        }
      })
    });
  };
  saveCredentials = (username, password, isOk, locIndex) => {
    const { step2Distributors, numberDistributor } = this.state
    this.setState({
      step2Distributors: step2Distributors.map((item, index) => {
        if (index !== numberDistributor) {
          return item;
        }
        return {
          ...item,
          credentials: item.credentials.map((item, index) => {
            if (index !== locIndex) {
              return item;
            }
            return {
              username: username,
              password: password,
              isOk: isOk
            }
          })
        }
      })
    });
  };

  //---------------STEP3---------------------------------------------------

  selectOptionSubmissionType = (value) => {
    const { step2Distributors, numberDistributor } = this.state;
    this.setState({
      showManualUpload: value === 'Manual Upload' ? true : false,
      step2Distributors: step2Distributors.map((item, index) => {
        if (index !== numberDistributor) {
          return item;
        }
        return {
          ...item,
          submissionType: value
        }
      })
    });
  };
  selectOptionMemberGPO = (value) => {
    this.setState({
      step3Inputs: {
        ...this.state.step3Inputs,
        isGpo: value,
        isGpoTitle: value === 1 ? 'Yes' : 'No',
        gpoName: value === 1 ? this.state.step3Inputs.gpoName : ''
      }
    });
  };
  selectOptionGPOName = (value) => {
    this.setState({
      step3Inputs: {
        ...this.state.step3Inputs,
        gpoName: value || ''
      }
    });
  };
  selectOptionDistributorManufacturer = (value) => {
    this.setState({
      step3Inputs: {
        ...this.state.step3Inputs,
        isDistributorManufacturerAgreement: value,
        isDistributorManTitle: value === 1 ? 'Yes' : 'No',
        nameOfCompanies: value === 1 ? this.state.step3Inputs.nameOfCompanies : []
      }
    });
  };
  handleChangeTerminationDate = (value) => {
    this.setState({
      step3Inputs: {
        ...this.state.step3Inputs,
        terminationDate: value
      }
    });
  };
  changeHandlerStep3Inputs = (value, name) => {
    this.setState({
      step3Inputs: {
        ...this.state.step3Inputs,
        [name]: value
      }
    });
  };
  authorizationChecked = (value) => {
    this.setState({
      step3Inputs: {
        ...this.state.step3Inputs,
        isAuthorized: !this.state.step3Inputs.isAuthorized,
        isAuthorizedInvalid: false
      },
      objSignUp: {
        ...this.state.step1Inputs,
        locations: this.state.step1Locations,
        lop: this.state.step3Inputs,
      },
    });
  };
  authorizationFsrChecked = (value) => {
    this.setState({
      step3Inputs: {
        ...this.state.step3Inputs,
        isFsrAuthorized: !this.state.step3Inputs.isFsrAuthorized,
        isFsrAuthorizedInvalid: false
      }
    });
  };
  authorizationReinhartChecked = () => {
    this.setState({
      step3Inputs: {
        ...this.state.step3Inputs,
        isReinhartAuthorized: !this.state.step3Inputs.isReinhartAuthorized,
        isReinhartAgreement: !this.state.step3Inputs.isReinhartAgreement,
        isReinhartAuthorizedInvalid: false
      },
      objSignUp: {
        ...this.state.step1Inputs,
        locations: this.state.step1Locations,
        lop: this.state.step3Inputs,
      },
    });
  };
  authorizationLOTChecked = (value) => {
    const prevValue = this.state.step3Inputs.isLOTAuthorized;
    const newValue = value === true ? true : (value === false ? false : !prevValue);
    this.setState({
      step3Inputs: {
        ...this.state.step3Inputs,
        isLOTAuthorized: newValue,
        isLOTAuthorizedInvalid: false,
        isGpo: newValue ? 1 : 0,
        isGpoTitle: newValue ? 'Yes' : 'No',
        gpoName: newValue ? this.state.step3Inputs.gpoName : ''
      }
    });
  };
  authorizationInvalid = (a, r, lot) => {
    this.setState({
      step3Inputs: {
        ...this.state.step3Inputs,
        isAuthorizedInvalid: a,
        isReinhartAuthorizedInvalid: r,
        isLOTAuthorizedInvalid: lot
      }
    });
  };
  nameOfCompaniesHandler = (value) => {
    const { step3Inputs } = this.state;
    this.setState({
      step3Inputs: {
        ...step3Inputs,
        nameOfCompanies: value.map(item => {
          if (item.isCustom) {
            return {
              ...item,
              label: item.value,
            };
          }
          return item;
        })
      }
    });
  };
  signatureHandler = (drawing) => {
    const { step3Inputs } = this.state;
    this.setState({
      step3Inputs: {
        ...step3Inputs,
        signature: drawing
      }
    });
  };
  doneSignUp = () => {
    const { dispatch, country } = this.props;
    const { step1Inputs, step1Locations } = this.state;
    const locations = step1Locations.filter((item) => item.isSkipEnabled === false);

    const firstAddress = {
      address: step1Inputs.businessAddress,
      city: step1Inputs.businessCity,
      state: step1Inputs.businessState,
      postcode: step1Inputs.businessPostcode
    };

    const allAddress = locations.map(loc => ({
      address: loc.address,
      city: loc.city,
      state: loc.state,
      postcode: loc.postcode
    }));

    dispatch(spinner(true));
    SignUpApi.getSmartyPlace([firstAddress, ...allAddress], country).then(({ data }) => {
      dispatch(spinner(false));
      const smartyLocations = data.data.map(loc => {
        let zipcode = loc.postcode.toString().trim()

        if (zipcode && country !== 'CA' && country !== 'ca') {
          if (zipcode.length > 5) zipcode = zipcode.substring(0, 5);
          if (zipcode.length < 5) zipcode = zipcode.padStart(5, '0');
        }
        return {
          ...loc,
          postcode: zipcode || ''
        }
      });
      this.doneSignUpEnd(smartyLocations);
    }).catch(err => {
      console.error(err);
      dispatch(spinner(false));
      dispatch(showModal(ConfirmationModal, 'confirmationModal', { header: 'Message', icon: false, message: "Something wen't wrong with the address." }));
    });
  };
  doneSignUpEnd = (smartyLocations) => {
    const { dispatch, companyId, brandSelected, companyCode, companyName, externalBrand, externalBrandCode, segment, subsegment, country, signUpSalesRep, isReinhartCPOnly, campaign } = this.props;
    const { step3Inputs, step1Inputs, step1Locations, step2Distributors, numberLocations, salesRepSignUpId } = this.state;
    const { isAuthorized, isAuthorizedInvalid, isFsrAuthorized, isFsrAuthorizedInvalid, nameOfCompanies, terminationDate, isGpo, gpoName, ...lop } = step3Inputs;
    const { mergedDaSalesRepReferred, firstName, lastName, addressSearch, businessStateObj, businessStateName, ...step1 } = step1Inputs;
    let step1LocationsTmp = step1Locations.filter((item) => item.isSkipEnabled === false);
    let objSignUpSalesRep = {};
    if (signUpSalesRep) {
      objSignUpSalesRep = {
        step1Inputs,
        step1Locations,
        step2Distributors,
        step3Inputs
      }
    }
    const objSignUp = {
      ...step1,
      ...objSignUpSalesRep,
      salesRepSignUpId,
      businessAddress: smartyLocations[0].address,
      businessPhoneNumber: step1Inputs.businessPhoneNumber,
      businessCity: smartyLocations[0].city,
      businessState: smartyLocations[0].state,
      businessPostcode: smartyLocations[0].postcode,
      contactName: firstName + ':' + lastName,
      siteUrl: `https://${window.location.hostname}/`,
      companyId: companyId,
      brandCode: isReinhartCPOnly ? 'da' : (brandSelected ? brandSelected.abbreviation : (externalBrand ? (externalBrandCode ? externalBrandCode : companyCode) : companyCode)),
      brand: isReinhartCPOnly ? 'dining alliance' : (brandSelected ? brandSelected.name : companyName),
      daSalesRep: step1Inputs.daSalesRep ? step1Inputs.daSalesRep.label : null,
      referredBy: step1Inputs.referredBy ? step1Inputs.referredBy.platformId : '',
      totalReportingLocations: numberLocations,
      locations: step1LocationsTmp.map((loc, i) => {
        const {
          nickname, flagType, flagTypeName, flagTypeObj,
          locationId, numberOfRooms, parent, phoneNumber, platform_id
        } = loc;
        return {
          nickname,
          flagType, flagTypeName, flagTypeObj, locationId, numberOfRooms,
          parent, phoneNumber, platform_id,
          address: smartyLocations[i + 1] ? smartyLocations[i + 1].address : smartyLocations[0].address,
          city: smartyLocations[i + 1] ? smartyLocations[i + 1].city : smartyLocations[0].city,
          state: smartyLocations[i + 1] ? smartyLocations[i + 1].state : smartyLocations[0].state,
          postcode: smartyLocations[i + 1] ? smartyLocations[i + 1].postcode : smartyLocations[0].postcode,
          distributors: step2Distributors.filter((dis) => dis.addThisTo[i + 1] === true).map((dis) => {
            const { distributorSelected, addThisTo, dcn, submissionType, credentials, distributorsHouseSelected, ...fields } = dis
            return {
              ...fields,
              submissionType: distributorSelected.company_distributor_sensitive === 1 ? 'auto' : 'dont know yet',
              dcn: dcn[i]
            }
          })
        }
      }),
      lop: {
        ...lop,
        isFsr: isFsrAuthorized ? 1 : 0,
        legalName: step1Inputs.businessName,
        isGpo: isGpo ? 1 : 0,
        gpoName: isGpo ? (gpoName ? gpoName : '') : '',
        terminationDate: companyId === 17 ? null : moment(terminationDate).format('YYYY-MM-DD'),
        isGpoTitle: lop.isGpoTitle === 'Yes' ? 1 : 0,
        isReinhartAgreement: lop.isReinhartAgreement === true ? 1 : 0,
        nameOfCompanies: nameOfCompanies.map(item => {
          return item.nickname
        }).toString(),
        manufacturers: nameOfCompanies.map(item => {
          return item.id
        })
      },
      segment: segment,
      subsegment: subsegment,
      country: country,
      primaryCampaign: campaign
    }
    // console.log(objSignUp)
    dispatch(spinner(true));
    SignUpApi.signup(objSignUp, signUpSalesRep).then(({ data }) => {
      console.log('data', data);
      let result = data.data;
      dispatch(spinner(false));
      dispatch(signUpClearAllSalesRep());
      if (result) {
        this.setState({
          successResponse: result
        });
        this.scrollTop();
        this.nextBackStep(5);
      } else {
        dispatch(showModal(ConfirmationModal, 'confirmationModal', { header: 'Message', icon: false, message: 'There was an error while processing sign up' }));
      }

      analytics.identify({
        userId: data.id,
        brand: this.props.companyName,
        traits: {
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName
        }
      });

      console.log('fullLocations', result.fullLocations);
      if (result.fullLocations) {
        result.fullLocations.forEach((location) => {
          analytics.group(location.id, {
            locationName: location.nickname,
          });

          // console.log('companyName', this.props.companyName);
          analytics.track('Company Location Created', {
            merchant_id: location.id,
            business_address: location.address,
            city: location.city,
            state: location.state,
            name: location.nickname,
            phone_number: location.phoneNumber,
            portal_name: this.props.companyName
          }, {
            context: {
              groupId: location.id
            }
          });

          location.distributors.forEach((distributor) => {
            analytics.track('Distributor Added', {
              distributor_name: distributor.nickname,
              distributor_house: distributor.nickname,
              distributor_id: distributor.id,
              dcn: distributor.dcn,
              portal_name: this.props.companyName,
            });
          });
        });
      }
    }).catch(function (error) {
      console.log('error', error);
      dispatch(spinner(false));
      if (error) {
        let errorMessage = error && error.data && error.data.data && error.data.data.message;
        dispatch(showModal(ConfirmationModal, 'confirmationModal', { header: 'Message', icon: false, message: ReactHtmlParser(errorMessage) }));
      }
    })
  };
  goToNextStep = () => {
    const { step2Distributors } = this.state;
    const loopArrayDistributor = step2Distributors.map((c, i) => {
      let provideDcn = true;
      for (let i = 0; i < c.dcn.length; i++) {
        if (c.addThisTo[i + 1]) {
          if (c.dcn[i] === '') {
            provideDcn = false;
          }
        }
      }
      const dataHouse = c.distributorsHouseSelected && c.distributorsHouseSelected.data;
      const dataDist = c.distributorSelected;

      const isPreffered = dataHouse ? dataHouse.company_distributor_sensitive : (dataDist && dataDist.company_distributor_sensitive);
      const isNonEntegra = dataHouse ? dataHouse.entegraReportingMethod === 'NON-ENTEGRA DISTRIBUTOR' : (dataDist && dataDist.entegraReportingMethod === 'NON-ENTEGRA DISTRIBUTOR');
      return (
        <FormDefaultDistributorRow key={`distributor_${i}`} {...c}
          companyId={this.props.companyId}
          handleEditDistributor={this.handleEditDistributor.bind(this, i)}
          handleDeleteDistributor={this.handleDeleteDistributor.bind(this, c.id)}
          isPreffered={isPreffered}
          provideDcn={provideDcn}
          isNonEntegra={isNonEntegra}
        />
      );
    });

    const step1 = <FormDefaultStep1 ref='SignUpStep1' isPorchDoor={true}
      hideSalesRep={this.props.hideSalesRep}
      hideDistributorSalesRep={this.props.hideDistributorSalesRep}
      disabledRefferedBy={this.state.disabledRefferedBy}
      numberLocations={this.state.numberLocations}
      updateNumberLocations={this.updateNumberLocations}
      changeHandlerInputs={this.changeHandlerInputs}
      step1Inputs={this.state.step1Inputs}
      selectTitle={this.selectTitle}
      addressSearch={this.state.step1Inputs.addressSearch}
      handleAddressSelect={this.handleAddressSelect}
      setAddressSearch={this.setAddressSearch}
      clearAddressSearch={this.clearAddressSearch}
      blurAddressSearch={this.blurAddressSearch}
      showAddressDetails={this.state.step1Inputs.showAddressDetails}
      toggleAddressDetails={this.toggleAddressDetails}
      {...this.props}
      daSalesRepFromApi={this.state.daSalesRepFromApi}
      selectDASalesRep={this.selectDASalesRep}
      mergedDaSalesRepReferredFromApi={this.state.mergedDaSalesRepReferredFromApi}
      referredPartnerFromApi={this.state.referredPartnerFromApi}
      selectReferredBy={this.selectReferredBy}
      selectStateContactInformation={this.selectStateContactInformation}
      stateLocation={this.state.location}
      step1Locations={this.state.step1Locations}
      selectStateLocation={this.selectStateLocation}
      onNextClick={this.goToLocationStep}
      validateEmailFromApi={this.validateEmailFromApi}
      validateRepeatedEmail={this.validateRepeatedEmail}
    />;
    const step2 = <FormDefaultStep2
      {...this.props}
      dispatch={this.props.dispatch}
      locationsAmount={this.state.numberLocations}
      stateLocation={this.state.location}
      changeHandlerLocationInput={this.changeHandlerLocationInput}
      step1Locations={this.state.step1Locations}
      step1Inputs={this.state.step1Inputs}
      selectStateLocation={this.selectStateLocationInput}
      selectFlagTypeLocation={this.selectFlagTypeInput}
      onBackClick={this.nextBackStep.bind(this, 1)}
      onNextClick={this.nextBackStep.bind(this, 3)}
      handleAddressSelectLocation={this.handleAddressSelectLocation}
      setAddressSearchLocation={this.setAddressSearchLocation}
      handleNicknameSelectLocation={this.handleNicknameSelectLocation}
      setNicknameSearchLocation={this.setNicknameSearchLocation}
      clearAddressSearchLocation={this.clearAddressSearchLocation}
      blurAddressSearchLocation={this.blurAddressSearchLocation}
      toggleAddressDetailsLocation={this.toggleAddressDetailsLocation}
      onSkipClick={this.skipLocation}
      sameChecked={this.sameChecked}
      sameCheckedValue={this.sameCheckedValue()}
    />;
    const step3 = <FormDefaultStep3 ref='SignUpStep2'
      companyId={this.props.companyId}
      dispatch={this.props.dispatch}
      showAddDistributor={this.state.showAddDistributor}
      loopArrayDistributor={loopArrayDistributor}
      addDistributor={this.updateAddDistributor.bind(this, true)}
      onBackClick={this.nextBackStep.bind(this, 2)}
      onNextClick={this.nextBackStep.bind(this, 4)}
      onSkipClick={this.skipDistributor}
      numberDistributor={this.state.numberDistributor}
      numberLocations={this.state.numberLocations}
      selectOptionDistributor={this.selectOptionDistributor}
      selectOptionSubmissionType={this.selectOptionSubmissionType}
      saveDistributor={this.saveDistributor}
      cancelDistributor={this.cancelDistributor}
      changeHandlerDistributorInput={this.changeHandlerDistributorInput}
      changeHandlerDistributorInputDcn={this.changeHandlerDistributorInputDcn}
      step2Distributors={this.state.step2Distributors}
      step1Inputs={this.state.step1Inputs}
      step1Locations={this.state.step1Locations}
      showManualUpload={this.state.showManualUpload}
      updateCheckedLocations={this.updateCheckedLocations}
      step2DistributorsFromApi={this.state.step2DistributorsFromApi}
      selectDistributorFromDropdown={this.selectDistributorFromDropdown}
      step2UnsavedDistributor={this.state.step2UnsavedDistributor}
      distributorsHouse={this.state.distributorsHouse}
      selectDistributorHouse={this.selectDistributorHouse}
      distributorsHouseSelected={this.state.distributorsHouseSelected}
      checkOrderOnline={this.checkOrderOnline}
    />;
    const step4 = <FormDefaultStep4 isPorchDoor={this.props.isPorchDoor}
      companyId={this.props.companyId}
      companyBrand={{ name: this.props.companyName, code: this.props.companyAbbreviation }}
      companyText={this.props.companyText}
      dispatch={this.props.dispatch} company={this.props.company}
      changeHandlerStep3Inputs={this.changeHandlerStep3Inputs}
      selectOptionMemberGPO={this.selectOptionMemberGPO}
      selectOptionGPOName={this.selectOptionGPOName}
      selectOptionDistributorManufacturer={this.selectOptionDistributorManufacturer}
      step2Distributors={this.state.step2Distributors}
      step1Inputs={this.state.step1Inputs}
      step3Inputs={this.state.step3Inputs}
      nameOfCompaniesHandler={this.nameOfCompaniesHandler}
      authorizationChecked={this.authorizationChecked}
      authorizationFsrChecked={this.authorizationFsrChecked}
      authorizationReinhartChecked={this.authorizationReinhartChecked}
      authorizationLOTChecked={this.authorizationLOTChecked}
      authorizationInvalid={this.authorizationInvalid}
      authorizationReinhartInvalid={this.authorizationReinhartInvalid}
      handleChangeTerminationDate={this.handleChangeTerminationDate}
      onBackClick={this.nextBackStep} cancelDistributor={this.cancelDistributor}
      onDoneClick={() => this.doneSignUp()}
      hideLegalForm={this.props.hideLegalForm}
      signatureHandler={this.signatureHandler}
      signUpSalesRep={this.props.signUpSalesRep}
    />;

    const stepSuccess = {
      successResponse: this.state.successResponse
    }

    const step5 = this.props.showAlmostDone ? (stepSuccess.successResponse ? <FormDefaultSuccessfullyAlmostDone {...stepSuccess} /> : null) : <FormDefaultSuccessfully isPorchDoor={this.props.isPorchDoor} step1Inputs={this.state.step1Inputs} />;
    switch (this.props.step) {
      case 1:
        return step1;
      case 2:
        return step2;
      case 3:
        return step3;
      case 4:
        return step4;
      case 5:
        return step5;
      default:
        return step1;
    }
  };
  render() {
    return (
      <div className={'signup-form step-' + this.props.step}>
        {this.goToNextStep()}
      </div>
    )
  };
};

const mapStateToProps = state => {
  return {
    spinner: state.spinner.spinner,
    partnerSelected: state.company.partnerSelected,
    brandSelected: state.company.brandSelected,
    companyId: state.company.id,
    companyCode: state.company.code,
    companyName: state.company.nickname,
    companyText: state.company.text,
    companyAbbreviation: state.company.abbreviation,
    externalBrand: state.company.externalBrand,
    externalBrandCode: state.company.externalBrandCode,
    companyMaxLocations: state.company.maxLocations,
    segment: state.company.segment,
    subsegment: state.company.subsegment,
    signUpWithSaleRep: state.signUp.signUpWithSaleRep
  };
}

export default connect(mapStateToProps)(FormDefault);
