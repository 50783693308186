import React from 'react';
import Bullet from '../../../MasterComponents/Bullet/Bullet';
import LogoMainCompany from '../../../MasterComponents/Logos/LogoMainCompany';
import fsrLogo from '../../../assets/images/fsr-logo.svg';

const SignUpSimpleSidebar = (props) => {
  const { history, company, isFoodServiceRewards } = props;
  const bulletsAll = ['Earn cash back on 165,000 contracted items', 'Insights on your purchases', 'Dozens of additional savings programs','FREE – We will never charge you'];
  const bulletsCC = ['Group Purchasing Contracts: Save on 165k items across all categories including disposables, grocery, frozen and maintenance products', '350+ Manufacturers: Including top manufacturers like Tyson, Pactiv, Barilla, McCormick and Georgia Pacific', 'Indirect Spend Savings Programs: Save on non-food programs including Chefworks uniforms, Skechers footwear and Grainger maintenance supplies'];
  const bullets = company.id === 3 ? bulletsCC : bulletsAll;
  const logoText = company.id === 3 ? 'Foodservice Group Purchasing' : 'Foodservice Rebates';
  
  const BulletsMap = bullets.map( (b, i) => 
    <Bullet key={i} text={b} isFoodServiceRewards={isFoodServiceRewards} />
  );

  return (
    <div className={`left-signup simple ${isFoodServiceRewards ? 'fsr-bg' : ''}`}>
      <div className='logo'>
        {
          isFoodServiceRewards ?
          <img src={fsrLogo} alt='Food Service Rewards' style={{maxwidth: '250px', maxHeight: '150px'}} />
          :
          <a href='/' onClick={() => history.push('/')}>
            <LogoMainCompany style={{maxWidth: '250px', maxHeight: '150px'}} />
          </a>
        }
      </div>
      <div className='bullets'>
        <div className={'signup-simple-register-text ' + (company.id === 3 ? 'text-white' : 'text-yellow')}
          style={isFoodServiceRewards === true ? { color: '#6BA068' } : null}>
          {logoText}
        </div>
        <br />
        { BulletsMap }
      </div>
    </div>
  );
};

export default SignUpSimpleSidebar;