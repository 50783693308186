import React from 'react';
import CreatePasswordContent from './CreatePasswordContent';
import CreatePasswordSidebar from './CreatePasswordSidebar';

const CreatePassword = props => {

  const url = '/create-password/';
  const recordId = props.location.pathname.substring(url.length);

  return (
    <div className='container-change'>
      <CreatePasswordSidebar history={props.history} recordId={recordId} />
      <CreatePasswordContent history={props.history} recordId={recordId} />
    </div>
  )
}

export default CreatePassword;