import React, { Component }  from 'react';
import Modal from '../../../MasterComponents/Modals/Modal';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import ModalHeader from '../../../MasterComponents/Modals/ModalHeader';
import RadioButton from '../../../MasterComponents/RadioButton/RadioButton';
import Button from '../../../MasterComponents/Button/Button';
import { findModal } from '../../../MasterComponents/Modals/ModalsActions';
import { Row, Col } from 'reactstrap';
import LocationApi from '../LocationApi';
class LocationDistributorDeleteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [
        {
          name: 'one',
          text: 'I do not buy from this distributor anymore',
          checked: false
        },
        {
          name:'two',
          text: 'I entered this distributor by mistake',
          checked: false
        },
        {
          name: 'three',
          text: "This distributor won't deliver to me anymore",
          checked: false
        },
        {
          name: 'four',
          text: 'Other',
          checked: false
        }
      ],
      reasonText: ''
    }
  }

  onClickDelete = () => {
    const { modals, merDist, refresh, history, isDistributorDetail } = this.props;
    const { reasonText } = this.state;
    const { id } = merDist;
    const params = {
      removal_reason: reasonText
    };
    if(reasonText !== '') {
      LocationApi.deleteDistributor(id, params).then(res => {
        findModal(modals, 'idLocationDistributorDeleteModal').close();
        refresh(() => {
          if(isDistributorDetail) {
            history.push(`/dashboard/rebates/card`);
          }
        });
      }).catch(()=> {
      });
    }    
  }

  onChangeOption = (opt, value) => {
    const { options } = this.state;
    let reason = value.text === 'Other' ? '' : value.text;
    this.setState({
      options: options.map((l, i) => {
        if (i !== opt) {
          return {
            ...l,
            checked: false
          }
        }
        return {
          ...l,
          checked: true
        }
      }),
      reasonText: reason
    })
  }

  onChangeTextArea = (e) => {
    const text = e.target.value;
    this.setState({
      reasonText: text
    });
  }

  render() {
    const { options } = this.state;
    const optionsArr = options.map((o, i) => (
      <RadioButton key={`opt_${i}`} text={o.text} name={o.name} value={o} checked={o.checked} onChange={()=>this.onChangeOption(i, o)} />
    ));
    
    return (
      <Modal id='idLocationDistributorDeleteModal'>
        <ModalHeader className='text-size-18'>Delete Distributor</ModalHeader>
        <ModalBody className='py-0'>
          <label className='text-size-14 text-grey pt-3'>Please tell us why would you like to delete this distributor</label>
          { optionsArr }
          { options[3].checked &&
              <textarea className='col-12 border' col={4} rows={8} placeholder='Please specify the reason...' onChange={this.onChangeTextArea} />
          }
          <Row className='py-4'>
            <Col>
              <Button onClick={this.onClickDelete} variant='primary' text='Delete' size='sm' />
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    )
  }
}

export default LocationDistributorDeleteModal;
