import Joi from 'joi';
import { useReducer, useRef, useState } from 'react';
import { getStatesProvinces } from '../../../MasterComponents/Helper/getPlaces';
import SignUpApi from '../../SignUp/SignUpApi';

const INITIAL_STATE = {
  street: '',
  city: '',
  state: null,
  zipcode: '',
  predictions: [],
  isLoading: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOADING':
      return {
        ...state,
        isLoading: true
      };
    case 'RESULTS':
      return {
        ...state,
        isLoading: false,
        predictions: action.value.map(pred => {
          return {
            label: pred.description,
            value: pred.place_id
          }
        })
      };
    case 'ONE':
      return {
        ...state,
        [action.field]: action.value
      };
    default:
      return {
        ...state,
        ...action.value
      };
  }
};

const SCHEMA = Joi.object({
  street: Joi.string().required().min(3),
  city: Joi.string().required().min(2),
  state: Joi.object().required().invalid(null),
  zipcode: Joi.string().required().pattern(/^[0-9]{5}?$/),
});

const SCHEMA_CA = Joi.object({
  street: Joi.string().required().min(3),
  city: Joi.string().required().min(2),
  state: Joi.object().required().invalid(null),
  zipcode: Joi.string().required().pattern(/[a-zA-Z0-9]{3}[ ][a-zA-Z0-9]{3}/),
});

const usePlacesForm = (country) => {
  const inputState = useRef(null);
  const [{predictions, loading, ...state}, dispatch] = useReducer(reducer, INITIAL_STATE);
  const [invalid, setInvalid] = useState([]);

  const onSearch = (input) => {
    dispatch({type: 'LOADING'});
    SignUpApi.getPlaceSearch(input, country).then(({data}) => {
      dispatch({type: 'RESULTS', value: data.data.predictions});
    }).catch(err => {
      console.error(err);
      dispatch({type: 'RESULTS', value: []});
    });
  };

  const onChange = (value, name) => {
    dispatch({type: 'ONE', field: name, value: value});
  };

  const onFocus = (name) => {
    setInvalid(invalid.filter(f => f !== name));
  };

  const updateAll = (values) => {
    dispatch({type: 'MULTIPLE', value: values});
  };

  const street = {
    onChange,
    value: state.street,
    isInvalid: invalid.some(f => f === 'street'),
    onFocus: () => onFocus('street')
  };

  const city = {
    onChange,
    value : state.city,
    isInvalid: invalid.some(f => f === 'city'),
    onFocus: () => onFocus('city')
  };

  const usState = {
    value: state.state,
    onChange: (value) => {
      onChange(value, 'state');
    },
    ref: inputState,
    options: getStatesProvinces(country)
  }

  const zipcode = {
    onChange,
    value : state.zipcode,
    isInvalid: invalid.some(f => f === 'zipcode'),
    onFocus: () => onFocus('zipcode')
  };

  const validate = () => {
    const SCHEMA_TO_USE = country === 'us' ? SCHEMA : SCHEMA_CA;
    const { isLoading, ...address } = state;
    const validation = SCHEMA_TO_USE.validate(address, {abortEarly: false, errors: {wrap: {label: ''}}});
    inputState.current.isValid();
    setInvalid(validation.error ? validation.error.details.map(e => e.context.key) : []);
    return validation;
  };

  return {
    street,
    city,
    state: usState,
    zipcode,
    predictions,
    updateAll,
    validate,
    invalid,
    setInvalid,
    inputState,
    onSearch
  }
};

export default usePlacesForm;