import React, { Component } from 'react';
import GridCol from '../../MasterComponents/Grid/GridCol';
import  './ClaimCash.scss';

class ClaimCashChecksEmpty extends Component {
    render() {
        return (
            <GridCol className='claim-text2 py-2 text-center px-0' style={{ lineHeight: '24px'}}>
                We did not find any payments associated with <br/>
                that Quickbooks Vendor Code. Please email <br/>
                <a href='/' onClick={e => {e.preventDefault();}}> customersupport@buyersedgeplatform.com </a> if you <br/>
                have questions or need help.
            </GridCol>
           
        );
    }
}

export default ClaimCashChecksEmpty;