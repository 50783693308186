import React from 'react';
import { Row, Col } from 'reactstrap';
import ContractSortArrow from './ContractSortArrow';

const ContractTableHeader = ({manufacturer, status, startDate, endDate, toggle}) => {
	return (
		<Row className='py-2 border-bottom text-size-10 line-height-12 text-grey items-list-rows-headers text-uppercase unselectable items-list-rows-headers'>
			<Col className='clickable' onClick={() => toggle('manufacturer')}>
				Manufacturer
				<ContractSortArrow asc={manufacturer === 1} show={manufacturer} />
			</Col>
			<Col className='clickable' onClick={() => toggle('status')}>
				Status
				<ContractSortArrow asc={status === 1} show={status} />
			</Col>
			<Col className='clickable' onClick={() => toggle('startDate')} xs={3}>
				Start Date
				<ContractSortArrow asc={startDate === 1} show={startDate} />
			</Col>
			<Col className='clickable' onClick={() => toggle('endDate')} xs={3}>
				End Date
				<ContractSortArrow asc={endDate === 1} show={endDate} />
			</Col>
		</Row>
	);
};

export default ContractTableHeader;