import { spinner } from "../Spinner/SpinnerActions";
import DashboardApi from "../../views/Dashboard/DashboardApi";
import { showModal } from "../Modals/ModalsActions";
import EnterCredentialsModal from "../../views/Dashboard/Modals/EnterCredentialsModal";
import axios from "axios";

const showEnterCredentials = function (index, merDist, dcns) {
  const modalProps = {
    index: index,
    merchantDistributor: merDist,
    saveCredentials: saveCredentials.bind(this),
    verifyCredentials: verifyCredentials.bind(this),
    dcns: dcns
  }
  this.props.dispatch(showModal(EnterCredentialsModal, 'enterCredentialsModal', modalProps));
};

const verifyCredentials = function(username, password, dcns, cb) {
  const { merchantDistributor, dispatch } = this.props;
  dispatch(spinner(true));
  DashboardApi.verifyCreds( merchantDistributor.distributorId, merchantDistributor.merchantId, username, password, dcns).then(({data}) => {
    dispatch(spinner(false));
    cb(data);
  }).catch(({data}) => {
    dispatch(spinner(false));
    cb(data);
  });
};

const saveCredentials = function(index, username, password, isCheckTermsConditions) {
  const { dispatch } = this.props;
  const { credentials, merchantDistributors } = this.state;
  if (index === 'all') {
    this.setState({
      credentialsAll: {
        username: username,
        password: password,
        isCheckTermsConditions:isCheckTermsConditions,
        credentialsAccepted : true
      }
    });

    let callArrays = [];
    dispatch(spinner(true));
    for (let i = 0; i < merchantDistributors.length; i++) {
      const merDist = merchantDistributors[i];
      const params = {
        merchantDistributorId: merDist.id,
        username: username,
        password: password
      };
      const call = DashboardApi.editCredentials(merDist.id, params);
      callArrays.push(call);
    }
    axios.all(callArrays).then(axios.spread((...res) => {
      dispatch(spinner(false));
    })).catch(err => {
      console.error(err);
    });
  } else {
    this.setState({
      credentials: credentials.map((item, i) => {
        if (i !== index) {
          return item;
        }
        return {
          username: username,
          password: password,
          isCheckTermsConditions:isCheckTermsConditions,
          credentialsAccepted : true
        }
      })
    });

    dispatch(spinner(true));
    const params = {
      merchantDistributorId: merchantDistributors[index].id,
      username: username,
      password: password
    };
    DashboardApi.editCredentials(merchantDistributors[index].id, params).then(({data}) => {
      dispatch(spinner(false));
    }).catch(err => {
      console.error(err);
    });
  }
};

export default showEnterCredentials;
