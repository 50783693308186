import React from 'react';
import PropTypes from 'prop-types';

const LogoDashboardFooterCompany = props => (
  <img id={props.id} className={props.className}
    style={props.style}
    src={process.env.REACT_APP_COMPANY_DASHBOARD_BANNER_FOOTER_LOGO || process.env.REACT_APP_COMPANY_DASHBOARD_FOOTER_LOGO || process.env.REACT_APP_COMPANY_MAIN_LOGO}
    alt={`${process.env.REACT_APP_COMPANY_NICKNAME} logo`} />
);

LogoDashboardFooterCompany.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string
}

export default LogoDashboardFooterCompany;