import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import GridRow from '../../MasterComponents/Grid/GridRow';
import GridCol from '../../MasterComponents/Grid/GridCol';
import GridContainer from '../../MasterComponents/Grid/GridContainer';
const imgInsightsEmpty = 'https://rebate-portal.s3.amazonaws.com/resources/insights/images/insights-empty.png';

class InsightsEmpty extends Component {
    render() {
        const { displayReports } = this.props;
        return (
            <GridContainer className={isMobile ? 'pt-5' : 'p-5 mt-5'} style={{height: isMobile ? '400px' : (displayReports ? '300px' : '500px')}}>
                <GridRow>
                    <GridCol className='text-center'>
                        <img src={imgInsightsEmpty} alt='empty' />
                    </GridCol>
                </GridRow>
                <GridRow>
                    <GridCol className='text-center py-3 insights-empty-text'>
                        We're sorry, but we can't display any data for you right now.
                    </GridCol>
                </GridRow>
                <GridRow>
                    <GridCol className='text-center pb-5 insights-empty-text2'>
                        Please double check that you have added and successfully activated your distributors. 
                    </GridCol>
                </GridRow>
            </GridContainer>
            
            
        );
    }
}

const mapStateToProps = state => {
    return {
      displayReports: state.company.displayReports
    };
  }
export default connect(mapStateToProps)(InsightsEmpty);