import React from 'react';
import { Container } from 'reactstrap';

const   InsideTrack = ({insidetrackId}) => {
  const jwt = localStorage.getItem('authKey');
  const insideTrackLink = process.env.REACT_APP_ENVIRONMENT === 'production' ? `https://myinsidetrack.com/login?platformJwt=${jwt}&insidetrackId=${insidetrackId}&hideTopMenu=1` : `https://angular.buyersedgepurchasing.com/login?platformJwt=${jwt}&insidetrackId=${insidetrackId}&hideTopMenu=1`;

  return (
    <Container fluid className='blog col-12 p-0 h-100'>
      <iframe  title='FoodCost' src={insideTrackLink} width='100%' height='100%' frameBorder='0'></iframe>
    </Container>
  );
};

export default InsideTrack;