import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import GridCol from '../../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../../MasterComponents/Grid/GridRow';
import InputLabel from '../../../../MasterComponents/InputLabel/InputLabel';
import emailDA from '../../../../assets/images/email.svg';
import emailCompImg from '../../../../assets/images/emailCompanies.svg';
import FormDefaultBrowserExtension from './FormDefaultBrowserExtension';
import FormDefaultBeyondPrime from './FormDefaultBeyondPrime';

class FormDefaultSuccessfullyAlmostDone extends Component {
  done = () => {
      window.location.reload();
  }
  render() {
    const { companyId, successResponse } = this.props;
    const emailImg = companyId === 1 ? emailDA : emailCompImg;
    const props = {
      successResponse: successResponse,
      companyId
    }
    return (
      <GridRow minWidth='100%'>
        <GridCol>
          <GridRow>
            <GridCol className={'text-center ' + (isMobile ? 'col-12 text-center': 'col-3 text-left')}>
              <img src={emailImg} alt='email' />
            </GridCol>
            <GridCol>
              <InputLabel className={'text-size-40 line-height-48 ' + (isMobile ? 'col-12 text-center ': '')}>Thank you!</InputLabel>
              <InputLabel className={'text-size-16 line-height-24 ' + (isMobile ? 'col-12 text-center ': '')}>Your information has been submitted successfully.</InputLabel>
              <InputLabel className={'text-size-16 line-height-24 ' + (isMobile ? 'col-12 text-center ': '')}>We will send you an activation email, please use it to log in.</InputLabel>
            </GridCol>
          </GridRow>
            <br/>
          <GridRow>
            <GridCol className={isMobile ? 'px-3': ''}>
              <FormDefaultBrowserExtension {...props} />
            </GridCol>
          </GridRow>
          <GridRow className={isMobile ? '': 'pb-5'}>
            <GridCol className={isMobile ? 'px-3': ''}>
              <FormDefaultBeyondPrime {...props}/>
            </GridCol>
          </GridRow>
        </GridCol>
      </GridRow>
    )
  }
}

const mapStateToProps = state => {
  return {
    companyId: state.company.id
  };
}

export default connect(mapStateToProps)(FormDefaultSuccessfullyAlmostDone);