import { Component } from 'react';
import { connect } from 'react-redux';
import { update } from './ResizeListenerActions';

class ResizeListener extends Component {
  componentDidMount = () => {
    window.addEventListener("resize", this.updateDimensions);
    this.props.dispatch(update(window.innerWidth, window.innerHeight));
  }
  updateDimensions = () => {
    this.props.dispatch(update(window.innerWidth, window.innerHeight));
  }
  render() {
    return (
      null
    );
  }
}

const mapStateToProps = state => {
  return {};
}

export default connect(mapStateToProps)(ResizeListener);