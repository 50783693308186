
import React, { Component } from 'react';
import FaqCardContent from './FaqCardContent';
import { Row } from 'reactstrap';
class FaqCard extends Component {

  render() {
    const { faqs, clickShow, idTitle } = this.props;
    const faqArrayMap = faqs.map((m, i)=> (
      <FaqCardContent  key={`faqCardContent_${i}`} {...m} clickShow={clickShow} idTitle={idTitle} />
    ));
    
    return (
      <Row className='mx-0'>
        {faqArrayMap}
      </Row>
    )
  }
}

export default FaqCard;
