import { apiInstance } from '../../config/tokenApi';

const authenticate = (email, password, companyId) => {
    return apiInstance.post('/auth', {
            username: email,
            password: password,
            companyId : companyId
    });
};

const adminAuthenticate = (email) => {
    const token = localStorage.getItem('authKeyAdmin');
    return apiInstance.post('/auth/admin', {
        username: email
    }, {
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    });
};

const authenticateMicrosoft = (payload) => {
    return apiInstance.post('/auth/microsoft', payload);
};

const activateUser = (resetToken) => {
    return apiInstance.post('/auth/activate-user', {
        token: resetToken
    });
};

const getMerchants = (params) => {
    return apiInstance.get('merchant/all', {params});
}

const getMerchantParents = (params) => {
    return apiInstance.get('merchant/all-parents', {params});
}

const getMerchantChildren = (id, params) => {
    return apiInstance.get(`merchant/all-children/${id}`, {params});
}

const getUsers = (id) => {
    return apiInstance.get(`merchant/user/${id}`);
}

export default {
    authenticate,
    adminAuthenticate,
    authenticateMicrosoft,
    activateUser,
    getMerchants,
    getMerchantParents,
    getMerchantChildren,
    getUsers
};
