import React from 'react';
import { Button, Col, Input, InputGroup, Row } from 'reactstrap';
import config from '../../../../config/config';
import IconSearch from '../../../../MasterComponents/Icons/IconSearch';
import ContractFilters from './ContractFilters';

const ContractTop = ({filters, onAddNew, disabledActions}) => {
	return (
		<Row className='p-3'>
			<Col>
				<ContractFilters {...filters} />
			</Col>
			<Col xs={4}>
				<InputGroup>
					<Input className='search rb-input shadow-none' placeholder='Search...' {...filters.search} />
					<IconSearch color={config.colors.rbDarkGrey} />
				</InputGroup>
			</Col>
			<Col className='mw-fc p-0'>
				<Button color='rb-blue' size='sm' block className='py-2 shadow-none active' disabled={disabledActions} onClick={onAddNew}>
					ADD NEW
				</Button>
			</Col>
		</Row>
	);
};

export default ContractTop;