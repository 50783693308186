import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import '../SignUp.scss';
import Bullet from '../../../MasterComponents/Bullet/Bullet';
import LogoMainCompany from '../../../MasterComponents/Logos/LogoMainCompany';
import { connect } from 'react-redux';
import { getBgImageLeft } from '../../../config/Company/CompanyHelper';
const imgFlag = 'https://rebate-portal.s3.amazonaws.com/resources/common/flag-ca.png'

class SignUpWhitelabelSidebar extends Component {
    goHome = (e) => {
        e.preventDefault();
        localStorage.setItem('isSignUpRoute', 0);
        this.props.history.push('/');
    };
    getChannelLogo = () => {
        const { partner } = this.props;
        const path = 'https://rebate-portal.s3.amazonaws.com/lops/';
        if (partner) {
            if (partner.abbreviation !== 'sheng') {
              return (
                  <div className='logo m-0'>
                      <a href='/'>
                          <img style={{maxWidth:'250px', maxHeight:'150px'}} src={`${path}${partner.abbreviation}-white.png`} alt={`${partner.name} Logo`} />
                      </a>
                  </div>
              );
            }
        }
        return null;
    }
    render() {
        const { registerText, registerBullets, selfRegisterText, selfRegisterBullets, isSelf, companyId, history } = this.props;
        const bulletsArray = isSelf && selfRegisterBullets.length > 0 ? selfRegisterBullets : registerBullets;
        const countryUrl = history.location.pathname.split('/')[2] === 'canada' ? 'CA': 'US';
        const bgImageLeft = getBgImageLeft(companyId);
        const BulletsMap = bulletsArray.map( (b, i) => 
            <Bullet key={i} text={b} />
        );
        const RegisterTextDiv = <div>{ ReactHtmlParser( isSelf ? selfRegisterText || registerText : registerText) }</div>;
        return (
            <div className='left-signup' style={{backgroundImage: `url(${bgImageLeft})`}}>
                <div className='logo'>
                    <a href='/' onClick={this.goHome}>
                        <LogoMainCompany style={{maxWidth: '250px', maxHeight: '150px'}} />
                    </a>
                </div>
                <div className='bullets'>
                    { RegisterTextDiv }
                    <br />
                    { BulletsMap }
                </div>
                { (companyId === 12 && countryUrl === 'CA') &&
                    <div className='logo mt-0'>
                        <img style={{maxWidth:'150px', maxHeight:'90px'}} src={imgFlag} alt='Canada' />
                    </div>
                }
                {this.getChannelLogo()}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        companyId: state.company.id,
        registerText: state.company.text.registerText,
        registerBullets: state.company.text.registerBullets,
        selfRegisterText: state.company.text.selfRegisterText,
        selfRegisterBullets: state.company.text.selfRegisterBullets,
    };
}

export default connect(mapStateToProps)(SignUpWhitelabelSidebar);
