import { Component } from 'react';

class SignUpRedirect extends Component {
  componentDidMount() {
    const { history, customUrl } = this.props;
    const url = `${history.location.pathname}/1${history.location.search}`;
    history.replace(customUrl || url);
  };
  render() {
    return null;
  };
}

export default SignUpRedirect;