import React from 'react';
import { isMobile } from 'react-device-detect';
import GridCol from '../../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../../MasterComponents/Grid/GridRow';
import InputLabel from '../../../../MasterComponents/InputLabel/InputLabel';
import DropDownListSelect from '../../../../MasterComponents/DropDownList/DropDownListSelect';
import ModalBody from '../../../../MasterComponents/Modals/ModalBody';

const DistributorStepAddNewModalLocations = ({locationsMap, dropdownHouse, locationsInvalid}) => {
  return (
    <ModalBody className={isMobile ? 'h-100 pt-0' : ''}>
      {
        !dropdownHouse.isDisabled ?
        <GridRow className='pb-3'>
          <GridCol>
            <InputLabel text='Distributor House *' />
            <DropDownListSelect {...dropdownHouse} />
            <InputLabel text='Correct house is mandatory to earn rebates' />
          </GridCol>
        </GridRow>
        : <></>
      }
      <GridRow className='my-3'>
        <GridCol className={'font-weight-bold' + (locationsInvalid ? ' text-red' : '')}>
          Add this distributor to:
        </GridCol>
      </GridRow>
      {locationsMap}
    </ModalBody>
  );
};

export default DistributorStepAddNewModalLocations;