import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconTruck = props => (
  <svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 32 21" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Distributor-Dashboard-Homepage" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Homepage" transform="translate(-1226.000000, -370.000000)" fill={!props.isActive ? props.color : props.activeColor}>
        <g id="Distributors" transform="translate(25.000000, 220.000000)">
          <g id="Group-4" transform="translate(700.000000, 0.000000)">
            <g id="offline-distributor" transform="translate(0.000000, 62.000000)">
              <g id="icons/truck" transform="translate(501.000000, 88.000000)">
                <path d="M17,15 L1,15 C0.447,15 0,14.552 0,14 L0,1 C0,0.448 0.447,0 1,0 L17,0 C17.553,0 18,0.448 18,1 L18,14 C18,14.552 17.553,15 17,15 Z M2,13 L16,13 L16,2 L2,2 L2,13 Z" id="Shape"></path>
                <path d="M7,21 C4.794,21 3,19.206 3,17 C3,14.794 4.794,13 7,13 C9.206,13 11,14.794 11,17 C11,19.206 9.206,21 7,21 Z M7,15 C5.897,15 5,15.897 5,17 C5,18.103 5.897,19 7,19 C8.103,19 9,18.103 9,17 C9,15.897 8.103,15 7,15 Z" id="Shape"></path>
                <path d="M24,21 C21.794,21 20,19.206 20,17 C20,14.794 21.794,13 24,13 C26.206,13 28,14.794 28,17 C28,19.206 26.206,21 24,21 Z M24,15 C22.897,15 22,15.897 22,17 C22,18.103 22.897,19 24,19 C25.103,19 26,18.103 26,17 C26,15.897 25.103,15 24,15 Z" id="Shape"></path>
                <rect id="Rectangle" x="10.125" y="16" width="10.75" height="2"></rect>
                <path d="M31,18 L27.083,18 L27.083,16 L30,16 L30,11.222 L27.28,10.165 C27.053,10.077 26.867,9.909 26.755,9.693 L24.778,5.884 L17.114,5.884 L17.114,3.884 L25.385,3.884 C25.758,3.884 26.101,4.092 26.273,4.424 L28.356,8.438 L31.362,9.606 C31.747,9.755 32,10.125 32,10.538 L32,17 C32,17.552 31.553,18 31,18 Z" id="Path"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

IconTruck.propTypes = {
  color: PropTypes.string,
  activeColor: PropTypes.string,
  isActive: PropTypes.bool,
  size: PropTypes.number,
};

IconTruck.defaultProps = {
  activeColor: config.colors.rbYellow,
  color: config.colors.rbWhite,
  isActive: false,
  size: 16,
};

export default IconTruck;