import { apiInstance } from '../../config/tokenApi';

const activateDistributorManual = (id, merchantId, files, dateRange, email, phone, dcn) => {
  let formData = new FormData();
  if (Array.isArray(files)) {
    files.forEach((file, i) => {
      formData.append('file_' + i, file.file);;
    });
  } else {
    files = []
  }
  formData.append('fileCount', files.length);
  formData.append('submissionType', 'manual');
  formData.append('dateRange', dateRange);
  formData.append('emailReminder', email);
  formData.append('phoneReminder', phone);
  formData.append('merchantId', merchantId);
  formData.append('dcn', dcn);
  return apiInstance.post(`distributor/${id}/activate`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

const activateDistributor = (id, merchants, submissionType) => {
  const data = {
    submission_type: submissionType,
    merchants: merchants
  }
  return apiInstance.post(`distributor/${id}/activate`, data);
}

const activateMerchantDistributor = (merchantDistributors, submissionType) => {
  const data = {
    submission_type: submissionType,
    merchantDistributors: merchantDistributors
  }
  return apiInstance.post(`merchant-distributor/activate`, data);
}

const activateMerchantDistributorManual = (merchantDistributorId, files, dateRange, email, phone, dcn) => {
  let formData = new FormData();
  if (Array.isArray(files)) {
    files.forEach((file, i) => {
      formData.append('file_' + i, file.file);;
    });
  } else {
    files = []
  }
  formData.append('fileCount', files.length);
  formData.append('submissionType', 'manual');
  formData.append('dateRange', dateRange);
  formData.append('emailReminder', email);
  formData.append('phoneReminder', phone);
  formData.append('merchantDistributorId', merchantDistributorId);
  formData.append('dcn', dcn);
  return apiInstance.post(`merchant-distributor/activate`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

const changeDistributorHouse = (id, merchantId, newDistId) => {
  const data = {
    distributor: newDistId,
  }
  return apiInstance.patch(`distributor/${id}/merchant/${merchantId}`, data);
}

const verifyCreds = (distributorId, merchantId, username, password, dcns) => {
  const data = {
    distributor_id: distributorId,
    merchant_id: merchantId,
    username: username,
    password: password,
    dcns: dcns
  };
  return apiInstance.post(`distributor/verify`, data);
}

const getDistributorsAll = (companyId, search, page) => {
  return apiInstance.get('distributor/all', {
    params: {
      search: search,
      page: page,
      companyId: companyId
    }
  });
}

const getDistributorsFixed = () => {
  return apiInstance.get('distributor/fixed');
}

const getDistributor = (id) => {
  return apiInstance.get(`/distributor/${id}/merchants`);
}

const getDistributorSubmissions = (id) => {
  return apiInstance.get(`distributor/${id}/submission`);
}

const getDistributorsHouse = (companyId, id) => {
  return apiInstance.get(`/distributor/${id}/house`, {
    params: {
      companyId: companyId
    }
  });
}

const addDistributor = (params) => {
  return apiInstance.post(`merchant-distributor`, params);
}

const editDistributor = (id, params) => {
  return apiInstance.patch(`distributor/${id}`, params);
}

const editCredentials = (id, params) => {
  return apiInstance.patch(`merchant-distributor/${id}/credential`, params);
}

const deleteDistributor = (id) => {
  return apiInstance.delete(`merchant-distributor/${id}`);
}

const editMerchantDistributor = (id, params) => {
  return apiInstance.patch(`merchant-distributor/${id}`, params);
}

const getDistributors = () => {
  return apiInstance.get('/distributor');
}

const getMerchants = () => {
  return apiInstance.get('/merchant', {
    params: {
      limit: 2000
    }
  });
}

const addMerchant = (params) => {
  return apiInstance.post('/merchant', params);
}

const editMerchant = (id, params) => {
  return apiInstance.patch(`/merchant/${id}`, params);
}

const deleteMerchant = (id, params) => {
  return apiInstance.delete(`/merchant/${id}`);
}

const changeActiveMerchant = (id) => {
  return apiInstance.post(`/user/active-merchant/${id}`);
}

const updateDistributorFile = (id, files, dateRange) => {
  let formData = new FormData();
  if (Array.isArray(files)) {
    files.forEach((file, i) => {
      formData.append('file_' + i, file.file);;
    });
  } else {
    files = []
  }
  formData.append('fileCount', files.length);
  formData.append('dateRange', dateRange);
  return apiInstance.post(`merchant-distributor/${id}/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

const getUsers = () => {
  return apiInstance.get(`/user`);
}

const changeUserPassword = (current_password, password, password_repeat) => {
  const data = {
    current_password: current_password,
    password: password,
    password_repeat: password_repeat
  }
  return apiInstance.post(`/user/change-password`, data);
}

const inviteUser = (params) => {
  return apiInstance.post(`/user/invite`, params);
}

const editUser = (id, params) => {
  return apiInstance.patch(`/user/${id}`, params);
}

const deleteUser = (id) => {
  return apiInstance.delete(`/user/${id}`);
}

export default {
  activateDistributorManual,
  activateDistributor,
  verifyCreds,
  updateDistributorFile,
  getDistributorsAll,
  getDistributorsFixed,
  getDistributor,
  getDistributorsHouse,
  addDistributor,
  getDistributors,
  getDistributorSubmissions,
  editDistributor,
  deleteDistributor,
  getMerchants,
  addMerchant,
  editMerchant,
  deleteMerchant,
  changeActiveMerchant,
  getUsers,
  changeUserPassword,
  inviteUser,
  editUser,
  deleteUser,
  changeDistributorHouse,
  editCredentials,
  activateMerchantDistributor,
  activateMerchantDistributorManual,
  editMerchantDistributor
};
