import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ModalHeader from '../../../MasterComponents/Modals/ModalHeader';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import Modal from '../../../MasterComponents/Modals/Modal';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import orderImg from '../../../assets/images/order-insights.svg';
import sampleImg from '../../../assets/images/sample-insights.svg';
import deleteImg from '../../../assets/images/delete-insights.svg';
import InsightsBodyOpportunitiesDeleteModal from './InsightsBodyOpportunitiesDeleteModal';
import InsightsBodyOpportunitiesOrderModal from './InsightsBodyOpportunitiesOrderModal';
import InsightsBodyOpportunitiesSampleModal from './InsightsBodyOpportunitiesSampleModal';
import { showModal } from '../../../MasterComponents/Modals/ModalsActions';
import Button from '../../../MasterComponents/Button/Button';
import { Button as BsButton } from 'reactstrap';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import config from '../../../config/config';
import Erni from '../../../MasterComponents/Erni/Erni';
class InsightsBodyOpportunitiesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showing: 5,
      openMatches: []
    };
  };
  toggleMatch = (pId) => {
    const { openMatches } = this.state;
    this.setState({
      openMatches: openMatches.some(m => m === pId) ? openMatches.filter(m => m !== pId) : [...openMatches, pId]
    });
  };
  showMore = () => {
    this.setState({
      showing: this.state.showing + 5
    });
  };
  showLess = () => {
    this.setState({
      showing: this.state.showing - 5
    });
  };
  openSample = (item) => {
    const { dispatch, platformIds } = this.props;
    this.props.dispatch(showModal(InsightsBodyOpportunitiesSampleModal, 'InsightsBodyOpportunitiesSampleModal', {...item, dispatch, platformIds}));
  };
  openOrder = (item) => {
    const { dispatch, platformIds } = this.props;
    this.props.dispatch(showModal(InsightsBodyOpportunitiesOrderModal, 'InsightsBodyOpportunitiesOrderModal', {...item, dispatch, platformIds}));
  };
  openDelete = (item) => {
    this.props.dispatch(showModal(InsightsBodyOpportunitiesDeleteModal, 'InsightsBodyOpportunitiesDeleteModal', {
      ...item,
      dispatch: this.props.dispatch,
      refresh: this.props.refresh,
      datePeriod: this.props.datePeriod
    }));
  };
  render() {
    const { dataItemsAll, companyId } = this.props;
    const { showing, openMatches } = this.state;
    const hasPackSize = (companyId === 1 || companyId === 3 || companyId === 4) ? true : false;
    const appliedColor = (companyId === 3) ? config.colors.primary : config.colors.secondary;
    const uniqueOriginalProductIds = dataItemsAll.map(d => d.original.productId).filter((v, i, a) => a.indexOf(v) === i);

    const matches = uniqueOriginalProductIds.map(pId => ({
      productId: pId,
      data: dataItemsAll.filter(d => d.original.productId === pId)
    }));

    const showShowMore = matches.length > showing;
    const showShowLess = showing > 5;

    const originalsAndMatchesMap = matches.slice(0, showing).map((m, i) => (
      <Fragment key={`ori_and_matc_${i}`}>
        <GridRow className='insights-box m-0'>
          <GridCol>
            <GridRow className='text-left text-capitalize text-size-16 font-weight-bold'>
              <GridCol style={{wordWrap : 'break-word'}} className='bg-striped pt-3'>
                {m.data[0].original.description}
              </GridCol>
              {
                !openMatches.some(c => c === m.productId) &&
                <GridCol style={{wordWrap : 'break-word'}} className='pt-3'>
                  {m.data[0].match.description}
                </GridCol>
              }
            </GridRow>
            <GridRow className='text-left text-capitalize text-size-14'>
              <GridCol style={{wordWrap : 'break-word'}} className='bg-striped pt-3'>
                {m.data[0].original.mfr}
              </GridCol>
              {
                !openMatches.some(c => c === m.productId) &&
                <GridCol style={{wordWrap : 'break-word'}} className='pt-3'>
                  {m.data[0].match.mfr}
                </GridCol>
              }
            </GridRow>
            { hasPackSize && 
              <GridRow className='text-left text-capitalize text-size-14'>
                <GridCol className='bg-striped py-3'>
                  {m.data[0].original.packsize}
                </GridCol>
                {
                  !openMatches.some(c => c === m.productId) &&
                  <GridCol className='py-3'>
                    {m.data[0].match.packsize}
                  </GridCol>
                }
              </GridRow>
            }
            <GridRow className='text-left text-capitalize text-size-14'>
              <GridCol className='bg-striped py-3'>
                {Number(m.data[0].original.qty.toFixed(2))}
              </GridCol>
              {
                !openMatches.some(c => c === m.productId) &&
                <GridCol className='py-3'>
                  {Number(m.data[0].match.qty.toFixed(2))}
                </GridCol>
              }
            </GridRow>
          </GridCol>
        </GridRow>
        <GridRow className={'insights-box m-0 text-size-14 text-left no-gutters align-items-center py-2' + (m.data.length === 1 ? ' mb-3' : '')}>
          <GridCol className='pl-2' style={{color: appliedColor}}>
            Save ${(m.data[0].match.annualRebate ? m.data[0].match.annualRebate : 0).toLocaleString('en', {minimumFractionDigits: 2})}
          </GridCol>
          <GridCol maxWidth='fit-content' className='pr-2 pl-0 insights-show-more text-size-12' onClick={this.openOrder.bind(this, m.data[0])}>
            <img src={orderImg} alt='Order' className='mr-2' /> Order
          </GridCol>
          <GridCol maxWidth='fit-content' className='pr-2 pl-0 insights-show-more text-size-12' onClick={this.openSample.bind(this, m.data[0])}>
            <img src={sampleImg} alt='Sample' className='mr-2' /> Sample
          </GridCol>
          <GridCol maxWidth='fit-content' className='pr-2 pl-0 insights-show-more text-size-12' onClick={this.openDelete.bind(this, m.data[0])}>
            <img src={deleteImg} alt='Delete' className='mr-2' /> Delete
          </GridCol>
        </GridRow>
        {
          m.data.length > 1 &&
          <>
          {
            openMatches.some(c => c === m.productId) &&
            m.data.map((d, ii) => (
              <Fragment key={`more_${i}_${ii}`}>
                <GridRow className='insights-box m-0'>
                  <GridCol>
                    <GridRow className='text-left text-capitalize text-size-16 font-weight-bold'>
                      <GridCol style={{wordWrap : 'break-word'}} className='pt-3'>
                        {d.match.description}
                      </GridCol>
                    </GridRow>
                    <GridRow className='text-left text-capitalize text-size-14'>
                      <GridCol style={{wordWrap : 'break-word'}} className='pt-3'>
                        {d.match.mfr}
                      </GridCol>
                    </GridRow>
                    { hasPackSize && 
                      <GridRow className='text-left text-capitalize text-size-14'>
                        <GridCol className='py-3'>
                          {d.match.packsize}
                        </GridCol>
                      </GridRow>
                    }
                    <GridRow className='text-left text-capitalize text-size-14'>
                      <GridCol className='py-3'>
                        {Number(d.match.qty.toFixed(2))}
                      </GridCol>
                    </GridRow>
                  </GridCol>
                </GridRow>
                <GridRow className='insights-box m-0 text-size-14 text-left no-gutters align-items-center py-2'>
                  <GridCol className='pl-2' style={{color: appliedColor}}>
                    Save ${(d.match.annualRebate ? d.match.annualRebate : 0).toLocaleString('en', {minimumFractionDigits: 2})}
                  </GridCol>
                  <GridCol maxWidth='fit-content' className='pr-2 pl-0 insights-show-more text-size-12' onClick={this.openOrder.bind(this, d)}>
                    <img src={orderImg} alt='Order' className='mr-2' /> Order
                  </GridCol>
                  <GridCol maxWidth='fit-content' className='pr-2 pl-0 insights-show-more text-size-12' onClick={this.openSample.bind(this, d)}>
                    <img src={sampleImg} alt='Sample' className='mr-2' /> Sample
                  </GridCol>
                  <GridCol maxWidth='fit-content' className='pr-2 pl-0 insights-show-more text-size-12' onClick={this.openDelete.bind(this, d)}>
                    <img src={deleteImg} alt='Delete' className='mr-2' /> Delete
                  </GridCol>
                </GridRow>
              </Fragment>
            ))
          }
          {
            openMatches.some(c => c === m.productId) ?
            <GridRow className='mb-3'>
              <GridCol>
                <BsButton onClick={this.toggleMatch.bind(this, m.productId)} className='btn-rb-dark-blue btn-bs shadow-none'>
                  {`HIDE MATCH${(m.data.length-1 > 1 && 'ES') || ''}`} <IoIosArrowUp color='white' fontSize='12px'/>
                </BsButton>
              </GridCol>
            </GridRow>
            :
            <GridRow className='mb-3'>
              <GridCol>
                <BsButton onClick={this.toggleMatch.bind(this, m.productId)} className='btn-bs shadow-none' style={{backgroundColor: config.colors.primary}}>
                  {`SHOW ${m.data.length-1} MORE MATCH${(m.data.length-1 > 1 && 'ES') || ''}`} <IoIosArrowDown color='white' fontSize='12px'/>
                </BsButton>
              </GridCol>
            </GridRow>
          }
          </>
        }
        </Fragment>
    ));

    return (
      <>
        <Erni bottom={350} right={5} message='We found similar items to those that you are purchasing today that can save you even more cashback. Click, “Order” or “Sample” to try them today.' />
        <Modal id='InsightsBodyOpportunitiesModal'>
          <ModalHeader noBorder blueHeader>
            <GridRow>
              <GridCol>
                Savings and Opportunities
              </GridCol>
            </GridRow>
          </ModalHeader>
          <ModalHeader className='insights-opportunities-modal-header' closeButton={false}>
            <GridRow className='m-0'>
              <GridCol className='orginal text-left p-3'>Currently Buying</GridCol>
              <GridCol className='match text-left p-3'>Switch and Save!</GridCol>
            </GridRow>
          </ModalHeader>
          <ModalBody className='text-center text-size-20 line-height-20' subtract={49}>
            {originalsAndMatchesMap}
            <GridRow>
              {
                showShowMore &&
                <GridCol>
                  <Button variant='primary' text='SHOW MORE' onClick={this.showMore} />
                </GridCol>
              }
              {
                showShowLess &&
                <GridCol>
                  <Button variant='white' text='SHOW LESS' onClick={this.showLess} />
                </GridCol>
              }
            </GridRow>
          </ModalBody>
        </Modal>
      </>
    )
  }
};

const mapStateToProps = state => {
  return {
    companyId: state.company.id
  };
}

export default connect(mapStateToProps)(InsightsBodyOpportunitiesModal);
