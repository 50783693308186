import Highcharts from 'highcharts';
// import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import highchartsMore from 'highcharts/highcharts-more';
import { isMobile } from 'react-device-detect';
import { round } from '../../../utils/utils';
import IconEmptyPricing from '../../../MasterComponents/Icons/IconEmptyPricing';
import config from '../../../config/config';
highchartsMore(Highcharts);

class ItemIntelDetailsChart extends Component {

  componentDidMount() {
    Highcharts.seriesTypes.arearange.prototype.getPointSpline = Highcharts.seriesTypes.spline.prototype.getPointSpline;
  }

  getGraphData(data) {
    if (data === null) return [];
    let newGraphData = [];
    let allNulls = true;
    Object.entries(data).forEach(element => {
      const priceData = element[1].priceData;
      if (priceData.length > 0) {
        allNulls = false;
        let min = priceData[1], max = priceData[2];
        if (priceData[1] > priceData[2]) {
          min = priceData[2];
          max = priceData[1];
        }
        newGraphData.push([priceData[0] * 1000, round(min, 2), round(max, 2)]);
      } else {
        const date = new Date(element[0]).getTime();
        newGraphData.push([date, null, null]);
      }
    });
    if (allNulls) return [];
    newGraphData = newGraphData.sort((a, b) => {
      if (a[0] > b[0]) return 1;
      if (a[0] < b[0]) return -1;
      return 0;
    });
    return newGraphData;
  }

  render() {
    const graphData = this.getGraphData(this.props.itemDetails.graphData);
    const displayIntelPricing = JSON.parse(localStorage.getItem('activeMerchant')).displayIntelPricing || 0;

    const chartOptions = {
      chart: {
        type: 'areaspline',
        height: isMobile ? '100%' : '25%',
      },
      title: {
        text: undefined,
      },
      credits: {
        enabled: false
      },
      xAxis: {
        type: 'datetime',
        gridLineWidth: 1,
        tickInterval: 7 * 24 * 36e5,
        labels: {
          format: '{value: %Y-%m-%e}',
          align: 'right',
        }
      },
      yAxis: {
        title: {
          text: null
        },
        labels: {
          // eslint-disable-next-line no-template-curly-in-string
          format: '${value}',
        },
      },

      tooltip: {
        crosshairs: true,
        shared: true,
        valuePrefix: '$',
        valueDecimals: 2,
        backgroundColor: '#1A1A1A',
        borderColor: '#1A1A1A',
        style: {
          color: 'white'
        },
      },

      series: [{
        name: 'Price',
        data: graphData,
        type: 'arearange',
        lineWidth: 0.5,
        linkedTo: ':previous',
        fillOpacity: 0.3,
        connectNulls: true,
        color: config.colors.primary,
        marker: {
          enabled: false,
          symbol: 'circle',
          radius: 2,
          states: {
            hover: {
              enabled: true
            }
          }
        }
      }]
    };

    return (
      <>
        <GridRow className={isMobile ? 'details-info-title pt-2 pb-4' : 'details-info-title pt-2 pb-4 px-5'}>
          Average Market Price
        </GridRow>
        <GridRow className={isMobile ? '' : 'border-left'}>
          {displayIntelPricing === 1 ?
            this.props.itemDetails.graphData ?
              graphData.length > 0 ?
                <GridCol style={{ width: '60px', minWidth: '100%' }} className={isMobile ? 'px-0' : ''}>
                  <HighchartsReact highcharts={Highcharts} highchartsMore={highchartsMore} options={chartOptions} />
                </GridCol>
                :
                <GridCol className='my-5 p-5 text-center text-grey'>
                  <IconEmptyPricing /><br /><br />
                  Sorry, we have no pricing info for this item.
                </GridCol>
              :
              <></>
            :
            <GridCol className='my-5 p-5 text-center text-grey'>
              <IconEmptyPricing /><br /><br />
              Sorry, we can't display this data right now.
            </GridCol>
          }
        </GridRow>
      </>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    itemDetails: state.itemIntel.itemDetails,
  };
};

export default connect(mapStateToProps)(ItemIntelDetailsChart);