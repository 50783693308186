import React, { Component, Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import GridRow from '../../MasterComponents/Grid/GridRow';
import imgSubHeaderDish from '../../assets/images/subheader-dish.png';
import imgSubHeaderDishMob from '../../assets/images/subheader-dish-mobile.png';

class ClaimCashTop extends Component {
    render() {
        return (
            <Fragment>
                <GridRow className='claim-header-img' align='center'>
                    <img src={isMobile || this.props.isMobile ? imgSubHeaderDishMob : imgSubHeaderDish} alt='claim'  width='100%' />
                    <GridRow className='claim-row'>
                        <span className='claim-title'>Claim ACH</span>
                    </GridRow>
                </GridRow>
            </Fragment>
        );
    }
}

export default ClaimCashTop;