import React from 'react';
import './Step.scss';
import IconCheck from '../Icons/IconCheck';
import PropTypes from 'prop-types';

const Step = props => (        
    <span className={'step step-' + props.status}>{ props.status.substring(0,4) !== 'done' ? props.text : <IconCheck/>}</span>
);

Step.propTypes = {    
    status: PropTypes.string
};
  
Step.defaultProps = {
    status: 'inactive' // inactive - active - done
};

export default Step;