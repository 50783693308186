import React, { Component } from 'react';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import SearchInput from '../../MasterComponents/SearchInput/SearchInput';
import GridCol from '../Grid/GridCol';
import IconEdit from '../Icons/IconEdit';
import IconDelete from '../Icons/IconDelete';
import config from '../../config/config';
import * as $ from 'jquery';
class DropDownListCheckbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      search: ''
    }
  }
  onEdit = (id) => {
    const { goToEdit } = this.props;
    goToEdit(id);
  };
  onDelete = (id) => {
    const { goToDelete } = this.props;
    goToDelete(id);
  };
  toggle = () => {
    const { triggerSearch } = this.props;
    if (!this.state.open) {
      setTimeout(() => {
        $('#ddl-menu').focus();
      }, 200);
    } else {
      triggerSearch();
    }

    this.setState({
      open: !this.state.open
    });
  }
  onChange = (opt)=>{
    const { toggleOption } = this.props;
    toggleOption(opt)
  };

  onChangeAll = (opt)=>{
    const { toggleAll } = this.props;
    toggleAll(opt)
  };

  onChageSearch= (value) => {
    this.setState({
      search: value
    });
  } 

  render() {
    const { options, optionSelected, hasAddedNew, isCheckedAll, hasAll, hasSearch, hasEdit, hasDelete, defaultText, defaultTextSelected, defaultTextAll, defaultTextSearch } = this.props;
    const { open, search } = this.state;
    const name = optionSelected.length === 0 || optionSelected.length === options.length ? defaultText : (optionSelected.length === 1 ? optionSelected[0].name : `${optionSelected.length} ${defaultTextSelected}`);
    const filterOpt = options.filter(o => o.name.toString().toLowerCase().search(search.toString().toLowerCase()) >= 0);
    const optionsMap = filterOpt.map((opt, i) => {
      return (
        <div key={`ddl_${i}`} className='px-0 clickable mt-2 border d-flex flex-row align-items-center dropdown-item'>
          <GridCol className='text-grey pr-0' maxWidth='20px'>
            <div className='custom-control custom-checkbox'>
            <input onChange={this.onChange.bind(this, opt)} checked={opt.isChecked} type='checkbox' className='custom-control-input' id={`ddl_checkbox_${i}`} />
              <label className='custom-control-label' htmlFor={`ddl_checkbox_${opt.id}`}></label>
            </div>
          </GridCol>
          <GridCol className='py-2 text-wrap' onClick={this.onChange.bind(this, opt)}>
            {opt.name}
          </GridCol>
          { hasEdit &&
            <GridCol className='text-right px-0 clickable' maxWidth='30px' onClick={() => this.onEdit(opt.id)}>
              <IconEdit color={config.colors.rbDarkGrey} />
            </GridCol>
          }
          { hasDelete &&
            <GridCol className='text-right pl-0 clickable' maxWidth='40px' onClick={() => this.onDelete(opt.id)}>
              <IconDelete color={config.colors.rbDarkGrey} />
            </GridCol>
          }
        </div>
      )
    })
    return (
      <div className='dropdown' id='locationDropdown' style={{zIndex:1}}>
        <button className='btn rb-dropdown rb-input form-control' type='button' id='locationDropdownMenuButton' onClick={this.toggle}>
          {name} {!open ? <IoMdArrowDropdown className='position-absolute dd-arrow' size={18} color={config.colors.rbBlack} /> : <IoMdArrowDropup className='position-absolute dd-arrow' size={18} color={config.colors.rbBlack} />}
        </button>
        <div id='ddl-menu' className={'dropdown-menu w-100 text-size-14 p-2 scrollbar-insights ' + (open ? ' show' : '')} role='menu' style={{maxHeight: '300px', overflow: 'auto'}}>
          { hasSearch && 
            <div className='px-0' tabIndex={0}>
              <GridCol className='px-0 py-2'>
                <SearchInput placeholder={defaultTextSearch} name='search' value={search} onChange={this.onChageSearch} />
              </GridCol>
            </div>
          }
          <div onBlur={this.toggle} tabIndex={1}>
            { hasAddedNew && 
              <div className='dashed-box-grey p-2 clickable text-center' onClick={this.props.goToAdd}>
                Add New Location
              </div>
            }
            { hasAll &&
              <>
                <div className='px-0 clickable mt-2 border d-flex flex-row align-items-center dropdown-item'>
                  <GridCol className='text-grey pr-0' maxWidth='20px'>
                    <div className='custom-control custom-checkbox'>
                    <input onChange={this.onChangeAll} checked={isCheckedAll} type='checkbox' className='custom-control-input' id={`loc_checkbox_all`} />
                      <label className='custom-control-label' htmlFor={`loc_checkbox_all`}></label>
                    </div>
                  </GridCol>
                  <GridCol className='py-2' onClick={this.onChangeAll}>
                    {defaultTextAll}
                  </GridCol>
                </div>
                <hr />
              </>
            }
            {optionsMap}
          </div>
        </div>
      </div>
    );
  }
};

DropDownListCheckbox.defaultProps = {
  hasAddedNew: false,
  hasAll: false,
  hasSearch: true,
  hasEdit: true,
  hasDelete: true,
  isCheckedAll: false,
  defaultText: 'Select',
  defaultTextSelected: 'Selected',
  defaultTextAll: 'All',
  defaultTextSearch: 'Search...',
  options: [],
  optionSelected: []
};

export default DropDownListCheckbox;
