import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconCheckMark = props => (
  <svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Claim-Cash" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="7---Confirmation" transform="translate(-690.000000, -132.000000)">
            <g id="icons/big-checkmark" transform="translate(690.000000, 132.000000)">
                <rect id="Rectangle" fill={!props.isActive ? props.color : props.activeColor} x="0" y="0" width="60" height="60" rx="30"></rect>
                <g id="icons/check" transform="translate(19.000000, 22.000000)" fill="#FFFFFF">
                    <path d="M21.8175,0.8075 C22.4025,1.39 22.4025,2.3425 21.8175,2.9275 L9.5525,15.1925 C9.26,15.485 8.875,15.6325 8.4925,15.6325 C8.11,15.6325 7.725,15.485 7.4325,15.1925 L1.1825,8.9425 C0.5975,8.36 0.5975,7.4075 1.1825,6.8225 C1.7675,6.2375 2.715,6.2375 3.3025,6.8225 L8.4925,12.01 L19.6975,0.8075 C20.285,0.2225 21.2325,0.2225 21.8175,0.8075 Z" id="Shape"></path>
                </g>
            </g>
        </g>
    </g>
  </svg>
);

IconCheckMark.propTypes = {
  color: PropTypes.string,
  activeColor: PropTypes.string,
  isActive: PropTypes.bool,
  size: PropTypes.number,
};

IconCheckMark.defaultProps = {
  activeColor: config.colors.rbYellow,
  color: config.colors.rbWhite,
  isActive: false,
  size: 16,
};

export default IconCheckMark;