import React, { Component, Fragment } from 'react';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import {connect} from 'react-redux';
import GridRow from '../../MasterComponents/Grid/GridRow';
import GridCol from '../../MasterComponents/Grid/GridCol';
import Button from '../../MasterComponents/Button/Button';
import { getBgImageMain, getLandingPageButtonVariant } from '../../config/Company/CompanyHelper';

class HomeContent extends Component {
    goTo = (i) => {  
        this.props.history.push('/'+ this.props.links[i].button);
    };
    render() {
        const { links, name, homeVideo, companyId } = this.props;
        const bgImageMain = getBgImageMain(companyId);
        const landingPageButtonVariant = getLandingPageButtonVariant(companyId);
        const buttonsMap = links.map((link, i) =>
            <Button type='button' variant={landingPageButtonVariant} size='lg' text={link.title} onClick={this.goTo.bind(this, i)} key={i} />
        );
        return ( 
            <div className='home-content no-gutters' style={{backgroundImage: `url(${bgImageMain})`}}>
                <GridRow>
                    <GridCol className='home-row-title'>
                        Welcome to My {name}
                    </GridCol>                    
                </GridRow>
                <GridRow>
                    <GridCol className='home-row-center'>
                        {buttonsMap}
                    </GridCol>
                </GridRow>
                { homeVideo &&
                    <Fragment>                    
                        <GridRow>
                            <GridCol className='text-center home-title-works mt-5'>
                                How It Works
                            </GridCol>
                        </GridRow>   
                        <GridRow>
                            <GridCol className={ isMobile ? '' : 'home-row-center'}>
                                    <iframe src={homeVideo}
                                            frameBorder='0'
                                            allow='autoplay; encrypted-media'
                                            allowFullScreen
                                            height= {isMobileOnly ? '220vh' : ( isTablet ? '290vh' : '360vh')}
                                            width={isMobile ? '80%' : '100%'}
                                            title='video'
                                    />
                            </GridCol>
                        </GridRow>
                    </Fragment>
                }
                <GridRow className='home-row-bottom'>                    
                    <GridCol className='home-row-text'>
                    </GridCol>                    
                </GridRow>
                <GridRow className='clearfix'>                    
                </GridRow>
            </div>
            
        );
    };
}


const mapStateToProps = (state) => {
    return {
      section: state.home.section,
      links: state.home.links,
      name: state.company.nickname,
      homeVideo: state.company.homeVideo
    };
};
  
export default connect(mapStateToProps)(HomeContent);