import React, { Component, Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import config from '../../../../config/config';
import GridCol from '../../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../../MasterComponents/Grid/GridRow';
import Input from '../../../../MasterComponents/Input/Input';
import InputLabel from '../../../../MasterComponents/InputLabel/InputLabel';
import DropDownListSelect from '../../../../MasterComponents/DropDownList/DropDownListSelect';
import DropDownListSelectCustom from '../../../../MasterComponents/DropDownList/DropDownListSelectCustom'
import Button from '../../../../MasterComponents/Button/Button';
import IconEdit from '../../../../MasterComponents/Icons/IconEdit';
import byGoogle from '../../../../assets/images/powered-by-google.png';
import getPlacesProps, { getStatesProvinces } from '../../../../MasterComponents/Helper/getPlaces';
import { getText } from '../../../../MasterComponents/Helper/getHelper';
import DropDownList from '../../../../MasterComponents/DropDownList/DropDownList';
import DropDownListOption from '../../../../MasterComponents/DropDownList/DropDownListOption';
class FormPorchDoorStepOneInformation extends Component {
    constructor(props){
        super(props);
        this.state = {
            password : {
                type: 'password',
                color: config.colors.rbDarkerGrey
            },
            confirmPassword : {
                type: 'password',
                color: config.colors.rbDarkerGrey
            },
            refferedBySaved: null,
        };
        this.getPlacesProps = getPlacesProps.bind(this);
    };
    isValid = () => {    
        const { setError, showTitle, showReportingLocations } = this.props;
        const {
            firstName, lastName, businessName, businessAddress,
            businessCity, state, businessPostcode, phoneNumber,
            email, daSalesRep, referredBy, confirmemail,
            title, numberLocations
        } = this.refs;
        const fn = firstName.isValid();
        const ln = lastName.isValid();
        const e = email.isValid();
        const t = showTitle ? title.isValid() : true;
        const loc = showReportingLocations ? numberLocations.isValid() : true;
        const ce = confirmemail.isValid();
        const bn = businessName.isValid();
        const ba = businessAddress.isValid();
        const bc = businessCity.isValid();
        const s = state.isValid();
        const bpc = businessPostcode.isValid();
        const pn = phoneNumber.isValid();

        if (!ba || !bc || !s || !bpc) {
           if (!this.props.showAddressDetails) {
                this.props.toggleAddressDetails();
            }
        }
        if (fn && ln && e && ce && bn && ba && bc && s && bpc && pn && t && loc) {
            if (email.props.value !== confirmemail.props.value) {
                email.setInvalid();
                confirmemail.setInvalid();
                setError("Emails don't match.");
                return false;
            }
            if (daSalesRep.isValid() || referredBy.isValid()) {
                daSalesRep.setValid();
                referredBy.setValid();
            } else {
                setError('At least one field is required: Sales Rep or Channel Partner.');
                return false;
            }
            return true;
        }
        setError('Required fields missing.');
        return false;
    }

    invalidEmail = () => {
        const { email } = this.refs;
        email.setInvalid();
    }
    invalidConfirmEmail = () => {
        const { confirmemail } = this.refs;
        confirmemail.setInvalid();
    }

    getUsStates = () => {
        const options = config.usStates.map((item) => {
            return {
                label: item.name,
                value: item.abbreviation
            }
        });
        return options;
    }

    getReferredList = () => {
        const { referredPartnerFromApi, isReinhartCPOnly } = this.props;
        const options = referredPartnerFromApi.map((item) => {
            return {
                label: item.name,
                value: item.id,
                platformId: item.platformId,
                isDaSalesRep: item.isDaSalesRep
            }
        });
        if (isReinhartCPOnly) {
            return options.filter(o => o.label.search('Reinhart') !== -1);
        }
        return options;
    }

    selectReferred = (value) => {
        this.props.selectReferredBy(value);
    }

    getSalesRepresentativeList = () => {
        const {daSalesRepFromApi} = this.props;
        const options = daSalesRepFromApi.map((item) => {
            return {
                label: item.name,
                value: item.id,
                platformId: item.platformId,
                isDaSalesRep: item.isDaSalesRep
            }
        });
        return options;
    }

    selectSalesRepresentative = (value) => {
        this.props.selectDASalesRep(value);
    }

    showPassword = () => {
        this.setState({
            password: {
                type: this.state.password.type === 'text' ? 'password' : 'text',
                color: this.state.password.color === config.colors.rbDarkerGrey ? config.colors.rbBlue : config.colors.rbDarkerGrey
            }          
        });
    }

    showConfirmPassword = () => {
        this.setState({
            confirmPassword: {
                type: this.state.confirmPassword.type === 'text' ? 'password' : 'text',
                color: this.state.confirmPassword.color === config.colors.rbDarkerGrey ? config.colors.rbBlue : config.colors.rbDarkerGrey
            }
        });
    }
    
    render() {
        const props = this.props;
        const {
            changeHandlerInputs, selectStateContactInformation, validateEmailFromApi,
            validateRepeatedEmail, disabledRefferedBy, addressSearch, showAddressDetails,
            toggleAddressDetails, handleAddressSelect, country,
            showTitle, showReportingLocations, selectTitle
        } = props;
        return (
            <Fragment>
                <GridRow className='line-form'>
                    <GridCol className='form-group' size={isMobile ? 12 : 6}>
                        <InputLabel text='Channel Partner'/>
                        <DropDownListSelectCustom
                            name='referredBy' ref='referredBy'
                            isDisabled={disabledRefferedBy}
                            value={props.step1Inputs.referredBy} onChange={this.selectReferred}
                            options={this.getReferredList()}
                            openMenuOnClick={false}
                            onInputChange={3}
                            menuIsOpen={false}
                            blurInputOnSelect={true}
                        />
                    </GridCol>
                    <GridCol className='form-group' size={isMobile ? 12 : 6}>
                        <InputLabel text='Sales Rep'/>
                        <DropDownListSelectCustom
                            name='daSalesRep' ref='daSalesRep'
                            value={props.step1Inputs.daSalesRep} onChange={this.selectSalesRepresentative}
                            options={this.getSalesRepresentativeList()}
                            openMenuOnClick={false}
                            onInputChange={3}
                            menuIsOpen={false}
                            blurInputOnSelect={true}
                        />
                    </GridCol>
                </GridRow>
                <GridRow>
                    <GridCol>
                        <InputLabel text='Operator Information' title className='title'/>
                    </GridCol>
                </GridRow>
                {
                    showTitle &&
                    <GridRow className='line-form'>
                        <GridCol className='form-group'>
                            <InputLabel text='Title *'/>
                            <DropDownList ref='title' title={props.step1Inputs.title}>
                            {config.signUpStepOneTitleOptions.map((title, i) =>
                                <DropDownListOption key={`title_opt_${i}`} title={title} onClick={selectTitle} />
                            )}
                            </DropDownList>
                        </GridCol>
                    </GridRow>
                }
                <GridRow className='line-form'>
                    <GridCol className='form-group'>
                        <InputLabel text='My First Name'/>
                        <Input ref='firstName' placeholder='First Name' type='text' name="firstName" value={props.step1Inputs.firstName || ''} onChange={changeHandlerInputs} validate min={3} max={100} />
                    </GridCol>
                    <GridCol className='form-group'>
                        <InputLabel text='My Last Name'/>
                        <Input ref='lastName' placeholder='Last Name' type='text' name="lastName" value={props.step1Inputs.lastName || ''} onChange={changeHandlerInputs} validate min={3} max={100} />
                    </GridCol>
                </GridRow>
                <GridRow className='line-form'>
                    <GridCol className='form-group'>
                        <InputLabel text='My Email'/>
                        <Input ref='email' type='email' name='email' placeholder='Email' value={props.step1Inputs.email || ''} onChange={changeHandlerInputs} onBlur={validateEmailFromApi.bind(this, props.step1Inputs.email)} validate min={3} max={100} />
                    </GridCol>
                    <GridCol className='form-group'>
                        <InputLabel text='Confirm Email'/>
                        <Input ref='confirmemail' type='email' name='emailRepeat' placeholder='Repeat Email' value={props.step1Inputs.emailRepeat || ''} onChange={changeHandlerInputs} onBlur={validateRepeatedEmail.bind(this, props.step1Inputs.emailRepeat)} validate min={3} max={100} />
                    </GridCol>
                </GridRow>
                <GridRow className='line-form' style={{alignItems: 'end'}}>
                    <GridCol className='form-group'>
                        <InputLabel text='Parent Company Name'/>
                        <Input ref="businessName" placeholder='Company Name' type='text' name="businessName" value={props.step1Inputs.businessName || ''} onChange={changeHandlerInputs} validate min={3} max={100} />
                    </GridCol>
                    <GridCol className='form-group'>
                        <InputLabel text='Parent Company Phone'/>
                        <Input ref='phoneNumber' type='tel' placeholder='Company Phone Number' name='phoneNumber' value={props.step1Inputs.phoneNumber || ''} onChange={changeHandlerInputs} validate min={3} max={50} />
                    </GridCol>
                    <GridCol className='form-group'>
                        <InputLabel text="My Location's Rebate ID # (Optional)" className='text-bold' />
                        <Input ref="parentPlatformId" validateCL type='text' placeholder='CL-1234567' name="parentPlatformId" value={props.step1Inputs.parentPlatformId || ''} onChange={changeHandlerInputs} />
                    </GridCol>
                </GridRow>
                <GridRow className='line-form align-items-center' style={{marginBottom: '16px'}}>
                <GridCol className='form-group m-0'>
                    <DropDownListSelectCustom {...this.getPlacesProps(country, 'optionsPlace', addressSearch, handleAddressSelect, 'Search Business Address...')} />
                </GridCol>
                <GridCol style={{maxWidth: 'fit-content'}}>
                    <Button onClick={toggleAddressDetails} style={{width: '40px', height: '40px'}} variant={ showAddressDetails ? 'blue' : 'white'} icon={<IconEdit color={ !showAddressDetails ? config.colors.rbBlue : config.colors.rbWhite} />}/>
                </GridCol>
                <GridCol style={{maxWidth: '120px'}}>
                    <img className='w-100' src={byGoogle} alt='google' />
                </GridCol>
                </GridRow>
                <GridRow className={'mx-0' + (showAddressDetails ? '' : ' d-none')} style={{paddingLeft: '15px', marginBottom: '16px'}}>
                    <GridCol className='py-3 pl-0' style={{backgroundColor: '#F8F8F8'}}>
                        <GridRow className='line-form'>
                            <GridCol className='form-group'>
                                <InputLabel text='Address Line *' />
                                <Input ref="businessAddress" type='text' name="businessAddress" value={props.step1Inputs.businessAddress || ''} onChange={changeHandlerInputs} validate min={3} max={200} placeholder='Business Address' />
                            </GridCol>
                            </GridRow>
                            <GridRow className='line-group'>
                            <GridCol className={(isMobile ?  'col-12': 'col-4') + ' form-group'}>
                                <InputLabel text='City *'/>
                                <Input ref="businessCity" type='text' name="businessCity" value={props.step1Inputs.businessCity || ''} onChange={changeHandlerInputs} validate min={3} max={100} placeholder='Business City' />
                            </GridCol>
                            <GridCol className={(isMobile ?  'col-6': 'col-4') + ' form-group'}>
                                <InputLabel text={getText(country, 'state')}/>
                                <DropDownListSelect
                                name='state' ref='state'
                                value={props.step1Inputs.businessStateObj} onChange={selectStateContactInformation}
                                options={getStatesProvinces(country)}/>
                            </GridCol>
                            <GridCol className={(isMobile ?  'col-6': 'col-4') + ' form-group'}>
                                <InputLabel text={getText(country, 'zipCode')}/>
                                <Input ref="businessPostcode" type='zipCode' name="businessPostcode" value={props.step1Inputs.businessPostcode || ''} onChange={changeHandlerInputs} country={country} validate placeholder={getText(country, 'zipCodeBusiness')} />
                            </GridCol>
                        </GridRow>
                    </GridCol>
                </GridRow>
                {
                    showReportingLocations &&
                    <GridRow className='line-form'>
                        <GridCol className='form-group'>
                            <InputLabel text='How many locations will be reporting *'/>
                            <Input ref="numberLocations" validate min={1} max={9999} type='number' placeholder='Location count' name="numberLocations" value={props.numberLocations || ''} onChange={props.updateNumberLocations} />
                        </GridCol>
                    </GridRow>
                }
            </Fragment>
        );
    }
}

export default FormPorchDoorStepOneInformation;