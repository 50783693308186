export const primeSetData = (data) => {
  return {
    type: 'PRIME_SET_DATA',
    payload: data
  };
};

export const primeSetDataUpdated = (data) => {
  return {
    type: 'PRIME_SET_DATA_UPDATED',
    payload: data
  };
};