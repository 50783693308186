import React from 'react';
import { Container } from 'reactstrap';

const Orderly = () => {
  const jwt = localStorage.getItem('authKey');
  const orderlyLink = process.env.REACT_APP_ENVIRONMENT === 'production' ? `https://gotorderly.com?platformJwt=${jwt}` : `https://theqebox.siftit.net?platformJwt=${jwt}`;

  return (
    <Container fluid className='blog col-12 p-0'>
      <iframe  title='FoodCost' src={orderlyLink} width='100%' height='100%' frameBorder='0'></iframe>
    </Container>
  );
};

export default Orderly;