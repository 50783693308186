import React from 'react';
import { Row, Col } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import InvoiceScanningDetailItem from './InvoiceScanningDetailItem';

const InvoiceScanningDetail = (props) => {
  const { invoicesAll, onUploadNow, setTooltipByItem } = props;
  if(invoicesAll.length === 0) {
    return (
      <Row className={`p-3 border rounded-2 text-size-14 line-height-16 ${isMobile ? 'mx-3 mb-4': 'mx-4'}` }>
        <Col className='text-center'>
          No Invoices Uploaded
        </Col>
      </Row>
    )
  };

  return (
    <>
      { invoicesAll.map((inv, i) =>
        <InvoiceScanningDetailItem 
          key={`inv_${i}`}
          inv={inv}
          name={inv.name}
          merchant={inv.merchant}
          updateTime={inv.updateTime}
          status={inv.status}
          onUploadNow={onUploadNow}
          statusType={inv.statusType}
          statusReason={inv.statusReason}
          isTooltipOpen={inv.isTooltipOpen}
          setTooltipByItem={setTooltipByItem}
        />
      )}
    </>
  )
}

export default InvoiceScanningDetail;