import React, { Component, Fragment } from 'react';
import { isMobile, isIOS } from 'react-device-detect';
import {connect} from 'react-redux';
import OpenInAppModal from '../OpenInAppModal.js/OpenInAppModal';
import { showModal } from './ModalsActions';

class Modals extends Component {
  componentDidMount() {
    const { dispatch, appUrl, companyId } = this.props;
    if (companyId === 1 && isMobile && isIOS && !this.isCoolSchool()) {
      dispatch(showModal(OpenInAppModal, 'openInAppModal', {appUrl: appUrl}));
    }
  };
  isCoolSchool = () => {
      const validUrl = [
          'prime.coolschoolcafe.com',
          'staging.prime.coolschoolcafe.com',
          'dev.prime.coolschoolcafe.com'
      ];
      return validUrl.includes(window.location.hostname.toLowerCase());
  };
  render() {
    const modalsMap = this.props.modals.map((modal, i)=>
      <modal.Component key={modal.id} {...modal.props} modals={this.props.modals}></modal.Component>
    );
    return (
      <Fragment>
        {modalsMap}
      </Fragment>
    );
  };
};

const mapStateToProps = (state) => {
  return {
    modals: state.modals.modals,
    appUrl: state.company.appUrl,
    companyId: state.company.id
  };
};

export default connect(mapStateToProps)(Modals);
