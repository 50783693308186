export default function reducer(state = {
  signUpWithSaleRep: {}
}, action) {
  switch (action.type) {
    case 'SIGNUP_SET_ALL_SALES_REP':
      return {
        ...state,
        signUpWithSaleRep: action.payload
      };
    case 'SIGNUP_CLEAR_ALL_SALES_REP':
      return {
        ...state,
        signUpWithSaleRep: {}
      };
    default:
      return state;
  }
}