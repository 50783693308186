import React, { Component } from 'react'
import Modal from '../../../MasterComponents/Modals/Modal';
import ModalHeader from '../../../MasterComponents/Modals/ModalHeader';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import ModalFooter from '../../../MasterComponents/Modals/ModalFooter';
import Button from '../../../MasterComponents/Button/Button';
import { isMobile } from 'react-device-detect';
import { findModal } from '../../../MasterComponents/Modals/ModalsActions';
import Bullet from '../../../MasterComponents/Bullet/Bullet';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import InputLabel from '../../../MasterComponents/InputLabel/InputLabel';
import PropTypes from 'prop-types';

class ConfirmationModal extends Component {
  closeModal = () => {
    const { modals, history } = this.props;    
    if(history){
        localStorage.setItem('isSignUpRoute', 0);
        this.props.history.push('/');
    }
    findModal(modals, 'confirmationModal').close();
  }
  
  render() {
    const { header, message, icon } = this.props;
    return (
      <Modal id='confirmationModal' fullHeight={isMobile} >
        <ModalHeader noBorder closeButton={false} className={isMobile ? 'pt-5 mt-5 px-4' : null}>{header}</ModalHeader>
        <ModalBody className='text-center text-size-14 text-line-height-20 d-flex align-items-center'>
            <GridCol>
                {(icon)?                
                <GridRow className='my-1'>
                    <GridCol className='d-flex justify-content-center'>
                        <Bullet big={true}/>
                    </GridCol>
                </GridRow>
                :null
                }
                <GridRow>
                    <GridCol>
                        <InputLabel className='text-size-16'>{message}</InputLabel><br/>              
                    </GridCol>
                </GridRow>
            </GridCol>
        </ModalBody>
        <ModalFooter>
          <Button variant='primary' onClick={this.closeModal} className='clickable' size='lg' text='Ok' />
        </ModalFooter>
      </Modal>
    );
  }
};

ConfirmationModal.propTypes = {
  icon: PropTypes.bool
}

ConfirmationModal.defaultProps = {
  icon: true
}

export default ConfirmationModal;