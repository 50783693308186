export const dataViewArray = [
  {
    id: 0,
    navbarText: 'Overview',
    viewTitle: `It's time to take things Digital`,
    viewBodyText: `Food Cost Management allows restaurants to more deeply understand their business. Run dynamic recipe costs that shift as prices change, track food cost percentage, and make inventory bookkeeping a breeze!`,
    urlImage: `https://rebate-portal.s3.amazonaws.com/resources/foodcost/images/overview.svg`,
    isActive: true
  },
  {
    id: 1,
    navbarText: 'Real-time Reports',
    viewTitle: `Real-time Reports`,
    viewBodyText: `Easily access key success metrics including: plate costs, cost of goods sold, and inventory so you can run a more profitable restaurant!`,
    urlImage: `https://rebate-portal.s3.amazonaws.com/resources/foodcost/images/real-time-reports.svg`,
    isActive: false
  },
  {
    id: 2,
    navbarText: 'Recipe Costs',
    viewTitle: `Recipe Costs`,
    viewBodyText: `Dynamic recipe costing automatically adjusts as your ingredient prices fluctuate. Finally... a real understanding of your plate costs is attainable!`,
    urlImage: `https://rebate-portal.s3.amazonaws.com/resources/foodcost/images/recipe-costs.svg`,
    isActive: false
  },
  {
    id: 3,
    navbarText: 'Inventory Control',
    viewTitle: `Inventory Control`,
    viewBodyText: `Mitigate time-consuming inventory counts and quickly resolve shrinkage and spoilage issues. Easily track on-hand amounts with up to date dollar values for each ingredient!`,
    urlImage: `https://rebate-portal.s3.amazonaws.com/resources/foodcost/images/inventory-control.svg`,
    isActive: false
  },
  // {
  //   id: 4,
  //   navbarText: 'Ingredient Price Trends',
  //   viewTitle: `Ingredient Price Trends`,
  //   viewBodyText: `Stay on top of ingredients that are creaping up in price before it’s too late!`,
  //   urlImage: `https://rebate-portal.s3.amazonaws.com/resources/foodcost/images/ingredient-price-trends.svg`,
  //   isActive: false
  // },
  {
    id: 4,
    navbarText: 'Accounting',
    viewTitle: `Accounting`,
    viewBodyText: `Invoices are coded and sent directly to your accounting software! Your accountant is going to love you!`,
    urlImage: `https://rebate-portal.s3.amazonaws.com/resources/foodcost/images/accounting.svg`,
    isActive: false
  },
  {
    id: 5,
    navbarText: 'Bill Pay',
    viewTitle: `Bill Pay`,
    viewBodyText: `Expedite the laborious bill-pay process. Upload, approve, and pay all of your invoices from one spot! Your accountant will thank you!`,
    urlImage: `https://rebate-portal.s3.amazonaws.com/resources/foodcost/images/bill-pay.svg`,
    isActive: false
  }
];