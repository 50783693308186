import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './DropDownList.scss';
import * as $ from 'jquery';

class DropDownList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isValid: true
    }
  }
  componentWillMount = () => {
    this.id = 'dp-'+Math.round(Math.random()*100);
  }
  isValid = () => {
    if (this.props.title) {
      this.setState({
        isValid: true
      });
      return true;
    } else {
      this.setState({
        isValid: false
      });
      return false;
    }
  }
  componentDidMount = () => {
    var self = this;
    $('#'+this.id).on('hide.bs.dropdown', function () {
      self.setState({
        open: false
      });
    });
    $('#'+this.id).on('hidden.bs.dropdown', function () {
      $('#'+this.id+' .rb-dropdown').blur();
    });
    $('#'+this.id).on('show.bs.dropdown', function () {
      self.setState({
        open: true,
        isValid: true
      });
    });
    // Search with letters
    $('#'+this.id).on('keypress', function (e) {
      if (e.which !== 13) {
        const key = String.fromCharCode(e.which);
        let found = false;
        $('#'+self.id).find('.dropdown-item').each(function(idx, item) {
          if ($(item).text().charAt(0).toLowerCase() === key && !found && !$(item).is(':focus')) {
            found = true;
            $(item).focus();
          }
        });
      } else {
        $('#'+self.id).dropdown('hide');
        self.setState({
          open: false
        });
      }
    });
  }
  render() {
    const options = React.Children.map(this.props.children, child => {
      if (child.props.title === this.props.title) return;
      return child;
    });
    return (
      <div id={this.id} className={'btn-group' + ( this.props.top ? ' rb-dropup-group' : ' rb-dropdown-group' )}>
        <button type='button' className={'btn rb-dropdown dropdown-toggle text-truncate' + (!this.state.isValid ? ' is-invalid' : '') + (this.props.grayText || !this.props.title ? ' opacity' : '') + (this.state.open ? ' dp-focus' : '') +(this.props.className ? ` ${this.props.className}` : '')} data-toggle='dropdown'>
          { this.props.title || "Select" }
        </button>
        <div className='dropdown-menu'>
          {options}
        </div>
      </div>
    );
  }
}

DropDownList.propTypes = {
  title: PropTypes.any,
  className: PropTypes.string,
  grayText: PropTypes.bool,
  top: PropTypes.bool,
  onChange:  PropTypes.func,
}

export default DropDownList;





