export default function reducer(state = {
  invoicesAll: [],
  filesArray: [],
  isSendDisbled: true,
  searchName: '',
}, action) {
  switch (action.type) {
    case 'INVOICE_SET_ALL':
      return {
        ...state,
        invoicesAll: action.payload
      };
    case 'INVOICE_SET_FILES':
      return {
        ...state,
        filesArray: action.payload
      };
    case 'INVOICE_SET_SEND_DISABLED':
      return {
        ...state,
        isSendDisbled: action.payload
      };
    default:
      return state;
  }
}