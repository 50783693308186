import React from 'react';
import { isMobile } from 'react-device-detect';
import { IoMdClose } from 'react-icons/io';
import { showModal } from '../../MasterComponents/Modals/ModalsActions';
import { spinner } from '../../MasterComponents/Spinner/SpinnerActions';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import SlidingPane from "react-sliding-pane";
import useShowActivateDistributor from '../../MasterComponents/SharedFunctions/useShowActivateDistributor';
import useRefresh from '../../MasterComponents/SharedFunctions/useRefresh';
import GridRow from '../../MasterComponents/Grid/GridRow';
import GridCol from '../../MasterComponents/Grid/GridCol';
import NotificationInfo from './NotificationInfo';
import useNotification from './hooks/useNotification';
import UploadPurchaseHistoryModal from '../Dashboard/Modals/UploadPurchaseHistoryModal';
import EnterCredentialsModal from '../Dashboard/Modals/EnterCredentialsModal';
import DashboardApi from '../Dashboard/DashboardApi';
import './Notification.scss';
import 'react-sliding-pane/dist/react-sliding-pane.css';

const Notification = (props) => {
  const {id, from, width, className, overlayClassName, showHeader, titleHeader, isOpen, onClose} = props;
  const {readNotification, deleteNotification} = useNotification.useApi();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const showActivateDistributor = useShowActivateDistributor();
  const notificationList = useSelector(({notification}) => notification.notificationList);
  
  const closeSidebar = () => {
    onClose(id);
  }

  const clickActivate = (noti) => {
    if(noti) {
      closeSidebar();
      showActivateDistributor(refresh, noti.merchantDistributor, false);
    }
  }

  const clickUploadNow = (noti) => {
    closeSidebar();
    dispatch(showModal(UploadPurchaseHistoryModal, 'uploadPurchaseHistoryModal', { merchantDistributor: noti.merchantDistributor, refresh: refresh }));
  }

  const onClickEditCredential = (noti) => {
    closeSidebar();
    dispatch(showModal(EnterCredentialsModal, 'enterCredentialsModal', {
      merchantDistributor: noti.merchantDistributor,
      verifyCredentials: verifyCredentials,
      saveCredentials: saveCredentials,
      isEdit: true,
      dcns: [noti.merchantDistributor.dcn]
    }));
  }

  const verifyCredentials = (distributorId, merchantId, username, password, dcns, cb) => {
    dispatch(spinner(true));
    DashboardApi.verifyCreds( distributorId, merchantId, username, password, dcns).then(({data}) => {
      dispatch(spinner(false));
      cb(data);
    }).catch(({data}) => {
      dispatch(spinner(false));
      cb(data);
    });
  };

  const saveCredentials = (merchantDistributorId, username, password) => {
    const params = {
      merchantDistributorId: merchantDistributorId,
      username: username,
      password: password
    }
    dispatch(spinner(true));
    DashboardApi.editCredentials(merchantDistributorId, params).then(({data}) => {
      dispatch(spinner(false));
      refresh();
    });
  };

  const onClickRead = (merDist, e) => {
    e.stopPropagation();
    if(merDist.isRead === 0){
      readNotification(merDist.id);
    }
  }

  const onClickDelete = (merDist, e) => {
    e.stopPropagation();
    deleteNotification(merDist.id);
  }

  const notificationMap = notificationList.map((merDist, i) => (
    <NotificationInfo key={`noti_${i}`} {...merDist} onClickActivate={()=>clickActivate(merDist)} onClickEditCredential={()=>onClickEditCredential(merDist)} clickUploadNow={()=>clickUploadNow(merDist)}
        onClickRead={(e)=>onClickRead(merDist, e)} onClickDelete={(e)=>onClickDelete(merDist, e)}  />
  ));

  if(isMobile) {
    return (
      <>
        {notificationMap}
      </>
    )
  }

  return (
    <SlidingPane
      hideHeader
      className={className}
      overlayClassName={'sb-custom-overlay ' + overlayClassName}
      isOpen={isOpen}
      from={from}
      width={width}
      onRequestClose={closeSidebar} >
      { showHeader && 
        <GridRow className='px-4 py-3 border-bottom'>
          <GridCol className='text-bold text-size-20 line-height-24'>
            {titleHeader}
          </GridCol>
          <GridCol className='mw-fc text-right clickable' onClick={closeSidebar}>
            <IoMdClose size={20} />
          </GridCol>
        </GridRow>
      }
      {notificationMap}
    </SlidingPane>
  )
}

Notification.propTypes = {
  showHeader: PropTypes.bool,
  width: PropTypes.string,
  from: PropTypes.string,
  titleHeader: PropTypes.string,
  className: PropTypes.string,
  overlayClassName:  PropTypes.string

};

Notification.defaultProps = {
  showHeader: true,
  width: '350px',
  from: 'right',
  titleHeader: '',
  className: '',
  overlayClassName: ''
};

export default Notification;


