import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import '../../SignUp.scss';
import GridCol from '../../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../../MasterComponents/Grid/GridRow';
import Button from '../../../.././MasterComponents/Button/Button';
import InputLabel from '../../../../MasterComponents/InputLabel/InputLabel';
import FormDefaultStepOneInformation from './FormDefaultStepOneInformation';

class FormDefaultStep1 extends Component {
    constructor(props) {
        super(props);
        this.state={
            notValidMessage: 'Required Field Missing',
            validFields: true
        }
    };
    isValid = () => {
        let result = false;
        const { SignUpStepOneInformation } = this.refs;
        const si = SignUpStepOneInformation.isValid();
        if (si) {
            result = true;
        }
        return result;
    };
    setError = (msg) => {
        this.setState({
            notValidMessage: msg
        });
    };
    invalidEmail = () => {
        const { SignUpStepOneInformation } = this.refs;
        SignUpStepOneInformation.invalidEmail();
    };
    invalidConfirmEmail = () => {
        const { SignUpStepOneInformation } = this.refs;
        SignUpStepOneInformation.invalidConfirmEmail();
    };
    backLocation = () =>{
        this.props.onBackClick(); 
    };
    nextLocation = () => {
        const result = this.isValid();
        this.setState({validFields:result}, () =>{
            if (!result){
                return;           
            } else {
                this.props.onNextClick();
            }
        });
    };
    skipLocation = (location) =>{
        this.props.onSkipClick(location);
    };
    backAccount = (e) => {
        e.preventDefault();
        this.props.history.push('/register');
    };
    render() {
        const { props, state } = this;
        const {validFields, notValidMessage} = state;

        return (
            <GridCol>
                <FormDefaultStepOneInformation
                    ref='SignUpStepOneInformation'
                    hideSalesRep={this.props.hideSalesRep}
                    hideDistributorSalesRep={this.props.hideDistributorSalesRep}
                    setError={this.setError}
                    isPorchDoor = {this.props.isPorchDoor}
                    numberLocations={props.numberLocations} 
                    updateNumberLocations={props.updateNumberLocations} 
                    changeHandlerInputs={props.changeHandlerInputs} 
                    step1Inputs={props.step1Inputs} 
                    selectTitle={props.selectTitle}
                    {...this.props} 
                    daSalesRepFromApi={props.daSalesRepFromApi} 
                    selectDASalesRep={props.selectDASalesRep}
                    referredPartnerFromApi={props.referredPartnerFromApi}
                    selectReferredBy = {props.selectReferredBy}
                    mergedDaSalesRepReferredFromApi={props.mergedDaSalesRepReferredFromApi}
                    selectStateContactInformation={props.selectStateContactInformation}
                    validateEmailFromApi={props.validateEmailFromApi}    
                    validateRepeatedEmail={props.validateRepeatedEmail}                 
                />
                <GridRow className='line-form'>
                    { isMobile ?
                        <GridRow className='px-3'>
                            <GridCol className='form-group' size={6}>
                                <Button type='button' variant='white' size='' text='BACK' onClick={this.backAccount}/>    
                            </GridCol>
                            <GridCol className='form-group' size={6}>
                                <Button type='button' variant='primary' size='' text='NEXT'  onClick={this.nextLocation}/>
                            </GridCol>
                            <GridCol className='form-group d-flex align-items-center' size={12}>
                                <InputLabel className='text-red text-size-14'>{validFields ? '' : notValidMessage}</InputLabel>
                            </GridCol>
                        </GridRow>
                        :
                        <GridCol className='p-0'>
                            <GridCol className='form-group' size={4}>
                                <Button type='button' variant='primary' size='' text='NEXT'  onClick={this.nextLocation}/>
                            </GridCol>
                            <GridCol className='form-group d-flex align-items-center' size={4}>
                                <InputLabel className='text-red text-size-14'>{validFields ? '' : notValidMessage}</InputLabel>
                            </GridCol>
                        </GridCol>
                    }
                </GridRow>
            </GridCol>
        )
    };
}

export default FormDefaultStep1;