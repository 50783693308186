import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconDelete = props => (
  <svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Designs" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="38---Location-Dropdown" transform="translate(-889.000000, -209.000000)" fill={!props.isActive ? props.color : props.activeColor}>
        <g id="pop-up" transform="translate(470.000000, 92.000000)">
          <g id="add-distributor" transform="translate(45.000000, 95.000000)">
            <g id="icons/delete" transform="translate(376.000000, 22.000000)">
              <path d="M9.93103448,16 C11.1481379,16 12.137931,15.0102069 12.137931,13.7931034 L12.137931,4.4137931 C12.137931,4.10868966 11.8913103,3.86206897 11.5862069,3.86206897 L1.65517241,3.86206897 C1.35006897,3.86206897 1.10344828,4.10868966 1.10344828,4.4137931 L1.10344828,13.7931034 C1.10344828,15.0102069 2.09324138,16 3.31034483,16 L9.93103448,16 Z M2.20689655,13.7931034 L2.20689655,4.96551724 L11.0344828,4.96551724 L11.0344828,13.7931034 C11.0344828,14.4016552 10.5395862,14.8965517 9.93103448,14.8965517 L3.31034483,14.8965517 C2.7017931,14.8965517 2.20689655,14.4016552 2.20689655,13.7931034 Z" id="Shape"></path>
              <path d="M12.6896552,2.20689655 L8.27586207,2.20689655 L8.27586207,1.48303448 C8.27586207,0.66537931 7.61048276,0 6.79337931,0 L6.44855172,0 C5.63089655,0 4.96551724,0.66537931 4.96551724,1.48303448 L4.96551724,2.20689655 L0.551724138,2.20689655 C0.24662069,2.20689655 0,2.45351724 0,2.75862069 C0,3.06372414 0.24662069,3.31034483 0.551724138,3.31034483 L12.6896552,3.31034483 C12.9947586,3.31034483 13.2413793,3.06372414 13.2413793,2.75862069 C13.2413793,2.45351724 12.9947586,2.20689655 12.6896552,2.20689655 Z M6.06896552,1.48303448 C6.06896552,1.27668966 6.2422069,1.10344828 6.44855172,1.10344828 L6.79337931,1.10344828 C6.99917241,1.10344828 7.17241379,1.27668966 7.17241379,1.48303448 L7.17241379,2.20689655 L6.06896552,2.20689655 L6.06896552,1.48303448 Z" id="Shape"></path>
              <path d="M5.24137931,13.2413793 C5.39365517,13.2413793 5.51724138,13.1177931 5.51724138,12.9655172 L5.51724138,6.89655172 C5.51724138,6.74427586 5.39365517,6.62068966 5.24137931,6.62068966 C5.08910345,6.62068966 4.96551724,6.74427586 4.96551724,6.89655172 L4.96551724,12.9655172 C4.96551724,13.1177931 5.08910345,13.2413793 5.24137931,13.2413793 Z" id="Path"></path>
              <path d="M8,13.2413793 C8.15227586,13.2413793 8.27586207,13.1177931 8.27586207,12.9655172 L8.27586207,6.89655172 C8.27586207,6.74427586 8.15227586,6.62068966 8,6.62068966 C7.84772414,6.62068966 7.72413793,6.74427586 7.72413793,6.89655172 L7.72413793,12.9655172 C7.72413793,13.1177931 7.84772414,13.2413793 8,13.2413793 Z" id="Path"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

IconDelete.propTypes = {
  color: PropTypes.string,
  activeColor: PropTypes.string,
  isActive: PropTypes.bool,
  size: PropTypes.number,
};

IconDelete.defaultProps = {
  activeColor: config.colors.rbYellow,
  color: config.colors.rbWhite,
  isActive: false,
  size: 16,
};

export default IconDelete;