import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import Checkbox from '../../../../MasterComponents/Checkbox/Checkbox';
import Button from '../../../../MasterComponents/Button/Button';
import Input from '../../../../MasterComponents/Input/Input';
import GridRow from '../../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../../MasterComponents/Grid/GridCol';
import InputLabel from '../../../../MasterComponents/InputLabel/InputLabel';
import SignUpApi from '../../SignUpApi';
import { showModal } from '../../../../MasterComponents/Modals/ModalsActions';
import EnterCredentialsModal from '../../../Dashboard/Modals/EnterCredentialsModal';
import { isTextValid } from '../../../../MasterComponents/Input/InputValidate';
import DropDownListSelect from '../../../../MasterComponents/DropDownList/DropDownListSelect';

class FormDefaultDistributor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            atLeastOneCheck: true,
            isDistributorRepEmailValid: true,
            distributors: [],
            distributorsData: [],
        }
    }
    componentDidMount = () => {
        this.getDistributors(null, 1);
    }
    getDistributors = (text, page) => {
        const { distributors } = this.state;
        const { companyId } = this.props;
        const saveData = (data) => {
            this.setState({
                distributors: [
                    ...distributors,
                    ...data.map(dist => {
                        return {
                            ...dist,
                            label: dist.nickname,
                            value: dist.id
                        }
                    })
                ]
            });
            this.getDistributors(null, page+1);
        }
        SignUpApi.getDistributors(companyId, text, page).then(({ data }) => {
            if (distributors.length === 0) {
                saveData(data.data);
            } else {
                if (data.data[data.data.length-1].id !== distributors[distributors.length-1].id) {
                    saveData(data.data);
                }
            }
        })
    }
    selectOptionDistributor = (selectedOption) => {
        this.props.selectOptionDistributor(selectedOption);
        this.refs.distributorHouse.setValid();
    }
    selectDistributorHouse = (option) => {
        this.props.selectDistributorHouse(option);
    }
    handleSaveDistributor = (boolParam) => {
        const { distributorName, distributorHouse } = this.refs;
        // const { distributorRepEmail } = this.refs;
        const dn = distributorName.isValid();
        const dh = distributorHouse.isValid();
        const oc = this.isOneCheck();
        // const drp = !!distributorRepEmail.props.value ? distributorRepEmail.isValid() : true;
        // this.setState({isDistributorRepEmailValid: drp});
        // if (dn && dh && oc && drp) {
        if (dn && dh && oc) {
            this.props.saveDistributor(boolParam);
        }
    }
    handleCancel = (boolParam) => {
        this.props.cancelDistributor(boolParam);
    }
    checkOrderOnline = () => {
        this.props.checkOrderOnline();
    }
    handleCheckedLocations = (boolParam) => {
        this.setState({
            atLeastOneCheck: true
        });
        this.props.updateCheckedLocations(boolParam);
    }
    checkDuplicate = (id) => {
        const { step2Distributors, numberDistributor } = this.props;
        let isUsed = false;
        let i = 0;
        step2Distributors.forEach(element => {
            if (element.id === id && i !== numberDistributor) {
                isUsed = true
            }
            i++;
        });
        return isUsed;
    }
    isOneCheck = () => {
        const { addThisTo } = this.props.step2Distributors[this.props.numberDistributor];
        let atLeastOne = false;
        for (let index = 0; index < addThisTo.length; index++) {
            if (addThisTo[index]) {
                atLeastOne = true;
            }
        }
        this.setState({
            atLeastOneCheck: atLeastOne
        });
        return atLeastOne;
    }
    enterCredentials = (i) => {
        const action = this.props.dispatch;
        const { username, password } = this.props.step2Distributors[this.props.numberDistributor].credentials[i];
        action(showModal(EnterCredentialsModal, 'enterCredentialsModal', {isSignUp: true, save: this.saveCredentials, locIndex: i, username: username, password: password}));
    }
    saveCredentials = (username, password, isOk, locIndex) => {
        this.props.saveCredentials(username, password, isOk,  locIndex);
    }
    isAllDcnValid = () => {
        const { step2Distributors, numberDistributor } = this.props;
        let oneInvalid = false;
        for (let i = 0; i < step2Distributors[numberDistributor].dcn.length; i++) {
            const value = step2Distributors[numberDistributor].dcn[i];
            if (!isTextValid(value, 3, 20)) {
                oneInvalid = true;
            }
        }
        return !oneInvalid;
    }

    render() {
        const props = this.props;
        const {changeHandlerDistributorInputDcn, numberLocations, distributorsHouse, distributorsHouseSelected, step1Locations, companyId} = this.props;
        const step1LocationsTmp = step1Locations.filter((item) => item.isSkipEnabled === false);
        const textDCN = companyId === 5 ? 'Ship to account number' : 'Distributor Account # (DCN) (optional)';
        const textPlaceholderDCN = companyId === 5 ? 'Ship to account number' : 'DCN';

        return (
            <GridCol className='form-box'>
                <GridRow>
                    <GridCol>
                        <InputLabel text='Add Distributor' title className='title'/>
                    </GridCol>
                </GridRow>
                <GridRow className='line-form'>
                    <GridCol className='form-group'>
                        <InputLabel text='Distributor Name *'/>
                        <DropDownListSelect
                            name='distributorName' ref='distributorName'
                            value={props.step2Distributors[props.numberDistributor].distributorSelected}
                            onChange={this.selectOptionDistributor} options={this.state.distributors}
                        />
                    </GridCol>
                </GridRow>
                <GridRow className='line-form'>
                    <GridCol className='form-group'>
                        <InputLabel text='Distributor House *'/>
                        <DropDownListSelect
                            name='distributorHouse' ref='distributorHouse'
                            value={distributorsHouseSelected}
                            onChange={this.selectDistributorHouse} options={distributorsHouse}
                            isDisabled={distributorsHouse.length === 0}
                        />
                    </GridCol>
                </GridRow>
                <GridRow className='line-form'>
                    <GridCol className='form-group'>
                        <InputLabel text='Distributor Rep Name' />
                        <Input type='text' name='distributorRepName' ref='distributorRepName' value={props.step2Distributors[props.numberDistributor].distributorRepName} onChange={props.changeHandlerDistributorInput} />
                    </GridCol>
                </GridRow>
                <GridRow className='line-form'>
                    <GridCol className='form-group'>
                        <InputLabel text='Distributor Rep Email' className={(!this.state.isDistributorRepEmailValid ? 'text-red' : '')} />
                        <Input type='email' name='distributorRepEmail' ref='distributorRepEmail' value={props.step2Distributors[props.numberDistributor].distributorRepEmail} onChange={props.changeHandlerDistributorInput} />
                    </GridCol>
                </GridRow>
                <GridRow className='line-form'>
                    <GridCol className={isMobile ? 'col-6' : 'col-12'}>
                        <Checkbox onChange={this.checkOrderOnline} name='isOrderOnline' value={props.step2Distributors.isOnlineOrderer} text='I order Online' />
                    </GridCol>
                </GridRow>
                <GridRow className={'line-form ' + (!isMobile ? 'mt-4' : 'mt-2')}>
                    <InputLabel className={'col col-12 subtitle' + (!this.state.atLeastOneCheck ? ' text-red' : '')} text='Add this distributor to:' title/>
                </GridRow>
                <GridRow className='line-form'>
                    {numberLocations > 1 ?
                    <GridCol className={'form-group' +(isMobile ? ' my-0' : '')}>
                        <GridRow className='check'>
                            <GridCol className={isMobile ? 'col-2' : 'col-1'}>
                                <Checkbox onChange={this.handleCheckedLocations} name={0} value={props.step2Distributors[props.numberDistributor].addThisTo[0]} text='' />
                            </GridCol>
                            <GridCol className={'col' + (isMobile ? '' : ' pl-2')}>
                                All Locations
                            </GridCol>
                        </GridRow>
                    </GridCol>
                    : null}
                </GridRow>

                {step1LocationsTmp.map((loc, i) => (
                <GridRow key={i} className='line-form'>
                    <GridCol className={'form-group' +(isMobile ? ' my-0' : '')}>
                        <GridRow className='check'>
                            <GridCol className={isMobile ? 'col-2' : 'col-1'}>
                                <Checkbox onChange={this.handleCheckedLocations} name={i+1} text='' value={props.step2Distributors[props.numberDistributor].addThisTo[i + 1]}  />
                            </GridCol>
                            <GridCol className={'col' + (isMobile ? '' : ' pl-4')}>
                                {loc.nickname}
                            </GridCol>
                        </GridRow>
                    </GridCol>
                    <GridCol className='form-group'>
                        <InputLabel className='col col-12 p-0 text-nowrap' text={textDCN} />
                        <Input type='text' placeholder={textPlaceholderDCN} name={i} value={props.step2Distributors[props.numberDistributor].dcn[i] || ''} onChange={changeHandlerDistributorInputDcn} />
                    </GridCol>
                </GridRow>
                ))}
                <GridRow className='line-form mt-5'>
                    <GridCol className='form-group'>
                        <Button type='button' variant='white' size='' text='CANCEL' onClick={this.handleCancel.bind(this, false)}></Button>
                    </GridCol>
                    <GridCol className='form-group'>
                        <Button type='button' variant='primary' size='' text='SAVE' onClick={this.handleSaveDistributor.bind(this, false)}></Button>
                    </GridCol>
                </GridRow>
            </GridCol>
        );
    }
}

const mapStateToProps = state => {
    return {
        companyId: state.company.id
    };
}

export default connect(mapStateToProps)(FormDefaultDistributor);
