import React, { Component } from 'react';
import './RadioButton.scss';
import PropTypes from 'prop-types';

class RadioButton extends Component {
    onClick = (event) => {
        if (!this.props.readOnly) {
            this.props.onChange(this.props.value, this.props.name, event);
        }
    }
    render() {
        const props = this.props;
        const { className, text } = props;
        return (
            <label className={'radiobutton-container' + (className ? ` ${className}` : '')}>{text}
                <input
                    type='radio'
                    name={props.name}
                    value={props.value}
                    checked={props.checked}
                    disabled={props.disabled}
                    onChange={this.onClick}
                    readOnly={props.readOnly}
                />
                <span className='mark'></span>
                {props.children}
            </label>
        );
    }
};


RadioButton.propTypes = {
    name: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
    checked: PropTypes.bool,
    text: PropTypes.string,
    className: PropTypes.string,
    grayText: PropTypes.bool,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
}

RadioButton.defaultProps = {
    bool: false
}

export default RadioButton;
