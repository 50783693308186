import React, { Component } from 'react';
import NewCard from './NewCard';
class New extends Component {
  render() {
    const { newArr, dispatch } = this.props;
    const propsNew = {
      newAll: newArr,
      dispatch
    }
    return (
      <NewCard {...propsNew} />
    )
  }
};

export default New;

