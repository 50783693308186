import React, { Component } from 'react';
import '../SignUp.scss';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import CompanyApi from '../../../config/Company/CompanyApi';
import { spinner } from '../../../MasterComponents/Spinner/SpinnerActions';
import { updateBrandSelected, updatePartners } from '../../../config/Company/CompanyActions';
import SignUpPorchDoorBrandSidebar from './SignUpPorchDoorBrandSidebar';
import SignUpForm from '../Forms/SignUpForm';

class SignUpPorchDoorBrand extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            step: 1,
            lastStep: 1,
            initReferredBy: null
        };
    };
    componentDidMount() {
        const { history, match, dispatch } = this.props;
        const { company } = match.params;
        if (history.location.search === '?c=tra') {
            const tra = {
                abbreviation: "tra",
                channelPlatformId: "CP-000703",
                companyId: 1,
                description: "Texas Restaurant Association",
                domain: "https://portal.buyersedgeplatform.com/lop/partners/tra",
                formType: "default",
                id: 20,
                name: "Texas Restaurant Association",
                salesRepId: null,
                type: "channel"
            }
            dispatch(updatePartners([tra], tra));
        }
        this.isOnTheList(company).then((c) => {
            const { partnerSelected } = this.props;
            if (partnerSelected) {
                // Set Referred by
                if ( partnerSelected.channelPlatformId || partnerSelected.channelPlatformId !== 'CP-none' ) {
                    const referBy = {value: partnerSelected.id, isDaSalesRep: true, label: partnerSelected.description, platformId: partnerSelected.channelPlatformId};
                    this.setState({
                        loading: false,
                        initReferredBy: referBy
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                }
            } else {
                const dontKnowReferBy = {value: c.salesRepId, isDaSalesRep: true, label: "I don't know my sales rep", iDontKnow: true};
                this.setState({
                    loading: false,
                    initReferredBy: c.salesRepId && dontKnowReferBy
                });
            }
        }).catch(() => {
            // Not on list
            history.push('/');
        });
    };
    isOnTheList = (name) => {
        return new Promise ((resolve, reject) => {
            const { dispatch, companyId, partnerSelected, formType} = this.props;
            let result = false;

            dispatch(spinner(true));
            CompanyApi.getOne(companyId, 'partners').then(({data}) => {
                const { partners } = data.data;
                dispatch(spinner(false));
                partners.forEach(c => {
                    if (c.abbreviation === name.toLowerCase() && c.type === 'brand') {
                        const ft = partnerSelected ? formType : c.formType;
                        dispatch(updateBrandSelected(c, ft));
                        result = c;
                    }
                });
                if (result) {
                    resolve(result);
                } else {
                    reject(false);
                }
            });
        });
    };
    getFavicon = () => {
        const { company } = this.props.match.params;
        const path = 'https://rebate-portal.s3.amazonaws.com/lops/';        
        const icon = `${path}${company.toLowerCase()}-ico.png`;
        return icon;
    };
    componentDidUpdate(prevProps, prevState) {
        const { action, push, replace } = this.props.history;
        const { urlStep, company } = this.props.match.params;
        const { isCoolSchool } = this.props;
        const url = isCoolSchool ? '/register' : `/platform/brand/${company}`;
        const prevStep = parseInt(prevProps.match.params.urlStep ? prevProps.match.params.urlStep : 1);
        const futureStep = parseInt(urlStep ? urlStep : 1);
        if (action === 'POP') {
            if ( ( prevStep < futureStep ) && ( futureStep > this.state.lastStep ) ) {
                this.setState({
                    step: prevStep,
                    lastStep: prevStep > this.state.lastStep ? prevStep : this.state.lastStep
                });
                replace(`${url}/${prevStep}`);
            } else if (futureStep > this.state.lastStep) {
                push(`${url}/${this.state.lastStep}`)
            } else {
                if (futureStep !== this.state.step) {
                    this.setState({
                        step: futureStep,
                        lastStep: futureStep > this.state.lastStep ? futureStep : this.state.lastStep
                    });
                }
            }
        } else {
            if (futureStep !== this.state.step) {
                this.setState({
                    step: futureStep,
                    lastStep: futureStep > this.state.lastStep ? futureStep : this.state.lastStep
                });
            }
        }
    };
    getNameAndAbbreviation = () => {
        const { brandSelected, isCoolSchool, partnerSelected, match } = this.props;
        const { company } = match.params;

        if (isCoolSchool) {
            return {
                name: {
                    brand: brandSelected && brandSelected.name,
                    partner: 'Prime Services'
                },
                abbreviation: {
                    brand: brandSelected && brandSelected.abbreviation,
                    partner: 'prime'
                },
            };
        }

        if (company === 'arrowstream') {
            return {
                name: {
                    brand: 'Consolidated Concepts',
                    partner: 'Arrowstream'
                },
                abbreviation: {
                    brand: 'cc',
                    partner: 'arrowstream'
                },
                extraText: {
                    partner: 'In Partnership With:'
                }
            }
        }

        return {
            name: {
                brand: brandSelected && brandSelected.name,
                partner: partnerSelected && partnerSelected.name
            },
            abbreviation: {
                brand: brandSelected && brandSelected.abbreviation,
                partner: partnerSelected && partnerSelected.abbreviation
            },
        }
    };
    render() {
        const { history, brandSelected, formType, match, isCoolSchool } = this.props;
        const { company } = match.params;
        const { step, loading, initReferredBy } = this.state;
        const sidebarProps = {
            history,
            ...this.getNameAndAbbreviation(),
            step: step,
            isCoolSchool
        }
        const formProps = {
            isPorchDoor: true,
            step,
            title: step === 1 ? 'Step 1: Operator and Contact Information' : ( step === 2 ? 'Step 2: Terms & Conditions' : ''),
            maxSteps: formType === 'default' ? 3 : 2,
            formType,
            history,
            initReferredBy,
            brandSearch: company,
            pathname: isCoolSchool ? '/register' : `/platform/brand/${company}`
        }
        return (
            <div className='container-signup'>
                <Helmet>
                    <link rel="shortcut icon" type="image/png" href={this.getFavicon()} />
                    <title>{brandSelected && brandSelected.name}</title>
                </Helmet>
                <SignUpPorchDoorBrandSidebar {...sidebarProps} />
                {
                    !loading && <SignUpForm {...formProps} />
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        companyId: state.company.id,
        partners: state.company.partners,
        brandSelected: state.company.brandSelected,
        partnerSelected: state.company.partnerSelected,
        formType: state.company.formType
    }
}

export default connect(mapStateToProps)(SignUpPorchDoorBrand);