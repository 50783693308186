import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'
import Home from '../views/Home/Home';
import Login from '../views/Login/Login';
import Logout from '../views/Logout/Logout';
import ResetPassword from '../views/ResetPassword/ResetPassword';
import ChangePassword from '../views/ChangePassword/ChangePassword';
import Dashboard from '../views/Dashboard/Dashboard';
import LoginAdmin from '../views/Login/LoginAdmin';
import LoginMicrosoft from '../views/Login/LoginMicrosoft';
import SignUpPorchDoorChannel from '../views/SignUp/PorchDoor/SignUpPorchDoorChannel';
import SignUpPorchDoorBrand from '../views/SignUp/PorchDoor/SignUpPorchDoorBrand';
import SignUpWhitelabelNewOrOld from '../views/SignUp/Whitelabel/SignUpWhitelabelNewOrOld';
import SignUpWhitelabelOld from '../views/SignUp/Whitelabel/SignUpWhitelabelOld';
import SignUpWhitelabelNewType from '../views/SignUp/Whitelabel/SignUpWhitelabelNewType';
import SignUpWhitelabelNewForm from '../views/SignUp/Whitelabel/SignUpWhitelabelNewForm';
import SignUpRedirect from '../views/SignUp/SignUpRedirect';
import Ach from '../views/Ach/Ach';
import CashbackEstimator from '../views/RebateCalculator/RebateCalculator';
import SignUpSimple from '../views/SignUpSimple/SignUpSimple';
import Onboarding from '../views/Onboarding/Onboarding';
import TermsAndConditions from '../views/TermsAndConditions/TermsAndConditions';
import JoinToday from '../views/JoinToday/JoinToday';
import CreatePassword from '../views/CreatePassword/CreatePassword';
import DCNCredentialsForm from '../views/DCNCredentials/DCNCredentialsForm';

class Router extends Component {
    isCoolSchool = () => {
        const validUrl = [
            'prime.coolschoolcafe.com',
            'staging.prime.coolschoolcafe.com',
            'dev.prime.coolschoolcafe.com'
        ];
        return validUrl.includes(window.location.hostname.toLowerCase());
    };
    isPorchDoor = () => {
        const validUrl = [
            'portal.buyersedgeplatform.com',
            'jc-portal.buyersedgeplatform.com',
            'dev-portal.buyersedgeplatform.com',
            'staging-portal.buyersedgeplatform.com'
        ];
        return validUrl.includes(window.location.hostname.toLowerCase());
    };
    render() {
        if (this.isCoolSchool()) {
            return (
                <Switch>
                    <Route exact path='/register' render={props => <SignUpRedirect {...props} />} />
                    <Route exact path='/register/:urlStep'
                        render={
                            props => <SignUpPorchDoorBrand {...props} isCoolSchool match={{...props.match, params: {...props.match.params, company: 'coolschool'}}} />
                        }
                    />
                    <Route>
                        <Redirect to='/register/1' />
                    </Route>
                </Switch>
            )
        }
        if (this.isPorchDoor()) {
            return (
                <Switch>
                    <Route exact path='/' component={() => {
                        window.location.href = 'https://www.buyersedgeplatform.com/';
                        return null;
                    }}/>
                    <Route exact path='/lop/partners/:company' render={props => <SignUpPorchDoorChannel {...props} />} />
                    <Route exact path='/platform/brand/:company' render={props => <SignUpRedirect {...props} />} />
                    <Route exact path='/platform/brand/:company/:urlStep' render={props => <SignUpPorchDoorBrand {...props} />} />
                </Switch>
            )
        }
        if (localStorage.getItem('isLoggedIn') === 'true') {
            const status = localStorage.getItem('status');
            if (status && status === '1') {
                return (
                    <Switch>
                        <Route exact path='/logout' render={props => <Logout {...props} />} />
                        <Route path='/onboarding/:urlStep' component={Onboarding} />
                        <Route>
                            <Redirect to='/onboarding/1' />
                        </Route>
                    </Switch>
                );
            }
            return (
                <Switch>
                    <Route path='/dashboard' component={Dashboard} />
                    <Route exact path='/login/:token' component={Login} />
                    <Route path='/admin-login' render={props => <LoginAdmin {...props} />} />
                    <Route exact path='/logout' render={props => <Logout {...props} />} />
                    <Route exact path='/terms-and-conditions' component={TermsAndConditions} />
                    <Route exact path='/notification/:notificationId' render={props => <DCNCredentialsForm {...props} />} />
                    <Route>
                        <Redirect to='/dashboard' />
                    </Route>
                </Switch>
            );
        } else {

            return (
                <Switch>
                    {
                        process.env.REACT_APP_COMPANY_ID === '1' ?
                            <Route path='/join-dining-alliance-for-free-today' component={JoinToday} />
                        : null
                    }
                    {
                        process.env.REACT_APP_COMPANY_ID === '1' ?
                            <Route exact path='/tra' component={SignUpRedirect} />
                        : null
                    }
                    {
                        process.env.REACT_APP_COMPANY_ID === '1' ?
                            <Route exact path='/tra/:urlStep'
                                render={({match, ...newProps}) =>
                                    <SignUpPorchDoorChannel {...newProps}
                                        match={{...match, params: {...match.params, company: 'tra'}}}
                                    />
                                }
                            />
                        : null
                    }
                    <Route exact path='/' render={props => <Home {...props} />} />
                    <Route exact path='/microsoft-login' render={props => <LoginMicrosoft {...props} />} />
                    <Route exact path='/login' render={props => <Login {...props} />} />
                    <Route exact path='/login/:token' render={props => <Login {...props} />} />
                    <Route exact path='/logout' render={props => <Logout {...props} />} />
                    <Route exact path='/reset-password' render={props => <ResetPassword {...props} />} />
                    <Route exact path='/change-password/:token' render={props => <ChangePassword {...props} />} />
                    <Route exact path='/create-password/:recordId' render={props => <CreatePassword {...props} />} />
                    <Route exact path='/register' render={props => <SignUpWhitelabelNewOrOld {...props} />} />
                    <Route exact path='/register/old/:urlStep' render={props => <SignUpWhitelabelOld {...props} />} />
                    <Route exact path='/register/old' render={props => <SignUpRedirect {...props} />} />
                    <Route exact path='/register/new/type' render={props => <SignUpWhitelabelNewType {...props} />} />
                    <Route exact path='/register/new/:urlStep' render={props => <SignUpWhitelabelNewForm {...props} />} />
                    <Route exact path='/register/salesrep/:urlStep' render={props => <SignUpWhitelabelNewForm {...props} signUpSalesRep={true} />} />
                    <Route exact path='/register/canada/:urlStep' render={props => <SignUpWhitelabelNewForm isCanadaUrl {...props} />} />
                    <Route exact path='/register/easy' render={props => <SignUpSimple {...props} country={process.env.REACT_APP_COMPANY_ID === '8' ? 'ca' : 'us'} />} />
                    {
                        process.env.REACT_APP_COMPANY_ID === '1' ?
                            <Route exact path='/register/food-service-rewards' render={props => <SignUpSimple {...props} country='us' isFoodServiceRewards />} />
                        : null
                    }
                    <Route exact path='/cashback-estimator' render={props => <CashbackEstimator {...props} />} />
                    <Route exact path='/simple-register' render={props => <SignUpSimple {...props} isIframe country='us' />} />
                    <Route exact path='/ach' render={props => <Ach {...props} />} />
                    <Route exact path='/terms-and-conditions' component={TermsAndConditions} />
                    <Route exact path='/notification/:notificationId' render={props => <DCNCredentialsForm {...props} />} />
                    <Route>
                        <Redirect to='/' />
                    </Route>
                </Switch>
            );
        }
    }
};

export default Router;
