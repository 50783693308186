import React, { Component } from 'react';
import GridRow from '../../MasterComponents/Grid/GridRow';
import GridCol from '../../MasterComponents/Grid/GridCol';
import { isMobile } from 'react-device-detect';

class DashboardHeaderImage extends Component {
  render() {
    const { text, background, logo } = this.props;
    return (
      <GridRow id='dashboard-header-img' align='center' className={isMobile ? 'mobile' : ''}>
        <img src={background} alt="The savings Portal"></img>
        <GridRow className='cashback-row align-items-center h-100'>
          <GridCol>
            <img className='header-logo-img' src={logo} alt="Cashback header"></img>
            <span className='cashback-label'>{text}</span>
          </GridCol>
        </GridRow>
      </GridRow>
    );
  }
}

export default DashboardHeaderImage;