import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import CompanyApi from './CompanyApi';
import { updateColors, updatePartners, updateRegisterLinks, setText, setDisplay, setCompanyLoading, setSocialLinks, setHomepage, setCountry } from './CompanyActions';
import { spinner } from '../../MasterComponents/Spinner/SpinnerActions';
import { getBtnBorderRadius, getStepActiveColor, getFonts, getFontStyleSheet, getTopBarLinkActiveColor, getBorderBottom, getMainButtonColor, getMainButtonBorderColor, getPrimaryButtonTextColor, getPrimaryButtonBorderWidth, getPrimaryButtonFontWeight, getDashboardFooterColor, getTopBarColor, getDashboardTopBarColor, getDashboardTopBarLinkColor } from './CompanyHelper';
class CompanyStyle extends Component {
  componentDidMount() {
    const { companyId, dispatch } = this.props;
    const storeColors = localStorage.getItem('companyColors');
    if (storeColors) {
      dispatch(updateColors(JSON.parse(storeColors)));
    }
    dispatch(spinner(true));
    CompanyApi.getOne(companyId, 'media,colors,registerLinks,text').then(({data}) => {
      const {
        country, colors, registerLinks, text, homepage,
        displayCashBack, displayInsight, displayItemIntel,
        displayClaimCheck, displayBeyondPrime, displayFoodbam,
        displayExistingUser, displayPopularDistributors,
        displayReports, displayContractManagement, displayFoodCostManagement
      } = data.data;
      dispatch(spinner(false));
      this.saveColors(colors);
      this.saveRegisterLinks(registerLinks);
      this.saveText(text);
      dispatch(setHomepage(homepage));
      dispatch(setDisplay(displayCashBack, displayInsight, displayItemIntel, displayClaimCheck, displayBeyondPrime, displayFoodbam, displayExistingUser, displayPopularDistributors, displayReports, displayContractManagement, displayFoodCostManagement));
      dispatch(setCompanyLoading(false));
      dispatch(setCountry(country));
    });
  };
  saveText = (text) => {
    const registerText = text.filter( t => t.type === 'register_text')[0];
    const registerBullets = text.filter( t => t.type === 'register_bullets')[0];
    const selfRegisterText = text.filter( t => t.type === 'self_register_text')[0];
    const selfRegisterBullets = text.filter( t => t.type === 'self_register_bullets')[0];
    const selfRegisterTitle = text.filter( t => t.type === 'self_register_title')[0];
    const selfRegisterSubtitle = text.filter( t => t.type === 'self_register_subtitle')[0];
    const loginText = text.filter( t => t.type === 'login_text')[0];
    const loginBullets = text.filter( t => t.type === 'login_bullets')[0];
    const terminationDate = text.filter( t => t.type === 'termination_date')[0];
    const reportingLocations = text.filter( t => t.type === 'reporting_locations')[0];
    const copyright = text.filter( t => t.type === 'copyright')[0];
    const dashboardHeader = text.filter( t => t.type === 'dashboard_header')[0];
    const activeDistributorCashBack = text.filter( t => t.type === 'active_distributor_cashback')[0];
    const termsAndConditions = text.filter( t => t.type === 'terms_and_conditions')[0];
    const contactInformationHeader = text.filter( t => t.type === 'contact_information_header')[0];
    const businessAddress = text.filter( t => t.type === 'business_address')[0];
    const existingPlatformId = text.filter( t => t.type === 'existing_platform_id')[0];
    const existingSalesRep = text.filter( t => t.type === 'existing_sales_rep')[0];
    const blogLink = text.filter( t => t.type === 'blog_link')[0];
    this.saveTextProperty('registerText', registerText);
    this.saveTextProperty('registerBullets', registerBullets, true);
    this.saveTextProperty('selfRegisterText', selfRegisterText);
    this.saveTextProperty('selfRegisterBullets', selfRegisterBullets, true);
    this.saveTextProperty('selfRegisterTitle', selfRegisterTitle);
    this.saveTextProperty('selfRegisterSubtitle', selfRegisterSubtitle);
    this.saveTextProperty('loginText', loginText);
    this.saveTextProperty('loginBullets', loginBullets, true);
    this.saveTextProperty('terminationDate', terminationDate);
    this.saveTextProperty('reportingLocations', reportingLocations);
    this.saveTextProperty('copyright', copyright);
    this.saveTextProperty('dashboardHeader', dashboardHeader);
    this.saveTextProperty('activeDistributorCashBack', activeDistributorCashBack, true);
    this.saveTextProperty('termsAndConditions', termsAndConditions);
    this.saveTextProperty('contactInformationHeader', contactInformationHeader);
    this.saveTextProperty('businessAddress', businessAddress);
    this.saveTextProperty('existingPlatformId', existingPlatformId);
    this.saveTextProperty('existingSalesRep', existingSalesRep);
    this.saveTextProperty('blogLink', blogLink);
    // Social Links
    const { dispatch } = this.props;
    const facebook = text.filter( t => t.type === 'social_facebook')[0];
    const twitter = text.filter( t => t.type === 'social_twitter')[0];
    const linkedIn = text.filter( t => t.type === 'social_linkedin')[0];
    const instagram = text.filter( t => t.type === 'social_instagram')[0];
    const links = {
      facebook: facebook ? facebook.value : null,
      twitter: twitter ? twitter.value : null,
      linkedIn: linkedIn ? linkedIn.value : null,
      instagram: instagram ? instagram.value : null,
    };
    dispatch(setSocialLinks(links));
  };
  saveTextProperty = (prop, value, parse) => {
    const { dispatch } = this.props;
    if (value) {
      const saveThis = parse ? JSON.parse(value.value) : value.value;
      dispatch(setText(prop, saveThis));
    }
  };
  saveRegisterLinks = (links) => {
    const { dispatch } = this.props;
    dispatch(updateRegisterLinks(links));
  };
  savePartners = (partners) => {
    const { dispatch } = this.props;
    dispatch(updatePartners(partners));
  };
  saveColors = (colors) => {
    const { dispatch, companyId } = this.props;
    const primary = colors.filter(c => c.type === 'primary')[0];
    const secondary = colors.filter(c => c.type === 'secondary')[0];
    const tertiary = colors.filter(c => c.type === 'tertiary')[0];
    const newColors = {
      mainButton: getMainButtonColor(companyId),
      mainButtonBorder: getMainButtonBorderColor(companyId),
      primaryButtonTextColor: getPrimaryButtonTextColor(companyId),
      primaryButtonBorderWidth: getPrimaryButtonBorderWidth(companyId),
      primaryButtonFontWeight: getPrimaryButtonFontWeight(companyId),
      topbar: getTopBarColor(companyId),
      dashboardTopbar: getDashboardTopBarColor(companyId),
      dashboardTopbarLink: getDashboardTopBarLinkColor(companyId),
      dashboardFooter: getDashboardFooterColor(companyId),
      primary: primary ? primary.value : '#0071CE',
      secondary: secondary ? secondary.value : '#FA9016',
      tertiary: tertiary ? tertiary.value : '#23408F'
    };
    localStorage.setItem('companyColors', JSON.stringify(newColors));
    dispatch(updateColors(newColors));
  };
  render() {
    const { mainButton, mainButtonBorder, primaryButtonTextColor, primaryButtonBorderWidth, primaryButtonFontWeight,
      topbar, dashboardTopbarLink, dashboardFooter, primary, secondary, tertiary } = this.props.color;
    const { companyId } = this.props;
    const fonts = getFonts(companyId);
    const btnBorderRadius = getBtnBorderRadius(companyId);
    const stepActiveColor = getStepActiveColor(companyId);
    const topBarLinkActiveColor = getTopBarLinkActiveColor(companyId);
    const borderBottom = getBorderBottom(companyId);
    const styleSheet = getFontStyleSheet(companyId);

    return (
      <Helmet>
        <link rel="stylesheet" href={`${process.env.PUBLIC_URL}/css/${styleSheet}.css`} />
        <style type='text/css'>{`
          :root {
            --primary: ${primary};
            --secondary: ${secondary};
            --tertiary: ${tertiary};
            --bodyFont: '${fonts.body}';
            --lightFont: '${fonts.light}';
            --mediumFont: '${fonts.medium}';
            --boldFont: '${fonts.bold}';
          }
          .btn-rb-white {
            border-radius: ${btnBorderRadius};
            border-width: ${primaryButtonBorderWidth};
            ${primaryButtonFontWeight && 'font-weight: ' + primaryButtonFontWeight };
          }
          .btn-rb-primary {
            background-color: ${mainButton} !important;
            border-color: ${mainButtonBorder};
            border-radius: ${btnBorderRadius};
            color: ${primaryButtonTextColor} !important;
            border-width: ${primaryButtonBorderWidth};
            ${primaryButtonFontWeight && 'font-weight: ' + primaryButtonFontWeight };
          }
          .btn-primary {
            background-color: ${mainButton} !important;
            border-color: ${mainButtonBorder} !important;
            border-radius: ${btnBorderRadius};
            color: ${primaryButtonTextColor} !important;
            border-width: ${primaryButtonBorderWidth};
            ${primaryButtonFontWeight && 'font-weight: ' + primaryButtonFontWeight };
          }
          .line-items .bullet-circle {
            background-color: ${primary};
          }
          .home-header {
            background-color: ${topbar};
          }
          .step-active {
            background-color: ${stepActiveColor};
            border-color: ${stepActiveColor};
          }
          .step-done {
            background-color: ${stepActiveColor};
            border-color: ${stepActiveColor};
          }
          #dashboard-topbar .bar .links.active{
            background-color: ${dashboardTopbarLink};
            color: ${topBarLinkActiveColor};
            border-bottom: ${borderBottom};
          }
          #dashboard-footer {
            background-color: ${dashboardFooter};
          }
        `}</style>
      </Helmet>
    )
  };
};

const mapStateToProps = state => {
  return {
    companyId: state.company.id,
    color: state.company.color
  }
};

export default connect(mapStateToProps)(CompanyStyle);
