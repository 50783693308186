import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconDots = props => (
  <svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 16 4" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Bottom-Nav" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="1---Homepage" transform="translate(-330.000000, -1331.000000)" fill={props.isActive ? props.activeColor : props.color} fillRule="nonzero">
        <g id="menu" transform="translate(0.000000, 1314.000000)">
          <g id="#5" transform="translate(300.000000, 0.000000)">
            <g id="icons/more" transform="translate(30.000000, 17.000000)">
              <path d="M8,0 C7.115,0 6.4,0.715 6.4,1.6 C6.4,2.485 7.115,3.2 8,3.2 C8.885,3.2 9.6,2.485 9.6,1.6 C9.6,0.715 8.885,0 8,0 L8,0 Z" id="Path"></path>
              <path d="M1.62,0 C0.735,0 0.02,0.715 0.02,1.6 C0.02,2.485 0.735,3.2 1.62,3.2 C2.505,3.2 3.22,2.485 3.22,1.6 C3.22,0.715 2.5,0 1.62,0 L1.62,0 Z" id="Path"></path>
              <path d="M14.4,0 C13.515,0 12.8,0.715 12.8,1.6 C12.8,2.485 13.515,3.2 14.4,3.2 C15.285,3.2 16,2.485 16,1.6 C16,0.715 15.285,0 14.4,0 L14.4,0 Z" id="Path"></path>
            </g>
          </g>
        </g>
        </g>
    </g>
  </svg>
);

IconDots.propTypes = {
  color: PropTypes.string,
  activeColor: PropTypes.string,
  isActive: PropTypes.bool,
  size: PropTypes.number,
};

IconDots.defaultProps = {
  activeColor: config.colors.rbYellow,
  color: config.colors.rbWhite,
  isActive: false,
  size: 16,
};

export default IconDots;