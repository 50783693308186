import React, { Component } from 'react';
import {Row, Col, Button, Card, CardBody} from 'reactstrap';
import { isMobile } from 'react-device-detect';
import config from '../../../../config/config';
const logoDA = `https://rebate-portal.s3.amazonaws.com/lops/my-da-logo.png`;
const logoBooyah = `https://rebate-portal.s3.amazonaws.com/lops/booyah-logo.png`;
const extensionDA = `https://chrome.google.com/webstore/detail/dining-alliance/kfmokcceclckeamgbcdeddallckbdlpd`;
const extensionBooyah = `https://chrome.google.com/webstore/detail/booyah/nfiainmjgkibdpjpofdillkcbbfimnpb`;
class FormDefaultBrowserExtension extends Component {

  clickDownload = () => {
    const { companyId } = this.props;
    window.open(companyId === 1 ? extensionDA : extensionBooyah, '_blank');
  }

  render() {
    const { companyId } = this.props;
    const logo = companyId === 1 ? logoDA : logoBooyah;
    return (
      <>
        <Row className='py-3'>
          <Col className='text-size-24 line-height-28'>
            Download our browser Extension
          </Col>
        </Row>
        <Row>
        <Col>
          <Card body className='bg-blue-dark p-0'>
            <CardBody>
              <Row className={isMobile ? 'text-center' : ''}>
                <Col xs={isMobile ? 12 : 2} className={isMobile ? 'text-center' : 'border-right'}>
                  <div className='pt-3'><img className={isMobile ? '' : 'w-100'} src={logo} width={isMobile ? '20%' : '50%'} alt='extension' /></div>
                </Col>
                <Col xs={isMobile ? 12 : 10} className='pl-0'>
                  <Col xs={12} className={'text-white text-size-20 line-height-24 ' + (isMobile ? 'py-2': '')}>
                    Instantly earn rebates on all your online purchases
                  </Col>
                  <Col xs={12} className='text-white text-size-14 line-height-20 py-2'>
                    BooYAH Plugs right in to your browser to capture your rebates automatically... and it{"'"}s FREE!
                  </Col>
                  <Col xs={12} className={isMobile ? 'd-flex justify-content-center' : 'col-3'}>
                    <Button active block className='py-2 shadow-none' size='md' onClick={this.clickDownload} style={{backgroundColor: config.colors.rbYellow}} >DOWNLOAD NOW</Button>
                  </Col>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        </Row>
      </>
    )
  }
}

export default FormDefaultBrowserExtension;
