import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { showModal } from '../../MasterComponents/Modals/ModalsActions';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { BsBell } from 'react-icons/bs';
import { setSettingsOpen } from './DashboardActions';
import ReactGA from 'react-ga';
import LogoDashboardCompany from '../../MasterComponents/Logos/LogoDashboardCompany';
import NotificationModal from '../Notification/Modals/NotificationModal';
import MoreModal from './Modals/MoreModal';
import PropTypes from 'prop-types';
import GridCol from '../../MasterComponents/Grid/GridCol';
import GridRow from '../../MasterComponents/Grid/GridRow';
import DashboardTopbarLink from './DashboardTopbarLink';
import IconMarker from '../../MasterComponents/Icons/IconMarker';
import IconPieChart from '../../MasterComponents/Icons/IconPieChart';
import IconWallet from '../../MasterComponents/Icons/IconWallet';
import IconStar from '../../MasterComponents/Icons/IconStar';
import IconCart from '../../MasterComponents/Icons/IconCart';
import IconDots from '../../MasterComponents/Icons/IconDots';
import IconQuestion from '../../MasterComponents/Icons/IconQuestion';
import IconHome from '../../MasterComponents/Icons/IconHome';
import IconGraph from '../../MasterComponents/Icons/IconGraph';
import { AiOutlineFileText } from 'react-icons/ai';
import { getIconBellColor, getBottomBarStyles, getTopbarClass, getTopBarLinkIconColor, getTopBarLinkActiveColor, getTopbarStyle, getNotificationStyles, getTopBarHasIcon } from '../../config/Company/CompanyHelper';

class DashboardTopbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topbarState: [
        {
          url: ['dashboard', 'home'],
          isActive: false,
          color: ''
        },
        {
          url: ['card', 'list'],
          isActive: false,
          color: ''
        },
        {
          url: 'insights',
          isActive: false,
          color: ''
        },
        {
          url: 'claim',
          isActive: false,
          color: ''
        },
        {
          url: 'prime',
          isActive: false,
          color: ''
        },
        {
          url: 'itemIntel',
          isActive: false,
          color: ''
        },
        {
          url: 'faq',
          isActive: false,
          color: ''
        },
        {
          url: 'foodCost',
          isActive: false,
          color: ''
        },
        {
          url: 'contractmanagement',
          isActive: false,
          color: ''
        }
      ]
    }
  };

  componentDidMount = () => {
    const { history } = this.props;
    const path = history.location.pathname;
    this.getTopbarInit(path);
  }

  getTopbarInit = (link) => {
    const { companyId } = this.props;
    const { topbarState } = this.state;
    if(link){
      const lastPath = link.split('/').pop();
      const topBarLinkIconColor = getTopBarLinkIconColor(companyId);
      const topBarLinkActiveColor = getTopBarLinkActiveColor(companyId);
      this.setState({
        topbarState: topbarState.map((t)=> {
          if (t.url.includes(lastPath)) {
            return {
              ...t,
              isActive: true,
              color: topBarLinkActiveColor
            }
          }
          return {
            ...t,
            isActive: false,
            color: topBarLinkIconColor
          };
        })
      })
    }
  }

  clickLink = link => {
    const { history, dispatch, onClick, activeMerchant } = this.props;
    this.getTopbarInit(link.url);
    if (link.url === '/dashboard/more') {
      dispatch(showModal(MoreModal, 'idMoreModal', {header: activeMerchant, history, dispatch }));
    } else if (!link.comingSoon) {
      if (onClick)
        onClick();
      history.push(link.url);
    }
    switch (link.url) {
      case '/dashboard/insights':
        ReactGA.event({
          category: 'Navbar',
          action: 'Click Insights'
        });
        break;
      case '/dashboard/optimize':
        ReactGA.event({
          category: 'Navbar',
          action: 'Click Optimize My Purchases'
        });
        break;
      default:
        break;
    }
    dispatch(setSettingsOpen(false));
  };
  showNotifications = () => {
    const { dispatch, notificationList } = this.props;
    const modalProps = {
      notificationList,
      titleHeader: 'Notifications',
      showHeader: true,
      width: '380px',
      from: 'right'
    };
    dispatch(showModal(NotificationModal, 'idNotificationModal', {modalProps : modalProps} ));
  };
  render() {
    const {
      history, displayCashBack, displayInsight,
      displayItemIntel, displayClaimCheck, displayBeyondPrime, companyId, notificationCounter,
      activeMerchant, displayContractManagementWhitelabel, displayFoodCostManagement } = this.props;
    const { topbarState } = this.state;
    const topbarStyle = getTopbarStyle(companyId);
    const notificationStyles = getNotificationStyles(companyId);
    const topbarClass = getTopbarClass(companyId);
    const topBarLinkIconColor = getTopBarLinkIconColor(companyId);
    const iconBellColor = getIconBellColor(companyId);
    const topBarHasIcon = getTopBarHasIcon(companyId);
    let links = [];
    const displayContractManagement = displayContractManagementWhitelabel && (activeMerchant && activeMerchant.displayContractManagement === 1);
    if(displayCashBack && displayInsight && displayBeyondPrime) {
      links.push({
        button: 'Home',
        title: 'Home',
        url: '/dashboard/home',
        icon: () => topBarHasIcon ? <IconHome color={topbarState[0].color} /> : null,
        iconComingSoon: () => { return null }
      });
    } else {
      if(displayCashBack && isMobile) {
        links.push({
          button: isMobile ? 'Locations' : 'Locations & Distributors',
          title: 'Cash back',
          url: '/dashboard/rebates/card',
          icon: () => topBarHasIcon ? <IconMarker color={topbarState[1].color}/> : null,
          iconComingSoon: () => { return null }
        });
      }
    }
    if(displayCashBack && !isMobile) {
      links.push({
        button: isMobile ? 'Locations' : 'Locations & Distributors',
        title: 'Cash back',
        url: '/dashboard/rebates/card',
        icon: () => topBarHasIcon ? <IconMarker color={topbarState[1].color}/> : null,
        iconComingSoon: () => { return null }
      });
    }
    if(displayInsight) {
      links.push({
        button: ((companyId === 4 || companyId === 11 || companyId === 12) && !isMobile) ? 'Insights & Reports': 'Insights',
        title: 'Insights',
        url: '/dashboard/insights',
        icon: () => topBarHasIcon ? <IconPieChart color={topbarState[2].color}/> : null,
        iconComingSoon: () => { return null }
      });
    }
    if(displayClaimCheck) {
      links.push({
        button: isMobile ? 'Claim ACH' : 'Claim ACH',
        title: isMobile ? 'Claim ACH' : 'Claim ACH',
        url: '/dashboard/claim',
        icon: () => topBarHasIcon ? <IconWallet color={topbarState[3].color}/> : null,
        iconComingSoon: () => { return null }
      });
    }
    if (displayBeyondPrime) {
      links.push({
        button: isMobile ? "Programs" : "Beyond Broadline",
        title: isMobile ? "Programs" : "Beyond Broadline",
        url: "/dashboard/prime",
        icon: () => topBarHasIcon ? <IconStar color={topbarState[4].color}/> : null,
        iconComingSoon: () => { return null }
      });
    };
    if(!isMobile && displayItemIntel) {
      links.push({
        button: 'Item Intel',
        title: 'Item Intel',
        url: '/dashboard/itemIntel',
        icon: () => topBarHasIcon ? <IconGraph color={topbarState[5].color}/> : null,
        iconComingSoon: () => { return null }
      });
    }
    if (!isMobile) {
      links.push({
        button: 'FAQ & Resources',
        title: 'FAQ',
        url: '/dashboard/faq',
        icon: () => topBarHasIcon ? <IconQuestion color={topbarState[6].color}/> : null,
        iconComingSoon: () => { return null }
      });
    }
    if (!isMobile && displayFoodCostManagement) {
      links.push({
        button: 'Food Cost Management',
        title: 'Food Cost Management',
        url: '/dashboard/foodCost',
        icon: () => topBarHasIcon ? <IconCart color={topbarState[7].color}/> : null,
        iconComingSoon: () => { return null }
      });
    }
    if (!isMobile && displayContractManagement) {
      links.push({
        button: 'Contract Mgmt',
        title: 'Contract Mgmt',
        url: '/dashboard/contractmanagement',
        icon: () => topBarHasIcon ? <AiOutlineFileText color={topBarLinkIconColor} size={16} /> : null,
        iconComingSoon: () => { return null }
      });
    }
    links.push({
      button: 'More',
      title: 'More',
      url: '/dashboard/more',
      icon: () => <IconDots color={topBarLinkIconColor}/>,
      iconComingSoon: () => { return null }
    });
    const linksMap = links.map((link, i) => (
      <Fragment key={i}>
        {i === 0 || isMobile ? null : <span>| &nbsp;</span>}
        {(i === links.length - 1 && !isMobile) ? null :
          <DashboardTopbarLink
              onClick={this.clickLink.bind(this, link)}
              title={link.button}
              Icon={link.icon}
              Icon2={link.iconComingSoon}
              isActive={history.location.pathname.substring(0, 18) === '/dashboard/rebates' ? link.url.includes('/dashboard/rebates') :  history.location.pathname.trim('/').startsWith(link.url.trim('/'))}
              className={topbarClass}
              companyId={companyId}
          />
        }
      </Fragment>
    ));

    if (isMobile) {
      return (
        <GridCol id="dashboard-topbar" style={{ top: 0, borderBottom: 'solid 1px rgba(173, 164, 164, 0.5)', backgroundColor: topbarStyle.backgroundColor }}>
          <GridRow className="bar bar-bottom m-0 fixed-bottom" align="center"
            style={getBottomBarStyles(companyId)}>
            {linksMap}
          </GridRow>
          <GridRow className="bar" align="center" justify="center">
            <GridCol className='mw-fc'></GridCol>
            <GridCol className="logo mobile">
              <LogoDashboardCompany style={{maxHeight: '43px'}} />
            </GridCol>
            <GridCol className='mw-fc clickable pl-0' onClick={this.showNotifications} style={{right: isMobileOnly ? '-12%' : '-30%'}}>
              <div className='notification-icon'>
                <div className='notification-count' style={notificationStyles}>{notificationCounter}</div>
                <BsBell size={18} color={iconBellColor}/>
              </div>
            </GridCol>
          </GridRow>
        </GridCol>
      );
    } else {
      return (
        <GridCol id="dashboard-topbar">
          <GridRow className="bar px-3" align="center"
            style={topbarStyle}>
            {linksMap}
          </GridRow>
        </GridCol>
      );
    }
  }
};

DashboardTopbar.propTypes = {
  section: PropTypes.shape({
    button: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired
}

const mapStateToProps = (state) => {
  return {
    section: state.dashboard.section,
    activeMerchant: state.dashboard.activeMerchant,
    companyId: state.company.id,
    displayCashBack: state.company.displayCashBack,
    displayInsight: state.company.displayInsight,
    displayItemIntel: state.company.displayItemIntel,
    displayClaimCheck: state.company.displayClaimCheck,
    displayBeyondPrime: state.company.displayBeyondPrime,
    displayFoodbam: state.company.displayFoodbam,
    displayFoodCostManagement: state.company.displayFoodCostManagement,
    notificationList: state.notification.notificationList,
    notificationCounter: state.notification.notificationCounter,
    sidebars: state.sidebar.sidebar,
    displayContractManagementWhitelabel: state.company.displayContractManagement
  };
};

export default connect(mapStateToProps)(DashboardTopbar);
