import React from 'react';
import DropDownList from '../../../MasterComponents/DropDownList/DropDownList';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import Input from '../../../MasterComponents/Input/Input';
import InputLabel from '../../../MasterComponents/InputLabel/InputLabel';
import DatePicker from 'react-datepicker';
import { isMobile } from 'react-device-detect';
import DropDownListSelect from '../../../MasterComponents/DropDownList/DropDownListSelect';

const IsGpoForm = ({isGpo, gpoName, terminationDate, isIframe}) => {
  if (isMobile || isIframe) {
    return (
      <>
        <GridRow className='mt-3'>
          <GridCol>
            <InputLabel text='Are you a Member of a Group Purchasing Organization (GPO) *' />
            <DropDownList title={isGpo.title}>
              {isGpo.options}
            </DropDownList>
          </GridCol>
        </GridRow>
        {
          isGpo.title === 'Yes' &&
          <>
            <GridRow className='mt-3'>
              <GridCol>
                <InputLabel text={terminationDate.text} />
                <DatePicker {...terminationDate.datePickerProps} wrapperClassName='w-100' className='rb-input form-control w-100' />
              </GridCol>
            </GridRow>
            <GridRow className='mt-3'>
              <GridCol>
                <InputLabel text='Group Purchasing Organization *' />
                <Input placeholder='Group Purchasing Organization Name' name='gpoName' {...gpoName} />
              </GridCol>
            </GridRow>
          </>
        }
      </>
    );
  }

  return (
    <>
      <GridRow className='mt-3'>
        <GridCol size={6}>
          <InputLabel text='Are you a Member of a Group Purchasing Organization (GPO) *' />
                        {
                            //TODO
                        }
          <DropDownList title={isGpo.title}>
            {isGpo.options}
          </DropDownList>
        </GridCol>
        {
          isGpo.title === 'Yes' &&
          <GridCol>
            <InputLabel text={terminationDate.text} />
            <DatePicker {...terminationDate.datePickerProps} wrapperClassName='w-100' className='rb-input form-control w-100' />
          </GridCol>
        }
      </GridRow>
      {
        isGpo.title === 'Yes' &&
        <GridRow className='mt-3'>
          <GridCol size={6}>
            <InputLabel text='Group Purchasing Organization *' />
            <DropDownListSelect {...gpoName} />
          </GridCol>
        </GridRow>
      }
    </>
  );
};

export default IsGpoForm;