import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconQuestion = props => (
  <svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="FAQ" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="1---FAQ" transform="translate(-1117.000000, -24.000000)" fill={!props.isActive ? props.color : props.activeColor}>
        <g id="icons/question" transform="translate(1117.000000, 24.000000)">
          <path d="M8,16 C3.589,16 0,12.411 0,8 C0,3.589 3.589,0 8,0 C12.411,0 16,3.589 16,8 C16,12.411 12.411,16 8,16 Z M8,1 C4.14,1 1,4.14 1,8 C1,11.86 4.14,15 8,15 C11.86,15 15,11.86 15,8 C15,4.14 11.86,1 8,1 Z M9.4885,8.1895 C8.4435,9.036 8.4975,9.999 8.498,10.009 C8.5225,10.2835 8.319,10.5265 8.044,10.551 C8.029,10.552 8.014,10.5525 7.9995,10.5525 C7.743,10.5525 7.525,10.357 7.502,10.0965 C7.4965,10.0365 7.388,8.604 8.859,7.412 C9.3265,7.0335 9.5,6.6845 9.5,6.124 C9.5,5.258 8.827,4.553 8,4.553 C7.1595,4.553 6.501,5.212 6.501,6.0535 C6.501,6.33 6.277,6.5535 6.001,6.5535 C5.725,6.5535 5.501,6.33 5.501,6.0535 C5.501,4.6515 6.5985,3.5525 8,3.5525 C9.3785,3.5525 10.5,4.706 10.5,6.124 C10.5,6.986 10.188,7.6225 9.4885,8.1895 Z M8.345,11.645 C8.44,11.7395 8.495,11.8645 8.495,12 C8.495,12.135 8.44,12.26 8.345,12.355 C8.255,12.445 8.125,12.5 7.995,12.5 C7.86,12.5 7.73,12.445 7.64,12.355 C7.545,12.26 7.495,12.135 7.495,12 C7.495,11.8645 7.545,11.7395 7.64,11.645 C7.825,11.46 8.16,11.46 8.345,11.645 Z" id="Shape"></path>
        </g>
      </g>
    </g>
  </svg>
);

IconQuestion.propTypes = {
  color: PropTypes.string,
  activeColor: PropTypes.string,
  isActive: PropTypes.bool,
  size: PropTypes.number,
};

IconQuestion.defaultProps = {
  activeColor: config.colors.rbYellow,
  color: config.colors.rbWhite,
  isActive: false,
  size: 16
};

export default IconQuestion;