import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { StripeProvider } from 'react-stripe-elements';
import './App.scss';
import config from '../config/config';
import Modals from '../MasterComponents/Modals/Modals';
import Router from './Router';
import ResizeListener from '../MasterComponents/ResizeListener/ResizeListener';
import Spinner from '../MasterComponents/Spinner/Spinner';
import initFullStory from '../include/fullStory';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import CompanyStyle from '../config/Company/CompanyStyle';
import Sidebars from '../MasterComponents/Sidebar/Sidebars';
import GridContainer from '../MasterComponents/Grid/GridContainer';
import GridRow from '../MasterComponents/Grid/GridRow';
import DashboardTopbarLogo from '../views/Dashboard/DashboardTopbarLogo';
import DashboardTopbar from '../views/Dashboard/DashboardTopbar';
import { isMobile } from 'react-device-detect';
import TagManager from 'react-gtm-module';
import DriftController from './DriftController';
import BeforeUnloadListener from '../MasterComponents/BeforeUnloadListener/BeforeUnloadListener';

class GA extends Component {
  componentDidMount() {
    const { history } = this.props;
    history.listen((location) => {
      ReactGA.pageview(location.pathname+location.search);
    });
    ReactGA.initialize('UA-62143216-1');
    ReactGA.pageview(history.location.pathname+history.location.search);

    const tagManagerArgs = {
      gtmId: 'GTM-PKF37T8',
      events: {
        'gtm.start': new Date().getTime(),
        'event': 'gtm.js'
      },
    }

    TagManager.initialize(tagManagerArgs)
  }
  render() {
    return (
      null
    )
  }
}

const GARouter = withRouter(GA);

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  };
  componentDidMount = () => {
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      initFullStory();
      ReactPixel.init('483398645491797', {}, { debug: true } );
    }
  };
  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
  };
  removeError = () => {
    this.setState({
      error: null,
      errorInfo: null
    });
  };
  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <GridContainer isFluid={true} className='d-flex flex-column vh-100'>
          { !isMobile &&
            <GridRow id='dashboard-topbar-top' className='fixed-top text-white'>
              <DashboardTopbarLogo onClick={this.removeError} history={this.props.history} pathname={this.props.history.location.pathname} />
            </GridRow>
          }
          <GridRow>
            <DashboardTopbar onClick={this.removeError} history={this.props.history} pathname={this.props.history.location.pathname} />
          </GridRow>
          <GridRow className='h-100' style={{marginTop: isMobile ? '55px' : '115px', marginBottom: isMobile ? '50px' : ''}}>
            <h2>Something went wrong.</h2>
          </GridRow>
        </ GridContainer>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
};


const ErrorBoundaryRouter = withRouter(ErrorBoundary);

class App extends Component {
  componentDidMount() {
    // Segment User Identification
    // Utils.identifySegment();
  }
  render() {
    return (
      <div className="App">
        { process.env.REACT_APP_ENVIRONMENT === 'production' ? <GARouter /> : null}
        <DriftController />
        <CompanyStyle />
        <StripeProvider apiKey={config.foodBamStripeKey}>
          <ErrorBoundaryRouter>
            <Router />
            <Modals />
            <ResizeListener />
            <BeforeUnloadListener />
            <Spinner />
            <Sidebars />
          </ErrorBoundaryRouter>
        </StripeProvider>
      </div>
    );
  }
}

export default App;
