import ReactGA from 'react-ga';
import { showModal } from '../Modals/ModalsActions';
import ActivateDistributorModal from '../../views/Dashboard/Modals/ActivateDistributorModal';

const showActivateDistributor = function(merDist, isEdit) {
  const { dispatch, refresh } = this.props;
  ReactGA.event({
    category: isEdit ? 'Edit Distributor' : 'Click Button',
    action: 'Click Activate Distributor'
  });
  dispatch(showModal(ActivateDistributorModal, 'activateDistributorModal', { merchantDistributor: merDist, refresh: refresh, isEdit: isEdit }));
};

export default showActivateDistributor;