import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconAlert = props => (
    <svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Designs" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="18---Credetials-Added" transform="translate(-863.000000, -322.000000)" fill={props.color}>
                <g id="pop-up" transform="translate(370.000000, 92.000000)">
                    <g id="locations" transform="translate(30.000000, 186.000000)">
                        <g id="checkmark">
                            <g transform="translate(463.000000, 44.000000)">
                                <g id="icons/alert">
                                    <path d="M12,0 C5.4,0 0,5.40005769 0,12 C0,18.5999423 5.4,24 12,24 C18.6,24 24,18.5999423 24,12 C24,5.40005769 18.6,0 12,0 Z M13.3846154,18 L10.6153846,18 L10.6153846,15.6923077 L13.3846154,15.6923077 L13.3846154,18 Z M13.3846154,12.9230769 L10.6153846,12.9230769 L10.6153846,5.53846154 L13.3846154,5.53846154 L13.3846154,12.9230769 Z" id="Shape"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

IconAlert.propTypes = {
    color: PropTypes.string,
    activeColor: PropTypes.string,
    isActive: PropTypes.bool,
    size: PropTypes.number,
};
  
IconAlert.defaultProps = {
    activeColor: config.colors.rbYellow,
    color: config.colors.rbWhite,
    isActive: false,
    size: 16,
};
  
  export default IconAlert;