import React from 'react';
import DropDownList from '../../../MasterComponents/DropDownList/DropDownList';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import InputLabel from '../../../MasterComponents/InputLabel/InputLabel';
import DropDownListSelect from '../../../MasterComponents/DropDownList/DropDownListSelect';
import { isMobile } from 'react-device-detect';

const IsDmaForm = ({isDma, nameOfCompanies, isIframe}) => {
  if (isMobile || isIframe) {
    return (
      <>
        <GridRow className='mt-3'>
          <GridCol>
            <InputLabel text='Direct Manufacturer Agreements (Direct deals) ' />
            <DropDownList title={isDma.title}>
              {isDma.options}
            </DropDownList>
          </GridCol>
        </GridRow>
        {
          isDma.value &&
          <GridRow className='mt-3'>
            <GridCol>
              <InputLabel text="Name of Direct Deal Manufacturer (to prevent 'double dipping') *"/>
              <DropDownListSelect {...nameOfCompanies} />
            </GridCol>
          </GridRow>
        }
      </>
    )
  }

  return (
    <GridRow className='mt-3'>
      <GridCol size={6}>
        <InputLabel text='Direct Manufacturer Agreements (Direct deals) ' />
        <DropDownList title={isDma.title}>
          {isDma.options}
        </DropDownList>
      </GridCol>
      {
        isDma.value &&
        <GridCol>
          <InputLabel text="Name of Direct Deal Manufacturer (to prevent 'double dipping') *"/>
          <DropDownListSelect {...nameOfCompanies} />
        </GridCol>
      }
    </GridRow>
  );
};

export default IsDmaForm;