import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import GridRow from '../../MasterComponents/Grid/GridRow';
import GridCol from '../../MasterComponents/Grid/GridCol';
import IconInformation from '../../MasterComponents/Icons/IconInformation';
import { getIconInformationColor } from '../../config/Company/CompanyHelper';
import  './Insights.scss';

class InsightsBodyOverallStats extends Component {
  render() {
    const { dataInsights, userCurrent, companyId } = this.props;
    const showDeviations = userCurrent.displayDeviations === 1 ? true : false;
    const showRebates = userCurrent.displayRebates === 1 ? true : false;
    const showOpportunity = userCurrent.displayOpportunity === 1 ? true : false;
    const totalAmount = (dataInsights.totalAmount ? dataInsights.totalAmount : 0).toLocaleString('en', {minimumFractionDigits: 2});
    const totalRebates = (dataInsights.totalRebates ? dataInsights.totalRebates : 0).toLocaleString('en', {minimumFractionDigits: 2});
    const annualizedRebates = (dataInsights.annualizedRebates ? dataInsights.annualizedRebates : 0).toLocaleString('en', {minimumFractionDigits: 2});
    const annualizedDeviations = (dataInsights.annualizedDeviations ? dataInsights.annualizedDeviations : 0).toLocaleString('en', {minimumFractionDigits: 2});
    const potentialOpportunity = (dataInsights.potentialOpportunity ? dataInsights.potentialOpportunity : 0).toLocaleString('en', {minimumFractionDigits: 2});
    const totalDeviations = (dataInsights.totalDeviations ? dataInsights.totalDeviations : 0).toLocaleString('en', {minimumFractionDigits: 2});
    const iconInformationColor = getIconInformationColor(companyId);

    if (isMobile) {
      return(
      <>
        <GridRow>
          <GridCol className='pt-3 text-center'>
            Total Processed Purchases &nbsp;
            <span className='clickable' data-toggle='tooltInsightsTotalPurchases' title='This is your total purchase volume that we have processed so far. Please note that there can be delays and it takes us time to process all of the purchases.'>
              <IconInformation color={iconInformationColor} size={15} />
            </span>
          </GridCol>
        </GridRow>
        <GridRow className='insights-line-bottom'>
          <GridCol className='py-3 text-center text-size-38 line-height-58'>
            ${totalAmount}
          </GridCol>
        </GridRow>
        { showOpportunity && 
          <>
            <GridRow>
              <GridCol className='pt-3 text-center'>
                Potential Rebates for Opportunities &nbsp;
                <span className='clickable' data-toggle='tooltInsightsTotalPurchases' title='This is an ESTIMATE of the potential rebates opportunies you could earn. Please note that there can be delays and it takes us time to process all of the purchases.'>
                  <IconInformation color={iconInformationColor} size={15} />
                </span>
              </GridCol>
            </GridRow>
            <GridRow className='insights-line-bottom'>
              <GridCol className='py-3 text-center text-size-38 line-height-58'>
                ${potentialOpportunity}
              </GridCol>
            </GridRow>
          </>
        }
        {
          showRebates &&
          <>
            <GridRow>
              <GridCol className='pt-3 text-center'>
                Estimated Accrued Rebates &nbsp;
                <span className='clickable' data-toggle='tooltInsightsTotalRebates' title='This is an ESTIMATE of your total accrued rebates for the time period you selected. This number may include payments that have already been made to you. The final payment amounts are subject to change and may differ from this number based on manufacturer contracts changes.'>
                  <IconInformation color={iconInformationColor} size={15} />
                </span> 
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol className='py-3 text-center text-size-38 line-height-58'>
                ${totalRebates}
              </GridCol>
            </GridRow>
            <GridRow className='pb-3 insights-line-bottom'>
              { dataInsights.annualizedRebates > 0 &&
                <GridCol className='text-center insights-text-annualized'> (${annualizedRebates} annualized)</GridCol>
              }
            </GridRow>
          </>
        }
        { showDeviations && 
          <>
            <GridRow>
              <GridCol className='pt-3 text-center'>
                Deviation Savings &nbsp;
                <span className='clickable' data-toggle='tooltInsightsTotalRebates' title='This is an estimate of your cumulative deviation savings. The calculation is done by comparing your pre-contract price to your price while on our contract. An estimate is used when a pre-contract price is not available. Please note it is the responsibility of your distributor to load deviated pricing available to them.'>
                  <IconInformation color={iconInformationColor} size={15} />
                </span> 
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol className='py-3 text-center text-size-38 line-height-58'>
                ${totalDeviations}
              </GridCol>
            </GridRow>
            <GridRow className='pb-3 insights-line-bottom'>
              { dataInsights.annualizedDeviations > 0 &&
                <GridCol className='text-center insights-text-annualized'> (${annualizedDeviations} annualized)</GridCol>
              }
            </GridRow>
          </>
        }
      </>
      );
    }
    return (
      <GridCol className='py-3 insights-line-right'>
        <GridRow>
          <GridCol className='pl-5 pb-3 text-left insights-graph-title insights-line-bottom'>
            Overall Stats
          </GridCol>
        </GridRow>
        <GridRow className='pl-5 pt-5 overall-stats-figures'>
          <GridCol className='text-grey pb-2'>
            Total Processed Purchases &nbsp;
            <span className='clickable' data-toggle='tooltInsightsTotalPurchases' data-placement='top' title='This is your total purchase volume that we have processed so far. Please note that there can be delays and it takes us time to process all of the purchases.'>
              <IconInformation color={iconInformationColor} size={15} />
            </span>
          </GridCol>
          <GridCol className='text-grey pb-2'>
          { showOpportunity && 
            <>
              Potential Rebates for Opportunities &nbsp;
              <span className='clickable' data-toggle='tooltInsightsTotalPurchases' data-placement='top' title='This is an ESTIMATE of the potential rebates opportunies you could earn. Please note that there can be delays and it takes us time to process all of the purchases.'>
                <IconInformation color={iconInformationColor} size={15} />
              </span>
            </>
          }
          </GridCol>
        </GridRow>
        <GridRow className='pl-5 overall-stats-figures'>
          <GridCol className='text-size-36 line-height-50'>
            ${totalAmount}
          </GridCol>
          <GridCol className='text-size-36 line-height-50'>
            { showOpportunity ? '$' +  potentialOpportunity  : null }
          </GridCol>
        </GridRow>
        {
          showRebates &&
          <>
            <GridRow className='pl-5 pt-5 overall-stats-figures'>
              <GridCol className='text-grey pb-2'>
                Estimated Accrued Rebates &nbsp;
                <span className='clickable' data-toggle='tooltInsightsTotalRebates' title='This is an ESTIMATE of your total accrued rebates for the time period you selected. This number may include payments that have already been made to you. The final payment amounts are subject to change and may differ from this number based on manufacturer contracts changes.'>
                  <IconInformation color={iconInformationColor} size={15} />
                </span>
              </GridCol>
              <GridCol className='text-grey pb-2'>
                { showDeviations && 
                  <>
                    Deviation Savings &nbsp;
                    <span className='clickable' data-toggle='tooltInsightsTotalRebates' title='This is an estimate of your cumulative deviation savings. The calculation is done by comparing your pre-contract price to your price while on our contract. An estimate is used when a pre-contract price is not available. Please note it is the responsibility of your distributor to load deviated pricing available to them.'>
                      <IconInformation color={iconInformationColor} size={15} />
                    </span>
                  </>
                }
              </GridCol>
            </GridRow>
            <GridRow className='pl-5 pb-5 overall-stats-figures'>
              <GridCol className='text-size-36 line-height-50'>
                ${ totalRebates }&nbsp;
                { dataInsights.annualizedRebates > 0 &&
                  <GridCol className='px-0'>
                    <span className='insights-text-annualized'> (${annualizedRebates} annualized)</span>
                  </GridCol>
                }
              </GridCol>
              <GridCol className='text-size-36 line-height-50'>
                { showDeviations ? '$' + totalDeviations : null }
                { (showDeviations && dataInsights.annualizedDeviations > 0) &&
                  <GridCol className='px-0'>
                    <span className='insights-text-annualized'> (${annualizedDeviations} annualized)</span>
                  </GridCol>
                }
              </GridCol>
            </GridRow>
          </>
        }
      </GridCol>
    );
  }
}

const mapStateToProps = state => {
  return {
    companyId: state.company.id,
    userCurrent: state.location.userCurrent
  };
}

export default connect(mapStateToProps)(InsightsBodyOverallStats);