import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconSettings = props => (
  <svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 16 13" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Intel" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="2---Item-Intel" transform="translate(-267.000000, -112.000000)" fill="#FFFFFF" fillRule="nonzero">
            <g id="button" transform="translate(245.000000, 98.000000)">
                <g id="icons/settings" transform="translate(22.000000, 14.000000)">
                    <path d="M0,10.5714286 L10.1196429,10.5714286 C10.3405714,10.0674286 10.8439643,9.71428571 11.4285714,9.71428571 C12.0131786,9.71428571 12.5166071,10.0674286 12.7375,10.5714286 L16,10.5714286 L16,11.7142857 L12.7375,11.7142857 C12.5165714,12.2182857 12.0131786,12.5714286 11.4285714,12.5714286 C10.8439643,12.5714286 10.3405357,12.2182857 10.1196429,11.7142857 L0,11.7142857" id="Path"></path>
                    <path d="M0,5.71428571 L3.2625,5.71428571 C3.48342857,5.21028571 3.98682143,4.85714286 4.57142857,4.85714286 C5.15603571,4.85714286 5.65946429,5.21028571 5.88035714,5.71428571 L16,5.71428571 L16,6.85714286 L5.88035714,6.85714286 C5.65942857,7.36114286 5.15603571,7.71428571 4.57142857,7.71428571 C3.98682143,7.71428571 3.48339286,7.36114286 3.2625,6.85714286 L0,6.85714286" id="Path"></path>
                    <path d="M0,0.857142857 L10.1196429,0.857142857 C10.3405714,0.353142857 10.8439643,0 11.4285714,0 C12.0131786,0 12.5166071,0.353142857 12.7375,0.857142857 L16,0.857142857 L16,2 L12.7375,2 C12.5165714,2.504 12.0131786,2.85714286 11.4285714,2.85714286 C10.8439643,2.85714286 10.3405357,2.504 10.1196429,2 L0,2" id="Path"></path>
                </g>
            </g>
        </g>
    </g>
  </svg>
);

IconSettings.propTypes = {
  color: PropTypes.string,
  activeColor: PropTypes.string,
  isActive: PropTypes.bool,
  size: PropTypes.number,
};

IconSettings.defaultProps = {
  activeColor: config.colors.rbYellow,
  color: config.colors.rbWhite,
  isActive: false,
  size: 16,
};

export default IconSettings;