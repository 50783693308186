import React from 'react';
import { Button, Col, Form, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import Modal from '../../../../MasterComponents/Modals/Modal';
import ModalBody from '../../../../MasterComponents/Modals/ModalBody';
import ModalHeader from '../../../../MasterComponents/Modals/ModalHeader';
import useAddNewContractModal from './useAddNewContractModal';
import DatePicker from 'react-datepicker';
import { IoMdCalendar } from 'react-icons/io';
import AddNewContractModalCheckbox from './AddNewContractModalCheckbox';
import ModalFooter from '../../../../MasterComponents/Modals/ModalFooter';
import UploadContract from './UploadContract';
import DropDownListSelectCustom from '../../../../MasterComponents/DropDownList/DropDownListSelectCustom';

const AddNewContractModal = ({isUpdate, contract, modals, getContracts}) => {
	const { 
		labels, manufacturerName, startDate, endDate, calendarIcon,
		iDontKnow, automaticRenewal, clientContactEmail, saveButton,
		onSelectFile, file, contractText, clientContactName, iAgree
	} = useAddNewContractModal(getContracts, isUpdate, contract, modals);

	return (
		<Modal size='md' id='addNewContractModal'>
			<ModalHeader>{isUpdate ? 'Update' : 'Add New'} Contract</ModalHeader>
			<ModalBody>
				<Form>
					<FormGroup>
						<Label {...labels}> Manufacturer Name * </Label>
						<DropDownListSelectCustom {...manufacturerName} />
						<Row>
							<Col>
								<Label {...labels}> Start Date * </Label>
							</Col>
							<Col>
								{
									!automaticRenewal.checked &&
									<Label {...labels}> End Date * </Label>
								}
							</Col>
						</Row>
						<Row>
							<Col>
								<DatePicker {...startDate} />
								<IoMdCalendar   {...calendarIcon} />
							</Col>
							<Col>
								{
									!automaticRenewal.checked &&
									<>
										<DatePicker {...endDate} />
										<IoMdCalendar {...calendarIcon} />
									</>
								}
							</Col>
						</Row>
						{
							!isUpdate &&
							<AddNewContractModalCheckbox {...iDontKnow} />
						}
						<AddNewContractModalCheckbox {...automaticRenewal} />
						<Label {...labels}> Client Contact Name * </Label>
						<Input {...clientContactName} />
						<Label {...labels}> Client Contact * </Label>
						<Input {...clientContactEmail} />
						<UploadContract onSelectFile={onSelectFile} file={file} />
						{
							(!automaticRenewal.checked && endDate.selected && manufacturerName.value) &&
							<>
								<FormText color='black' className='mt-3'> {contractText} </FormText>
								<AddNewContractModalCheckbox {...iAgree} />
							</>
						}
					</FormGroup>
				</Form>
			</ModalBody>
			<ModalFooter justify='start'>
				<Button {...saveButton}>
					{saveButton.text}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default AddNewContractModal;
