import React, { Component } from 'react';
import { findModal } from '../../../MasterComponents/Modals/ModalsActions';
import Modal from '../../../MasterComponents/Modals/Modal';
import ModalHeader from '../../../MasterComponents/Modals/ModalHeader';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import Button from '../../../MasterComponents/Button/Button';

class LocationMangeModalDelete extends Component {

  yes = () => {
    const { acceptDelete, id, modals } = this.props;
    acceptDelete(id);
    findModal(modals, 'idLocationMangeModalDelete').close();
  }
  cancel = () => {
    const { modals } = this.props;
    findModal(modals, 'idLocationMangeModalDelete').close();
  }

  render() {
    return (
      <Modal id='idLocationMangeModalDelete' centered>
        <ModalHeader noBorder>
        </ModalHeader>
        <ModalBody>
          <GridRow className='pb-4'>
            <GridCol className='text-center text-size-20 line-height-20'>
              Are you sure you want to delete this location?
            </GridCol>
          </GridRow>
          <GridRow>
            <GridCol>
              <Button onClick={this.cancel} variant='white' text='Cancel' />
            </GridCol>
            <GridCol>
              <Button onClick={this.yes} variant='primary' text='Delete' />
            </GridCol>
          </GridRow>
        </ModalBody>
      </Modal>
    )
  }
}

export default LocationMangeModalDelete;
