import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';

class ModalBody extends Component {
  render() {
    const props = this.props;
    const realTimeHeight = {
      maxHeight: (props.vh - 70 - props.subtract)+'px'
    };
    return (
      <div className={'modal-body rb-modal-body' + (props.className ? ` ${props.className}` : '') + (isMobile ? ' mobile' : '')} style={ isMobile ? realTimeHeight : null }>
        {props.children}
      </div>
    );
  }
}

ModalBody.propTypes = {
  className: PropTypes.string,
  subtract: PropTypes.number
};

ModalBody.defaultProps = {
  className: '',
  subtract: 0
};

const mapStateToProps = state => {
  return {
    vh: state.resizeListener.height
  };
}

export default connect(mapStateToProps)(ModalBody);