import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { Row, Col, CardBody } from 'reactstrap';
import { showModal } from '../../../../MasterComponents/Modals/ModalsActions';
import { spinner } from '../../../../MasterComponents/Spinner/SpinnerActions';
import getDistributorBP, { programDataUnAuthDefault } from '../../../PrimePrograms/PrimeProgramsData';
import Button from '../../../../MasterComponents/Button/Button';
import LearnMoreModal from '../../../PrimePrograms/Modals/LearnMoreModal';
import SignUpModal from '../../../PrimePrograms/Modals/SignUpModal';
import SignUpApi from '../../SignUpApi';
import config from '../../../../config/config';
class FormDefaultBeyondPrime extends Component {
  constructor(props){
    super(props);
    this.getDistributorBP = getDistributorBP.bind(this);
    this.state = {
      successResponse: props.successResponse,
      activeMerchant: props.successResponse ? props.successResponse.activeMerchantId : 0,
      token: props.successResponse ? props.successResponse.authKey : ''
    }
  }

  componentDidMount = () => {
    const { companyId } = this.props;
    const hasAds = false;
    const hasAuth = false;
    this.getDistributorBP(companyId, hasAds, hasAuth);
  };

  onLearnMore = (bp) => {
    const { dispatch } = this.props;
    if(bp.pdf !==''){
      dispatch(showModal(LearnMoreModal, 'idLearnMoreModal', { pdf : bp.pdf }));
    }
  }

  onSignUp = (bp) => {
    const { activeMerchant } = this.state;
    if(bp.id !== '') {
      if(activeMerchant) {
        this.addDistributorBP(activeMerchant, bp.id);
      }
    }
  }

  goToUrl = (bp) => {
    if(bp.url !==''){
      window.open(bp.url, '_blank');
    }
  }

  addDistributorBP = (merchantId, distId)=> {
    const { token } = this.state;
    const { dispatch } = this.props;
    const params = {
      distId
    }
    dispatch(spinner(true));
    SignUpApi.addDistributorBP(merchantId, token, params).then((res)=> {
      dispatch(spinner(false));
      dispatch(showModal(SignUpModal, 'idSignUpModal'));
    }).catch(function() {
      dispatch(spinner(false));
    });
  }

  render() {
    const { companyId, primeAllUpdated } = this.props;
    const buttonTextLeft = (companyId === 3 || companyId === 13 ) ? 'Details' : 'Learn More';
    const buttonTextRight = (companyId === 3 || companyId === 13 ) ? 'Learn More' : 'Sign Up';
    let primeArr = primeAllUpdated.slice(0, 4);

    return (
      <>
        <Row className='py-3'>
          <Col className='text-size-24 line-height-28'>
            Join our Beyond Broadline Programs
          </Col>
        </Row>
        <Row className='px-3'>
          { primeArr.map((p, i) => {
            const hoverTitle = p.hoverTitle ? p.hoverTitle: '';
            const hoverBody = p.hoverBody ? p.hoverBody : '';
            const hoverStyleBody = p.card ? p.card.cardHover.styleBody : programDataUnAuthDefault[0]['cardHover']['styleBody'];
            const mainStyleBody = p.card ? p.card.cardMain.styleBody : programDataUnAuthDefault[0]['cardMain']['styleBody'];
            const hoverStyleTitle = p.card ? p.card.cardHover.styleTitle : programDataUnAuthDefault[0]['cardHover']['styleTitle'];
            const hoverStyleLogo = p.card ? p.card.cardHover.styleLogo : programDataUnAuthDefault[0]['cardHover']['styleLogo'];
            const mainHeader = p.mainHeader ? p.mainHeader : '';
            const mainFooter = p.mainFooter ? p.mainFooter : '';
            const mainTitle = p.mainTitle ? p.mainTitle : ''
             return (
            <Col key={`prime_${i}`} xs={ isMobile ? 12 : 6} className={'bounds px-0 ' + (companyId === 3 ? 'bounds-cc' : '')} style={{lineHeight:'50px'}}>
              <Col style={{backgroundColor: companyId === 3 ? config.colors.secondary : config.colors.primary }} className='px-0'>
                <CardBody style={{height: isMobile ? '150px' : '320px'}}>
                <Col className='text-white text-left text-uppercase px-0' style={{zIndex:1, top: '-15px'}}>{mainHeader}</Col>
                <Col className={'prime-card-main-footer text-right ' + (companyId === 3 ? 'text-primary' : 'text-yellow text-uppercase font-italic')} style={{zIndex:1}}>{isMobile ? '' : mainFooter}</Col>
                  {companyId !== 3 && <div className= 'corner-cut'></div>}
                  <img src={p.logo} alt='Partner Logo' style={isMobile ? hoverStyleLogo : mainStyleBody} />
                </CardBody>
              </Col>
              { isMobile &&
                <>
                  <Col xs={12} className={'text-uppercase text-white '} style={hoverStyleTitle}>{mainTitle}</Col>
                  <Col xs={12} className='text-white line-height-24 ' onClick={()=> this.goToUrl(p)} style={Object.assign({}, hoverStyleBody, {height: '300px'})} >{hoverBody}</Col>
                  <Col>
                    <Row style={{position:'absolute', bottom: '10px'}} className='w-100'>
                      <Col>
                        <Button type='submit' className='btn-prime-learn-mobile' variant={'primary'} onClick={()=> this.onLearnMore(p)} text={buttonTextLeft} />
                      </Col>
                      <Col>
                        <Button type='submit' className='btn-prime-signup-mobile' variant={'white'} onClick={()=> this.onSignUp(p)} text={buttonTextRight} />
                      </Col>
                    </Row>
                  </Col>
                </>
              }
              { !isMobile && 
                <Col style={{zIndex:2}} className={'overlay-prime ' + (companyId === 3 ? 'bg-primary ' : 'bg-yellow')}>
                  <Row>
                    <Col xs={12}>
                      <img src={p.logo} alt='Partner Logo' style={hoverStyleLogo} />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className={'prime-card-hover-header text-uppercase mt-3 ' + (companyId === 3 ? 'text-white' : '')} style={hoverStyleTitle}>{hoverTitle}</Col>
                    <Col xs={12} className='text-prime clickable'  onClick={()=> this.goToUrl(p)}  style={hoverStyleBody}>{hoverBody}</Col>
                  </Row>
                  <Row style={{position:'absolute', bottom: '10px'}} className='w-100'>
                    <Col>
                      <Button type='submit' className='btn-prime-learn' variant={companyId === 3 ? 'primary' : 'yellow'} onClick={()=> this.onLearnMore(p)} text={buttonTextLeft} />
                    </Col>
                    <Col>
                      <Button type='submit' className='btn-prime-signup' variant={companyId === 3 ? 'white' : 'white-secondary'} onClick={()=> this.onSignUp(p)} text={buttonTextRight} />
                    </Col>
                  </Row>
                </Col>
              }
            </Col>
            )})
          }
        </Row>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    companyId: state.company.id,
    primeAllUpdated: state.prime.primeAllUpdated
  };
}

export default connect(mapStateToProps)(FormDefaultBeyondPrime);
