import React, { Component } from 'react';
import { IoIosPlayCircle } from "react-icons/io";
import GridCol from '../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import moment from 'moment';
import config from '../../../config/config';
import IconEdit from '../../../MasterComponents/Icons/IconEdit';
import IconFolder from '../../../MasterComponents/Icons/IconFolder';
import IconSuccess from '../../../MasterComponents/Icons/IconSuccess';
import IconProcessing from '../../../MasterComponents/Icons/IconProcessing';
import IconAlert from '../../../MasterComponents/Icons/IconAlert';
import IconDelete from '../../../MasterComponents/Icons/IconDelete';
class DistributorListRow extends Component {
  render() {
    const { onClickActivate, clickActivateEdit, clickActivateDelete, onClickUploadNow } = this.props;
    const md = this.props.merchantDistributor;
    const distributor = this.props.merchantDistributor.distributor;
    const lastLoadDate = moment(md.lastLoadDate).isValid() ? moment(md.lastLoadDate).format('M-DD-YY') : (!md.lastLoadDate ? '-' : md.lastLoadDate);
    const nextLoadDate = moment(md.nextLoadDate).isValid() ? moment(md.nextLoadDate).format('M-DD-YY') : (!md.nextLoadDate ? '-' : md.nextLoadDate);
    const submissionType =  md.submissionType === 'manual' ? 'Manual Upload' : (md.submissionType === 'auto' ? 'Auto' : 'Online Ordering');
    const isNotActiveButton = md.isActive === 0 && md.submissionType !== 'auto' ? true : false;
    const statusOnlineColor =  md.statusType === 'success' ? config.colors.rbGreen : (md.statusType === 'processing' ? config.colors.rbYellowLight : config.colors.rbRedLight);
    const statusEditColor =  md.statusType === 'failedDcn' ? config.colors.rbRedLight : config.colors.rbDarkerGrey;
    const statusOnlineIcon = md.statusType === 'success' ? <IconSuccess size={20} color={config.colors.rbGreen} /> : (md.statusType === 'processing' ? <IconProcessing size={20} color={config.colors.rbYellowLight} /> : <IconAlert size={20} color={config.colors.rbRedLight} />);
    
    return (
      <GridRow className='p-3 border-top text-size-14 line-height-16'>
        <GridCol  size={2}>
          {md.distributor}
        </GridCol>
        <GridCol  size={1}>
          {md.dcn}
        </GridCol>
        <GridCol  size={1}>
          {submissionType}
        </GridCol>
        <GridCol  size={2}>
          { md.submissionType === 'manual' || md.submissionType === 'auto'  ? nextLoadDate : '' }
        </GridCol>
        <GridCol  size={2}>
        { md.submissionType === 'manual' || md.submissionType === 'auto' ? lastLoadDate : '' }
        </GridCol>
        <GridCol size={4} className='text-right'>
          <GridRow className='px-0'>
            { isNotActiveButton && 
              <>
                <GridCol size={6} className='text-left pt-2 text-blue text-size-13'>
                  <IoIosPlayCircle color={config.colors.primary} size={20} /> &nbsp; Needs Activation
                </GridCol>
                <GridCol size={4}>
                  <button type='button' onClick={onClickActivate} className='btn btn-primary active btn-sm shadow-none' style={{width:'90px'}}>CONNECT</button>
                </GridCol>
                <GridCol size={1} onClick={clickActivateEdit} className='text-right pt-2 clickable'>
                  <IconEdit color={statusEditColor} />
                </GridCol>
                <GridCol size={1} onClick={clickActivateDelete} className='text-right pt-2 clickable'>
                  <IconDelete color={config.colors.rbDarkerGrey} />
                </GridCol>
              </>
            }
            { md.submissionType === 'online' ?
              <GridCol onClick={clickActivateEdit} className='text-left clickable text-size-13' style={{color: statusOnlineColor}}>
                { statusOnlineIcon } &nbsp; { (md.statusMessage && md.statusMessage === 'Processing...') ? 'Pending...' : md.statusMessage }
              </GridCol>
              :
              <GridCol/>
            }
            { md.submissionType === 'manual' && 
              <GridCol onClick={onClickUploadNow} className='text-right mw-fc clickable'>
                <IconFolder color={statusEditColor} />
              </GridCol>
            }
            { ((md.submissionType === 'online' || md.submissionType !== 'online') && !isNotActiveButton) &&
              <GridCol onClick={clickActivateEdit} className='text-right mw-fc clickable'>
                <IconEdit color={statusEditColor} />
              </GridCol>
            }
            { !isNotActiveButton && 
              <GridCol onClick={clickActivateDelete} className='mw-fc px-1'>
                <IconDelete color={config.colors.rbDarkerGrey} />
              </GridCol>
            }
          </GridRow>
        </GridCol>
      </GridRow>
    )
  }
}

export default DistributorListRow;
