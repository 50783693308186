import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { FaLongArrowAltRight } from 'react-icons/fa';
import logosImage from '../../../assets/images/beyond-prime.png';

const JoinSavings = ({scrollTop}) => {
	return (
		<Container fluid className='bg-action pt-5 pb-5'>
			<Container className='pt-4 pb-5 mw-1140'>
				<Row className='text-white align-items-stretch'>
					<Col md={12} lg={6}>
						<Row>
							<Col className='text-title'>
								ADD FOOD/NON-FOOD SAVINGS PROGRAMS & SOLUTIONS AT NO COST
							</Col>
						</Row>
						<Row className='mt-4'>
							<Col>
								Our Beyond Broadline offer a variety of ways to save money on all non-food aspects of your restaurant, including office supplies, pest control, recycling, credit card processing, cash handling services and more. Members receive preferred pricing on a wide variety of restaurant services. 
							</Col>
						</Row>
						<Row className='mt-4'>
							<Col>
								Included in our Beyond Broadline are our employee perk programs, which are designed to save money for your teams and position you as a restaurant employer of choice. Top brands like ChefWorks, Shoes for Crews and Skechers offers shoes and apparel to your staff at discounted prices and our cell phone partners, Verizon offer employer group discounts to Dining Alliance members. Additionally, our restaurant technology programs help our members update their systems and processes with discounts on essential restaurant technology applications.
							</Col>
						</Row>
						<Row className='py-5'>
							<Col>
								<Button color='warning' onClick={scrollTop}>
									ACTIVATE DISCOUNTS NOW <FaLongArrowAltRight className='ml-2' />
								</Button>
							</Col>
						</Row>
					</Col>
					<Col className='align-items-center d-flex'>
						<img src={logosImage} alt='Beyond Prime' className='mw-100' />
					</Col>
				</Row>
			</Container>
		</Container>
	)
};

export default JoinSavings;