import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import GridRow from '../../MasterComponents/Grid/GridRow';
import GridCol from '../../MasterComponents/Grid/GridCol';
import Input from '../../MasterComponents/Input/Input';
import InputLabel from '../../MasterComponents/InputLabel/InputLabel';
import Button from '../../MasterComponents/Button/Button';
import { isTextValid } from '../../MasterComponents/Input/InputValidate';
import DashboardApi from './DashboardApi';
import { spinner } from '../../MasterComponents/Spinner/SpinnerActions';
import PropTypes from 'prop-types';
import { showModal, findModal } from '../../MasterComponents/Modals/ModalsActions';
import ConfirmationUserModal from './Modals/ConfirmationUserModal';
class DashboardSettingsChangePassword extends Component {
    constructor(props) {
      super(props);    
      this.state = {
        currentPassword: '',
        newPassword: '',
        repeatPassword: ''
      }
    }

    changePasswordInputs = (value, name, e) => {
        this.setState({
          [name]: value
        });
    }

    allowSave = () => {
        const { currentPassword, newPassword, repeatPassword} = this.state;
        let message = '';
        if (isTextValid(currentPassword, 5, 50) && isTextValid(newPassword, 5, 50) && isTextValid(repeatPassword, 5, 50)) {
            if (newPassword !== repeatPassword) {    
                message = 'New and confirm password must be the same';
            }        
            if (currentPassword === newPassword) {
                message = 'Current and new password must be different';
            }
        }else{
            message = 'Password length not valid';
        }
        return message;
    }
    
    save = () => {
        const { dispatch, modals } = this.props;
        let message = this.allowSave();
        if (message !== ''){        
            dispatch(showModal(ConfirmationUserModal, 'confirmationUserModal', {header:'Error', message: message, icon: false}));
            return;
        }
        const { currentPassword, newPassword, repeatPassword} = this.state;
        
        dispatch(spinner(false));
        DashboardApi.changeUserPassword(currentPassword,newPassword,repeatPassword).then(({ data }) => {        
            dispatch(spinner(false));
            let result = data.data;            
            this.setState({
                currentPassword: '',
                newPassword: '',
                repeatPassword: ''
            }, () => {
                if (isMobile){ findModal(modals, 'manageUserModal').close(); }                
                dispatch(showModal(ConfirmationUserModal, 'confirmationUserModal', {header:'Confirmation', message: result.message}));
            });
        })
        .catch(error => {
            dispatch(spinner(false));
            if (isMobile){ findModal(modals, 'manageUserModal').close(); }
            if (error.response) {
                let errorMessage = error.response.data.data.message;
                dispatch(showModal(ConfirmationUserModal, 'confirmationUserModal', {header:'Confirmation', message: errorMessage, icon: false}));
            }
        });
        
    }
    render() {
        let {currentPassword, newPassword, repeatPassword} = this.state
        return (            
            <Fragment>
                <GridRow className='line-form mt-3'>
                    <GridCol className={'col'+(isMobile ? '': '-4')+' form-group'}>
                        <InputLabel text='Current Password'/>
                        <Input ref="currentPassword" type='password' name="currentPassword" placeholder='Current Password' value={currentPassword} onChange={this.changePasswordInputs} validate min={5} max={50}/>
                    </GridCol>                    
                </GridRow>
                <GridRow className='line-form'>
                    <GridCol className={'col'+(isMobile ? '': '-4')+' form-group'}>
                        <InputLabel text='New Password'/>
                        <Input ref="newPassword" type='password' name="newPassword" placeholder='New Password' value={newPassword} onChange={this.changePasswordInputs} validate min={5} max={50}/>
                    </GridCol>                    
                </GridRow>
                <GridRow className='line-form'>
                    <GridCol className={'col'+(isMobile ? '': '-4')+' form-group'}>
                        <InputLabel text='Confirm Password'/>
                        <Input ref="repeatPassword" type='password' name="repeatPassword" placeholder='Confirm Password' value={repeatPassword} onChange={this.changePasswordInputs} validate min={5} max={50}/>
                    </GridCol>                    
                </GridRow>
                <GridRow className='line-form mt-3'>
                    <GridCol className={(isMobile ?'':'col-4') + ' form-group'}>
                        <Button type='button' variant='primary' size='' text='Save'  onClick={this.save} />
                    </GridCol>
                </GridRow>
            </Fragment>
          );
    }
}

DashboardSettingsChangePassword.propTypes = {
    currentPassword: PropTypes.string,
    newPassword: PropTypes.string,
    repeatPassword: PropTypes.string
};

const mapStateToProps = state => {
    return {
      section: state.dashboard.section,
      users: state.dashboard.users,
      locations: state.dashboard.merchants
    };
  }
  
export default connect(mapStateToProps)(DashboardSettingsChangePassword);
