import { apiInstance } from '../../config/tokenApi';

const getDistributorBPAuth = (id) => {
    return apiInstance.get(`/distributor/bp-list/${id}`);
};

const addDistributorBP = (id, params) => {
    return apiInstance.post(`/merchant/${id}/bp-distributor`, params);
}

const getManufacturerAd = (companyId) => {
    return apiInstance.get(`/manufacturer-ad/${companyId}`);
}
  
const saveManufacturerAd = (params) => {
    const url = process.env.REACT_APP_INSIDETRACK_API;
    const clientPortal = process.env.REACT_APP_COMPANY_BRAND_NAME;
    const clientSecret = process.env.REACT_APP_INSIDETRACK_CLIENT_SECRET;
    const link = '/b2b/v1/picture/report';
    return apiInstance.post(`${link}`, params, {
        baseURL: url,
        headers: {
            'Content-Type': 'application/json',
            'client-id': clientPortal,
            'client-secret': clientSecret,
            'Authorization': null
        }
    });
}

export default {
    getDistributorBPAuth,
    addDistributorBP,
    getManufacturerAd,
    saveManufacturerAd
};
