export const changePage = (section) => {
  return {
    type: 'CHANGE_SECTION',
    payload: section
  }
};

export const saveMerchants = (list) => {
  return {
    type: 'UPDATE_MERCHANTS',
    payload: list
  }
};

export const saveActiveMerchant = (merchant) => {
  return {
    type: 'UPDATE_ACTIVE_MERCHANT',
    payload: merchant
  };
};

export const orderlyStart = () => {
  return {
    type: 'ORDERLY_START',
  };
};

export const saveUsers = (list) => {
  return {
    type: 'UPDATE_USERS',
    payload: list
  }
};

export const setEnableInsightsModal = (bool) => {
  return {
    type: 'SET_ENABLE_INSIGHTS_MODAL',
    payload: bool
  }
};

export const setSettingsOpen = (bool) => {
  return {
    type: 'SET_SETTINGS_OPEN',
    payload: bool
  }
};

export const setDisplayBrowserExtension = (displayBrowserExtension) => {
  return {
    type: 'UPDATE_DISPLAY_BROWSER_EXTENSION',
    payload: displayBrowserExtension
  }
}
