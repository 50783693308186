import React from 'react';
import { isMobile } from 'react-device-detect';
import config from '../../../../config/config';
import DropDownListSelect from '../../../../MasterComponents/DropDownList/DropDownListSelect';
import GridCol from '../../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../../MasterComponents/Grid/GridRow';
import IconArrowForward from '../../../../MasterComponents/Icons/IconArrowForward';
import InputLabel from '../../../../MasterComponents/InputLabel/InputLabel';
import ModalBody from '../../../../MasterComponents/Modals/ModalBody';

const DistributorStepAddNewModalDistributors = ({dropdown, search, locationsMap, selectDistributor, dropdownHouse, locationsInvalid}) => {
  if (isMobile) {
    return (
      <ModalBody className={isMobile ? 'h-100 pt-0' : ''}>
        {
          (search.value.length === 0 && search.displayPopularDistributors) &&
          <GridRow  className='pb-3 border-bottom'>
            <GridCol>
              Or choose from these popular distributors
            </GridCol>
          </GridRow>
        }
        {
          search.options.map(dist => (
            <GridRow onClick={() => selectDistributor(dist)} key={`dist_search_${dist.id}`} className='align-items-center py-3 border-bottom'>
              <GridCol>
                {dist.label}
              </GridCol>
              <GridCol style={{maxWidth: 'fit-content'}}>
                <IconArrowForward color={config.colors.rbGrey} />
              </GridCol>
            </GridRow>
          ))
        }
      </ModalBody>
    )
  }

  return (
    <ModalBody className={isMobile ? 'h-100 pt-0' : ''}>
      <GridRow className='pb-3'>
        <GridCol>
          <InputLabel text='Distributor Name *' />
          <DropDownListSelect {...dropdown} />
        </GridCol>
      </GridRow>
      <GridRow>
        <GridCol>
          <InputLabel text='Distributor House *' />
          <DropDownListSelect {...dropdownHouse} />
          <InputLabel text='Correct house is mandatory to earn rebates' />
        </GridCol>
      </GridRow>
      <GridRow className='my-3'>
        <GridCol className={'font-weight-bold' + (locationsInvalid ? ' text-red' : '')}>
          Add this distributor to:
        </GridCol>
      </GridRow>
      {locationsMap}
    </ModalBody>
  );
};

export default DistributorStepAddNewModalDistributors;