export default function reducer(state = {
  primeAll: [],
  primeAllUpdated: []
}, action) {
  switch (action.type) {
    case 'PRIME_SET_DATA':
      return {
        ...state,
        primeAll: action.payload
      };
    case 'PRIME_SET_DATA_UPDATED':
      return {
        ...state,
        primeAllUpdated: action.payload
      };
    default:
      return state;
  }
}