import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconSubtract = props => (
  <svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 15 3" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="FAQ" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="2---Question-1" transform="translate(-953.000000, -164.000000)" fill={!props.isActive ? props.color : props.activeColor}>
        <g id="quesion" transform="translate(50.000000, 136.000000)">
          <g id="icons/collapse" transform="translate(903.000000, 28.000000)">
            <path d="M15,1.5 C15,2.03967391 14.561413,2.47826087 14.0217391,2.47826087 L8.47826087,2.47826087 L6.52173913,2.47826087 L0.97826087,2.47826087 C0.436956522,2.47826087 0,2.03967391 0,1.5 C0,0.960326087 0.438586957,0.52173913 0.97826087,0.52173913 L6.52173913,0.52173913 L8.47826087,0.52173913 L14.0217391,0.52173913 C14.5630435,0.52173913 15,0.960326087 15,1.5 Z" id="Shape"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

IconSubtract.propTypes = {
  color: PropTypes.string,
  activeColor: PropTypes.string,
  isActive: PropTypes.bool,
  size: PropTypes.number
};

IconSubtract.defaultProps = {
  activeColor: config.colors.rbYellow,
  color: config.colors.rbWhite,
  isActive: false,
  size: 16
};

export default IconSubtract;