import React, { Component } from 'react'
import Button from '../../../MasterComponents/Button/Button'
import GridCol from '../../../MasterComponents/Grid/GridCol'
import GridRow from '../../../MasterComponents/Grid/GridRow'
import Modal from '../../../MasterComponents/Modals/Modal'
import ModalBody from '../../../MasterComponents/Modals/ModalBody'
import ModalFooter from '../../../MasterComponents/Modals/ModalFooter'
import { findModal } from '../../../MasterComponents/Modals/ModalsActions'

class LOTAcknowledgementModal extends Component {
    closeModal = () => {
        const { modals } = this.props;
        this.props.acknowledgeLOTTerms(false);
        findModal(modals, 'LOTAcknowledgementModal').close();
    }

    acknowledgeLOTTerms = () => {
        const { modals } = this.props;
        this.props.acknowledgeLOTTerms(true);
        findModal(modals, 'LOTAcknowledgementModal').close();
    }

    render() {
        const { message } = this.props;
        return (
            <Modal id='LOTAcknowledgementModal' centered>
                <ModalBody className='mt-3'>
                    <GridRow>
                        <GridCol>
                            {message}
                        </GridCol>
                    </GridRow>
                </ModalBody>
                <ModalFooter className='justify-content-between'>
                    <Button type='button' variant='white' onClick={this.closeModal} className='clickable' size='md' text='Cancel' style={{ width: '100px' }} />
                    <Button type='button' variant='primary' onClick={this.acknowledgeLOTTerms} className='clickable' size='md' text='I acknowledge' />
                </ModalFooter>
            </Modal>
        )
    }
}

export default LOTAcknowledgementModal
