import React from 'react';
import { isMobile } from 'react-device-detect';
import Button from '../../MasterComponents/Button/Button';
import GridCol from '../../MasterComponents/Grid/GridCol';
import GridRow from '../../MasterComponents/Grid/GridRow';
import Input from '../../MasterComponents/Input/Input';
import InputLabel from '../../MasterComponents/InputLabel/InputLabel';
import useCreatePassword from './hooks/useCreatePassword';

const ChangePasswordContent = props => {

  const { password, confirmPassword,
    onChangePassword, onChangeConfirmPassword, onNext
  } = useCreatePassword(props);

  return (
    <div className='right-change'>
      <div className='header-change'>
        {isMobile ? <InputLabel text='Login' title className='title' /> : null}
      </div>
      <form className='change-form'>
        <GridRow className='row'>
          <GridCol className='col-12'>
            <InputLabel text='Create a Password' title className='title' />
          </GridCol>
          <GridCol className='col-12 form-group mt-4'>
            <InputLabel text='Password' />
            <GridCol className='col p-0'>
              <Input type='password' value={password} name='password' onChange={onChangePassword} placeholder='********' className='login-text' />
            </GridCol>
          </GridCol>
          <GridCol className='col-12 form-group mt-4'>
            <InputLabel text='Confirm Password' />
            <GridCol className='col p-0'>
              <Input type='password' value={confirmPassword} name='confirmPassword' onChange={onChangeConfirmPassword} placeholder='********' className='login-text' />
            </GridCol>
          </GridCol>
          <GridCol className='col-12 form-group mt-5'>
            <GridCol className='col p-0'>
              <Button type='button' variant='primary' text='Next' onClick={onNext}></Button>
            </GridCol>
          </GridCol>
        </GridRow>
      </form>
      <div className='footer-reset'>
      </div>
    </div>
  )
}

export default ChangePasswordContent;