import { apiInstance } from '../../config/tokenApi';

const changePassword = (resetToken, newpass, confirmpass) => {
    
    return apiInstance.post('/auth/change-password', {        
        token: resetToken,
        password: newpass,
        password_repeat: confirmpass
    });
};

export default {
    changePassword: changePassword
};
