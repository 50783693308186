import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconArrowDown = props => (
<svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 20 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Designs" stroke="none" fill="none">
        <g id="1---Login" transform="translate(-944.000000, -370.000000)" fill={!props.isActive ? props.color : props.activeColor} fillRule="nonzero">
            <g id="form" transform="translate(580.000000, 140.000000)">
                <g id="input" transform="translate(0.000000, 189.000000)">
                    <g id="icons/eye-off" transform="translate(364.000000, 41.000000)">
                        <path d="M10,0 C6.34375,0 3.49553571,2.17857143 0,5.71428571 C3.00892857,8.73660714 5.53571429,11.4285714 10,11.4285714 C14.4598214,11.4285714 17.7410714,8.01785714 20,5.77678571 C17.6875,3.15178571 14.4107143,0 10,0 Z M10,9.79017857 C7.79464286,9.79017857 6,7.95982143 6,5.71428571 C6,3.46428571 7.79464286,1.63839286 10,1.63839286 C12.2053571,1.63839286 14,3.46875 14,5.71428571 C14,7.96428571 12.2053571,9.79017857 10,9.79017857 Z" id="Shape"></path>
                        <path d="M10,4.28571429 C10,3.93303571 10.1294643,3.61160714 10.3392857,3.36160714 C10.2276786,3.34375 10.1160714,3.33482143 10,3.33482143 C8.71428571,3.33482143 7.66517857,4.40178571 7.66517857,5.71428571 C7.66517857,7.02678571 8.71428571,8.09375 10,8.09375 C11.2857143,8.09375 12.3348214,7.02678571 12.3348214,5.71428571 C12.3348214,5.61160714 12.3258929,5.50892857 12.3169643,5.40625 C12.0714286,5.59821429 11.7678571,5.71428571 11.4330357,5.71428571 C10.6383929,5.71428571 10,5.07589286 10,4.28571429 Z" id="Path"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
);

IconArrowDown.propTypes = {
  color: PropTypes.string,
  activeColor: PropTypes.string,
  isActive: PropTypes.bool,
  size: PropTypes.number
};

IconArrowDown.defaultProps = {
  activeColor: config.colors.rbYellow,
  color: config.colors.rbWhite,
  isActive: false,
  size: 16
};

export default IconArrowDown;