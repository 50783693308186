import React, { Component, Fragment } from 'react';
import Modal from '../../../MasterComponents/Modals/Modal';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import { isMobile } from 'react-device-detect';
import ModalHeader from '../../../MasterComponents/Modals/ModalHeader';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class LearnMoreModal extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            numPages: 0,
            pageNumber: 1,
            arrayPages : []
        }
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
        this.getArrayPages(numPages)
    }
    
    getArrayPages = (numPages) => {
        const arrayValue = Array.from(Array(numPages).keys());
        arrayValue.shift();
        arrayValue.push(numPages);
        this.setState({ arrayPages : arrayValue });
    }

    render() {
       const props = this.props;
        return (
            <Modal  id='idLearnMoreModal' centered={isMobile}  size='xl'>
                <ModalHeader />
                <ModalBody>                    
                    {isMobile ?
                    <Fragment>
                        <Document file={props.pdf} loading={'loading...'} onLoadSuccess={this.onDocumentLoadSuccess} >
                            { this.state.arrayPages.map((page, i) => (
                                    <Page pageNumber={page} width={800} height={1000} key={i} />
                            ))}
                        </Document>
                    </Fragment>
                     :
                    <iframe title='pdf' src={props.pdf} style={{width: '100%', height: '500px'}}>
                        <p>It appears your web browser doesn't support iframes.</p>
                    </iframe>
                    }
                </ModalBody>
            </Modal>
        );
    }
}

export default LearnMoreModal;