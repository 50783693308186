import React, { Component } from 'react';
import GridRow from '../../MasterComponents/Grid/GridRow';
import GridCol from '../../MasterComponents/Grid/GridCol';
import LogoMainCompany from '../../MasterComponents/Logos/LogoMainCompany';

class HomeHeader extends Component {
  render() {
    return (        
      <GridRow className='home-header logo py-4 no-gutters'>
          <GridCol>
            <LogoMainCompany style={{maxHeight: '120px', maxWidth: '90%'}} />
          </GridCol>
      </GridRow>
    );
  }
};

export default HomeHeader;