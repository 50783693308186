import Joi from 'joi';
import { useEffect, useReducer, useState } from 'react';
import DashboardApi from '../../Dashboard/DashboardApi';
import LocationApi from '../../Location/LocationApi';
import { getStatesProvinces } from '../../../MasterComponents/Helper/getPlaces';

const reducer = (state, action) => {
  switch(action.type) {
    case 'KEY':
      return state.map((loc, i) => {
        if (i !== action.payload.index) return loc;

        if (action.payload.key === 'invalid') {
          return {
            ...loc,
            invalid: action.payload.value,
            addressDetails: action.payload.value.length > 0 ? true : loc.addressDetails
          };
        }

        if (action.payload.key === 'name' && action.payload.value.length === 0) {
          return {
            ...loc,
            name: '',
            street: '',
            city: '',
            state: null,
            zipcode: '',
            phoneNumber: '',
            invalid: [],
            addressDetails: false,
            isEmpty: true
          };
        }

        return {
          ...loc,
          [action.payload.key]: action.payload.value,
          isEmpty: false
        };
      });
    case 'RELOAD_LOCATIONS':
      return state.map((loc, i) => {
        if (action.payload[i]) {
          return {
            ...action.payload[i],
            zipcode: action.payload[i].zipcode ? action.payload[i].zipcode.toString() : '',
            isEmpty: false
          }
        }

        return {
          id: null,
          name: '',
          street: '',
          city: '',
          state: null,
          zipcode: '',
          phoneNumber: '',
          invalid: [],
          addressDetails: false,
          isEmpty: true
        };
      });
    case 'ADD_LOCATIONS':
      return [
        ...state,
        ...action.payload.map(loc => {
          return {
            ...loc,
            zipcode: loc.zipcode ? loc.zipcode.toString() : '',
            postcode: loc.postcode || '',
            isEmpty: false
          }
        })
      ];
    case 'ADD_EMPTY':
      let empties = [];
      for (let i = 0; i < action.payload; i++) {
        empties.push({
          id: null,
          name: '',
          street: '',
          city: '',
          state: null,
          zipcode: '',
          phoneNumber: '',
          invalid: [],
          addressDetails: false,
          isEmpty: true
        });
      }
      return [...state, ...empties];
    case 'FULL_ADDRESS':
      return state.map((loc, i) => {
        if (i !== action.payload.index) return loc;

        const {index, ...fields} = action.payload;
        return {
          ...loc,
          ...fields,
          zipcode: (fields.zipcode && fields.zipcode.toString()) || (loc.zipcode && loc.zipcode.toString()) || '',
          isEmpty: false
        };
      })
    default:
      return state;
  }
};

const SCHEMA = Joi.object({
  name: Joi.string().required().min(3),
  street: Joi.string().required().min(3),
  city: Joi.string().required().min(2),
  state: Joi.object().required().invalid(null),
  zipcode: Joi.string().required().pattern(/^[0-9]{5}?$/),
});

const SCHEMA_CA = Joi.object({
  name: Joi.string().required().min(3),
  street: Joi.string().required().min(3),
  city: Joi.string().required().min(2),
  state: Joi.object().required().invalid(null),
  zipcode: Joi.string().required().pattern(/[a-zA-Z0-9]{3}[ ][a-zA-Z0-9]{3}/),
});

const COUNTRY_SCHEMA = Joi.string().min(4).pattern(/^[0-9-]+$/).required();

const useLocations = ({history}) => {
  const [country, setCountry] = useState('us');
  const [locations, dispatchLocations] = useReducer(reducer, []);

  const getCountry = (postcode) => {
    if (postcode) {
      const isUs = COUNTRY_SCHEMA.validate(postcode.toString());
      return isUs.error ? 'ca' : 'us';
    } else {
      return 'us';
    }
  };

  useEffect(() => {
    LocationApi.getCurrentUser().then(({data}) => {
      if (data.data.status !== 1) {
        history.replace('/logout');
      } else {
        const count = data.data.reportedLocations;
        localStorage.setItem('email', data.data.email);
        DashboardApi.getMerchants().then(({data}) => {
          const result = data.data;
          const country = getCountry(result[0] && result[0].postcode);
          setCountry(country);
          const initLocations = result.map(l => {
            const stateObj = getStatesProvinces(country).filter(s => s.value === l.state)[0];
            return {
              id: l.id,
              name: l.nickname,
              street: l.address,
              city: l.city,
              state: stateObj ? {label: stateObj.label, value: l.state } : null,
              zipcode: l.postcode,
              postcode: l.postcode,
              phoneNumber: l.phoneNumber,
              invalid: [],
              addressDetails: false,
            }
          });
          dispatchLocations({type: 'ADD_LOCATIONS', payload: initLocations});
          dispatchLocations({type: 'ADD_EMPTY', payload: count - initLocations.length});
        }).catch(err => {
          console.error(err);
        });
      }
    }).catch(err => {
      console.error(err);
    });
    //eslint-disable-next-line
  }, []);

  const validate = () => {
    let oneIsInvalid = false;
    const SCHEMA_TO_USE = country === 'us' ? SCHEMA : SCHEMA_CA;
    locations.forEach((loc, i) => {
      const form = {
        name: loc.name,
        street: loc.street,
        city: loc.city,
        state: loc.state,
        zipcode: loc.zipcode
      };
      const validation = SCHEMA_TO_USE.validate(form, {abortEarly: false, errors: {wrap: {label: ''}}});
      if (i === 0 || form.name !== '' || form.street !== '' || form.city !== '' || form.state !== null || form.zipcode !== '') {
        // User started fields, validate
        dispatchLocations({
          type: 'KEY',
          payload: {
            index: i,
            key: 'invalid',
            value: validation.error ? validation.error.details.map(e => e.context.key) : []
          }
        });

        if (validation.error) {
          oneIsInvalid = true;
        }
      }
    });

    return !oneIsInvalid
  };

  const addEmpty = () => {
    dispatchLocations({type: 'ADD_EMPTY', payload: 1});
  };

  return {
    locations, dispatchLocations, validate, addEmpty,
    country
  }
};

export default useLocations;