import React from 'react';
import GridCol from '../../MasterComponents/Grid/GridCol';
import Checkbox from '../../MasterComponents/Checkbox/Checkbox';
import { isMobile } from 'react-device-detect';
const DashboardAddNewDistributorLogo = props => (
  <GridCol size={isMobile ? 6 : 3} className='my-2'>
    <label className='row m-0 add-distributor-card clickable' htmlFor={`checkbox_${props.id}`}>
      <GridCol className='text-center' align='center'>
        {props.nickname}
      </GridCol>
      <Checkbox id={`checkbox_${props.id}`} onChange={props.onChange} value={props.value} className='selector m-0' name={props.id} />
    </label>
  </GridCol>
);

export default DashboardAddNewDistributorLogo;