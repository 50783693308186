import React, { Component, Fragment } from 'react';
import { isMobile, isMobileOnly } from 'react-device-detect';
import GridRow from '../../MasterComponents/Grid/GridRow';
import './PrimePrograms.scss'
import GridCol from '../../MasterComponents/Grid/GridCol';
import { connect } from 'react-redux';
import { getPrimeHasTopHeader, getPrimeTitleClass } from '../../config/Company/CompanyHelper';

const imgPrimeProgram = 'https://rebate-portal.s3.amazonaws.com/resources/prime/images/beyond-prime-programs.jpg'

class PrimeProgramsTop extends Component {
    render() {
        const styleFont = {
            fontSize: isMobileOnly ? '25px' : '50px'  
        };
        const { companyId } =  this.props;
        const hasTopHeader = getPrimeHasTopHeader(7);
        const primeTitleClass = getPrimeTitleClass(companyId);
        
        return (
            <Fragment>
                { hasTopHeader &&
                    <GridRow id='prime-header-img' align='center'>
                    <img src={imgPrimeProgram} alt="Prime" />
                    <GridRow className='prime-row'>
                        <span className='prime-title' style={styleFont}>Beyond Broadline</span>
                    </GridRow>
                </GridRow>
                }
                <GridRow id='prime-header-img'>
                    <GridRow>
                        <span className={'text-uppercase mt-4 ' + (isMobile ?  'cutting-edge-mobile mx-4 ' : 'cutting-edge ') + primeTitleClass}>
                            Cutting Edge Technology. Employee Perks. Group Discounts. & More 
                        </span>
                    </GridRow>
                    <GridRow>
                        <GridCol className={isMobile ? '' : 'col-2' }>
                        </GridCol>
                        <GridCol className={'mt-3 mb-3 ' + (isMobile ? 'col-12 px-5' : 'col-8') }>
                            <span className='prime-our-beyond'>
                                Our Beyond Broadline offer a variety of ways to save money on all non-food aspects of your restaurant, 
                                including office supplies, pest control, recycling, 
                                credit card processing, cash handling services and more.
                                Members receive preferred pricing on a wide variety of restaurant services.
                            </span>
                        </GridCol>
                        <GridCol className={isMobile ? '' : 'col-2' }>
                        </GridCol>
                    </GridRow>
                </GridRow>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        companyId: state.company.id
    };
}

export default connect(mapStateToProps)(PrimeProgramsTop);