import React, { Component, Fragment } from 'react';
import Button from '../../../../MasterComponents/Button/Button';
import InputLabel from '../../../../MasterComponents/InputLabel/InputLabel';
import GridCol from '../../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../../MasterComponents/Grid/GridRow';
import DashedBox from '../../../../MasterComponents/DashedBox/DashedBox';
import FormDefaultDistributor from './FormDefaultDistributor';


class FormDefaultStep3 extends Component {

    addDistributor = () =>{
        this.props.addDistributor(); 
    }

    backDistributor = () =>{
        this.props.onBackClick(); 
    }

    nextOrSkipDistributor = () =>{
        if (this.props.step2Distributors.length > 0){
            this.props.onNextClick();
        } else {
            this.props.onSkipClick();
        }
    }

    render() {
        const { showAddDistributor,loopArrayDistributor,numberDistributor,
            numberLocations,selectOptionDistributor,selectOptionSubmissionType,
            saveDistributor,cancelDistributor,changeHandlerDistributorInput,
            changeHandlerDistributorInputDcn,step2Distributors,step1Locations,
            showManualUpload,updateCheckedLocations,step2DistributorsFromApi,
            selectDistributorFromDropdown,step2UnsavedDistributor, distributorsHouse, selectDistributorHouse,
            distributorsHouseSelected, checkOrderOnline } = this.props; 
        
        return (
            <Fragment>
                {!showAddDistributor ?
                    <GridCol className='form-box'>
                        <GridRow>
                            <GridCol>
                                <InputLabel text='Distributor Information' title className='title'/>                        
                            </GridCol>
                        </GridRow>

                        <GridRow className='line-form'>
                            <GridCol className='form-group'>
                                {loopArrayDistributor}
                                <DashedBox text={'+ Add New Distributor'} height={'3.5em'} className='line-box mx-0 clickable' onClick={this.addDistributor}/>
                            </GridCol>
                        </GridRow>

                        <GridRow className='line-form'>
                            <GridCol className='form-group'>
                                <Button type='button' variant='white' size='' text='BACK' onClick={this.backDistributor}/>
                            </GridCol>
                            <GridCol className='form-group'>
                                <Button type='button' variant='primary' size='' text={step2Distributors.length > 0 ? 'NEXT' : 'SKIP'} onClick={this.nextOrSkipDistributor}/>
                            </GridCol>
                        </GridRow>
                    </GridCol>                
                :
                
                <FormDefaultDistributor 
                        companyId={this.props.companyId}
                        numberDistributor={numberDistributor} 
                        numberLocations={numberLocations}
                        selectOptionDistributor={selectOptionDistributor} 
                        selectOptionSubmissionType={selectOptionSubmissionType} 
                        saveDistributor={saveDistributor} 
                        cancelDistributor={cancelDistributor} 
                        changeHandlerDistributorInput={changeHandlerDistributorInput} 
                        changeHandlerDistributorInputDcn={changeHandlerDistributorInputDcn} 
                        step2Distributors={step2Distributors} 
                        step1Locations={step1Locations} 
                        showManualUpload={showManualUpload} 
                        updateCheckedLocations={updateCheckedLocations} 
                        step2DistributorsFromApi={step2DistributorsFromApi} 
                        selectDistributorFromDropdown={selectDistributorFromDropdown} 
                        step2UnsavedDistributor={step2UnsavedDistributor} 
                        distributorsHouse = {distributorsHouse}
                        selectDistributorHouse={selectDistributorHouse}
                        distributorsHouseSelected = {distributorsHouseSelected}
                        checkOrderOnline = {checkOrderOnline}
                        />
                                    
                }
            </Fragment>
        );
    }
}

export default FormDefaultStep3;