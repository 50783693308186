import React, { Component } from 'react';
import { RxCaretLeft, RxCaretRight } from 'react-icons/rx';

class LocationPages extends Component {
  prevPage = () => {
    const { page, pageSearch } = this.props;
    if (parseInt(page) > 1) {
      pageSearch(page-1);
    }
  }
  nextPage = () => {
    const { page, pageCount, pageSearch } = this.props;
    if (parseInt(page) < parseInt(pageCount)) {
      pageSearch(page+1);
    }
  }
  onPage = (page) => {
    const { pageSearch } = this.props;
    pageSearch(page);
  }
  render() {
    const { 
      page, pageCount
     } = this.props;

    return (
      <div className='p-3 d-flex flex-col align-items-center mx-auto w-100 justify-content-center'>
        <div className='clickable' onClick={this.prevPage}>
          <RxCaretLeft />
        </div>
          {
          Array.from({length: pageCount}, (_, index) => (
            <div onClick={this.onPage.bind(this, index+1)} className={`text-center p-1 clickable ${page == index+1 && 'btn-rb-primary'}`} key={`page_${index+1}`} style={{width: '26px', height: '26px'}}>
              {index+1}
            </div>
          ))
          }
        <div className='clickable' onClick={this.nextPage}>
          <RxCaretRight />
        </div>
      </div>
    )
  }
}

export default LocationPages;
