import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import LocationTop from './LocationTop';
import DistributorList from './DistributorList/DistributorList';
import DistributorCard from './DistributorCard/DistributorCard';
import GridContainer from '../../MasterComponents/Grid/GridContainer';
import { getBgColor } from '../../config/Company/CompanyHelper';

class Location extends Component {
  render() {
    const {
      dispatch, companyColors, companyId, history,
      merchant, merchantGroup, distributorFilter,
      distributorAll, searchLocation, searchLocationId,
      activeMerchant, refresh, modals, merchantGroupQuery, loadMore
    } = this.props;
    const bgColor = getBgColor(companyId);

    const propsLocation = {
      dispatch,
      companyColors,
      merchantGroupQuery,
      companyId,
      history,
      searchLocation,
      searchLocationId,
      merchantAll: merchant,
      merchantGroup,
      distributorFilter,
      distributorAll,
      activeMerchant,
      modals,
      refresh,
      loadMore
    };
    
    return (
      <GridContainer isFluid={true} className={`px-3 py-1 flex-grow-1 ${bgColor}`}>
        <LocationTop {...propsLocation} />
        <Switch>
          <Route exact path='/dashboard/rebates/card' render={newProps => <DistributorCard {...propsLocation} {...newProps} /> } />
          <Route exact path='/dashboard/rebates/list' render={newProps => <DistributorList {...propsLocation} {...newProps} /> } />
          <Route>
            <Redirect to='/dashboard/rebates/card' />
          </Route>
        </Switch>
      </GridContainer>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    searchLocation: state.location.searchLocation,
    searchLocationId: state.location.searchLocationId,
    merchant: state.location.merchantFilter,
    merchantGroup: state.location.merchantGroup,
    distributorFilter: state.location.distributorFilter,
    distributorAll: state.location.distributorAll,
    activeMerchant: state.dashboard.activeMerchant,
    modals: state.modals.modals,
    merchantGroupQuery: state.location.merchantGroupQuery
  };
};

export default connect(mapStateToProps)(Location);
