import React, { useState } from 'react';
import { connect } from 'react-redux';
import GridRow from '../../MasterComponents/Grid/GridRow';
import GridCol from '../../MasterComponents/Grid/GridCol';
import config from '../../config/config';
import { isMobile } from 'react-device-detect';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import IconCircle from '../../MasterComponents/Icons/IconCircle';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import loadingGif from '../../assets/images/opportunities-loader.gif';
import Button from '../../MasterComponents/Button/Button';
import  './Insights.scss';
import { getRandomColor } from '../../utils/utils';
import { Col, Row } from 'reactstrap';

const InsightsBodyOpportunities = (props) => {
  const [showVolumes, setShowVolumes] = useState({show: false, data: []});
  const { dataSummary, toggleOpportunities, show, dataItemsAll, loading, displayOpportunity, companyId, purchasedRebateItems, purchasedNonRebateItems } = props;
  const appliedColor = (companyId === 3) ? config.colors.primary : config.colors.secondary;
  const chartOptions = {
    chart: {
      height: '90%',
    },
    credits: {
      enabled: false
    },
    title: {
      text: ''
    },
    series: [{
      type: 'pie',
      name: 'Items',
      innerSize: '0%',
      showInLegend: false,
      data: dataSummary
    }],
    tooltip: {
      valuePrefix: '$',
      backgroundColor: '#1A1A1A',
      borderColor: '#1A1A1A',
      borderRadius: 5,
      valueDecimals: 2,
      style: {
        color: 'white'
      },
      pointFormat: ' {point.y} ({point.percentage:.0f}%)</span>',
      formatter: function () {
        return this.key !== 'Hide' ? this.y.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})+'%' : false;
      }
    },
    plotOptions: {
      pie: {
        shadow: false,
        center: ['50%', '50%'],
        cursor: 'pointer',
        point: {
          events: {
            click: function (event) {
              const clickWhat = event.point.description;
              const data = clickWhat === 'Purchases without rebates and/or Deviations' ? purchasedNonRebateItems : purchasedRebateItems;
              setShowVolumes({
                show: true,
                data: data.sort((a, b) => {
                  if (a.purchase < b.purchase) {
                    return 1
                  }
                
                  if (a.purchase > b.purchase) {
                    return -1
                  }
                
                  return 0
                }).map((d,i) => ({description: d.description, y: d.purchase, color: getRandomColor(i+2)})).slice(0, 8)
              });
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        showInLegend: false,
      }
    }
  };

  const chartOptionsVolumes = {
    chart: {
      height: '90%',
    },
    credits: {
      enabled: false
    },
    title: {
      text: ''
    },
    series: [{
      type: 'pie',
      name: 'Items',
      innerSize: '0%',
      showInLegend: false,
      data: showVolumes.data
    }],
    tooltip: {
      valuePrefix: '$',
      backgroundColor: '#1A1A1A',
      borderColor: '#1A1A1A',
      borderRadius: 5,
      valueDecimals: 2,
      style: {
        color: 'white'
      },
      pointFormat: ' {point.y} ({point.percentage:.0f}%)</span>',
      formatter: function () {
        return this.key !== 'Hide' ? this.y.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})+'%' : false;
      }
    },
    plotOptions: {
      pie: {
        shadow: false,
        center: ['50%', '50%'],
        cursor: 'pointer',
        dataLabels: {
          enabled: false
        },
        showInLegend: false,
      }
    }
  };

  const volumesArray =[
    <GridRow className='py-2 clickable' key='vol_back' onClick={() => setShowVolumes({...showVolumes, show: false})}>
      <GridCol className='text-primary'>
        Go Back
      </GridCol>
    </GridRow>,
    ...showVolumes.data.map((d, i) => {
    return (
      <Row className='no-gutters pb-2' key={`vol_${i}`}>
        <Col xs={8} className='text-left'>
          <IconCircle color={d.color} size={12} />
          <span className='pl-3 text-grey-primary text-left text-capitalize'>{d.description && d.description.toLowerCase()}</span>
        </Col>
        <Col className={'text-grey-primary pb-2 text-right'}>
          ${(d.y || 0).toLocaleString('en', {minimumFractionDigits: 2})}
        </Col>
      </Row>
    )
  })];
    
  const dataItemsArray = dataSummary.map((d, i) => {
    return (
      <GridRow className='py-2 ' key={`vol_${i}`}>
        <GridCol size={1}>
          <IconCircle color={d.color} size={8} />
        </GridCol>
        <GridCol className='text-capitalize insights-graph-text'>
          {d.description}
        </GridCol>
        <GridCol className='text-right insights-graph-text' size={4}>
          {(d.y).toLocaleString('en', {minimumFractionDigits: 2})}%
        </GridCol>
      </GridRow>
    )
  })

  if (isMobile) {
    return (
      <GridCol className='py-3'>
        <GridRow>
          <GridCol className='pb-3 text-center insights-graph-title insights-line-bottom'>
            Savings and Opportunities
          </GridCol>
        </GridRow>
        <GridRow className='m-0'>
          <GridCol>
            <HighchartsReact highcharts={Highcharts} options={showVolumes.show ? chartOptionsVolumes : chartOptions} />
          </GridCol>
        </GridRow>
        <GridRow className='m-0'>
          <GridCol style={{maxHeight: '270px', overflowX:'hidden', overflowY:'auto'}} >
            {showVolumes.show ? volumesArray : dataItemsArray}
          </GridCol>
        </GridRow>
        {
          loading &&
          <>
            <GridRow>
              <GridCol className='pb-1 text-center'>
                <img src={loadingGif} width='150px' alt='loading' />
              </GridCol>
            </GridRow>
            <GridRow className='insights-line-bottom'>
              <GridCol className='pb-5 text-center text-yellow text-size-14'>
                Loading Opportunities...
              </GridCol>
            </GridRow>
          </>
        }
        {
          (dataItemsAll.length > 0 && !loading && displayOpportunity) &&
          <>
            <GridRow>
              <GridCol className='mt-5 text-size-20' style={{color: appliedColor}}>
                You could be earning more rebates!
              </GridCol>
            </GridRow>
            <GridRow className='pt-3'>
              <GridCol>
                <Button variant='primary' text='Show opportunities' onClick={toggleOpportunities} />
              </GridCol>
            </GridRow>
          </>
        }
      </GridCol>
    );
  }

  return (
    <GridCol className='py-3'>
      <GridRow>
        <GridCol className='pl-5 pb-3 text-left insights-graph-title insights-line-bottom'>
          Savings and Opportunities
        </GridCol>
      </GridRow>
      <GridRow className='pt-4 pr-4'>
        <GridCol className='col-5' style={{width: '60px', minWidth: '30%'}}>
          <HighchartsReact highcharts={Highcharts} options={showVolumes.show ? chartOptionsVolumes : chartOptions} />
        </GridCol>
        <GridCol className='col-7 text-left px-0'>
          <GridCol className='scrollbar-insights'>
            {showVolumes.show ? volumesArray : dataItemsArray}
            {
              ((!showVolumes.show && dataItemsAll.length) > 0 && !loading && displayOpportunity) &&
              <>
                <GridRow>
                  <GridCol className='mt-5 text-size-20' style={{color: appliedColor}}>
                    You could be earning more rebates!
                  </GridCol>
                </GridRow>
                <GridRow className='pt-3'>
                  <GridCol className='text-left insights-show-more clickable' onClick={toggleOpportunities}>
                    {
                      !show ?
                      <>
                        Show opportunities <IoIosArrowDown color={config.colors.primary} fontSize='18px'/>
                      </>
                      :
                      <>
                        Hide opportunities <IoIosArrowUp color={config.colors.primary} fontSize='18px'/>
                      </>
                    }
                  </GridCol>
                </GridRow>
              </>
            }
          </GridCol>
        </GridCol>
      </GridRow>
    </GridCol>
  );
}

const mapStateToProps = state => {
  return {
    companyId: state.company.id
  };
}

export default connect(mapStateToProps)(InsightsBodyOpportunities);
