import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import GridRow from '../../MasterComponents/Grid/GridRow';
import GridCol from '../../MasterComponents/Grid/GridCol';
import IconAdd from '../../MasterComponents/Icons/IconAdd';
import config from '../../config/config';
import ReactHtmlParser from 'react-html-parser';
import IconSubtract from '../../MasterComponents/Icons/IconSubtract';
import { connect } from 'react-redux';
import { getFAQAddIconColor, getFAQSubstractIconColor, getFAQColor, getFAQClass, getFAQBackground } from '../../config/Company/CompanyHelper';

class FaqCardContent extends Component {

  clickShowHide = (id) => {
    const { idTitle } = this.props; 
    this.props.clickShow(idTitle, id)
  }

  render() {
    const { id, show, question, answer, companyId } = this.props;
    
    return (
      <GridCol size={12} className={'card-rb clickable p-1 ' + (isMobile ? 'my-2': 'my-1') } onClick={()=> this.clickShowHide(id)}
        style={{ backgroundColor: getFAQBackground(companyId), border: "solid 1px rgba(0, 0, 0, 0.125)" }}>
        <GridRow className='p-3 pb-0'>
          <GridCol size={isMobile ? 10 : 11}
            className={'faq-question-text ' + getFAQClass(companyId)}
            style={{ color: show ? config.colors.rbBlack : getFAQColor(companyId) }}
          >
          {question}
          </GridCol>
          <GridCol size={isMobile ? 2 : 1} className='text-right'
            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >            
          { show ?
            <IconSubtract size={16} color={getFAQSubstractIconColor(companyId)} />
            :
            <IconAdd size={16} color={getFAQAddIconColor(companyId)} />
          }
          </GridCol>
        </GridRow>
        { (show === true) &&
          <GridCol size={12} className='pb-3 faq-answer-text pr-5'>
            {ReactHtmlParser(answer)}
          </GridCol>
        }
      </GridCol>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyId: state.company.id,
  };
};

export default connect(mapStateToProps)(FaqCardContent);