import React, { Component } from 'react'
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import Modal from '../../../MasterComponents/Modals/Modal';
import ModalHeader from '../../../MasterComponents/Modals/ModalHeader';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import ModalFooter from '../../../MasterComponents/Modals/ModalFooter';
import Button from '../../../MasterComponents/Button/Button';
import DropDownListSelect from '../../../MasterComponents/DropDownList/DropDownListSelect';
import InputLabel from '../../../MasterComponents/InputLabel/InputLabel';
import Input from '../../../MasterComponents/Input/Input';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import Checkbox from '../../../MasterComponents/Checkbox/Checkbox';
import { isTextValid, isEmailValid } from '../../../MasterComponents/Input/InputValidate';
import { spinner } from '../../../MasterComponents/Spinner/SpinnerActions';
import DashboardApi from '../DashboardApi';
import { showModal, findModal } from '../../../MasterComponents/Modals/ModalsActions';
import ConfirmationUserModal from './ConfirmationUserModal';

class InviteUserModal extends Component {
    constructor(props) {
      super(props);
      this.state = {
        contact_name:'',
        email:'',
        title: {label: 'Owner', value: 'Owner'},
        locations: props.locations,
        display_rebates: false,
        roleOptions: [
            {label: 'Owner', value: 'Owner'},
            {label: 'Chef', value: 'Chef'},
            {label: 'Read Only', value: 'Read Only'}
        ],
        check: props.locations.map(mer => {
            return false;
        }),
        checkAll: false,
        checkOne: false,
        cantSave: true
      }
    }

    componentDidMount = () => {
        const { type, user, locations } = this.props;
        const { roleOptions } = this.state;
        if(type ==='add'){
            this.setState({
                check: locations.map(mer => {
                    return false;
                  })
            })
        }else{
            let checks=[];
            let i=0;
            locations.forEach(loc => {
                checks.push(false);
                if(user.locations){
                    user.locations.forEach(locUsr => {
                        if (loc.id === locUsr) {
                            checks[i] = true;
                        }
                    });
                }
                i=i+1;
            });

            let checskAll = (user.locations ? (user.locations.length === locations.length ? true: false) : false);
            let checksOne = (user.locations ? (user.locations.length >=1 ? true: false): false);
            let checksRebates = (user.displayRebates !== null ? (user.displayRebates===1 ? true : false) : false);

            let title={label: 'Owner', value: 'Owner'};
            roleOptions.forEach(role => {
                if(user.title === role.value){
                    title = role;
                }
            });
            this.setState({
                contact_name:user.contactName,
                email:user.email,
                title:title,
                locations: user.locations,
                display_rebates: checksRebates,
                cantSave: false,
                checkAll: checskAll,
                check: checks,
                checkOne:checksOne
            })
        }

    }

    changeHandler = (value, name, e) => {
        this.setState({
            [name]: value
        }, () => {
            this.verify();
        });
    }

    selectOption = (value) => {
        this.setState({
          title: value
        });
    }

    checkHandler = (i) => {
        const {check} = this.state;
        const newValue = !check[i];
        const checkNew = check.map((item, index) => {
          if (i !== index) {
            return item;
          }
          return newValue
        });
        let oneNot = false;
        checkNew.forEach(ele => {
          if (!ele) {
            oneNot = true;
          }
        });
        this.setState({
          check: checkNew,
          checkAll: oneNot ? false : true,
          checkOne: false
        }, () => {
            this.verify();
        });
    }

    checkAllHandler = (i) => {
        const {check, checkAll} = this.state;
        const newValue = !checkAll;
        this.setState({
          checkAll: newValue,
          check: check.map((item, index) => {
            return newValue
          }),
          checkOne: false
        }, () => {
            this.verify();
        });
    }

    checkRebatesHandler = () => {
        const {display_rebates} = this.state;
        const newValue = !display_rebates;
        this.setState({
            display_rebates: newValue
        })
    }

    verify = () => {
        const { contact_name, email, check } = this.state;
        if (!isTextValid(contact_name, 3, 20)) {
            this.setState({ cantSave: true })
            return;
        }

        if (!isEmailValid(email)) {
            this.setState({ cantSave: true })
            return;
        }

        let atLeastOneCheck = false;
        for (let i = 0; i < check.length; i++) {
            const ele = check[i];
            if (ele) {
                atLeastOneCheck = true;
            }
        }

        if (!atLeastOneCheck) {
            this.setState({ cantSave: true })
            return;
        }

        this.setState({ cantSave: false })
        return;
    }

    invite = (e) => {
        e.preventDefault();
        const { contact_name, email, title, check, display_rebates, checkAll } = this.state;
        const { dispatch, modals, locations, refresh, companyId } = this.props;
        const  locIds=[];
        for (let i = 0; i < check.length; i++) {
            const ele = check[i];
            if (ele) {
                locIds.push(locations[i].id);
            }
        }

        const params = {
            contact_name: contact_name,
            email: email,
            title: title.value,
            locations: locIds,
            display_rebates: display_rebates,
            siteUrl: `https://${window.location.hostname}/`,
            companyId: companyId,
            all_locations: checkAll,
        }

        dispatch(spinner(true));
        DashboardApi.inviteUser(params).then(({data}) => {
            dispatch(spinner(false));
            findModal(modals, 'inviteUserModal').close();
            dispatch(showModal(ConfirmationUserModal, 'confirmationUserModal', {header:'Invitation Sent', message: 'Your invitation was sent to', email: email}));
            refresh();
        })
        .catch(error => {
            dispatch(spinner(false));
            findModal(modals, 'inviteUserModal').close();
            if (error.data.data.message) {
                let objMessage=JSON.parse(error.data.data.message);
                let msgArr='';
                Object.keys(objMessage).map(key => ( msgArr+=objMessage[key] + ' \n ' ));
                dispatch(showModal(ConfirmationUserModal, 'confirmationUserModal', {header:'Error', message: msgArr, icon: false}));
            }
        });
    }

    saveUser = (e) => {
        e.preventDefault();
        const { contact_name, email, title, check, display_rebates } = this.state;
        const { dispatch, modals, user, locations, refresh } = this.props;
        const  locIds=[];
        for (let i = 0; i < check.length; i++) {
            const ele = check[i];
            if (ele) {
                locIds.push(locations[i].id);
            }
        }

        const params = {
            contact_name: contact_name,
            email: email,
            title: title.value,
            locations: locIds,
            display_rebates: display_rebates
        }

        dispatch(spinner(true));
        DashboardApi.editUser(user.id, params).then(({data}) => {
            dispatch(spinner(false));
            findModal(modals, 'inviteUserModal').close();
            dispatch(showModal(ConfirmationUserModal, 'confirmationUserModal', {header:'Confirmation', message: 'User Saved', email: email}));
            refresh();
        })
        .catch(error => {
            dispatch(spinner(false));
            findModal(modals, 'inviteUserModal').close();
            if (error.data.data.message) {
                let objMessage=JSON.parse(error.data.data.message);
                let msgArr='';
                Object.keys(objMessage).map(key => ( msgArr+=objMessage[key] + ' \n ' ));
                dispatch(showModal(ConfirmationUserModal, 'confirmationUserModal', {header:'Error', message: msgArr, icon: false}));
            }
        });
    }

    render() {
        const { contact_name, email, title, roleOptions,check, checkAll, checkOne, display_rebates, cantSave } = this.state;
        const { header, type, locations} = this.props;
        return (
            <Modal size='md' id='inviteUserModal'>
                <ModalHeader blueHeader={isMobile ? true : false}>{header}</ModalHeader>
                <ModalBody>
                    <GridRow>
                        <GridCol className='form-group'>
                            <InputLabel>Name*</InputLabel>
                            <Input value={contact_name} type='text' validate name='contact_name' onChange={this.changeHandler} placeholder='' min={3} max={20}/>
                        </GridCol>
                    </GridRow>
                    <GridRow>
                        <GridCol className='form-group'>
                            <InputLabel>Email*</InputLabel>
                            <Input value={email} type='email' validate name='email' onChange={this.changeHandler} placeholder='' disabled={(type==='edit'? true: false)}/>
                        </GridCol>
                    </GridRow>
                    <GridRow>
                        <GridCol className='form-group'>
                            <InputLabel>Role*</InputLabel>
                            <DropDownListSelect defaultValue={title[0]}
                                name='role' ref='role'
                                isSearchable={false}
                                value={title}
                                onChange={this.selectOption} options={roleOptions}
                            />
                        </GridCol>
                    </GridRow>
                    <GridRow className='mt-3 mb-0'>
                        <GridCol>
                            <InputLabel text='Add this user to:' title className='text-size-16'/>
                        </GridCol>
                    </GridRow>
                    { true ?
                    <GridRow align='center' className={(isMobile ? ' d-flex flex-row-reverse' : '') + ' mr-2 mb-0'}>
                        <GridCol className='col-1'>
                            <Checkbox text=''  className={checkOne ? 'text-red' : null} name='all' value={checkAll} onChange={this.checkAllHandler} />
                        </GridCol>
                        <GridCol className='col'>
                            All Locations
                        </GridCol>
                    </GridRow>
                    : null }
                    {locations.map((loc, i) => (
                    <GridRow key={i} align='center' className={(isMobile ? ' d-flex flex-row-reverse' : '') + ' mr-2 mb-0'}>
                        <GridCol className='col-1'>
                            <Checkbox text='' className={checkOne ? 'text-red' : ''} name={i} value={check[i]} onChange={this.checkHandler} />
                        </GridCol>
                        <GridCol className='col'>
                            {loc.nickname}
                        </GridCol>
                    </GridRow>
                    ))}

                    <GridRow align='center' className={(isMobile ? ' d-flex flex-row-reverse' : '') + ' mr-2 mt-3'}>
                        <GridCol className='col-1'>
                            <Checkbox text=''  name='rebates' value={display_rebates} onChange={this.checkRebatesHandler} />
                        </GridCol>
                        <GridCol className='col'>
                            Allow this user to see rebates
                        </GridCol>
                    </GridRow>
                </ModalBody>
                <ModalFooter className='text-left justify-content-start' center={false}>
                    <GridCol className='px-0 form-group'>
                        <Button disabled={cantSave} variant='primary' onClick={(type==='add') ? this.invite.bind(this) : this.saveUser.bind(this)} size='' text={(type==='add') ? 'Invite' : 'Save'} />
                    </GridCol>
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
      locations: state.dashboard.merchants,
      users: state.dashboard.users,
      companyId: state.company.id
    };
}

export default connect(mapStateToProps)(InviteUserModal);
