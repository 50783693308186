import React, { Component, Fragment } from 'react';
import GridCol from '../../MasterComponents/Grid/GridCol';
import GridRow from '../../MasterComponents/Grid/GridRow';
import ReactSlider from 'react-slider';
import Input from '../../MasterComponents/Input/Input';
import { isMobile } from 'react-device-detect';
import numeral from 'numeral';
import HomeApi from './HomeApi'

const SliderValue = ({label, value, min, max, onChange}) => (
  <GridCol size={ isMobile ? 12 : 6} className='mb-4'>
    <GridRow>
      <GridCol className='font-weight-bold'>{label}</GridCol>
      <GridCol size={4} className='font-weight-bold text-right'>{numeral(value).format('$0,0')}</GridCol>
    </GridRow>
    <GridRow style={{height: '34px'}}>
      <GridCol>
        <ReactSlider
          step={100}
          className='calc-slider'
          thumbClassName='thumb'
          trackClassName='track'
          onChange={onChange}
          value={value}
          min={min}
          max={max}
        />
      </GridCol>
    </GridRow>
    <GridRow>
      <GridCol>{numeral(min).format('$0,0')}</GridCol>
      <GridCol className='text-right'>{numeral(max).format('$0,0')}</GridCol>
    </GridRow>
  </GridCol>
);

const DropdownValue = ({label, value, options, onChange}) => (
  <GridCol size={ isMobile ? 12 : 6} className='mb-5'>
    <GridRow>
      <GridCol className='font-weight-bold'>{label}</GridCol>
    </GridRow>
    <GridRow className='pt-2' style={{height: '34px'}}>
      <GridCol>
        <div className="dropdown calc-dropdown">
          <button className="dropdown-toggle w-100 calc-dropdown-button" type="button" id="dropdownMenuButton" aria-haspopup="true" data-toggle="dropdown" aria-expanded="false">
            {value}
          </button>
          <div className="dropdown-menu calc-dropdown-menu w-100" aria-labelledby="dropdownMenuButton">
            {options.map((o, i) => (
              <div onClick={() => onChange(o)} key={`op_${label}_${i}`} className="dropdown-item w-100 clickable">{o}</div>
            ))}
          </div>
        </div>
      </GridCol>
    </GridRow>
  </GridCol>
);

const YesOrNoValue = ({label, yes, onChange}) => (
  <GridCol size={ isMobile ? 12 : 6} className='mb-5'>
    <GridRow>
      <GridCol className='font-weight-bold'>{label}</GridCol>
    </GridRow>
    <GridRow className='pt-2' style={{height: '34px'}}>
      <GridCol>
        <button onClick={() => onChange(true)} className={'calc-button w-100' + (yes ? ' active' : '')}>Yes</button>
      </GridCol>
      <GridCol>
        <button onClick={() => onChange(false)} className={'calc-button w-100' + (!yes ? ' active' : '')}>No</button>
      </GridCol>
    </GridRow>
  </GridCol>
);

class SavingsCalculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      broadline: 0,
      paper: 0,
      meat: 0,
      produce: 0,
      soda: 0,
      alcohol: 0,
      cash: 0,
      type: 'Italian',
      onlineYes: true,
      showResults: false,
      email: '',
      estimate: 0
    }
  };
  onChange = (field, value) => {
    this.setState({
      [field]: value
    });
  };
  addDistributor = (d) => {
    this.setState({
      distributors: [
        ...this.state.distributors,
        d
      ]
    });
  };
  removeDistributor = (d) => {
    this.setState({
      distributors: this.state.distributors.filter(dd => dd !== d),
    });
  };
  searchOnChange = (e) => {
    const newValue = e.currentTarget.value.replace(/[^a-zA-Z]+/g, "");;
    this.setState({
      searchValue: newValue
    });
  };
  saveCalculate = () => {
    const { broadline, paper, meat, produce, soda, alcohol, cash,
      type, onlineYes, email } = this.state;
      const result = ((broadline * 0.005) + (paper * 0.005)) * 52;
      this.setState({
        estimate: result
      });
      const params = {
        broadline,
        paper_disposables: paper,
        speciality_meat: meat,
        produce,
        soda,
        alcohol,
        cash_and_carry: cash,
        restaurant_type: type,
        is_online_orderer: onlineYes,
        email,
        estimate: result
      }
      HomeApi.saveLead(params).then(() => {
      }).catch(err => {
        console.error(err);
      });
  }
  goToResults = () => {
    if (!this.state.showResults) {
      if (this.refs.email.isValid()) {
        this.saveCalculate();
        this.setState({
          showResults: !this.state.showResults
        });
      }
    } else {
      this.setState({
        showResults: !this.state.showResults
      });
    }
  };
  goToSignUp = () => {
    this.props.history.push('/register');
  }
  render() {
    const {
      broadline, paper, meat,
      produce, soda, alcohol, cash,
      type, onlineYes, showResults, email, estimate } = this.state;

    return (
      <GridCol className='savings-bg py-4 text-left d-flex flex-column justify-content-center' style={ isMobile ? null : {height: '736px'}}>
        {
          showResults ?
            <Fragment>
              <GridRow>
                <GridCol className='text-center font-weight-bold text-size-20 pb-3'>YOU COULD EARN</GridCol>
              </GridRow>
              <GridRow>
                <GridCol className='text-center calc-result'>{numeral(estimate).format('$0,0')}</GridCol>
              </GridRow>
              <GridRow>
                <GridCol className='text-center annually pb-3'>Cash Back annually</GridCol>
              </GridRow>
              <GridRow>
                <GridCol className='text-center pb-3 by-enrolling-in-my-d'>by enrolling in My Dining Alliance</GridCol>
              </GridRow>
              {
                !isMobile ?
                  <GridRow className='text-center'>
                    <GridCol className='text-center pb-3'>
                      <button onClick={this.goToResults} className='calc-button calc-again-button m-3'>CALCULATE AGAIN</button>
                      { !this.props.hideGetStarted &&
                      <button onClick={this.goToSignUp} className='calc-button calc-started-button m-3'>GET STARTED</button>
                      }
                    </GridCol>
                  </GridRow>
                :
                  <Fragment>
                    { !this.props.hideGetStarted &&
                    <GridRow className='text-center mb-3'>
                      <GridCol className='text-center'>
                        <button onClick={this.goToSignUp} className='calc-button calc-started-button'>GET STARTED</button>
                      </GridCol>
                    </GridRow>
                    }
                    <GridRow className='text-center'>
                      <GridCol className='text-center'>
                        <button onClick={this.goToResults} className='calc-button calc-again-button'>CALCULATE AGAIN</button>
                      </GridCol>
                    </GridRow>
                  </Fragment>
              }
            </Fragment>
          :
          <Fragment>
            <GridRow>
              <GridCol className='text-center font-weight-bold text-size-20 pb-3'>CASHBACK ESTIMATOR</GridCol>
            </GridRow>
            <GridRow>
              <GridCol className='pb-4'>
                How much do you spend per week at distributors in the following categories?
              </GridCol>
            </GridRow>
            <GridRow>
              <SliderValue onChange={(v) => { this.onChange('broadline', v) }} label='Broadline (Delivered)' value={broadline} min={0} max={20000} />
              <SliderValue onChange={(v) => { this.onChange('paper', v) }} label='Paper/Disposables' value={paper} min={0} max={20000} />
              <SliderValue onChange={(v) => { this.onChange('meat', v) }} label='Speciality/Meat Distributors' value={meat} min={0} max={15000} />
              <SliderValue onChange={(v) => { this.onChange('produce', v) }} label='Produce' value={produce} min={0} max={20000} />
              <SliderValue onChange={(v) => { this.onChange('soda', v) }} label='Soda' value={soda} min={0} max={10000} />
              <SliderValue onChange={(v) => { this.onChange('alcohol', v) }} label='Alcohol' value={alcohol} min={0} max={20000} />
              <SliderValue onChange={(v) => { this.onChange('cash', v) }} label='Cash and Carry (Restaurant Depot, Sams Club, Etc)' value={cash} min={0} max={20000} />
              <DropdownValue onChange={(v) => this.onChange('type', v)} value={type} label='What type of restaurant do you operate?' options={['Italian', 'Fast Casual', 'Mexican', 'Bar', 'Fine Dining', 'Other']} />
              <YesOrNoValue onChange={(v) => { this.onChange('onlineYes', v) }} label='Do you order online from your distributors?' yes={onlineYes} />
              {/* <DropdownDistributor searchValue={searchValue} searchOnChange={this.searchOnChange} onRemove={this.removeDistributor} onAdd={this.addDistributor} values={distributors} /> */}
              <GridCol size={isMobile ? 12 : 6}>
                <GridRow>
                  <GridCol className='font-weight-bold'>Email</GridCol>
                </GridRow>
                <GridRow className={'pt-2' + (isMobile ? ' mb-4' : '')}>
                  <GridCol>
                    <Input name='email' validate ref='email' placeholder='Email' className='calc-input' onChange={(v) => this.onChange('email', v)} type='email' value={email} />
                  </GridCol>
                </GridRow>
              </GridCol>
            </GridRow>
            <GridRow className='pt-3 align-items-end mb-5'>
              <GridCol size={isMobile ? 12 : 6} className={'text-right' + (isMobile ? '' : ' offset-6')}>
                <button onClick={this.goToResults} className='calc-button calc-calculate w-100'>CALCULATE</button>
              </GridCol>
            </GridRow>
          </Fragment>
        }
      </GridCol>
    );
  };
};

export default SavingsCalculator;