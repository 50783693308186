import React, { Component } from 'react';
import ModalHeader from '../../../MasterComponents/Modals/ModalHeader';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import Modal from '../../../MasterComponents/Modals/Modal';
import ModalFooter from '../../../MasterComponents/Modals/ModalFooter';
import Button from '../../../MasterComponents/Button/Button';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import Checkbox from '../../../MasterComponents/Checkbox/Checkbox';
import InsightsApi from '../InsightsApi';
import { spinner } from '../../../MasterComponents/Spinner/SpinnerActions';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import { findModal } from '../../../MasterComponents/Modals/ModalsActions';

class InsightsBodyOpportunitiesDeleteModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      incorrectMatch: false,
      noSwitchCategory: false,
      noSwitchManufacturer: false,
      happyCurrentItem: false
    };
  };
  deleteConfirm = () => {
    const { dispatch, original, match, refresh, datePeriod, modals } = this.props;
    const { incorrectMatch, noSwitchCategory, noSwitchManufacturer, happyCurrentItem } = this.state;

    const params = {
      originalProductId: original.product_id,
      matchProductId: match.product_id,
      incorrectMatch: incorrectMatch ? 1 : 0,
      noSwitchCategory: noSwitchCategory ? 1 : 0,
      noSwitchManufacturer: noSwitchManufacturer ? 1 : 0,
      happyCurrentItem: happyCurrentItem ? 1 : 0
    };

    dispatch(spinner(true));
    InsightsApi.DeleteInsight(params).then(() => {
      dispatch(spinner(false));
      const format = 'YYYY-MM-DD';
      const startDate = moment(datePeriod.startDate); 
      const endDate = moment(datePeriod.endDate);
      refresh(startDate.format(format), endDate.format(format));
      findModal(modals, 'InsightsBodyOpportunitiesDeleteModal').close();
    }).catch(err => {
      console.error(err);
      dispatch(spinner(false));
    });
  };
  checkHandler = (name) => {
    this.setState({
      [name]: !this.state[name]
    });
  };
  render() {
    const { incorrectMatch, noSwitchCategory, noSwitchManufacturer, happyCurrentItem } = this.state;

    return (
      <Modal id='InsightsBodyOpportunitiesDeleteModal' fullHeight={isMobile}>
        <ModalHeader noBorder blueHeader={isMobile}>
          Why do you not like this?
        </ModalHeader>
        <ModalBody className='text-size-20 line-height-20'>
          <GridRow align='center'>
            <GridCol>
              <Checkbox name='incorrectMatch' className='text-size-14' text='Incorrect match' value={incorrectMatch} onChange={this.checkHandler.bind(this, 'incorrectMatch')}/>
            </GridCol>
          </GridRow>
          <GridRow align='center'>
            <GridCol>
              <Checkbox name='happyCurrentItem' className='text-size-14' text='Happy with current item' value={happyCurrentItem} onChange={this.checkHandler.bind(this, 'happyCurrentItem')}/>
            </GridCol>
          </GridRow>
          <GridRow align='center'>
            <GridCol>
              <Checkbox name='noSwitchCategory' className='text-size-14' text="I won't switch this category" value={noSwitchCategory} onChange={this.checkHandler.bind(this, 'noSwitchCategory')}/>
            </GridCol>
          </GridRow>
          <GridRow align='center'>
            <GridCol>
              <Checkbox name='noSwitchManufacturer' className='text-size-14' text="I won't switch to this manufacturer" value={noSwitchManufacturer} onChange={this.checkHandler.bind(this, 'noSwitchManufacturer')}/>
            </GridCol>
          </GridRow>
        </ModalBody>
        <ModalFooter justify='start'>
          <Button text='SUBMIT' size={isMobile ? '' : 'md'} variant='primary' onClick={this.deleteConfirm} />
        </ModalFooter>
      </Modal>
    )
  }
};

export default InsightsBodyOpportunitiesDeleteModal;
