import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import GridCol from '../../MasterComponents/Grid/GridCol';
import GridRow from '../../MasterComponents/Grid/GridRow';
import Button from '../../MasterComponents/Button/Button';
import Input from '../../MasterComponents/Input/Input';
import InputLabel from '../../MasterComponents/InputLabel/InputLabel';
import ResetPasswordApi from './ResetPasswordApi';
import { getTextLinkColor } from '../../config/Company/CompanyHelper';

class ResetPasswordContent extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            email: ''            
        }
    }

    componentDidMount = () => {
        this.setState({
            email: ''
        });
    }

    changeHandler = (value, name, e) => {
        this.setState({
          [name]: value
        });
    };

    requestPassword = (e) => {
        e.preventDefault();
        
        if (this.state.email === '') {
            alert('Email can not be empty');
        }
        
        ResetPasswordApi.resetPassword(this.state.email, this.props.companyId, `https://${window.location.hostname}/`)
            .then(({data})=>{                
                let result = data.data;
                alert(result.message);       
            })
            .catch(function (error) {
                if (error.data) {
                    let errorMessage = error.data.data.message;
                    alert(errorMessage);
                }
            })        
    };

    goToLogin = (e) => {
        e.preventDefault();
        this.props.history.push('/login');
    };

    

    render() {
        const { email } = this.state;
        const { companyId } = this.props;
        const textLinkUnauthColor = getTextLinkColor(companyId);
        return (
            <div className='right-reset'>
                <div className='header-reset'>
                    { isMobile ? <InputLabel text='Login' title className='title'/> : null}
                </div>
                <form className='reset-form' onSubmit={this.requestPassword}>
                    <GridRow className='row' minWidth = { isMobile ? null : '400px'}>
                        <GridCol className='col-12'>
                            <InputLabel text='Forgot Password' title className='title'/>
                        </GridCol>
                        <GridCol className='col-12 form-group'>                            
                            <GridCol className='col p-0'>
                                <InputLabel text='Please enter your email so we can send you a link to reset your password' title className='forgotpass-text'/>
                            </GridCol>
                        </GridCol>
                        <GridCol className='col-12 form-group'>
                            <InputLabel text='Email'/>
                            <GridCol className='col p-0'>
                                <Input value={email} validate name='email' onChange={this.changeHandler} placeholder='Enter your email' className='login-text' min={3} max={100} />
                            </GridCol>
                        </GridCol>                        
                        <GridCol className='col-12 form-group'>
                            <GridCol className='col p-0'>
                                <Button type='button' variant='primary' text='Submit' onClick={this.requestPassword}></Button>
                            </GridCol>
                        </GridCol>
                        <GridCol className='col-12 form-group'>
                            <GridCol className='col p-0 label-contact d-flex'>
                                <span><a href='/login' onClick={this.goToLogin} className='font-weight-bold text-size-13' style={{color: textLinkUnauthColor}}>Back to Login</a></span>
                            </GridCol>                            
                        </GridCol>
                    </GridRow>                    
                </form>
                <div className='footer-reset'>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        companyId: state.company.id
    };
}

export default connect(mapStateToProps)(ResetPasswordContent);