export const notificationSetData = (data) => {
  return {
    type: 'NOTIFICATION_SET_DATA',
    payload: data
  };
};

export const notificationSetCounter = (data) => {
  return {
    type: 'NOTIFICATION_SET_COUNTER',
    payload: data
  };
};
    