import React from "react";
import PropTypes from 'prop-types'; 

const TextArea = (props) => (
    <textarea className={props.className} rows={props.rows} cols={props.col} placeholder={props.placeholder} defaultValue={props.defaultValue} readOnly={props.readOnly}>
        {props.text || props.children}
    </textarea>
);

TextArea.propTypes = {
    text: PropTypes.string,  
    className: PropTypes.string,
    title: PropTypes.string,
    defaultValue: PropTypes.string
  }

export default TextArea;
