import React from 'react';
import { FiChevronLeft, FiChevronsLeft, FiChevronRight, FiChevronsRight } from 'react-icons/fi';
import config from '../../config/config';
import './Reports.scss';

const ReportsPagination = ({reportsPerPage, currentPage, totalReports, onPaginate, onFirst, onLast, onPrevious, onNext}) => {
  const pageNumbers = [];

  for(let i=1; i<= Math.ceil(totalReports / reportsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className='pagination py-4 pl-3 justify-content-center align-items-center'>
      <li className='px-3 clickable' onClick={onFirst}> <FiChevronsLeft size={19} color={config.colors.rbBlack} /> </li>
        <li className='px-2 clickable' onClick={onPrevious}> <FiChevronLeft size={18} color={config.colors.rbBlack} /> </li>
        { pageNumbers.map(number => (
          <li key={number} onClick={()=> onPaginate(number)} className={'page-item px-3 clickable ' }>
            <div className={'text-center pt-1 ' + (currentPage === number ? 'pagination-active' : '')}>
              {number}
            </div>
          </li>
          ))
        }
        <li className='px-2 clickable' onClick={onNext}> <FiChevronRight size={18} /> </li>
        <li className='px-3 clickable' onClick={onLast}> <FiChevronsRight size={19} /> </li>
      </ul>
    </nav>
  )
}

export default ReportsPagination;
