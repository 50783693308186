import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconDropdown = props => (
  <svg width={props.size + 'px'} height={(props.size / 2) + 'px'} viewBox="0 0 8 4" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Intel" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="2---Item-Intel" transform="translate(-215.000000, -116.000000)" fill="#202020">
        <g id="dropdown" transform="translate(12.000000, 98.000000)">
          <g id="icons/dropdown" transform="translate(203.000000, 18.000000)">
            <polygon id="Path" points="0 0 4 4 8 0"></polygon>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

IconDropdown.propTypes = {
  color: PropTypes.string,
  activeColor: PropTypes.string,
  isActive: PropTypes.bool,
  size: PropTypes.number,
};

IconDropdown.defaultProps = {
  activeColor: config.colors.rbYellow,
  color: config.colors.rbWhite,
  isActive: false,
  size: 8,
};

export default IconDropdown;