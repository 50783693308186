export default function reducer(state = {
  width: '0px',
  height: '0px'
}, action) {
  switch (action.type) {
    case 'UPDATE':
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}