import ReactGA from 'react-ga';
import { showModal } from '../Modals/ModalsActions';
import ActivateDistributorModal from '../../views/Dashboard/Modals/ActivateDistributorModal';
import { useDispatch } from 'react-redux';
const useShowActivateDistributor = () => {
  const dispatch = useDispatch();
  const showActivateDistributor = (refresh, merDist, isEdit) => {
    ReactGA.event({
      category: isEdit ? 'Edit Distributor' : 'Click Button',
      action: 'Click Activate Distributor'
    });
    dispatch(showModal(ActivateDistributorModal, 'activateDistributorModal', { merchantDistributor: merDist, refresh: refresh, isEdit: isEdit }));
  }
  return showActivateDistributor;
};
export default useShowActivateDistributor;