import React from 'react';
import PropTypes from 'prop-types';

const LogoMainCompany = props => (
  <img id={props.id} className={props.className}
    style={props.style}
    src={process.env.REACT_APP_COMPANY_MAIN_LOGO}
    alt={`${process.env.REACT_APP_COMPANY_NICKNAME} logo`} />
);

LogoMainCompany.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string
}

export default LogoMainCompany;