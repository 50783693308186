import { useEffect } from 'react';
import queryString from 'query-string';

const useSmartLink = (history, dispatch) => {
  useEffect(() => {
    const { search } = history.location;
    if (search) {
      const qp = queryString.parse(search);
      dispatch(qp);
    }
    // eslint-disable-next-line
  }, []);
};

export default useSmartLink;