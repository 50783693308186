import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconStar = props => (
  <svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="side-menu" transform="translate(-34.000000, -259.000000)" fill={!props.isActive ? props.color : props.activeColor} fillRule="nonzero">
        <g id="menu-item">
          <g transform="translate(34.000000, 257.000000)">
            <g id="icons/star" transform="translate(0.000000, 2.000000)">
              <path d="M15.0991515,5.19030303 L10.3243636,4.50957576 L8.1910303,0.267151515 C8.02618182,-0.0606060606 7.48945455,-0.0606060606 7.32460606,0.267151515 L5.19127273,4.50957576 L0.416484848,5.19030303 C0.232727273,5.21648485 0.0804848485,5.3449697 0.0232727273,5.52193939 C-0.0334545455,5.69842424 0.0155151515,5.89236364 0.149818182,6.02036364 L3.5990303,9.32024242 L2.7849697,13.9767273 C2.75345455,14.1585455 2.82715152,14.3418182 2.976,14.4509091 C3.12484848,14.5604848 3.32218182,14.5764848 3.48557576,14.4911515 L7.75757576,12.2870303 L12.0300606,14.4911515 C12.0998788,14.5275152 12.1764848,14.5454545 12.2526061,14.5454545 C12.3539394,14.5454545 12.4547879,14.5139394 12.5396364,14.4513939 C12.6884848,14.342303 12.7621818,14.1585455 12.7306667,13.9772121 L11.9166061,9.32072727 L15.3658182,6.02084848 C15.4996364,5.89236364 15.5490909,5.69890909 15.4923636,5.52242424 C15.4351515,5.3449697 15.2829091,5.21648485 15.0991515,5.19030303 Z M11.0589091,8.79854545 C10.9420606,8.91006061 10.8882424,9.0729697 10.9163636,9.23248485 L11.6067879,13.1820606 L7.98060606,11.3110303 C7.91030303,11.2751515 7.83418182,11.2572121 7.75806061,11.2572121 C7.68193939,11.2572121 7.60581818,11.2751515 7.53551515,11.3110303 L3.90933333,13.1820606 L4.59975758,9.23248485 C4.62787879,9.0729697 4.57406061,8.91006061 4.45721212,8.79854545 L1.54133333,6.00969697 L5.57915152,5.43369697 C5.73575758,5.41139394 5.872,5.31345455 5.94375758,5.17139394 L7.75757576,1.56412121 L9.57187879,5.17139394 C9.64315152,5.3129697 9.77987879,5.41139394 9.93648485,5.43369697 L13.974303,6.00969697 L11.0589091,8.79854545 Z" id="Shape"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

IconStar.propTypes = {
  color: PropTypes.string,
  activeColor: PropTypes.string,
  isActive: PropTypes.bool,
  size: PropTypes.number,
};

IconStar.defaultProps = {
  activeColor: config.colors.rbYellow,
  color: config.colors.rbWhite,
  isActive: false,
  size: 16,
};

export default IconStar;