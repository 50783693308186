import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import earnImage from '../../../assets/images/ICON-earn-cashback.png';
import freeImage from '../../../assets/images/ICON-free-to-join.png';
import connectedImage from '../../../assets/images/ICONS-get-connected.png';
import savingsImage from '../../../assets/images/ICONS-savings-offers.png';

const JoinBenefits = () => {
	const benefits = [{
		title: '100% FREE TO JOIN',
		text: 'Becoming a Dining Alliance member comes at no cost and is entirely FREE. Sign up is quick and easy.',
		image: freeImage
	}, {
		title: 'EARN CASHBACK',
		text: 'Start earning CashBack for FREE on over 165,000 rebate line items you buy everyday.',
		image: earnImage
	}, {
		title: 'SAVINGS OFFERS',
		text: 'Save on additional program offerings such as Skechers, DirecTV, ChefWorks & more.',
		image: savingsImage
	}, {
		title: 'GET CONNECTED',
		text: 'Plug your operation into the best technology available to restaurants at no cost to you.',
		image: connectedImage
	}];

	return (
		<Container className='mw-1140'>
			<Row className='pt-4'>
				{
					benefits.map(({ title, text, image }) =>
						<Col key={`ben_${title}`} className='join-benefits-image-div' xs={6} sm={6} md={6} lg={3}>
							<Row className='pt-3'>
								<Col>
									<img src={image} alt={title} className='join-benefits-image' />
								</Col>
							</Row>
							<Row>
								<Col className='text-small-title text-action py-3'>
									{title}
								</Col>
							</Row>
							<Row>
								<Col className='text-muted'>
									{text}
								</Col>
							</Row>
						</Col>
					)
				}
			</Row>
		</Container>
	);
};

export default JoinBenefits;