import React, { Component, Fragment } from 'react';
import { claimCashSetStep } from '../ClaimCash/Reducer/ClaimCashAction';
import GridRow from '../../MasterComponents/Grid/GridRow';
import GridCol from '../../MasterComponents/Grid/GridCol';
import IconReceipt from '../../MasterComponents/Icons/IconReceipt';
import Button from '../../MasterComponents/Button/Button';
import IconCheckMark from '../../MasterComponents/Icons/IconCheckMark';
import config from '../../config/config';
import ClaimCashChecksEmpty from './ClaimCashChecksEmpty';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

class ClaimCashChecks extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLimit: 3,
            buttonText: 'View More'
        }
    }

    goToClaimCash = (data) => {
        this.props.goToClaimCash(data, true);
    }

    backCheck = () => {
        const { dispatch } = this.props;
        dispatch(claimCashSetStep(1));
    }

    seeDetails = (data) => {
        this.props.seeDetails(data);
    }

    toggleVisibility = () => {
        this.state.showLimit ?
            this.setState({
                showLimit: null,
                buttonText: 'Hide'
            })
            :
            this.setState({
                showLimit: 1,
                buttonText: 'View More'
            });
    }

    render() {
        const { showLimit, buttonText } = this.state;
        const { steps, dataLookUp } = this.props;
        const dataLookUpArray = dataLookUp.map((c, i) => (
            <GridCol key={i} className={'border-top px-3 ' + (c.isGray ? 'claim-gray' : '') + (showLimit && i >= showLimit ? ' d-none' : '')} >
                <GridRow className='pt-4 pb-2'>
                    <GridCol size={4} className='claim-text1'>
                        {c.timeframe}
                        <GridCol size={3} className='pl-0 pt-2 claim-text2'>
                            {c.amount ? (c.amount).split('.')[0] : ''}
                        </GridCol>
                    </GridCol>
                    <GridCol size={3} className='text-capitalize pt-4 px-0'>
                        {c.type}
                    </GridCol>
                </GridRow>
                <GridRow>
                  { c.details_only === 1 &&
                    <GridCol>
                      <Button variant='white' size='sm' text='Details' onClick={() => this.seeDetails(c)}></Button>
                    </GridCol>
                  }
                  { (c.details_only === 0 && c.sfCheckId !== null) &&
                    <>
                      <GridCol>
                        <Button variant='white' size='sm' text='Details' onClick={() => this.seeDetails(c)}></Button>
                      </GridCol>
                      <GridCol size={5} className='text-right'>
                        { c.requested_date === null ?
                          <GridCol size={5} className='text-right px-2'>
                            <Button type='button' variant='primary' size='sm' text='CLAIM' onClick={this.goToClaimCash.bind(this, c)}></Button>
                          </GridCol>
                          : 
                          <GridCol className='px-2 text-left'>
                            <IconCheckMark color={config.colors.rbGreen} size={30} />
                          </GridCol>
                        }
                      </GridCol>
                    </>
                  }
                  { (c.details_only === 0 && c.sfCheckId === null) &&
                    <>
                      <GridCol size={5} className='text-right'>
                        { c.requested_date === null ?
                          <GridCol size={5} className='text-right px-2'>
                            <Button type='button' variant='primary' size='sm' text='CLAIM' onClick={this.goToClaimCash.bind(this, c)}></Button>
                          </GridCol>
                          : 
                          <GridCol className='px-2 text-left'>
                            <IconCheckMark color={config.colors.rbGreen} size={30} />
                          </GridCol>
                        }
                      </GridCol>
                    </>
                  }
                </GridRow>
                <GridRow className='py-2 px-3 claim-text2'>
                    <GridCol size={5}>
                    </GridCol>
                </GridRow>
            </GridCol>
        ));

        return (
            <Fragment>
                <GridRow>
                    <GridCol className='text-center'>
                        <GridCol className='py-3'>
                            <IconReceipt color={config.colors.secondary} size={30} />
                        </GridCol>
                        <GridCol className='pb-5 claim-text1'>
                            Rebate Checks
                        </GridCol>
                    </GridCol>
                </GridRow>
                {steps.step2.status &&
                    <Fragment>
                        {dataLookUp.length > 0 ?
                            <>
                                <GridCol className='border px-0'>
                                    {dataLookUpArray}
                                </GridCol>
                                {(showLimit < dataLookUp.length) &&
                                    <GridCol className='d-flex justify-content-center'>
                                        <div className='btn btn-link text-primary text-uppercase' onClick={this.toggleVisibility}>
                                            {buttonText} {showLimit ? <IoIosArrowDown /> : <IoIosArrowUp />}
                                        </div>
                                    </GridCol>
                                }
                            </>
                            :
                            <GridCol>
                                <ClaimCashChecksEmpty />
                            </GridCol>
                        }
                        <GridRow className='pt-5'>
                            <GridCol className='form-group'>
                                <Button type='button' variant='white' size='' text='BACK' onClick={this.backCheck} />
                            </GridCol>
                        </GridRow>
                    </Fragment>
                }
            </Fragment>
        );
    }
}

export default ClaimCashChecks;