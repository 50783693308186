import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconWallet = props => (
  <svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="side-menu" transform="translate(-34.000000, -212.000000)" fill={!props.isActive ? props.color : props.activeColor} fillRule="nonzero">
        <g id="menu-item">
          <g transform="translate(34.000000, 208.000000)">
            <g id="icons/wallet" transform="translate(0.000000, 4.000000)">
              <path d="M14.4,0 L1.6,0 C0.717866667,0 0,0.717866667 0,1.6 L0,10.1333333 C0,11.0154667 0.717866667,11.7333333 1.6,11.7333333 L14.4,11.7333333 C15.2821333,11.7333333 16,11.0154667 16,10.1333333 L16,1.6 C16,0.717866667 15.2821333,0 14.4,0 Z M14.9333333,6.93333333 L9.88533333,6.93333333 L9.1744,5.86666667 L9.88533333,4.8 L14.9333333,4.8 L14.9333333,6.93333333 Z M14.4,10.6666667 L1.6,10.6666667 C1.30613333,10.6666667 1.06666667,10.4272 1.06666667,10.1333333 L1.06666667,1.6 C1.06666667,1.30613333 1.30613333,1.06666667 1.6,1.06666667 L14.4,1.06666667 C14.6938667,1.06666667 14.9333333,1.30613333 14.9333333,1.6 L14.9333333,3.73333333 L9.6,3.73333333 C9.42186667,3.73333333 9.25546667,3.8224 9.15626667,3.97066667 L8.0896,5.57066667 C7.97013333,5.74986667 7.97013333,5.98293333 8.0896,6.16213333 L9.15626667,7.76213333 C9.25546667,7.91093333 9.42186667,8 9.6,8 L14.9333333,8 L14.9333333,10.1333333 C14.9333333,10.4272 14.6938667,10.6666667 14.4,10.6666667 Z" id="Shape"></path>
                <circle id="Oval" cx="10.6666667" cy="5.86666667" r="1"></circle>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

IconWallet.propTypes = {
  color: PropTypes.string,
  activeColor: PropTypes.string,
  isActive: PropTypes.bool,
  size: PropTypes.number,
};

IconWallet.defaultProps = {
  activeColor: config.colors.rbYellow,
  color: config.colors.rbWhite,
  isActive: false,
  size: 16,
};

export default IconWallet;