import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconFacebook = props => (
  <svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 90 90" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="icons/facebook" fill={!props.isActive ? props.color : props.activeColor}>
      <path d="M90,15.001C90,7.119,82.884,0,75,0H15C7.116,0,0,7.119,0,15.001v59.998
        C0,82.881,7.116,90,15.001,90H45V56H34V41h11v-5.844C45,25.077,52.568,16,61.875,16H74v15H61.875C60.548,31,59,32.611,59,35.024V41
        h15v15H59v34h16c7.884,0,15-7.119,15-15.001V15.001z"/>
    </g>
  </svg>
);

IconFacebook.propTypes = {
  color: PropTypes.string,
  activeColor: PropTypes.string,
  isActive: PropTypes.bool,
  size: PropTypes.number,
};

IconFacebook.defaultProps = {
  activeColor: config.colors.rbYellow,
  color: config.colors.rbWhite,
  isActive: false,
  size: 16,
};

export default IconFacebook;