import React, { Component, Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import GridCol from '../../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../../MasterComponents/Grid/GridRow';
import InputLabel from '../../../../MasterComponents/InputLabel/InputLabel';
import Bullet from '../../../../MasterComponents/Bullet/Bullet';
import Button from '../../../../MasterComponents/Button/Button';

class FormPorchDoorSuccessfully extends Component {
    done = () => {
        window.location.reload();
    }
    render() {
        const props = this.props;
        const header = <Fragment>
            <GridRow>
                <GridCol className='signup-header-sucessfully'>
                    <Bullet/>
                </GridCol>
            </GridRow>      
        </Fragment>

        if (props.isBuyersEdge && !props.isDA) {
            return (
                <GridRow minWidth='100%'>
                    <GridCol>
                        {isMobile ? header : null}
                        <GridRow>
                            <GridCol className={isMobile ? 'text-center': null}>
                                <InputLabel className='signup-title-sucessfully'>That's it! You are signed up!</InputLabel>
                            </GridCol>
                        </GridRow>
                        <GridRow>
                            <GridCol className={isMobile ? 'text-center': null}>
                                <InputLabel className='signup-body-words'>Your associated platform member will be in contact and get you onboarded and start collecting your rebates...</InputLabel><br />
                            </GridCol>
                        </GridRow>
                        <GridRow className='mt-3'>
                            <GridCol size={6} className={isMobile ? 'text-center': null}>
                                <Button type='button' variant='blue be-blue-border be-blue-bg' size='' text='Done' onClick={this.done} />
                            </GridCol>
                        </GridRow>
                    </GridCol>
                </GridRow>
            )
        }
        return (
            <GridRow minWidth='100%'>
                <GridCol>
                    {isMobile ? header : null}
                    <GridRow>
                        <GridCol className={isMobile ? 'text-center': null}>
                            <InputLabel className='signup-title-sucessfully'>Thank you <span className='text-uppercase'>{props.step1Inputs.firstName} {props.step1Inputs.lastName}!</span></InputLabel>
                        </GridCol>
                    </GridRow>
                    <GridRow>
                        <GridCol className={isMobile ? 'text-center': null}>
                            <InputLabel className='signup-body-words'>Your information for <span className='text-uppercase'>{props.step1Inputs.businessName}</span> has been submitted successfully.</InputLabel><br />                           
                            <InputLabel className='signup-body-words'>Once we have processed your information, we will send an activation link to your email that you can use to log in.</InputLabel>
                        </GridCol>
                    </GridRow>
                </GridCol>
            </GridRow>
        )
    }
}

export default FormPorchDoorSuccessfully;
