import React from 'react';
import { Col, Row, Button } from 'reactstrap';
import Modal from '../../../../MasterComponents/Modals/Modal';
import ModalBody from '../../../../MasterComponents/Modals/ModalBody';
import ModalFooter from '../../../../MasterComponents/Modals/ModalFooter';
import imgFailed from '../../../../assets/images/imagesfailed.svg';
import { findModal } from '../../../../MasterComponents/Modals/ModalsActions';

const ContractFailedModal = ({modals}) => {
	const close = () => {
		findModal(modals, 'contractFailedModal').close();
	};
	return (
		<Modal size='sm' id='contractFailedModal'>
			<ModalBody>
				<Row className='my-5'>
					<Col className='text-center'>
						<img src={imgFailed} alt='failed' />
					</Col>
				</Row>
				<Row className='my-4'>
					<Col className='text-center text-size-18 font-weight-bold'>
						Something Went Wrong
					</Col>
				</Row>
				<Row>
					<Col className='text-center text-size-14'>
						Please contact your sales representative to modify your contracts
					</Col>
				</Row>
			</ModalBody>
			<ModalFooter>
				<Button color='primary' size='sm' block className='py-2 shadow-none active' onClick={close}>
					GOT IT
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default ContractFailedModal;