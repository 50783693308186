import React, { Component } from 'react';
import PrimeProgramsContent from './PrimeProgramsContent';
class PrimePrograms extends Component {
  render() {
    const { props } = this;
    return (
      <PrimeProgramsContent {...props} />
    );
  }
}

export default PrimePrograms;