import React, { Fragment, useEffect } from 'react';
import ReactGA from 'react-ga';
import DistributorCardContent from './DistributorCardContent';
import { showModal } from '../../../MasterComponents/Modals/ModalsActions';
import ActivateDistributorModal from '../../../views/Dashboard/Modals/ActivateDistributorModal';

const DistributorCard = props => {
    const { locationName, location, companyColors, dispatch, companyId, merchantAll, merchantGroup, history, refresh } = props;

    const showActivateDistributor = (merDist, isEdit) => {
      ReactGA.event({
        category: isEdit ? 'Edit Distributor' : 'Click Button',
        action: 'Click Activate Distributor'
      });
      dispatch(showModal(ActivateDistributorModal, 'activateDistributorModal', { merchantDistributor: merDist, refresh: refresh, isEdit: isEdit }));
    };

    useEffect(() => {
        if (location.state) {
          if (location.state.action === 'ADD_DISTRIBUTOR') {
            refresh((merchantGroup) => {
              const merchant = merchantGroup.filter(
                mer => mer.merchantDistributors.some(
                    merDist => merDist.distributorId === location.state.distId && merDist.merchantId === location.state.locations[0] && !merDist.isActive
                )
              )[0];

              if (merchant) {
                const merchantDistributor = merchant.merchantDistributors.filter(merDist => merDist.distributorId === location.state.distId)[0]
                if (merchantDistributor){
                  showActivateDistributor(merchantDistributor);
                }
              }
            });
          }
        }

    }, [])

    const propsLocation = {
      locationName,
      dispatch,
      companyColors,
      companyId,
      merchantAll,
      history,
      refresh
    };

    const merchantGroupMap = merchantGroup.map((m, i) => (
      <DistributorCardContent key={i} groupId={i} {...propsLocation} locationAddress={`${m.city}, ${m.state}`} locationName={m.nickname} merchant={m} merchantDistributors={m.merchantDistributors} merchantAll={merchantAll} />
    ));

    return (
      <Fragment>
        { merchantGroupMap }
      </Fragment>
    )
};

export default DistributorCard;

