import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

const Spinner = () => {
  const isHome = window.location.pathname === '/dashboard/home';
  
  const spinner = useSelector(({spinner}) => spinner.spinner);
  const blueDots = (
    <Fragment>
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </Fragment>
  );
  if (spinner && !isHome) {
    return (
      <div className="mask">
        <div className="spinner">
          {blueDots}
        </div>
      </div>
    );
  } else {
    return (
      <div className="mask hide">
        <div className="spinner" />
      </div>
    );
  }
};

export default Spinner;
