import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { showModal } from '../../../MasterComponents/Modals/ModalsActions';
import NewCardModal from '../Modals/NewCardModal';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import { getFAQHowToLinkClass } from '../../../config/Company/CompanyHelper';

class NewCard extends Component {

  onClickMore = (resource) => {
    const { dispatch } = this.props;
    if (resource.type === 'video') {
      dispatch(showModal(NewCardModal, 'idNewCardModal', { resource: resource }));
    } else {
      window.open(resource.url);
    }
  }

  render() {
    const { newAll, companyId } = this.props;
    const FAQHowToLinkClass = getFAQHowToLinkClass(companyId)
    const newArray = newAll.map((n, i) => (
      <Col xs={isMobile ? 12 : 6} key={`new_${i}`} className='py-2'>
        <Row>
          <Col xs={isMobile ? 12 : 6} className='py-2 mask-youtube1'>
          { n.type === 'video' ?
            <>
              <iframe
                width='100%' 
                height='200px' 
                src={n.url}
                title={n.title} 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen>
              </iframe>
              <div className='faq-mask-youtube clickable' onClick={()=> this.onClickMore(n)}></div>
            </>
            :
            <img src={n.cover_image} alt='article cover' width='100%' style={{ objectFit: "cover" }} />
          }
          </Col>
          <Col xs={isMobile ? 12 : 6}>
            <Row>
              <Col className='text-size-14 line-height-24 text-bold pt-2'>
                {n.title}
              </Col>
            </Row>
            <Row>
              {n.description &&
                <Col className={'text-size-14 line-height-20 text-grey ' + (isMobile ? 'py-1' : 'py-3')}>
                  {ReactHtmlParser(n.description)}
                </Col>}
            </Row>
            <Row>
              <Col className={'text-blue text-bold text-size-14 line-height-17 py-2 clickable text-uppercase ' + FAQHowToLinkClass} onClick={()=> this.onClickMore(n)}>
                {n.type === 'video' ? 'Watch Now' : 'Learn More'}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    ));

    return (
      <Col xs={isMobile ? 12 : 12} className={(isMobile ? 'mx-0 py-2' : 'py-2')} style={{ width: 'auto' }}>
        <Row>
          {newArray}
        </Row>
      </Col>
    )
  }
};

const mapStateToProps = (state) => {
  return {
    companyId: state.company.id
  };
};

export default connect(mapStateToProps)(NewCard);
