import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import LogoMainCompany from '../../../MasterComponents/Logos/LogoMainCompany';
import { FaLongArrowAltRight } from 'react-icons/fa';
import useJoinSignup from '../hooks/useJoinSignup';
import JoinSignupFormSmall from './JoinSignupFormSmall';
import JoinSignupFormBig from './JoinSignupFormBig';
import JoinSignupSucceed from './JoinSignupSucceed';

const JoinSignup = ({history, scrollTop}) => {
	const {showFullForm, showSucceed, ...form} = useJoinSignup(history, scrollTop);
	return (
		<Container fluid className={!showFullForm ? 'bg-warning' : 'bg-primary'}>
			<Container className='mw-1600'>
				<Row className='text-white'>
					<Col className='py-5' md={12} lg={6}>
						<Row className='py-3'>
							<Col><LogoMainCompany className='mw-100'/></Col>
						</Row>
						{!showFullForm ?
							<>
								<Row className='mt-4'>
									<Col className='text-title'>
										BECOME A DINING ALLIANCE MEMBER FOR FREE AND EARN CASHBACK ON 165,000 LINE ITEMS & PRODUCTS
									</Col>
								</Row>
								<Row className='mt-4'>
									<Col>
										Dining Alliance is the #1 restaurant buying group in the industry. We enable restaurants to save on over 165,000 rebated line items and products, many of which they’re already buying but at higher prices. Joining Dining Alliance is always 100% FREE. Find out for yourself why 65,000+ other restaurants trust Dining Alliance to deliver savings and expertise for free.
									</Col>
								</Row>
								<Row className='mt-4'>
									<Col>
										<Button color='action'>
											BECOME A MEMBER FOR FREE <FaLongArrowAltRight className='ml-2' />
										</Button>
									</Col>
								</Row>
							</>
							:
							<>
								<Row className='mt-4'>
									<Col className='text-title'>
										<u><i>WAIT! YOUR MEMBERSHIP IS NOT COMPLETE</i></u> - FILL OUT THIS SHORT FORM TO FINISH YOUR SIGNUP TODAY!
									</Col>
								</Row>
								<Row className='mt-4'>
									<Col className="video-container">
										<video src="https://buyersedgeplatform.com/wp-content/uploads/2021/03/Incomplete-Sign-Up.mp4" controls controlslist="nodownload" loop="true" poster="https://diningalliance.com/wp-content/uploads/2021/04/DA-1-STEP.png"></video>
									</Col>
								</Row>
								<Row className='mt-4'>
									<Col>
										<p>You’re just one final step from becoming a Dining Alliance member, for FREE! Fill out this short form here, and we will create your portal account. Upon completing this form, your myDining Alliance portal account will be created. With your portal account you will be able to:</p>
										<ul>
											<li>Accrue CashBack on over 165,000+ rebated line items</li>
											<li>Get insights into your restaurants monthly spend broken down by category, manufacturer and much more</li>
											<li>Access our large selection of Beyond Broadline Programs – discounts and savings on Skechers, DirecTV, Chef Works, etc</li>
											<li>and much more</li>
										</ul>
										<p>Simply fill out this form, and you’ll be on your way to earning FREE CashBack on items your restaurant purchases everyday!</p>
									</Col>
								</Row>
							</>
						}
					</Col>
					<Col className='bg-join-signup py-5 px-3'>
						<Row className='mx-lg-5 mx-md-2 py-3 pb-5 px-lg-3 px-md-1'>
							{
								!showFullForm ?
									<JoinSignupFormSmall {...form} />
								:
									(
										showSucceed ?
											<JoinSignupSucceed />
										:
											<JoinSignupFormBig {...form} />
									)
							}
						</Row>
					</Col>
				</Row>
			</Container>
		</Container>
	)
};

export default JoinSignup;