import React, { Component } from 'react';
import { connect } from 'react-redux';
import removeToken from '../../config/tokenApi';
import { spinner } from '../../MasterComponents/Spinner/SpinnerActions';

class Logout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: ''
        }

    }
    componentDidMount = () => {
        this.setState({
            email: ''
        });
        this.logout();
    }

    logout = () => {
        this.props.dispatch(spinner(false));
        localStorage.removeItem('userid');
        localStorage.removeItem('username');
        localStorage.removeItem('email');
        localStorage.removeItem('phoneNumber');
        localStorage.removeItem('authKey');
        localStorage.removeItem('activeMerchant');
        localStorage.setItem('isLoggedIn',false);
        localStorage.removeItem('displayFoodbam');
        localStorage.removeItem('isFoodbamActive');
        localStorage.removeItem('showInsideTrackModal');
        localStorage.removeItem('authKeyAnalyst');
        localStorage.removeItem('authKeyAdmin');
        localStorage.clear();
        removeToken();
        // if (this.props.msalContext && this.props.msalContext.instance) {
        //     this.props.msalContext.instance.logoutRedirect({
        //       account: this.props.msalContext.instance.getAccountByHomeId(),
        //       postLogoutRedirectUri: '/',
        //       mainWindowRedirectUri: '/'
        //     });
        // }
        this.props.history.push('/login');
    }

    render() {
        return (
            <div>

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {};
  }

export default connect(mapStateToProps)(Logout);
