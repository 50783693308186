import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconInformation = (props)=>(
    <svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Designs" stroke="none" fill="none">
        <g id="2---Sign-Up---Step-1" transform="translate(-722.000000, -344.000000)" fill={!props.isActive ? props.color : props.activeColor}>
            <g id="input" transform="translate(580.000000, 344.000000)">
                <g id="icons/info" transform="translate(142.000000, 0.000000)">
                    <path d="M11.9733333,5.92 C11.928,2.62133333 9.21866667,-0.016 5.92,0.0266666667 C2.62133333,0.0693333333 -0.016,2.78133333 0.0266666667,6.08 C0.072,9.37866667 2.78133333,12.016 6.08,11.9733333 C9.37866667,11.928 12.0186667,9.21866667 11.9733333,5.92 Z M6,2.15733333 C6.472,2.15733333 6.85333333,2.53866667 6.85333333,3.01066667 C6.85333333,3.48266667 6.472,3.864 6,3.864 C5.528,3.864 5.14666667,3.48266667 5.14666667,3.01066667 C5.14666667,2.53866667 5.528,2.15733333 6,2.15733333 Z M7.17333333,9.70666667 L4.82666667,9.70666667 L4.82666667,9.41333333 L5.41333333,9.41333333 L5.41333333,5.14666667 L4.82666667,5.14666667 L4.82666667,4.82666667 L6.58666667,4.82666667 L6.58666667,9.41333333 L7.17333333,9.41333333 L7.17333333,9.70666667 Z" id="Shape"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
);


IconInformation.propTypes = {
    color: PropTypes.string,
    activeColor: PropTypes.string,
    isActive: PropTypes.bool,
    size: PropTypes.number,
  };
  
  IconInformation.defaultProps = {
    activeColor: config.colors.rbYellow,
    color: config.colors.rbWhite,
    isActive: false,
    size: 22,
  };
  
  export default IconInformation;