import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import Button from '../../../../MasterComponents/Button/Button';
import GridCol from '../../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../../MasterComponents/Grid/GridRow';
import InputLabel from '../../../../MasterComponents/InputLabel/InputLabel';
import Modal from '../../../../MasterComponents/Modals/Modal';
import ModalFooter from '../../../../MasterComponents/Modals/ModalFooter';
import ModalHeader from '../../../../MasterComponents/Modals/ModalHeader';
import { findModal } from '../../../../MasterComponents/Modals/ModalsActions';
import SearchInput from '../../../../MasterComponents/SearchInput/SearchInput';
import { spinner } from '../../../../MasterComponents/Spinner/SpinnerActions';
import DashboardApi from '../../../Dashboard/DashboardApi';
import DistributorStepAddNewModalDistributors from './DistributorStepAddNewModalDistributors';
import DistributorStepAddNewModalLocations from './DistributorStepAddNewModalLocations';

const DistributorStepAddNewModal = ({dropdown, search, locations, dispatchState, dropdownHouse, validate, modals, locationsInvalid, refresh}) => {
  const dispatch = useDispatch();
  const [showLocations, setShowLocations] = useState(false);

  useEffect(() => {
    dispatchState({
      type: 'RESET',
    });
    //eslint-disable=next-line
  }, []);

  const onAdd = () => {
    if (validate()) {
      dispatch(spinner(true));
      const params = {
        signedText: '',
        distId: (dropdownHouse.value && dropdownHouse.value.id) || dropdown.value.id,
        locations: locations.filter(loc => loc.check).map(loc => loc.id)
      };
      DashboardApi.addDistributor(params).then(() => {
        dispatch(spinner(false));
        findModal(modals, 'addNewDistributorModal').close();
        refresh(params.distId);
      }).catch(err => {
        dispatch(spinner(false));
        console.error(err);
      });
    }
  };

  const onBack = () => {
    setShowLocations(false);
    dispatchState({
      type: 'RESET',
    });
  };

  const selectDistributor = (dist) => {
    setShowLocations(true);
    dispatchState({
      type: 'SELECT_DISTRIBUTOR',
      payload: dist
    });
    dispatchState({
      type: 'UPDATE_SEARCH',
      payload: dist.label
    });
  };

  const locationsMap = locations.filter(loc => !loc.isEmpty).map((loc) => (
    <GridRow className='mt-3' key={`loc_${loc.index}`}>
      <GridCol>
        <div className="rb-checkbox custom-control custom-checkbox top-0">
          <input onChange={() => dispatchState({type: 'CHECK_LOCATIONS', payload: loc.index})} type='checkbox' id={`loc_${loc.index}`} className='custom-control-input' checked={loc.check} />
          <label className='custom-control-label' htmlFor={`loc_${loc.index}`}>{loc.name}</label>
        </div>
      </GridCol>
    </GridRow>
  ));

  if (isMobile) {
    return (
      <Modal fullHeight size='100' id='addNewDistributorModal'>
        <ModalHeader blueHeader noBorder>Add New Distributor</ModalHeader>
        <ModalHeader noBorder className='align-items-start'>
          <InputLabel text='Distributor Name *' className='w-100 text-left' />
          <SearchInput {...search} disabled={showLocations} />
        </ModalHeader>
        {
          !showLocations ?
            <DistributorStepAddNewModalDistributors dropdown={dropdown} search={search} selectDistributor={selectDistributor} />
          :
            <>
              <DistributorStepAddNewModalLocations locationsMap={locationsMap} dropdownHouse={dropdownHouse} locationsInvalid={locationsInvalid} />
              <ModalFooter>
                <GridCol className='px-0'>
                  <Button text={'BACK'} variant='yellow-outline' onClick={onBack} />
                </GridCol>
                <GridCol className='px-0'>
                  <Button text={'ADD'} variant='yellow' onClick={onAdd} />
                </GridCol>
             </ModalFooter>
            </>
        }
      </Modal>
    )
  }

  return (
    <Modal fullHeight={isMobile} size={isMobile ? '100' : 'sm'} id='addNewDistributorModal'>
      <ModalHeader blueHeader={isMobile} noBorder={isMobile}>Add New Distributor</ModalHeader>
      <DistributorStepAddNewModalDistributors dropdownHouse={dropdownHouse} dropdown={dropdown} search={search} locationsMap={locationsMap} locationsInvalid={locationsInvalid} />
      <ModalFooter>
        <GridCol className='px-0'>
          <Button text={'ADD DISTRIBUTOR'} variant='yellow' onClick={onAdd} />
        </GridCol>
      </ModalFooter>
    </Modal>
  );
};

export default DistributorStepAddNewModal;