import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import HiddenField from './HiddenField';

const HiddenFieldList = () => {
  const merchantParentPlatformId = useSelector(({dashboard}) => (
    dashboard && 
    dashboard.activeMerchant && 
    dashboard.activeMerchant.platformId
  ) || '');

  const parentMerchantId = useSelector(({dashboard}) => (
    dashboard && 
    dashboard.activeMerchant && 
    dashboard.activeMerchant.id
  ) || '');

  const isAdmin = localStorage.getItem('authKeyAdmin');

  return (
    <Fragment>
      <HiddenField value={parentMerchantId} selectorId='merchant-id' />
      <HiddenField value={merchantParentPlatformId} selectorId='platform-id' />
      <HiddenField value={isAdmin ? '1' : '0'} selectorId='is-admin' />
    </Fragment>
  );
};

export default HiddenFieldList;