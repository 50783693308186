import React, { Component } from 'react';
import PrimeProgramsBody from './PrimeProgramsBody';
import PrimeProgramsTop from './PrimeProgramsTop';
import PrimeProgramsBottom from './PrimeProgramsBottom';
import { isMobile } from 'react-device-detect';

const propsPrograms = {
    isSliced: false
}
class PrimeProgramsContent extends Component {
  render() {
    return (
      <div className='p-0'>
        <PrimeProgramsTop />
        <PrimeProgramsBody {...propsPrograms} />
        {
            isMobile ? null  :  <PrimeProgramsBottom /> 
        }
      </div>
    );
  }
}

export default PrimeProgramsContent;