import React, { Component, Fragment } from 'react';
import moment from 'moment';
import Card from '../../../MasterComponents/Card/Card';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import IconEdit from '../../../MasterComponents/Icons/IconEdit';
import IconFolder from '../../../MasterComponents/Icons/IconFolder';
import IconDelete from '../../../MasterComponents/Icons/IconDelete';
import config from '../../../config/config';
import Button from '../../../MasterComponents/Button/Button';
class DistributorCardOneMobile extends Component {
  render() {
    const {
      md, isOrange, statusOnlineColor,
      statusEditColor, statusCredentialsColor, statusOnlineIcon,
      goToEditDistributor, onClickUploadNow, onClickActivate,
      clickActivateEdit, clickActivateDelete, onClickEditCredentials, showHistory
    } = this.props;
    const styleButton = {position:'absolute', bottom: '20px', left: '0', right: '0'};

    return (
      <Card className={'px-0 py-0 distributor-card mobile border' + (isOrange ? ' orange' : '')} onClick={goToEditDistributor} size='pb-2 px-0 col-12'>
        <GridRow className='pb-3 text-grey text-size-14 clickable' style={{height: '17px'}}>
          { (md.submissionType === 'online' || md.submissionType === 'auto') && 
            <GridCol onClick={clickActivateEdit}  style={{color: statusEditColor}} className='mw-fc'>
              <IconEdit color={statusEditColor} /> Edit
            </GridCol>
          }
          { md.submissionType === 'manual' && 
            <GridCol onClick={clickActivateEdit}  style={{color: statusEditColor}} className='mw-fc'>
              <IconEdit color={isOrange ? config.colors.rbWhite : config.colors.rbDarkerGrey} /> Edit
            </GridCol>
          }
          <GridCol onClick={clickActivateDelete}  style={{color: statusEditColor}} className='mw-fc'>
            <IconDelete color={isOrange ? config.colors.rbWhite : config.colors.rbDarkerGrey} /> Delete
          </GridCol>
          { md.submissionType === 'manual' && 
            <GridCol className='text-right' onClick={showHistory}>
              <IconFolder color={isOrange ? config.colors.rbWhite : config.colors.rbDarkerGrey} /> Submission History
            </GridCol>
          }
        </GridRow>
        <GridRow className='py-0 mt-3'>
          <GridCol>
            <h5 className='font-weight-bolder'>{md.distributor}</h5>
          </GridCol>
        </GridRow>
        { ((md.isActive === 1 && md.submissionType !== 'auto') || md.statusType === 'backupSuggested' || md.statusType === 'backupRequired') &&
          <Fragment>
            <GridRow>
              <GridCol>
                DCN
              </GridCol>
              <GridCol className='text-right'>
                { md.dcn }
              </GridCol>
            </GridRow>
            <GridRow className='mt-2 no-gutters text-size-14 line-height-20 text-grey text-weight-bold'>
              <GridCol>
                Submission Type
              </GridCol>
              <GridCol className='text-right'>
                {md.submissionType === 'manual' ? 'Manual Upload' : (md.submissionType === 'auto' ? 'Auto' : (md.submissionType === 'online' ? 'Online Ordering' : "I Don't Know Yet"))}
              </GridCol>
            </GridRow>
            { md.submissionType === 'manual' &&
              <GridRow className='text-size-14 line-height-20 text-grey text-weight-bold'>
                <GridCol>
                  Last Proof Of Purchase Submission
                </GridCol>
                <GridCol>
                    {moment(md.lastLoadDate).isValid() ? moment(md.lastLoadDate).format('M-DD-YY') : (!md.lastLoadDate ? 'No Data' : md.lastLoadDate)}
                </GridCol>
              </GridRow>
            }
            {  (md.submissionType === 'online' || (md.statusType === 'backupSuggested' || md.statusType === 'backupRequired') || md.isActive) &&
              <GridRow className='text-size-14 line-height-20 text-grey text-weight-bold'>
                <GridCol>
                  Status
                </GridCol>
                <GridCol size={9} className='text-right' style={{color: statusOnlineColor}}>
                  {statusOnlineIcon}
                  &nbsp;{((md.statusMessage && md.statusMessage === 'Processing...') ? 'Pending...' : md.statusMessage) || ((md.status === null && md.statusType === null) && 'Pending...')}
                </GridCol>
              </GridRow> 
            }
            { md.submissionType === 'manual' &&
              <GridRow className='text-size-14 line-height-20 text-grey text-weight-bold'>
                <GridCol>
                  Next Scheduled Submission
                </GridCol>
                <GridCol className='text-right'>
                  {moment(md.nextLoadDate).isValid() ? moment(md.nextLoadDate).format('M-DD-YY') : (!md.nextLoadDate ? 'No Data' : md.nextLoadDate)}
                </GridCol>
              </GridRow>
            }
            { md.submissionType === 'manual' &&
              <GridRow className='pt-0'>
                <GridCol>
                  <Button size='sm' variant='white' onClick={onClickUploadNow} className='text-capitalize w-100' text='Upload Now' />
                </GridCol>
              </GridRow>
            }
            { md.submissionType !== 'auto' && md.statusType !== 'backupSuggested' && md.statusType !== 'backupRequired' &&
              <GridRow className='pt-0'>
                <GridCol>
                  <Button size='lg' variant='white' onClick={onClickEditCredentials} className={'text-capitalize w-100 ' + (statusCredentialsColor ? 'btn-red-online' : '')} text='RECONNECT'/>
                </GridCol>
              </GridRow>
            }
          </Fragment>
        }
        {  md.submissionType === 'auto' &&
          <Fragment>
            <GridRow>
              <GridCol>
                DCN
              </GridCol>
              <GridCol className='text-right'>
                { md.dcn }
              </GridCol>
            </GridRow>
              <GridRow className='mt-2 text-size-14 line-height-20 text-grey text-weight-bold'>
                <GridCol>
                  Great news, we are auto connected to this distributor and will collect your Cash Back for you.
                </GridCol>
              </GridRow>
              <GridRow className='mt-1 text-size-14 line-height-20 text-grey text-weight-bold'>
                <GridCol>
                  Submission Type
                </GridCol>
                <GridCol className='text-right'>
                    <span className='font-weight-bold'>Auto</span>
                </GridCol>
              </GridRow>
          </Fragment>
        }
        { !md.isActive && md.submissionType !== 'auto' && md.statusType !== 'backupSuggested' && md.statusType !== 'backupRequired' &&
          <Fragment>
            {
              md.dcn &&
              <GridRow>
                <GridCol>
                  DCN
                </GridCol>
                <GridCol className='text-right'>
                  { md.dcn }
                </GridCol>
              </GridRow>
            }
            <GridRow className='mt-2'>
              <GridCol className='text-size-14 text-line-height-20 text-grey text-weight-bold'>
                Activate this distributor to start getting your cash back
              </GridCol>
            </GridRow>
            <GridRow style={styleButton}>
              <GridCol>
                <Button onClick={onClickActivate} className='w-100 clickable' variant='primary' size='lg' text='CONNECT'></Button>
              </GridCol>
            </GridRow>
          </Fragment>
        }
        {
          (md.statusType === 'failedPassword' && !md.isActive) &&
          <GridRow style={styleButton}>
            <GridCol>
              <Button size='lg' variant='white' onClick={onClickEditCredentials} className={'text-capitalize w-100 ' + (statusCredentialsColor ? 'btn-red-online' : '')} text='RECONNECT'/>
            </GridCol>
          </GridRow>
        }
        {
          (md.statusType === 'failedDcn' && !md.isActive) &&
          <GridRow style={styleButton}>
            <GridCol>
              <Button size='lg' variant='white' onClick={onClickActivate} className={'text-capitalize w-100 ' + (statusCredentialsColor ? 'btn-red-online' : '')} text='RECONNECT'/>
            </GridCol>
          </GridRow>
        }
        {
          (md.statusType === 'backupSuggested' || md.statusType === 'backupRequired') &&
          <GridRow style={styleButton}>
            <GridCol>
              <Button onClick={onClickActivate} className='w-100 clickable' variant='white' size='lg' text='Provide Backup'></Button>
            </GridCol>
          </GridRow>
        }
      </Card>
    )
  }
}

export default DistributorCardOneMobile;
