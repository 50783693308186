import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { CardElement, injectStripe, Elements } from 'react-stripe-elements';
import '../FoodBam/FoodBamPaymentDetails.scss';
import GridContainer from '../../MasterComponents/Grid/GridContainer';
import GridRow from '../../MasterComponents/Grid/GridRow';
import GridCol from '../../MasterComponents/Grid/GridCol';
import Step from '../../MasterComponents/Step/Step';
import Dash from '../../MasterComponents/Dash/Dash';
import Bullet from '../../MasterComponents/Bullet/Bullet';
import InputLabel from '../../MasterComponents/InputLabel/InputLabel';
import Button from '../../MasterComponents/Button/Button';
import { spinner } from '../../MasterComponents/Spinner/SpinnerActions';

class _CreditCardForm extends Component {
  state = {
    isLoading: false,
    errorMessage: '',
  };

  handleChange = ({error}) => {
    this.setState({errorMessage: (error && error.message) || ''});
  }

  handleSubmit = (evt) => {
    evt.preventDefault();

    const { dispatch } = this.props;
    dispatch(spinner(true));
    if (this.props.stripe) {
      this.props.stripe.createToken().then((payload) => {
        dispatch(spinner(false));
        if (!payload.error) {
          this.props.handleResult(payload);
        }
      });
    }
  }

  render() {
    const errorMessage = !this.state.errorMessage ? this.props.errorMessage : this.state.errorMessage;

    const options = {
      style: {
        base: {
          color: '#202020',
          lineHeight: '17px',
          fontSmoothing: 'antialiased',
          fontSize: '15px',
          '::placeholder': {
            color: '#76777b'
          }
        },
        invalid: {
          color: '#dc3545',
          iconColor: '#dc3545'
        }
      }
    };
    return (
      <form onSubmit={this.handleSubmit}>
        <label>
          Card details
          <CardElement
            onChange={this.handleChange}
            hidePostalCode={true}
            {...options}
          />
        </label>
        <InputLabel className='text-red text-size-14'>{errorMessage}</InputLabel>
        <Button type='submit' variant='primary' size='' text='SAVE' className='my-4' />
      </form>
    );
  }

}

const CreditCardForm = injectStripe(_CreditCardForm);

class FoodBamPaymentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleResult = (payload) => {
    this.props.nextStepHandler(payload.token.id);
  }

  render() {
    return (
      <div className='foodbam-payment-details'>
        <GridContainer className='mb-5' isFluid={false}>
          <GridRow>
            <GridCol>
              <h1 className='m-5'>START ORDERING ONLINE</h1>
            </GridCol>
          </GridRow>
          <GridRow>
            <GridCol className='steps mt-3 mb-5'>
              <Step status={'done'} text={1} />
              <Dash className='mx-5' />
              <Step status={'done'} text={2} />
              <Dash className='mx-5' />
              <Step status={'active'} text={3} />
            </GridCol>
          </GridRow>

          <GridRow align='center' justify='center'>
            <GridCol className={(!isMobile ? 'col-6' : 'col-12')}>
              <div className={(!isMobile ? 'mr-5' : '')}>
                <p className='enter-payment-detail'>Enter payment details:</p>

                <Elements>
                  <CreditCardForm handleResult={this.handleResult} dispatch={this.props.dispatch} errorMessage={this.props.errorMessage} />
                </Elements>

              </div>
            </GridCol>
            <GridCol className={(!isMobile ? 'col-6 border-left' : 'col-12')}>
              <div className={(!isMobile ? ' ml-5' : '')}>
                <h3 className='mb-4'><del>$149</del> <em>$99/month</em></h3>
                <p className='mb-4'>Save time and money with the complete back-of-house technology solution for restaurants.</p>
                <div className='bullets'>
                  <Bullet text='Use FoodBAM to order from all your vendors' />
                  <Bullet text='Conduct recipe-costing' />
                  <Bullet text='Track your inventory' />
                  <Bullet text='Analyze your purchase data' />
                </div>
              </div>
            </GridCol>
          </GridRow>
        </GridContainer>

      </div>
    );
  }

}

FoodBamPaymentDetails.propTypes = {
  nextStepHandler: PropTypes.func,
  errorMessage: PropTypes.string
};

const mapStateToProps = state => {
  return {};
}

export default connect(mapStateToProps)(FoodBamPaymentDetails);
