import { apiInstance } from '../../config/tokenApi';

const getNotifications = () => {
  return apiInstance.get('/notification');
}

const readNotification = (id, params) => {
  return apiInstance.patch(`/notification/${id}`, params);
}

const deleteNotification = (id) => {
  return apiInstance.delete(`/notification/${id}`);
}

export default {
  getNotifications,
  readNotification,
  deleteNotification
}
