import React, { Component } from 'react';
import '../SignUp.scss';
import Bullet from '../../../MasterComponents/Bullet/Bullet';

class SignUpPorchDoorChannelSidebar extends Component {
    goTo = (e) => {
        e.preventDefault();
        localStorage.setItem('isSignUpRoute', 0);
        this.props.history.push('/');
    };
    getLogo = () => {
        const { abbreviation } = this.props;
        const path = 'https://rebate-portal.s3.amazonaws.com/lops/';
        return `${path}${abbreviation}-white.png`;
    }
    getLogoPartner = () => {
        const { abbreviation } = this.props;
        const path = 'https://rebate-portal.s3.amazonaws.com/lops/';
        return `${path}${abbreviation}-white.png`;
    }
    getStep1Bullets = () => {
        const { abbreviation, description } = this.props;

        const BEPartnership = [
            `As part of the Partnership between Buyers Edge Platform and ${description}, you qualify for a special Manufacturer Appreciation Program.`,
            "Join 55,000+ other restaurants and start earning cash back from all your restaurant's purchases",
            'Get Access to an Additional Portfolio of Beyond Prime Distribution Savings Programs',
            'Get Data Insights into Your Restaurant Purchases'
        ];

        const DAPartnership = [
            `As part of the Partnership between Dining Alliance and ${description}, you qualify for a special Manufacturer Appreciation Program.`,
            "Join 55,000+ other restaurants and start earning cash back on your restaurant's purchases",
            'Get Access to an Additional Portfolio of Beyond Prime Distribution Savings Programs',
            'Get Data Insights into Your Restaurant Purchases'
        ];

        const noDA = [
            "Join 55,000+ other restaurants and start earning cash back on your restaurant's purchases",
            'Get Access to an Additional Portfolio of Beyond Prime Distribution Savings Programs',
            'Get Data Insights into Your Restaurant Purchases'
        ];

        const rcg = [
            `As part of the Partnership between Dining Alliance and Restaurant Cashback Group, you qualify for a special Manufacturer Appreciation Program.`,
            "Join 55,000+ other restaurants and start earning cash back on your restaurant's purchases",
            'Get Access to an Additional Portfolio of Beyond Prime Distribution Savings Programs',
            'Get Data Insights into Your Restaurant Purchases'
        ];
        
        const bestwestern = [
            `As part of the Best Western Food & Beverage MarketPlace, you qualify for a special Manufacturer Appreciation Program.`,
            "Join 55,000+ other restaurants and start earning cash back from all your hotel's purchases",
            'Get Data Insights into Your Food & Beverages Purchases'
        ];

        const aifd = [
            `As part of the Partnership between Buyers Edge Platform and Association of Independent Food Distributors LLC, you qualify for a special Manufacturer Appreciation Program.`,
            "Join 55,000+ other restaurants and start earning cash back from all your restaurant's purchases",
            'Get Access to an Additional Portfolio of Beyond Prime Distribution Savings Programs',
            'Get Data Insights into Your Restaurant Purchases'
        ];

        const ram = [
            `As part of the Partnership between Dining Alliance and Restaurant Association of Maryland, you qualify for a special Manufacturer Appreciation Program.`,
            "Join 55,000+ other restaurants and start earning cash back from all your restaurant's purchases",
            'Get Access to an Additional Portfolio of Beyond Prime Distribution Savings Programs',
            'Get Data Insights into Your Restaurant Purchases'
        ];

        const S1PPartnership = [
            `As part of the Partnership between Source1 Purchasing and ${description}, you qualify for a special Manufacturer Appreciation Program.`,
            "Join 55,000+ other restaurants and start earning cash back from all your purchases",
            'Get Access to an Additional Portfolio of Beyond Prime Distribution Savings Programs',
            'Get Data Insights into Your Restaurant Purchases'
        ];

        const fsd = [
            `For Food Service Businesses Only; Consumers are Not Eligible`,
            `Are you a Food Service Operator? Then as part of the Partnership between Buyers Edge Platform and Food Service Direct, you qualify for a special Manufacturer Appreciation Program.`,
            `Join 55,000+ other restaurants and start earning cash back from all your restaurant's purchases`,
            `Get Access to an Additional Portfolio of Beyond Prime Distribution Savings Programs`,
            `Get Data Insights into Your Restaurant Purchases`
        ];

        const brady = [
            `As part of the Partnership between Buyers Edge Platform and Brady Industries, you qualify for a special Manufacturer Appreciation Program.`,
            `Join 55,000+ other restaurants and start earning cash back from all your restaurant's purchases.`,
            `Get Access to an Additional Portfolio of Beyond Prime Distribution Savings Programs.`,
            `Get Data Insights into Your Restaurant Purchases`
        ]

        switch (abbreviation) {
            case 'brady':
                return brady;
            case 'sheng':
                return noDA;
            case 'rcg':
                return rcg;
            case 'beyond':
            case 'orderly':
            case 'cirrus':
            case 'recipecosting':
            case 'oc':
            case 'tb':
            case 'xtrachef':
            case 'meal':
            case 'ez':
            case 'mpp':
                return BEPartnership;
            case 'aifd':
                return aifd;
            case 'ram':
                return ram;
            case 'bw':
                return bestwestern;
            case 'lrc':
            case 'crm':
            case 'trp':
            case 'cfc':
            case 'tra':
            case 'scrla':
            case 'pcg':
            case 'tmsc':
                return DAPartnership;
            case 'ohla':
                return S1PPartnership;
            case 'fsd':
                return fsd;
            default:
                return BEPartnership;
        }
    }
    sideText = () => {
        const { abbreviation } = this.props;
        switch(abbreviation) {
            case 'rep':
                return <><div className='text-center text-size-20 text-bold' style={{marginTop: '-30px', marginBottom: '20px'}}>Restaurant Empowerment Project</div></>;
            default:
                return null;
        }
    }
    render() {
        const { name } = this.props;
        
        return (
            <div className='left-signup be'>
                <div className='logo'>
                    <a href='/' onClick={this.goTo}>
                        <img style={{maxWidth:'250px', maxHeight:'150px'}} src={this.getLogo()} alt={`${name} Logo`} />
                    </a>
                </div>
                <div className='bullets'>
                    { this.sideText() }
                    { this.getStep1Bullets().map((b, i) => {
                        return (
                            <Bullet key={i} className='be-blue-bg' text={b} />
                        );
                    }) }
                </div>
            </div>
        )
    }
}

export default SignUpPorchDoorChannelSidebar;
