import { apiInstance } from '../../config/tokenApi';

const getFaqCompany = (id) => {
  return apiInstance.get(`faq/by-company/${id}`);
}

const getFaqResourceCompany = (id) => {
  return apiInstance.get(`faq-resource/by-company/${id}`);
}

export default {
  getFaqCompany,
  getFaqResourceCompany

}
