import { companyWebsites } from './companyWebsites';

export const updateColors = (colors) => {
  return {
    type: 'UPDATE_COLORS',
    payload: colors
  }
};

export const updatePartners = (partners, selectedPartner) => {
  return {
    type: 'UPDATE_PARTNERS',
    payload: partners,
    payloadSelected: selectedPartner
  }
};

const fixRegisterLink = (link) => {
  if (!!link.isExternalDomain) {
    const website = companyWebsites.find(w => w.production === link.domain);
    const fixedDomain = website ? website[process.env.REACT_APP_ENVIRONMENT] : link.domain;
    return {
      ...link,
      domain: link.domain.replace(link.domain, fixedDomain)
    };
  }
  return link;
};

export const updateRegisterLinks = (links) => {
  if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
    const fixedLinks = links.map(link => {
      link.children = link.children.map(child => fixRegisterLink(child));
      return fixRegisterLink(link);
    });
    return {
      type: 'UPDATE_REGISTER_LINKS',
      payload: fixedLinks
    }
  }
  return {
    type: 'UPDATE_REGISTER_LINKS',
    payload: links
  }
};

export const updateBrandSelected = (brand, formType) => {
  return {
    type: 'UPDATE_BRAND_SELECTED',
    payload: brand,
    payloadType: formType
  }
};

export const setFormType = (type) => {
  return {
    type: 'UPDATE_FORM_TYPE',
    payload: type
  }
};

export const setRegisterFormType = (type) => {
  return {
    type: 'UPDATE_REGISTER_FORM_TYPE',
    payload: type
  }
};

export const setSegment = (segment) => {
  return {
    type: 'UPDATE_SEGMENT',
    payload: segment
  }
};

export const setSubsegment = (subsegment) => {
  return {
    type: 'UPDATE_SUBSEGMENT',
    payload: subsegment
  }
};

export const setText = (name, text) => {
  return {
    type: 'UPDATE_TEXT',
    text: name,
    payload: text
  }
};

export const setDisplay = (cashBack, insight, itemIntel, claimCheck, beyondPrime, foodbam, existingUser, popularDistributors, reports, contractManagement, displayFoodCostManagement) => {
  return {
    type: 'UPDATE_DISPLAY',
    payload: {
      cashBack: cashBack ? true : false,
      insight: insight ? true : false,
      itemIntel: itemIntel ? true : false,
      claimCheck: claimCheck ? true : false,
      beyondPrime: beyondPrime ? true : false,
      foodbam: foodbam ? true : false,
      existingUser: existingUser ? true : false,
      popularDistributors: popularDistributors ? true : false,
      reports: reports ? true : false,
      contractManagement: contractManagement ? true : false,
      displayFoodCostManagement: displayFoodCostManagement ? true : false
    }
  }
};

export const setCountry = (country) => {
  return {
    type: 'SET_COUNTRY',
    payload: country
  }
};

export const setCompanyLoading = (bool) => {
  return {
    type: 'SET_COMPANY_LOADING',
    payload: bool
  }
};

export const setSocialLinks = (links) => {
  return {
    type: 'SET_SOCIAL_LINKS',
    payload: links
  }
};

export const setHomepage = (url) => {
  return {
    type: 'SET_HOMEPAGE',
    payload: url
  }
}
