export const insightsSetAll = (data) => {
  return {
    type: 'INSIGHTS_SET_ALL',
    payload: data
  }
};

export const insightsSetOpportunity = (data) => {
  return {
    type: 'INSIGHTS_SET_OPPORTUNITY',
    payload: data
  }
};

export const faqSetAll = (data) => {
  return {
    type: 'FAQ_SET_ALL',
    payload: data
  }
};

export const newSetAll = (data) => {
  return {
    type: 'NEW_SET_ALL',
    payload: data
  }
};