import React from 'react';
import { BsTrash } from 'react-icons/bs';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import GridRow from '../../MasterComponents/Grid/GridRow';
import GridCol from '../../MasterComponents/Grid/GridCol';

const NotificationInfo = (props) => {
  const {onClickActivate, onClickEditCredential, clickUploadNow, onClickRead, onClickDelete, distributor, message, createTime, isRead, type} = props;
  return (
    <GridCol className={'border-bottom clickable ' + (isMobile ? 'px-4 py-3 ': 'px-5 pt-2 pb-3') + (!isRead ? ' bg-grey-new':'')} onClick={onClickRead}>
      <GridRow>
        <GridCol className='text-right px-0 text-size-12 line-height-14'>
          {moment(createTime).startOf('day').fromNow() }
        </GridCol>
      </GridRow>
      <GridRow className='pt-2'>
        <GridCol className='pl-0 pr-4 text-size-14 line-height-24'>
          <span className='text-bold'>{distributor}</span> - {message}
        </GridCol>
      </GridRow>
      <GridRow className='pt-3 text-size-14 line-height-17 text-uppercase text-blue font-weight-normal'>
        { (type === 'backupRequired' || type === 'backupSuggested') &&
          <GridCol className='pl-0 clickable' onClick={onClickActivate}>
            Provide Backup
          </GridCol>
        }
        { (type === 'failedActivation' || type === 'failedAccountLocked' || type === 'failedDcn' || type === 'failedPermission')  && 
          <GridCol className='pl-0 clickable' onClick={onClickActivate}>
            Connect
          </GridCol>
        }
        { type === 'failedPassword' &&
          <GridCol className='pl-0 clickable' onClick={onClickEditCredential}>
            Reconnect
          </GridCol>
        }
        { type === 'failedUpload' &&
          <GridCol className='pl-0 clickable' onClick={clickUploadNow}>
            Upload Now
          </GridCol>
        }
        <GridCol className='text-right mw-fc px-0' onClick={onClickDelete}>
          <BsTrash  className='clickable' color='gray' size={16} /> &nbsp;
        </GridCol>
      </GridRow>
    </GridCol>
  )
}

export default NotificationInfo;
