import React from 'react';
import { isMobile } from 'react-device-detect';
import { getSignUpSimpleHeaderButtonClass } from '../../../config/Company/CompanyHelper';
import Button from '../../../MasterComponents/Button/Button';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import IconArrowBack from '../../../MasterComponents/Icons/IconArrowBack';
import InputLabel from '../../../MasterComponents/InputLabel/InputLabel';

const Header = ({ history, isFoodServiceRewards, companyId }) => {
  const signUpSimpleHeaderButtonClass = getSignUpSimpleHeaderButtonClass(companyId);

  if (isMobile) {
    return (
      <div className='header-main'>
        <div className='header-fixed home-header'>
          <GridCol className='pt-2'>
            <InputLabel text='Create Account' title className='title' />
          </GridCol>
          <div onClick={() => history.push('/register')} className='position-absolute' style={{left: '10px'}}>
            <IconArrowBack color='white' size={18} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='container'>
      <GridRow className='align-items-center pt-5'>
        <GridCol className='text-right'>
          Already have an account?
        </GridCol>
        <GridCol maxWidth='120px'>
          <Button variant={isFoodServiceRewards ? 'fsr-outline' : signUpSimpleHeaderButtonClass}
          text='LOGIN' onClick={() => history.push('/login')} 
          />
        </GridCol>
      </GridRow>
      <GridRow>
        <GridCol className='text-size-36 font-weight-bold'>
          Create Account
        </GridCol>
      </GridRow>
    </div>
  );
};

export default Header;