export const itemIntelSetItems = (data) => {
  return {
    type: 'ITEM_INTEL_SET_ITEMS',
    payload: data
  }
};

export const itemIntelSetManufacturers = (data) => {
  return {
    type: 'ITEM_INTEL_SET_MANUFACTURERS',
    payload: data
  }
};

export const itemIntelSetCategories = (data) => {
  return {
    type: 'ITEM_INTEL_SET_CATEGORIES',
    payload: data
  }
};

export const itemIntelSetPage = (data) => {
  return {
    type: 'ITEM_INTEL_SET_PAGE',
    payload: data
  }
};

export const itemIntelSetFilters = (data) => {
  return {
    type: 'ITEM_INTEL_SET_FILTERS',
    payload: data
  }
};

export const itemIntelLoadMoreItems = (data) => {
  return {
    type: 'ITEM_INTEL_LOAD_MORE_ITEMS',
    payload: data
  }
};

export const itemIntelSetScrollToSelectedItem = (data) => {
  return {
    type: 'ITEM_INTEL_SET_SCROLL_TO_SELECTED_ITEM',
    payload: data
  }
};

export const itemIntelSetItemDetails = (data) => {
  return {
    type: 'ITEM_INTEL_SET_ITEM_DETAILS',
    payload: data
  }
};

export const itemIntelResetItemDetails = () => {
  return {
    type: 'ITEM_INTEL_RESET_ITEM_DETAILS',
  }
};

export const itemIntelSetSimilarItems = (data) => {
  return {
    type: 'ITEM_INTEL_SET_SIMILAR_ITEMS',
    payload: data
  }
};

export const itemIntelResetSimilarItems = () => {
  return {
    type: 'ITEM_INTEL_RESET_SIMILAR_ITEMS',
  }
};

export const itemIntelSetShowFiltersSection = (data) => {
  return {
    type: 'ITEM_INTEL_SET_SHOW_FILTERS_SECTION',
    payload: data
  }
};