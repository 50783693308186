import React from 'react';
import config from '../../../config/config';
import Button from '../../../MasterComponents/Button/Button';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import IconEdit from '../../../MasterComponents/Icons/IconEdit';
import Input from '../../../MasterComponents/Input/Input';
import InputLabel from '../../../MasterComponents/InputLabel/InputLabel';
import usePlacesForm from '../hooks/usePlacesForm';
import byGoogle from '../../../assets/images/powered-by-google.png';
import { isMobile } from 'react-device-detect';
import DropDownListSelect from '../../../MasterComponents/DropDownList/DropDownListSelect';
import DropDownListSelectCustom from '../../../MasterComponents/DropDownList/DropDownListSelectCustom';
import { getText } from '../../../MasterComponents/Helper/getHelper';

const LocationBox = (props) => {
  const {
    placesAutocompleteProps, locationName, addressDetails,
    fullAddress, phoneNumber, street, city, usState, zipcode
  } = usePlacesForm(props);

  const { country } = props;

  const req = props.index === 0 ? ' *' : '';
  return (
    <>
      <GridRow className='my-3'>
        <GridCol className='font-weight-bold'>
          Location {props.index+1}
        </GridCol>
      </GridRow>
      <GridRow>
        <GridCol size={isMobile ? 12 : 6}>
          <InputLabel text={`Location Name${req}`}/>
          {
            addressDetails.value ?
              <Input placeholder='Location Name' name='locationName' {...locationName} />
            :
              <DropDownListSelectCustom {...placesAutocompleteProps} />
          }
        </GridCol>
      </GridRow>
      <GridRow className='mt-3'>
        <GridCol size={isMobile ? 12 : 6}>
          <GridRow className='align-items-end'>
            <GridCol className='pr-0'>
              <InputLabel text={`Official Business Address${req}`} />
              <input {...fullAddress} />
            </GridCol>
            <GridCol style={{maxWidth: 'fit-content'}}>
              <Button onClick={addressDetails.toggle} style={{width: '40px', height: '40px'}} variant={ addressDetails.value ? 'blue' : 'white'} icon={<IconEdit color={ !addressDetails.value ? config.colors.rbBlue : config.colors.rbWhite} />}/>
            </GridCol>
          </GridRow>
        </GridCol>
      </GridRow>
      <GridRow className={addressDetails.value ? 'mx-0 mt-2' : 'mx-0 d-none'}>
        <GridCol className='py-2' style={{backgroundColor: '#F8F8F8'}}>
          <GridRow>
            <GridCol>
              <InputLabel text='Phone Number' />
              <Input clearOnInvalid validate type='tel' {...phoneNumber} />
            </GridCol>
          </GridRow>
          <GridRow className='mt-2'>
            <GridCol>
              <InputLabel text='Address Line *' />
              <Input placeholder='Address Line' name='street' {...street} />
            </GridCol>
          </GridRow>
          <GridRow className='mt-2'>
            <GridCol className={isMobile ?  'col-12': 'col-4'}>
              <InputLabel text='City *' />
              <Input placeholder='City' name='city' {...city} />
            </GridCol>
            <GridCol className={isMobile ?  'col-6 mt-2': 'col-4'}>
              <InputLabel text={getText(country, 'state')} />
              <DropDownListSelect name='state' {...usState} />
            </GridCol>
            <GridCol className={isMobile ?  'col-6 mt-2': 'col-4'}>
              <InputLabel text={getText(country, 'zipCode')} />
              <Input placeholder='Zipcode' name='zipcode' {...zipcode} />
            </GridCol>
          </GridRow>
        </GridCol>
      </GridRow>
      <GridRow className='align-items-center mt-2 mx-0'>
        <GridCol className='border-bottom pb-2'>
          <GridRow>
            <GridCol maxWidth='80px' className='px-0'>
              <img className='w-100' src={byGoogle} alt='google' />
            </GridCol>
          </GridRow>
        </GridCol>
      </GridRow>
    </>
  );
};

export default LocationBox;