import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconReceipt = props => (
 <svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 32 27" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Claim-Cash" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="3---Step-2" transform="translate(-704.000000, -342.000000)">
            <g id="icons/check" transform="translate(704.000000, 342.000000)">
                <rect id="Rectangle" fill={!props.isActive ? props.color : props.activeColor} x="4" y="8" width="24" height="1"></rect>
                <rect id="Rectangle" fill={!props.isActive ? props.color : props.activeColor} x="4" y="12" width="24" height="1"></rect>
                <rect id="Rectangle" fill={!props.isActive ? props.color : props.activeColor} x="4" y="16" width="24" height="1"></rect>
                <path d="M3.03539787,26.0003256 C3.14497124,25.2337716 3.50292618,24.5473264 4.02512627,24.0251263 C4.65850169,23.3917508 5.53350169,23 6.5,23 C7.46649831,23 8.34149831,23.3917508 8.97487373,24.0251263 C9.49709854,24.5473511 9.85506264,25.2338366 9.96461769,26.0004345 L12.0353823,26.0004345 C12.1449374,25.2338366 12.5029015,24.5473511 13.0251263,24.0251263 C13.6585017,23.3917508 14.5335017,23 15.5,23 C16.4664983,23 17.3414983,23.3917508 17.9748737,24.0251263 C18.4970985,24.5473511 18.8550626,25.2338366 18.9646177,26.0004345 L22.0353823,26.0004345 C22.1449374,25.2338366 22.5029015,24.5473511 23.0251263,24.0251263 C23.6585017,23.3917508 24.5335017,23 25.5,23 C26.4664983,23 27.3414983,23.3917508 27.9748737,24.0251263 C28.4970738,24.5473264 28.8550288,25.2337716 28.9646021,26.0003256 L31,26.0001073 L31,0.999882521 L28.6631139,0.999607971 C28.4391239,1.4714276 28.1127993,1.88524001 27.7124961,2.21208421 C27.1094664,2.70445289 26.3392772,3 25.5,3 C24.6607228,3 23.8905336,2.70445289 23.2875039,2.21208421 C22.887177,1.88522066 22.5608374,1.47137861 22.3368463,0.999524193 L18.6631537,0.999524193 C18.4391626,1.47137861 18.112823,1.88522066 17.7124961,2.21208421 C17.1094664,2.70445289 16.3392772,3 15.5,3 C14.6607228,3 13.8905336,2.70445289 13.2875039,2.21208421 C12.887177,1.88522066 12.5608374,1.47137861 12.3368463,0.999524193 L9.66315366,0.999524193 C9.43916256,1.47137861 9.11282298,1.88522066 8.71249607,2.21208421 C8.10946637,2.70445289 7.33927716,3 6.5,3 C5.66072284,3 4.89053363,2.70445289 4.28750393,2.21208421 C3.88720071,1.88524001 3.56087607,1.4714276 3.33688611,0.999607971 L1,0.999882521 L1,26.0001073 L3.03539787,26.0003256 Z" id="Combined-Shape" stroke={!props.isActive ? props.color : props.activeColor} strokeWidth="2"></path>
            </g>
        </g>
    </g>
</svg>
);

IconReceipt.propTypes = {
  color: PropTypes.string,
  activeColor: PropTypes.string,
  isActive: PropTypes.bool,
  size: PropTypes.number
};

IconReceipt.defaultProps = {
  activeColor: config.colors.rbYellow,
  color: config.colors.rbWhite,
  isActive: false,
  size: 16
};

export default IconReceipt;