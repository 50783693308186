import React, { Component } from 'react';
import {  findModal } from '../../../MasterComponents/Modals/ModalsActions';
import Modal from '../../../MasterComponents/Modals/Modal';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import Button from '../../../MasterComponents/Button/Button';
import IconCheckMark from '../../../MasterComponents/Icons/IconCheckMark';
import config from '../../../config/config';

class SuccessClaimModal extends Component {

    gotIt = () => {
        const { modals } = this.props;
        findModal(modals, 'idSuccessClaimModal').close();
    }

    render() {
        const {brandName} = this.props;
        return (
             <Modal id='idSuccessClaimModal' size='sm'>
                <ModalBody className='px-4'>
                    <GridRow>
                        <GridCol className='p-0 text-center'>
                            <IconCheckMark color={config.colors.rbGreen} size={50} />
                        </GridCol>
                    </GridRow>
                    <GridRow className='pt-3'>
                        <GridCol className='text-center' style={{fontSize:'18px', lineHeight: '24px'}}>
                            Thank You! The transaction will show in your account as "ACH Credit {brandName} Payment" and should arrive in your account in 2-5 business days.
                        </GridCol>
                    </GridRow>
                    <GridRow className='mt-5 text-center'>
                        <GridCol size={1}>
                        </GridCol>
                        <GridCol size={10}>
                            <Button type='button' variant='primary' size='md' text='GOT IT' onClick={this.gotIt}></Button>
                        </GridCol>
                        <GridCol size={1}>
                        </GridCol>
                    </GridRow>
                </ModalBody>
            </Modal>
        );
    }
}

export default SuccessClaimModal;