import React, { Component } from 'react';
import './ChangePassword.scss';
import ChangePasswordSidebar from './ChangePasswordSidebar';
import ChangePasswordContent from './ChangePasswordContent';

class ChangePassword extends Component {
       render() {
        const url = '/change-password/';
        const token  = this.props.location.pathname.substring(url.length);
        
        return (            
            <div className='container-change'>
                <ChangePasswordSidebar history={this.props.history} token={token}/>
                <ChangePasswordContent history={this.props.history} token={token}/>
            </div>
        )
    }
}

export default ChangePassword;