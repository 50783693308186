import React, { Component } from 'react';
import InputLabel from '../../../../MasterComponents/InputLabel/InputLabel';
import GridCol from '../../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../../MasterComponents/Grid/GridRow';
import { isMobile } from 'react-device-detect';
import Bullet from '../../../../MasterComponents/Bullet/Bullet';
import emailImg from '../../../../assets/images/email.svg';
import emailImgCompanies from '../../../../assets/images/emailCompanies.svg';

class FormExistingUserSuccess extends Component {
    render() {
        const { companyId } = this.props;
        const header =
            <GridRow>
                <GridCol className='signup-header-sucessfully'>
                    <Bullet/>
                </GridCol>
            </GridRow>      
        return (
            <GridCol>
                    {isMobile ? header : null}
                <GridRow>
                    <GridCol className='text-center signup-title-sucessfully'>
                        <img src={companyId === 1 ?  emailImg : emailImgCompanies } alt='email' />
                    </GridCol>
                </GridRow>
                <GridRow>
                    <GridCol className='text-center'>
                        <InputLabel className='signup-title-sucessfully'>Almost Done!</InputLabel>
                    </GridCol>
                </GridRow>
                <GridRow>
                    <GridCol className='text-center'>
                    <InputLabel className='signup-body-words'>Please click the activation link in your email to log in to your account.</InputLabel>
                    </GridCol>
                </GridRow>
            </GridCol>
        )
    }
}

export default FormExistingUserSuccess;