import React, { Component, Fragment } from 'react';
import GridRow from '../../MasterComponents/Grid/GridRow';
import { changePage } from './DashboardActions';
import Card from '../../MasterComponents/Card/Card';
import GridCol from '../../MasterComponents/Grid/GridCol';
import IconUpload from '../../MasterComponents/Icons/IconUpload';
import config from '../../config/config';
import IconEdit from '../../MasterComponents/Icons/IconEdit';
import IconDelete from '../../MasterComponents/Icons/IconDelete';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { showModal, findModal } from '../../MasterComponents/Modals/ModalsActions';
import UploadPurchaseHistoryModal from './Modals/UploadPurchaseHistoryModal';
import { spinner } from '../../MasterComponents/Spinner/SpinnerActions';
import DashboardApi from './DashboardApi';
import EnterCredentialsModal from './Modals/EnterCredentialsModal';
import Modal from '../../MasterComponents/Modals/Modal';
import ModalHeader from '../../MasterComponents/Modals/ModalHeader';
import ModalBody from '../../MasterComponents/Modals/ModalBody';
import Button from '../../MasterComponents/Button/Button';
import IconLock from '../../MasterComponents/Icons/IconLock';
import DistributorHouseModal from './Modals/DistributorHouseModal';
import showActivateDistributor from '../../MasterComponents/SharedFunctions/showActivateDistributor';
import LocationDistributorDeleteModal from '../Location/Modals/LocationDistributorDeleteModal';


class ErrorWarning extends Component {
  cancel = () => {
    const { modals } = this.props;
    findModal(modals, 'errorWarningModal').close();
  }
  render() {
    return(
      <Modal id='errorWarningModal' centered>
        <ModalHeader closeButton={false} noBorder={true}>
          {this.props.message}
        </ModalHeader>
        <ModalBody>
          <GridRow>
            <GridCol>
              <Button onClick={this.cancel} variant='white' text='Ok' />
            </GridCol>
          </GridRow>
        </ModalBody>
      </Modal>
    )
  }
}

class UpdateWarning extends Component {
  yes = () => {
    const { acceptDelete, id, modals } = this.props;
    acceptDelete(id);
    findModal(modals, 'updateWarningModal').close();
  }
  cancel = () => {
    const { modals } = this.props;
    findModal(modals, 'updateWarningModal').close();
  }
  render() {
    return(
      <Modal id='updateWarningModal' centered>
        <ModalHeader closeButton={false} noBorder={true}>
          Are you sure you want to change your Distributor House?
        </ModalHeader>
        <ModalBody>
          <GridRow>
            <GridCol>
              <Button onClick={this.cancel} variant='white' text='No' />
            </GridCol>
            <GridCol>
              <Button onClick={this.yes} variant='primary' text='Yes' />
            </GridCol>
          </GridRow>
        </ModalBody>
      </Modal>
    )
  }
}

const verifyCredentials = (distributorId, merchantId, username, password, dcns, cb) => {
  const { dispatch } = this.props;
  dispatch(spinner(true));
  DashboardApi.verifyCreds( distributorId, merchantId, username, password, dcns).then(({data}) => {
    dispatch(spinner(false));
    cb(data);
  }).catch(({data}) => {
    dispatch(spinner(false));
    cb(data);
  });
};

class DashboardEditDistributor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nickname: '',
      rebatesApproval: false,
      submissionType: '',
      lastSubmission: '',
      nextSubmission: '',
      loading: true,
      parentDistributor: null,
      distributor: null,
      distributorsHouseSelected : null,
      distributorsHouse: [],
      signedText: ''
    }
    this.showActivateDistributor = showActivateDistributor.bind(this);
  }
  componentDidMount = () => {
    window.scroll({top: 0, left: 0, behavior: 'smooth' });
    const { dispatch, history } = this.props;
    const section= {
      button: 'Rebates Portal',
      title: 'Edit Distributor'
    };
    dispatch(changePage(section));
    
    if (!history.location.state) {
      history.replace('/dashboard/rebates/card');
    } else {
      this.mapData();
    }
  }
  getMyParentDistributor = (page) => {
    const { dispatch, location, companyId } = this.props;
    const { distributorParentId, id } = location.state.merchantDistributor;
    const saveData = (data) => {
      const parentDistributor = data.filter(d => d.id === distributorParentId || d.id === id)[0];
      if (parentDistributor) {
        this.setState({
          parentDistributor: data.filter(d => d.id === distributorParentId || d.id === id)[0],
        });
      }
    }
    dispatch(spinner(true));
    DashboardApi.getDistributorsAll(companyId, '', page).then(({data, headers}) => {
      dispatch(spinner(false));
      saveData(data.data);
      if (headers['x-pagination-current-page'] !== headers['x-pagination-page-count']) {
        this.getMyParentDistributor(page+1);
      }
    });
  }
  getDistributorHouse = () => {
    const { dispatch, location, companyId } = this.props;
    const { distributor, distributorParentId, distributorId } = location.state.merchantDistributor;
    dispatch(spinner(true));
    console.log('distributorParentId', location.state.merchantDistributor);

    DashboardApi.getDistributorsHouse(companyId, distributorParentId || distributorId).then(({data}) => {
      this.getMyParentDistributor(1);
      dispatch(spinner(false));
      const result = data.data;
      this.setState({
        loading: false,
        distributorsHouseSelected: distributorParentId ? {label: distributor, value: distributorId} : null,
        distributorsHouse: result.length ? result.map(d => {
          return {
            label: d.distributor || d.nickname,
            value: d.id
          }
        }) : []
      });
    });
  }
  mapData = () => {
    const { history } = this.props;
    const md = history.location.state.merchantDistributor;
    console.log('md', md);
    this.setState({
      distributor: md,
      loading: false,
      nickname: md.distributor,
      rebatesApproval: md.isActive,
      submissionType: md.submissionType,
      lastSubmission: moment(md.lastLoadDate).isValid() ? moment(md.lastLoadDate).format('M-DD-YY') : (!md.lastLoadDate ? 'No Data' : md.lastLoadDate),
      nextSubmission: moment(md.nextLoadDate).isValid() ? moment(md.nextLoadDate).format('M-DD-YY') : (!md.nextLoadDate ? 'No Data' : md.nextLoadDate)
    });
    this.getDistributorHouse();
  }
  acceptDelete = () => {
    const { dispatch, match, history, refresh } = this.props;
    const { id } = match.params;
    dispatch(spinner(true));
    DashboardApi.deleteDistributor(id).then(res => {
      dispatch(spinner(false));
      refresh(() => {
        history.push(`/dashboard/rebates/card`);
      });
    });
  }
  clickDelete = () => {
    const { dispatch, match, refresh, history } = this.props;
    const { id } = match.params;
    dispatch(showModal(LocationDistributorDeleteModal, 'idLocationDistributorDeleteModal', {  merDist: { id }, refresh, history, isDistributorDetail: true }));
  }
  clickEdit = (e) => {
    e.stopPropagation();
    this.showActivateDistributor(this.props.location.state.merchantDistributor, true);
  };
  clickUploadNow = (e) => {
    e.stopPropagation();
    const { dispatch, refresh, location } = this.props;
    const { merchantDistributor } = location.state;
    dispatch(showModal(UploadPurchaseHistoryModal, 'uploadPurchaseHistoryModal', { merchantDistributor: merchantDistributor, refresh: refresh }));
  };
  clickEditCredentials = (e) => {
    e.stopPropagation();
    const { dispatch, location } = this.props;
    const { merchantDistributor } = location.state;
    dispatch(showModal(EnterCredentialsModal, 'enterCredentialsModal', {
      merchantDistributor: merchantDistributor,
      saveCredentials: this.saveCredentials,
      verifyCredentials: this.verifyCredentials,
      dcns: [merchantDistributor.dcn],
      isEdit: true
    }));
  }
  saveCredentials = (merchantDistributorId, username, password) => {
    const { dispatch } = this.props;
    const params = {
      merchantDistributorId: merchantDistributorId,
      username: username,
      password: password
    }
    dispatch(spinner(true));
    DashboardApi.editCredentials(params).then(({data}) => {
      dispatch(spinner(false));
    });
  };
  verifyCredentials = verifyCredentials
  showSubmissionType = () => {
    const { submissionType } = this.state;
    switch (submissionType) {
      case 'dont know yet':
        return `Don't Know Yet`;
      case 'online':
        return `Online Ordering`;
      case 'manual':
        return `Manual Upload`;
      default:
        return '';
    }
  };
  clickEditDistributorHouse = () => {
    const { dispatch } = this.props;
    const { distributorsHouse, distributorsHouseSelected, signedText } = this.state;

    dispatch(showModal(DistributorHouseModal, 'distributorHouseModalId', {
      distributorParent: this.state.parentDistributor,
      distributorsHouse: distributorsHouse, 
      distributorsHouseSelected : distributorsHouseSelected,
      onChangeDistributorHouse : this.onChangeDistributorHouse, 
      saveDistributorHouse : this.saveDistributorHouse,
      signedText : signedText,
      changeHandlerSignedText : this.changeHandlerSignedText
    }));
  };
  saveDistributorHouse = () => {
    const { distributorsHouseSelected } = this.state;
    const { history, dispatch, location, refresh } = this.props;
    const { merchantDistributor } = location.state;

    const callApi = () => {
      dispatch(spinner(true));
      const params = {
        distributorId: distributorsHouseSelected.value
      };

      DashboardApi.editMerchantDistributor(merchantDistributor.id, params).then(({data}) => {
        dispatch(spinner(false));
        refresh(() => {
          history.replace(`/dashboard/rebates`);
        });
      }).catch((res) => {
        dispatch(spinner(false));
        dispatch(showModal(ErrorWarning, 'errorWarningModal', {message: "Something went wrong."}));
      })
    };

    dispatch(showModal(UpdateWarning, 'updateWarningModal', {acceptDelete: callApi}));
  };
  onChangeDistributorHouse = (option) => {
    this.setState({
      distributorsHouseSelected : option,
    });
  };
  changeHandlerSignedText = (value) => {
    this.setState({
      signedText : value
    });
  }

  render() {
    const { nickname, submissionType, lastSubmission, nextSubmission, rebatesApproval, distributorsHouse } = this.state;

    return (
      <GridRow className={'h-75 ' + (isMobile ? 'py-3' : 'px-4 py-4')}>
        <Card>
          <GridRow className='pb-0'>
            <GridCol>
              <h5 className='font-weight-bolder'>{nickname}</h5>
            </GridCol>
          </GridRow>
          <GridRow>
            <GridCol maxWidth='250px' className='text-size-14 text-line-height-20 text-grey text-weight-bold'>
              History Submission Type
            </GridCol>
            <GridCol maxWidth='150px' className='text-right text-capitalize'>
              {this.showSubmissionType()}
            </GridCol>
          </GridRow>
          { rebatesApproval && submissionType === 'manual' ?
            <GridRow>
              <GridCol maxWidth='250px' className='text-size-14 text-line-height-20 text-grey text-weight-bold'>
                Last Rebate Submission
              </GridCol>
              <GridCol maxWidth='150px' className='text-right text-capitalize'>
                {lastSubmission}
              </GridCol>
            </GridRow>
          : null}
          { rebatesApproval ?
            <GridRow>
              <GridCol maxWidth='250px' className='text-size-14 text-line-height-20 text-grey text-weight-bold'>
                Next Scheduled Submission
              </GridCol>
              <GridCol maxWidth='150px' className='text-right text-capitalize'>
                {nextSubmission}
              </GridCol>
            </GridRow>
          : null }
          {
            isMobile ?
            <Fragment>
              <GridRow>
                <GridCol onClick={this.clickEdit} className='text-grey clickable'>
                  <IconEdit color={config.colors.rbDarkerGrey} /> Edit Distributor Type
                </GridCol>
              </GridRow>
              {
                rebatesApproval ?
                <GridRow>
                {
                  submissionType === 'manual' ?
                  <GridCol onClick={this.clickUploadNow} className='text-grey clickable'>
                    <IconUpload color={config.colors.rbDarkerGrey} /> Upload Now
                  </GridCol>
                  :
                  (
                    submissionType !== 'auto' ? 
                    <GridCol onClick={this.clickEditCredentials} className='text-grey clickable'>
                      <IconEdit color={config.colors.rbDarkerGrey} /> Reconnect
                    </GridCol>
                    : null
                  )
                }
                </GridRow>
                :
                null
              }
              {
                distributorsHouse.length > 0 ?
                  <GridCol onClick={this.clickEditDistributorHouse} className='text-grey clickable'>
                    <IconEdit color={config.colors.rbDarkerGrey} /> Edit Distributor House
                  </GridCol>
                : null
              }
              <GridRow>
                <GridCol onClick={this.clickDelete} className='text-grey clickable'>
                  <IconDelete color={config.colors.rbDarkerGrey} /> Delete
                </GridCol>
              </GridRow>
            </Fragment>
            :
            <GridRow>
              {
                rebatesApproval && submissionType === 'manual' ?
                <GridCol onClick={this.clickUploadNow} className='text-grey clickable' size={2}>
                  <IconUpload color={config.colors.rbDarkerGrey} /> Upload Now
                </GridCol>
                :
                rebatesApproval && submissionType !== 'auto' ?
                <GridCol onClick={this.clickEditCredentials} className='text-grey clickable' size={2}>
                  <IconLock color={config.colors.rbDarkerGrey} /> Reconnect
                </GridCol>
                :
                null
              }
              <GridCol onClick={this.clickEdit} className='text-grey clickable' size={2}>
                <IconEdit color={config.colors.rbDarkerGrey} /> Edit Distributor Type
              </GridCol>
              {
                distributorsHouse.length > 0 ?
                <GridCol onClick={this.clickEditDistributorHouse} className='text-grey clickable' size={2}>
                  <IconEdit color={config.colors.rbDarkerGrey} /> Edit Distributor House
                </GridCol>
                : null
              }
              <GridCol onClick={this.clickDelete} className='text-grey clickable' size={2}>
                <IconDelete color={config.colors.rbDarkerGrey} /> Delete
              </GridCol>
            </GridRow>
          }
        </Card>
      </GridRow>
    );
  }
};

export default DashboardEditDistributor;
