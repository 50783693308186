import React, { Component } from 'react';
import Modal from '../Modals/Modal';
import ModalHeader from '../Modals/ModalHeader';
import ModalBody from '../Modals/ModalBody';
import GridCol from '../Grid/GridCol';
import GridRow from '../Grid/GridRow';
import Button from '../Button/Button';
import { findModal } from '../Modals/ModalsActions';

class ErrorWarning extends Component {
  cancel = () => {
    const { modals } = this.props;
    findModal(modals, 'errorWarningModal').close();
  }
  render() {
    return(
      <Modal id='errorWarningModal' centered>
        <ModalHeader closeButton={false} noBorder={true}>
          {this.props.message}
        </ModalHeader>
        <ModalBody>
          <GridRow>
            <GridCol>
              <Button onClick={this.cancel} variant='white' text='Ok' />
            </GridCol>
          </GridRow>
        </ModalBody>
      </Modal>
    )
  }
};

export default ErrorWarning;