import { apiInstance } from '../../config/tokenApi';

const getInvoices = (params) => {
  return apiInstance.post(`/invoice/all`, params);
}

const saveInvoice = (formData) => {
  return apiInstance.post(`/invoice`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

const updateInvoice = (id, formData) => {
  return apiInstance.post(`/invoice/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export default {
  saveInvoice,
  getInvoices,
  updateInvoice
}