export const invoiceSetAll = (data) => {
  return {
    type: 'INVOICE_SET_ALL',
    payload: data
  }
};

export const invoiceSetFiles = (data) => {
  return {
    type: 'INVOICE_SET_FILES',
    payload: data
  }
};

export const invoiceSetSendDisabled = (data) => {
  return {
    type: 'INVOICE_SET_SEND_DISABLED',
    payload: data
  }
};