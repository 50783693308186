import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconCart = props => (
  <svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 16 13" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Designs" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="3---Homepage---Pop-Up" transform="translate(-988.000000, -29.000000)" fill={!props.isActive ? props.color : props.activeColor} fillRule="nonzero">
        <g id="header-1">
          <g id="header">
            <g id="nav" transform="translate(321.000000, 0.000000)">
              <g id="icons/cart" transform="translate(667.000000, 29.000000)">
                <path d="M3.5,9.5 C2.673,9.5 2,10.173 2,11 C2,11.827 2.673,12.5 3.5,12.5 C4.327,12.5 5,11.827 5,11 C5,10.173 4.327,9.5 3.5,9.5 Z M3.5,11.5 C3.2245,11.5 3,11.2755 3,11 C3,10.7245 3.2245,10.5 3.5,10.5 C3.7755,10.5 4,10.7245 4,11 C4,11.2755 3.7755,11.5 3.5,11.5 Z" id="Shape"></path>
                <path d="M10,11 C10,10.173 9.327,9.5 8.5,9.5 C7.673,9.5 7,10.173 7,11 C7,11.827 7.673,12.5 8.5,12.5 C9.327,12.5 10,11.827 10,11 Z M8,11 C8,10.7245 8.2245,10.5 8.5,10.5 C8.7755,10.5 9,10.7245 9,11 C9,11.2755 8.7755,11.5 8.5,11.5 C8.2245,11.5 8,11.2755 8,11 Z" id="Shape"></path>
                <path d="M15.5,0 L12.5,0 C12.281,0 12.088,0.142 12.0225,0.351 L9.6325,8 L2.3905,8 L1.1405,3 L7,3 C7.276,3 7.5,2.776 7.5,2.5 C7.5,2.224 7.276,2 7,2 L0.5,2 C0.346,2 0.2005,2.071 0.106,2.1925 C0.0115,2.314 -0.0225,2.472 0.015,2.6215 L1.515,8.6215 C1.5705,8.844 1.7705,9 2,9 L10,9 C10.219,9 10.412,8.858 10.4775,8.649 L12.8675,1 L15.5,1 C15.776,1 16,0.776 16,0.5 C16,0.224 15.776,0 15.5,0 Z" id="Path"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

IconCart.propTypes = {
  color: PropTypes.string,
  activeColor: PropTypes.string,
  isActive: PropTypes.bool,
  size: PropTypes.number,
};

IconCart.defaultProps = {
  activeColor: config.colors.rbYellow,
  color: config.colors.rbWhite,
  isActive: false,
  size: 16,
};

export default IconCart;
