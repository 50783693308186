

const useHomePrograms = (props) => {
  const { history } = props;
  const onGoToPrograms = () => {
    history.push('/dashboard/prime');
  }

  return {
    onGoToPrograms
  }
}

export default useHomePrograms;
