import React from 'react';
import { useSelector } from 'react-redux';
import { getNotificationModalHeaderClass } from '../../../config/Company/CompanyHelper';
import Modal from '../../../MasterComponents/Modals/Modal'
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import ModalHeader from '../../../MasterComponents/Modals/ModalHeader';
import Notification from '../Notification';

const NotificationModal = () => {
  const companyId = useSelector(({ company }) => company.id);
  return (
    <Modal id='idNotificationModal' fullHeight={true}>
      <ModalHeader blueHeader closeColor={'white'} className={getNotificationModalHeaderClass(companyId)}>Notifications</ModalHeader>
      <ModalBody className='py-0'>
        <Notification />
      </ModalBody>
    </Modal>
  )
}

export default NotificationModal;
