import React, { Component } from 'react'
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import '../FoodBam/FoodBamHome.scss';
import GridContainer from '../../MasterComponents/Grid/GridContainer';
import GridRow from '../../MasterComponents/Grid/GridRow';
import GridCol from '../../MasterComponents/Grid/GridCol';
import Button from '../../MasterComponents/Button/Button';
import breezeOrdering from '../../assets/images/foodbam/breeze-ordering.png';
import saveMoney from '../../assets/images/foodbam/save-money.svg';
import quickInventory from '../../assets/images/foodbam/quick-inventory.png';
import easyRecipeCosting from '../../assets/images/foodbam/easy-recipe-costing.png';
import FoodBamFrame from './FoodBamFrame';

class FoodBamHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFoodbamActive: localStorage.getItem('isFoodbamActive')
    };
  }

  goToFoodBamAddDistributors = (e) => {
    e.preventDefault();
    this.props.history.push('/dashboard/foodBAM/sign-up');
  }

  render() {
    const { isFoodbamActive } = this.state;
    if (isFoodbamActive==='1') {
      return <FoodBamFrame />;
    }

    const singleQuote = "'";
    return (
      <div className='foodbam-home'>
        <GridRow className='hero-row'>
          <GridContainer isFluid={false}>
            <GridRow align='center' justify='center'>
              <GridCol className='col-12'>
                <h1>Perfect for chefs, general <br />managers, and owners</h1>
                <p>Start with Foodbam today and get it for <del>$145</del> <em>$99/month</em> for the first year.</p>
                <Button type='button' variant='primary' size='md' text='TRY NOW' onClick={this.goToFoodBamAddDistributors}></Button>
              </GridCol>
            </GridRow>
          </GridContainer>
        </GridRow>

        <GridRow className='even-row'>
          <GridContainer isFluid={false}>
            <GridRow align='center' justify='center'>
              <GridCol className={(!isMobile ? 'col-6' : 'col-12')}>
                <h2>FoodBAM makes ordering a breeze for restaurants AND distributors.</h2>
                <p>Chefs and restaurant owners can search their distributors{singleQuote} product catalogs and add items to their orders from their phones or computers. Purchase orders are routed to distributors in an easy, standardized format. All orders and prices are saved in the system, making re-orders a snap.</p>
              </GridCol>
              <GridCol className={(!isMobile ? 'col-6' : 'col-12')}>
                <img src={breezeOrdering} className='img-fluid' alt='Breeze ordering' />
              </GridCol>
            </GridRow>
          </GridContainer>
        </GridRow>

        <GridRow className='odd-row'>
          <GridContainer isFluid={false}>
            <GridRow align='center'>
              <GridCol className={(!isMobile ? 'col-6' : 'col-12')}>
                <img src={saveMoney} className='img-fluid' alt='Save money' />
              </GridCol>
              <GridCol className={(!isMobile ? 'col-6' : 'col-12')}>
                <h2>Save money with us.</h2>
                <p>FoodBAM{singleQuote}s back-of-house restaurant software offers savings opportunities at every click or tap. Restaurants can compare item prices using comparison view, find item matches with savings suggestions, and uncover rebate opportunities as they place orders.</p>
              </GridCol>
            </GridRow>
          </GridContainer>
        </GridRow>

        <GridRow className='even-row'>
          <GridContainer isFluid={false}>
            <GridRow align='center'>
              <GridCol className={(!isMobile ? 'col-6' : 'col-12')}>
                <h2>Take inventory in minutes.</h2>
                <p>Taking inventory does not have to be your worst nightmare anymore. FoodBAM delivers a running and up-to-date restaurant inventory right to your fingertips. Inventory is integrated right into the ordering process so you can manage your restaurant{singleQuote}s cost of goods and the shelf life of your products. Spend your nights counting sheep… not potatoes.</p>
              </GridCol>
              <GridCol className={(!isMobile ? 'col-6' : 'col-12')}>
                <img src={quickInventory} className='img-fluid' alt='Quick inventory' />
              </GridCol>
            </GridRow>
          </GridContainer>
        </GridRow>

        <GridRow className='odd-row'>
          <GridContainer isFluid={false}>
            <GridRow align='center' justify='center'>
              <GridCol className={(!isMobile ? 'col-6' : 'col-12')}>
                <img src={easyRecipeCosting} className='img-fluid' alt='Easy Recipe Costing' />
              </GridCol>
              <GridCol className={(!isMobile ? 'col-6' : 'col-12')}>
                <h2>Recipe costing made easy.</h2>
                <p>FoodBam is recipe costing made simple.  Plug quantities and conversions into FoodBAM and our back-of-house restaurant software will deliver waste percentage, gross margin, profit, menu price and total cost in an instant.  The software pulls items directly off your order guide and updates prices in real time, so your recipe costs are always calculated with the latest info.  Work smarter, not harder with FoodBAM.</p>
              </GridCol>
            </GridRow>
          </GridContainer>
        </GridRow>

        <GridRow className='cta-row'>
          <GridContainer isFluid={false}>
            <GridRow align='center' justify='center'>
              <GridCol className='col-12'>
                <h2 className='text-center'>Ready to try FoodBAM?</h2>
                <p className='text-center'>Easy to set up. Cancel anytime.</p>
                <div className='text-center'><Button type='button' size='md' variant='white' text='TRY NOW' onClick={this.goToFoodBamAddDistributors}></Button></div>
              </GridCol>
            </GridRow>
          </GridContainer>
        </GridRow>
      </div>
    );
  }

}

const mapStateToProps = state => {
  return {};
}

export default connect(mapStateToProps)(FoodBamHome);
