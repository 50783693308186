export const signUpSetAllSalesRep = (data) => {
  return {
    type: 'SIGNUP_SET_ALL_SALES_REP',
    payload: data
  }
};

export const signUpClearAllSalesRep = () => {
  return {
    type: 'SIGNUP_CLEAR_ALL_SALES_REP'
  }
};